
import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'
import DossierDetail from './DossierDetail.vue'
import DossierFilterComponent from './DossierFilter.vue'
import DossierMap from './DossierMap.vue'
import DossierTable from './DossierTable.vue'
import DossierDialogs from './DossierDialogs.vue'
import { dossierService, DossierFilter } from '@/services/dossier.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import SearchBar from '@/components/ged/SearchBar.vue'
import { authService } from '@/services/auth.service'
import { QUOTE_STATUES } from '@/services/constants.service'

@Component({
  name: 'Dossier',
  subscriptions () {
    return {
      user$: userService.onChange,
      filter$: dossierService.onChangeFilter,
      dossiers$: dossierService.onChangeDossiers,
      totalDossiers$: dossierService.onChangeTotalDossiers,
      loading$: dossierService.onLoading
    }
  },
  components: {
    SearchBar,
    DossierDetail,
    DossierFilterComponent,
    DossierMap,
    DossierTable,
    DossierDialogs
  },
  computed: {
    mapHeight: function () {
      const captureMapHeight = document.querySelector('#map') as HTMLElement
      return captureMapHeight.clientHeight
    }
  },
  watch: {
    $route () {
      (this as Dossier).clearFilter();
      (this as Dossier).createDossier()
    }
  }
})

export default class Dossier extends Vue {
  user$: User = new User()
  filter$ = new DossierFilter()
  filterActive: boolean = false
  mapActive: boolean = false
  queryTimeout: any = null
  loading$: boolean = false
  pageType: any | null = null

  created () {
    this.createDossier()
    if (authService.userValue && authService.userValue.id) {
      this.loading$ = true
      userService
        .getUserById(authService.userValue.id.toString())
        .then(() => {
          this.loading$ = false
        })
        .catch((err) => {
          console.error(err)
          this.loading$ = false
        })
    }
  }

  get extractable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.EXPORT_FOLDER_LIST)
  }

  createDossier () {
    this.pageType = this.$route.query.type
    if (this.filter$ && this.filter$.type) {
      this.filter$.type = null
    }
    this.refreshPage()
  }

  clearFilter () {
    this.filter$.query = ''
    dossierService.clearFilter()
    this.refreshPage()
  }

  refreshPage () {
    dossierService.resetCurrentPage()
    this.pageType = this.$route.query.type ? this.$route.query.type : this.pageType
    const filterType = this.filter$ && this.filter$.type ? this.filter$.type : null
    const filterMode = this.filter$ && this.filter$.query ? 'query' : 'filters'
    if (this.pageType && (this.pageType === 'quote' || this.pageType === 'settled-folder')) {
      // Change the tab name otherwise it was "Dossier" the name of the Component given in index.ts
      if (this.pageType === 'quote' || filterType === 'quote') {
        document.title = 'Devis'
      }
      if (this.pageType === 'quote' && (filterType === 'folder' || filterType === 'settled-folder' || filterType === 'legal-folder')) {
        document.title = 'Dossiers'
      }

      const type = this.filter$ && this.filter$.type ? this.filter$.type : this.pageType
      const filter = {
        ...this.filter$,
        type,
        pageType: this.filter$ && this.filter$.pageType ? this.filter$.pageType : this.pageType,
        status: type === 'settled-folder' ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] : (type === 'quote' ? [0, 1, 2, 3, 4, 5, 6] : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13])
      }
      dossierService.initFilter(filter)
      dossierService.searchDossiers(filterMode)
    } else if (this.pageType && this.pageType === 'my-settled-folder') {
      const filter = {
        ...this.filter$,
        creator: true,
        pageType: this.filter$ && this.filter$.type ? this.filter$.type : this.pageType,
        createdBy: authService.userValue ? authService.userValue.id : '',
        type: filterType || 'settled-folder'
      }
      if (filterType === 'quote') {
        document.title = 'Devis'
      } else {
        document.title = 'Dossiers'
      }
      dossierService.initFilter(filter)
      dossierService.searchDossiers(filterMode)
    } else if (this.pageType && this.pageType === 'closed') {
      let filter = {}
      if (!this.filter$ || !this.filter$.type || this.filter$.type === 'settled-folder') {
        filter = {
          ...this.filter$,
          type: filterType || 'settled-folder',
          pageType: this.filter$ && this.filter$.type ? this.filter$.type : this.pageType,
          status: [9, 10]
        }
      } else if (this.filter$ && this.filter$.type === 'folder') {
        filter = {
          ...this.filter$,
          type: filterType,
          pageType: this.filter$ && this.filter$.type ? this.filter$.type : this.pageType,
          status: [9, 10]
        }
      } else if (this.filter$ && this.filter$.type === 'legal-folder') {
        filter = {
          ...this.filter$,
          type: filterType,
          pageType: this.filter$ && this.filter$.type ? this.filter$.type : this.pageType,
          status: [12, 13]
        }
      } else if (this.filter$ && this.filter$.type === 'quote') {
        filter = {
          ...this.filter$,
          type: filterType,
          pageType: this.filter$ && this.filter$.type ? this.filter$.type : this.pageType,
          status: QUOTE_STATUES[QUOTE_STATUES.length - 2].value
        }
      }
      dossierService.initFilter(filter)
      dossierService.searchDossiers(filterMode)
    } else if (this.pageType && this.pageType === 'progress') {
      const filter = {
        ...this.filter$,
        pageType: this.filter$ && this.filter$.type ? this.filter$.type : this.pageType,
        type: filterType || 'settled-folder',
        status: [0, 1, 2, 3, 4, 5, 6, 7, 8]
      }
      dossierService.initFilter(filter)
      dossierService.searchDossiers(filterMode)
    } else if (this.pageType && this.pageType === 'myQuote') {
      const filter = {
        ...this.filter$,
        pageType: this.filter$ && this.filter$.type ? this.filter$.type : this.pageType,
        createdBy: authService.userValue ? authService.userValue.id : '',
        type: filterType || 'quote'
      }
      dossierService.initFilter(filter)
      dossierService.searchDossiers(filterMode)
    } else if (this.pageType && this.pageType === 'author') {
      const filter = {
        ...this.filter$,
        pageType: this.filter$ && this.filter$.type ? this.filter$.type : this.pageType,
        expert: authService.userValue ? [authService.userValue.id] : [],
        writer: true,
        type: filterType || 'quote'
      }
      dossierService.initFilter(filter)
      dossierService.searchDossiers(filterMode)
    } else {
      dossierService.initFilter({
        ...this.filter$,
        pageType: this.pageType,
        type: filterType || null
      })
      dossierService.searchDossiers(filterMode)
    }
  }

  handleFilter () {
    this.filterActive = !this.filterActive
  }

  handleMap () {
    this.mapActive = !this.mapActive
  }

  addFolder () {
    (this.$refs.dossierDialogs as any).showSelectTypeDialog(
      (type: string) => {
        if (type === 'quote') {
          window.open(this.$router.resolve({ path: '/devis' }).href, '_blank')
        } else if (type === 'legal-folder') {
          window.open(this.$router.resolve({ path: '/dossier-judiciaire' }).href, '_blank')
        }
      }
    )
  }

  changeQuery () {
    // dossierService.clearFilter();
    clearTimeout(this.queryTimeout)
    this.queryTimeout = setTimeout(function () {
      dossierService.searchDossiers('query')
    }, 500)
  }

  changeType () {
    dossierService.getFilterDatas()
    this.refreshPage();
    (this.$refs.filterComponent as any).updateActiveFilterStatut()
  }

  exportList () {
    dossierService.exportDossiersList().then((res: any) => {
      if (res.data) {
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        const today = moment(new Date()).format('DD-MM-YYYY')
        const fileName = 'export-dossiers-' + today + '.xlsx'
        link.href = downloadUrl
        link.setAttribute('download', fileName) // any other extension
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    })
  }
}
