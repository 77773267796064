import { activeLogRocket, apiClient } from '@/services/api.client'
import { BehaviorSubject, Observable } from 'rxjs'

// todo: complete interface
export interface User {
  id?: number
  nom: string
  prenom: string
  email: string
  role: any
  avatarFile?: any
  userRights?: any[]
  roles?: any[]
  rights?: any[]
  notes?: any[]
}

// this class is responsible for managing the logged user.
class AuthService {
  private readonly LogRocket: any = null
  private readonly _user: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null)
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private readonly _two_factor: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public onChange: Observable<User | null> = this._user.asObservable() // subscribe to user change
  public onLoading: Observable<boolean> = this._loading.asObservable() // subscribe to loading status change
  public onTwoFactor: Observable<boolean> = this._two_factor.asObservable()
  // get the current user value (this doesn't follow changes)
  get userValue (): User | null {
    return this._user.getValue()
  }

  constructor () {
    const authInfo = localStorage.getItem('auth_info')
    if (authInfo != null) {
      this._user.next(JSON.parse(authInfo))
    }
    this.refreshUser().catch((err) => {
      throw err
    })

    if (activeLogRocket) {
      this.LogRocket = require('logrocket')
      this.LogRocket.init('kocydj/robineassocies')
    }
  }

  public async refreshUser (): Promise<void> {
    await apiClient.get('security/me').then(res => {
      localStorage.setItem('auth_info', JSON.stringify(res.data))
      this._user.next(res.data)

      if (!activeLogRocket || !this.LogRocket) {
        return
      }
      this.LogRocket.identify(res.data.email, {
        name: `${String(res.data.firstname)} ${String(res.data.lastname)}`,
        email: res.data.email,

        // Add your own custom user variables here, ie:
        id: res.data.id
      })
    })
  }

  public async login (email: string, password: string): Promise<void> {
    this._loading.next(true)
    await apiClient.post('security/login', { email, password }).then(res => {
      this._loading.next(false)
      if (typeof res.data.two_factor_complete !== 'undefined') {
        this._two_factor.next(true)
      } else {
        localStorage.setItem('auth_info', JSON.stringify(res.data.user))
        this._user.next(res.data.user)
      }
    })
  }

  public async two_factor (authCode: string): Promise<void> {
    this._loading.next(true)
    await apiClient.post('2fa_check', { authCode }).then(res => {
      localStorage.setItem('auth_info', JSON.stringify(res.data.user))
      this._user.next(res.data.user)
      this._loading.next(false)
      this._two_factor.next(false)
    })
  }

  public async logout (): Promise<void> {
    localStorage.removeItem('auth_info')
    this._user.next(null)
    try {
      await apiClient.post('security/logout')
    } catch (e) {

    }
  }

  public getUserId (): any {
    if ((authService.userValue != null) && authService.userValue.id) {
      return authService.userValue.id
    }
    return null
  }
}

export const authService = new AuthService()
