<template>
  <div class="zoom-range">
    <button
      class="btn zoom-range-before"
      @click="val--" />
    <input
      id="zoom-range--slider"
      v-model="val"
      type="range"
      step="1"
      name="radius"
      :min="min"
      :max="max"
      class="zoom-range-control">
    <button
      class="btn zoom-range-after"
      @click="val++" />
  </div>
</template>
<script>
export default {
  name: 'ZoomRange',
  props: {
    min: {
      type: [Number, String],
      default: 1
    },
    max: {
      type: [Number, String],
      default: 3
    },
    value: {
      type: [Number, String],
      default: 1
    }
  },
  data () {
    return {
      val: this.value
    }
  },
  watch: {
    val (r) {
      this.$emit('input', r)
    },
    value (r) {
      this.val = r
    }
  },
  mounted () {}
}
</script>
