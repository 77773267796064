
import _ from 'lodash'
import Moment from 'moment'
import { Component, Vue } from 'vue-property-decorator'
import { dashboardService, SummaryData } from '@/services/dashboard.service'
import 'chart.js'
import 'chartjs-plugin-labels'
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions () {
    return {
      summaryData$: dashboardService.onChangeSummaryData
    }
  },
  methods: {}
})
export default class DashboardLineChart extends Vue {
  summaryData$: SummaryData
  chartOptions: any = {}
  chartInfos: any[] = [...Array(12).keys()].map((month: number) => ({
    label: Moment().set('month', 3).locale('fr').add(month, 'month').format('MMMM'),
    month: Moment().set('month', 3).locale('fr').add(month, 'month').format('M')
  }))

  created () {
  }

  mounted () {
    this.chartOptions = {
      responsive: true,
      legend: {
        display: true
      },
      animation: {
        animateScale: true,
        animateRotate: true
      },
      plugins: {
        labels: false
      },
      layout: {
        padding: {
          top: 25
        }
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (context: any) {
            let label = context.label || ''

            if (label) {
              label += ': '
            }
            if (context.value !== null) {
              label += new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(context.value)
            }
            return label
          }
        }
      },
      hover: { mode: null },
      scales: {
        yAxes: [{
          ticks: {
            callback (value: number) {
              const options = [
                { max: 10, val: 1000000000, sym: 'B' },
                { max: 7, val: 1000000, sym: 'M' },
                { max: 4, val: 1000, sym: 'K' }
              ]
              const len = Math.abs(value).toString().length
              const option = options.find(opt => opt.max < len)
              if (option) {
                return (value / option.val).toFixed(0).concat(option.sym)
              }
              return value
            }
          }
        }]
      }
    }
  }

  get chartData (): any {
    if (!this.summaryData$.CACumulatedCurrentYear) return {}
    const CACurrentYear: any = this.summaryData$.CACumulatedCurrentYear
    const CALastYear: any = this.summaryData$.CACumulatedLastYear

    const chartData: any = {
      datasets: [
        {
          label: 'CA année N-1',
          data: this.chartInfos.map((item: any) => _.round(CALastYear[item.month], 2)),
          fill: false,
          backgroundColor: 'lightgrey',
          borderWidth: 1
        },
        {
          label: 'CA année N',
          data: this.chartInfos.map((item: any) => _.round(CACurrentYear[item.month], 2)),
          fill: false,
          backgroundColor: 'maroon',
          borderWidth: 1
        }
      ],
      labels: this.chartInfos.map((item: any) => item.label)
    }

    return chartData
  }
}
