
import { Component, Prop, Vue } from 'vue-property-decorator'
import { billingService, Billing } from '@/services/billing.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import FormattedFloat from '../utils/FormattedFloat.vue'
import { legalFolderService } from '@/services/legalFolder.service'
import { LegalFolder } from '@/models/legalFolder.model'
import { COURTHOUSE_BILLING } from '@/services/constants.service'
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions () {
    return {
      billing$: billingService.onChangeBilling,
      user$: userService.onChange,
      legalFolder$: legalFolderService.onChangeLegalFolder
    }
  },
  components: {
    FormattedFloat
  }
})

export default class BillingConsignation extends Vue {
  @Prop({ default: false }) disabled: boolean

  billing$: Billing = new Billing()
  user$: User = new User()
  legalFolder$: LegalFolder = new LegalFolder()
  COURTHOUSE_BILLING = COURTHOUSE_BILLING

  get updateable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.BILLING_SECTION_UPDATE) && !this.disabled
  }

  selectContact (contactId: string, item: any, itemIndex: number): void {
    if (contactId === this.COURTHOUSE_BILLING) {
      item.contactId = this.COURTHOUSE_BILLING
      item.courthouseId = this.legalFolder$.courthouse ? this.legalFolder$.courthouse.id : null
      item.contactName = null
      this.billing$.consignationPayments.splice(itemIndex, 1, item)
    } else {
      const contact = this.billing$.availableContacts.find((contact) => contactId === contact.id)
      if (contact) {
        item.courthouseId = null
        item.contactId = contact.id
        item.contactName = contact.nom + (contact.prenom ? ' ' + contact.prenom : '')
        this.billing$.consignationPayments.splice(itemIndex, 1, item)
      }
    }
  }

  deleteConsignationPayment (index: number): void {
    billingService.deleteConsignationPayment(index)
  }

  addConsignationPayment (index: number): void {
    billingService.addConsignationPayment(index)
  }

  changeAmount (): void {
    billingService.updateConsignationPayment()
  }
}
