
import { Component, Vue } from 'vue-property-decorator'
import InformationGenerals from '../components/quote/general/InformationGenerals.vue'
import QuoteManagement from '../components/quote/QuoteManagement.vue'
import QuoteBillingManagement from '../components/quote/QuoteBillingManagement.vue'
import QuoteDialogs from '../components/quote/QuoteDialogs.vue'
import QuotePipeLine from '../components/quote/QuotePipeLine.vue'
import FileManagementList from '../components/quote/file/FileManagementList.vue'
import EmailManagement from '../components/quote/email/EmailManagement.vue'
import BillingState from '../components/billing/BillingState.vue'
import { quoteService, Quote } from '@/services/quote.service'
import { contactService } from '@/services/contact.service'
import { userService, User, USER_RIGHTS, USER_ROLES } from '@/services/user.service'
import { QUOTE_STATUES } from '@/services/constants.service'
import { authService } from '@/services/auth.service'
import { settledFolderService } from '@/services/settledFolder.service'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Moment from 'moment'
import ConflitDialog from '@/components/conflit/ConflitDialog.vue'
import { localeDateStr } from '@/utils/functions'
import { tap } from 'rxjs/operators'
import { quoteRouteName } from '@/utils/constant'
import QuoteHistory from '@/components/quote/QuoteHistory.vue'
@Component({
  name: quoteRouteName,
  subscriptions (this: Vue) {
    return {
      quote$: quoteService.onChangeQuote.pipe(
        tap((res: any) => {
          if (res.name) {
            document.title = res.name
          }
        })
      ),
      user$: userService.onChange
    }
  },
  components: {
    QuoteHistory,
    InformationGenerals,
    QuoteManagement,
    FileManagementList,
    EmailManagement,
    QuoteDialogs,
    QuotePipeLine,
    QuoteBillingManagement,
    BillingState,
    ClipLoader,
    ConflitDialog
  },
  watch: {
    $route () {
      if (this.$route.params.id) {
        (this as QuoteView).getQuote()
      } else {
        quoteService.initQuote()
      }
    }
  }
})
export default class QuoteView extends Vue {
  QUOTE_STATUES = QUOTE_STATUES
  loading: boolean = false
  collapseNumber: number = 1
  quote$: Quote = new Quote()
  user$: User = new User()
  activeNames : string = 'general'
  showConflict: boolean = false

  localeDateStr: Function = localeDateStr

  created () {
    contactService.getContactNames()
    quoteService.initQuote()
    if (localStorage.getItem('duplicatedQuote')) {
      if (authService.userValue && authService.userValue.id) {
        this.loading = true
        const data = JSON.parse(localStorage.getItem('duplicatedQuote') || '')
        userService
          .getUserById(authService.userValue.id.toString())
          .then(() => {
            this.loading = false
            quoteService.initDuplicateQuote(data)
          })
          .catch((err) => {
            console.error(err)
            this.loading = false
          })
      }
      localStorage.removeItem('duplicatedQuote')
    } else {
      if (authService.userValue && authService.userValue.id) {
        this.loading = true
        userService
          .getUserById(authService.userValue.id.toString())
          .then(() => {
            if (this.$route.params.id) {
              this.getQuote()
            } else {
              this.loading = false
            }
          })
          .catch((err) => {
            console.error(err)
            this.loading = false
          })
      }
    }
  }

  get deletable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.ADM)
  }

  get changeable (): boolean {
    if (this.$route.params.id) {
      return userService.hasRight(this.user$, USER_RIGHTS.UPDATE_STATUS_UPDATE)
    }
    return false
  }

  get createable (): boolean {
    return !userService.hasRole(this.user$, USER_ROLES.EXPERT)
  }

  get updateable (): boolean {
    if (!this.$route.params.id) {
      return this.createable && (
        userService.hasRight(this.user$, USER_RIGHTS.LM_SECTION_UPDATE) ||
                userService.hasRight(this.user$, USER_RIGHTS.FILE_SECTION_UPDATE) ||
                userService.hasRight(this.user$, USER_RIGHTS.MAIL_SECTION_UPDATE) ||
                userService.hasRight(this.user$, USER_RIGHTS.BILLING_SECTION_UPDATE)
      )
    } else {
      return (
        userService.hasRight(this.user$, USER_RIGHTS.LM_SECTION_UPDATE) ||
                userService.hasRight(this.user$, USER_RIGHTS.FILE_SECTION_UPDATE) ||
                userService.hasRight(this.user$, USER_RIGHTS.MAIL_SECTION_UPDATE) ||
                userService.hasRight(this.user$, USER_RIGHTS.BILLING_SECTION_UPDATE)
      )
    }
  }

  get cancelable (): boolean {
    if (this.quote$.status === 3) {
      return true
    }
    return false
  }

  get notification (): any {
    const notifications = this.quote$.notifications
    const find = notifications.filter((item: any) => {
      return item.checked === false && item.recipient.id === this.user$.id
    }).sort((itemA : any, itemB : any) => {
      if (Moment(itemA.createdAt).isBefore(itemB.createdAt)) {
        return 1
      };
      return -1
    })
    return find && find.length > 0 ? find[0] : null
  }

  get quoteStatusAction (): string {
    switch (this.quote$.status) {
      case 2: // En attente d'acceptation de validation
        return 'Annuler la soumission'
      default:
        return QUOTE_STATUES[this.quote$.status].action
    }
  }

  get editableStatus (): boolean {
    return this.quote$.status <= 6
  }

  getQuote (): void {
    quoteService
      .getQuoteById(this.$route.params.id)
      .then((res: any) => {
        this.loading = false
        if (
          res.quote.status === 2 &&
                    userService.hasRight(this.user$, USER_RIGHTS.VDD)
        ) {
          (this.$refs.quoteDialogs as any).showAcceptValidationDialog(
            (result: boolean) => {
              if (result) {
                this.changeStatus(3)
              } else {
                this.$router.replace('/devis')
                quoteService.initQuote()
              }
            }
          )
        }
      })
      .catch((err) => {
        console.error(err)
        this.$router.replace('/devis')
        this.loading = false
      })
  }

  refuseQuote (): void {
    (this.$refs.quoteDialogs as any).showCommentDialog((comment: string) => {
      if (comment) {
        this.loading = true
        quoteService.saveQuote().then(() => {
          quoteService.refuseValidation(comment).then(() => {
            this.loading = false
          }, () => {
            this.loading = false
            this.$message({
              message: 'La sauvegarde a échoué!, Veuillez réessayer plus tard.',
              type: 'error',
              offset: 65
            })
          })
        }, () => {
          this.loading = false
          this.$message({
            message: 'La sauvegarde a échoué!, Veuillez réessayer plus tard.',
            type: 'error',
            offset: 65
          })
        })
      }
    })
  }

  cancelSubmit (): void {
    quoteService.cancelSubmit()
  }

  checkNotification (notification: any): void {
    notification.checked = true
    quoteService.checkNotification(notification.id)
  }

  changeStatus (status: number): void {
    if (this.changeable) {
      const quoteId = this.quote$.id
      switch (status) {
        case 1: // Soumettre la LM
          this.quote$.status = 1
          quoteService.saveQuote().then(() => quoteService.getStatusHistory())
          break
        case 2: // En attente d'acceptation de validation
          (this.$refs.quoteDialogs as any).showSelectFileDialog(
            (fileId: string, comment: string) => {
              if (fileId) {
                this.loading = true
                quoteService.submitQuote(fileId, comment).then(() => {
                  this.loading = false
                  quoteService.getStatusHistory()
                  this.$message({
                    message: 'La procédure de signature Yousign a bien été créée.',
                    type: 'success',
                    offset: 65
                  })
                }, () => {
                  this.loading = false
                  this.$message({
                    message: 'Erreur lors de la création de la procédure de signature électronique.' +
                                          ' Vérifier les informations suivantes : prénom, nom et numéro de téléphone du signataire et format de la lettre de mission (docx).',
                    type: 'error',
                    offset: 65,
                    duration: 15000
                  })
                })
              }
            }
          )
          break
        case 3: // En attente de validation
          quoteService.acceptValidation().then(() => quoteService.getStatusHistory())
          break
        case 4: // LM validate
          (this.$refs.quoteDialogs as any).showCommentDialog(
            (comment: string) => {
              if (comment) {
                this.loading = true
                quoteService.saveQuote().then(() => {
                  quoteService.validateQuote(comment).then(() => {
                    this.loading = false
                    quoteService.getStatusHistory()
                    this.$confirm('Souhaitez-vous commencer la procédure de signature via Yousign ?', '', {
                      confirmButtonText: 'Oui',
                      cancelButtonText: 'Non',
                      type: 'warning'
                    }).then(() => {
                      quoteService.sendToYousign().then(() => {
                        this.$message({
                          message: 'La procédure de signature Yousign a bien été créée.',
                          type: 'success',
                          offset: 65
                        })
                      })
                    })
                  }, () => {
                    this.loading = false
                    this.$message({
                      message: 'La sauvegarde a échoué!, Veuillez réessayer plus tard.',
                      type: 'error',
                      offset: 65
                    })
                  })
                }, () => {
                  this.loading = false
                  this.$message({
                    message: 'La sauvegarde a échoué!, Veuillez réessayer plus tard.',
                    type: 'error',
                    offset: 65
                  })
                })
              }
            }
          )
          break
        case 5: // Envoye
          quoteService.emailSent().then(() => quoteService.getStatusHistory())
          break
        case 6:
          this.quote$.oldStatus = this.quote$.status
          this.quote$.status = 6
          quoteService.saveQuote().then(() => quoteService.getStatusHistory())
          break
        case 7: // Ouvert
          quoteService.validate()
          if (this.quote$.associateFolderId) {
            quoteService.sendToFolder().then((res: any) => {
              this.$router.push('/dossier-amiable/' + res.folder_id)
            })
          } else {
            quoteService.sendToFolder().then((res: any) => {
              this.$router.push('/dossier-amiable/' + res.folder_id)
            }).catch(() => {
              this.$message({
                message: "La date d'acceptation est vide.",
                type: 'error',
                offset: 65
              })
              quoteService.getQuoteById(quoteId)
            })
          }
          break
        default:
          // TODO
          break
      }
    }
  }

  changeActiveNames (activeNames: string):void {
    this.activeNames = activeNames
  }

  saveQuote (): void {
    if (this.updateable && !this.loading) {
      this.loading = true
      quoteService.saveQuote().then(
        (res: any) => {
          this.loading = false
          this.$message({
            message: 'Le devis a été sauvegardé avec succès.',
            type: 'success',
            offset: 65
          })
          if (!this.$route.params.id) {
            this.$router.replace('/devis/' + res.quote.id)
          }
        },
        () => {
          this.loading = false
          this.$message({
            message: 'La sauvegarde a échoué!, Veuillez réessayer plus tard.',
            type: 'error',
            offset: 65
          })
        }
      )
    }
  }

  changeShowConflict (show: boolean) {
    this.showConflict = show
  }

  handleChange (val: number) {
    if (val) {
      this.collapseNumber = val
    }
  }

  duplicateQuote () {
    if (!this.$route.params.id) {
      return
    }
    quoteService.duplicateQuote().then(() => {
      window.open(this.$router.resolve({ path: '/devis' }).href, '_blank')
    })
  }

  deleteQuote () {
    if (!this.$route.params.id) {
      return
    }
    (this.$refs.quoteDialogs as any).showConfirmDeleteDialog(
      (result: boolean) => {
        if (result) {
          quoteService.deleteQuote().then(() => {
            this.$router.replace('/liste-dossiers')
          })
        }
      }
    )
  }

  associateQuote () {
    if (!this.$route.params.id) {
      return
    }
    settledFolderService.getFolders();
    (this.$refs.quoteDialogs as any).showSelectFolderDialog(
      (folderId: string) => {
        if (folderId) {
          quoteService.associateToFolder(folderId)
        }
      }
    )
  }

  handleAccordion () {
    this.activeNames = ''
  }

  showConflicts () {
    this.showConflict = true
  }

  transformToPdf () {
    (this.$refs.quoteDialogs as any).updateShowSelectFileToTransform(
      (fileId: string) => {
        if (fileId) {
          this.loading = true
          quoteService.generatePdf(fileId).then(() => {
            this.loading = false
            this.$message({
              message: 'PDF généré et ajouté aux pièces jointes avec succès.',
              type: 'success',
              offset: 65
            })
          }, () => {
            this.loading = false
          })
        }
      }
    )
  }
}
