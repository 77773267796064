
import { Component, Vue } from 'vue-property-decorator'
import { fileService, AppFile } from '@/services/file.service'
    @Component({
      subscriptions (this: Vue) {
        return {
          files$: fileService.onChange
        }
      }
    })
export default class SettledFolderDialogs extends Vue {
  files$: AppFile[] = []
  showingSelectFile: boolean = false
  showingAcceptValidation: boolean = false
  showingComment: boolean = false
  showingConfirmDelete: boolean = false
  showingConfirmAssociation: boolean = false
  showingSelectFolder: boolean = false

  callback: any = {}

  selectFileForm: any = {
    selectedFile: null
  }

  selectFileFormRules: any = {
    selectedFile: [
      { required: true, message: 'Veuillez sélectionner une lettre de mission', trigger: 'change' }
    ]
  }

  commentForm: any = {
    comment: null
  }

  commentFormRules: any = {
    comment: [
      { required: true, message: 'Merci d’ajouter un commentaire', trigger: 'change' }
    ]
  }

  get settledFolderReportFiles (): AppFile[] {
    return this.files$.filter((file: AppFile) => file.type === 'SettledFolderReport')
  }

  showSelectFileDialog (callback: any) {
    this.showingSelectFile = true
    this.callback = callback
  }

  submitSelectFileForm () {
    (this.$refs.selectFileForm as any).validate((valid: boolean) => {
      if (valid) {
        this.showingSelectFile = false
        this.callback(this.selectFileForm.selectedFile)
      }
    })
  }

  cancelSelectFile () {
    this.showingSelectFile = false
    this.callback(null)
  }

  showAcceptValidationDialog (callback: any) {
    this.showingAcceptValidation = true
    this.callback = callback
  }

  acceptValidation () {
    this.showingAcceptValidation = false
    this.callback(true)
  }

  cancelAccept () {
    this.showingAcceptValidation = false
    this.callback(false)
  }

  showCommentDialog (callback: any) {
    this.showingComment = true
    this.callback = callback
  }

  submitCommentForm () {
    (this.$refs.commentForm as any).validate((valid: boolean) => {
      if (valid) {
        this.showingComment = false
        this.callback(this.commentForm.comment)
      }
    })
  }

  cancelComment () {
    this.showingComment = false
    this.callback(null)
  }

  showConfirmDeleteDialog (callback: any) {
    this.showingConfirmDelete = true
    this.callback = callback
  }

  confirmDelete () {
    this.showingConfirmDelete = false
    this.callback(true)
  }

  cancelDelete () {
    this.showingConfirmDelete = false
    this.callback(false)
  }
}
