
import { Component, Prop, Vue } from 'vue-property-decorator'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import { baseUrl } from '@/services/api.client'
import FileMainCategory from './FileMainCategory.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import SelectFolderOfUpload from './SelectFolderOfUpload.vue'
import { fileService } from '@/services/file.service'
import { SettledFolder, settledFolderService } from '@/services/settledFolder.service'
import { Quote, quoteService } from '@/services/quote.service'
import { legalFolderService } from '@/services/legalFolder.service'
import { LegalFolder } from '@/models/legalFolder.model'

const vue2Dropzone = require('vue2-dropzone')

// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions (this: any) {
    return {
      user$: userService.onChange,
      loading: fileService.onChangeLoading,
      settledFolder$: settledFolderService.onChangeFolder,
      legalFolder$: legalFolderService.onChangeLegalFolder,
      quote$: quoteService.onChangeQuote
    }
  },
  components: {
    vueDropzone: vue2Dropzone,
    ClipLoader,
    FileMainCategory,
    SelectFolderOfUpload
  }
})

export default class FileManagementList extends Vue {
  @Prop({ default: 0 }) folderId: number
  @Prop({ }) folderType: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ }) filesCount: number

  baseUrl: string | undefined = baseUrl
  user$: User = new User()
  dropzone: any = null
  dropzone_2: any = null
  selectedDropZone: any = null
  filesToUpload: any = null
  loading: boolean = false
  quote$: Quote = new Quote()
  legalFolder$: LegalFolder = new LegalFolder()
  settledFolder$: SettledFolder = new SettledFolder()
  countFiles: number = 0

  dropzoneOptions: any = {
    url: 'upload',
    parallelUploads: 100,
    uploadMultiple: true
  }

  get isLegalFolder (): boolean {
    return this.$route.name === 'legal-folder'
  }

  mounted () {
    const app = this
    this.dropzone = (this.$refs.dropzone as any).dropzone

    this.dropzone.uploadFiles = function (files: File[]) {
      app.uploadFiles(app.dropzone, files)
    }
    if (this.quote$ && this.quote$.files) {
      this.countFiles += this.quote$.files.length
    }
    if (this.settledFolder$ && this.settledFolder$.files) {
      this.countFiles += this.settledFolder$.files.length
    }
    if (this.legalFolder$ && this.legalFolder$.files) {
      this.countFiles += this.legalFolder$.files.length
    }
  }

  get changeable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.FILE_SECTION_UPDATE)
  }

  created () {

  }

  /*
    upload files
  */
  uploadFiles (dropzone: any, files: any[]): void {
    this.selectedDropZone = dropzone
    this.filesToUpload = files;
    (this.$refs.selectFolderOfUpload as any).open()
  }

  startUpload (selectedFolder: any) {
    const self = this.selectedDropZone
    const minSteps = 6
    const maxSteps = 60
    const timeBetweenSteps = 100
    const bytesPerStep = 100000
    for (let i = 0; i < this.filesToUpload.length; i++) {
      const file = this.filesToUpload[i]
      // setTimeout(() => {
      fileService.uploadFile(file, selectedFolder.selectedType, selectedFolder.selectedCategoryId).then(() => {
        const totalSteps = Math.round(Math.min(maxSteps, Math.max(minSteps, file.size / bytesPerStep)))
        for (let step = 0; step < totalSteps; step++) {
          const duration = timeBetweenSteps * (step + 1)
          setTimeout((function (file, totalSteps, step) {
            return function () {
              file.upload = {
                progress: 100 * (step + 1) / totalSteps,
                total: file.size,
                bytesSent: (step + 1) * file.size / totalSteps
              }
              self.emit('uploadprogress', file, file.upload.progress, file.upload.bytesSent)
              if (file.upload.progress === 100) {
                file.status = 'success'
                self.emit('success', file, 'success', null)
                self.emit('complete', file)
                self.processQueue()
                setTimeout(() => {
                  self.removeAllFiles(true)
                }, 1000)
              }
            }
          }(file, totalSteps, step)), duration)
        }
      }).catch(() => {
        file.status = 'error'
        self.emit('error', file, 'error')
        self.emit('complete', file)
        self.processQueue()
      })
      // }, 100);
    }
  }

  stopUploadFiles (): void {
    this.filesToUpload.forEach((file: any) => {
      this.selectedDropZone.removeFile(file)
    })
    this.filesToUpload = []
  }

  get readable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.FILE_SECTION_READ)
  }
}
