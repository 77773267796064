<template>
  <div
    v-if="!isAdvanced"
    class="filter"
    :class="{ active: isActive }">
    <div class="filter-top d-flex align-items-center">
      <img
        class="search-black"
        :src="require('../../assets/img/pipe_line/search_black.svg')"
        @click="search()">
      <el-select
        v-model="globalItemSearch$.fields"
        class="filters-fields-select"
        placeholder="Select"
        :popper-append-to-body="false">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value" />
      </el-select>
      <el-button
        class="btn-symbol"
        @click="searchType()">
        <el-tooltip
          v-if="globalItemSearch$.queryType === 'contain'"
          class="item"
          effect="dark"
          content="contient"
          placement="bottom">
          <img
            :src="require('../../assets/img/pipe_line/symbol.svg')">
        </el-tooltip>
        <el-tooltip
          v-if="globalItemSearch$.queryType === 'exact'"
          class="item"
          effect="dark"
          content="égal"
          placement="bottom">
          <img
            :src="require('../../assets/img/pipe_line/symbol2.svg')">
        </el-tooltip>
        <el-tooltip
          v-if="globalItemSearch$.queryType === 'not_contain'"
          class="item"
          effect="dark"
          content="différent"
          placement="bottom">
          <img
            :src="require('../../assets/img/pipe_line/symbol4.svg')">
        </el-tooltip>
      </el-button>
      <div class="input-search mr-3 ">
        <el-input
          v-if="globalItemSearch$.fields === getAdresseTypeSearch()"
          v-model="globalItemSearch$.queryString"
          v-loading.fullscreen.lock="isLoading$"
          placeholder="Rechercher une adresse…"
          size="mini"
          @focus="activeSearch()"
          @input="debounceSearch"
          @keypress.native.enter="search">
          <el-button
            slot="append"
            @click="resetInput">
            <img :src="require('../../assets/img/pipe_line/rafraichir.svg')">
          </el-button>
        </el-input>
        <el-input
          v-else
          v-model="globalItemSearch$.queryString"
          v-loading.fullscreen.lock="isLoading$"
          placeholder="Rechercher un dossier, une adresse…"
          @keypress.native.enter="search"
          @focus="activeSearch()">
          <el-button
            slot="append"
            @click="resetInput">
            <img :src="require('../../assets/img/pipe_line/rafraichir.svg')">
          </el-button>
        </el-input>
      </div>
      <div
        v-if="!isActive"
        class="filter-number mr-3"
        @click="showAdvanced()">
        Filtres ({{ filtersCount$ }})
      </div>
      <el-button
        v-if="isActive"
        class="btn-symbol close-filter"
        @click="hideSearch()">
        <img :src="require('../../assets/img/pipe_line/close_white.svg')">
      </el-button>
    </div>
    <transition
      enter-active-class="enter-active"
      leave-active-class="leave-active"
      @before-enter="beforeEnter"
      @enter="enter"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
      @leave="leave"
      @after-leave="afterLeave">
      <div
        v-if="isActive"
        class="filter-advanced">
        <div class="recherche-recent">
          <div
            v-if="globalItemSearch$.fields === getAdresseTypeSearch()"
            class="recent-list">
            <ul>
              <li
                v-for="(elt, index) in referenceAddresse"
                :key="index"
                class="d-flex align-items-center justify-content-between">
                <div
                  class="d-flex align-items-center justify-content-start pointer"
                  @click="openPreviousSearch(elt.label)">
                  <img
                    v-if="!elt.disabled"
                    class="mr-3"
                    width="12px"
                    height="12px"
                    :src="require('../../assets/img/pipe_line/search_black.svg')">
                  {{ elt.label }}
                </div>
              </li>
            </ul>
          </div>
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h5>Mes recherches récentes</h5>
            <el-button
              type="text"
              @click="deleteAllGlobalSearches">
              Effacer l’historique
            </el-button>
          </div>
          <div class="recent-list">
            <ul>
              <li
                v-for="(elt, index) in globalSearches$"
                :key="index"
                class="d-flex align-items-center justify-content-between">
                <div
                  class="d-flex align-items-center justify-content-start pointer"
                  @click="openPreviousSearch(elt.name)">
                  <img
                    class="mr-3"
                    :src="require('../../assets/img/pipe_line/history.svg')">
                  {{ elt.name }}
                </div>
                <el-button
                  class="btn-symbol"
                  @click="deleteGlobalSearch(elt)">
                  <img :src="require('../../assets/img/pipe_line/close_white.svg')">
                </el-button>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="location">
          <div v-if="globalItemSearch$.fields === getAdresseTypeSearch()">
            <div
              v-if="isSuggestion"
              class="d-flex align-items-center justify-content-start">
              <div class="d-flex align-items-center justify-content-start mr-3">
                <img
                  class="mr-3"
                  :src="require('../../assets/img/pipe_line/location.svg')">
                Dans un rayon de
              </div>
              <div class="slider-container mr-4 ml-4">
                <el-slider
                  v-model="globalItemSearch$.location.distance"
                  label="test"
                  show-tooltip
                  :step="0.5"
                  :format-tooltip="formatTooltip"
                  tooltip-class="range-tooltip" />
              </div>
              <span>{{ globalItemSearch$.location.distance }} Km</span>
            </div>
            <div class="d-flex align-items-center justify-content-start fw-b">
              <img
                class="mr-3"
                :src="require('../../assets/img/pipe_line/france.svg')">
              Toute la France
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <el-button
              title="Rechercher"
              class="btn-detail-foot"
              @click="search">
              Rechercher
            </el-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { globalSearchService } from '@/services/globalSearch.service'
import { historyService } from '@/services/history.service'
import {
  searchGlobalItemsService
  , SEARCH_TYPE
} from '@/services/searchGlobalItems.service'
import { tap } from 'rxjs/operators'
import {
  listReferenceService
} from '@/services/listReference.service'

export default {
  subscriptions () {
    return {
      loading$: globalSearchService.onLoading,
      globalSearches$: globalSearchService.onChangeGlobalSearches,
      currentHistory$: historyService.onChangeCurrentHistory.pipe(tap((res) => {
        if (res.criteria) {
          this.globalItemSearch$ = res.criteria
        }
      })),
      filtersCount$: searchGlobalItemsService.onChangeFiltersCount,
      globalItemSearch$: searchGlobalItemsService.onChangeGlobalItemsFilters,
      isLoading$: searchGlobalItemsService.onChangeLoading
    }
  },
  props: {
    isAdvanced: {
      type: Boolean,
      required: true
    }
  },
  emits: ['showAdvanced'],
  data () {
    return {
      isSuggestion: false,
      isActive: false,
      icon1: true,
      icon2: false,
      icon3: false,
      icon4: false,
      options: [
        {
          value: SEARCH_TYPE.ADDRESS,
          label: 'Adresse'
        },
        {
          value: SEARCH_TYPE.CONTACT_NAME,
          label: 'Nom/Prénom Contact'
        },
        {
          value: SEARCH_TYPE.QUOTE_FOLDER_NAME,
          label: 'Num devis/dossiers'
        },
        {
          value: SEARCH_TYPE.GED_NAME,
          label: 'Nom document/note'
        },
        {
          value: SEARCH_TYPE.PHONE_NUMBER,
          label: 'Numéro de téléphone contact'
        },
        {
          value: SEARCH_TYPE.GED_TAG,
          label: 'Tags de documents/note'
        }
      ],
      value: 'Adresse',
      location: 0,
      referenceAddresse: [],
      debounce: null
    }
  },
  created () {
    globalSearchService.getGlobalSearches()
    this.clickoutsideAdvancedFilter()
    window.addEventListener('keypress', this.handleKeyPress)
  },
  beforeDestroy () {
    window.removeEventListener('keypress', this.handleKeyPress)
  },
  methods: {
    /**
     * @param {HTMLElement} element
     */
    beforeEnter (element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = '0px'
        }

        element.style.display = null
      })
    },
    /**
     * @param {HTMLElement} element
     */
    enter (element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = `${element.scrollHeight}px`
        })
      })
    },
    /**
     * @param {HTMLElement} element
     */
    afterEnter (element) {
      element.style.height = null
    },
    /**
     * @param {HTMLElement} element
     */
    beforeLeave (element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = `${element.offsetHeight}px`
        }
      })
    },
    /**
     * @param {HTMLElement} element
     */
    leave (element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = '0px'
        })
      })
    },
    /**
     * @param {HTMLElement} element
     */
    afterLeave (element) {
      element.style.height = null
    },
    searchType () {
      const queryTypes = {
        exact: 'contain',
        contain: 'not_contain',
        not_contain: 'exact'
      }
      this.globalItemSearch$.queryType = queryTypes[this.globalItemSearch$.queryType]
    },
    hideSearch () {
      this.isActive = false
    },
    activeSearch () {
      this.isActive = true
    },
    showAdvanced () {
      this.$emit('showAdvanced')
    },
    search () {
      searchGlobalItemsService.setIsLoading(true)
      this.hideSearch()
      if (this.globalItemSearch$.queryString.trim().length !== 0) {
        globalSearchService.saveGlobalSearch(this.globalItemSearch$.queryString)
        this.globalItemSearch$.offset = 0
        this.$emit('search', this.globalItemSearch$)
      }
    },
    clickoutsideAdvancedFilter () {
      window.addEventListener('mousedown', (event) => {
        if (!event.target.closest('.filter-advanced,.filter')) {
          this.isActive = false
        }
      })
    },
    deleteAllGlobalSearches () {
      globalSearchService.deleteAllSearches().then(() => {
        globalSearchService.getGlobalSearches()
      })
    },
    deleteGlobalSearch (elt) {
      globalSearchService.deleteSearch(elt.id).then(() => {
        globalSearchService.getGlobalSearches()
      })
    },
    formatTooltip (val) {
      return val + ' Km'
    },
    openPreviousSearch (name) {
      if (name) {
        this.isSuggestion = this.referenceAddresse.findIndex((item) => item.label.toLowerCase() === name.toLowerCase()) !== -1
        this.globalItemSearch$.queryString = name
      }
    },
    getAdresseTypeSearch () {
      return SEARCH_TYPE.ADDRESS
    },
    debounceSearch (queryString) {
      if (!queryString) {
        return
      }
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.isSuggestion = this.referenceAddresse.findIndex((item) => item.label.toLowerCase() === queryString.toLowerCase()) !== -1
        this.referenceAddresse = [{ label: 'Recherche en cours...', disabled: true }]
        listReferenceService.getReferenceAddresse(queryString).then(res => {
          this.referenceAddresse = res
        })
      }, 600)
    },
    resetInput () {
      this.globalItemSearch$.queryString = ''
      searchGlobalItemsService.resetFilters()
    },
    handleKeyPress (e) {
      if (e.key === 'Enter' && this.isActive) {
        this.search()
      }
    }
  }

}
</script>
