import { apiClient } from '@/services/api.client'
import { BehaviorSubject, Observable } from 'rxjs'

export class Role {
  id?: number
  nom: string
  description: string
  rights: number[]

  constructor (role: any) {
    this.id = role.id
    this.nom = role.nom
    this.description = role.description
    this.rights = role.rights.map((right: any) => right.id)
  }
}
// this class is responsible for managing the roles.
class RoleService {
  private readonly _role: BehaviorSubject<Role | any> = new BehaviorSubject<Role | any>({})
  private readonly _roles: BehaviorSubject<Role[]> = new BehaviorSubject<Role[]>([])
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  private readonly _activeRoleIndex: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null)

  public onChangeRole: Observable<Role | any> = this._role.asObservable() // subscribe to role change
  public onChange: Observable<Role[]> = this._roles.asObservable() // subscribe to roles change
  public onLoading: Observable<boolean> = this._loading.asObservable() // subscribe to loading status change
  public onChangeActiveRoleIndex: Observable<number | null> = this._activeRoleIndex.asObservable()

  get roles (): Role[] {
    return this._roles.getValue()
  }

  public async getRoles (nom: string = '', description: string = '', rights: number[] = [], sortProp: string = 'nom', sortOrder: string = 'ASC'): Promise<void> {
    this._loading.next(true)
    await apiClient.get('roles', { params: { nom, description, rights: JSON.stringify(rights), sortOrder, sortProp } }).then(res => {
      if (res.data) {
        const roles: Role[] = []
        res.data.forEach((role: any) => {
          roles.push(new Role(role))
        })
        this._roles.next(roles)
        this._loading.next(false)
      }
    })
  }

  public async getRoleById (roleId: string): Promise<void> {
    this._loading.next(true)
    await apiClient.get('role/' + roleId).then(res => {
      this._role.next(new Role(res.data))
      this._loading.next(false)
    })
  }

  public async saveRole (id: any, nom: string, description: string, rights: any[]): Promise<void> {
    await apiClient.post('role', { id, nom, description, rights }).then(res => {
      this._role.next(new Role(res.data))
    })
  }

  public activeRoleIndex (index: number | null): void {
    this._activeRoleIndex.next(index)
  }
}

export const roleService = new RoleService()
