
import { Component, Vue, Emit } from 'vue-property-decorator'
import {
  referenceFileService,
  TYPES,
  AppFile
} from '@/services/referenceFile.service'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { baseUrl } from '@/services/api.client'
import { referenceService } from '@/services/listReference.service'

const vue2Dropzone = require('vue2-dropzone')

// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions () {
    return {
      actesLoading$: referenceFileService.onLoading.Actes,
      plansLoading$: referenceFileService.onLoading.Plans,
      surfacesLoading$: referenceFileService.onLoading.Surfaces,
      rapportLoading$: referenceFileService.onLoading.Rapport,
      autresLoading$: referenceFileService.onLoading.Autres,
      actesFiles$: referenceFileService.onChange.Actes,
      plansFiles$: referenceFileService.onChange.Plans,
      surfacesFiles$: referenceFileService.onChange.Surfaces,
      rapportFiles$: referenceFileService.onChange.Rapport,
      autresFiles$: referenceFileService.onChange.Autres,
      reference$: referenceService.onChangeReference
    }
  },
  components: {
    vueDropzone: vue2Dropzone,
    ClipLoader
  }
})
export default class FileManagement extends Vue {
  actesDropzone: any = null
  plansDropzone: any = null
  surfacesDropzone: any = null
  rapportDropzone: any = null
  autresDropzone: any = null
  reference$: any = null
  baseUrl: string | undefined = baseUrl
  actesFiles$: AppFile[] = []
  plansFiles$: AppFile[] = []
  surfacesFiles$: AppFile[] = []
  rapportFiles$: AppFile[] = []
  autresFiles$: AppFile[] = []

  dropzoneOptions: any = {
    url: 'upload',
    parallelUploads: 1
  }

  oldFileOrders: any[] = []

  mounted () {
    const app = this
    this.actesDropzone = (this.$refs.actesDropzone as any).dropzone
    this.plansDropzone = (this.$refs.plansDropzone as any).dropzone
    this.surfacesDropzone = (this.$refs.surfacesDropzone as any).dropzone
    this.rapportDropzone = (this.$refs.rapportDropzone as any).dropzone
    this.autresDropzone = (this.$refs.autresDropzone as any).dropzone

    this.actesDropzone.uploadFiles = function (files: File[]) {
      app.uploadFiles(app.actesDropzone, files, TYPES[0], app.actesFiles$)
    }
    this.plansDropzone.uploadFiles = function (files: File[]) {
      app.uploadFiles(app.plansDropzone, files, TYPES[1], app.plansFiles$)
    }
    this.surfacesDropzone.uploadFiles = function (files: File[]) {
      app.uploadFiles(
        app.surfacesDropzone,
        files,
        TYPES[2],
        app.surfacesFiles$
      )
    }
    this.rapportDropzone.uploadFiles = function (files: File[]) {
      app.uploadFiles(app.rapportDropzone, files, TYPES[3], app.rapportFiles$)
    }
    this.autresDropzone.uploadFiles = function (files: File[]) {
      app.uploadFiles(app.autresDropzone, files, TYPES[4], app.autresFiles$)
    }
  }

  get numberAttached () {
    let totalNumber = 0
    totalNumber = this.actesFiles$.length + this.plansFiles$.length + this.surfacesFiles$.length + this.rapportFiles$.length + this.autresFiles$.length
    return totalNumber
  }

  /*
    upload files
  */
  uploadFiles (
    dropzone: any,
    files: any[],
    type: string,
    appFiles: AppFile[]
  ): void {
    const self = dropzone
    const document: any = this
    const minSteps = 6
    const maxSteps = 60
    const timeBetweenSteps = 100
    const bytesPerStep = 100000
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      setTimeout(() => {
        this.$emit('isUploading', true)
        referenceFileService
          .uploadReferenceFile(file, type, this.$route.params.id ? this.$route.params.id : this.reference$.id)
          .then(res => {
            this.saveFileIds(res.data.id)
            const totalSteps = Math.round(
              Math.min(maxSteps, Math.max(minSteps, file.size / bytesPerStep))
            )
            for (let step = 0; step < totalSteps; step++) {
              const duration = timeBetweenSteps * (step + 1)
              setTimeout(
                (function (file, totalSteps, step) {
                  return function () {
                    file.upload = {
                      progress: (100 * (step + 1)) / totalSteps,
                      total: file.size,
                      bytesSent: ((step + 1) * file.size) / totalSteps
                    }
                    self.emit(
                      'uploadprogress',
                      file,
                      file.upload.progress,
                      file.upload.bytesSent
                    )
                    if (file.upload.progress === 100) {
                      file.status = 'success'
                      self.emit('success', file, 'success', null)
                      self.emit('complete', file)
                      self.processQueue()
                      setTimeout(() => {
                        self.removeFile(file)
                        document.$emit('isUploading', false)
                      }, 100)
                    }
                  }
                })(file, totalSteps, step),
                duration
              )
            }
            appFiles.push(res.data)
          })
          .catch(() => {
            file.status = 'error'
            self.emit('error', file, 'error')
            self.emit('complete', file)
            self.processQueue()
            this.$emit('isUploading', false)
          })
      }, 100)
    }
  }

  downloadFile (id: number): void {
    window.open(baseUrl + 'files/download/' + id)
  }

  deleteFile (id: number, type: string, files: AppFile[]): void {
    this.$confirm('êtes-vous sûr de vouloir supprimer cette pièce jointe?', '', {
      confirmButtonText: 'Confirmation',
      cancelButtonText: 'Annuler',
      type: 'warning'
    }).then(() => {
      referenceFileService.deleteReferenceFile(id, type).then(() => {
        const index = files.findIndex((item: AppFile) => item.id === id)
        files.splice(index, 1)
      })
    })
  }

  downloadAll (type: string): void {
    if (this.$route.params.id) {
      window.open(baseUrl + 'reference-files/downloads/' + type + '/' + this.$route.params.id)
    } else {
      this.$message({
        message: 'Sauvegarde de la référence nécessaire.',
        type: 'error',
        offset: 65
      })
    }
  }

  @Emit('saveFileIds')
  saveFileIds (fileId: number) {
    return fileId
  }
}
