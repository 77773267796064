import { safeGet, localeDateStr, convertFrNumberEuro } from '@/utils/functions'
import { LegalFolder, Courrier, CourrierContact, Courthouse, NatureDecision } from '@/models/legalFolder.model'
import { legalFolderService } from '@/services/legalFolder.service'
import { billingService, Billing, ConsignationPayment } from '@/services/billing.service'
import { Contact } from '@/services/contact.service'

export class DocumentData {
  public legalFolder$: LegalFolder
  public courthouses$: Courthouse[]
  public natureDecisions$: NatureDecision[]
  public courrier: Courrier
  public courrierContacts: CourrierContact[]
  public billing$: Billing

  public constructor (courrier: Courrier, courrierContacts: CourrierContact[]) {
    this.courrier = courrier
    this.courrierContacts = courrierContacts
    legalFolderService.onChangeLegalFolder.subscribe((legalFolder: LegalFolder) => {
      this.legalFolder$ = legalFolder
    })
    legalFolderService.onChangeCourthouses.subscribe((courthouses: Courthouse[]) => {
      this.courthouses$ = courthouses
    })
    legalFolderService.onChangeNatureDecisions.subscribe((natureDecisions: NatureDecision[]) => {
      this.natureDecisions$ = natureDecisions
    })
    billingService.onChangeBilling.subscribe((billing: any) => {
      this.billing$ = billing
    })
  }

  get contact1Title (): string {
    return safeGet(this.courrier, ['contact1', 'contact', 'title'])
  }

  get contact1Nom (): string {
    return safeGet(this.courrier, ['contact1', 'contact', 'nom'])
  }

  get contact1Prenom (): string {
    return safeGet(this.courrier, ['contact1', 'contact', 'prenom'])
  }

  get contact2Title (): string {
    const contact2Title = safeGet(this.courrier, ['contact2', 'contact', 'title'])
    if (contact2Title) {
      return safeGet(this.courrier, ['contact2', 'contact', 'title'])
    } else {
      return ''
    }
  }

  get contact2Nom (): string {
    const contact2 = safeGet(this.courrier, ['contact2', 'contact', 'nom'])
    if (contact2) {
      return safeGet(this.courrier, ['contact2', 'contact', 'nom'])
    } else {
      return ''
    }
  }

  get contact2Prenom (): string {
    const contact2Prenom = safeGet(this.courrier, ['contact2', 'contact', 'prenom'])
    if (contact2Prenom) {
      return safeGet(this.courrier, ['contact2', 'contact', 'prenom'])
    } else {
      return ''
    }
  }

  get firstContactAddress (): string {
    const contactAddress = safeGet(this.courrier, ['contact1', 'contactAddress'])
    if (contactAddress) {
      return `${String(contactAddress.street)} ${String(contactAddress.zipCode)} ${String(contactAddress.city)}`
    } else {
      return ''
    }
  }

  get showAR (): boolean {
    return safeGet(this.courrier, ['contact1', 'mandator']) || safeGet(this.courrier, ['contact2', 'mandator'])
  }

  get courrierDate (): string {
    return new Date(this.courrier.date).toLocaleDateString('fr-fr')
  }

  get dateDelaiLimite (): string {
    const limitDateOfReception: Date = this.legalFolder$.limitDateOfReception
    return limitDateOfReception != null ? new Date(limitDateOfReception).toLocaleDateString('fr-fr', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : ''
  }

  get applicantContactsWithMandator (): any[] {
    return this.courrierContacts.filter((courrierContact: CourrierContact) => {
      return courrierContact.type === 'applicant' && courrierContact.mandator
    }).map((courrierContact: CourrierContact) => {
      return {
        ...courrierContact,
        ...courrierContact.contact
      }
    })
  }

  get respondentContactsWithMandator (): any[] {
    return this.courrierContacts.filter((courrierContact: CourrierContact) => {
      return courrierContact.type === 'respondent' && courrierContact.mandator
    }).map((courrierContact: CourrierContact) => {
      return {
        ...courrierContact,
        ...courrierContact.contact
      }
    })
  }

  get applicantContacts (): any[] {
    return this.courrierContacts.filter((courrierContact: CourrierContact) => {
      return courrierContact.type === 'applicant'
    }).map((courrierContact: CourrierContact) => {
      return {
        ...courrierContact,
        ...courrierContact.contact
      }
    })
  }

  get respondentContacts (): any[] {
    return this.courrierContacts.filter((courrierContact: CourrierContact) => {
      return courrierContact.type === 'respondent'
    }).map((courrierContact: CourrierContact) => {
      return {
        ...courrierContact,
        ...courrierContact.contact
      }
    })
  }

  get mandatorContacts (): any[] {
    return this.courrierContacts.filter((courrierContact: CourrierContact) => {
      return courrierContact.mandator
    }).map((courrierContact: CourrierContact) => {
      return {
        ...courrierContact,
        ...courrierContact.contact
      }
    })
  }

  get noMandatorContacts (): any[] {
    return this.courrierContacts.filter((courrierContact: CourrierContact) => {
      return !courrierContact.mandator
    }).map((courrierContact: CourrierContact) => {
      return {
        ...courrierContact,
        ...courrierContact.contact
      }
    })
  }

  get interlocuteurContacts (): any[] {
    return this.courrierContacts.filter((courrierContact: CourrierContact) => {
      return courrierContact.interlocuteur
    }).map((courrierContact: CourrierContact) => {
      return {
        ...courrierContact,
        ...courrierContact.contact
      }
    })
  }

  get showCher (): boolean {
    if ((this.courrier.contact1 != null) && this.courrier.contact1.contact) {
      return this.courrier.contact1.contact.title === 'Maître'
    } else {
      return false
    }
  }

  get courthouseNom (): string {
    return safeGet(this.legalFolder$, ['courthouse', 'name'])
  }

  get courthouseAddress (): string {
    return safeGet(this.legalFolder$, ['courthouse', 'address'])
  }

  get meetingDateSite (): string {
    const meetingDateSite: Date = this.legalFolder$.meetingDateSite
    return meetingDateSite != null ? new Date(meetingDateSite).toLocaleDateString('fr-fr', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : ''
  }

  get meetingHour (): string {
    return this.legalFolder$.meetingHour.replace(':', 'H')
  }

  get dateProrogation (): string {
    const dateProrogation: Date = this.legalFolder$.dateOfSimulation
    return dateProrogation != null ? new Date(dateProrogation).toLocaleDateString('fr-fr', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : ''
  }

  get dateOfDecision (): string {
    return this.legalFolder$.dateOfDecision != null ? localeDateStr(this.legalFolder$.dateOfDecision) : ''
  }

  get natureOfDecision (): NatureDecision | undefined {
    return this.natureDecisions$.find((natureDecision: NatureDecision) => natureDecision.id === this.legalFolder$.natureOfDecision)
  }

  get firstAddress (): string {
    const firstAddress = this.legalFolder$.addresses[0]
    if (firstAddress) {
      return `${String(firstAddress.buildingNumber)} ${String(firstAddress.street)} ${String(firstAddress.zip)} ${String(firstAddress.city)}`
    } else {
      return ''
    }
  }

  get firstApplicantContactWithMandator (): CourrierContact | undefined {
    return this.courrierContacts.find((courrierContact: CourrierContact) => {
      return courrierContact.type === 'applicant' && courrierContact.mandator
    })
  }

  get doc8ShowCher (): boolean {
    return safeGet(this.courrier, ['contact1', 'contact', 'title']) === 'Maître' || safeGet(this.courrier, ['contact2', 'contact', 'title']) === 'Maître'
  }

  get doc8ShowMonsieurMadame (): boolean {
    return safeGet(this.courrier, ['contact1', 'contact', 'contactType']) || safeGet(this.courrier, ['contact2', 'contact', 'contactType'])
  }

  // honoraires data
  get dateOfFinalReportDeposit (): string {
    return this.legalFolder$.dateOfFinalReportDeposit != null ? localeDateStr(this.legalFolder$.dateOfFinalReportDeposit) : ''
  }

  sumData (key: string): number {
    return this.legalFolder$.modification.getSum(key)
  }

  priceData (key: string): number {
    const price = safeGet(this.legalFolder$, ['modification', 'price'])
    return price[key]
  }

  modificationCharge (key: string): number {
    return this.legalFolder$.modification.getPrice(key)
  }

  fraisAmountData (key: string): number {
    const amount = safeGet(this.legalFolder$, ['costs', 'amount'])
    return amount[key]
  }

  fraisPriceData (key: string): number {
    const price = safeGet(this.legalFolder$, ['costs', 'price'])
    return price[key]
  }

  fraisCharge (key: string): number {
    return this.legalFolder$.costs.getPrice(key)
  }

  get consignationPayments (): any[] {
    return this.billing$.consignationPayments.map((consignationPayment: ConsignationPayment) => {
      const item = {
        name: '',
        amountValue: consignationPayment.amount,
        amount: convertFrNumberEuro(consignationPayment.amount),
        date: consignationPayment.date != null ? localeDateStr(new Date(consignationPayment.date)) : '',
        dateOfDecision: this.dateOfDecision
      }
      if (consignationPayment.contactId === 'billing_courthouse_select') {
        item.name = (this.legalFolder$.courthouse != null) ? this.legalFolder$.courthouse.name : ''
      } else {
        const contact = this.billing$.availableContacts.find((availableContact: Contact) => availableContact.id === consignationPayment.contactId)
        item.name = (contact != null) ? contact.nom + ' ' + contact.prenom : ''
      }
      return item
    })
  }
}
