
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Billing, billingService } from '@/services/billing.service'
import { quoteService, Quote, QuoteMission } from '@/services/quote.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import { MISSION_EXPERT_OPTIONS, MISSION_LOCAL_OPTIONS } from '@/services/constants.service'
import FormattedFloat from '../utils/FormattedFloat.vue'
import { tap } from 'rxjs/operators'
// The @Component decorator indicates the class is a Vue component
@Component({

  subscriptions (this: any) {
    return {
      billing$: billingService.onChangeBilling,
      quote$: quoteService.onChangeQuote.pipe(tap((res: any) => {
        res.missions.forEach((item: any) => {
          /* delete billing mission if quote mission is not in option equal true
            https://app.asana.com/0/1169730782888125/1194855983270265/f
           */
          const missionIndex = this.billing$.missions.findIndex((c: any) => c.number === item.number)
          if (!item.option && missionIndex !== -1) {
            this.billing$.missions.splice(missionIndex, 1)
            return
          }

          let mission = null
          if (missionIndex !== -1) {
            mission = this.billing$.missions[missionIndex]
          }

          if (mission) {
            mission.amount = item.amount
            mission.expertType = item.expertType
            mission.localType = item.localType
          }
          if (item.option) {
            if (!mission) {
              const newMission = JSON.parse(JSON.stringify(item))
              newMission.option = false
              this.billing$.missions.push(newMission)
            } else {
              mission.expertType = item.expertType
              mission.localType = item.localType
              mission.amount = item.amount
            }
          }
        })
      })),
      user$: userService.onChange
    }
  },
  components: {
    FormattedFloat
  }
})

export default class QuoteMissions extends Vue {
  @Prop({ default: false }) disabled: boolean

  quote$: Quote = new Quote()
  user$: User = new User()
  billing$: Billing = new Billing()
  filteredExpertOptions = MISSION_EXPERT_OPTIONS
  filteredLocalOptions = MISSION_LOCAL_OPTIONS

  get updateable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.BILLING_SECTION_UPDATE) && !this.disabled
  }

  get validateMissions (): [] {
    return this.billing$.missions.reduce((tab: any, mission: QuoteMission) => {
      if (this.quote$.missions.find((c) => c.number === mission.number)) {
        tab.push(mission)
        return tab
      }
      return tab
    }, []).sort((a: QuoteMission, b: QuoteMission) => {
      if (a.number < b.number) {
        return -1
      } else {
        return 1
      }
    })
  }

  get missions (): [] {
    if (this.billing$.missions.length === 0 || !this.billing$.missions.length) {
      billingService.initMissionBilling()
    }
    return this.billing$.missions.reduce((tab: any, mission: QuoteMission) => {
      if (this.quote$.missions.find((c) => c.number === mission.number)) {
        return tab
      }
      tab.push(mission)
      return tab
    }, []).sort((a: QuoteMission, b: QuoteMission) => {
      if (a.number < b.number) {
        return -1
      } else {
        return 1
      }
    })
  }

  filterExpertOptions (query: string) {
    this.filteredExpertOptions = billingService.filterOptions(query, 19, MISSION_EXPERT_OPTIONS)
  }

  filterLocalOptions (query: string) {
    this.filteredLocalOptions = billingService.filterOptions(query, 31, MISSION_LOCAL_OPTIONS)
  }

  deleteMission (index: number): void {
    billingService.deleteMission(index)
  }

  addMission (): void {
    billingService.addMission(this.billing$.missions.length - 1)
  }

  changeExpertType (): void {
    // todo
  }

  changeLocalType (): void {
    // todo
  }

  changeAmount (): void {
    billingService.updateMission()
  }

  changeOption (): void {
    billingService.updateMission()
  }
}
