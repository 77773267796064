import { apiClient } from '@/services/api.client'
import { BehaviorSubject, Observable } from 'rxjs'

export class ContactAddress {
  id: string
  contactId: string
  type: string
  buildingNumber: string
  street: string
  city: string
  zipCode: string
  address: string
  isBilling: boolean
  constructor (data?: any) {
    this.id = (data && data.id) || ''
    this.contactId = (data && data.contactId) || ''
    this.street = (data && data.street) || ''
    this.type = (data && data.type) || ''
    this.buildingNumber = (data && data.buildingNumber) || ''
    this.city = (data && data.city) || ''
    this.zipCode = (data && data.zipCode) || ''
    this.address = this.getAddressLabel(data)
    this.isBilling = (data && data.isBilling) || false
  }

  getAddressLabel (data: any = {}): string {
    let label: string = ''
    if (data.buildingNumber) {
      label += String(data.buildingNumber) + ' '
    }
    if (data.street) {
      label += String(data.street) + ', '
    }
    if (data.zipCode) {
      label += String(data.zipCode) + ' '
    }
    if (data.city) {
      label += String(data.city)
    }
    return label
  }
}
class ContactAddressService {
  private readonly _contactAddress: BehaviorSubject<ContactAddress | any> = new BehaviorSubject<ContactAddress | any>({})
  private readonly _contactAddresses: BehaviorSubject<ContactAddress[]> = new BehaviorSubject<ContactAddress[]>([])
  private readonly _allContactAddress: BehaviorSubject<ContactAddress[]> = new BehaviorSubject<ContactAddress[]>([])
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  public onChangeContactAddress: Observable<ContactAddress | any> = this._contactAddress.asObservable()
  public onChange: Observable<ContactAddress[]> = this._contactAddresses.asObservable()
  public onChangeAllContactAddress: Observable<ContactAddress[]> = this._allContactAddress.asObservable()
  public onLoading: Observable<boolean> = this._loading.asObservable()

  public initContactAddress (): void {
    this._contactAddresses.next([new ContactAddress()])
  }

  public async getContactAddresses (contactId: string): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('contact-addresses/' + contactId).then(res => {
      if (res.data) {
        const contactAddresses = res.data.map((item: any) => {
          return new ContactAddress({
            ...item,
            contactId: item.contact.id
          })
        })
        this._contactAddresses.next(contactAddresses)
        this._loading.next(false)
        return res.data
      }
    })
  }

  public async getContactAddressById (id: number): Promise<void> {
    this._loading.next(true)
    return await apiClient.get('contact-address/' + id.toString()).then(res => {
      if (res.data) {
        this._loading.next(false)
        return res.data
      }
    })
  }

  public async getAllContactAddress (): Promise<void> {
    this._loading.next(true)
    return await apiClient.get('contact-address').then(res => {
      if (res.data) {
        this._allContactAddress.next(res.data)
        this._loading.next(false)
        return res.data
      }
    })
  }

  public async deleteAddress (addressSetId: string): Promise<void> {
    this._loading.next(true)
    await apiClient.delete('contact-address/' + addressSetId).then(res => {
      if (res.data) {
        this._contactAddress.next(new ContactAddress(res.data))
      }
      this._loading.next(false)
    })
  }

  public async saveContactAddress (id: any, contactId: string, type: string, buildingNumber: string, street: string, city: string, zipCode: string, isBilling: boolean): Promise<void> {
    await apiClient.post('contact-address', { id, contactId, type, buildingNumber, street, city, zipCode, isBilling }).then(res => {
      this._contactAddress.next(new ContactAddress(res.data))
    })
  }
}

export const contactAddressService = new ContactAddressService()
