<template>
  <div class="result-list-view h-100">
    <div class="result-list-th-container">
      <div class="t-w-40 result-list-th" />
      <template v-if="['quote', 'folder'].includes(currentTab)">
        <div class="t-w-90 result-list-th">
          Nom
        </div>
        <div class="result-list-th">
          Client
        </div>
        <div class="t-w-90 result-list-th t-w-100">
          Status
        </div>
        <div class="result-list-th t-w-150">
          Expertise
        </div>
        <div class="result-list-th">
          Adresse
        </div>
      </template>
      <template v-else-if="currentTab === 'ged'">
        <div class="result-list-th">
          Nom
        </div>
        <div class="result-list-th t-w-100">
          Source
        </div>
        <div class="result-list-th t-w-100">
          Date de création
        </div>
        <div class="result-list-th t-w-100">
          Date source
        </div>
        <div class="result-list-th t-w-90">
          Theme
        </div>
        <div class="result-list-th t-w-100">
          Tags
        </div>
        <div class="result-list-th">
          Adresse
        </div>
      </template>
      <template v-else-if="currentTab === 'contact'">
        <div class="result-list-th">
          Nom
        </div>
        <div class="result-list-th t-w-150">
          Email
        </div>
        <div class="result-list-th t-w-150">
          Téléphone
        </div>
        <div class="result-list-th">
          Adresse
        </div>
      </template>
      <template v-else-if="currentTab === 'reference'">
        <div class="result-list-th t-w-120">
          ID
        </div>
        <div class="result-list-th t-w-100">
          Date
        </div>
        <div class="result-list-th t-w-100">
          Type de local
        </div>
        <div class="result-list-th t-w-100">
          Type de transaction
        </div>
        <div class="result-list-th t-w-100">
          Surface
        </div>
        <div class="result-list-th t-w-100">
          Prix
        </div>
        <div class="result-list-th">
          Adresse
        </div>
      </template>
      <template v-else-if="currentTab === 'fodegi'">
        <div class="result-list-th">
          ID
        </div>
        <div class="result-list-th">
          Adresse
        </div>
      </template>
      <template v-else>
        <div class="result-list-th">
          Nom
        </div>
        <div class="result-list-th">
          Adresse
        </div>
      </template>
      <div class="t-w-40 result-list-th" />
    </div>
    <RecycleScroller
      v-slot="{ item, index }"
      ref="virtualscroller"
      class="h-100"
      :items="formatedData"
      :item-size="50"
      :grid-items="1"
      item-class="result-list-td-container"
      :emit-update="true"
      @update="setLoading()">
      <div style="height: 40px;">
        <all-result-item-list
          v-if="currentTab === 'all'"
          :result="item"
          :is-detail="isDetail"
          :is-result-assoc="isResultAssoc"
          @doubleShowMiniature="showMiniature"
          @doubleGetResultsAssocies="getResultsAssocies"
          @doubleHandleOpenDetail="handleOpenDetail"
          @doublePin="pinResult"
          @click="focusMap(item)" />
        <ged-result-item-list
          v-else-if="item.objectType === 'ged'"
          :result="item"
          :is-detail="isDetail"
          :is-result-assoc="isResultAssoc"
          @doubleShowMiniature="showMiniature"
          @doubleGetResultsAssocies="getResultsAssocies"
          @doubleHandleOpenDetail="handleOpenDetail"
          @doublePin="pinResult"
          @click="focusMap(item)" />
        <ref-result-item-list
          v-else-if="item.objectType === 'reference'"
          :result="item"
          :is-detail="isDetail"
          :is-result-assoc="isResultAssoc"
          @doubleShowMiniature="showMiniature"
          @doubleGetResultsAssocies="getResultsAssocies"
          @doubleHandleOpenDetail="handleOpenDetail"
          @doublePin="pinResult"
          @click="focusMap(item)" />
        <contact-result-item-list
          v-else-if="item.objectType === 'contact'"
          :result="item"
          :is-detail="isDetail"
          :is-result-assoc="isResultAssoc"
          @doubleShowMiniature="showMiniature"
          @doubleGetResultsAssocies="getResultsAssocies"
          @doubleHandleOpenDetail="handleOpenDetail"
          @doublePin="pinResult"
          @click="focusMap(item)" />
        <folder-result-item-list
          v-else-if="item.objectType === 'quote' || item.objectType === 'settled-folder' || item.objectType === 'legal-folder'"
          :index="index"
          :result="item"
          :is-detail="isDetail"
          :is-result-assoc="isResultAssoc"
          @doubleShowMiniature="showMiniature"
          @doubleGetResultsAssocies="getResultsAssocies"
          @doubleHandleOpenDetail="handleOpenDetail"
          @doublePin="pinResult"
          @click="focusMap(item)" />
        <fodegi-result-item-list
          v-else-if="item.objectType === 'fodegi'"
          :key="`fodegi-${index}`"
          :result="item"
          @doublePin="pinResult"
          @click="focusMap(item)" />
      </div>
    </RecycleScroller>
  </div>
</template>
<script>
import {
  QUOTE_STATUES,
  SETTED_FOLDER_STATUES,
  LEGAL_FOLDER_STATUES,
  LOCAL_TYPE
} from '@/services/constants.service'
import { listReferenceService } from '@/services/listReference.service'
import { globalSearchService } from '@/services/globalSearch.service'
import {
  getTransactionTypeLabel,
  getExpertiseTypeLabels,
  numberWithSpaces
} from '@/utils/functions'
import { searchGlobalItemsService } from '@/services/searchGlobalItems.service'
import RefResultItemList from '@/components/search/result/RefResultItemList.vue'
import ContactResultItemList from '@/components/search/result/ContactResultItemList.vue'
import GedResultItemList from '@/components/search/result/GedResultItemList.vue'
import FolderResultItemList from '@/components/search/result/FolderResultItemList.vue'
import FodegiResultItemList from '@/components/search/result/FodegiResultItemList.vue'
import AllResultItemList from '@/components/search/result/AllResultItemList.vue'
export default {
  components: { RefResultItemList, ContactResultItemList, GedResultItemList, FolderResultItemList, FodegiResultItemList, AllResultItemList },
  subscriptions () {
    return {
      listReferences$: listReferenceService.onChangeListReferences
    }
  },
  props: {
    results: {
      type: Array,
      required: true
    },
    currentTab: {
      type: String,
      required: true
    },
    isResultAssoc: {
      type: Boolean,
      required: false
    },
    isDetail: {
      type: Boolean,
      required: false
    }
  },
  emits: ['togglePin', 'detail', 'result', 'miniature'],
  computed: {
    resultsWithId () {
      return this.results.map(r => ({
        id: this.getId(r),
        ...r
      })
      )
    },
    formatedData () {
      return this.results.map((item) => {
        if (item.objectType === 'ged') {
          return {
            id: 'ged-' + item.ged.id,
            objectType: item.objectType,
            name: item.ged.name,
            adresse: item.ged.adresses.adresse,
            source: item.ged.source,
            createdAt: item.createdAt,
            dateStart: item.ged.dateStart,
            theme: item.ged.theme,
            tags: item.ged.tags,
            isPin: item.isPin ? item.isPin : false,
            item
          }
        }
        if (item.objectType === 'quote') {
          return {
            id: 'quote-' + item.folder.id,
            objectType: item.objectType,
            name: item.folder.name,
            adresse: this.getFirstAdresse(item.folder.adresses),
            client: (item.folder.contacts && item.folder.contacts[0]) ? item.folder.contacts[0].contactName : '',
            status: this.foldersStatus(
              item.objectType,
              item.folder.status
            ),
            expertiseType: getExpertiseTypeLabels(item.folder.expertiseType),
            isPin: item.isPin ? item.isPin : false,
            totalHT: item.folder.totalHT,
            item
          }
        }
        if (item.objectType === 'legal-folder' || item.objectType === 'settled-folder') {
          return {
            id: item.objectType + '-' + item.folder.id,
            objectType: item.objectType,
            name: item.folder.name,
            client: (item.folder.contacts && item.folder.contacts[0]) ? item.folder.contacts[0].contactName : '',
            status: this.statusLabel(item),
            expertiseType: getExpertiseTypeLabels(item.folder.expertiseType),
            adresse: this.getFirstAdresse(item.folder.adresses),
            isPin: item.isPin ? item.isPin : false,
            item
          }
        }
        if (item.objectType === 'contact') {
          return {
            id: 'contact-' + item.contact.id,
            objectType: item.objectType,
            name: item.contact.firstname + ' ' + item.contact.lastname,
            adresse: this.getFirstAdresse(item.contact.adresses),
            email: item.contact.email,
            phoneNumber: item.contact.phoneNumber,
            isPin: item.isPin ? item.isPin : false,
            item
          }
        }
        if (item.objectType === 'reference') {
          return {
            id: 'reference-' + item.reference.id,
            objectType: item.objectType,
            name: item.reference.ficheId,
            adresse: item.reference.adresse,
            createdAt: item.createdAt,
            typeLocal: item.reference.typeLocal,
            typeTransaction: item.reference.typeTransaction,
            phoneNumber: item.reference.phoneNumber,
            dateEffetBailStart: item.reference.dateEffetBailStart,
            dateVenteStart: item.reference.dateVenteStart,
            surface: numberWithSpaces(Number(item.reference.surfaceP).toFixed(1)) + ' m2 P' ||
              numberWithSpaces(Number(item.reference.surfaceM2).toFixed(1)) + ' m2',
            price:
                item.reference.typeTransaction === 1
                  ? numberWithSpaces(Number(item.reference.loyerP).toFixed(1)) + ' €/m2 P' || numberWithSpaces(Number(item.reference.loyerM2).toFixed(1)) + ' €/m2'
                  : numberWithSpaces(Number(item.reference.prixM2).toFixed(1)) + ' €/m2' || numberWithSpaces(Number(item.reference.prixHd).toFixed(1)) + ' € HD',
            isPin: item.isPin ? item.isPin : false,
            item
          }
        }
        if (item.objectType === 'fodegi') {
          return {
            id: 'fodegi-' + item.fodegi.id,
            objectType: item.objectType,
            adresse: item.fodegi.adresse
          }
        }

        return {}
      })
    }
  },
  methods: {
    handleCurrentChange (val) {
      this.currentRow = val
    },
    pinResult (result) {
      const id = Number(result.id.substring(result.id.lastIndexOf('-') + 1))
      this.$emit('togglePin', { type: result.objectType, id, isPin: result.isPin })
    },
    foldersStatus (folderType, status) {
      if (folderType === 'quote') {
        return QUOTE_STATUES.filter((item) => item.value === status)[0]
          .label
      } else {
        return SETTED_FOLDER_STATUES.filter(
          (item) => item.value === status
        )[0].label
      }
    },
    localType (value) {
      if (!value) {
        return ''
      }
      return LOCAL_TYPE.filter((item) => item.value === value)[0]
        .label
    },
    getFirstAdresse (adresses) {
      if (adresses && adresses[0]) {
        return (
          adresses[0].label
        )
      }
      return ''
    },
    statusLabel (result) {
      let folderStatus = null
      if (result.objectType === 'quote') {
        folderStatus = QUOTE_STATUES.find(status => status.value === result.folder.status)
      } else if (result.objectType === 'legal-folder') {
        folderStatus = LEGAL_FOLDER_STATUES.find(status => status.value === result.folder.status)
      } else {
        folderStatus = SETTED_FOLDER_STATUES.find(status => status.value === result.folder.status)
      }
      return folderStatus ? folderStatus.label : ''
    },
    async focusMap (result) {
      searchGlobalItemsService.changeActiveId(result.id)
      const adresse = result.adresse.trim()
      if (adresse !== '') {
        this.$emit('changeView', { address: adresse, id: result.id })
      }
    },
    showMiniature (data) {
      const detail = true
      const result = data.item
      this.$emit('miniature', { detail, result })
      if (this.isResultAssoc) this.getResultsAssocies(data)
    },
    handleResult (showAssoc, resultsAssocies) {
      this.$emit('result', { showAssoc, resultsAssocies })
    },
    handleOpenDetail (data) {
      const result = data.item
      this.$emit('detail', result)
    },
    getTransactionTypeLabel (value) {
      return getTransactionTypeLabel(value, this.listReferences$)
    },
    getResultsAssocies (data) {
      const result = data.item
      switch (result.objectType) {
        case 'contact':
          globalSearchService.getAssociatedResultsContact(result.contact.id).then(res => {
            this.handleResult(true, res)
          })
          break
        case 'ged':
          globalSearchService.getAssociatedResultsGed(result.ged.adresses.label).then(res => {
            this.handleResult(true, res)
          })
          break
        case 'reference':
          globalSearchService.getAssociatedResultsReference(result.reference.adresse).then(res => {
            this.handleResult(true, res)
          })
          break
        default:
          if (result.folder.adresses.length > 0) {
            globalSearchService.getAssociatedResultsFolder(result.folder.adresses[0].label).then(res => {
              this.handleResult(true, res)
            })
          }
          break
      }
      const detail = true
      this.$emit('miniature', { detail, result })
    },
    getId (result) {
      if (result.objectType === 'contact') {
        return 'contact-' + result.contact.id
      } else if (result.objectType === 'reference') {
        return 'reference-' + result.reference.id
      } else if (result.objectType === 'quote' || result.objectType === 'settled-folder' || result.objectType === 'legal-folder') {
        return 'folder-' + result.folder.id
      } else if (result.objectType === 'ged' && result.ged.adresses && result.ged) {
        return 'ged-' + result.ged.id
      }
      return ''
    },
    findInList (id) {
      const i = this.formatedData.findIndex((element) => element.id === id)
      if (i >= 0) {
        this.$refs.virtualscroller.scrollToItem(i)
        searchGlobalItemsService.changeActiveId(id)
      }
    },
    setLoading () {
      searchGlobalItemsService.setIsLoading(false)
    }
  }
}
</script>

<style>
.el-dropdown-menu__item {
  padding: 0 10px !important;
}
.vue-recycle-scroller__item-view {
  width: 100% !important;
}
</style>
