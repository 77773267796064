<template>
  <ul v-loading="loading$">
    <li
      v-for="result in associedContacts"
      :key="result.id"
      :class="[{ active: (result.contact.id === activeId) }, 'contact-color']"
      @click="selectResult(result.contact.id)">
      <div class="d-flex align-items-center justify-content-start list-result-assicie-top">
        <figure>
          <i class="rb-person_black" />
        </figure>
        <div>{{ result.contact.firstname }} {{ result.contact.lastname }}</div>
      </div>
      <div class="detail-result">
        <div class="d-flex align-items-start justify-content-start">
          <i class="rb-message-alt" /> {{ result.contact.email }}
        </div>
        <div class="d-flex align-items-start justify-content-start">
          <i class="rb-contact-filled" /> {{ result.contact.phoneNumber }}
        </div>
        <div
          v-for="address in result.contact.adresses"
          :key="address"
          class="d-flex align-items-start justify-content-start">
          <i class="rb-marqueur" />
          {{ address.buildingnumber }}
          {{ address.street }}
          {{ address.city }}
          {{ address.zipcode }}
        </div>
      </div>
      <div class="btn-container mt-3 mb-3 grey">
        <el-button @click="openDetails(result.contact.id, result.objectType)">
          <i class="rb-visibility1" />
        </el-button>
      </div>
    </li>

    <li
      v-for="result in associedFolders"
      :key="result.id"
      :class="[{ active: (result.folder.id === activeId) }, 'dossier-color']"
      @click="selectResult(result.folder.id)">
      <div class="d-flex align-items-center justify-content-start list-result-assicie-top">
        <figure>
          <i class="rb-dossier-alt" />
        </figure>
        <div>{{ result.folder.name }}</div>
      </div>
      <div class="detail-result">
        <div
          v-for="addresse in result.folder.adresses"
          :key="addresse"
          class="d-flex align-items-start justify-content-start">
          <i class="rb-marqueur" />
          {{ addresse.buildingnumber }}
          {{ addresse.street }}
          {{ addresse.city }}
          {{ addresse.zipcode }}
        </div>
        <div
          v-for="missionType in result.folder.missionType"
          :key="missionType"
          class="d-flex align-items-start justify-content-start">
          <i class="rb-ged-alt" /> <span> {{ missionType.label }} </span>
        </div>
        <div
          v-for="expertiseType in result.folder.expertiseType"
          :key="expertiseType"
          class="d-flex align-items-start justify-content-start">
          <i class="rb-devis-alt" /> {{ expertiseType.label }}
        </div>
        <div
          v-for="localType in result.folder.localType"
          :key="localType"
          class="d-flex align-items-start justify-content-start">
          <i class="rb-apartment_black" /> {{ localType.label }}
        </div>
      </div>
      <div class="btn-container mt-3 mb-3 grey">
        <el-button>
          <i class="rb-visibility1" />
        </el-button>
        <el-button @click="openDetails(result.folder.id, result.objectType)">
          <i class="rb-open-new-alt" />
        </el-button>
      </div>
    </li>

    <li
      v-for="(result, index) in associedGeds"
      :key="result.id"
      :class="[{ active: (result.id === activeId) }, 'ged-color']"
      @click="selectResult(result.id)">
      <div class="d-flex align-items-center justify-content-start list-result-assicie-top">
        <figure>
          <i class="rb-ged-alt" />
        </figure>
        <div>{{ result.ged.name }}</div>
      </div>
      <div class="detail-result">
        <div class="d-flex align-items-start justify-content-start">
          <i class="rb-devis-alt" />
          <div>{{ result.ged.theme }}</div>
        </div>
      </div>
      <div class="detail-result">
        <div class="d-flex align-items-start justify-content-start">
          <i class="rb-open-new-alt" />
          <div>
            <span
              v-for="(tag, tagIndex) in result.ged.tags"
              :key="tagIndex">
              {{ tag }}
              <span v-if="tagIndex < result.ged.tags - 1">, </span>
            </span>
          </div>
        </div>
      </div>
      <div class="detail-result">
        <div class="d-flex align-items-center justify-content-start">
          <i class="rb-marqueur" />
          <div>
            <span>
              {{ result.ged.adresses.housenumber }}
              {{ result.ged.adresses.street }}
              {{ result.ged.adresses.city }}
              {{ result.ged.adresses.postalcode }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="result.ged.linkedFolderName.length > 0"
        class="detail-result">
        <div class="d-flex align-items-center justify-content-start">
          <i class="rb-dossier-alt" />
          <div>
            <span
              v-for="(folderName, linkedFolderNameIndex) in result.ged.linkedFolderName"
              :key="linkedFolderNameIndex">
              {{ folderName }}
              <span v-if="index < result.ged.linkedFolderName.length">, </span>
            </span>
          </div>
        </div>
      </div>
      <div class="detail-result">
        <div class="d-flex align-items-center justify-content-start">
          <i class="rb-ged-alt" />
          <div>{{ result.ged.type }}</div>
        </div>
      </div>
      <div class="detail-result">
        <div class="d-flex align-items-start justify-content-start">
          <i class="rb-apartment_black" />
          {{ result.ged.source }}
        </div>
      </div>
      <div class="detail-result">
        <div class="d-flex align-items-start justify-content-start">
          <i class="rb-calendrier" />
          {{ result.ged.dateStart }} - {{ result.ged.dateEnd }}
        </div>
      </div>
      <div class="btn-container mt-3 mb-3 grey">
        <el-button>
          <i class="rb-visibility1" />
        </el-button>
      </div>
    </li>

    <li
      v-for="result in associedReferences"
      :key="result.id"
      :class="[{ active: (result.id === activeId) }, 'ref-color']"
      @click="selectResult(result.id)">
      <div class="d-flex align-items-center justify-content-start list-result-assicie-top">
        <figure>
          <i class="rb-ref" />
        </figure>
        <div>{{ result.reference.adresse }}</div>
      </div>
      <div class="detail-result">
        <div class="d-flex align-items-start justify-content-start">
          <i class="rb-apartment_black" />
          {{ typeLocalName(result.reference.typeLocal) }}
        </div>
      </div>
      <div class="detail-result">
        <div class="d-flex align-items-start justify-content-start">
          <i class="rb-euro_black" />
          {{ typeTransactionName(result.reference.typeTransaction) }}
        </div>
      </div>
      <div class="detail-result">
        <div class="d-flex align-items-start justify-content-start">
          <i class="rb-open-new-alt" />
          {{ typeValeurName(result.reference.typeValeur) }}
        </div>
      </div>
      <div
        v-if="result.reference.activites"
        class="detail-result">
        <div class="d-flex align-items-start justify-content-start">
          <i class="rb-devis-alt" />
          {{ activityName(result.reference.activites) }}
        </div>
      </div>
      <div
        v-if="result.reference.source"
        class="detail-result">
        <div class="d-flex align-items-start justify-content-start">
          <i class="rb-apartment_black" />
          {{ result.reference.source }}
        </div>
      </div>
      <div class="btn-container mt-3 mb-3 grey">
        <el-button @click="openDetails(result.reference.id, result.objectType)">
          <i class="rb-visibility1" />
        </el-button>
      </div>
    </li>
  </ul>
</template>
<script>
import { listReferenceService } from '@/services/listReference.service'
import { globalSearchService } from '@/services/globalSearch.service'
export default {
  subscriptions () {
    return {
      loading$: globalSearchService.onLoading
    }
  },
  props: {
    results: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      activeId: 0,
      typeLocalList: [],
      typeValeurList: [],
      activityList: [],
      transactionList: []
    }
  },
  computed: {
    associedContacts () {
      if (this.results) {
        return this.results.items.filter(
          (item) => item.objectType === 'contact'
        )
      }

      return []
    },
    associedFolders () {
      return this.results.items.filter(
        (item) => item.objectType === 'quote' || item.objectType === 'settled-folder' || item.objectType === 'legal-folder'
      )
    },
    associedGeds () {
      return this.results.items.filter(
        (item) => item.objectType === 'ged'
      )
    },
    associedReferences () {
      return this.results.items.filter(
        (item) => item.objectType === 'reference'
      )
    }
  },
  created () {
    listReferenceService.getListReference().then(res => {
      this.typeLocalList = res.filter((item) => item.type === 1)
      this.activityList = res.filter((item) => item.type === 3)
      this.typeValeurList = res.filter((item) => item.type === 11)
      this.transactionList = res.filter((item) => item.type === 7 || item.type === 4)
    })
  },
  methods: {
    selectResult (id) {
      this.activeId = id
    },
    openDetails (id, type) {
      if (type === 'contact') {
        window.open(this.$router.resolve({ path: '/contact/' + id }).href, '_blank')
      } else if (type === 'quote') {
        window.open(this.$router.resolve({ path: '/devis/' + id }).href, '_blank')
      } else if (type === 'settled-folder') {
        window.open(this.$router.resolve({ path: '/dossier-amiable/' + id }).href, '_blank')
      } else if (type === 'legal-folder') {
        window.open(this.$router.resolve({ path: '/dossier-judiciaire/' + id }).href, '_blank')
      } else if (type === 'reference') {
        window.open(this.$router.resolve({ path: '/edit-ref/' + id }).href, '_blank')
      }
    },

    typeTransactionName (typeTransactionNum) {
      if (typeTransactionNum) {
        const typeLocal = this.typeLocalList.find((item) => item.num === typeTransactionNum)
        return typeLocal ? typeLocal.label : ''
      } else {
        return ''
      }
    },

    typeLocalName (typeLocalNum) {
      if (typeLocalNum) {
        const typeLocal = this.typeLocalList.find((item) => item.num === typeLocalNum)
        return typeLocal ? typeLocal.label : ''
      } else {
        return ''
      }
    },

    typeValeurName (typeValeurNum) {
      if (typeValeurNum) {
        const typeValeur = this.typeValeurList.find((item) => item.num === typeValeurNum)
        return typeValeur ? typeValeur.label : ''
      } else {
        return ''
      }
    },

    activityName (activityNum) {
      if (activityNum) {
        const activity = this.activityList.find((item) => item.num === activityNum)
        return activity ? activity.label : ''
      } else {
        return ''
      }
    }
  }
}
</script>
