
import { Component, Vue } from 'vue-property-decorator'
import { fileService, Category } from '@/services/file.service'

@Component({
  subscriptions (this: any) {
    return {
      category$: fileService.onChangeCategory
    }
  }
})
export default class FormCategory extends Vue {
  category$: Category = new Category()
  active: boolean = false
  rules: any = {
    name: [
      {
        required: true,
        message: 'Le nom de la sous-categorie est obligatoire',
        trigger: 'change',
        suppressWarning: false
      }
    ]
  }

  open (): void {
    this.active = true
    this.$nextTick(() => {
      (this.$refs.input as any).focus();
      (this.$refs.categoryForm as any).resetFields()
    })
  }

  close (): void {
    this.active = false
  }

  validate (): Promise<boolean> {
    return new Promise((resolve: any) => {
      (this.$refs.categoryForm as any).validate((valid: boolean) => {
        resolve(valid)
      })
    })
  }

  async handleSave (): Promise<void> {
    const isValid: Boolean = await this.validate()
    if (isValid) {
      this.$emit('submit', this.category$)
    }
  }
}
