import { apiClient } from './api.client'
import { BehaviorSubject, Observable } from 'rxjs'
import { authService } from './auth.service'
import { generateTypeLocalGroup } from '@/utils/functions'
import { USER_RIGHTS, userService } from '@/services/user.service'

export class SummaryData {
  locative: number
  venales: number
  deviction: number
  others: number
  legalFolder: number
  total: number
  CAByMonth: any
  CACurrentYear: any
  CALastYear: any
  CACumulatedCurrentYear: any
  CACumulatedLastYear: any
  validatedQuoteCurrentYear: any
  validatedQuoteLastYear: any
  createdQuoteCurrentYear: any
  createdQuoteLastYear: any
  validateQuoteRateCurrentYear: any
  validateQuoteRateLastYear: any
  billedFolderCurrentYear: any
  billedFolderLastYear: any
  createdRefAndGedCurrentYear: any
  createdRefAndGedPreviousYear: any

  constructor (data?: any) {
    this.locative = (data && data.locative) || 0
    this.venales = (data && data.venales) || 0
    this.deviction = (data && data.deviction) || 0
    this.others = (data && data.others) || 0
    this.legalFolder = (data && data.legalFolder) || 0
    this.total = (data && data.total) || 0
    this.CAByMonth = (data && data.CAByMonth) || {}
    this.CACurrentYear = (data && data.CACurrentYear) || {}
    this.CALastYear = (data && data.CALastYear) || {}
    this.CACumulatedCurrentYear = (data && data.CACumulatedCurrentYear) || {}
    this.CACumulatedLastYear = (data && data.CACumulatedLastYear) || {}
    this.validatedQuoteCurrentYear = (data && data.validatedQuoteCurrentYear) || {}
    this.validatedQuoteLastYear = (data && data.validatedQuoteLastYear) || {}
    this.createdQuoteCurrentYear = (data && data.createdQuoteCurrentYear) || {}
    this.createdQuoteLastYear = (data && data.createdQuoteLastYear) || {}
    this.validateQuoteRateCurrentYear = (data && data.validateQuoteRateCurrentYear) || {}
    this.validateQuoteRateLastYear = (data && data.validateQuoteRateLastYear) || {}
    this.billedFolderCurrentYear = (data && data.billedFolderCurrentYear) || {}
    this.billedFolderLastYear = (data && data.billedFolderLastYear) || {}
    this.createdRefAndGedCurrentYear = (data && data.createdRefAndGedCurrentYear) || {}
    this.createdRefAndGedPreviousYear = (data && data.createdRefAndGedPreviousYear) || {}
  }
}

class DashboardService {
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  private readonly _references: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  private readonly _documents: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  private readonly _summaryData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  private readonly _listReferences: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])

  public onLoading: Observable<boolean> = this._loading.asObservable()
  public onChangeReferences: Observable<any[]> = this._references.asObservable()
  public onChangeDocuments: Observable<any[]> = this._documents.asObservable()
  public onChangeSummaryData: Observable<any[]> = this._summaryData.asObservable()
  public onChangeListReferences: Observable<any[]> = this._listReferences.asObservable()

  public async getLastReferences (): Promise<void> {
    this._loading.next(true)
    await apiClient.get('dashboard/last-references').then(res => {
      if (res.data) {
        this._references.next(res.data)
      }
      this._loading.next(false)
    })
  }

  public async getLastDocuments (): Promise<void> {
    this._loading.next(true)
    await apiClient.get('dashboard/last-documents').then(res => {
      if (res.data) {
        this._documents.next(res.data)
      }
      this._loading.next(false)
    })
  }

  public async getSummaryData (): Promise<void> {
    this._loading.next(true)
    let userId = null
    if ((authService.userValue != null) && authService.userValue.id) {
      userId = authService.userValue.id
    }
    await apiClient.get('dashboard/' + String(userId) + '/summary-data').then(res => {
      if (res.data) {
        if (authService.userValue && authService.userValue.rights && userService.hasRight(authService.userValue, USER_RIGHTS.DASHBOARD_ADMIN)) {
          res.data.validateQuoteRateCurrentYear = this.validatedQuoteRate(res.data.createdQuoteCurrentYear.data, res.data.validatedQuoteCurrentYear.data, '% accepté N')
          res.data.validateQuoteRateLastYear = this.validatedQuoteRate(res.data.createdQuoteLastYear.data, res.data.validatedQuoteLastYear.data, '% accepté N-1')
          res.data.validateQuoteCumulatedRateCurrentYear = this.validatedQuoteRate(this.cumulatedValues(res.data.createdQuoteCurrentYear.data), this.cumulatedValues(res.data.validatedQuoteCurrentYear.data), '% accepté cumulé N')
          res.data.validateQuoteCumulatedRateLastYear = this.validatedQuoteRate(this.cumulatedValues(res.data.createdQuoteLastYear.data), this.cumulatedValues(res.data.validatedQuoteLastYear.data), '% accepté cumulé N-1')
        }
        this._summaryData.next(res.data)
      }
      this._loading.next(false)
    })
    const type: number = 1 // type de local dans la table list_references
    await apiClient.get('list-reference/' + String(type)).then(res => {
      if (res.data) {
        this._listReferences.next(generateTypeLocalGroup(res.data))
      }
      this._loading.next(false)
    })
  }

  private validatedQuoteRate (createdQuote: any, validatedQuote: any, label: string): any {
    const res: any = {}
    res.label = label
    res.data = {}

    Object.keys(createdQuote).forEach(function (key) {
      res.data[key] = Number(createdQuote[key]) === 0 ? 0 : (Number(validatedQuote[key]) * 100) / Number(createdQuote[key])
    })

    return res
  }

  private cumulatedValues (source: any): any {
    const res: any = {}
    let sum = 0

    const temp = Object.keys(source)
    const arraySource = [...temp.slice(3), ...temp.slice(0, 3)]
    arraySource.forEach(function (key) {
      sum += Number(source[key])
      res[key] = sum
    })

    return res
  }
}

export const dashboardService = new DashboardService()
