import { apiClient } from '@/services/api.client'
import { BehaviorSubject, Observable } from 'rxjs'
import { LegalFolder } from '@/models/legalFolder.model'
import { SettledFolder, settledFolderService } from '@/services/settledFolder.service'
import { User } from '@/services/user.service'
import { ReferenceAddress } from '@/services/listReference.service'
import { PageParams } from '@/models/ged.model'
import { legalFolderService } from '@/services/legalFolder.service'
import { AxiosResponse } from 'axios'

export class SearchRef {
  id?: number | null
  legalFolder: LegalFolder | null
  settledFolder: SettledFolder | null
  folder: any | null
  title: string
  criteria: JSON
  result: JSON
  user: User
  typeValeur: number
  typeLocal: number
  activites: number[] | null
  createdAt: string
  userName: string
  resultCount: number

  constructor (searchRef: any = {}) {
    this.id = searchRef.id
    this.folder = searchRef.folder
    this.legalFolder = searchRef.legalFolder
    this.settledFolder = searchRef.settledFolder
    this.title = searchRef.title
    this.criteria = searchRef.criteria
    this.result = searchRef.result
    this.user = searchRef.user
    this.typeValeur = searchRef.typeValeur
    this.typeLocal = searchRef.typeLocal
    this.activites = searchRef.activites
    this.createdAt = searchRef.createdAt
    this.userName = searchRef.userName
    this.resultCount = searchRef.resultCount
  }
}

export class SearchCriteria {
  title: string
  folderName: string
  peliasAddress: ReferenceAddress
  userId: number | null
  dateMini: string
  dateMaxi: string
  typeValeur: number
  typeLocal: number
  activites: number
  constructor (data: any = {}) {
    this.title = data.title || ''
    this.folderName = data.folderName || ''
    this.peliasAddress = new ReferenceAddress(data.peliasAddress)
    this.userId = data.userId || null
    this.dateMini = data.dateMini || ''
    this.dateMaxi = data.dateMaxi || ''
    this.typeValeur = data.typeValeur || null
    this.typeLocal = data.typeLocal || null
    this.activites = data.activites || null
  }
}

export class ScreenshotFile {
  basename: string
  path: string
  constructor (data: any = {}) {
    this.basename = data.basename || ''
    this.path = data.path || ''
  }
}

export class FolderListItem {
  label: string
  options: any[]
  constructor (data: any = {}) {
    this.label = data.label || ''
    this.options = data.options || []
  }
}

class SaveSearchService {
  private readonly _search: BehaviorSubject<SearchRef> = new BehaviorSubject<SearchRef>(new SearchRef())
  private readonly _searches: BehaviorSubject<SearchRef[]> = new BehaviorSubject<SearchRef[]>([])
  private readonly _folders: BehaviorSubject<FolderListItem[]> = new BehaviorSubject<FolderListItem[]>([])
  private readonly _screenshotFiles: BehaviorSubject<ScreenshotFile[]> = new BehaviorSubject<ScreenshotFile[]>([])
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  private readonly _searchCriteria: BehaviorSubject<SearchCriteria> = new BehaviorSubject<SearchCriteria>(new SearchCriteria())
  private readonly _totalSearches: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  private readonly _pageParams: BehaviorSubject<PageParams> = new BehaviorSubject<PageParams>(new PageParams())
  private readonly _filter: BehaviorSubject<string> = new BehaviorSubject<string>('default')

  public onChange: Observable<SearchRef> = this._search.asObservable()
  public onChangeSearches: Observable<SearchRef[]> = this._searches.asObservable()
  public onChangeFolders: Observable<FolderListItem[]> = this._folders.asObservable()
  public onChangeScreenshotFiles: Observable<ScreenshotFile[]> = this._screenshotFiles.asObservable()
  public onChangeTotalSearches: Observable<number> = this._totalSearches.asObservable()
  public onLoading: Observable<boolean> = this._loading.asObservable()
  public onChangeSearchCriteria: Observable<SearchCriteria> = this._searchCriteria.asObservable()
  public onChangePageParams: Observable<PageParams> = this._pageParams.asObservable()
  public onChangeFilter: Observable<string> = this._filter.asObservable()

  get filter (): string {
    return this._filter.getValue()
  }

  get search (): SearchRef {
    return this._search.getValue()
  }

  get searchCriteria (): SearchCriteria {
    return this._searchCriteria.getValue()
  }

  get pageParams (): PageParams {
    return this._pageParams.getValue()
  }

  get folers (): FolderListItem[] {
    return this._folders.getValue()
  }

  public async saveSearch (searchRef: SearchRef): Promise<any> {
    this._loading.next(true)
    await apiClient.post('search', { searchRef }).then(res => {
      if (res.data) {
        const saveSearch = this.setSavedSearch(res)
        this._search.next(saveSearch)
      }
      this._loading.next(false)
    }).catch(() => {
      this._loading.next(false)
    })
  }

  public initDuplicateSearch (data: any): void {
    const search = data
    search.id = null
    search.legalFolderId = null
    search.settledFolderId = null
    search.title = ''
    search.criteria = null
    search.result = null
    search.folderName = null
    search.folderId = null
    this._search.next(search)
  }

  public async duplicateSearch (): Promise<any> {
    return await new Promise((resolve: any, reject: any) => {
      localStorage.setItem('duplicatedSearch', JSON.stringify(this.search))
      resolve('success')
    })
  }

  public setSavedSearch (res: any): any {
    const saveSearch = res.data
    if (saveSearch.legalFolder) {
      const type = 1
      const folder = saveSearch.legalFolder
      saveSearch.folderId = folder.id
      saveSearch.folderName = saveSearch.legalFolder.name
      saveSearch.selectedFolder = {
        type,
        folder
      }
    }
    if (saveSearch.settledFolder) {
      const type = 2
      const folder = saveSearch.settledFolder
      saveSearch.folderId = folder.id
      saveSearch.folderName = saveSearch.settledFolder.name
      saveSearch.selectedFolder = {
        type,
        folder
      }
    }
    return saveSearch
  }

  public setFolders (): void {
    const legalFolders: LegalFolder[] = legalFolderService.folders
    const settledFolders: SettledFolder[] = settledFolderService.folders

    const settledFoldersGroup: FolderListItem = {
      label: 'Dossier amiable',
      options: settledFolders
    }

    const legalFoldersGroup: FolderListItem = {
      label: 'Dossier judiciaire',
      options: legalFolders
    }

    this._folders.next([
      settledFoldersGroup,
      legalFoldersGroup
    ])
  }

  public setSaveForm (type: number, folder: any): void {
    const searchForm: any = {}
    searchForm.folderId = folder.id
    searchForm.folderName = folder.name
    searchForm.selectedFolder = {
      type,
      folder
    }
    searchForm.title = this.search.title
    this._search.next(searchForm)
  }

  public async getSearchById (id: string): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('search/' + id).then((res: any) => {
      const saveSearch = this.setSavedSearch(res)
      this._search.next(saveSearch)
      this._loading.next(false)
      return res.data
    }).catch((err) => {
      this._loading.next(false)
      return err
    })
  }

  public async uploadScreenshot (formData: FormData): Promise<any> {
    this._loading.next(true)
    return await apiClient.post('map/upload-screenshot', formData).then((res: any) => {
      this._loading.next(false)
      return res.data
    }).catch((err) => {
      this._loading.next(false)
      return err
    })
  }

  public async uploadDropzoneScreenshot (formData: FormData, uploadProgress: any): Promise<any> {
    this._loading.next(true)
    return await apiClient.post('map/uploads', formData, {
      onUploadProgress: uploadProgress
    }).then((res: any) => {
      this._loading.next(false)
      return res.data
    }).catch((err) => {
      this._loading.next(false)
      return err
    })
  }

  public async getScreenshotFiles (userId: number, searchId: string): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('map/screenshot-files/' + userId.toString() + '/' + searchId).then((res: any) => {
      this._loading.next(false)
      const screenshotFiles: ScreenshotFile[] = []
      res.data.map((screenshotFile: ScreenshotFile) => [
        screenshotFiles.push(new ScreenshotFile(screenshotFile))
      ])
      this._screenshotFiles.next(screenshotFiles)
      return res.data
    }).catch((err) => {
      this._loading.next(false)
      return err
    })
  }

  public async screenshotDelete (path: string, userId: number, searchId: string): Promise<any> {
    this._loading.next(true)
    return await apiClient.delete('map/screenshot/' + path).then((res: any) => {
      this._loading.next(false)
      this.getScreenshotFiles(userId, searchId).catch((err) => {
        throw err
      })
      return res.data
    }).catch((err) => {
      this._loading.next(false)
      return err
    })
  }

  public async getSearches (filter?: string): Promise<SearchRef[]> {
    if (filter) {
      this._filter.next(filter)
    }
    if (this.filter === 'default') {
      return await this.getAllSearch()
    } else {
      return await this.searchSearches()
    }
  }

  public async getUserSearches (userId: number): Promise<SearchRef[]> {
    const data = { userId }
    this._searchCriteria.next(new SearchCriteria(data))
    return await this.searchSearches()
  }

  public async getAllSearch (): Promise<SearchRef[]> {
    this._loading.next(true)
    this._searchCriteria.next(new SearchCriteria())
    return await apiClient.get('search', {
      params: this.pageParams
    }).then((res: any) => {
      this._loading.next(false)
      const searches: SearchRef[] = []
      res.data.searches.forEach((search: any) => {
        searches.push(new SearchRef(search))
      })
      this._searches.next(searches)
      this._totalSearches.next(res.data.total)
      return searches
    }).catch((err) => {
      this._loading.next(false)
      return err
    })
  }

  public async searchSearches (): Promise<SearchRef[]> {
    this._loading.next(true)
    return await apiClient.post('searches-search', { ...this.searchCriteria, pageParams: this.pageParams }).then((res: any) => {
      this._loading.next(false)
      const searches: SearchRef[] = []
      res.data.data.forEach((search: any) => {
        searches.push(new SearchRef(search))
      })
      this._searches.next(searches)
      this._totalSearches.next(res.data.total)
      return searches
    }).catch((err) => {
      this._loading.next(false)
      return err
    })
  }

  public async proSearchSearches (proSearch: string): Promise<void> {
    this._loading.next(true)
    await apiClient.post('searches-pro-search', { proSearch }).then(res => {
      if (res.data) {
        const searches: SearchRef[] = []
        res.data.forEach((search: any) => {
          searches.push(new SearchRef(search))
        })
        this._searches.next(searches)
        this._loading.next(false)
      }
    })
  }

  public async deleteSearch (searchId: number): Promise<AxiosResponse<any>> {
    this._loading.next(true)
    return await apiClient.delete('/search/' + searchId.toString()).then(res => {
      this._loading.next(false)
      return res
    })
  }
}

export const saveSearchService = new SaveSearchService()
