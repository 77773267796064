<template>
  <el-dialog
    :visible.sync="visible"
    :append-to-body="true"
    :center="true"
    class="import-ref"
    title="Import Référence"
    width="410px"
    @close="close">
    <el-upload
      ref="upload"
      drag
      :limit="1"
      :action="baseUrl + 'reference/import-bodacc'"
      :on-success="handleSuccess"
      :on-error="handleError"
      :with-credentials="true"
      accept=" .xlsx">
      <i class="el-icon-upload" />
      <div
        class="el-upload__text"
        style="padding: 2rem">
        Import des références Bodacc.
      </div>
    </el-upload>
    <div
      v-for="(id,i) in importedIds"
      :key="i">
      <p class="m-4">
        {{ id + ' ; ' }} <a
          :href="`/edit-ref/${id}`"
          :target="'_blank'">{{ frontUrl + 'edit-ref/' + id }}</a>
      </p>
    </div>
    <p class="m-4">
      {{ 'Nombre de fiches importées : ' + importedIds.length }}
    </p>
  </el-dialog>
</template>

<script>
import { baseUrl, frontUrl } from '@/services/api.client'
export default {
  name: 'ImportBodacc',
  data () {
    return {
      visible: false,
      baseUrl,
      importedIds: [],
      frontUrl
    }
  },
  methods: {
    handleSuccess (response) {
      this.importedIds = response
      this.$message({
        message: 'Imported successfully.',
        type: 'success',
        offset: 65
      })
    },
    handleError (response) {
      this.$message({
        message: JSON.parse(response).message,
        type: 'error',
        offset: 65
      })
    },
    open () {
      this.visible = true
    },
    close () {
      this.visible = false
    }
  }
}
</script>
