<template>
  <el-form class="el-form--custom d-flex">
    <perfect-scrollbar>
      <div class="filter-advanced--filters">
        <el-row
          :gutter="20"
          class="mb-4">
          <el-col :span="12">
            <el-select
              v-model="filters.type"
              :clearable="true"
              filterable
              placeholder="Type de fiche"
              popper-class="selectSearch">
              <el-option
                label="Document"
                value="document" />
              <el-option
                label="Note"
                value="note" />
            </el-select>
          </el-col>
          <el-col :span="12">
            <el-input
              v-model="filters.name"
              name
              placeholder="Nom"
              :clearable="true" />
          </el-col>
        </el-row>
        <el-row
          :gutter="20"
          class="mb-4">
          <el-col :span="12">
            <el-input
              v-model="filters.source"
              :clearable="true"
              placeholder="Source" />
          </el-col>
          <el-col :span="12">
            <el-autocomplete
              v-model="filters.address"
              class="el-autocomplete--custom"
              :clearable="true"
              placeholder="Adresse"
              :fetch-suggestions="querySearch"
              value-key="label"
              :trigger-on-focus="false"
              @select="handleSelectAddress"
              @clear="clearPelias" />
          </el-col>
        </el-row>
        <el-row
          :gutter="20"
          class="mb-4">
          <el-col :span="12">
            <el-select
              v-model="filters.theme"
              :clearable="true"
              filterable
              placeholder="Thème"
              popper-class="selectSearch">
              <el-option
                v-for="(item, index) in listThemes"
                :key="index"
                :label="item.name"
                :value="item.name" />
            </el-select>
          </el-col>
          <el-col :span="12">
            <el-select
              v-model="filters.content"
              :clearable="true"
              filterable
              placeholder="Contenu"
              popper-class="selectSearch">
              <el-option
                v-for="(item, index) in listContents"
                :key="index"
                :label="item.name"
                :value="item.name" />
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-date-picker
              v-model="filters.dateMini"
              :clearable="true"
              type="date"
              format="dd/MM/yyyy"
              placeholder="Date Mini" />
          </el-col>
          <el-col :span="12">
            <el-date-picker
              v-model="filters.dateMaxi"
              :clearable="true"
              type="date"
              format="dd/MM/yyyy"
              placeholder="Date Maxi"
              @change="handleChangeDateMaxi" />
          </el-col>
        </el-row>
      </div>
      <div class="btn-container">
        <div class="d-flex align-items-center justify-content-center">
          <el-button
            class="btn-black"
            @click="searchGlobalItems">
            Rechercher ({{ globalItemsTotal$ }})
          </el-button>
          <el-button @click="() => $emit('reset-filters')">
            Réinitialiser
          </el-button>
        </div>
      </div>
    </perfect-scrollbar>
  </el-form>
</template>

<script>
import { searchGlobalItemsService } from '@/services/searchGlobalItems.service'
import {
  listReferenceService,
  ReferenceAddress
} from '@/services/listReference.service'
import { gedService } from '@/services/ged.service'

export default {
  subscriptions () {
    return {
      globalItemsTotal$: searchGlobalItemsService.onChangeGlobalItemsTotal,
      filtersCount$: searchGlobalItemsService.onChangeFiltersCount,
      filters: searchGlobalItemsService.onChangeGedFilters
    }
  },

  data () {
    return {
      listThemes: [],
      listContents: [],
      titles: [
        {
          value: '',
          label: ''
        },
        {
          value: 'Indivision',
          label: 'Indivision'
        },
        {
          value: 'Consorts',
          label: 'Consorts'
        },
        {
          value: 'Maître',
          label: 'Maître'
        },
        {
          value: 'Monsieur',
          label: 'Monsieur'
        },
        {
          value: 'Madame',
          label: 'Madame'
        }
      ]
    }
  },

  created () {
    gedService.getListThemes().then((res) => {
      this.listThemes = res
    })
    gedService.getListContents().then((res) => {
      this.listContents = res
    })
  },

  methods: {
    searchGlobalItems () {
      searchGlobalItemsService.setIsLoading(true)
      this.$emit('search', this.filters)
    },
    querySearch (queryString, cb) {
      if (!queryString) return
      listReferenceService.getReferenceAddresse(queryString).then((res) => {
        cb(res)
      })
    },

    handleSelectAddress (peliasAddress) {
      this.filters.peliasAddress = peliasAddress
    },

    clearPelias () {
      this.filters.peliasAddress = new ReferenceAddress()
    },
    handleChangeDateMaxi (date) {
      if (date) {
        date.setHours(23)
        date.setMinutes(59)
        date.setSeconds(59)
        this.filters.dateMaxi = date
      }
    }
  }
}
</script>

<style>
.el-autocomplete--custom {
  width: 100%;
}
</style>
