
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormattedFloat from '../../utils/FormattedFloat.vue'
import { isFloat } from '@/utils/functions'
import { baseBlockItems } from '../blockItems'
import { floatRule } from '../formRules'
import { referenceService } from '@/services/listReference.service'
import { tap } from 'rxjs/operators'

@Component({
  subscriptions (this: any) {
    return {
      form: referenceService.onChangeReference.pipe(
        tap((res: any) => {
          if (!res.tablePonderation) {
            res.tablePonderation = [
              {
                niveau: '',
                zone: '',
                surfaceUtile: 0,
                ponderation: 0,
                surfacePonderee: 0
              }
            ]
          }
        }))
    }
  },
  components: {
    FormattedFloat
  }
})
export default class TableauPonderation extends Vue {
  @Prop({ type: Object })
    optionList: any

  @Prop({ type: String })
    localLabel!: any

  form: any
  floatRule = floatRule
  isFloat = isFloat

  moveUp (rowIndex: number) {
    this.form.tablePonderation.splice(rowIndex - 1, 0, this.form.tablePonderation.splice(rowIndex, 1)[0])
  }

  moveDown (rowIndex: number) {
    this.form.tablePonderation.splice(rowIndex + 1, 0, this.form.tablePonderation.splice(rowIndex, 1)[0])
  }

  removeItem (rowIndex: number) {
    this.form.tablePonderation.splice(rowIndex, 1)
    this.calculSurfacePonderee()
  }

  addItem (rowIndex: number) {
    this.form.tablePonderation.splice(rowIndex + 1, 0, {
      niveau: '',
      zone: '',
      surfaceUtile: 0,
      ponderation: 0,
      surfacePonderee: 0
    })
  }

  checkDisplayCondition (title: string) {
    const localLabel = this.localLabel
    return (
      baseBlockItems[localLabel] && !baseBlockItems[localLabel].includes(title)
    )
  }

  calculSurfacePonderee () {
    referenceService.updateRef(this.form, 'tablePonderation')
  }

  formatNumber (number: number): string {
    return new Intl.NumberFormat('fr-FR', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }).format(number)
  }

  getSummaries (param: any) {
    const { columns, data } = param
    const sums: any = []
    columns.forEach((column: any, index: any) => {
      if (column.label === 'Coefficient') {
        sums[index] = ''
        return
      }
      if (index === 0) {
        sums[index] = 'Totaux'
        return
      }
      if (index === 1) {
        sums[index] = ''
        return
      }
      const values = data.map((item: any) => Number(item[column.property]))
      if (!values.every((value: any) => isNaN(value))) {
        const total = values.reduce((prev: any, curr: any) => {
          const value = Number(curr)
          if (!isNaN(value)) {
            return prev + curr
          } else {
            return prev
          }
        }, 0)
        sums[index] = this.formatNumber(total)
      } else {
        sums[index] = ''
      }
    })

    return sums
  }
}
