
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { quoteService, QuoteAddress, Quote } from '@/services/quote.service'
import QuoteAddressComponent from './QuoteAddress.vue'
import MapPreview from '../element/MapPreview.vue'
import { legalFolderService } from '@/services/legalFolder.service'
import { LegalFolder } from '@/models/legalFolder.model'
import { ReferenceAddress } from '@/services/listReference.service'
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions () {
    return {
      quote$: quoteService.onChangeQuote,
      folder$: legalFolderService.onChangeLegalFolder
    }
  },
  components: {
    QuoteAddressComponent,
    MapPreview
  }
})

export default class QuoteAddresses extends Vue {
  @Prop({ default: false }) updateable: boolean
  @Prop({ default: false }) opened: boolean
  quote$: Quote = new Quote()
  folder$: LegalFolder = new LegalFolder()
  referenceAddress$: ReferenceAddress = new ReferenceAddress()
  addressIndex: number = 0
  mapHeight: number = 200
  allowUpdate: boolean = false

  @Watch('opened')
  onActiveCollapse (val: boolean) {
    if (val) {
      (this.$refs.mapPreview as any).map.invalidateSize()
    }
  }

  @Watch('quote$.addresses')
  onChangeAddresses (val: QuoteAddress[]) {
    if (val) {
      this.handleChangeAddress()
    }
  }

  changeAddressType (quoteAddress: QuoteAddress): ReferenceAddress {
    return {
      ...new ReferenceAddress(),
      housenumber: quoteAddress.buildingNumber,
      street: quoteAddress.street,
      postalcode: quoteAddress.zip,
      locality: quoteAddress.city,
      lat: parseFloat(quoteAddress.lat),
      lon: parseFloat(quoteAddress.lon)
    }
  }

  handlePreviewAddress (index: number): void {
    this.addressIndex = index
    this.handleChangeAddress(index)
  }

  handleChangeAddress (index: number = 0): void {
    if (!this.quote$.addresses[index]) return
    this.referenceAddress$ = this.changeAddressType(this.quote$.addresses[index])
  }

  handleSelectLocation ({ lat, lon }: any): void {
    if (!this.quote$.addresses[this.addressIndex]) return
    this.quote$.addresses[this.addressIndex].lat = lat.toString()
    this.quote$.addresses[this.addressIndex].lon = lon.toString()
  }

  addAddress (): void {
    this.mapHeight += 70
    quoteService.addAddress(this.quote$.addresses.length - 1)
  }
}
