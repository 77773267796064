<template>
  <div
    class="custom-form toggler"
    @click="toggle">
    <div class="option-toggle">
      <input
        :id="`toggler-${label.toLowerCase()}`"
        v-model="val"
        type="radio"
        :value="true">
      <label :for="`toggler-${label.toLowerCase()}`">{{ label }}</label>
    </div>
    <span
      class="option-toggler single-val"
      :class="{'active':val,}" />
  </div>
</template>
<script>
export default {
  name: 'CustomToggle',
  props: {
    value: {
      type: [String, Boolean],
      default: false
    },
    label: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      val: this.value
    }
  },
  watch: {
    val (v) {
      this.$emit('input', v)
    }
  },
  methods: {
    toggle () {
      this.val = !this.val
    }
  }
}
</script>
