
import { Component, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import moment from 'moment'
import html2canvas from 'html2canvas'
import b64toBlob from 'b64-to-blob'
import {
  listReferenceService
} from '@/services/listReference.service'
import {
  SearchRef,
  saveSearchService
} from '@/services/saveSearch.service'
import { LegalFolder } from '@/models/legalFolder.model'
import {
  legalFolderService
} from '@/services/legalFolder.service'
import { authService } from '@/services/auth.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'

import MapView from './components/MapView.vue'
import ZoomRange from './components/ZoomRange.vue'
import SaveSearch from './components/SaveSearch.vue'
import ScreenshotUpload from './components/ScreenshotUpload.vue'
import { baseUrl } from '@/services/api.client'

@Component({
  subscriptions (this: Vue) {
    return {
      legalFolders: legalFolderService.onChange,
      user$: userService.onChange,
      loading: saveSearchService.onLoading,
      savedSearch: saveSearchService.onChange
    }
  },
  components: {
    MapView,
    ZoomRange,
    SaveSearch,
    ScreenshotUpload
  },
  watch: {
    $route () {
      (this as any).$router.go()
    }
  }
})
export default class SearchRefPage extends Vue {
  baseUrl: string | undefined = baseUrl
  displayedComponent = ''
  currentSort = 'name'
  displaySaveModal: boolean = false
  legalFolders: LegalFolder[] = []
  searchResult: any[] = []
  searchCondition: any = {}
  savedSearch: any = {}
  isInitialMap: boolean = true
  userId: number
  showScreenshotModal: boolean = false
  user$: User = new User()
  newResult: any[] = []
  updatable: boolean = false
  loading: boolean = false
  updateAction: boolean = false

  created () {
    if (!authService.userValue || !authService.userValue.id) {
      this.$message({
        type: 'error',
        message: 'Utilisateur invalide',
        offset: 65
      })
      return false
    } else {
      this.userId = authService.userValue.id
      userService
        .getUserById(authService.userValue.id.toString())
        .then(() => {
          if (this.accessable) {
            if (this.$route.params.id) {
              if (this.editable) {
                const searchId = this.$route.params.id
                saveSearchService.getSearchById(searchId).then((res: any) => {
                  this.savedSearch = res
                  this.changeSearchCriteria(res.criteria)
                  listReferenceService.searchReferences(res.criteria).then((res: any) => {
                    const newResult: any = res
                    const currentResult: any = this.savedSearch.result
                    this.changeSearchResult(this.savedSearch.result)
                    const currentResultIds: any = []
                    currentResult.forEach((table: any) => {
                      table.data.forEach((element: any) => {
                        currentResultIds.push(element.id)
                      })
                    })
                    const newResultIds: any = []
                    newResult.forEach((element: any) => {
                      newResultIds.push(element.id)
                    })
                    if (_.isEqual(_.sortBy(newResultIds), _.sortBy(currentResultIds))) {
                      this.updatable = false
                    } else {
                      this.newResult = newResult
                      this.updatable = true
                    }
                    this.loading = false
                  })
                })
                  .catch(() => {
                    this.loading = false
                    this.$router.replace('/search-ref')
                  })
              } else {
                this.loading = false
                this.$message({
                  type: 'error',
                  message: "Vous n'avez pas les droits nécessaires pour accéder à cette recherche.",
                  offset: 65
                })
                this.$router.go(-1)
              }
            }
          } else {
            this.loading = false
            this.$message({
              type: 'error',
              message: 'Vous n\'avez pas les droits nécessaires pour effectuer une recherche.',
              offset: 65
            })
            this.$router.go(-1)
          }
        })
        .catch(() => {
          this.loading = false
          this.$message({
            type: 'error',
            message: 'Utilisateur invalide',
            offset: 65
          })
          return false
        })
    }
  }

  get accessable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.SEARCH_READ)
  }

  get editable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.SEARCH_SAVE)
  }

  get extractable (): boolean {
    return (
      userService.hasRight(this.user$, USER_RIGHTS.EXTRACTION_50_DAY) ||
      userService.hasRight(this.user$, USER_RIGHTS.EXTRACTION_150_DAY) ||
      userService.hasRight(this.user$, USER_RIGHTS.EXTRACTION_NL_DAY)
    )
  }

  // Capture a screenshot of #screenshot div
  handleCapture (overwrite: boolean = false) {
    const mapElement = _.last(
      document.getElementsByClassName('leaflet-map-pane')
    )
    const transform = mapElement.style.transform
    if (transform) {
      const values = transform.split(/\w+\(|\);?/)
      if (values[1] && values[1].length) {
        const property = values[1].split(/,\s?/g)
        mapElement.style.transform = 'none'
        mapElement.style.left = property[0]
        mapElement.style.top = property[1]
      }
    }
    const captureContainer = document.querySelector('#map') as HTMLElement
    const self: any = this
    html2canvas(captureContainer, {
      backgroundColor: '#ffffff',
      allowTaint: false,
      useCORS: true,
      scrollX: 0,
      scrollY: -window.scrollY
    }).then(function (canvas) {
      if (transform) {
        mapElement.style.transform = transform
        mapElement.style.left = ''
        mapElement.style.top = ''
      }
      const imgData = canvas.toDataURL('image/png')
      const contentType = imgData.split(',')[0]
      const b64Data = imgData.split(',')[1]
      const blob = b64toBlob(b64Data, contentType)

      const imgFile = new File([blob], 'map-screenshot.png')
      const formData = new FormData()
      formData.append('imgFile', imgFile)
      formData.append('userId', self.userId.toString())
      formData.append('searchId', self.$route.params.id)
      formData.append('overwrite', overwrite.toString())
      saveSearchService.uploadScreenshot(formData).then((resp: any) => {
        if (resp.status === 'ok') {
          self.$message({
            type: 'success',
            message: 'Le screenshot a bien été sauvegardé'
          })
        } else if (resp.status === 'limited') {
          self.$confirm('Plus de 10 screenshots sont enregistrés, le screenshot le plus ancien sera supprimé', 'Attention', {
            confirmButtonText: 'Valider',
            cancelButtonText: 'Annuler',
            type: 'warning'
          }).then(() => {
            self.handleCapture(true)
          }).catch((error: any) => {
            self.$message({
              message: "Erreur lors de l'import du fichier.",
              type: 'error',
              offset: 65
            })
            throw error
          })
        } else {
          self.$message({
            type: 'error',
            message: 'Unknown issue! Try again later!'
          })
        }
      })
    })
  }

  handleDownload (isNumberFormat: boolean) {
    if (!this.extractable || !this.editable) {
      return
    }
    if (!_.isEmpty(this.searchCondition)) {
      this.savedSearch.criteria = this.searchCondition
    }
    const type = this.savedSearch.selectedFolder ? this.savedSearch.selectedFolder.type : null
    const folder = this.savedSearch.selectedFolder ? this.savedSearch.selectedFolder.folder : null
    const tableResult = this.searchResult
    if (!_.isEmpty(tableResult)) {
      const modelData = {
        id: null,
        hide: null,
        tableId: null,
        rating: 0
      }
      tableResult.forEach((searchTable: any) => {
        searchTable.data.forEach((data: any, index: number) => {
          searchTable.data[index] = _.pick(data, _.keys(modelData))
        })
      })
    }
    const searchRef = new SearchRef({
      id: this.$route.params.id ? this.$route.params.id : null,
      legalFolder: (type && type === 1) ? folder : null,
      settledFolder: (type && type === 2) ? folder : null,
      title: this.savedSearch.title,
      criteria: this.savedSearch.criteria,
      result: tableResult,
      user: this.user$,
      typeValeur: this.savedSearch.criteria.typeValeur,
      typeLocal: this.savedSearch.criteria.typeLocal,
      activites: this.savedSearch.criteria.activites
    })
    this.loading = true
    saveSearchService.saveSearch(searchRef).then(() => {
      listReferenceService.exportSearch(this.userId, this.$route.params.id ? parseInt(this.$route.params.id) : this.savedSearch.id, isNumberFormat).then((res: any) => {
        if (res.data) {
          const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          const today = moment(new Date()).format('DD-MM-YYYY')
          const fileName = 'export-references-' + today + '.xlsx'
          link.href = downloadUrl
          link.setAttribute('download', fileName) // any other extension
          document.body.appendChild(link)
          link.click()
          link.remove()
          this.loading = false
          if (!this.$route.params.id) {
            saveSearchService.deleteSearch(this.savedSearch.id).then(() => {
            }).catch((err: any) => {
              this.$message({
                message: 'Une erreur est survenue lors de la suppresion de la recherche.',
                type: 'error',
                offset: 65
              })
              throw err
            })
          }
        } else {
          this.loading = false
          this.$message({
            type: 'error',
            message: "Vous avez excédez la limitation d'extraction des références ou vous n'avez pas les droits pour effectuer un export.",
            offset: 65
          })
        }
      }).catch(error => {
        this.loading = false
        this.$message({
          message: "Vous avez excédez la limitation d'extraction des références ou vous n'avez pas les droits pour effectuer un export.",
          type: 'error',
          offset: 65
        })
        throw error
      })
    })
  }

  handleDouplicateSearch () {
    if (!this.$route.params.id) {
      return
    }
    saveSearchService.duplicateSearch().then(() => {
      window.open(this.$router.resolve({ path: '/search-ref' }).href, '_blank')
    })
  }

  handleUpdateResult () {
    if (!_.isEmpty(this.newResult)) {
      if (this.$route.params.id) {
        const savedResults = this.savedSearch.result
        savedResults.forEach((category: any) => {
          category.data.forEach((item: any) => {
            const relatedRef = this.newResult.find((ref: any) => ref.id === item.id)
            if (relatedRef) {
              relatedRef.rating = item.rating
              relatedRef.hide = item.hide
            }
          })
        })
      }
      this.newResult = _.orderBy(
        this.newResult,
        ['dateDeffetBail'],
        ['asc']
      )
      this.newResult = _.map(this.newResult, function (
        element: any,
        index: number
      ) {
        return _.extend(
          {},
          element,
          { hide: element.hide },
          { tableId: index },
          { cloudy: false }
        )
      })
      this.newResult = [
        {
          fold: false,
          typeTransaction: this.savedSearch.criteria.typeValeur * 4 - 3,
          color: '#810D00',
          headerHide: false,
          data: this.newResult.filter(
            (item: any) =>
              item.typeTransaction === this.savedSearch.criteria.typeValeur * 4 - 3
          )
        },
        {
          fold: false,
          typeTransaction: this.savedSearch.criteria.typeValeur * 4 - 2,
          color: '#778F1E',
          headerHide: false,
          data: this.newResult.filter(
            (item: any) =>
              item.typeTransaction === this.savedSearch.criteria.typeValeur * 4 - 2
          )
        },
        {
          fold: false,
          typeTransaction: this.savedSearch.criteria.typeValeur * 4 - 1,
          color: '#1C3283',
          headerHide: false,
          data: this.newResult.filter(
            (item: any) =>
              item.typeTransaction === this.savedSearch.criteria.typeValeur * 4 - 1
          )
        },
        {
          fold: false,
          typeTransaction: this.savedSearch.criteria.typeValeur * 4,
          color: '#7F7F7F',
          headerHide: false,
          data: this.newResult.filter(
            (item: any) => item.typeTransaction === this.savedSearch.criteria.typeValeur * 4
          )
        },
        {
          fold: false,
          typeTransaction: null,
          color: '#000000',
          headerHide: false,
          data: this.newResult.filter(
            (item: any) => item.typeTransaction === null
          )
        }
      ]
      this.newResult.forEach((table: any) => {
        let globalIndex = 1
        table.data.forEach((reference: any) => {
          reference.tableId = globalIndex++
        })
      })

      this.updateAction = true
      this.updatable = false

      const modelData = {
        id: null,
        hide: null,
        tableId: null
      }

      const newTableResult = JSON.parse(JSON.stringify(this.newResult))
      newTableResult.forEach((searchTable: any) => {
        searchTable.data.forEach((data: any, index: number) => {
          searchTable.data[index] = _.pick(data, _.keys(modelData))
        })
      })
      this.searchResult = newTableResult
    }
  }

  handleSave () {
    if (!this.editable) {
      return
    }
    this.displaySaveModal = true
  }

  closeSaveModal () {
    this.displaySaveModal = false
  }

  handleChangeScreenshotModel (show: boolean): void {
    this.showScreenshotModal = show
  }

  changeSearchResult (searchResult: any) {
    this.searchResult = searchResult
  }

  changeSearchCriteria (criteria: any) {
    this.searchCondition = criteria
  }

  changeMapStatus (isInitialMap: boolean) {
    this.isInitialMap = isInitialMap
  }

  deleteSearch () {
    this.$confirm('Voulez-vous supprimer définitivement cette recherche ?', '', {
      confirmButtonText: 'Confirmation',
      cancelButtonText: 'Annuler',
      type: 'warning'
    }).then(() => {
      saveSearchService.deleteSearch(parseInt(this.$route.params.id)).then(() => {
        this.$message({
          message: 'La recherche a été supprimée avec succès.',
          type: 'success',
          offset: 65
        })
        this.$router.replace('/search-ref')
      }).catch((err: any) => {
        this.$message({
          message: 'Une erreur est survenue lors de la suppresion de la recherche.',
          type: 'error',
          offset: 65
        })
        throw err
      })
    })
  }

  updateRatingReference (referenceId: Number, rating: Number, key: any) {
    this.searchResult[key].data.find((ref: any) => ref.id === referenceId).rating = rating
  }
}
