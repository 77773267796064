import { apiClient } from '@/services/api.client'
import { BehaviorSubject, Observable } from 'rxjs'
import { User } from '@/services/auth.service'
import { GlobalItemSearch } from '@/services/searchGlobalItems.service'

export class HistoryItem {
  id: number
  title: string
  criteria: any
  result: any
  createdAt: string
  createdSince: string
  createdBy: User
  deleted: number
}

class HistoryService {
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  private readonly _history: BehaviorSubject<HistoryItem[]> = new BehaviorSubject<HistoryItem[]>([])
  private readonly _currentHistory: BehaviorSubject<HistoryItem> = new BehaviorSubject<HistoryItem>(new HistoryItem())
  private readonly _historyTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0)

  public onLoading: Observable<boolean> = this._loading.asObservable()
  public onChangeHistory: Observable<HistoryItem[]> = this._history.asObservable()
  public onChangeCurrentHistory: Observable<HistoryItem> = this._currentHistory.asObservable()
  public onChangehistoryTotal: Observable<number> = this._historyTotal.asObservable()

  async getHistory (): Promise<any> {
    return await apiClient.get('search-history').then((res: any) => {
      this._history.next(res.data.history)
      this._historyTotal.next(res.data.total)
      return res.data.history
    }).catch((err) => {
      throw err
    })
  }

  public async saveHistory (title: string, criteria: GlobalItemSearch, result: any, total: number): Promise<HistoryItem> {
    this._loading.next(true)
    return await apiClient.post('search-history', {
      title,
      criteria,
      result,
      total
    }).then((res: any) => {
      this._currentHistory.next(res.data)
      this._loading.next(false)
      return res.data
    })
  }

  public async updateHistory (id: number, title: string, criteria: GlobalItemSearch, result: any, total: number): Promise<HistoryItem> {
    this._loading.next(true)
    return await apiClient.put('search-history/' + id.toString(), {
      title,
      criteria,
      result,
      total
    }).then((res: any) => {
      this._currentHistory.next(res.data)
      this._loading.next(false)
      return res.data
    })
  }

  public async deleteHistory (id: any): Promise<any> {
    this._loading.next(true)
    return await apiClient.delete('search-history/' + String(id)).then((res: any) => {
      this.getHistory().catch((err) => {
        throw err
      })
      this._loading.next(false)
      return res
    })
  }

  public async deleteAllHistory (): Promise<any> {
    this._loading.next(true)
    return await apiClient.delete('search-history-all').then((res: any) => {
      this._loading.next(false)
      return res
    })
  }

  public async getHistoryById (id: number): Promise<HistoryItem> {
    this._loading.next(true)
    return await apiClient.get('search-history/' + id.toString())
      .then((res: any) => {
        this._currentHistory.next(res.data.history)
        this._loading.next(false)
        return res.data
      })
  }
}

export const historyService = new HistoryService()
