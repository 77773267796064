import { apiClient, CancelToken } from '@/services/api.client'
import { authService } from '@/services/auth.service'
import { BehaviorSubject, Observable } from 'rxjs'
import { Notification } from '@/models/notification.model'
import { PageParams } from '@/models/ged.model'

class NotificationService {
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  private readonly _notifications: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([])
  private readonly _totalNotifications: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  private readonly _pageParams: BehaviorSubject<PageParams> = new BehaviorSubject<PageParams>(new PageParams())

  public onLoading: Observable<boolean> = this._loading.asObservable()
  public onChangeNotifications: Observable<Notification[]> = this._notifications.asObservable()
  public onChangeTotalNotifications: Observable<number> = this._totalNotifications.asObservable()
  public onChangePageParams: Observable<PageParams> = this._pageParams.asObservable()

  private cancelRequest: () => void

  get totalDossiers (): number {
    return this._totalNotifications.getValue()
  }

  get pageParams (): PageParams {
    return this._pageParams.getValue()
  }

  public async getNotifications (): Promise<void> {
    this._loading.next(true)
    let userId = null
    if ((authService.userValue != null) && authService.userValue.id) {
      userId = authService.userValue.id
    }
    if (this.cancelRequest) {
      this.cancelRequest()
    }
    const self = this
    await apiClient.get('active-notifications/' + String(userId), {
      params: {
        currentPage: this.pageParams.currentPage,
        pageSize: 10
      },
      cancelToken: new CancelToken(function executor (cancelRequest: () => void) {
        // An executor function receives a cancel function as a parameter
        self.cancelRequest = cancelRequest
      })
    }).then(res => {
      if (res.data) {
        const notifications: Notification[] = []
        res.data.notifications.forEach((notification: any) => {
          notifications.push(new Notification(notification))
        })
        this._notifications.next(notifications)
        this._totalNotifications.next(res.data.total)
        this._loading.next(false)
      }
    })
  }

  public async deactivateNotification (id: number): Promise<void> {
    const notifications = this._notifications.getValue()
    const newNotifications = notifications.filter((notification: Notification) => notification.id !== id)
    this._notifications.next(newNotifications)
    await apiClient.post('deactivate-notification', { id }).then(async res => {
      await this.getNotifications()
    })
  }
}

export const notificationService = new NotificationService()
