
import { Component, Vue } from 'vue-property-decorator'
import BusinessManagement from '@/components/legalFolder/business/Business.vue'
import HonorairesManagement from '@/components/legalFolder/honoraires/Honoraires.vue'
import CourrierManagement from '@/components/legalFolder/Courrier.vue'
import FileManagementList from '../components/quote/file/FileManagementList.vue'
import LegalFolderDialogs from '@/components/legalFolder/LegalFolderDialogs.vue'
import LegalFolderReport from '@/components/legalFolder/LegalFolderReport.vue'
import LegalFolderPipeLine from '@/components/legalFolder/LegalFolderPipeLine.vue'
import EmailManagement from '@/components/quote/email/EmailManagement.vue'
import InformationGenerals from '@/components/quote/general/InformationGenerals.vue'
import BillingState from '../components/billing/BillingState.vue'
import LegalFolderBillingManagement from '../components/legalFolder/LegalFolderBillingManagement.vue'
import { quoteService, Quote } from '@/services/quote.service'
import { contactService } from '@/services/contact.service'
import { LegalFolder } from '@/models/legalFolder.model'
import { legalFolderService } from '@/services/legalFolder.service'
import { authService } from '@/services/auth.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import { LEGAL_FOLDER_STATUES } from '@/services/constants.service'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import FolderSearches from '@/components/search/FolderSearches.vue'
import ConflitDialog from '@/components/conflit/ConflitDialog.vue'
import { localeDateStr } from '@/utils/functions'
import { tap } from 'rxjs/operators'
import QuoteHistory from '@/components/quote/QuoteHistory.vue'

    @Component({
      subscriptions (this: Vue) {
        return {
          folder$: legalFolderService.onChangeLegalFolder.pipe(
            tap((res: any) => {
              if (res.name) {
                document.title = res.name
              }
            })
          ),
          quote$: quoteService.onChangeQuote,
          user$: userService.onChange
        }
      },
      data: () => ({
        activeNames: 'general'
      }),
      watch: {
        $route () {
          if (this.$route.params.id) {
            document.title = legalFolderService.folder.name
          }
        }
      },
      components: {
        QuoteHistory,
        ConflitDialog,
        FolderSearches,
        BusinessManagement,
        HonorairesManagement,
        CourrierManagement,
        EmailManagement,
        FileManagementList,
        LegalFolderDialogs,
        LegalFolderReport,
        BillingState,
        LegalFolderBillingManagement,
        LegalFolderPipeLine,
        ClipLoader,
        InformationGenerals
      }
    })
export default class LegalFolderView extends Vue {
  LEGAL_FOLDER_STATUES = LEGAL_FOLDER_STATUES
  loading: boolean = false
  collapseNumber: number = 1
  quote$: Quote = new Quote()
  user$: User = new User()
  folder$: LegalFolder = new LegalFolder()
  editing: boolean = false
  activeNames: any = null
  showConflict: boolean = false

  localeDateStr: Function = localeDateStr

  created () {
    contactService.getContactNames()
    userService.getUsers()
    legalFolderService.initFolder()
    if (localStorage.getItem('duplicatedFolder')) {
      if (authService.userValue && authService.userValue.id) {
        this.loading = true
        const data = JSON.parse(localStorage.getItem('duplicatedFolder') || '')
        userService
          .getUserById(authService.userValue.id.toString())
          .then(() => {
            this.loading = false
            legalFolderService.initDuplicateFolder(data)
          })
          .catch(() => {
            this.loading = false
          })
      }
      localStorage.removeItem('duplicatedFolder')
    } else {
      if (authService.userValue && authService.userValue.id) {
        this.loading = true
        userService
          .getUserById(authService.userValue.id.toString())
          .then(() => {
            if (this.$route.params.id) {
              legalFolderService
                .getFolderById(this.$route.params.id)
                .then((res: any) => {
                  this.loading = false
                  if (
                    (res.folder.status === 3 || res.folder.status === 7) &&
                                            userService.hasRight(this.user$, USER_RIGHTS.VDD)
                  ) {
                    (this.$refs.legalFolderDialogs as any).showAcceptValidationDialog(
                      (result: boolean) => {
                        if (result) {
                          this.changeStatus(res.folder.status + 1)
                        } else {
                          this.$router.replace('/dossier-judiciaire')
                        }
                      }
                    )
                  }
                })
                .catch(() => {
                  this.$router.replace('/dossier-judiciaire')
                  this.loading = false
                })
            } else {
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
          })
      }
    }
  }

  get changeable (): boolean {
    if (this.$route.params.id) {
      return userService.hasRight(this.user$, USER_RIGHTS.UPDATE_STATUS_UPDATE)
    }
    return false
  }

  get deletable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.ADM)
  }

  get readable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.LEGAL_FOLDER_READ)
  }

  get updateable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.LEGAL_FOLDER_UPDATE)
  }

  get cancelable (): boolean {
    if (this.folder$.status === 4 || this.folder$.status === 8) {
      return true
    }
    return false
  }

  get folderStatusAction (): string {
    switch (this.folder$.status) {
      case 3:
        return 'Annuler la soumission'
      case 7:
        return 'Annuler la soumission'
      default:
        return LEGAL_FOLDER_STATUES[this.folder$.status].action
    }
  }

  get editableStatus (): boolean {
    return this.folder$.status <= 12
  }

  refuseFolder (type: string): void { // type can be 'prereport', 'finalreport'
    (this.$refs.legalFolderDialogs as any).showCommentDialog((comment: string) => {
      if (comment) {
        legalFolderService.refuseValidation(comment, type)
      }
    })
  }

  cancelSubmit (type: string): void { // type can be 'prereport', 'finalreport'
    legalFolderService.cancelSubmit(type)
  }

  changeStatus (status: number): void {
    if (this.changeable) {
      if (this.folder$.status === 12 || status === 12) {
        if (!userService.hasRight(this.user$, USER_RIGHTS.CLOSE_FOLDER_UPDATE) || !this.user$.id) {
          this.$message({
            message: "Vous n'avez pas le droit d'effectuer cette action.",
            type: 'error',
            offset: 65
          })
          return
        }
      }
      switch (status) {
        case 0:
          legalFolderService.openFolder().then(() => { legalFolderService.getStatusHistory() })
          break
        case 1: // En attente de visite
          if (!userService.hasRight(this.user$, USER_RIGHTS.FOLDER_ASSIGNATION) || !this.user$.id) {
            this.$message({
              message: "Vous n'avez pas le droit de d'attribuer un dossier.",
              type: 'error',
              offset: 65
            })
          } else {
            legalFolderService.attributeFolder(this.user$.id).then(() => { legalFolderService.getStatusHistory() })
          }
          break
        case 2: // En cours de rédaction
          legalFolderService.visitFolder().then(() => { legalFolderService.getStatusHistory() })
          break
        case 3: // En attente d\'acceptation de validation prérapport
          (this.$refs.legalFolderDialogs as any).showSelectFileDialog(
            (fileId: string) => {
              if (fileId) {
                this.loading = true
                legalFolderService.submitReport(fileId, 'prereport').then(() => {
                  this.loading = false
                  legalFolderService.getStatusHistory()
                }, () => {
                  this.loading = false
                })
              }
            }
          )
          break
        case 4: // En attente validation prérapport
          legalFolderService.acceptValidation('prereport').then(() => { legalFolderService.getStatusHistory() })
          break
        case 5: // En attente de l\'email de prérapport
          (this.$refs.legalFolderDialogs as any).showCommentDialog(
            (comment: string) => {
              if (comment) {
                this.loading = true
                legalFolderService.validateReport(comment, 'prereport').then(() => {
                  this.loading = false
                  legalFolderService.getStatusHistory()
                }, () => {
                  this.loading = false
                })
              }
            }
          )
          break
        case 6: // Repports en cours de finalisation
          legalFolderService.emailSent()
          break
        case 7: // En attente d\'acceptation de validation rapport final
          (this.$refs.legalFolderDialogs as any).showSelectFileDialog(
            (fileId: string) => {
              if (fileId) {
                this.loading = true
                legalFolderService.submitReport(fileId, 'finalreport').then(() => {
                  this.loading = false
                  legalFolderService.getStatusHistory()
                }, () => {
                  this.loading = false
                })
              }
            }
          )
          break
        case 8: // En attente validation rapport final
          legalFolderService.acceptValidation('finalreport').then(() => { legalFolderService.getStatusHistory() })
          break
        case 9: // Rapport validé
          (this.$refs.legalFolderDialogs as any).showCommentDialog(
            (comment: string) => {
              if (comment) {
                this.loading = true
                legalFolderService.validateReport(comment, 'finalreport').then(() => {
                  this.loading = false
                  legalFolderService.getStatusHistory()
                }, () => {
                  this.loading = false
                })
              }
            }
          )
          break
        case 10: // Rapport Déposé
          legalFolderService.depositReport().then(() => { legalFolderService.getStatusHistory() })
          break
        case 11: // Payé
          legalFolderService.paymentSent().then(() => { legalFolderService.getStatusHistory() })
          break
        case 12: // Cloturé
          if (this.user$.id) {
            legalFolderService.closeFolder(this.user$.id).then(() => { legalFolderService.getStatusHistory() })
          }
          break
        default:
          // TODO
          break
      }
    }
  }

  saveFolder (): void {
    if (!this.loading) {
      this.loading = true
      legalFolderService.saveFolder().then((res: any) => {
        this.loading = false
        this.$message({
          message: 'Le dossier a été sauvegardé avec succès.',
          type: 'success',
          offset: 65
        })
        if (!this.$route.params.id) {
          this.$router.replace('/dossier-judiciaire/' + res.folder.id)
        }
      }, () => {
        this.loading = false
        this.$message({
          message: 'La sauvegarde a échoué!, Veuillez réessayer plus tard.',
          type: 'error',
          offset: 65
        })
      })
    }
  }

  changeShowConflict (show: boolean) {
    this.showConflict = show
  }

  handleChange (val: number) {
    if (val) {
      this.collapseNumber = val
    }
  }

  duplicateFolder () {
    if (!this.$route.params.id) {
      return
    }
    legalFolderService.duplicateFolder().then(() => {
      window.open(this.$router.resolve({ path: '/dossier-judiciaire' }).href, '_blank')
    })
  }

  deleteFolder () {
    if (!this.$route.params.id) {
      return
    }
    (this.$refs.legalFolderDialogs as any).showConfirmDeleteDialog(
      (result: boolean) => {
        if (result) {
          legalFolderService.deleteFolder().then(() => {
            this.$router.replace('/dossier-judiciaire')
          })
        }
      }
    )
  }

  stopEditing (): void {
    // this.editing = false;
    // if (this.folder$.suffixName) {
    //     this.folder$.name = this.folder$.prefixName + this.folder$.suffixName.toUpperCase();
    // }
  }

  handleAccordion () {
    this.activeNames = ''
  }

  showConflicts () {
    this.showConflict = true
  }
}
