
import { Component, Prop, Vue } from 'vue-property-decorator'
import { reportService, Report } from '@/services/report.service'
import { userService, User } from '@/services/user.service'

    // The @Component decorator indicates the class is a Vue component
    @Component({
      subscriptions () {
        return {
          report$: reportService.onChangeReport,
          users$: userService.onChangeUsers
        }
      }
    })

export default class ReportExperts extends Vue {
        @Prop({ default: false }) updateable: boolean
        report$: Report = new Report()
        users$: User[] = []

        created () {
          userService.getUserFilter()
        }

        sortUserList (list: [User]) : [User] {
          return list.sort((a: User, b: User) => {
            const nameA = a && a.nom ? a.nom.toUpperCase() : '' // ignore upper and lowercase
            const nameB = b && b.nom ? b.nom.toUpperCase() : '' // ignore upper and lowercase
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          })
        }

        deleteExpert (index: number): void {
          reportService.deleteExpert(index)
        }

        addExpert (index: number): void {
          reportService.addExpert(index)
        }

        changeExpert (item: any, itemIndex: number): void {
          const find = this.users$.find((user: any) => user.id === item.userId)
          if (find) {
            item.position = find.position
          }
          this.report$.experts.splice(itemIndex, 1, item)
        }

        validate (): Promise<boolean> {
          return new Promise((resolve: any) => {
            let writer = 0
            let reviewer = 0
            this.report$.experts.forEach((item) => {
              if (item.userId && item.writer) {
                writer++
              }
              if (item.userId && item.reviewer) {
                reviewer++
              }
            })
            if (writer > 0 && reviewer > 0) {
              resolve(true)
            } else {
              resolve(false)
            }
          })
        }
}
