
import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import { gedService } from '@/services/ged.service'
import { localeDateStr, periodString } from '@/utils/functions'
import { PageParams } from '@/models/ged.model'
import { baseUrl } from '@/services/api.client'

@Component({
  name: 'TableGED',
  subscriptions () {
    return {
      loading$: gedService.onLoading,
      geds$: gedService.onChangeGeds,
      activeGedIndex$: gedService.onChangeActiveGedIndex,
      totalGed$: gedService.onChangeTotalGed,
      pageParams$: gedService.onChangePageParams
    }
  },
  components: {}
})
export default class TableGED extends Vue {
  @Prop({ default: 'document' }) mode: string
  @Prop({ default: null }) proSearch: string
  isFixesIndex: boolean = false
  selectedIndex: any = null

  localeDateStr: Function = localeDateStr
  periodString: Function = periodString

  pageParams$: PageParams

  created () {
    gedService.getGeds()
  }

  onChangeCurrent (currentPage: number) {
    this.pageParams$.currentPage = currentPage
    gedService.updatePageParams({ currentPage })
    if (this.proSearch) {
      gedService.proSearchGed(this.proSearch)
    } else {
      gedService.getGeds()
    }
  }

  onChangeSizePage (pageSize: number) {
    localStorage.setItem('pageSize', `${pageSize}`)
    this.pageParams$.pageSize = pageSize
    gedService.updatePageParams({})
    if (this.proSearch) {
      gedService.proSearchGed(this.proSearch)
    } else {
      gedService.getGeds()
    }
  }

  changeSortField (fieldName: string) {
    gedService.updatePageParams({
      sortField: fieldName,
      sortAsc: !this.pageParams$.sortAsc
    })
    if (this.proSearch) {
      gedService.proSearchGed(this.proSearch)
    } else {
      gedService.getGeds()
    }
  }

  @Emit('selectDocument')
  toggleDocument (index = null) {
    gedService.activeGedIndex(index)
  }

  preview (index = null) {
    if (this.selectedIndex === index) {
      this.isFixesIndex = !this.isFixesIndex
      this.selectedIndex = null
      gedService.activeGedIndex(null)
    } else {
      this.isFixesIndex = true
      this.selectedIndex = index
      gedService.activeGedIndex(index)
    }
  }

  editGed (id: number) {
    gedService.getGedById(id)
    gedService.showFormModal(true)
  }

  downloadFile (fileName: string, originName: string) {
    gedService.checkDownload().then((resp: any) => {
      if (resp.status) {
        const translatedName = originName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        window.open(`${baseUrl}ged-download/${fileName}/${translatedName}`)
      } else {
        this.$message({
          message: resp.message,
          type: 'error',
          offset: 65
        })
      }
    })
  }

  changeIndex (index = null) {
    if (!this.isFixesIndex) {
      gedService.activeGedIndex(index)
    }
  }
}
