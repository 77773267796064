
import { Component, Vue, Prop } from 'vue-property-decorator'
import BillingConsignation from '../billing/BillingConsignation.vue'
import BillingMissions from '../billing/BillingMissions.vue'
import BillingDebours from '../billing/BillingDebours.vue'
import BillingRemises from '../billing/BillingRemises.vue'
import BillingComplements from '../billing/BillingComplements.vue'
import BillingReceivedPayments from '../billing/BillingReceivedPayments.vue'
import BillingDepositInvoices from '../billing/BillingDepositInvoices.vue'
import BillingInvoices from '../billing/BillingInvoices.vue'
import BillingAvoirs from '../billing/BillingAvoirs.vue'
import BillingRetrocession from '@/components/billing/BillingRetrocession.vue'
import { billingService, Billing } from '@/services/billing.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions (this: Vue) {
    return {
      billing$: billingService.onChangeBilling,
      user$: userService.onChange
    }
  },
  components: {
    BillingConsignation,
    BillingMissions,
    BillingDebours,
    BillingRemises,
    BillingComplements,
    BillingReceivedPayments,
    BillingDepositInvoices,
    BillingInvoices,
    BillingAvoirs,
    BillingRetrocession
  }
})

export default class LegalFolderBillingManagement extends Vue {
  @Prop({ default: false }) disabled: boolean

  user$: User = new User()
  billing$: Billing = new Billing()

  get readable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.BILLING_SECTION_READ)
  }
}
