
import { Component, Prop, Vue } from 'vue-property-decorator'
import { authService } from '@/services/auth.service'
import { saveSearchService } from '@/services/saveSearch.service'
import { baseUrl } from '@/services/api.client'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    @Component({
      subscriptions () {
        return {
          screenshotFiles$: saveSearchService.onChangeScreenshotFiles
        }
      },
      components: {
        vueDropzone: vue2Dropzone
      },
      props: {
        show: Boolean
      }
    })

export default class ScreenshotUpload extends Vue {
        @Prop({ type: Function }) handleChangeScreenshotModel: any

        baseUrl = baseUrl
        screenshotDropzone: any = null

        dropzoneOptions: any = {
          url: `${baseUrl}map/upload-screenshot`,
          parallelUploads: 100,
          acceptedFiles: 'image/*'
        }

        userId: number
        searchId: string

        created () {
          if (!authService.userValue || !authService.userValue.id) {
            this.$message({
              type: 'error',
              message: 'Invalid User',
              offset: 65
            })
            return false
          }
          this.userId = authService.userValue.id
          saveSearchService.getScreenshotFiles(this.userId, this.$route.params.id)
        }

        onCompleteUpload () {
          saveSearchService.getScreenshotFiles(this.userId, this.$route.params.id)
        }

        initDropzone () {
          const screenshotDropzone = (this.$refs.screenshotDropzone as any).dropzone
          this.screenshotDropzone = screenshotDropzone
          screenshotDropzone.uploadFiles = (files: any[]) => {
            for (let i = 0; i < files.length; i++) {
              const file = files[i]
              const formData = new FormData()
              formData.append('imgFile', file)
              formData.append('userId', this.userId.toString())
              formData.append('searchId', this.$route.params.id)
              formData.append('overwrite', 'false')
              const uploadProgress = (progressEvent: any) => {
                const progress = Math.floor(progressEvent.loaded / progressEvent.total) * 100
                screenshotDropzone.emit('uploadprogress', file, progress, progressEvent.loaded)
              }
              saveSearchService.uploadDropzoneScreenshot(formData, uploadProgress).then((resp) => {
                if (resp.status === 'ok') {
                  screenshotDropzone.emit('success', file, 'success', null)
                  screenshotDropzone.removeFile(file)
                } else {
                  screenshotDropzone.emit('error', file, 'Storage limited!', null)
                }
              }).catch(() => {
                file.status = 'error'
                screenshotDropzone.emit('error', file, 'error')
                screenshotDropzone.emit('complete', file)
              })
            }
          }
        }

        clearFiles () {
          this.screenshotDropzone.removeAllFiles(true)
        }

        upload () {
          this.screenshotDropzone.files.forEach((file: any) => {
            file.status = 'queued'
          })
          this.screenshotDropzone.processQueue()
        }

        screenshotDownload (path: string): void {
          window.open(`${baseUrl}map/download-screenshot/${path}`)
        }

        screenshotDelete (path: string) {
          saveSearchService.screenshotDelete(path, this.userId, this.$route.params.id)
        }

        handleClose (): void {
          this.handleChangeScreenshotModel(false)
        }
}
