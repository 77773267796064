
import { Component, Vue } from 'vue-property-decorator'
import { dashboardService, SummaryData } from '../../services/dashboard.service'
import 'chart.js'
import 'chartjs-plugin-labels'
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions () {
    return {
      summaryData$: dashboardService.onChangeSummaryData
    }
  },
  methods: {}
})

export default class DashboardChart extends Vue {
  summaryData$: SummaryData
  chartData: any = {}
  chartOptions: any = {}
  chartInfos: any[] = [
    {
      label: 'Valeurs locatives',
      backgroundColor: '#5071c0'
    }, {
      label: 'Valeurs vénales',
      backgroundColor: '#a5a4a5'
    }, {
      label: 'Indemnités d’éviction',
      backgroundColor: '#f5c133'
    }, {
      label: 'Autres',
      backgroundColor: '#de823c'
    }
  ]

  created () {

  }

  mounted () {
    this.chartOptions = {
      responsive: true,
      legend: {
        display: false
      },
      animation: {
        animateScale: true,
        animateRotate: true
      },
      plugins: {
        labels: {
          // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
          render: 'value',
          fontSize: 12,
          fontStyle: 'bold',
          fontColor: '#000',
          fontFamily: '"Lucida Console", Monaco, monospace'
        }
      },
      tooltips: { enabled: false },
      hover: { mode: null }
    }

    dashboardService.getSummaryData().then(() => {
      this.getChartData()
    })
  }

  getChartData () {
    const locative = this.summaryData$.locative
    const venales = this.summaryData$.venales
    const deviction = this.summaryData$.deviction
    const others = this.summaryData$.others

    this.chartData = {
      datasets: [{
        data: [locative, venales, deviction, others],
        backgroundColor: this.chartInfos.map((item: any) => item.backgroundColor)
      }],
      labels: this.chartInfos.map((item: any) => item.label)
    }
  }
}
