
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// The @Component decorator indicates the class is a Vue component
@Component({})

export default class FormattedNegativeNumber extends Vue {
    @Prop({ default: 0.00 }) value: string | null
    @Prop({ default: '' }) placeholder: string
    @Prop({ default: false }) disabled: boolean
    @Prop({ default: false }) readonly: boolean
    @Prop({ default: false }) prefix: boolean
    @Prop({ default: false }) suffix: boolean
    formattedValue: string = ''
    activeInput: boolean = false

    @Watch('value')
    onValueChanged (val: any) {
      if (val !== '-') {
        this.formattedValue = this.formatNumber(this.value || 0, this.activeInput ? 0 : 2) !== 'NaN' ? this.formatNumber(this.value || 0, this.activeInput ? 0 : 2) : ''
      }
    }

    created () {
      this.formattedValue = this.formatNumber(this.value || 0, 2) !== 'NaN' ? this.formatNumber(this.value || 0, 2) : ''
    }

    handleFocus (): void {
      this.activeInput = true
      if (!this.readonly && !this.disabled) {
        this.formattedValue = ''
      }
    }

    handleBlur (): void {
      this.activeInput = false
      if (!this.readonly && !this.disabled && this.formattedValue === '') {
        this.formattedValue = this.formatNumber(this.value || 0, 2) !== 'NaN' ? this.formatNumber(this.value || 0, 2) : ''
      }
    }

    changeValue (event: any): void {
      if (this.formattedValue !== '-' && event.key !== ',' && this.formattedValue !== '-0,0' && this.formattedValue !== '0,0' && this.formattedValue !== '-0') {
        const changed = this.reverseFormatNumber(this.formattedValue, 'fr')
        this.formattedValue = this.formatNumber(changed, this.activeInput ? 0 : 2)
        this.$emit('input', changed)
      }
    }

    formatNumber (number: any, minimumFractionDigits: number = 0): string {
      if (number !== '-' && number != null) {
        return new Intl.NumberFormat('fr-FR', {
          style: 'decimal',
          minimumFractionDigits,
          maximumFractionDigits: 2
        }).format(number)
      } else {
        return number
      }
    }

    reverseFormatNumber (val: string, locale: string): number | string | null {
      if (val !== '-' && val != null) {
        const group: string = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '')
        const decimal: string = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '')
        const reversedVal: string = val.replace(new RegExp('\\' + group, 'g'), '')
        const reversedNumber = parseFloat(reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.'))
        return Number.isNaN(reversedNumber) ? null : reversedNumber
      }
      return val
    }
}
