
import { Component, Vue } from 'vue-property-decorator'
import { authService } from '../../services/auth.service'
import { userService, Note } from '../../services/user.service'
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions () {
    return {
      authUser$: authService.onChange
    }
  },
  methods: {}
})

export default class DashboardNote extends Vue {
  loading: boolean = false
  authUser$: any = {}
  content: string = ''
  selectedNote: Note = new Note()
  showingPanel: boolean = false

  showPanel () {
    this.showingPanel = !this.showingPanel
  }

  createNote () {
    if (this.content) {
      const note = new Note({
        content: this.content,
        userId: this.authUser$.id
      })
      this.loading = true
      userService.createNote(note).then(() => {
        authService.refreshUser().then(() => {
          this.loading = false
          this.content = ''
        }, () => {
          this.loading = false
        })
      }, () => {
        this.loading = false
      })
    }
  }

  updateNote () {
    if (this.selectedNote.content) {
      userService.updateNote(this.selectedNote).then(() => {
        authService.refreshUser().then(() => {
          this.selectedNote = new Note()
        })
      })
    }
  }

  handleCommand (note: Note, command: string) {
    if (command === 'update') {
      this.selectedNote = note
    } else if (command === 'delete') {
      this.deleteNote(note)
    }
  }

  deleteNote (note: Note) {
    if (this.authUser$ && this.authUser$.notes) {
      const index = this.authUser$.notes.findIndex((item: Note) => item.id === note.id)
      this.authUser$.notes.splice(index, 1)
    }
    userService.deleteNote(note.id).then(() => {
      authService.refreshUser()
    })
  }

  /*
            Ticket : https://app.asana.com/0/1169730782888125/1181571004050692/f
        * */
  onInputKeyup (event: any, note?: Note) {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault()
      if (note) {
        this.updateNote()
      } else {
        this.createNote()
      }
    }
  }
}
