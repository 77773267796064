
import { Component, Prop, Vue } from 'vue-property-decorator'
import { quoteService } from '@/services/quote.service'
import { userService, User } from '@/services/user.service'

// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions () {
    return {
      quote$: quoteService.onChangeQuote,
      user$: userService.onChange
    }
  }
})

export default class QuoteFilesRequested extends Vue {
  @Prop({ default: false }) updateable: boolean
  user$: User = new User()
  quote$: any = {}

  addDemande (file: any) {
    file.rows.push({
      demand: false,
      received: false,
      content: ''
    })
  }

  setAllDemand (file: any) {
    file.rows.forEach((row: any) => {
      row.demand = file.demand
    })
  }

  setAllReceived (file: any) {
    file.rows.forEach((row: any) => {
      row.received = file.received
    })
  }

  changeDemand (row: any) {
    if (this.updateable) {
      row.demand = !row.demand
    }
  }

  changeReceived (row: any) {
    if (this.updateable) {
      row.received = !row.received
    }
  }
}
