
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Courrier, CourrierContact, CourrierType, Courthouse, LegalFolder } from '@/models/legalFolder.model'
import { legalFolderService } from '@/services/legalFolder.service'
import { Quote, QuoteContact, quoteService } from '@/services/quote.service'
import { Contact, contactService } from '@/services/contact.service'
import { DocAPI } from '@/utils/courrierDocAPI'
import { ContactAddress, contactAddressService } from '@/services/contactAddress.service'

@Component({
  subscriptions () {
    return {
      legalFolder$: legalFolderService.onChangeLegalFolder,
      courrierTypes$: legalFolderService.onChangeCourrierTypes,
      courthouses$: legalFolderService.onChangeCourthouses,
      quote$: quoteService.onChangeQuote,
      allContacts$: contactService.onChange,
      allContactAddress$: contactAddressService.onChangeAllContactAddress
    }
  },
  components: {}
})

export default class CourrierManagement extends Vue {
  @Prop({ default: false }) updateable: boolean
  courrierTypes$: CourrierType[]
  legalFolder$: LegalFolder
  quote$: Quote
  allContacts$: Contact[]
  allContactAddress$: ContactAddress[]
  courthouses$: Courthouse[]
  courthouseValue = 'courrier_courthouse_select'

  rules: any = {}

  created () {
    legalFolderService.getCourrierTypes()
    legalFolderService.getCourthouses()
    contactAddressService.getAllContactAddress()
  }

  get courrierContacts (): any[] {
    return this.quote$.contacts.filter((quoteContact: QuoteContact) => {
      return !!quoteContact.contactId
    }).map((quoteContact: QuoteContact) => {
      const contact: any = quoteContact.contact
      const email = contact[`email${quoteContact.email}`]
      let contactAddress: any = null
      if (quoteContact.contactAddressId) {
        contactAddress = this.allContactAddress$.find((contactAddress: ContactAddress) => contactAddress.id === quoteContact.contactAddressId)
      }
      return new CourrierContact({
        contact,
        contactAddress,
        email,
        interlocuteur: quoteContact.interlocuteur,
        mandator: quoteContact.mandator,
        type: quoteContact.type
      })
    })
  }

  get courrierTypeOptions () {
    const self: any = this
    return function (index: number): CourrierType[] {
      return self.courrierTypes$.filter((courrierType: CourrierType) => {
        if (self.legalFolder$.courriers[index].courthouse) {
          return courrierType.isCourthouse
        } else {
          return !courrierType.isCourthouse
        }
      })
    }
  }

  get address () {
    const self: any = this
    return function (index: number): string {
      const courrier = self.legalFolder$.courriers[index]
      if (courrier.courthouse) {
        return self.legalFolder$.courthouse.address
      } else if (courrier.contact1Id) {
        const courrierContact = self.courrierContacts.find((item: any) => item.contact && item.contact.id === courrier.contact1Id)
        if (courrierContact && courrierContact.contactAddress) {
          courrier.contact1AddressId = courrierContact.contactAddress.id
          return `${courrierContact.contactAddress.buildingNumber} ${courrierContact.contactAddress.street} ${courrierContact.contactAddress.zipCode} ${courrierContact.contactAddress.city}`
        }
      }
      return ''
    }
  }

  get nom1 () {
    const self: any = this
    return function (index: number): any {
      const courrier = self.legalFolder$.courriers[index]
      if (courrier.courthouse) {
        return self.courthouseValue
      } else {
        return courrier.contact1Id
      }
    }
  }

  changeNom1 (value: any, index: number): void {
    if (value === 'courrier_courthouse_select') {
      this.legalFolder$.courriers[index].courthouse = true
      this.legalFolder$.courriers[index].contact1Id = null
    } else {
      this.legalFolder$.courriers[index].courthouse = false
      this.legalFolder$.courriers[index].contact1Id = value
    }
    this.legalFolder$.courriers[index].contact2Id = null
    this.legalFolder$.courriers[index].courrierTypeId = null
  }

  addCourrier (index: number): void {
    legalFolderService.addCourrier(index)
  }

  deleteCourrier (index: number): void {
    legalFolderService.deleteCourrier(index)
  }

  generate (courrier: Courrier): void {
    if (!courrier.courrierTypeId) {
      this.$message({
        message: 'Veuillez sélectionner un type de courrier.',
        type: 'error',
        offset: 65
      })
      return
    }
    // assign data to generate document
    const courrierContact1 = this.courrierContacts.find((item: any) => item.contact && item.contact.id === courrier.contact1Id)
    if (courrierContact1) {
      courrier.contact1 = courrierContact1
      if (courrierContact1.contactAddress) {
        courrier.contact1Address = courrierContact1.contactAddress
      }
    }
    const courrierContact2 = this.courrierContacts.find((item: any) => item.contact && item.contact.id === courrier.contact2Id)
    if (courrierContact2) {
      courrier.contact2 = courrierContact2
    }
    const courrierType = this.courrierTypes$.find((item: any) => item.id === courrier.courrierTypeId)
    if (courrierType) {
      courrier.courrierType = courrierType
    }

    const courrierData = JSON.parse(JSON.stringify(courrier))
    const courrierContactsData = JSON.parse(JSON.stringify(this.courrierContacts))
    const docApi: DocAPI = new DocAPI(courrierData, courrierContactsData)
    docApi.generate()
  }

  validate (): Promise<boolean> {
    return new Promise((resolve: any) => {
      (this.$refs.emailForm as any).validate((valid: boolean) => {
        resolve(valid)
      })
    })
  }
}
