
import { Component, Prop, Vue } from 'vue-property-decorator'
import QuoteContactComponent from './QuoteContact.vue'
import { quoteService, Quote } from '@/services/quote.service'
import ContactDialog from '@/components/contact/ContactDialog.vue'
// The @Component decorator indicates the class is a Vue component
@Component({
  name: 'QuoteContacts',
  subscriptions () {
    return {
      quote$: quoteService.onChangeQuote
    }
  },
  components: {
    ContactDialog,
    QuoteContactComponent
  }
})

export default class QuoteContacts extends Vue {
  @Prop({ default: false }) updateable: boolean
  quote$: Quote = new Quote()

  validate (): Promise<boolean> {
    const promiseList: any[] = []
    this.quote$.contacts.forEach((item: any, index: number) => {
      promiseList.push(((this.$refs[index] as any)[0]).validate())
    })
    return new Promise((resolve: any, reject: any) => {
      Promise.all(promiseList).then((values: any[]) => {
        const inValid = values.filter((item) => item === false)
        resolve((inValid.length === 0))
      }, () => {
        reject(new Error())
      })
    })
  }
}
