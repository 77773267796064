
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { tap } from 'rxjs/operators'
import { ROLE_TYPE_OPTIONS } from '@/services/constants.service'
import { contactService, Contact } from '@/services/contact.service'
import { quoteService, QuoteContact, Quote } from '@/services/quote.service'
import { userService, User } from '@/services/user.service'
import { billingService } from '@/services/billing.service'
import { reportService } from '@/services/report.service'
import { contactAddressService } from '@/services/contactAddress.service'
import { frontUrl } from '@/services/api.client'

// The @Component decorator indicates the class is a Vue component
@Component({
  name: 'QuoteContact',
  subscriptions (this: any) {
    return {
      quote$: quoteService.onChangeQuote,
      user$: userService.onChange,
      contacts$: contactService.onChange,
      contact$: contactService.onChangeContact.pipe(
        tap((res: any) => {
          if (res.id && res.id === this.model.contactId) {
            this.model.telephone1 = res.telephone1
            this.model.contactType = res.contactType
            contactAddressService.getContactAddresses(res.id)
            const emails: any[] = []
            if (res.email1) {
              emails.push({
                value: 1,
                label: res.email1
              })
            }
            if (res.email2) {
              emails.push({
                value: 2,
                label: res.email2
              })
            }
            if (emails.length > 0) {
              if (!this.model.email) {
                this.model.email = emails[0].value
              }
            }
            this.emails = emails
          }
        })
      ),
      addresses$: contactAddressService.onChange.pipe(
        tap((res: any[]) => {
          if (res.length > 0) {
            if (res[0].contactId === this.model.contactId) {
              const addresses: any[] = []
              res.forEach((item: any) => {
                addresses.push({
                  value: item.id,
                  label: (item.buildingNumber || '') + ' ' + (item.street || '') + ' ' + (item.zipCode || '') + ' ' + (item.city || '')
                })
              })
              if (addresses.length > 0) {
                if (!this.model.contactAddressId) {
                  this.model.contactAddressId = addresses[0].value
                }
              }
              this.addresses = addresses
            }
          }
        })
      )
    }
  }
})

export default class QuoteContactComponent extends Vue {
  @Prop({ default: false }) updateable: boolean
  @Prop({ default: '' }) type: string
  @Prop({ default: 0 }) index: number
  @Prop({ default: new QuoteContact() }) model: QuoteContact

  @Watch('model')
  onModelChanged (val: QuoteContact, oldVal: QuoteContact) {
    if (val.contactId != null && val.contactId !== oldVal.contactId) {
      setTimeout(() => {
        contactService.intQuoteContact(this.model.contactId)
      }, 100)
    }
  }

  quote$: Quote = new Quote()
  user$: User = new User()
  emails: any[] = []
  addresses: any[] = []
  roleTypeOptions: any[] = []
  filteredRoleTypeOptions: any[] = []
  contactOptions: any[]

  rules: any = {
    contactId: [
      { required: true, message: 'Merci de sélectionner au moins un contact', trigger: 'blur' }
    ]
  }

  created (): void {
    if (this.model.contactId != null) {
      setTimeout(() => {
        contactService.intQuoteContact(this.model.contactId)
      }, 100)
    }
    this.roleTypeOptions = JSON.parse(JSON.stringify(ROLE_TYPE_OPTIONS))
    this.filteredRoleTypeOptions = this.roleTypeOptions
    this.get_contact_name_options()
  }

  filterRoleTypeOptions (query: string) {
    this.filteredRoleTypeOptions = quoteService.filterOptions(query, 9, this.roleTypeOptions)
  }

  selectContact (contactId: number): void {
    this.model.contactAddressId = ''
    this.model.email = null
    this.model.contactId = contactId
    this.model.contact = contactService.setContact(contactId)
    billingService.updateAvailableContacts()
    reportService.updateAvailableContacts()
    contactService.getContactNames()
  }

  selectRole (): void {
    reportService.updateAvailableContacts()
  }

  deleteContact (): void {
    quoteService.deleteContact(this.type, this.index)
    billingService.updateAvailableContacts()
    reportService.updateAvailableContacts()
  }

  addContact (): void {
    quoteService.addContact(this.type, this.index)
  }

  goToContactEdit (): void {
    window.open(frontUrl + 'contact/' + this.model.contactId, '_blank')
  }

  refreshContact (): void {
    // get contact & list
    if (this.model.contactId) {
      contactService.getContactById(this.model.contactId.toString())
    }
    contactService.getContactNames()
  }

  validate (): Promise<boolean> {
    return new Promise((resolve: any) => {
      (this.$refs.ruleForm as any).validate((valid: boolean) => {
        resolve(valid)
      })
    })
  }

  filterContacts (filterValue: any) {
    if (filterValue != null && filterValue.length > 0) {
      contactService.getContactNamesByKey(filterValue)
    } else {
      contactService.getContactNames()
    }
  }

  get_contact_name_options () {
    contactService.onChange.subscribe((item: Contact[]) => {
      this.contactOptions = []
      item.forEach(itemOne => {
        this.contactOptions.push(itemOne)
      })
    })
    return this.contactOptions
  }
}
