
import { Component, Prop, Vue } from 'vue-property-decorator'
import { fileService, CategoryTree } from '@/services/file.service'

@Component({
  subscriptions (this: any) {
    return {
      categoryTree$: fileService.onChangeTree
    }
  }
})
export default class SelectFolderOfUpload extends Vue {
  @Prop({ type: Array }) filesToUpload: any
  @Prop({ type: Object }) selectedDropZone: any

  categoryTree$: any = null
  selectedFolder: any = {}
  active: boolean = false
  options: any[] = []
  rules: any = {
    selectedCategoryId: [
      {
        required: true,
        message: 'Le dossier est obligatoire',
        trigger: 'change'
      }
    ]
  }

  setCategoriesOptions (category: any): any[] {
    const children: any = []
    let index = 0
    if (category.length > 0) {
      category.forEach((item: any) => {
        children.push({ id: item.id, label: item.type })
        if (item.subCategories.length > 0) {
          children[index].children = this.setCategoriesOptions(item.subCategories)
        } else {
          children[index].children = []
        }
        index++
      })
    }
    return children
  }

  open (): void {
    this.active = true
    const optionList: any = []
    let index = 0
    this.categoryTree$.forEach((mainCategory: any) => {
      optionList.push({ id: mainCategory.id, label: this.label(mainCategory.type) })
      const child = this.getCategories(mainCategory.type)
      if (child.length > 0) {
        optionList[index].children = this.setCategoriesOptions(child)
      } else {
        optionList[index].children = []
      }
      index++
    })
    this.options = optionList
    this.$nextTick(() => {
      (this.$refs.selectFolderOfUpload as any).resetFields()
    })
  }

  close (): void {
    this.active = false
    this.$emit('stopUploadFiles')
  }

  async handleSave (): Promise<void> {
    if (this.selectedFolder.selectedCategoryId != null) {
      this.$emit('startUpload', this.selectedFolder)
      this.active = false
    }
  }

  getCategories (type: string): CategoryTree[] {
    const category = this.categoryTree$.find((item: CategoryTree) => item.type === type)
    return (category && category.subCategories) || []
  }

  selectFolder (val: any) {
    this.selectedFolder.selectedCategoryId = val.id
    this.selectedFolder.selectedType = this.reverselabel(val.label)
  }

  get isLegalFolder (): boolean {
    return this.$route.name === 'legal-folder'
  }

  label (type: string): string {
    let label = ''
    switch (type) {
      case 'LM' :
        label = 'Lettre de mission'
        break
      case 'Bill':
        label = 'Factures'
        break
      case 'SettledFolderReport':
      case 'LegalFolderReport':
        label = 'Rapports'
        break
      case 'Random':
        label = 'Pièces de travails'
        break
      case 'Joined':
        label = 'Fichiers reçus'
        break
      default:
        label = ''
        break
    }
    return label
  }

  reverselabel (type: string): string {
    let label = ''
    switch (type) {
      case 'Lettre de mission' :
        label = 'LM'
        break
      case 'Factures':
        label = 'Bill'
        break
      case 'Rapports':
        label = this.isLegalFolder ? 'LegalFolderReport' : 'SettledFolderReport'
        break
      case 'Pièces de travails':
        label = 'Random'
        break
      case 'Fichiers reçus':
        label = 'Joined'
        break
      default:
        label = type
        break
    }
    return label
  }
}
