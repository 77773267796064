import { apiClient } from '@/services/api.client'
import { BehaviorSubject } from 'rxjs'

export const TYPES = ['Actes', 'Plans', 'Surfaces', 'Rapport', 'Autres']

export type TypesContainer = Record<string, any>

export class AppFile {
  id: number
  name: string
  created_on: string
  bucket_name: string
  type: string
  order: number

  constructor (file: any = {}) {
    this.id = file.id
    this.name = file.name
    this.created_on = file.created_on
    this.bucket_name = file.bucket_name
    this.type = file.type
    this.order = file.order
  }
}

class ReferenceFileService {
  private _files: TypesContainer = {}
  private _loading: TypesContainer = {}
  public onChange: TypesContainer = {}
  public onLoading: TypesContainer = {}

  constructor () {
    TYPES.forEach((type) => {
      this._files[type] = new BehaviorSubject<AppFile[]>([])
      this._loading[type] = new BehaviorSubject<boolean>(true)
      this.onChange[type] = this._files[type].asObservable()
      this.onLoading[type] = this._loading[type].asObservable()
    })
  }

  public initFiles (files: AppFile[] = []): void {
    TYPES.forEach((type, index) => {
      const filtered = files.filter((file: AppFile) => file.type === type)
      this._files[type].next(filtered)
      this._loading[type].next(false)
    })
  }

  public getAllReferenceFiles (referenceId: string): void {
    TYPES.forEach((type, index) => {
      this.getReferenceFilesById(referenceId, type).then(r => {}).catch((err) => {
        throw err
      })
    })
  }

  public async getReferenceFiles (referenceId: string, type: string): Promise<void> {
    this._loading[type].next(true)
    await apiClient.get('reference-files?type=' + type).then(res => {
      const filtered = res.data.filter((file: any) => {
        return file.type === type && file.reference.id === referenceId
      })
      this._files[type].next(filtered)
      this._loading[type].next(false)
    }).catch(() => {
      this._loading[type].next(false)
    })
  }

  public async getReferenceFilesById (referenceId: string, type: string): Promise<void> {
    this._loading[type].next(true)
    await apiClient.get('reference-files/' + type + '/' + referenceId).then(res => {
      this._files[type].next(res.data)
      this._loading[type].next(false)
    }).catch(() => {
      this._loading[type].next(false)
    })
  }

  public async uploadReferenceFile (file: File, type: string, referenceId: string): Promise<any> {
    const formData = new FormData()
    formData.append('reference-files', file)
    formData.append('type', type)
    formData.append('referenceId', referenceId)
    return await apiClient.post('reference-files/upload', formData)
  }

  public async deleteReferenceFile (id: number, type: string): Promise<void> {
    this._loading[type].next(true)
    await apiClient.delete('reference-files/' + id.toString()).then(res => {
      this._loading[type].next(false)
    }).catch(() => {
      this._loading[type].next(false)
    })
  }

  public async changeReferenceOrder (newOrders: any[], type: string): Promise<void> {
    this._loading[type].next(true)
    await apiClient.post('reference-files/order', {
      newOrders
    }).then(res => {
      this._loading[type].next(false)
    }).catch(() => {
      this._loading[type].next(false)
    })
  }

  public async getImageUrl (id: number): Promise<any> {
    return await apiClient.get('reference-files/image/' + id.toString() + '/url').then(res => {
      return res
    }).catch((err) => {
      return err
    })
  }

  public async getImageUrlByRefId (id: number): Promise<any> {
    return await apiClient.get(id.toString() + '/reference-files/image/url').then(res => {
      return res
    }).catch((err) => {
      return err
    })
  }
}

export const referenceFileService = new ReferenceFileService()
