
import { Component, Prop, Vue } from 'vue-property-decorator'
import { reportService, Report } from '@/services/report.service'
import { userService, User } from '@/services/user.service'
import FormattedFloat from '../utils/FormattedFloat.vue'
    // The @Component decorator indicates the class is a Vue component
    @Component({
      subscriptions () {
        return {
          report$: reportService.onChangeReport,
          user$: userService.onChange
        }
      },
      components: {
        FormattedFloat
      }
    })

export default class ReportAnalysis extends Vue {
        @Prop({ default: false }) updateable: boolean
        report$: Report = new Report()
        user$: User = new User()

        changePages (item: any): void {
          item.pages = Math.floor(item.pages || 0)
        }
}
