<template>
  <el-form class="el-form--custom d-flex">
    <perfect-scrollbar>
      <div class="filter-advanced--filters">
        <el-row
          :gutter="20"
          type="flex">
          <el-col>
            <el-form-item label="">
              <el-radio-group
                v-model="filters.type"
                size="mini"
                style="margin-top: 15px">
                <el-radio-button label="Personne Morale" />
                <el-radio-button label="Tout" />
                <el-radio-button label="Personne Physique" />
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          :gutter="20"
          type="flex"
          class="mb-4">
          <el-col
            :lg="6"
            :sm="5">
            <el-input
              v-model="filters.lastname"
              placeholder="Entrez un nom" />
          </el-col>
          <el-col
            v-if="filters.type !== 'Personne Morale'"
            :lg="6"
            :sm="5">
            <el-input
              v-model="filters.firstname"
              placeholder="Entrez un prénom" />
          </el-col>
          <el-col
            v-if="filters.type !== 'Personne Physique'"
            :lg="6"
            :sm="5">
            <el-input
              v-model="filters.siret"
              placeholder="Entrez un siret" />
          </el-col>
        </el-row>
        <el-row
          :gutter="20"
          type="flex">
          <el-col :span="6">
            <el-input
              v-model="filters.brand"
              placeholder="Entrez une enseigne" />
          </el-col>
          <el-col
            v-if="filters.type !== 'Personne Morale'"
            :span="6">
            <el-select
              v-model="filters.title"
              placeholder="Sélectionner un titre">
              <el-option
                v-for="item in titles"
                :key="item.value"
                :label="item.label"
                :value="item.value" />
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-input
              v-model="filters.activite"
              placeholder="Entrez une activite" />
          </el-col>
        </el-row>
      </div>
      <div class="btn-container">
        <div class="d-flex align-items-center justify-content-center">
          <el-button
            class="btn-black"
            @click="searchGlobalItems">
            Rechercher ({{ globalItemsTotal$ }})
          </el-button>
          <el-button @click="() => $emit('reset-filters')">
            Réinitialiser
          </el-button>
        </div>
      </div>
    </perfect-scrollbar>
  </el-form>
</template>

<script>
import { searchGlobalItemsService } from '@/services/searchGlobalItems.service'
export default {
  subscriptions () {
    return {
      globalItemsTotal$: searchGlobalItemsService.onChangeGlobalItemsTotal,
      filtersCount$: searchGlobalItemsService.onChangeFiltersCount,
      filters: searchGlobalItemsService.onChangeContactFilters
    }
  },

  data () {
    return {
      titles: [
        {
          value: '',
          label: ''
        },
        {
          value: 'Indivision',
          label: 'Indivision'
        },
        {
          value: 'Consorts',
          label: 'Consorts'
        },
        {
          value: 'Maître',
          label: 'Maître'
        },
        {
          value: 'Monsieur',
          label: 'Monsieur'
        },
        {
          value: 'Madame',
          label: 'Madame'
        }
      ]
    }
  },

  methods: {
    searchGlobalItems () {
      searchGlobalItemsService.setIsLoading(true)
      this.$emit('search', this.filters)
    }
  }
}
</script>

<style>
</style>
