<template>
  <div class="saved-search">
    <div class="saved-search-header">
      <h2 class="mb-3">
        Mes recherches sauvegardées
      </h2>
      <div class="result-number">
        <span>{{ historyTotal$ }}</span> recherches sauvegardées
      </div>
      <el-button
        class="close"
        @click="$emit('closeModal', null)">
        <i class="rb-close" />
      </el-button>
    </div>
    <div class="saved-search-body mt-4">
      <div class="saved-search-body-top mb-3">
        <div class="d-flex align-items-center justify-content-end ml-3">
          Récent
          <el-button class="black ml-3">
            <i class="rb-sync_alt" />
          </el-button>
        </div>
      </div>
      <div class="saved-search-list">
        <perfect-scrollbar>
          <el-table
            :data="historyItems$"
            style="width: 100%">
            <el-table-column
              prop="title"
              label="Nom"
              sortable
              width="100" />
            <el-table-column
              prop="date"
              label="Date de création"
              sortable
              width="150">
              <template slot-scope="scope">
                <span class="date">{{ scope.row.createdAt }}</span>
                <span class="date-pub">{{ scope.row.createdSince }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="auteur"
              label="Auteur">
              <template slot-scope="scope">
                <div class="d-flex align-items-center justify-content-start author">
                  <img :src="require('../../assets/img/picto.png')">
                  <span>{{ scope.row.createdBy.nom + ' ' + scope.row.createdBy.prenom }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="result.total"
              label="Résultats"
              width="100" />
            <el-table-column
              label="Action"
              width="100">
              <template slot-scope="scope">
                <el-button
                  class="btn-action"
                  @click="$emit('closeModal', scope.row.id)">
                  <i class="rb-pencil" />
                </el-button>
                <el-button
                  class="btn-action"
                  @click="deleteHistory(scope.row)">
                  <i class="rb-trash-alt" />
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>
<script>

import { historyService } from '@/services/history.service'

export default {
  emits: ['closeModal'],
  subscriptions () {
    return {
      historyItems$: historyService.onChangeHistory,
      historyTotal$: historyService.onChangehistoryTotal
    }
  },
  mounted () {
    historyService.getHistory()
  },
  methods: {
    deleteHistory (elt) {
      this.$confirm('Voulez-vous supprimer définitivement cette recherche ?', '', {
        confirmButtonText: 'Confirmation',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(() => {
        historyService.deleteHistory(elt.id)
      }).catch((err) => {
        this.$message({
          message: 'Une erreur est survenue lors de la suppresion de la recherche.',
          type: 'error',
          offset: 65
        })
        throw err
      })
    }
  }
}
</script>
