
import { Component, Prop, Vue } from 'vue-property-decorator'
import { tap } from 'rxjs/operators'
import { baseUrl } from '@/services/api.client'
import { billingService, Billing } from '@/services/billing.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import { contactAddressService } from '@/services/contactAddress.service'
import FormattedFloat from '../utils/FormattedFloat.vue'
import { legalFolderService } from '@/services/legalFolder.service'
import { LegalFolder } from '@/models/legalFolder.model'
import { COURTHOUSE_BILLING } from '@/services/constants.service'
import { shortcutsMonthDatePicker } from '@/utils/constant'
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions (this: any) {
    return {
      user$: userService.onChange,
      legalFolder$: legalFolderService.onChangeLegalFolder,
      billing$: billingService.onChangeBilling.pipe(
        tap((res: any) => {
          const oldDepositInvoices = (this.billing$ && this.billing$.depositInvoices) || []
          res.depositInvoices.forEach((item: any, index: number) => {
            if (oldDepositInvoices[index] && oldDepositInvoices[index].contactId === item.contactId) {
              return
            }
            setTimeout(() => {
              item.contactId && contactAddressService.getContactAddresses(item.contactId)
            }, 100)
          })
        })
      ),
      contactAddress: contactAddressService.onChange.pipe(
        tap((res: any[]) => {
          if (res.length > 0) {
            this.billing$.depositInvoices.forEach((item: any, index: number) => {
              if (item.contactId === res[0].contactId) {
                const addresses: any[] = []
                res.forEach((item: any) => {
                  addresses.push({
                    value: item.id,
                    label: item.address || ''
                  })
                })
                if (addresses.length > 0) {
                  if (!item.contactAddressId) {
                    item.contactAddressId = addresses[0].value
                  }
                }
                item.addresses = addresses
                this.billing$.depositInvoices.splice(index, 1, item)
              }
            })
          }
        })
      )
    }
  },
  components: {
    FormattedFloat
  }
})

export default class BillingDepositInvoices extends Vue {
  @Prop({ default: false }) disabled: boolean

  loading: number = -1
  baseUrl: string | undefined = baseUrl
  billing$: Billing = new Billing()
  user$: User = new User()
  legalFolder$: LegalFolder = new LegalFolder()
  COURTHOUSE_BILLING = COURTHOUSE_BILLING
  shortcutsMonthDatePicker = shortcutsMonthDatePicker

  get updateable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.BILLING_SECTION_UPDATE) && !this.disabled
  }

  selectContact (contactId: string, item: any, itemIndex: number): void {
    if (contactId === this.COURTHOUSE_BILLING) {
      item.contactId = this.COURTHOUSE_BILLING
      item.courthouseId = this.legalFolder$.courthouse ? this.legalFolder$.courthouse.id : null
      item.contactAddressId = this.legalFolder$.courthouse ? this.legalFolder$.courthouse.address : ''
      item.contactName = null
      this.billing$.depositInvoices.splice(itemIndex, 1, item)
    } else {
      const contact = this.billing$.availableContacts.find((contact) => contactId === contact.id)
      if (contact) {
        item.courthouseId = null
        item.contactId = contactId
        item.contactName = contact.nom + (contact.prenom ? ' ' + contact.prenom : '')
        item.contactAddressId = ''
        this.billing$.depositInvoices.splice(itemIndex, 1, item)
        contactAddressService.getContactAddresses(contactId)
      }
    }
  }

  generateDocument (index: number, item: any): void {
    if (this.loading === -1) {
      this.loading = index
      billingService.generateBillingDocument('deposit', index, item.number).then(() => {
        this.loading = -1
      }, () => {
        this.loading = -1
      })
    }
  }

  generateBillNumber (index: number): void {
    billingService.saveBillingRow('deposit', this.billing$.depositInvoices[index]).then((res: any) => {
      this.billing$.depositInvoices[index].id = res.row.id
      this.billing$.depositInvoices[index].number = res.row.number
    })
  }

  deleteDepositInvoice (index: number): void {
    billingService.deleteDepositInvoice(index)
  }

  addDepositInvoice (index: number): void {
    billingService.addDepositInvoice(index)
  }

  changeAmount (): void {
    billingService.updateDepositInvoice()
  }
}
