<template>
  <div
    class="result d-flex align-items-center justify-content-start"
    :class="[{ active: (result.id === activeId) }, 'ref-color']"
    @click="handleClickOnItem(result)">
    <figure>
      <i class="rb-ref" />
    </figure>
    <div class="description">
      <div class="desc-top d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-between align-items-center tag-container">
          <el-tag
            v-if="result.reference.typeLocal"
            class="mr-2">
            {{ getLocalTypeLabel(result.reference.typeLocal) }}
          </el-tag>
          <el-tag
            v-if="result.reference.typeTransaction"
            class="mr-2">
            {{ getAbbreviation(getTransactionTypeLabel(result.reference.typeTransaction)) }}
          </el-tag>
          <el-tag
            v-if="result.reference.source"
            class="mr-2">
            {{ result.reference.source }}
          </el-tag>
          <el-tag v-if="result.reference.enseigneLocataire">
            {{ result.reference.enseigneLocataire }}
          </el-tag>
          <el-tag v-if="result.reference.activite">
            {{ getShortLabel(getActiviteLabel(result.reference.activite)) }}
          </el-tag>
        </div>
        <div
          class="desc-top-right d-flex justify-content-between align-items-end"
          :class="{ active: result.isPin }">
          <el-tooltip
            class="item"
            effect="dark"
            content="Epingler"
            placement="right">
            <el-button
              :class="{ active: result.isPin }"
              @click="pinResult(result)">
              <i
                v-if="result.isPin"
                class="rb-pin" />
              <i
                v-else
                class="rb-push_pin" />
            </el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Miniature"
            placement="right">
            <el-button
              :class="{ active: iseye }"
              @click="showMiniature">
              <i
                v-if="iseye"
                class="rb-visibility1" />
              <i
                v-else
                class="rb-visibility" />
            </el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Résultats associés"
            placement="right">
            <el-button
              @click="showAssociateResult">
              <i
                v-if="result.isassoc"
                class="rb-resultats-associe" />
              <i
                v-else
                class="rb-resultats-associe-alt" />
            </el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Ouvrir"
            placement="right">
            <el-button
              @click="handleClick(result.reference.id)">
              <i class="rb-open-new-alt" />
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <div>
        <h5 class="text-uppercase">
          {{ result.reference.adresse }}
        </h5>
      </div>
      <div
        v-if="result.reference.typeValeur === 1"
        class="desc-detail d-flex justify-content-start align-items-center">
        <div
          v-if="result.reference.loyerP"
          class="d-flex justify-content-between align-items-start mr-3">
          <i class="rb-euro_black mr-2" />
          {{ numberWithSpaces(Number(result.reference.loyerP).toFixed(1)) + ' €/m2 P' }}
        </div>
        <div
          v-else-if="result.reference.loyerM2"
          class="d-flex justify-content-between align-items-start mr-3">
          <i class="rb-euro_black mr-2" />
          {{ numberWithSpaces(Number(result.reference.loyerM2).toFixed(1)) + ' €/m2' }}
        </div>
        <div
          v-if="result.reference.surfaceP"
          class="d-flex justify-content-between align-items-start mr-3">
          <i class="rb-space_dashboard mr-2" />
          {{ numberWithSpaces(Number(result.reference.surfaceP).toFixed(1)) + ' m2 P' }}
        </div>
        <div
          v-else-if="result.reference.surfaceM2"
          class="d-flex justify-content-between align-items-start mr-3">
          <i class="rb-space_dashboard mr-2" />
          {{ numberWithSpaces(Number(result.reference.surfaceM2).toFixed(1)) + ' m2' }}
        </div>
        <div
          v-if="result.reference.loyerDecapitalise"
          class="d-flex justify-content-between align-items-start mr-3">
          <i class="rb-euro_black mr-2" />
          {{ numberWithSpaces(Number(result.reference.loyerDecapitalise).toFixed(1)) + ' €/m2 D' }}
        </div>
        <div
          v-if="result.reference.dateEffetBailStart"
          class="d-flex justify-content-between align-items-start mr-3">
          <i class="rb-calendrier mr-2" />
          {{ 'Date de Bail: '+ fromFrToEnDate(result.reference.dateEffetBailStart) }}
        </div>
      </div>
      <div
        v-else
        class="desc-detail d-flex justify-content-start align-items-center">
        <div
          v-if="result.reference.surfaceM2"
          class="d-flex justify-content-between align-items-start mr-3">
          <i class="rb-space_dashboard mr-2" />
          {{ numberWithSpaces(Number(result.reference.surfaceM2).toFixed(1)) + ' m2' }}
        </div>
        <div
          v-if="result.reference.prixHd"
          class="d-flex justify-content-between align-items-start mr-3">
          <i class="rb-euro_black mr-2" />
          {{ numberWithSpaces(Number(result.reference.prixHd).toFixed(1)) + ' € HD' }}
        </div>
        <div
          v-if="result.reference.prixHdPerCa"
          class="d-flex justify-content-between align-items-start mr-3">
          <i class="rb-euro_black mr-2" />
          {{ numberWithSpaces(Number(result.reference.prixHdPerCa).toFixed(1)) + ' €/m2 HD' }}
        </div>
        <div
          v-if="result.reference.tauxRendement"
          class="d-flex justify-content-between align-items-start mr-3">
          <i class="rb-space_dashboard mr-2" />
          {{ result.reference.tauxRendement }}
        </div>
        <div
          v-if="result.reference.dateVenteStart"
          class="d-flex justify-content-between align-items-start mr-3">
          <i class="rb-calendrier mr-2" />
          {{ 'Date de Vente: ' + fromFrToEnDate(result.reference.dateVenteStart) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listReferenceService } from '@/services/listReference.service'
import {
  getLocalTypeLabel,
  getTransactionTypeLabel,
  getActiviteLabel,
  getAbbreviation,
  getShortLabel,
  numberWithSpaces,
  fromFrToEnDate
} from '@/utils/functions'
import { searchGlobalItemsService } from '@/services/searchGlobalItems.service'
import { tap } from 'rxjs/operators'

export default {
  subscriptions () {
    return {
      listReferences$: listReferenceService.onChangeListReferences,
      loading$: searchGlobalItemsService.onLoading,
      activeId$: searchGlobalItemsService.onChangeActiveId.pipe(tap((res) => {
        if (res) {
          this.activeId = res
        }
      }))
    }
  },
  props: {
    result: {
      type: Object,
      required: true
    },
    isDetail: {
      type: Boolean,
      require: false
    },
    isResultAssoc: {
      type: Boolean,
      require: false
    }
  },
  emits: ['pin', 'click', 'result', 'detail', 'miniature'],
  data () {
    return {
      activeId: null,
      iseye: false
    }
  },
  methods: {
    handleClickOnItem (item) {
      this.$emit('click')
      this.isDetail && this.showMiniature()
      this.isResultAssoc && this.showAssociateResult()
      searchGlobalItemsService.changeActiveId(item.id)
    },
    handleClick (id) {
      const route = this.$router.resolve({ path: '/edit-ref/' + id })
      window.open(route.href)
    },
    handleResult () {
      this.$emit('result', true)
    },
    pinResult (result) {
      this.$emit('pin', result)
    },
    showMiniature () {
      this.iseye = !this.iseye
      this.$emit('miniature', this.result)
    },
    getLocalTypeLabel (value) {
      return getLocalTypeLabel(value, this.listReferences$)
    },
    getTransactionTypeLabel (value) {
      return getTransactionTypeLabel(value, this.listReferences$)
    },
    getActiviteLabel (value) {
      return getActiviteLabel(value, this.listReferences$)
    },
    getAbbreviation (value) {
      return getAbbreviation(value)
    },
    getShortLabel (value) {
      return getShortLabel(value)
    },
    numberWithSpaces (value) {
      return numberWithSpaces(value)
    },
    showAssociateResult () {
      this.$emit('result', this.result)
    },
    fromFrToEnDate (value) {
      return fromFrToEnDate(value)
    }
  }
}
</script>
