
import RichEditor from '@/components/element/RichEditor.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Recipient, Email, emailService, EmailTemplate } from '@/services/email.service'
import { fileService, AppFile } from '@/services/file.service'
import { quoteService, Quote, QuoteContact } from '@/services/quote.service'
import { contactService, Contact } from '@/services/contact.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import { legalFolderService } from '@/services/legalFolder.service'
import { LegalFolder } from '@/models/legalFolder.model'
import { SettledFolder, settledFolderService } from '@/services/settledFolder.service'
import { billingService, Billing } from '@/services/billing.service'
import { reportService, Report } from '@/services/report.service'
import { convertFrNumber, formattedDate, formattedHour } from '@/utils/functions'
import moment from 'moment'
@Component({
  subscriptions () {
    return {
      quote$: quoteService.onChangeQuote,
      report$: reportService.onChangeReport,
      billing$: billingService.onChangeBilling,
      legalFolder$: legalFolderService.onChangeLegalFolder,
      settledFolder$: settledFolderService.onChangeFolder,
      user$: userService.onChange,
      allContacts$: contactService.onChange,
      allUsers$: userService.onChangeUsers,
      files$: fileService.onChange,
      emailTemplates$: emailService.onChangeEmailTemplates
    }
  },
  components: {
    RichEditor
  },
  methods: {
    convertFrNumber,
    formattedDate,
    formattedHour
  }
})

export default class EmailManagement extends Vue {
  @Prop({ default: false }) disabled: boolean

  email: Email = new Email()
  quote$: Quote = new Quote()
  legalFolder$: LegalFolder | null = null
  settledFolder$: SettledFolder | null = null
  billing$: Billing | null = null
  report$: Report | null = null
  user$: User = new User()
  allUsers$: User[]
  allContacts$: Contact[]
  emailTemplates$: any[]
  files$: AppFile[] = []
  rules: any = {}
  typeLabel: any = {
    Joined: 'Fichiers Reçus',
    Random: 'Autres',
    LM: 'Lettre de mission',
    Bill: 'Factures',
    SettledFolderReport: 'Rapports',
    LegalFolderReport: 'Rapports'
  }

  get readable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.MAIL_SECTION_READ)
  }

  get updateable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.MAIL_SECTION_UPDATE) && !this.disabled
  }

  created () {
    this.refresh()
  }

  refresh () {
    userService.getUsers()
    emailService.getEmailTemplates()
    this.email = new Email()
    /* TODO refactor */
    setTimeout(() => {
      (this.$refs.emailForm as any).clearValidate()
    }, 500)
  }

  get emailTemplates (): any[] {
    let emailTemplates: any[] = this.emailTemplates$

    if (this.$route.name === 'legal-folder') {
      emailTemplates = emailTemplates.filter((template: any) => {
        return template.id !== 1 && template.id !== 2
      })
    } else {
      emailTemplates = emailTemplates.filter((template: any) => {
        return template.id === 1 || template.id === 2 || template.id === 11 || template.id === 12
      })
    }

    return emailTemplates
  }

  get users (): any[] {
    const users: any[] = this.allUsers$
    const index = users.findIndex((user: User) => user.email === 'robine@robine-associes.com')
    const cutOut = users.splice(index, 1)[0] // cut the element at index 'from'
    users.splice(0, 0, cutOut)
    return users.filter((user: User) => {
      return user && user.email && user.email.replace(/\s/g, '') !== ''
    })
  }

  get recipientOptions (): Recipient[] {
    const recipients: Recipient[] = this.quote$.contacts.filter((quoteContact: QuoteContact) => {
      return quoteContact.interlocuteur && !!quoteContact.contactId
    }).map((quoteContact: QuoteContact) => {
      const contact: any = quoteContact.contact
      const email = contact[`email${quoteContact.email}`]
      return new Recipient({
        nom: contact.nom,
        prenom: contact.prenom,
        email,
        title: contact.title,
        contactType: contact.contactType
      })
    })
    const filteredRecipients: Recipient[] = []
    const uniqueKeyArray: string[] = []
    recipients.forEach((recipient: Recipient) => {
      if (uniqueKeyArray.indexOf(recipient.email) === -1) {
        filteredRecipients.push(recipient)
        uniqueKeyArray.push(recipient.email)
      }
    })
    filteredRecipients.push(new Recipient({
      nom: '',
      prenom: '',
      email: 'robine@robine-associes.com',
      title: '',
      contactType: ''
    }))
    return filteredRecipients
  }

  get fileOptions () {
    const fileOptions: any[] = []
    Object.keys(this.typeLabel).forEach((type: string) => {
      const optionGroup: any = { label: this.typeLabel[type], options: [] }
      this.getFiles(type).forEach((file: AppFile) => {
        optionGroup.options.push(file)
      })
      fileOptions.push(optionGroup)
    })
    return fileOptions
  }

  get folderName () {
    if (this.$route.name === 'settled-folder') {
      return this.settledFolder$ ? this.settledFolder$.name : ''
    } else if (this.$route.name === 'legal-folder') {
      return this.legalFolder$ ? this.legalFolder$.name : ''
    } else {
      return this.quote$ ? this.quote$.name : ''
    }
  }

  get applicantMandatorContact () {
    const applicantMandatorContact = this.quote$.contacts.find((contact: any) => {
      return contact.type === 'applicant' && contact.mandator === true
    })
    if (applicantMandatorContact != null && applicantMandatorContact.contact != null) {
      return `${applicantMandatorContact.contact.nom} ${applicantMandatorContact.contact.prenom ? applicantMandatorContact.contact.prenom : ''}`
    }
    return ''
  }

  get respondentMandatorContact () {
    const respondentMandatorContact = this.quote$.contacts.find((contact: any) => {
      return contact.type === 'respondent' && contact.mandator === true
    })
    if (respondentMandatorContact != null && respondentMandatorContact.contact != null) {
      return `${respondentMandatorContact.contact.nom} ${respondentMandatorContact.contact.prenom ? respondentMandatorContact.contact.prenom : ''}`
    }
    return ''
  }

  get address () {
    const address = this.quote$.addresses[0]
    let addressString = ''
    if (address.buildingNumber) {
      addressString += address.buildingNumber + ' '
    }
    if (address.street) {
      addressString += address.street + ', '
    }
    if (address.zip) {
      addressString += address.zip + ' '
    }
    if (address.city) {
      addressString += address.city
    }
    return addressString
  }

  get montantBilling () {
    if (this.billing$) {
      const montantFacture = this.billing$.invoiceAmount * 1.2
      const consignationAmount = this.billing$.consignationAmount || 0
      return convertFrNumber(montantFacture - consignationAmount)
    }
    return 0
  }

  get lastInvoice () {
    if (this.billing$) {
      const invoices = this.billing$.invoices
      return invoices.reduce(function (prev: any, current: any) {
        return (moment(prev.date).unix() > moment(current.date).unix()) ? prev : current
      })
    }

    return null
  }

  get amountLeft () {
    let totalInvoice = 0
    let totalAcompte = 0
    if (this.billing$) {
      totalInvoice = this.billing$.invoiceAmount * 1.2
      this.billing$.depositInvoices.forEach((depositInvoice) => {
        if (depositInvoice.amount) {
          totalAcompte += depositInvoice.amount * 1.2
        }
      })
    }
    return totalInvoice - totalAcompte
  }

  get recipientTitle () {
    const recipients = this.email.recipients
    if (recipients.length > 0) {
      if (recipients[0].title) {
        return recipients[0].title
      }
    }

    return 'Madame, Monsieur'
  }

  get dynamicContents () {
    const dynamicContents: any[] = [
      {
        id: 'folderName',
        value: this.folderName
      },
      {
        id: 'applicantMandatorContact',
        value: this.applicantMandatorContact
      },
      {
        id: 'respondentMandatorContact',
        value: this.respondentMandatorContact
      },
      {
        id: 'address',
        value: this.address
      },
      {
        id: 'sectionNumber',
        value: this.legalFolder$ ? this.legalFolder$.sectionNumber : ''
      },
      {
        id: 'meetingDateSite',
        value: this.legalFolder$ ? formattedDate(this.legalFolder$.meetingDateSite).toUpperCase() : ''
      },
      {
        id: 'meetingHour',
        value: this.legalFolder$ ? formattedHour(this.legalFolder$.meetingHour) : ''
      },
      {
        id: 'meetingDateOffice',
        value: this.legalFolder$ ? formattedDate(this.legalFolder$.meetingDateOffice).toUpperCase() : ''
      },
      {
        id: 'meetingHourOffice',
        value: this.legalFolder$ ? formattedHour(this.legalFolder$.meetingHourOffice) : ''
      },
      {
        id: 'limitDateOfReception',
        value: this.legalFolder$ ? formattedDate(this.legalFolder$.limitDateOfReception).toUpperCase() : ''
      },
      {
        id: 'dateOfFinalDeposit/dateOfSimulation',
        value: this.legalFolder$ ? formattedDate(this.legalFolder$.dateOfSimulation || this.legalFolder$.dateOfFinalDeposit).toUpperCase() : ''
      },
      {
        id: 'dateOfFinalDepositForUsers',
        value: this.legalFolder$ ? formattedDate(this.legalFolder$.dateOfFinalDepositForUsers).toUpperCase() : ''
      },
      {
        id: 'montantFactureTTC-montantConsignation',
        value: this.montantBilling
      },
      {
        id: 'lastInvoiceNumber',
        value: this.lastInvoice ? this.lastInvoice.number : ''
      },
      {
        id: 'lastInvoiceDate',
        value: this.lastInvoice ? formattedDate(this.lastInvoice.date).toUpperCase() : ''
      },
      {
        id: 'lastInvoiceAmount',
        value: this.amountLeft ? convertFrNumber(this.amountLeft || 0) : 0
      },
      {
        id: 'recipientTitle',
        value: this.recipientTitle
      }
    ]
    return dynamicContents
  }

  getFiles (type: string): AppFile[] {
    return this.files$.filter((file: AppFile) => file.type === type)
  }

  changeSender (user: User): void {
    if (user.id === -1) {
      this.email.sender = null
    }
  }

  changeEmailTemplate (emailTemplate: EmailTemplate): void {
    if (emailTemplate.id === -1) {
      this.email.template = null
      this.email.subject = ''
      this.email.content = ''
      return
    }
    this.adjustEmailTemplate(emailTemplate)
  }

  adjustEmailTemplate (emailTemplate: EmailTemplate) {
    if (this.$route.name === 'settled-folder' || this.$route.name === 'Quote') {
      let subject = emailTemplate.subject.replace('$Reference_dossier', this.folderName)
      let content = emailTemplate.content
      this.dynamicContents.forEach((item: any) => {
        subject = subject.replace('{{' + item.id + '}}', item.value)
        content = content.split('{{' + item.id + '}}').join(item.value)
      })
      this.email.subject = subject
      this.email.content = content
    } else if (this.$route.name === 'legal-folder') {
      let subject = emailTemplate.subject
      let content = emailTemplate.content
      this.dynamicContents.forEach((item: any) => {
        subject = subject.replace('{{' + item.id + '}}', item.value)
        content = content.split('{{' + item.id + '}}').join(item.value)
      })
      this.email.subject = subject
      this.email.content = content
      let user = false
      switch (emailTemplate.id) {
        case 3:
        case 4:
          user = this.users.find((user: any) => {
            return user.id === 9
          })
          if (user) {
            const recipient = new Recipient(user)
            this.email.cci.push(recipient)
          }
          break
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          if (this.report$) {
            const experts = this.report$.experts.filter((expert: any) => {
              return expert.writer === true
            })
            experts.forEach((expert: any) => {
              const recipient = new Recipient(expert.user)
              this.email.cci.push(recipient)
            })
          }
          break
      }
    }
  }

  validate (): Promise<boolean> {
    return new Promise((resolve: any) => {
      (this.$refs.emailForm as any).validate((valid: boolean) => {
        resolve(valid)
      })
    })
  }

  sendEmail () {
    this.rules = {
      recipients: [
        { type: 'array', required: true, message: 'Un destinataire est obligatoire !', trigger: 'change' }
      ],
      sender: [
        { required: true, message: 'Un envoyeur est obligatoire !', trigger: 'change' }
      ],
      subject: [
        { required: true, message: 'L\'objet est obligatoire !', trigger: 'change' }
      ],
      content: [
        { required: true, message: 'Le contenu est obligatoire !', trigger: 'change' }
      ]
    }
    setTimeout(() => {
      (this.$refs.emailForm as any).validate((valid: boolean) => {
        if (valid) {
          this.$confirm('Validez-vous l’envoi du mail ?', '', {
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            type: 'info'
          }).then(() => {
            emailService.sendEmail(this.email).then((res:any) => {
              if (res.data && res.data.result === true) {
                this.$message({
                  type: 'success',
                  message: 'Email envoyé !',
                  offset: 65
                })
                this.refresh()
              } else {
                this.$message({
                  type: 'error',
                  message: "L'envoi du mail a échoué!",
                  offset: 65
                })
              }
            })
          })
        }
      })
    }, 100)
  }
}
