
import { Component, Vue } from 'vue-property-decorator'
import BlockWrapper from '@/components/wrappers/Block.vue'
import SearchTable from '@/components/search/SearchTable.vue'
import SearchCriteria from '@/components/search/SearchCriteria.vue'
import { saveSearchService } from '@/services/saveSearch.service'
    @Component({
      subscriptions () {
        return {
          searches$: saveSearchService.onChangeSearches,
          loading$: saveSearchService.onLoading,
          totalSearches$: saveSearchService.onChangeTotalSearches
        }
      },
      components: {
        BlockWrapper,
        SearchTable,
        SearchCriteria
      }
    })
export default class SearchView extends Vue {
  filterActive: boolean = false
  proSearch: string = ''
  loading$ : boolean = false

  created () {
  }

  handleFilter () {
    this.filterActive = !this.filterActive
  }

  goSearchRef () {
    window.open(this.$router.resolve({ path: '/search-ref' }).href, '_blank')
  }

  clearFilter () {
    saveSearchService.getSearches('default')
  }
}
