<template>
  <div class="auth-wrap d-flex align-items-center pb-5 justify-content-center text-dark">
    <div class="w-100 mb-5 pb-5">
      <el-row class="px-3 d-flex w-100 justify-content-center">
        <el-col :md="8">
          <el-alert
            class="mb-3"
            :title="warning"
            type="warning alert"
            show-icon
            :closable="false" />
          <el-form
            ref="forgotPasswordForm"
            :model="formData"
            label-width="120px"
            label-position="left"
            :rules="formRules"
            @submit.prevent.native="submitForm('forgotPasswordForm')">
            <el-form-item
              label="Email"
              prop="email"
              class="mb-4 pb-2">
              <el-input
                v-model="formData.email"
                class="block"
                type="email"
                autocomplete="on"
                placeholder="xxx@xxx.com" />
            </el-form-item>
            <div class="d-flex justify-content-around mt-5">
              <el-button
                :loading="loading"
                type="primary"
                class="el-button status-btn el-button--default is-round"
                @click="submitForm('forgotPasswordForm')">
                Modifier mon mot de passe
              </el-button>
            </div>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { authService } from '@/services/auth.service'
import { userService } from '@/services/user.service'

export default {
  name: 'ForgotPassword',
  subscriptions () {
    return {
      user: authService.onChange
    }
  },
  data () {
    return {
      loading: false,
      warning: 'Saisissez l\'adresse e-mail que vous utilisez habituellement pour vous connecter et cliquez sur "Modifier mon mot de passe". Nous vous enverrons par e-mail un lien qui vous permettra de réinitialiser votre mot de passe.',
      formData: {
        email: ''
      },
      formRules: {
        email: [
          { required: true, message: 'L’adresse email doit être renseigné', trigger: 'blur' },
          { type: 'email', message: 'Veuillez une adresse email valide', trigger: ['blur'] }
        ]
      }
    }
  },
  created () {
    if (this.user) {
      this.$router.push({ path: '/' })
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true
          userService.sendResetPasswordLink(this.formData.email).then(
            () => {
              this.loading = false
              this.$message({
                message: 'Un email de réinitialisation du mot de passe a été envoyé',
                type: 'success',
                offset: 65
              })
              this.$router.replace('/connexion')
            },
            () => {
              this.loading = false
              this.$message({
                message: 'Erreur!, Veuillez réessayer plus tard.',
                type: 'error',
                offset: 65
              })
            }
          )
        }
        return valid
      })
    }
  }
}
</script>
