
import { Component, Vue } from 'vue-property-decorator'

@Component({
  props: {
    content: String
  }
})
export default class TdItem extends Vue {

}
