
import { Component, Vue } from 'vue-property-decorator'
import { tap } from 'rxjs/operators'
import { Ged } from '@/models/ged.model'
import { gedService } from '@/services/ged.service'
import L, { Layer } from 'leaflet'
import { MAP_BOX_URL, MAP_BOX_ATTRIBUTION } from '@/constants'
import { listReferenceService, ReferenceAddress } from '@/services/listReference.service'

export class MarkerIcon extends Layer {
  id?: number
  latitude?: number
  longitude?: number
  dossierId?: number
}

@Component({
  name: 'MapGED',
  subscriptions (this: any) {
    return {
      geds$: gedService.onChangeGeds.pipe(
        tap(() => {
          setTimeout(() => {
            if (!this.map) {
              return
            }
            this.removeMarkers()
            this.drawMarkers()
          })
        })
      ),
      activeGedIndex$: gedService.onChangeActiveGedIndex.pipe(
        tap((index: any) => {
          if (index === null) {
            return
          }
          this.activeMarker(index)
        })
      )
    }
  },
  components: {}
})
export default class MapGED extends Vue {
  geds$: Ged[]
  map: any = null
  tileLayer: any = null
  markersLayer: any = null
  cordinates = {
    latitude: 48.86,
    longitude: 2.34
  }

  active: false
  viewAddress: string = ''
  searchMarker: any = null

  created () {
  }

  mounted () {
    this.initMap()
  }

  initMap () {
    const mapContainer: any = L.DomUtil.get('map')
    if (mapContainer != null) {
      mapContainer._leaflet_id = null
    }
    this.map = new (L.map as any)('map', {
      zoomControl: true,
      dragging: true,
      renderer: L.canvas()
    }).setView([this.cordinates.latitude, this.cordinates.longitude], 13)
    this.tileLayer = L.tileLayer(MAP_BOX_URL, {
      maxZoom: 18,
      attribution: MAP_BOX_ATTRIBUTION
    })
    this.tileLayer.addTo(this.map)
  }

  removeMarkers () {
    if (this.markersLayer !== null) {
      this.markersLayer.clearLayers()
    }
  }

  drawMarkers () {
    const markers: any = []
    const previousLat: Array<any> = []
    this.geds$.forEach((ged: Ged, index: number) => {
      if (!ged.address) {
        return
      }
      let latitude = ged.lat
      let longitude = ged.lng

      // permit ot check if one marker is in same place
      let countMarker = 0
      previousLat.forEach((c: any) => {
        countMarker += c.lat === ged.lat && c.lng === ged.lng ? 1 : 0
      })
      if (countMarker > 0) {
        latitude += (countMarker / 10000)
        longitude += (countMarker / 10000)
      }

      // check if document have address marker
      if (!latitude || !longitude || (latitude === 0 && longitude === 0)) {
        return
      }

      const MarkerIcon: MarkerIcon = L.marker([latitude, longitude], {
        icon: L.divIcon({
          className: 'custom-div-icon',
          html: `<div id="maker${index}" class='marker-pin' style="opacity: 1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32">
                                        <g fill="none" fill-rule="evenodd">
                                            <path fill="#810D00" d="M15.427 29.435c-8.039-11.52-9.53-12.701-9.53-16.935C5.896 6.701 10.651 2 16.517 2c5.867 0 10.623 4.701 10.623 10.5 0 4.234-1.492 5.416-9.53 16.935a1.337 1.337 0 0 1-2.184 0z"/>
                                        </g>
                                    </svg>
                                    <p style="font-size: ${(index + 1) > 10 ? '9px' : '12px'}; margin: ${(index + 1) > 10 ? '8px auto' : '5px auto'};">${index + 1}</p>
                                    <?xml version="1.0" encoding="utf-8"?>
                                </div>`,
          iconSize: [30, 42],
          iconAnchor: [15, 42]
        })
      }).on('mouseover', () => {
        this.handleMarkerClick(index)
      }).on('click', () => {
        this.handleMarkerClick(index)
      })
      MarkerIcon.id = index
      MarkerIcon.latitude = latitude
      MarkerIcon.longitude = longitude

      // crate marker
      markers.push(MarkerIcon)
      previousLat.push({ lat: ged.lat, lng: ged.lng })
    })
    if (markers.length > 0) {
      this.markersLayer = L.layerGroup(markers)
      this.markersLayer.addTo(this.map)
    } else {
      if (!this.markersLayer) {
        return
      }
      this.markersLayer.remove()
    }
  }

  handleMarkerClick (index: number) {
    gedService.activeGedIndex(index)
    if (this.searchMarker) {
      this.searchMarker.remove()
    }
  }

  /* search map */
  querySearch (queryString: string, cb: Function) {
    if (!queryString) return
    listReferenceService.getReferenceAddresse(queryString).then(res => {
      cb(res)
    })
  }

  handleSelectViewAddress (item: ReferenceAddress) {
    if (this.searchMarker) {
      this.searchMarker.remove()
    }
    this.cordinates.latitude = item.lat
    this.cordinates.longitude = item.lon
    let zoom = 10
    switch (item.layer) {
      case 'macroregion':
      case 'region':
      case 'macrocounty':
        zoom = 11
        break
      case 'locality':
      case 'localadmin':
        zoom = 12
        break
      case 'borough':
      case 'neighbourhoods':
      case 'coarse':
      case 'postalcode':
        zoom = 15
        break
      case 'venue':
      case 'address':
      case 'street':
        zoom = 19
        break
      default:
        break
    }
    this.map.setView([this.cordinates.latitude, this.cordinates.longitude], zoom)
    this.searchMarker = L.marker([this.cordinates.latitude, this.cordinates.longitude], {
      icon: L.divIcon({
        className: 'custom-div-icon',
        html: `<div class='marker-pin' style="opacity: 1">
                                <?xml version="1.0" encoding="utf-8"?>
                                <!-- Generator: Adobe Illustrator 24.1.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 512 512" xml:space="preserve" height="35">
                                    <style type="text/css">
                                        .searchMap{fill:#1C3283;}
                                    </style>
                                    <g>
                                        <g>
                                          <path class="searchMap" d="M256,0c-70.6,0-128,57.4-128,128c0,67,51.7,122,117.3,127.5v245.9c0,5.9,4.8,10.7,10.7,10.7
                                            s10.7-4.8,10.7-10.7V255.5C332.3,250,384,195,384,128C384,57.4,326.6,0,256,0z M256,64c-35.3,0-64,28.7-64,64
                                            c0,5.9-4.8,10.7-10.7,10.7s-10.7-4.8-10.7-10.7c0-47.1,38.3-85.3,85.3-85.3c5.9,0,10.7,4.8,10.7,10.7C266.7,59.2,261.9,64,256,64z
                                            "/>
                                        </g>
                                    </g>
                                </svg>
                            </div>`,
        iconSize: [30, 42],
        iconAnchor: [15, 42]
      })
    })
    this.searchMarker.addTo(this.map)
  }

  activeMarker (index: number) {
    const elements = document.getElementsByClassName('marker-pin')
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i]
      if (!element) {
        continue
      }
      element.classList.remove('active')
      if (!element.parentElement) {
        continue
      }
      element.parentElement.style.zIndex = `-${(i + 1) * 70}`
    }
    const element = document.getElementById(`maker${index}`)
    if (!element) {
      return
    }
    element.classList.add('active')
    if (!element.parentElement) {
      return
    }
    element.parentElement.style.zIndex = '1000'

    if (this.active) {
      return
    }
    const layer = this.markersLayer.getLayers().find((Layer: MarkerIcon) => Layer.id === index)
    if (!layer) {
      return
    }
    this.map.setView([layer.latitude, layer.longitude], 13)
    if (this.searchMarker) {
      this.searchMarker.remove()
    }
  }
}
