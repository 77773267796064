
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import {
  ListReference,
  listReferenceService,
  referenceService
} from '@/services/listReference.service'
import _ from 'lodash'
import {
  periodString,
  convertTimeStampToDateAndTime,
  isFloat
} from '@/utils/functions'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import { MAP_STATE_1, MAP_STATE_2, MAP_STATE_3 } from '@/constants'
import ThItem from './ThItem.vue'
import TdItem from './TdItem.vue'
import ZoomRange from './ZoomRange.vue'
import DownloadModal from './DownloadModal.vue'
import {
  baseBlockItems,
  baseBlockWithoutTypeLocal
} from '../../addReference/blockItems'
import {
  tableBaseItems,
  tableLocativeItems,
  tableValeurItems
} from '../../addReference/itemList'

@Component({
  subscriptions (this: Vue) {
    return {
      user$: userService.onChange,
      loading: listReferenceService.onLoading
    }
  },
  components: {
    ThItem,
    TdItem,
    ZoomRange,
    DownloadModal
  }
})
export default class SearchedTable extends Vue {
  @Prop({ type: Array })
    searchTableResult: any

  @Prop({ type: Array })
    initialResult: any

  @Prop({ type: Array })
    typeTransaction: ListReference[]

  @Prop({ type: Array })
    typeValeur: ListReference[]

  @Prop({ type: Array })
    typeLocalItemsList: ListReference[]

  @Prop({ type: Array })
    activites: ListReference[]

  @Prop({ type: Number })
    goToDivId: number

  @Prop({ type: Object })
    searchCondition: any

  @Prop({ type: Boolean })
    showTable: boolean

  loading: boolean

  user$: User = new User()
  currentSortDir = 'asc'
  rating = 0
  toggleState = MAP_STATE_2
  MAP_STATE_1 = MAP_STATE_1
  MAP_STATE_2 = MAP_STATE_2
  MAP_STATE_3 = MAP_STATE_3
  stateInt = 2

  convertTimeStampToDateAndTime = convertTimeStampToDateAndTime
  periodString: Function = periodString
  isFloat = isFloat
  tableBaseItems = tableBaseItems
  tableLocativeItems = tableLocativeItems
  tableValeurItems = tableValeurItems
  baseBlockWithoutTypeLocal = baseBlockWithoutTypeLocal
  isHidden : boolean = false

  get extractable (): boolean {
    return (
      userService.hasRight(this.user$, USER_RIGHTS.EXTRACTION_50_DAY) ||
      userService.hasRight(this.user$, USER_RIGHTS.EXTRACTION_150_DAY) ||
      userService.hasRight(this.user$, USER_RIGHTS.EXTRACTION_NL_DAY)
    )
  }

  get numberResults (): Number {
    let results = 0
    this.searchTableResult.map((table: any) => {
      results += table.data.length
      return results
    })
    return results
  }

  displayMap () {
    this.stateInt = 1
    this.$emit('toggleView', MAP_STATE_1)
  }

  displayTable () {
    this.stateInt = 3
    this.$emit('toggleView', MAP_STATE_3)
  }

  handleStateInt () {
    this.toggleState = `MAP_STATE_${this.stateInt}`
    this.$emit('toggleView', this.toggleState)
  }

  handleChangeScreenshotModel (value: boolean) {
    this.$emit('changeScreenshotModel', value)
  }

  handleDownload () {
    (this.$refs.downloadModal as any).open()
  }

  download (isNumberFormat: boolean): void {
    this.$emit('download', isNumberFormat)
  }

  transactionCondition (value: number, condition: string): boolean {
    if (value === null) {
      return false
    }
    return this.getTransactionLabel(value) === condition
  }

  getFormattedValue (key: string, value: any) {
    return referenceService.getFormattedValue(key, value)
  }

  getTransactionLabel (typeTransaction: Number) {
    let label = ''
    const target: any = this.typeTransaction.filter(
      (item: any) => item.num === typeTransaction
    )
    target.length > 0 ? (label = target[0].label) : (label = '')

    return label
  }

  getActivity (activites: Number) {
    let label = ''
    const target: any = this.activites.filter(
      (item: any) => item.num === activites
    )
    target.length > 0 ? (label = target[0].label) : (label = '')

    return label
  }

  checkDisplayBaseCondition (title: string) {
    if (!this.searchCondition.typeLocal && title) {
      return baseBlockWithoutTypeLocal.includes(title)
    } else {
      const transactionLabel = this.getLocalLabel(this.searchCondition.typeLocal)
      return (
        baseBlockItems[transactionLabel] &&
              !baseBlockItems[transactionLabel].includes(title)
      )
    }
  }

  getResultTypeValeur () {
    let typeValeur = 0
    this.searchTableResult.forEach((table: any) => {
      if (table.data.length === 1) {
        typeValeur = table.data[0].typeValeur
      }
    })
    return typeValeur
  }

  checkDisplayLocativeCondition () {
    const typeValeur = this.searchCondition.ficheId ? this.getResultTypeValeur() : this.searchCondition.typeValeur
    return typeValeur === 1
  }

  checkDisplayValeurCondition () {
    const typeValeur = this.searchCondition.ficheId ? this.getResultTypeValeur() : this.searchCondition.typeValeur
    return typeValeur === 2
  }

  getLocalLabel (typeLocal: Number) {
    let label = ''
    const target: any = this.typeLocalItemsList.filter(
      (item: any) => item.num === typeLocal
    )
    target.length > 0 ? (label = target[0].label) : (label = '')

    return label
  }

  changeRating (referenceId: Number, newRating: Number, key: any) {
    const prevRating: Number = this.searchTableResult[key].data.find((ref: any) => ref.id === referenceId).rating
    if (prevRating === newRating) {
      newRating = 0
    }
    this.searchTableResult[key].data.find((ref: any) => ref.id === referenceId).rating = newRating
    this.$emit('changeRatingSavedSearch', referenceId, newRating, key)
    /* listReferenceService.updateRating(referenceId, rating).then((data: any) => {
     this.searchTableResult[key].data.find((ref: any) => ref.id === referenceId).rating = data.rating;
     return data;
    }); */
  }

  @Watch('goToDivId', { immediate: true, deep: true })
  goToDiv () {
    if (this.goToDivId) {
      const elmnt = document.getElementById(this.goToDivId.toString())
      if (elmnt) {
        elmnt.scrollIntoView()
      }
    }
  }

  @Emit('updatedSearchResult')
  hideReference (key: any, index: any) {
    this.searchTableResult[key].data[index].hide = !this.searchTableResult[key]
      .data[index].hide
    this.initialResult[key].data[index].hide = !this.initialResult[key].data[
      index
    ].hide
    return this.searchTableResult
  }

  @Emit('updatedSearchResult')
  foldTable (key: any) {
    this.searchTableResult[key].fold = !this.searchTableResult[key].fold
    return this.searchTableResult
  }

  @Emit('updatedSearchResult')
  removeHiddenRefs () {
    this.isHidden = true
    this.searchTableResult.forEach((table: any) => {
      table.data = _.remove(table.data, function (reference: any) {
        return reference.hide === false
      })
    })
    return this.searchTableResult
  }

  @Emit('updatedSearchResult')
  handleHideAllRefsInTable (tableKey: number) {
    this.searchTableResult[tableKey].headerHideAllRef = !this.searchTableResult[tableKey].headerHideAllRef
    this.isHidden = !this.isHidden
    this.searchTableResult[tableKey].data.forEach((ref: any) => {
      if (this.searchTableResult[tableKey].headerHideAllRef) {
        ref.hide = true
      } else {
        ref.hide = false
      }
    })
    return this.searchTableResult
  }

  @Emit('updatedSearchResult')
  showHiddenRefs () {
    this.isHidden = false
    return JSON.parse(JSON.stringify(this.initialResult))
  }

  @Emit('updatedSearchResult')
  sort (key: any, type: string) {
    this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
    if (this.searchTableResult[key]) {
      if (type === 'dateDeffetBail' || type === 'dateVente') {
        type = type + 'Start'
      }
      this.searchTableResult[key].data = _.orderBy(
        this.searchTableResult[key].data,
        [type],
        [this.currentSortDir]
      )
    }
    return this.searchTableResult
  }

  handleSelectRef (selectedTableKey: number, referenceKey: number) {
    this.$emit('handleSelectRef', selectedTableKey, referenceKey)
  }
}
