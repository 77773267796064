
import { Component, Prop, Vue } from 'vue-property-decorator'
import { legalFolderService } from '@/services/legalFolder.service'
import { COST_KEYS } from '@/models/legalFolder.model'
import { convertFrNumberEuro } from '@/utils/functions'
import FormattedFloat from '../../../utils/FormattedFloat.vue'

@Component({
  subscriptions () {
    return {
      legalFolder$: legalFolderService.onChangeLegalFolder
    }
  },
  components: {
    FormattedFloat
  },
  methods: {
    convertFrNumberEuro
  }
})

export default class CostsManagement extends Vue {
  @Prop({ default: false }) updateable: boolean
  COST_KEYS = COST_KEYS

  handleAmountChange (): void {
    legalFolderService.updateFolder()
  }

  handlePriceChange (): void {
    legalFolderService.updateFolder()
  }
}
