import { Courrier, CourrierContact } from '@/models/legalFolder.model'
import Docxtemplater from 'docxtemplater'
import { saveAs } from 'file-saver'
import PizZip from 'pizzip'
import { apiClient } from '@/services/api.client'

import { DocumentData } from '@/utils/courrierDocUtils'
import { getPriceString } from '@/utils/functions'

export class DocAPI {
  public renderData: any
  public docNumber: string
  public courrierTypeName: string
  public documentData: DocumentData
  public constructor (courrier: Courrier, courrierContacts: CourrierContact[]) {
    if (courrier.courrierType != null) {
      this.docNumber = courrier.courrierType.id.toString()
      this.courrierTypeName = courrier.courrierType.type
    } else {
      throw Object.assign(new Error('missing courrier type'))
    }
    this.documentData = new DocumentData(courrier, courrierContacts)
    const honoraireTotal = this.documentData.legalFolder$.modification.getTotalPrice() + this.documentData.legalFolder$.costs.getTotalPrice()
    const consignationTotal = this.documentData.consignationPayments.reduce(function (prev: number, cur) {
      return prev + parseFloat(cur.amountValue)
    }, 0)
    let showMonsieurMadame = false
    if (!this.documentData.doc8ShowCher) {
      showMonsieurMadame = this.documentData.doc8ShowMonsieurMadame
    }
    this.renderData = {
      // 1
      numDossier: this.documentData.legalFolder$.name,
      contact1Title: this.documentData.contact1Title,
      showTitle1: !!this.documentData.contact1Title,
      contact1Nom: this.documentData.contact1Nom,
      contact1Prenom: this.documentData.contact1Prenom,
      showPrenom1: !!this.documentData.contact1Prenom,
      contact2Title: this.documentData.contact2Title,
      showTitle2: !!this.documentData.contact2Title,
      contact2Nom: this.documentData.contact2Nom,
      contact2Prenom: this.documentData.contact2Prenom,
      showPrenom2: !!this.documentData.contact2Prenom,
      firstContactAddress: this.documentData.firstContactAddress,
      showAR: this.documentData.showAR,
      courrierDate: this.documentData.courrierDate,
      applicantContactsWithMandator: this.documentData.applicantContactsWithMandator,
      respondentContactsWithMandator: this.documentData.respondentContactsWithMandator,
      sectionNumber: this.documentData.legalFolder$.sectionNumber,
      showCher: this.documentData.showCher,
      courthouseNom: this.documentData.courthouseNom,
      courthouseAddress: this.documentData.courthouseAddress,
      meetingDateSite: this.documentData.meetingDateSite,
      meetingHour: this.documentData.meetingHour,
      mandatorContacts: this.documentData.mandatorContacts,
      noMandatorContacts: this.documentData.noMandatorContacts,
      interlocuteurContacts: this.documentData.interlocuteurContacts,
      // 2
      chamber: this.documentData.legalFolder$.chamber != null && this.documentData.legalFolder$.chamber !== '' ? this.documentData.legalFolder$.chamber : '',
      dateOfDecision: this.documentData.dateOfDecision,
      natureOfDecisionTitle: (this.documentData.natureOfDecision != null) ? this.documentData.natureOfDecision.title : null,
      firstAddress: this.documentData.firstAddress,
      dateDelaiLimite: this.documentData.dateDelaiLimite,
      dateProrogation: this.documentData.dateProrogation,
      // 3
      applicantContacts: this.documentData.applicantContacts,
      respondentContacts: this.documentData.respondentContacts,
      // 5
      honorairesTotal: getPriceString(honoraireTotal),
      honorairesTax: getPriceString(honoraireTotal * 0.2),
      honorairesGeneral: getPriceString(honoraireTotal * 1.2),
      consignationPayments: this.documentData.consignationPayments,
      honorairesLeft: getPriceString(honoraireTotal * 1.2 - consignationTotal),
      // 7
      firstApplicantContactWithMandatorTitle: (this.documentData.firstApplicantContactWithMandator != null) ? this.documentData.firstApplicantContactWithMandator.contact.title : null,
      firstApplicantContactWithMandatorPrenom: (this.documentData.firstApplicantContactWithMandator != null) ? this.documentData.firstApplicantContactWithMandator.contact.prenom : null,
      firstApplicantContactWithMandatorNom: (this.documentData.firstApplicantContactWithMandator != null) ? this.documentData.firstApplicantContactWithMandator.contact.nom : null,
      // 8
      doc8ShowCher: this.documentData.doc8ShowCher,
      showMonsieurMadame
    }
  }

  public generate (): void {
    apiClient.get('file/template/courrier/' + this.docNumber, { responseType: 'arraybuffer' }).then(content => {
      const zip = new PizZip(content.data)
      const doc = new Docxtemplater().loadZip(zip).setOptions({
        delimiters: {
          start: '{{',
          end: '}}'
        },
        nullGetter (part: any, scopeManager: any) {
          if (!part.module) {
            return `MISSING(${String(part.value)})`
          }
          if (part.module === 'rawxml') {
            return ''
          }
          return ''
        },
        parser: function (tag: any) {
          return {
            get (scope: any, context: any) {
              if (tag === '$index') {
                const indexes = context.scopePathItem
                return indexes[indexes.length - 1]
              }
              if (tag === '$isLast') {
                const totalLength =
                                    context.scopePathLength[context.scopePathLength.length - 1]
                const index =
                                    context.scopePathItem[context.scopePathItem.length - 1]
                return index === totalLength - 1
              }
              if (tag === '$isFirst') {
                const index =
                                    context.scopePathItem[context.scopePathItem.length - 1]
                return index === 0
              }
              return scope[tag]
            }
          }
        }
      })
      doc.setData(this.renderData)
      doc.render()
      const out = doc.getZip().generate({
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      })
      saveAs(out, `${this.courrierTypeName}-${new Date().toLocaleDateString('fr-fr')}.docx`)
    }).catch((err) => {
      throw err
    })
  }
}
