import { apiClient } from '@/services/api.client'
import { BehaviorSubject, Observable } from 'rxjs'

export interface Right {
  id: number
  nom: string
  description: string
}
// this class is responsible for managing the rights.
class RightService {
  private readonly _rights: BehaviorSubject<Right[]> = new BehaviorSubject<Right[]>([])
  public onChange: Observable<Right[]> = this._rights.asObservable() // subscribe to rights change

  get rights (): Right[] {
    return this._rights.getValue()
  }

  public async getRights (): Promise<void> {
    await apiClient.get('rights').then(res => {
      this._rights.next(res.data)
    })
  }
}

export const rightService = new RightService()
