import Vue from 'vue'
import MainWrap from './wrappers/MainWrap'
import DropDownMenu from './utils/Dropdown'
import DoughnutChart from './dashboard/DoughnutChart'
import BarChart from './dashboard/BarChart'
import LineChart from './dashboard/LineChart'

[MainWrap, DropDownMenu, DoughnutChart, BarChart, LineChart].forEach(element => {
  Vue.component(element.name, element)
})
