
import { isNil, groupBy } from 'lodash'
import { Component, Vue, Ref } from 'vue-property-decorator'
import { authService } from '@/services/auth.service'
import { USER_RIGHTS, userService } from '@/services/user.service'
import { Role, roleService } from '@/services/role.service'
import { Right, rightService } from '@/services/right.service'
import { Form } from 'element-ui/types'

@Component({
  subscriptions (this: Vue) {
    return {
      role: roleService.onChangeRole,
      rights: rightService.onChange
    }
  }
})
export default class RoleView extends Vue {
    @Ref() readonly roleForm!: Form

    role: Role = {
      id: undefined,
      nom: '',
      description: '',
      rights: []
    }

    rights: Right[] = []
    activeCategorie: string = 'Dossiers'
    rules: any = {
      nom: [
        { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' }
      ]
    }

    created () {
      const user = authService.userValue
      if (!userService.hasRight(user, USER_RIGHTS.ROLE_RIGHTS)) {
        this.$router.replace('/')
      } else {
        const roleID = isNil(this.$route.params.id) ? null : this.$route.params.id

        this.initForm()
        rightService.getRights()
        if (roleID !== null) {
          roleService.getRoleById(roleID)
        }
      }
    }

    get isCreation () {
      return isNil(this.$route.params.id)
    }

    get groupedRights () {
      const rightsObject = []
      const groupedByCategories = groupBy(this.rights, 'categorie')

      for (const right in groupedByCategories) {
        rightsObject.push({
          category: right,
          data: groupedByCategories[right]
        })
      }
      return rightsObject
    }

    initForm () {
      this.role = {
        id: undefined,
        nom: '',
        description: '',
        rights: []
      }
    }

    submitForm () {
      this.roleForm.validate((valid: any) => {
        if (valid) {
          roleService
            .saveRole(
              this.role.id,
              this.role.nom,
              this.role.description,
              this.role.rights
            )
            .then(() => {
              this.$router.push('/roles')
            })
        }
        return valid
      })
    }

    goBack () {
      this.$router.push('/roles')
    }
}
