
import { Component, Prop, Vue } from 'vue-property-decorator'
import QuoteContacts from './QuoteContacts.vue'
import QuoteAddresses from './QuoteAddresses.vue'
import QuoteMissions from './QuoteMissions.vue'
import QuoteFees from './QuoteFees.vue'
import { baseUrl } from '@/services/api.client'
import { quoteService, Quote } from '@/services/quote.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions (this: Vue) {
    return {
      quote$: quoteService.onChangeQuote,
      user$: userService.onChange
    }
  },
  components: {
    QuoteContacts,
    QuoteAddresses,
    QuoteMissions,
    QuoteFees
  }
})

export default class QuoteManagement extends Vue {
  @Prop({ default: false }) opened: boolean
  @Prop({ default: false }) disabled: boolean

  baseUrl: string | undefined = baseUrl
  quote$: Quote = new Quote()
  user$: User = new User()
  downloading: boolean = false

  validate (): Promise<boolean> {
    return (this.$refs.quoteContacts as any).validate()
  }

  generatePdf () {
    if (!this.downloading) {
      this.downloading = true
      quoteService.saveQuote().then(() => {
        return quoteService.generateDocument().then((res) => {
          this.downloading = false
          return res
        }).catch(error => {
          this.$message({
            message: "Erreur lors de la génération de la lettre de mission. Veuillez contacter le service d'administration.",
            type: 'error',
            offset: 65
          })
          this.downloading = false
          throw error
        })
      })
    }
  }

  get readable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.LM_SECTION_READ)
  }

  get updateable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.LM_SECTION_UPDATE) && !this.disabled
  }

  get canValidate (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.VDD)
  }

  submitQuote () {
    this.$emit('submit')
  }

  validateQuote () {
    const result = this.quote$.contacts.findIndex((contact: any) => contact.mandator && (!contact.telephone1 || !contact.email))
    if (result !== -1) {
      this.$confirm("Attention certains mandants n'ont pas d'adresse mail ou de numéro de téléphone renseigné. " +
        'Ceci sont nécessaires pour la signature électronique Yousign.' +
        'Voulez-vous vraiment continuer ?', '', {
        confirmButtonText: 'Confirmation',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(() => {
        this.$emit('validate')
      })
    } else {
      this.$emit('validate')
    }
  }

  refuseQuote () {
    this.$emit('refuse')
  }

  transformToPdf () {
    this.$emit('transformToPdf')
  }
}
