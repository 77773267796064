
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import L from 'leaflet'
import 'leaflet.markercluster'
import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'
import 'leaflet-defaulticon-compatibility'
import { ReferenceAddress } from '@/services/listReference.service'
import { MAP_BOX_URL, MAP_BOX_ATTRIBUTION } from '@/constants'

@Component({})
export default class MapPreview extends Vue {
  @Prop({ default: new ReferenceAddress() }) address: ReferenceAddress
  @Prop({ default: 200 }) height: number
  @Prop({ default: 0 }) transformHeight: number
  @Prop({ default: false }) allowUpdate: boolean

  map: any = null
  tileLayer: any = null
  polygonOverlayLayer: any = null
  searchMarker: any = null
  cordinates = {
    lat: 48.86,
    lon: 2.34
  }

  zoom: number = 13

  @Watch('address')
  onChangeAddress (val: ReferenceAddress) {
    if (val.lat && val.lon) {
      this.cordinates.lat = val.lat
      this.cordinates.lon = val.lon
      this.handleChangeAddress()
    }
  }

  @Watch('height')
  onChangeTransform () {
    setTimeout(() => { this.map.invalidateSize(true) }, 1000)
  }

  mounted () {
    this.initMap()
  }

  initMap () {
    const mapContainer: any = L.DomUtil.get('map-preview')
    if (mapContainer != null) {
      mapContainer._leaflet_id = null
    }
    this.map = new (L.map as any)('map-preview', {
      zoomControl: true,
      dragging: true,
      renderer: L.canvas()
    }).setView([this.cordinates.lat, this.cordinates.lon], this.zoom)
    this.tileLayer = L.tileLayer(MAP_BOX_URL, {
      maxZoom: 18,
      attribution: MAP_BOX_ATTRIBUTION
    })
    this.tileLayer.addTo(this.map)
    L.featureGroup().addTo(this.map)
    this.map.on('zoomend', (event: any) => {
      this.zoom = event.target._zoom || 13
    })
    this.map.on('click', (event: any) => {
      if (event.latlng.lat && event.latlng.lng && this.allowUpdate) {
        this.cordinates.lat = event.latlng.lat
        this.cordinates.lon = event.latlng.lng
        this.$emit('change', this.cordinates)
        this.handleChangeAddress()
      }
    })
  }

  handleChangeAddress () {
    if (this.searchMarker) {
      this.searchMarker.remove()
    }
    this.map.setView(
      [this.cordinates.lat, this.cordinates.lon],
      16
    )
    this.searchMarker = L.marker([this.cordinates.lat, this.cordinates.lon], {
      icon: L.divIcon({
        className: 'custom-div-icon',
        html: `<div class='marker-pin' style="opacity: 1">
                <?xml version="1.0" encoding="utf-8"?>
                <!-- Generator: Adobe Illustrator 24.1.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 512 512" xml:space="preserve" height="35">
                  <style type="text/css">
                    .searchMap{fill:#409eff;}
                  </style>
                  <g>
                    <g>
                      <path class="searchMap" d="M256,0c-70.6,0-128,57.4-128,128c0,67,51.7,122,117.3,127.5v245.9c0,5.9,4.8,10.7,10.7,10.7
                        s10.7-4.8,10.7-10.7V255.5C332.3,250,384,195,384,128C384,57.4,326.6,0,256,0z M256,64c-35.3,0-64,28.7-64,64
                        c0,5.9-4.8,10.7-10.7,10.7s-10.7-4.8-10.7-10.7c0-47.1,38.3-85.3,85.3-85.3c5.9,0,10.7,4.8,10.7,10.7C266.7,59.2,261.9,64,256,64z
                        "/>
                    </g>
                  </g>
                </svg>
              </div>`,
        iconSize: [30, 42],
        iconAnchor: [15, 42]
      })
    })
    this.searchMarker.addTo(this.map)
  }
}
