<template>
  <div class="sidebar">
    <div class="menu d-flex flex-column align-items-center justify-content-center">
      <div
        class="logo-side d-flex align-items-center justify-content-center"
        @click="navigateToMenu('/')">
        <img :src="require('../../assets/img/AVISIO-logo.svg')">
      </div>
      <perfect-scrollbar>
        <el-menu
          class="el-menu-vertical-demo"
          :collapse="isCollapse">
          <el-submenu
            index="1"
            :class="{'is-active':isRoute('global-search')}">
            <template slot="title">
              <a href="/global-search"><img
                class="icon"
                :src="require('../../assets/img/pipe_line/search.svg')"></a>
            </template>
            <el-menu-item
              index="1-1"
              :class="{'active':isRoute('global-search')}">
              <a href="/global-search">Rechercher</a>
            </el-menu-item>
            <el-menu-item
              index="1-2"
              :class="{'active':isRoute('/my-searches')}">
              <a href="/my-searches">Mes recherches</a>
            </el-menu-item>
          </el-submenu>
          <el-menu-item
            index="2"
            :class="{'active':isRoute('dashboard')}">
            <a href="/"><img
                          class="icon icon-default"
                          :src="require('../../assets/img/pipe_line/dashboard.svg')">
              <img
                class="icon icon-hover"
                :src="require('../../assets/img/pipe_line/dashboard-filled.svg')"></a>
            <span slot="title">Dashboard</span>
          </el-menu-item>
          <el-submenu
            index="3"
            :class="{'active':isRoute('devis') || isRoute('contact') || isRoute('dossier-judiciaire') || isRoute('add-ref') || isRoute('devis')}">
            <template slot="title">
              <img
                class="icon icon-default"
                :src="require('../../assets/img/pipe_line/plus.svg')">
              <img
                class="icon icon-hover"
                :src="require('../../assets/img/pipe_line/plus-filled.svg')">
            </template>
            <el-menu-item
              index="3-1"
              :class="{'active':isRoute('devis')}">
              <a href="/devis">Devis</a>
            </el-menu-item>
            <el-menu-item
              index="3-2"
              :class="{'active':isRoute('contact')}">
              <a href="/contact/0">Contact</a>
            </el-menu-item>
            <el-menu-item
              index="3-3"
              :class="{'active':isRoute('dossier-judiciaire')}">
              <a href="/dossier-judiciaire">Dossier judiciaire</a>
            </el-menu-item>
            <el-menu-item
              index="3-4"
              :class="{'active':isRoute('add-ref')}">
              <a href="/add-ref">Référence</a>
            </el-menu-item>
            <el-menu-item
              index="3-5"
              :class="{'active':isRoute('devis')}">
              <a @click="newGed">Document</a>
            </el-menu-item>
          </el-submenu>
          <el-submenu
            index="4"
            :class="{'active':isRoute('search-ref') || isRoute('search')}">
            <template slot="title">
              <a href="/search-ref"><img
                class="icon"
                :src="require('../../assets/img/pipe_line/ref.svg')"></a>
            </template>
            <el-menu-item
              index="4-1"
              :class="{'active':isRoute('search-ref')}">
              <a href="/search-ref">Nouvelle recherche</a>
            </el-menu-item>
            <el-menu-item
              index="4-2"
              :class="{'active':isRoute('search')}">
              <a href="/search">Mes recherches</a>
            </el-menu-item>
            <el-menu-item index="4-3">
              <a @click="openImportRefModal()">Importer des références</a>
            </el-menu-item>
          </el-submenu>
          <el-menu-item
            index="5"
            :class="{'active':isRoute('ged')}">
            <a href="/ged"><img
                             class="icon icon-default"
                             :src="require('../../assets/img/pipe_line/documentation.svg')">
              <img
                class="icon icon-hover"
                :src="require('../../assets/img/pipe_line/documentation-filled.svg')"></a>
            <span slot="title">GED</span>
          </el-menu-item>
          <el-menu-item
            index="6"
            :class="{'active':isRoute('liste-dossiers?type=quote')}">
            <a href="/liste-dossiers?type=quote"><img
                                                   class="icon icon-default"
                                                   :src="require('../../assets/img/pipe_line/devis.svg')">
              <img
                class="icon icon-hover"
                :src="require('../../assets/img/pipe_line/devis-filled.svg')"></a>
            <span slot="title">Devis</span>
          </el-menu-item>
          <el-submenu
            index="7"
            :class="{'active':isRoute('liste-dossiers?type=settled-folder') || isRoute('liste-dossiers?type=progress') || isRoute('liste-dossiers?type=closed')}">
            <template slot="title">
              <a href="/liste-dossiers?type=settled-folder"><img
                                                              class="icon icon-default"
                                                              :src="require('../../assets/img/pipe_line/dossier.svg')">
                <img
                  class="icon icon-hover"
                  :src="require('../../assets/img/pipe_line/dossier-filled.svg')"></a>
            </template>
            <el-menu-item
              index="7-1"
              :class="{'active':isRoute('liste-dossiers?type=my-settled-folder')}">
              <a href="/liste-dossiers?type=my-settled-folder">Mes dossiers</a>
            </el-menu-item>
            <el-menu-item
              index="7-2"
              :class="{'active':isRoute('liste-dossiers?type=progress')}">
              <a href="/liste-dossiers?type=progress">En cours</a>
            </el-menu-item>
            <el-menu-item
              index="7-3"
              :class="{'active':isRoute('liste-dossiers?type=closed')}">
              <a href="/liste-dossiers?type=closed">Clôturé</a>
            </el-menu-item>
          </el-submenu>
          <el-menu-item
            index="8"
            :class="{'active':isRoute('clients')}">
            <a href="/clients"><img
                                 class="icon icon-default"
                                 :src="require('../../assets/img/pipe_line/contact.svg')">
              <img
                class="icon icon-hover"
                :src="require('../../assets/img/pipe_line/contact-filled.svg')"></a>
            <span slot="title">Contacts</span>
          </el-menu-item>
          <el-submenu
            v-if="isAdmin"
            index="9"
            :class="{'active':isRoute('users') || isRoute('roles')}">
            <template slot="title">
              <img
                class="icon icon-default"
                :src="require('../../assets/img/pipe_line/parametre.svg')">
              <img
                class="icon icon-hover"
                :src="require('../../assets/img/pipe_line/parametre-filled.svg')">
            </template>
            <el-menu-item
              index="9-1"
              :class="{'active':isRoute('users')}">
              <a href="/users">Utilisateurs</a>
            </el-menu-item>
            <el-menu-item
              index="9-2"
              :class="{'active':isRoute('roles')}">
              <a href="/roles">Rôles</a>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </perfect-scrollbar>
      <div class="menu-item-wrap bottom-menu">
        <div class="sidebar-avatar mb-4">
          <el-tooltip
            class="item"
            effect="dark"
            content="Déconnexion"
            placement="right">
            <figure @click="logout()">
              <img :src="require('../../assets/img/power.svg')">
            </figure>
          </el-tooltip>
        </div>
        <div class="sidebar-avatar">
          <figure @click="goUser">
            <img :src="getAvatarUrl()">
          </figure>
          <span class="status" />
        </div>
      </div>
    </div>
    <import-ref ref="importRefModal" />
    <template v-if="allowImportFormGed">
      <FormGED />
    </template>
  </div>
</template>

<script>
import { authService } from '@/services/auth.service'
import { userService, User } from '@/services/user.service'
import ImportRef from '@/views/ImportRef.vue'
import { Ged } from '@/models/ged.model'
import { gedService } from '@/services/ged.service'
import FormGED from '../ged/FormGED'
export default {
  name: 'Sidebar',
  components: { FormGED, ImportRef },
  data () {
    return {
      active: null,
      isCollapse: true,
      allowImportFormGed: false
    }
  },
  computed: {
    routeArr () {
      return this.$route.path.split('/')
    },
    isAdmin () {
      return userService.isAdministrator()
    }
  },
  subscriptions () {
    return {
      authUser$: authService.onChange
    }
  },
  methods: {
    navigateToMenu (rubrique) {
      this.$router.push(rubrique)
    },
    isRoute (route) {
      return this.routeArr.includes(route)
    },
    getAvatarUrl () {
      if (this.authUser$ && this.authUser$.avatarFile && this.authUser$.avatarFile.url) {
        return this.authUser$.avatarFile.url
      } else {
        return require('@/assets/img/default-avatar.png')
      }
    },
    goUser () {
      if (this.authUser$) {
        this.$router.push('/user/' + this.authUser$.id)
      }
    },
    logout () {
      authService.logout().then(() => {
        this.$router.push({ path: '/connexion' })
      })
    },
    openImportRefModal () {
      this.$refs.importRefModal.open()
    },
    newGed () {
      const me = new User(authService.userValue)
      const ged = new Ged({ user: me })
      gedService.showFormModal(true)
      gedService.updateGedForm(ged)
      this.allowImportFormGed = !this.allowImportFormGed
    }
  }
}
</script>
