<template>
  <div>
    <div class="filter-advanced">
      <div class="filter-advanced-top d-flex justify-content-between align-items-stretch">
        <el-radio-group
          v-model="globalItemSearch.objectType"
          @change="resetFilters">
          <el-radio-button
            v-for="option in objectTypes"
            :key="option.value"
            :label="option.value"
            :class="option.class">
            <i :class="'rb-'+ option.icon" /> {{ option.label }}
          </el-radio-button>
        </el-radio-group>
        <div @click="$emit('hideAdvanced', false)">
          <el-button class="filter-btn">
            Filtres ({{ filtersCount }})
            <img :src="require('../../assets/img/pipe_line/close_white.svg')">
          </el-button>
        </div>
      </div>
    </div>
    <div class="filter-advanced-container">
      <folder-filters
        v-if="globalItemSearch.objectType === 'folder'"
        @reset-filters="resetFilters"
        @search="searchGlobalItems"
        @total="(value) => filtersCount = value" />
      <contact-filters
        v-if="globalItemSearch.objectType === 'contact'"
        @reset-filters="resetFilters"
        @search="searchGlobalItems" />
      <ged-filters
        v-if="globalItemSearch.objectType === 'ged'"
        @reset-filters="resetFilters"
        @search="searchGlobalItems" />
      <reference-filters
        v-if="globalItemSearch.objectType === 'reference'"
        @reset-filters="resetFilters"
        @search="searchGlobalItems" />
    </div>
  </div>
</template>
<script>
import FolderFilters from './filters/FolderFilters.vue'
import {
  searchGlobalItemsService
} from '@/services/searchGlobalItems.service'
import ContactFilters from './filters/ContactFilters.vue'
import GedFilters from './filters/GedFilters.vue'
import ReferenceFilters from './filters/ReferenceFilters.vue'
export default {
  name: 'AdvancedFilter',
  components: { FolderFilters, ContactFilters, GedFilters, ReferenceFilters },
  props: {
    isAdvanced: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  subscriptions () {
    return {
      filtersCount$: searchGlobalItemsService.onChangeFiltersCount,
      globalItemSearch: searchGlobalItemsService.onChangeGlobalItemsFilters,
      folderFilters: searchGlobalItemsService.onChangeFolderFilters,
      gedFilters: searchGlobalItemsService.onChangeGedFilters,
      contactFilters: searchGlobalItemsService.onChangeContactFilters,
      referenceFilters: searchGlobalItemsService.onChangeReferenceFilters
    }
  },
  data () {
    return {
      objectTypes: [{
        value: 'folder',
        icon: 'dossier',
        label: 'Dossier',
        class: 'dossier-color'
      }, {
        value: 'contact',
        icon: 'person',
        label: 'Contact',
        class: 'contact-color'
      }, {
        value: 'ged',
        icon: 'ged',
        label: 'GED',
        class: 'ged-color'
      }, {
        value: 'reference',
        icon: 'ref',
        label: 'Reference',
        class: 'ref-color'
      }]
    }
  },
  computed: {
    filtersCount () {
      let count = 0
      Object.keys(this.folderFilters).forEach(key => {
        if (Array.isArray(this.folderFilters[key])) {
          if (this.folderFilters[key].length > 0) {
            ++count
          }
        } else if (typeof this.folderFilters[key] === 'object' && key !== 'caYear') {
          if (this.folderFilters[key] && Object.keys(this.folderFilters[key]).length > 0) {
            const values = []
            Object.values(this.folderFilters[key]).forEach(val => {
              if (val !== null && val !== '') {
                values.push(val)
              }
            })
            if (values.length > 0) {
              ++count
            }
          }
        } else if (this.folderFilters[key]) {
          ++count
        }
      })
      Object.keys(this.contactFilters).forEach(key => {
        if (this.contactFilters[key]) {
          ++count
        };
      })

      Object.keys(this.gedFilters).forEach(key => {
        if (this.gedFilters[key]) {
          ++count
        };
      })

      Object.keys(this.referenceFilters).forEach(key => {
        if (key !== 'peliasAddress') {
          if (this.referenceFilters[key]) {
            ++count
          };
        }
      })

      searchGlobalItemsService.setFiltersCount(count)

      return this.filtersCount$
    }
  },

  methods: {
    searchGlobalItems (filters) {
      this.globalItemSearch.advancedSearchContent = filters
      this.globalItemSearch.isAdvanced = true
      this.$emit('search', this.globalItemSearch)
      this.$emit('hideAdvanced', true)
    },
    resetFilters () {
      searchGlobalItemsService.resetFilters()
    }
  }
}
</script>

<style lang="scss" scoped>
.billing-state {
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  font-size: 14px;
}
</style>
