
import { Component, Vue } from 'vue-property-decorator'
import { LegalFolder } from '@/models/legalFolder.model'
import { legalFolderService } from '@/services/legalFolder.service'
import { LEGAL_FOLDER_STATUES } from '@/services/constants.service'
import LegalFolderStatusModal from './LegalFolderStatusModal.vue'
import { tap } from 'rxjs/operators'
    // The @Component decorator indicates the class is a Vue component
    @Component({
      subscriptions (this: any) {
        return {
          folder$: legalFolderService.onChangeLegalFolder.pipe(
            tap((res: any) => {
              if (res.id) {
                const statusHistory: any[] = res.statusHistory.filter((element: any) => element.status <= res.status)
                for (const element of statusHistory.sort((a, b) => a.date - b.date)) {
                  this.activeStatusDate.splice(element.status, 1, new Date(element.changeAt).toLocaleDateString(undefined) + ' ' + new Date(element.changeAt).toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' }))
                }
              }
            }))
        }
      },
      components: {
        LegalFolderStatusModal
      }
    })

export default class LegalFolderPipeLine extends Vue {
  LEGAL_FOLDER_STATUES = LEGAL_FOLDER_STATUES
  folder$: LegalFolder = new LegalFolder()
  activeStatusDate : any[] = []

  created () {
    this.activeStatusDate = Array(this.LEGAL_FOLDER_STATUES.length).fill(null)
  }

  get step (): number {
    if (this.folder$.status === 0) {
      return 0
    }
    if (this.folder$.status === 1) {
      return 1
    } else if (this.folder$.status === 2) {
      return 2
    } else if (this.folder$.status === 3 || this.folder$.status === 4) {
      return 3
    } else if (this.folder$.status === 5) {
      return 4
    } else if (this.folder$.status === 6) {
      return 5
    } else if (this.folder$.status === 7 || this.folder$.status === 8) {
      return 6
    } else if (this.folder$.status === 9) {
      return 7
    } else if (this.folder$.status === 10) {
      return 8
    } else if (this.folder$.status === 11) {
      return 9
    } else if (this.folder$.status >= 12) {
      return 10
    }

    return 0
  }

  changeStep (step: number) {
    if (this.step !== step) {
      switch (step) {
        case 0:
          this.$emit('change', 0)
          break
        case 1:
          this.$emit('change', 1)
          break
        case 2:
          this.$emit('change', 2)
          break
        case 3:
          this.$emit('change', 4)
          break
        case 4:
          this.$emit('change', 5)
          break
        case 5:
          this.$emit('change', 6)
          break
        case 6:
          this.$emit('change', 8)
          break
        case 7:
          this.$emit('change', 9)
          break
        case 8:
          this.$emit('change', 10)
          break
        case 9:
          this.$emit('change', 11)
          break
        case 10:
          (this.$refs.statusValid as any).open()
          break
        default:

          break
      }
    }
  }

  handleValidStatus ({ status, reason }: any) {
    if (status === 12) {
      this.$emit('change', status)
    } else {
      this.folder$.status = status
      this.folder$.conflictReason = reason
      legalFolderService.saveFolder()
    }
  }
}
