
import _ from 'lodash'
import Moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { dashboardService, SummaryData } from '@/services/dashboard.service'
import 'chart.js'
import 'chartjs-plugin-labels'
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions () {
    return {
      summaryData$: dashboardService.onChangeSummaryData
    }
  },
  methods: {}
})
export default class DashboardMetricTemplate extends Vue {
  @Prop({}) title: string
  @Prop({}) currentYear: any
  @Prop({}) lastYear: any
  @Prop({}) cumulated: boolean

  summaryData$: SummaryData
  chartOptions: any = {}
  chartInfos: any[] = [...Array(12).keys()].map((month: number) => ({
    label: Moment().set('month', 3).locale('fr').add(month, 'month').format('MMMM'),
    month: Moment().set('month', 3).locale('fr').add(month, 'month').format('M')
  }))

  mounted () {
    this.chartOptions = {
      responsive: true,
      legend: {
        display: true
      },
      animation: {
        animateScale: true,
        animateRotate: true
      },
      plugins: {
        labels: false
      },
      layout: {
        padding: {
          top: 25
        }
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (context: any) {
            let label = context.label || ''

            if (label) {
              label += ': '
            }
            if (context.value !== null) {
              label += new Intl.NumberFormat('fr-FR', {}).format(context.value)
            }
            return label
          }
        }
      },
      hover: { mode: null },
      scales: {
        yAxes: [{
          ticks: {
            callback (value: number) {
              const options = [
                { max: 10, val: 1000000000, sym: 'B' },
                { max: 7, val: 1000000, sym: 'M' },
                { max: 4, val: 1000, sym: 'K' }
              ]
              const len = Math.abs(value).toString().length
              const option = options.find(opt => opt.max < len)
              if (option) {
                return (value / option.val).toFixed(0).concat(option.sym)
              }
              return value
            }
          }
        }]
      }
    }
  }

  get chartData (): any {
    if (!this.currentYear || !this.lastYear) return {}
    const currentYear: any = this.currentYear.data
    const lastYear: any = this.lastYear.data
    const cumulatedCurrentYear = this.cumulated ? this.cumulatedValues(currentYear) : null
    const cumulatedLastYear = this.cumulated ? this.cumulatedValues(lastYear) : null

    return {
      datasets: [
        {
          label: this.lastYear.label,
          data: this.chartInfos.map((item: any) => this.cumulated ? _.round(cumulatedLastYear[item.month], 2) : _.round(lastYear[item.month], 2)),
          fill: false,
          backgroundColor: 'lightgrey',
          borderWidth: 1
        },
        {
          label: this.currentYear.label,
          data: this.chartInfos.map((item: any) => this.cumulated ? _.round(cumulatedCurrentYear[item.month], 2) : _.round(currentYear[item.month], 2)),
          fill: false,
          backgroundColor: 'maroon',
          borderWidth: 1
        }
      ],
      labels: this.chartInfos.map((item: any) => item.label)
    }
  }

  cumulatedValues (source: { [key: string]: number }): any {
    const res: { [key: string]: number } = {}
    let sum = 0
    this.chartInfos.forEach((item: any) => {
      sum += Number(source[item.month])
      res[item.month] = sum
    })

    return res
  }
}
