
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import L from 'leaflet'
import {
  ListReference,
  listReferenceService,
  ReferenceAddress
} from '@/services/listReference.service'
import { criteriaBaseItemList } from '../../addReference/itemList'
import {
  baseBlockItems,
  locativeBlockItems,
  valeurBlockItems,
  baseBlockItemsSearchSection
} from '../../addReference/blockItems'
import { floatRule } from '../../addReference/formRules'
import {
  isFloat,
  filterOptionsWithoutCaseSensitive,
  filterGroupList
} from '@/utils/functions'
import CustomToggleDouble from '@/components/form/ToggleDouble.vue'
import { shortcutsDatePicker } from '@/utils/constant'

@Component({
  components: {
    CustomToggleDouble
  }
})
export default class SearchSection extends Vue {
  @Prop({ type: Object })
    searchCondition: any

  @Prop({ type: Array })
    typeValeur: any[]

  @Prop({ type: Array })
    typeTransaction: any[]

  @Prop({ type: Array })
    typeTransactionList: ListReference[]

  @Prop({ type: Array })
    typeLocalItems: any[]

  @Prop({ type: Array })
    typeLocalItemsList: ListReference[]

  @Prop({ type: Object })
    optionList: any

  @Prop({ type: Array })
    activites: any[]

  @Prop({ type: Boolean })
    isNotExactAddress: any

  viewAddress: string = ''
  search: any = {
    natureCapital: []
  }

  isVenales: boolean = false
  dateRange: any = []
  datePickerOptions: any = {}
  displaySearchCrietria: boolean = true
  filteredOptionList: any = []
  filteredTypeLocalItems: any = []
  filteredTypeTransaction: any = []
  filteredActivites: any = []
  newAdresseQuery : any = null

  isFloat = isFloat

  criteriaBaseItemList = criteriaBaseItemList

  floatRule = floatRule

  rangePickerOptions: any = {
    shortcuts: shortcutsDatePicker
  }

  handleDate (val: any) {
    if (val && val.length > 1) {
      this.search.dateMini = val[0]
      this.search.dateMaxi = val[1]
    } else {
      this.search.dateMini = ''
      this.search.dateMaxi = ''
    }
  }

  blurInput () {
    const autocomplete = this.$refs.autocomplete as any
    autocomplete.$refs.input.blur()
  }

  @Watch('optionList', { immediate: true, deep: true })
  handleOptionList () {
    this.filteredOptionList = JSON.parse(JSON.stringify(this.optionList))
  }

  @Watch('typeLocalItems', { immediate: true, deep: true })
  handleTypeLocalItems () {
    this.filteredTypeLocalItems = JSON.parse(JSON.stringify(this.typeLocalItems))
  }

  @Watch('typeTransaction', { immediate: true, deep: true })
  handleTypeTransaction () {
    this.filteredTypeTransaction = JSON.parse(JSON.stringify(this.typeTransaction))
  }

  @Watch('activites', { immediate: true, deep: true })
  handleActivites () {
    this.filteredActivites = JSON.parse(JSON.stringify(this.activites))
  }

  @Watch('searchCondition', { immediate: true, deep: true })
  handleSearchCondition (r: any) {
    if (r.referenceAddress) {
      this.viewAddress = r.referenceAddress.label
    }
    if (r.dateMaxi && r.dateMini) {
      this.dateRange = [r.dateMini, r.dateMaxi]
    }
    if (r.typeValeur) {
      this.isVenales = r.typeValeur === 2
    }
    this.search = {
      ...this.search,
      ...r
    }
  }

  mounted () {
    L.DomEvent.disableClickPropagation(
        this.$refs.searchSection as HTMLDivElement
    )
    L.DomEvent.disableScrollPropagation(
        this.$refs.searchSection as HTMLDivElement
    )
  }

  handleChangeTypeValeur (value: boolean) {
    this.isVenales = value
    value ? this.search.typeValeur = 2 : this.search.typeValeur = 1
    if (this.search.dateMaxi) {
      delete this.search.dateMaxi
    }
    if (this.search.dateMini) {
      delete this.search.dateMini
    }
  }

  handleSearchCrietira () {
    this.displaySearchCrietria = !this.displaySearchCrietria
  }

  querySearch (queryString: string, cb: Function) {
    if (!queryString) return
    listReferenceService.getReferenceAddresse(queryString).then(res => {
      cb(res)
    })
  }

  handleChangeAddress (): void {
    if (this.search.referenceAddress) {
      delete this.search.referenceAddress
      this.newAdresseQuery = ''
    }
    if (this.search.radius) {
      delete this.search.radius
    }
  }

  @Emit('updateSearchAddress')
  handleSelect (item: ReferenceAddress) {
    this.search.referenceAddress = item
    this.newAdresseQuery = item
    return item
  }

  @Emit('handleSelectViewAddress')
  handleSelectViewAddress (item: ReferenceAddress) {
    this.search.referenceAddress = item
    this.newAdresseQuery = item
    return item
  }

  @Emit('handleSearch')
  handleSearch (ev: KeyboardEvent) {
    ev.preventDefault()
    if (this.newAdresseQuery) {
      const item = this.handleSelectViewAddress(this.newAdresseQuery)
      this.viewAddress = item.label
      this.search.referenceAddress = item
    }
    if (this.isNotExactAddress) {
      delete this.search.radius
    }
    return JSON.parse(JSON.stringify(this.search))
  }

  @Emit('handleRefreshSearchCondition')
  handleRefresh () {
    const search = {
      typeLocal: null,
      typeValeur: 1,
      natureCapital: []
    }
    this.viewAddress = ''
    this.newAdresseQuery = null
    this.isVenales = false
    this.dateRange = []
    this.search = JSON.parse(JSON.stringify(search))
  }

  checkDisplayCondition (valeur: string, transaction: string, title: string, properties: any) {
    const localLabel = this.getLocalLabel(this.search.typeLocal)
    const checkValue: boolean = valeur === 'any' ? true : this.valueCondition(this.search.typeValeur, valeur)
    const display:boolean = (baseBlockItemsSearchSection.includes(title) && checkValue) ||
            (this.search.typeValeur &&
      checkValue &&
      (baseBlockItems[localLabel] &&
        !baseBlockItems[localLabel].includes(title)) &&
      (locativeBlockItems[localLabel] &&
        !locativeBlockItems[localLabel].includes(title)) &&
      (valeurBlockItems[localLabel] &&
        !valeurBlockItems[localLabel].includes(title)))

    if (!display) {
      if (title === 'Nature du capital') {
        this.search.natureCapital.forEach((capital: any, i: number) => {
          this.search.natureCapital.splice(i, 1)
        })
      } else {
        properties.forEach((property: any) => {
          if (this.search[property]) {
            delete this.search[property]
          }
        })
      };
    }

    return display
  }

  valueCondition (value: number, condition: string): boolean {
    return this.getValueLabel(value) === condition
  }

  localCondition (value: number, condition: string): boolean {
    return this.search.typeLocal && this.getLocalLabel(value) === condition
  }

  transactionCondition (value: number, condition: string): boolean {
    return this.getTransactionLabel(value) === condition
  }

  getTransactionLabel (typeTransaction: Number) {
    let label = ''
    const target: any = this.typeTransactionList.filter(
      (item: any) => item.num === typeTransaction
    )
    target.length > 0 ? (label = target[0].label) : (label = '')

    return label
  }

  getValueLabel (typeValeur: Number) {
    let label = ''
    const target: any = this.typeValeur.filter(
      (item: any) => item.num === typeValeur
    )
    target.length > 0 ? (label = target[0].label) : (label = '')

    return label
  }

  getLocalLabel (typeLocal: Number) {
    let label = ''
    const target: any = this.typeLocalItemsList.filter(
      (item: any) => item.num === typeLocal
    )
    target.length > 0 ? (label = target[0].label) : (label = '')

    return label
  }

  filterOptions (query: string, isGroup: boolean, type: string) {
    if (isGroup) {
      if (type === 'typeLocal') {
        this.filteredTypeLocalItems = filterGroupList(query, this.typeLocalItems)
      } else if (type === 'typeTransaction') {
        this.filteredTypeTransaction = filterGroupList(query, this.typeTransaction)
      } else if (type === 'activities') {
        this.filteredActivites = filterGroupList(query, this.activites)
      }
    } else {
      this.filteredOptionList[type] = filterOptionsWithoutCaseSensitive(query, 'label', this.optionList[type])
    }
  }

  handleFocusList (isGroup: boolean, type: string) {
    if (isGroup) {
      if (type === 'typeLocal') {
        this.filteredTypeLocalItems = JSON.parse(JSON.stringify(this.typeLocalItems))
      } else if (type === 'typeTransaction') {
        this.filteredTypeTransaction = JSON.parse(JSON.stringify(this.typeTransaction))
      } else if (type === 'activities') {
        this.filteredActivites = JSON.parse(JSON.stringify(this.activites))
      }
    } else {
      this.filteredOptionList[type] = JSON.parse(JSON.stringify(this.optionList[type]))
    }
  }
}
