

// The @Component decorator indicates the class is a Vue component
import { Component, Vue } from 'vue-property-decorator'
import { Quote, quoteService } from '@/services/quote.service'
import { tap } from 'rxjs/operators'
import { dossierService } from '@/services/dossier.service'
import { LEGAL_FOLDER_STATUES, QUOTE_STATUES, SETTED_FOLDER_STATUES } from '@/services/constants.service'
import { legalFolderService } from '@/services/legalFolder.service'
import { settledFolderService } from '@/services/settledFolder.service'
@Component({
  subscriptions (this: any) {
    return {
      quote$: quoteService.onChangeQuote
        .pipe(
          tap((res: any) => {
            if (res.id && this.folderType !== 'settled_folder') {
              this.folderId = res.id
              this.folderType = 'quote'
              this.statusList = res.statusHistory
            }
          })
        ),
      legal$: legalFolderService.onChangeLegalFolder
        .pipe(
          tap((res: any) => {
            if (res.id) {
              this.folderId = res.id
              this.folderType = 'legal_folder'
              this.statusList = res.statusHistory
            }
          })
        ),
      settled$: settledFolderService.onChangeFolder
        .pipe(
          tap((res: any) => {
            if (res.id) {
              this.folderId = res.id
              this.folderType = 'settled_folder'
              this.statusList = res.statusHistory
            }
          })
        )
    }
  }
})
export default class QuoteHistory extends Vue {
  SETTLED_FOLDER_STATUES = SETTED_FOLDER_STATUES
  LEGAL_FOLDER_STATUES = LEGAL_FOLDER_STATUES
  QUOTE_STATUES = QUOTE_STATUES
  quote$ : Quote
  radio1: string = ''
  historyTri: boolean = false
  statusList: any[] = []
  folderId : number | null = null
  folderType: string = ''

  changeStringToVariable (str: string) {
    // eslint-disable-next-line
    return eval(str)
  }

  toggleTri () {
    this.historyTri = !this.historyTri
    this.statusList.sort((a:any, b:any) => {
      if (this.historyTri) {
        return new Date(a.changeAt).valueOf() - new Date(b.changeAt).valueOf()
      } else {
        return new Date(b.changeAt).valueOf() - new Date(a.changeAt).valueOf()
      }
    })
  }

  updateStatusByDate () {
    if (this.folderId) {
      dossierService.getStatusHistoryByDateRange(this.folderId, this.folderType, this.radio1).then((res: any) => {
        this.statusList = res.statusHistory
      })
    }
  }
}
