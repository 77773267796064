export const requireRule: any = [
  {
    required: true,
    message: 'Merci de remplir les champs obligatoires',
    trigger: 'blur'
  }
]

export const floatRule: any = [
  {
    pattern: /^[-]{0,1}[0-9]+[.,][0-9]+|[-]{0,1}[0-9]+$/,
    message: 'Type de valeur invalide',
    trigger: 'change'
  }
]
