<template>
  <div class="map-container">
    <div id="map">
      <transition>
        <div
          v-if="isCollapse"
          class="search-map">
          <filter-form
            :is-advanced="!isCollapse"
            @search="search"
            @showAdvanced="() => $emit('showAdvanced')" />
        </div>
      </transition>
      <div class="btn-control-container">
        <el-button @click="()=> $emit('showAdvanced')">
          <i class="rb-open_in" />
        </el-button>
      </div>
    </div>
    <clusters-items-modal
      ref="clustersItemsModal"
      @show-miniature="handleFindInList" />
  </div>
</template>
<script>
import FilterForm from '@/components/form/FilterForm.vue'
import ClustersItemsModal from './ClustersItems.modal.vue'
import L from 'leaflet'
import { listReferenceService } from '@/services/listReference.service'
import { searchGlobalItemsService, SEARCH_TYPE } from '@/services/searchGlobalItems.service'
import { tap } from 'rxjs/operators'
import 'leaflet.markercluster'
import {
  LEGAL_FOLDER_STATUES,
  QUOTE_STATUES,
  SETTED_FOLDER_STATUES
} from '@/services/constants.service'
import {
  getExpertiseTypeLabels,
  localeDateStr,
  getTransactionTypeLabel,
  getActiviteLabel,
  getAbbreviation,
  getShortLabel,
  numberWithSpaces,
  fromFrToEnDate,
  getDistanceBetweenTwoPoints
} from '@/utils/functions'

const tgm = require('@targomo/leaflet/targomo-leaflet-full.umd.js')
export default {
  name: 'MapSearch',
  components: { FilterForm, ClustersItemsModal },
  props: {
    isCollapse: {
      type: Boolean,
      default: true
    },
    showResult: {
      type: Boolean,
      default: false,
      required: false
    },
    filteredItems: {
      type: Array,
      default: function () {
        return []
      },
      required: true
    },
    globalItemSearch: {
      type: Object,
      default: function () {
        return {}
      },
      required: true
    }
  },
  subscriptions () {
    return {
      loading$: searchGlobalItemsService.onLoading,
      activeId$: searchGlobalItemsService.onChangeActiveId,
      globalItemsTotal$: searchGlobalItemsService.onChangeGlobalItemsTotal.pipe(
        tap((res) => {
          if (res) {
            this.globalItemsTotal = res
          }
        })
      ),
      globalItemsFilters$:
        searchGlobalItemsService.onChangeGlobalItemsFilters.pipe(
          tap((res) => {
            if (res && res.queryString && res.fields === SEARCH_TYPE.ADDRESS) {
              this.mapPosition(res.queryString, 0)
            }
          })
        ),
      listReferences$: listReferenceService.onChangeListReferences
    }
  },
  data () {
    return {
      map: null,
      isAll: false,
      viewCluster: null,
      viewAll: null,
      polygonOverlayLayer: null,
      tileLayer: null,
      marker: [],
      popup: null,
      circle: null,
      zoom: 13,
      radio1: [],
      checked: false,
      cordinates: {
        latitude: 48.86,
        longitude: 2.34
      },
      clusterMarkers: null,
      globalItems: [],
      allMaps: [],
      selectedElement: null,
      mapboxUrl:
        'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png',
      mapboxAttribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>',
      globalItemsTotal: 0,
      markers: {},
      selectedRef: null,
      prevSelectedRefKey: 0
    }
  },
  computed: {
    globalItemsWithId () {
      return this.globalItems.map(r => ({
        id: this.getId(r),
        ...r
      })
      )
    }
  },
  watch: {
    filteredItems () {
      this.globalItems = this.filteredItems
      this.serializedResultForMap(this.globalItems)
    }
  },
  mounted () {
    this.initMap().then(() => {
      this.getUserCordinates()
    })
    window.addEventListener('miniature', this.handleMiniature)
    window.addEventListener('open-details', this.handleOpenDetails)
    window.addEventListener('open-conflicts', this.handleOpenConflictModal)
    window.addEventListener('findInList', this.handleFindInList)
  },
  beforeDestroy () {
    window.removeEventListener('miniature', this.handleMiniature)
    window.removeEventListener('open-details', this.handleOpenDetails)
    window.removeEventListener('open-conflicts', this.handleOpenConflictModal)
    window.removeEventListener('findInList', this.handleFindInList)
  },
  methods: {
    fromFrToEnDate (value) {
      return fromFrToEnDate(value)
    },
    resetZoom () {
      this.zoom = 13
      this.map.setView(
        [this.cordinates.latitude, this.cordinates.longitude],
        this.zoom
      )
    },
    resetToUserPosition () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((pos) => {
          const userCoordinates = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude
          }

          this.map.setView(
            [userCoordinates.latitude, userCoordinates.longitude],
            this.zoom
          )
        })
      }
    },
    handleMiniature (event) {
      const result = this.globalItems[event.detail]
      const detail = true
      this.$emit('miniature', { detail, result })
    },
    handleOpenDetails (event) {
      const result = this.globalItems[event.detail]
      this.$emit('detail', result)
    },
    handleOpenConflictModal (event) {
      const result = this.globalItems[event.detail]
      const data = {
        conflicts: result.folder.conflits,
        name: result.folder.name,
        type: result.objectType,
        id: result.folder.id
      }
      this.$emit('showConflicts', data)
    },
    handleFindInList (event) {
      if (!this.showResult) {
        const result = this.globalItemsWithId.find((element) => element.id === event.detail)
        const detail = true
        this.$emit('findInList', event.detail)
        if (result.objectType !== 'fodegi') {
          this.$emit('miniature', { detail, result })
        }
      }
    },
    async initMap () {
      this.map = new L.Map('map', {
        zoomControl: true,
        dragging: true,
        renderer: L.canvas()
      }).setView(
        [this.cordinates.latitude, this.cordinates.longitude],
        this.zoom
      )
      this.tileLayer = L.tileLayer(this.mapboxUrl, {
        maxZoom: 20,
        attribution: this.mapboxAttribution
      })
      this.tileLayer.addTo(this.map)

      // define the polygon overlay
      this.polygonOverlayLayer = new tgm.TgmLeafletPolygonOverlay({
        strokeWidth: 20
      })
      this.clusterLocalisation()
    },
    statusLabel (result) {
      let folderStatus = null
      if (result.objectType === 'quote') {
        folderStatus = QUOTE_STATUES.find(
          (status) => status.value === result.folder.status
        )
      } else if (result.objectType === 'legal-folder') {
        folderStatus = LEGAL_FOLDER_STATUES.find(
          (status) => status.value === result.folder.status
        )
      } else {
        folderStatus = SETTED_FOLDER_STATUES.find(
          (status) => status.value === result.folder.status
        )
      }
      return folderStatus ? folderStatus.label : ''
    },
    serializedResultForMap (globalItems) {
      this.allMaps = []
      if (globalItems.length > 0) {
        globalItems.forEach((item, index) => {
          if (
            item.objectType === 'quote' ||
            item.objectType === 'settled-folder' ||
            item.objectType === 'legal-folder'
          ) {
            if (item.folder.adresses) {
              item.folder.adresses.forEach((adresse) => {
                const folderColors = {
                  quote: 'devis',
                  settledFolder: 'dossier',
                  folder: 'dossier',
                  legalFolder: 'dossier-jr'
                  // legalFolder: "fodegi",
                }
                const serializedItem = {
                  id: item.objectType + '-' + item.folder.id,
                  index,
                  latitude: adresse.lat ? Number(adresse.lat) : '',
                  longitude: adresse.lng ? Number(adresse.lng) : '',
                  type: item.objectType
                    ? item.objectType === 'quote'
                      ? 'Devis'
                      : item.objectType === 'settled-folder'
                        ? 'Dossier Amiable'
                        : 'Dossier Judiciaire'
                    : '',
                  nom: item.folder.name
                    ? this.capitalizeFirstLetter(item.folder.name)
                    : '',
                  adress:
                    (adresse.buildingnumber
                      ? adresse.buildingnumber + ' '
                      : '') +
                    (adresse.street ? adresse.street + ', ' : '') +
                    (adresse.zipcode ? adresse.zipcode + ', ' : '') +
                    (adresse.city ? adresse.city.toUpperCase() : ''),
                  category: item.objectType
                    ? item.objectType === 'quote'
                      ? 'Devis'
                      : item.objectType === 'settled-folder'
                        ? 'Dossier Amiable'
                        : 'Dossier Judiciaire'
                    : '',
                  color: folderColors[item.objectType],
                  icon: 'rb-dossier-alt',
                  localType: getExpertiseTypeLabels(item.folder.localType),
                  hasBalanceAmount: item.folder.hasBalanceAmount
                    ? 'Reste à facturer'
                    : '',
                  hasPendingAmount: item.folder.hasPendingAmount
                    ? 'Reste à percevoir'
                    : '',
                  hasConflicts: item.folder.conflits !== null,
                  objectType: 'folder'
                }

                if (getDistanceBetweenTwoPoints(serializedItem.latitude, serializedItem.longitude, this.globalItemSearch.location.lat, this.globalItemSearch.location.lon) < this.globalItemSearch.location.distance || this.globalItemSearch.location.distance === 0) {
                  this.allMaps.push(serializedItem)
                  return true
                }
              })
            }
          } else if (item.objectType === 'contact') {
            if (item.contact.adresses) {
              item.contact.adresses.forEach((adresse) => {
                const serializedItem = {
                  id: 'contact-' + item.contact.id,
                  index,
                  latitude: adresse.lat ? Number(adresse.lat) : '',
                  longitude: adresse.lng ? Number(adresse.lng) : '',
                  type: item.contact.type,
                  nom:
                    (item.contact.lastname
                      ? item.contact.lastname.toUpperCase() + ' '
                      : '') +
                    (item.contact.firstname
                      ? this.capitalizeFirstLetter(item.contact.firstname)
                      : ''),
                  adress:
                    (adresse.buildingnumber
                      ? adresse.buildingnumber + ' '
                      : '') +
                    (adresse.street ? adresse.street + ', ' : '') +
                    (adresse.zipcode ? adresse.zipcode + ', ' : '') +
                    (adresse.city ? adresse.city.toUpperCase() : ''),
                  category: this.capitalizeFirstLetter(item.objectType),
                  color: 'contact',
                  icon: 'rb-person_black',
                  objectType: item.objectType,
                  email: item.contact.email,
                  phoneNumber: item.contact.phoneNumber
                }

                if (getDistanceBetweenTwoPoints(serializedItem.latitude, serializedItem.longitude, this.globalItemSearch.location.lat, this.globalItemSearch.location.lon) < this.globalItemSearch.location.distance || this.globalItemSearch.location.distance === 0) {
                  this.allMaps.push(serializedItem)
                  return true
                }
              })
            }
          } else if (item.objectType === 'ged') {
            const adresse = item.ged.adresses
            const serializedItem = {
              id: 'ged-' + item.ged.id,
              index,
              latitude: adresse.lat ? Number(adresse.lat) : '',
              longitude: adresse.lng ? Number(adresse.lng) : '',
              type: item.objectType,
              nom: this.capitalizeFirstLetter(item.ged.name),
              adress:
                (adresse.housenumber ? adresse.housenumber + ' ' : '') +
                (adresse.street ? adresse.street + ', ' : '') +
                (adresse.postalcode ? adresse.postalcode + ', ' : '') +
                (adresse.locality ? adresse.locality.toUpperCase() : ''),
              category: this.capitalizeFirstLetter(item.objectType),
              color: 'ged',
              icon: 'rb-ged-alt',
              objectType: item.objectType,
              theme: this.capitalizeFirstLetter(item.ged.theme),
              content: this.capitalizeFirstLetter(item.ged.content),
              source: this.capitalizeFirstLetter(item.ged.source),
              dateStart: item.ged.dateStart && this.fromFrToEnDate(localeDateStr(item.ged.dateStart))
            }

            if (getDistanceBetweenTwoPoints(serializedItem.latitude, serializedItem.longitude, this.globalItemSearch.location.lat, this.globalItemSearch.location.lon) < this.globalItemSearch.location.distance || this.globalItemSearch.location.distance === 0) {
              this.allMaps.push(serializedItem)
            }
          } else if (item.objectType === 'reference') {
            const serializedItem = {
              id: 'reference-' + item.reference.id,
              index,
              latitude: item.reference.lat ? Number(item.reference.lat) : '',
              longitude: item.reference.lng ? Number(item.reference.lng) : '',
              activite: this.getShortLabel(this.getActiviteLabel(item.reference.activite)),
              dateEffetBailStart: item.reference.dateEffetBailStart && this.fromFrToEnDate(item.reference.dateEffetBailStart),
              dateVenteStart: item.reference.dateVenteStart && this.fromFrToEnDate(item.reference.dateVenteStart),
              typeTransaction: this.getAbbreviation(this.getTransactionTypeLabel(item.reference.typeTransaction)),
              type: 'Référence',
              color: 'ref',
              adress: item.reference.adresse,
              icon: 'rb-ref',
              objectType: item.objectType,
              nom: item.reference.ficheId,
              category: 'Référence',
              typeLocal: item.reference.typeLocal,
              source: item.reference.source
                ? item.reference.source.toUpperCase()
                : '',
              detail: item.reference.typeValeur === 1
                ? `${item.reference.loyerP && numberWithSpaces(Number(item.reference.loyerP).toFixed(1)) + ' €/m2 P' + ' | '}
              ${item.reference.loyerM2 && numberWithSpaces(Number(item.reference.loyerM2).toFixed(1)) + ' €/m2' + ' | '}
              ${item.reference.surfaceP && numberWithSpaces(Number(item.reference.surfaceP).toFixed(1)) + ' m2 P' + ' | '}
              ${item.reference.surfaceM2 && numberWithSpaces(Number(item.reference.surfaceM2).toFixed(1)) + ' m2' + ' | '}
              ${item.reference.loyerDecapitalise && numberWithSpaces(Number(item.reference.loyerDecapitalise).toFixed(1)) + ' €/m2 D'}`
                : `
              ${item.reference.surfaceM2 && numberWithSpaces(Number(item.reference.surfaceM2).toFixed(1)) + ' m2' + ' | '}
              ${item.reference.prixHd && numberWithSpaces(Number(item.reference.prixHd).toFixed(1)) + ' € HD' + ' | '}
              ${item.reference.prixHdPerCa && numberWithSpaces(Number(item.reference.prixHdPerCa).toFixed(1)) + ' €/m2 HD'}
              ${item.reference.tauxRendement && item.reference.tauxRendement}
              `
            }

            if (getDistanceBetweenTwoPoints(serializedItem.latitude, serializedItem.longitude, this.globalItemSearch.location.lat, this.globalItemSearch.location.lon) < this.globalItemSearch.location.distance || this.globalItemSearch.location.distance === 0) {
              this.allMaps.push(serializedItem)
            }
          } else if (item.objectType === 'fodegi') {
            const serializedItem = {
              id: 'fodegi-' + item.fodegi.id,
              index,
              latitude: item.fodegi.location.lat ? item.fodegi.location.lat : '',
              longitude: item.fodegi.location.lon ? item.fodegi.location.lon : '',
              type: 'Fodegi',
              color: 'fodegi',
              adress: item.fodegi.adresse,
              icon: 'rb-ref',
              objectType: item.objectType,
              nom: '',
              category: 'Fodegi'
            }

            if (getDistanceBetweenTwoPoints(serializedItem.latitude, serializedItem.longitude, this.globalItemSearch.location.lat, this.globalItemSearch.location.lon) < this.globalItemSearch.location.distance || this.globalItemSearch.location.distance === 0) {
              this.allMaps.push(serializedItem)
            }
          }
        })
        this.clusterLocalisation(this.allMaps)
      }
    },
    buildPopup (item) {
      const index = item.index
      if (item.objectType === 'folder') {
        return `<div class="popupFolder">
                    <div class="d-flex align-items-center justify-content-start list-result-assicie-top">
                        <figure>
                            <i class="${item.icon}"></i>
                        </figure>
                        <div>${item.nom}</div>
                    </div>
                    <div class="detail-result">
                      <div class="d-flex flex-wrap align-items-start justify-content-start">
                          <i class="rb-dossier-alt mr-2"></i>
                          ${item.type}
                          ${
          item.localType
            ? '<span>, ' + item.localType + '</span>'
            : ''
        }
                          <i class="rb-warning_red ml-2" onclick="window.dispatchEvent(new CustomEvent('open-conflicts', {'detail': '${index}'}))"></i>
                      </div>
                      <div class="d-flex flex-wrap align-items-start justify-content-start">
                          <i class="rb-devis-alt mr-2"></i>
                          ${
          item.hasBalanceAmount
            ? '<span>, ' + item.hasBalanceAmount + '</span>'
            : ''
        }
                          ${
          item.hasPendingAmount
            ? '<span>, ' + item.hasPendingAmount + '</span>'
            : ''
        }
                      </div>
                      <div class="d-flex flex-wrap align-items-start justify-content-start">
                          <span><i class="rb-marqueur mr-1"></i>
                          ${item.adress}</span>
                      </div>
                    </div>
                    <div class="btn-container mt-3 mb-3 grey">
                      <button onclick="window.dispatchEvent(new CustomEvent('miniature', {'detail': '${index}'}))" class="btn-popup">
                        <i class="rb-visibility1"></i> 
                      </button>
                      <button onclick="window.dispatchEvent(new CustomEvent('open-details', {'detail': '${index}'}))"  class="btn-popup"> 
                        <i class="rb-open-new-alt"></i>
                      </button>     
                    </div>
                  </div>`
      }
      if (item.objectType === 'ged') {
        return `<div class="popupGed">
                    <div class="d-flex align-items-center justify-content-start list-result-assicie-top">
                        <figure>
                            <i class="${item.icon}"></i>
                        </figure>
                        <div>${item.nom}</div>
                    </div>
                    <div class="detail-result">
                      <div class="d-flex align-items-start justify-content-start">
                          <i class="rb-ged-alt"></i>
                          GED
                          ${
          item.theme
            ? '<span>, ' + item.theme + '</span>'
            : ''
        }
                          ${
          item.content
            ? '<span>, ' + item.content + '</span>'
            : ''
        }
                      </div>
                      <div class="d-flex align-items-start justify-content-start">
                          <i class="rb-marqueur"></i>
                          <span>${item.dateStart}</span>
                          <span> , Source : ${item.source}</span>
                      </div>
                    </div>
                    <div class="btn-container mt-3 mb-3 grey">
                      <button onclick="window.dispatchEvent(new CustomEvent('miniature', {'detail': '${index}'}))" class="btn-popup">
                        <i class="rb-visibility1"></i> 
                      </button>
                      <button onclick="window.dispatchEvent(new CustomEvent('open-details', {'detail': '${index}'}))"  class="btn-popup"> 
                        <i class="rb-open-new-alt"></i>
                      </button>     
                    </div>
                  </div>`
      }
      if (item.objectType === 'contact') {
        return `<div class="popupContact">
                    <div class="d-flex align-items-center justify-content-start list-result-assicie-top">
                        <figure>
                            <i class="${item.icon}"></i>
                        </figure>
                        <div>${item.nom}</div>
                    </div>

                    <div class="detail-result">
                      ${
          item.type
            ? `
                      <div class="d-flex align-items-start justify-content-start">
                          <i class="rb-apartment_black"></i>
                          <span>${item.type}</span>
                      </div>`
            : ''
        }
                      
                      <div class="d-flex align-items-start justify-content-start">
                          <i class="rb-marqueur"></i>
                          <div>
                            ${item.adress}
                            ${
          item.email
            ? '<span>, ' + item.email + '</span>'
            : ''
        }
                            ${
          item.phoneNumber
            ? '<span>, ' + item.phoneNumber + '</span>'
            : ''
        }
                          </div>
                      </div>
                    </div>
                    <div class="btn-container mt-3 mb-3 grey">
                      <button onclick="window.dispatchEvent(new CustomEvent('miniature', {'detail': '${index}'}))" class="btn-popup">
                        <i class="rb-visibility1"></i> 
                      </button>
                      <button onclick="window.dispatchEvent(new CustomEvent('open-details', {'detail': '${index}'}))"  class="btn-popup"> 
                        <i class="rb-open-new-alt"></i>
                      </button>     
                    </div>
                  </div>`
      }
      if (item.objectType === 'reference') {
        return `<div class="popupReference">
                    ${
          item.activite
            ? `<div class="d-flex align-items-center justify-content-start list-result-assicie-top">
                        <figure>
                            <i class="${item.icon}"></i>
                        </figure>
                        <div>${item.activite}</div>
                    </div>`
            : ''
        }
                    <div class="detail-result">
                      ${
          item.dateEffetBailStart
            ? `
                            <div class="d-flex align-items-start justify-content-start">
                                <i class="rb-calendrier"></i>
                                <span>Date de bail: ${item.dateEffetBailStart}</span>
                            </div>`
            : ''
        }
                      ${
          item.dateVenteStart
            ? `
                            <div class="d-flex align-items-start justify-content-start">
                                <i class="rb-calendrier"></i>
                                <span>Date de vente: ${item.dateVenteStart}</span>
                            </div>`
            : ''
        }
                      
                      <div class="d-flex align-items-start justify-content-start">
                          <i class="rb-marqueur"></i>
                          <div>
                            ${item.adress}
                          </div>
                      </div>
                      ${
          item.typeTransaction
            ? `<div class="d-flex align-items-start justify-content-start">
                                <i class="rb-devis-alt"></i>
                                <div>
                                  ${item.typeTransaction}
                                </div>
                            </div>`
            : ''
        }
                      ${
          item.detail &&
          `
                        <div class="d-flex align-items-start justify-content-start">

                                <div>
                                  ${item.detail}
                                </div>
                            </div>
                        `
        }
                      ${
          item.source
            ? `<div class="d-flex align-items-start justify-content-start">
                          <i class="rb-apartment_black"></i>
                          <div>
                            ${item.source}
                          </div>
                      </div>`
            : ''
        }
                    </div>
                    <div class="btn-container mt-3 mb-3 grey">
                      <button onclick="window.dispatchEvent(new CustomEvent('miniature', {'detail': '${index}'}))" class="btn-popup">
                        <i class="rb-visibility1"></i> 
                      </button>
                      <button onclick="window.dispatchEvent(new CustomEvent('open-details', {'detail': '${index}'}))"  class="btn-popup"> 
                        <i class="rb-open-new-alt"></i>
                      </button>     
                    </div>
                  </div>`
      }
      if (item.objectType === 'fodegi') {
        return `<div class="popupReference">
                    <div class="d-flex align-items-center justify-content-start list-result-assicie-top">
                        <figure style="margin: 0 1rem 0">
                            <i class="${item.icon}"></i>
                        </figure>
                        <div>${item.adress}</div>
                    </div>
                </div>`
      }
    },
    clusterLocalisation (items) {
      if (!items) {
        return
      }
      this.removeMarkers()
      this.clusterMarkers = L.markerClusterGroup({
        zoomToBoundsOnClick: false,
        iconCreateFunction: function (cluster) {
          const total = cluster.getChildCount()
          const markersByType = cluster
            .getAllChildMarkers()
            .reduce((acc, value) => {
              if (!acc[value.options.itemObjectType]) {
                acc[value.options.itemObjectType] = 1
              } else {
                acc[value.options.itemObjectType]++
              }
              return acc
            }, {})
          let circles = ''
          const radius = 5
          const maxSize = 2 * radius * Math.PI
          let previousSegmentsLength = 0
          let currentSegmentLength = 0
          Object.entries(markersByType).sort((a, b) => (a > b) ? 1 : -1).forEach(([key, value]) => {
            let color = ''
            let title = ''
            if (key === 'ged') {
              color = '#DD6317'
              title = 'Ged: ' + value
            }
            if (key === 'folder') {
              color = '#DD1717'
              title = 'Dossier: ' + value
            }
            if (key === 'contact') {
              color = '#0058FF'
              title = 'Contact: ' + value
            }
            if (key === 'reference') {
              color = '#6B17DD'
              title = 'Référence: ' + value
            }
            if (key === 'fodegi') {
              color = '#32B88F'
              title = 'Fodegi: ' + value
            }
            currentSegmentLength = (maxSize * value) / total
            circles += `<circle r="${radius}" cx="${radius + 1}" cy="${radius + 1}" stroke="${color}" stroke-width="2" stroke-dasharray="0 ${previousSegmentsLength} ${currentSegmentLength} ${maxSize}">
                  <title>${title}</title>
              </circle>`
            previousSegmentsLength += currentSegmentLength
          })
          return L.divIcon({
            className: 'marker-circle-icon',
            html: `  <div>
                <svg xmlns="http://www.w3.org/2000/svg" height="60" width="60" viewBox="0 0 ${radius + radius + 2} ${radius + radius + 2}">
                    ${circles}
                    <circle r="${radius}" cx="${radius + 1}" cy="${radius + 1}" stroke-width="5" fill="white">10</circle>
                    <text x="50%" y="50%" text-anchor="middle" fill="black" font-size="5px" font-family="Arial" dy=".3em">${total}</text>
                  </svg>
            </div>`,
            iconSize: [60, 60]
          })
        },
        maxClusterRadius: function (zoomLevel) {
          return zoomLevel > 17 ? 30 : (zoomLevel > 10 ? 100 : 200)
        }
      })
      this.clusterMarkers.on('clusterclick', (a) => {
        this.$refs.clustersItemsModal.open(a.layer.getAllChildMarkers().map(marker => {
          return marker.options.item
        }))
      })

      items.forEach((item) => {
        const popup = this.buildPopup(item)
        const marker = new L.Marker([item.latitude, item.longitude], {
          itemObjectType: item.objectType,
          item,
          icon: L.divIcon({
            className: 'marker-icon-container',
            html:
              `<div id="${item.index}" class='marker-icon ` +
              item.color +
              `-color' onclick="window.dispatchEvent(new CustomEvent('findInList', {'detail': '${item.id}'}))">
                      <i class="` +
              item.icon +
              `"></i>
                  </div>`,
            iconSize: [32, 32]
          })
        })
          .bindPopup(popup, { autoPan: false })
        this.markers[item.id] = marker
        this.clusterMarkers.addLayer(marker)
      })
      if (this.map) {
        this.map.addLayer(this.clusterMarkers)
      }
    },
    async mapPosition (address, offset) {
      await searchGlobalItemsService
        .findAddressPosition(address)
        .then((res) => {
          if (res && res.status === 200) {
            let zoom = 13
            switch (res.data.type) {
              case 'macroregion':
              case 'region':
              case 'macrocounty':
                zoom = 11
                break
              case 'locality':
              case 'localadmin':
                zoom = 12
                break
              case 'borough':
              case 'neighbourhoods':
              case 'coarse':
              case 'postalcode':
              case 'municipality':
                zoom = 13
                break
              case 'venue':
              case 'address':
              case 'street':
                zoom = 16
                break
              case 'housenumber':
                zoom = 17
                break
              default:
                break
            }
            this.map.setView([res.data.lat, res.data.lng - offset * 3], zoom)
          }
        })
    },
    removeMarkers () {
      if (this.clusterMarkers !== null) {
        this.clusterMarkers.clearLayers()
      }
    },
    openPopup (id) {
      if (this.markers[id].__parent && this.markers[id].__parent._markers.length > 1) {
        this.markers[id].__parent.spiderfy()
        this.markers[id].openPopup()
      } else {
        this.markers[id].openPopup()
      }
    },
    async getUserCordinates () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((pos) => {
          const userCoordinates = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude
          }

          this.map.setView(
            [userCoordinates.latitude, userCoordinates.longitude],
            this.zoom
          )

          // this.marker = L.marker(
          //   [userCoordinates.latitude, userCoordinates.longitude],
          //   {
          //     icon: L.divIcon({
          //       className: "custom-div-icon",
          //       html:
          //         '<img src="' +
          //         require("../../assets/img/pipe_line/map-position.svg") +
          //         '">',
          //       iconSize: [41, 41],
          //     }),
          //   }
          // )
          //   .bindTooltip("Vous êtes ici", {
          //     direction: "top",
          //     permanent: true,
          //   })
          //   .openTooltip()
          //   .addTo(this.map);
        })
      }
    },
    capitalizeFirstLetter (string) {
      const strings = string.split(' ')
      for (let i = 0; i < strings.length; i++) {
        strings[i] = strings[i].charAt(0).toUpperCase() + strings[i].slice(1)
      }
      return strings.join(' ')
    },
    search (search) {
      this.$emit('search', search)
    },
    getId (result) {
      if (result.objectType === 'contact') {
        return 'contact-' + result.contact.id
      } else if (result.objectType === 'reference') {
        return 'reference-' + result.reference.id
      } else if (result.objectType === 'quote' || result.objectType === 'settled-folder' || result.objectType === 'legal-folder') {
        return result.objectType + '-' + result.folder.id
      } else if (result.objectType === 'ged' && result.ged.adresses && result.ged) {
        return 'ged-' + result.ged.id
      } else if (result.objectType === 'fodegi') {
        return 'fodegi-' + result.fodegi.id
      }
      return ''
    },
    getShortLabel (value) {
      return getShortLabel(value)
    },
    getActiviteLabel (value) {
      return getActiviteLabel(value, this.listReferences$)
    },
    getAbbreviation (value) {
      return getAbbreviation(value)
    },
    getTransactionTypeLabel (value) {
      return getTransactionTypeLabel(value, this.listReferences$)
    }
  }
}
</script>
<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
