
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({})
export default class QuoteStatusValidModal extends Vue {
  status: number = 7
  active: boolean = false
  reason: string | null = null
  invalidReason: boolean = false

  @Watch('status')
  onChangeStatus (): void {
    this.invalidReason = false
  }

  open (): void {
    this.active = true
    this.reset()
  }

  close (): void {
    this.active = false
  }

  reset (): void {
    this.status = 7
    this.reason = null
    this.invalidReason = false
  }

  handleConfirm (): void {
    this.$emit('validate', { status: this.status, reason: this.reason })
    this.close()
  }
}
