export const POTENTIAL_END_DELAY = 45

export const MAP_STATE_1 = 'MAP_STATE_1'
export const MAP_STATE_2 = 'MAP_STATE_2'
export const MAP_STATE_3 = 'MAP_STATE_3'

export const MAP_BOX_URL = 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png'
export const MAP_BOX_ATTRIBUTION = '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'

export const VUE_APP_API_URL = process.env.VUE_APP_API_URL
export const VUE_APP_RAPPORT_API_URL: string = String(process.env.VUE_APP_API_URL).replace('api.', 'rapport.')
export const VUE_APP_FRONT_URL = process.env.VUE_APP_FRONT_URL
export const ACTIVE_LOG_ROCKET = process.env.ACTIVE_LOG_ROCKET
export const ACTIVE_MENU = process.env.ACTIVE_MENU ? process.env.ACTIVE_MENU.split(',') : null
