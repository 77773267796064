import { contactService, Contact } from './contact.service'
import { quoteService } from './quote.service'
import { BehaviorSubject, Observable } from 'rxjs'

export class ReportExpert {
  id: number
  reportId: number
  userId: number
  position: string
  writer: boolean
  reviewer: boolean
  signer: boolean
  constructor (data?: any) {
    this.id = (data && data.id) || null
    this.reportId = (data && data.reportId) || null
    this.userId = (data && data.userId) || null
    this.position = (data && data.position) || null
    this.writer = (data && data.writer) || false
    this.reviewer = (data && data.reviewer) || false
    this.signer = (data && data.signer) || false
  }
}

export class ReportReceiption {
  id: number
  date: string
  contactId: number
  detail: string
  constructor (data?: any) {
    this.id = (data && data.id) || null
    this.date = (data && data.date) || null
    this.contactId = (data && data.contactId) || null
    this.detail = (data && data.detail) || ''
  }
}

export class ReportRdvPlace {
  id: number
  bailleurId: number
  preneurId: number
  constructor (data?: any) {
    this.id = (data && data.id) || null
    this.bailleurId = (data && data.bailleurId) || null
    this.preneurId = (data && data.preneurId) || ''
  }
}

export class ReportRdvCabinet {
  id: number
  bailleurId: number
  preneurId: number
  constructor (data?: any) {
    this.id = (data && data.id) || null
    this.bailleurId = (data && data.bailleurId) || null
    this.preneurId = (data && data.preneurId) || ''
  }
}

export class Report {
  id: number
  folderId: number | null
  fileId: number | null
  visitDate: string
  valueDate: string
  receiptDecisionDate: string
  analysis: any
  file: any
  experts: ReportExpert[]
  receiptions: ReportReceiption[]
  rdvPlaces: ReportRdvPlace[]
  rdvCabinets: ReportRdvCabinet[]
  availableContacts: Contact[]
  constructor (data?: any) {
    this.id = (data && data.id) || null
    this.folderId = (data && data.folderId) || null
    this.fileId = (data && data.fileId) || null
    this.visitDate = (data && data.visitDate) || null
    this.valueDate = (data && data.valueDate) || null
    this.receiptDecisionDate = (data && data.receiptDecisionDate) || null
    this.analysis = (data && data.analysis) || null
    this.file = (data && data.file) || null
    this.experts = (data && data.experts) || []
    this.receiptions = (data && data.receiptions) || []
    this.rdvPlaces = (data && data.rdvPlaces) || []
    this.rdvCabinets = (data && data.rdvCabinets) || []
    this.availableContacts = (data && data.availableContacts) || []
  }
}

class ReportService {
  private readonly _report: BehaviorSubject<Report | any> = new BehaviorSubject<Report | any>({})
  private readonly _reports: BehaviorSubject<Report[]> = new BehaviorSubject<Report[]>([])
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  public onChangeReport: Observable<Report | any> = this._report.asObservable()
  public onChangeReports: Observable<Report[]> = this._reports.asObservable()
  public onLoading: Observable<boolean> = this._loading.asObservable()

  get report (): Report | any {
    return this._report.getValue()
  }

  public initReport (data?: any): void {
    // check billing contacts
    const availableContacts: any = {}
    const quote = quoteService.quote
    quote.contacts.forEach((item: any) => {
      if (item.role && item.contact && item.contact.id) {
        availableContacts[item.contact.id] = item.contact
      }
    })
    if (data) {
      if (data.experts && data.experts.length > 0) {
        data.experts.forEach((expert: any) => {
          expert.userId = expert.user ? expert.user.id : null
        })
      } else {
        data.experts = [new ReportExpert()]
      }
      if (data.receiptions && data.receiptions.length > 0) {
        data.receiptions.forEach((receiption: any) => {
          receiption.contactId = receiption.contact ? receiption.contact.id : null
        })
      } else {
        data.receiptions = [new ReportReceiption()]
      }
      if (data.rdvPlaces && data.rdvPlaces.length > 0) {
        data.rdvPlaces.forEach((rdvPlace: any) => {
          rdvPlace.bailleurId = rdvPlace.bailleur ? rdvPlace.bailleur.id : null
          rdvPlace.preneurId = rdvPlace.preneur ? rdvPlace.preneur.id : null
        })
      } else {
        data.rdvPlaces = [new ReportRdvPlace()]
      }
      if (data.rdvCabinets && data.rdvCabinets.length > 0) {
        data.rdvCabinets.forEach((rdvCabinet: any) => {
          rdvCabinet.bailleurId = rdvCabinet.bailleur ? rdvCabinet.bailleur.id : null
          rdvCabinet.preneurId = rdvCabinet.preneur ? rdvCabinet.preneur.id : null
        })
      } else {
        data.rdvCabinets = [new ReportRdvCabinet()]
      }
      if (!data.analysis) {
        data.analysis = this.getInitAnalysisData()
      }
      if (data.file) {
        data.fileId = data.file.id
      }
      data.availableContacts = Object.values(availableContacts)
      const fetchedReport = new Report(data)
      this._report.next(fetchedReport)
    } else {
      const report = new Report({
        experts: [new ReportExpert()],
        receiptions: [new ReportReceiption()],
        rdvPlaces: [new ReportRdvPlace()],
        rdvCabinets: [new ReportRdvCabinet()],
        analysis: this.getInitAnalysisData(),
        availableContacts: Object.values(availableContacts)
      })
      this._report.next(report)
    }
  }

  public getInitAnalysisData (): any {
    return {
      first: [
        { label: 'Bailleur', content: '', pages: 0, observations: '', type: 'text' },
        { label: 'Preneur', content: '', pages: 0, observations: '', type: 'text' },
        { label: 'Durée', content: '', pages: 0, observations: '', type: 'text' },
        { label: 'Date d\'effet', content: '', pages: 0, observations: '', type: 'date' },
        { label: 'Date d\'échéance', content: '', pages: 0, observations: '', type: 'date' },
        { label: 'Destination', content: '', pages: 0, observations: '', type: 'text' },
        { label: 'Loyer initial', content: '', pages: 0, observations: '', type: 'number' },
        { label: 'Loyer actuel', content: '', pages: 0, observations: '', type: 'number' },
        { label: 'Indexation/révision', content: '', pages: 0, observations: '', type: 'text' },
        { label: 'Accession', content: '', pages: 0, observations: '', type: 'text' },
        { label: 'Clauses particulières', content: '', pages: 0, observations: '', type: 'text' }
      ],
      second: [
        { label: 'Sous-location', pages: 0, autorisee: false, interdite: false, nonvise: false, observations: '' },
        { label: 'Cession', pages: 0, autorisee: false, interdite: false, nonvise: false, observations: '' }
      ],
      third: [
        { label: 'Entretien', pages: 0, bailleur: false, preneur: false, nonvise: false, observations: '' },
        { label: 'Impôt foncier', pages: 0, bailleur: false, preneur: false, nonvise: false, observations: '' },
        { label: 'Art. 606 du C.Civ', pages: 0, bailleur: false, preneur: false, nonvise: false, observations: '' },
        { label: 'Assurance immeuble', pages: 0, bailleur: false, preneur: false, nonvise: false, observations: '' },
        { label: 'charges locatives courantes', pages: 0, bailleur: false, preneur: false, nonvise: false, observations: '' },
        { label: 'Réglementation ERP, hygiène, sécurité, police, travail', pages: 0, bailleur: false, preneur: false, nonvise: false, observations: '' }
      ]
    }
  }

  public addExpert (index: number): void {
    const newExpert = new ReportExpert()
    this.report.experts.splice(index + 1, 0, newExpert)
  }

  public deleteExpert (index: number): void {
    if (this.report.experts.length > 1) {
      this.report.experts.splice(index, 1)
    }
  }

  public addReceiption (index: number): void {
    const newReceiption = new ReportReceiption()
    this.report.receiptions.splice(index + 1, 0, newReceiption)
  }

  public deleteReceiption (index: number): void {
    if (this.report.receiptions.length > 1) {
      this.report.receiptions.splice(index, 1)
    }
  }

  public addRdvPlace (index: number): void {
    const newRdvPlace = new ReportRdvPlace()
    this.report.rdvPlaces.splice(index + 1, 0, newRdvPlace)
  }

  public deleteRdvPlace (index: number): void {
    if (this.report.rdvPlaces.length > 1) {
      this.report.rdvPlaces.splice(index, 1)
    }
  }

  public addRdvCabinet (index: number): void {
    const newRdvCabinet = new ReportRdvPlace()
    this.report.rdvCabinets.splice(index + 1, 0, newRdvCabinet)
  }

  public deleteRdvCabinet (index: number): void {
    if (this.report.rdvCabinets.length > 1) {
      this.report.rdvCabinets.splice(index, 1)
    }
  }

  public async updateAvailableContacts (): Promise<void> {
    const quote = quoteService.quote
    const contactsId = quote.contacts.map((contact: any) => contact.contactId)
    const contacts = await contactService.getContactsById(contactsId)
    if (this.report.availableContacts) {
      this.report.availableContacts = contacts.filter((contact: any) => {
        const find = quote.contacts.find((item: any) => item.contact && item.role && item.contactId === contact.id)
        if (find) {
          return true
        }
        return false
      })
    }
    // check reception contacts
    if (this.report.receiptions) {
      this.report.receiptions.forEach((item: any) => {
        if (item.contactId) {
          const find = this.report.availableContacts.find((contact: any) => contact.id === item.contactId)
          if (!find) {
            item.contactId = null
          }
        }
      })
    }
    // check rdvPlaces
    if (this.report.rdvPlaces) {
      this.report.rdvPlaces.forEach((item: any) => {
        if (item.bailleurId) {
          const find = this.report.availableContacts.find((contact: any) => contact.id === item.bailleurId)
          if (!find) {
            item.bailleurId = null
          }
        }
        if (item.preneurId) {
          const find = this.report.availableContacts.find((contact: any) => contact.id === item.preneurId)
          if (!find) {
            item.preneurId = null
          }
        }
      })
    }
    // check rdvCabinets
    if (this.report.rdvCabinets) {
      this.report.rdvCabinets.forEach((item: any) => {
        if (item.bailleurId) {
          const find = this.report.availableContacts.find((contact: any) => contact.id === item.bailleurId)
          if (!find) {
            item.bailleurId = null
          }
        }
        if (item.preneurId) {
          const find = this.report.availableContacts.find((contact: any) => contact.id === item.preneurId)
          if (!find) {
            item.preneurId = null
          }
        }
      })
    }

    this._report.next(this.report)
  }
}

export const reportService = new ReportService()
