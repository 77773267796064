
// A demo component. Don't use this as an example for best practices.
import { Component, Vue } from 'vue-property-decorator'
import { apiClient, baseUrl } from '../services/api.client'

enum variableTypes {
    textType = 1,
    imageType,
    arrayType
}

class Variable {
  id: number | null
  name: string | null
  ref: string | null
  value: string | null
  type: number
  description: string | null
  file?: any
  preview?: any

  get typeLabel (): string {
    switch (this.type) {
      case variableTypes.textType:
        return 'Texte'
      case variableTypes.imageType:
        return 'Image'
      case variableTypes.arrayType:
        return 'Tableau'
      default:
        return 'Inconnu'
    }
  }

  get imgSrc (): string {
    return baseUrl + 'powerpoint/variable/see/' + this.value
  }

  constructor (data: any = {}) {
    this.id = data.id || null
    this.name = data.name || null
    this.description = data.description || null
    this.value = data.value || null
    this.ref = data.variableName || null
    this.type = data.type || 1
  }
}

@Component({})
export default class PowerpointTest extends Vue {
  public variables: Variable[] = []
  public templateFile: any = null
  private message: string | null = null
  public dialogVisible: boolean = false
  public selectedVariable: Variable | null = null
  public types = [
    { key: variableTypes.textType, label: 'Texte' },
    { key: variableTypes.imageType, label: 'Image' },
    { key: variableTypes.arrayType, label: 'Tableau' }
  ]

  created () {
    this.refresh()
  }

  openDialog (variable: Variable) {
    this.selectedVariable = variable
    this.dialogVisible = true
  }

  closeDialog () {
    this.selectedVariable = null
    this.dialogVisible = false
  }

  // todo: in a helper service?
  printMessage (message: string, type: any = 'success') {
    this.$notify({
      title: type,
      message,
      type
    })
  }

  /// /////////////////////////////////////////////
  // template
  public async submitTemplate () {
    const ref: any = this.$refs.uploadFile
    const formData = new FormData()
    formData.append('template', ref.uploadFiles[0].raw)
    const response = await apiClient.post(
      '/word/template/upload',
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    const message = response.status
      ? 'Edition du template réussie.'
      : "Erreur lors de l'édition du template."
    this.printMessage(message)
    return false
  }

  public async deleteTemplate () {
    await apiClient.delete('/powerpoint/template')
    this.printMessage('Le template a été supprimé.')
  }

  /// ///////////////////////////////////////////////
  // variables
  public async refresh () {
    const res = await apiClient.get('/powerpoint/variables')
    this.variables = res.data.map((d: any) => new Variable(d))
  }

  public add () {
    this.openDialog(new Variable())
  }

  public remove (index: number) {
    this.variables.splice(index, 1)
  }

  // todo add validation
  public async submitVariable () {
    const v = this.selectedVariable
    if (v === null) {
      throw new Error('Selected variable is null!')
    }
    const formData = new FormData()

    formData.append(
      'variable',
      JSON.stringify({
        name: v.name,
        id: v.id,
        ref: v.ref,
        value: v.value,
        type: v.type,
        description: v.description
      })
    )
    if (v.type === variableTypes.imageType) {
      formData.append('image', v.file)
    }
    try {
      await apiClient.post('/powerpoint/variable', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      this.printMessage('Edition de la variable réussie.')
      this.closeDialog()
      this.refresh()
    } catch (e: any) {
      this.printMessage(e, 'error')
    }
  }

  public async deleteVariable (variable: Variable) {
    if (variable.id) {
      await apiClient.delete('/powerpoint/variable/' + variable.id)
      this.printMessage('La variable a été supprimée.')
      this.refresh()
    }
  }

  public handleImageUpload () {
    const ref: any = this.$refs.uploadImage
    const fileData = ref.uploadFiles[0].raw
    if (this.selectedVariable === null) {
      throw new Error('Selected variable is null!')
    }
    this.selectedVariable.file = fileData
    // this.selectedVariable.preview = URL.createObjectURL(file.raw);
  }

  /// /////////////////////////////////////////////////////////////
  // download
  public downloadPowerpoint () {
    window.open(
      'http://api.robine.localhost/powerpoint/edited/download',
      '_blank'
    )
  }
}
