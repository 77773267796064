
import { Component, Prop, Vue } from 'vue-property-decorator'
import { locativeBlockItems } from '../blockItems'
import { informationsBailItemList } from '../itemList'
import { floatRule } from '../formRules'
import { referenceService } from '@/services/listReference.service'
import { tap } from 'rxjs/operators'
import { shortcutsDatePicker } from '@/utils/constant'

@Component({
  subscriptions (this: any) {
    return {
      value: referenceService.onChangeReference.pipe(tap((res: any) => {
        if (res) {
          if (!res.hasExactDateBail && res.dateDeffetBailStart && res.dateDeffetBailEnd) {
            this.dateEffet = [res.dateDeffetBailStart, res.dateDeffetBailEnd]
            this.isExactDate = false
          } else if (res.hasExactDateBail && res.dateDeffetBailStart) {
            this.exactDateEffet = res.dateDeffetBailStart
            this.isExactDate = true
          }
        }
      }
      )),
      isMigratedRef: referenceService.onChangeIsMigratedRef
    }
  }
})
export default class InformationsBail extends Vue {
  @Prop({ type: Object })
    optionList: any

  // @Prop({ type: Object })
  value: any
  @Prop({ type: String })
    localLabel!: any

  @Prop({ type: Number })
    typeValeur!: any

  isExactDate = true
  isMigratedRef = false
  rangePickerOptions: any = {
    shortcuts: shortcutsDatePicker
  }

  exactDateEffet: any = null
  dateEffet: any = []
  floatRule = floatRule
  informationsBailItemList = informationsBailItemList

  created () {
    if (this.value) {
      if (this.value.hasExactDateBail) {
        this.isExactDate = this.value.hasExactDateBail
      } else {
        this.value.hasExactDateBail = this.isExactDate
      }
      if (!this.isExactDate && this.value.dateDeffetBailStart && this.value.dateDeffetBailEnd) {
        this.dateEffet = [this.value.dateDeffetBailStart, this.value.dateDeffetBailEnd]
      } else if (this.value.dateDeffetBailStart && this.isExactDate) {
        this.exactDateEffet = this.value.dateDeffetBailStart
      }
    }
  }

  handleDateDeffetBail (val: any) {
    if (val && val.length > 1) {
      this.value.dateDeffetBailStart = val[0]
      this.value.dateDeffetBailEnd = val[1]
    } else {
      this.value.dateDeffetBailStart = ''
      this.value.dateDeffetBailEnd = ''
    }
    this.dateEffet = [this.value.dateDeffetBailStart, this.value.dateDeffetBailEnd]
  }

  handleExactDateDeffetBail (val: string) {
    this.value.dateDeffetBailStart = val
    this.value.dateDeffetBailEnd = ''
    this.exactDateEffet = val
  }

  toggleExactDate (val: boolean) {
    if (val) {
      this.exactDateEffet = this.value.dateDeffetBailStart
      this.value.dateDeffetBailEnd = ''
      this.value.hasExactDateBail = true
    } else {
      this.value.hasExactDateBail = false
    }
  }

  checkDisplayCondition (title: string) {
    if (this.isMigratedRef || title === 'Destination') {
      return true
    }
    const localLabel = this.localLabel
    return (
      this.typeValeur === 1 && locativeBlockItems[localLabel] && !locativeBlockItems[localLabel].includes(title)
    )
  }

  changeSelect (item: any) {
    if (this.value[item.value] === 0) {
      this.value[item.value] = null
    }
  }
}
