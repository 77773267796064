
import { Component, Prop, Vue } from 'vue-property-decorator'
import ModificationManagement from './partial/Modifiation.vue'
import CostsManagement from './partial/Costs.vue'
import { LegalFolder, CourrierContact } from '@/models/legalFolder.model'
import { legalFolderService } from '@/services/legalFolder.service'
import { convertFrNumberEuro } from '@/utils/functions'
import { quoteService, Quote } from '@/services/quote.service'
import { contactService, Contact } from '@/services/contact.service'
import { contactAddressService, ContactAddress } from '@/services/contactAddress.service'
import { HonorairesAPI } from '@/utils/honorairesAPI'

@Component({
  subscriptions () {
    return {
      legalFolder$: legalFolderService.onChangeLegalFolder,
      quote$: quoteService.onChangeQuote,
      allContacts$: contactService.onChange,
      allContactAddress$: contactAddressService.onChangeAllContactAddress
    }
  },
  components: {
    ModificationManagement,
    CostsManagement
  },
  methods: {
    convertFrNumberEuro
  }
})

export default class HonorairesManagement extends Vue {
  @Prop({ default: false }) updateable: boolean
  legalFolder$: LegalFolder
  quote$: Quote
  allContacts$: Contact[]
  allContactAddress$: ContactAddress[]

  get totalFee (): number {
    return this.legalFolder$.modification.getTotalPrice() + this.legalFolder$.costs.getTotalPrice()
  }

  get courrierContacts (): any[] {
    const courrierContacts: any[] = this.legalFolder$.contacts.filter((contact: any) => {
      return !!contact.contactId
    }).map((contactInfo: any) => {
      const email = `email${contactInfo.email}`
      let contactAddress: any = null
      if (contactInfo.contactAddressId) {
        contactAddress = this.allContactAddress$.find((contactAddress: ContactAddress) => contactAddress.id === contactInfo.contactAddressId)
      }
      const data = {
        contact: contactInfo.contact,
        contactAddress,
        email,
        interlocuteur: contactInfo.interlocuteur,
        mandator: contactInfo.mandator,
        type: contactInfo.type
      }
      return new CourrierContact(data)
    })
    return courrierContacts
  }

  exportXlsx () {
    const honorairesAPI: HonorairesAPI = new HonorairesAPI(this.courrierContacts)
    honorairesAPI.generate()
  }
}
