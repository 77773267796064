
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class DownloadModal extends Vue {
  isNumberFormat: boolean = false
  active: boolean = false

  open (): void {
    this.active = true
  }

  close (): void {
    this.active = false
  }

  handleDownload (): void {
    this.$emit('download', this.isNumberFormat)
    this.close()
  }
}
