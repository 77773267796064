
import { Component, Prop, Vue } from 'vue-property-decorator'
import { reportService, Report } from '@/services/report.service'

    // The @Component decorator indicates the class is a Vue component
    @Component({
      subscriptions () {
        return {
          report$: reportService.onChangeReport
        }
      }
    })

export default class ReportJudiciaire extends Vue {
        @Prop({ default: false }) updateable: boolean
        report$: Report = new Report()

        deleteReceiption (index: number): void {
          reportService.deleteReceiption(index)
        }

        addReceiption (index: number): void {
          reportService.addReceiption(index)
        }

        changeReceiption (item: any, itemIndex: number): void {
          this.report$.receiptions.splice(itemIndex, 1, item)
        }

        deleteRdvPlace (index: number): void {
          reportService.deleteRdvPlace(index)
        }

        addRdvPlace (index: number): void {
          reportService.addRdvPlace(index)
        }

        changeRdvPlace (item: any, itemIndex: number) {
          this.report$.rdvPlaces.splice(itemIndex, 1, item)
        }

        deleteRdvCabinet (index: number): void {
          reportService.deleteRdvCabinet(index)
        }

        addRdvCabinet (index: number): void {
          reportService.addRdvCabinet(index)
        }

        changeRdvCabinet (item: any, itemIndex: number) {
          this.report$.rdvCabinets.splice(itemIndex, 1, item)
        }
}
