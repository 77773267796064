
import { Component, Prop, Vue } from 'vue-property-decorator'
import { SettledFolder, settledFolderService } from '@/services/settledFolder.service'
import { Quote, quoteService } from '@/services/quote.service'
import { LegalFolder } from '@/models/legalFolder.model'
import { legalFolderService } from '@/services/legalFolder.service'
import { formatUserName, getNow } from '@/utils/functions'
import { fileService } from '@/services/file.service'
import html2canvas from 'html2canvas'
import b64toBlob from 'b64-to-blob'

@Component({
  subscriptions () {
    return {
      settleFolder$: settledFolderService.onChangeFolder,
      quote$: quoteService.onChangeQuote,
      legalFolder$: legalFolderService.onChangeLegalFolder
    }
  },
  components: {}
})

export default class ConflitDialog extends Vue {
  @Prop({ type: String }) type: string
  @Prop({ type: String, default: null }) name: string

  quote$: Quote = new Quote()
  settleFolder$: SettledFolder = new SettledFolder()
  legalFolder$: LegalFolder = new LegalFolder()
  titleConflit: string = 'Conflits au ' + getNow()

  created () {
  }

  getListConflit () {
    if (this.type === 'settleFolder') {
      return this.settleFolder$.conflit
    }
    if (this.type === 'legalFolder') {
      return this.legalFolder$.conflit
    }
    if (this.type === 'quote') {
      return this.quote$.conflit
    }
  }

  getContactName (contacts: any[], natureConflit: string) {
    let contactNames: string
    if (!contacts) {
      return
    }
    if (natureConflit !== 'Contact présent chez FODEGI' && natureConflit !== 'Biens sous gestion FODEGI') {
      contacts = contacts.filter(item => item.mandator)
      if (contacts.length === 1) {
        contactNames = contacts.map((item: any) => {
          return item.contact ? formatUserName(item.contact.nom, item.contact.prenom) : ''
        }).join('')
      } else {
        contactNames = contacts.map((item: any) => {
          return item.contact ? formatUserName(item.contact.nom, item.contact.prenom) : ''
        }).join(', ')
      }
    } else {
      contactNames = contacts[0]
    }
    return contactNames
  }

  getAddresses (addresses: any) {
    if (!addresses) {
      return
    }
    const addressesLabel = addresses.map((item: any) => {
      return item.buildingNumber + ' ' + item.street + ' ' + item.zip + ' ' + item.city
    })
    return addressesLabel.join('\n ')
  }

  // Capture a screenshot of .conflitContainer div
  saveConflits () {
    const captureContainer = document.querySelector('.conflictContainer') as HTMLElement
    const self: any = this

    html2canvas(captureContainer, {
      backgroundColor: '#ffffff',
      allowTaint: false,
      useCORS: true,
      scrollX: 0,
      scrollY: -window.scrollY
    }).then(function (canvas) {
      const imgData = canvas.toDataURL('image/png')
      const contentType = imgData.split(',')[0]
      const b64Data = imgData.split(',')[1]
      const blob = b64toBlob(b64Data, contentType)

      const current = new Date()
      const date = current.getDate() + '-' + (current.getMonth() + 1) + '-' + current.getFullYear()
      const filename = 'conflits-d-interet-' + self.name + '-' + date + '.png'
      const imgFile = new File([blob], filename)
      fileService.uploadFile(imgFile, 'Random', 6).then(() => {
        self.$message({
          type: 'success',
          message: 'La capture d\'écran a bien été sauvegardé dans les pièces jointes.'
        })
        self.$emit('showConflits', false)
      }).catch(() => {
        self.$message({
          message: "Erreur lors de l'enregistrement de la capture d'écran.",
          type: 'error',
          offset: 65
        })
      })
    })
  }
}
