import Vue from 'vue'

Vue.filter('strToUpper', function (text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1)
})

Vue.filter('capitalizeFirstLetter', function (text: string) {
  const strings = text.split(' ')
  for (let i = 0; i < strings.length; i++) {
    strings[i] = strings[i].charAt(0).toUpperCase() + strings[i].slice(1)
  }
  return strings.join(' ')
})
