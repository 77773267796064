import { Quote } from '@/services/quote.service'
import { User } from '@/services/user.service'
import { SettledFolder } from '@/services/settledFolder.service'
import { LegalFolder } from '@/models/legalFolder.model'

export class NotificationType {
  id: number
  type: number
  title: string
  content: string
  constructor (data: any = {}) {
    this.id = data.id || null
    this.type = data.type || null
    this.title = data.title || ''
    this.content = data.content || ''
  }
}

export class Notification {
  id: number
  recipient: User
  type: NotificationType
  quote: Quote
  folder: SettledFolder | LegalFolder | null
  content: string
  checked: boolean
  createdAt: string
  nameType: string

  constructor (data: any = {}) {
    this.id = data.id || null
    this.recipient = new User(data.recipient)
    this.type = new NotificationType(data.type)
    this.quote = data.quote || null
    this.nameType = data.nameType || null
    this.content = data.content || ''
    this.checked = data.checked || false
    this.createdAt = data.createdAt
    if (data.settledFolder != null) {
      this.folder = data.settledFolder
    } else if (data.legalFolder != null) {
      this.folder = data.legalFolder
    } else {
      this.folder = null
    }
  }
}
