
import { Component, Vue } from 'vue-property-decorator'
import { Notification } from '@/models/notification.model'
import { notificationService } from '@/services/notification.service'
import { PageParams } from '@/models/ged.model'
import moment from 'moment'

Vue.filter('formatDate', function (value: string) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
  }
})

@Component({
  subscriptions () {
    return {
      notifications$: notificationService.onChangeNotifications,
      totalNotifications$: notificationService.onChangeTotalNotifications,
      pageParams$: notificationService.onChangePageParams
    }
  },
  components: {}
})

export default class DashboardNotification extends Vue {
  notifications$: Notification[]
  totalDossiers$: number = 0
  pageParams$: PageParams

  created () {
    notificationService.getNotifications()
  }

  hide (id: number): void {
    notificationService.deactivateNotification(id)
  }

  onChangeCurrent (currentPage: number) {
    this.pageParams$.currentPage = currentPage
    notificationService.getNotifications()
  }

  onChangeSizePage (sizePage: number) {
    this.pageParams$.pageSize = sizePage
    notificationService.getNotifications()
  }

  gotToFolder (notification: Notification) {
    if (notification.folder != null) {
      return this.$router.push({
        name: notification.folder.type,
        params: {
          id: notification.folder.id.toString()
        }
      })
    } else {
      return this.$router.push({
        name: 'Quote',
        params: {
          id: notification.quote.id
        }
      })
    }
  }
}
