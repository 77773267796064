import Vue from 'vue'
import VueRouter from 'vue-router'
import Connexion from '../views/Connexion.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Users from '../views/Users.vue'
import UserView from '../views/User.vue'
import Roles from '../views/Roles.vue'
import RoleView from '../views/Role.vue'
import Quote from '../views/Quote.vue'
import SettledFolder from '../views/SettledFolder.vue'
import LegalFolder from '../views/LegalFolder.vue'
import { authService } from '@/services/auth.service'

import Dossier from '../components/dossier/Dossier.vue'
import Client from '../components/client/Client.vue'
// import Folder from "../components/folder/Folder.vue"
// import MissionManagement from "../components/folder/MissionManagement.vue"
// import PowerpointTest from "@/views/PowerpointTest.vue";
import WordTest from '@/views/WordTest.vue'
import SearchRef from '../components/searchRef/SearchRef.vue'
import AddReference from '../components/addReference/AddReference.vue'
import ContactManagement from '@/views/ContactManagement.vue'
import Search from '@/views/Search.vue'
import ImportRef from '@/views/ImportRef.vue'

import GED from '@/views/GED.vue'
import Home from '@/views/Home.vue'
import Dashboard from '@/views/Dashboard.vue'
import TwoFactor from '@/views/TwoFactor.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/connexion',
    name: 'connexion',
    component: Connexion,
    meta: { title: 'R&A' }
  },
  {
    path: '/connexion/2fa',
    name: '2fa',
    component: TwoFactor,
    meta: { title: 'R&A' }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: { title: 'R&A' }
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { title: 'R&A' }
  },
  {
    path: '/test',
    name: 'Test',
    component: async () => await import('../views/Test.vue'),
    meta: { title: 'R&A' }
  },
  {
    path: '/',
    component: Dashboard,
    name: 'Dashboard',
    meta: { requiresAuth: true, title: 'R&A' }
  },
  {
    path: '/users',
    component: Users,
    meta: { requiresAuth: true, title: 'Utilisateur' },
    name: 'users'
  },
  {
    path: '/user/:id?',
    component: UserView,
    meta: { requiresAuth: true, title: 'Utilisateur' },
    name: 'user'
  }, {
    path: '/roles',
    component: Roles,
    meta: { requiresAuth: true, title: 'Role' },
    name: 'roles'
  }, {
    path: '/role/:id?',
    component: RoleView,
    meta: { requiresAuth: true, title: 'Role' },
    name: 'role'
  },
  {
    path: '/liste-dossiers',
    component: Dossier,
    meta: { requiresAuth: true, title: 'Dossiers' },
    name: 'QuoteList'
  },
  {
    path: '/clients',
    name: 'Client',
    component: Client,
    meta: { requiresAuth: true, title: 'Contact' }
  },
  {
    path: '/devis/:id?',
    component: Quote,
    name: 'Quote',
    meta: { requiresAuth: true, title: 'Devis' }
  },
  {
    path: '/dossier-amiable/:id',
    component: SettledFolder,
    meta: { requiresAuth: true, title: 'Dossier' },
    name: 'settled-folder'
  },
  /* {
    path: '/powerpoint',
    component: PowerpointTest,
    meta: { requiresAuth: true, title: 'R&A' }
  }, */
  {
    path: '/word',
    component: WordTest,
    meta: { requiresAuth: true, title: 'R&A' }
  },
  {
    path: '/search-ref/:id?',
    component: SearchRef,
    name: 'SearchRef',
    meta: { requiresAuth: true, title: 'Référence' }
  },
  {
    path: '/add-ref',
    component: AddReference,
    name: 'AddReference',
    meta: { requiresAuth: true, title: 'Référence' }
  },
  {
    path: '/edit-ref/:id',
    component: AddReference,
    meta: { requiresAuth: true, title: 'Référence' }
  },
  {
    path: '/import-ref',
    component: ImportRef,
    meta: { requiresAuth: true, title: 'Import Référence' }
  },
  {
    path: '/contact/:id',
    component: ContactManagement,
    name: 'Contact',
    meta: { requiresAuth: true, title: 'Contact' }
  },
  {
    path: '/dossier-judiciaire/:id?',
    component: LegalFolder,
    meta: { requiresAuth: true, title: 'Dossier' },
    name: 'legal-folder'
  },
  {
    path: '/ged',
    component: GED,
    meta: { requiresAuth: true, title: 'Documentation' },
    name: 'Ged'
  },
  {
    path: '/search',
    component: Search,
    meta: { requiresAuth: true, title: 'R&A' },
    name: 'Search'
  },
  {
    path: '/global-search',
    name: 'Home',
    component: Home,
    meta: { title: 'R&A' },
    props: { isMySearch: false }
  },
  {
    path: '/my-searches',
    name: 'Mes recherches',
    component: Home,
    meta: { title: 'R&A' },
    props: { isMySearch: true }
  },
  { path: '*', redirect: '/' }
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // permit to update Title
  document.title = (to.meta && to.meta.title) || ''

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (authService.userValue != null) {
      next() // make sure to always call next()!
    } else {
      next({
        path: '/connexion',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
