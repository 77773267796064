
import { Component, Vue, Ref } from 'vue-property-decorator'
import { Form } from 'element-ui/types'
import GeneralInfo from './components/GeneralInfo.vue'
import SurfaceCapacite from './components/SurfaceCapacite.vue'
import LoyersPrix from './components/LoyersPrix.vue'
import Performaces from './components/Performances.vue'
import InformationsBail from './components/InformationsBail.vue'
import Jugement from './components/Jugement.vue'
import InformationsVente from './components/InformationsVente.vue'
import TableauPonderation from './components/TableauPonderation.vue'
import EmplacementImage from './components/EmplacementImage.vue'
import ObservationImportantes from './components/ObservationImportantes.vue'
import ObservationGenerals from './components/ObservationGenerals.vue'
import PiecesJointes from './components/PiecesJointes.vue'
import MapPreview from '../element/MapPreview.vue'
import { ListReference, listReferenceService, ReferenceAddress, referenceService } from '@/services/listReference.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import { authService } from '@/services/auth.service'
import { referenceFileService } from '@/services/referenceFile.service'
import {
  isFloat, roundNumber, convertTimeStampToDateAndTime,
  periodString,
  localeDateStr,
  generateTypeLocalGroup,
  generateActivitiesGroup
} from '@/utils/functions'
import { baseBlockItems } from './blockItems'

@Component({
  subscriptions (this: Vue) {
    return {
      loading: listReferenceService.onLoading,
      user$: userService.onChange,
      form: referenceService.onChangeReference,
      referenceAddress$: referenceService.onChangeReferenceAdressse
    }
  },
  components: {
    GeneralInfo,
    SurfaceCapacite,
    LoyersPrix,
    Performaces,
    InformationsBail,
    Jugement,
    InformationsVente,
    TableauPonderation,
    ObservationImportantes,
    ObservationGenerals,
    EmplacementImage,
    PiecesJointes,
    MapPreview
  },
  watch: {
    $route () {
      (this as any).initRef()
    }
  }
})
export default class AddReference extends Vue {
  @Ref() readonly generateReferenceForm!: Form
  periodString: Function = periodString
  localeDateStr: Function = localeDateStr

  loading = false
  user$: User = new User()
  form: any = {
    confidentialite: false,
    ficheInformations: new Date(),
    tablePonderation: [
      {
        niveau: '',
        zone: '',
        surfaceUtile: 0,
        ponderation: 0,
        surfacePonderee: 0
      }
    ],
    fileIds: [],
    fileList: []
  }

  referenceAddress$: ReferenceAddress = new ReferenceAddress()
  typeValeur: ListReference[] = []
  typeLocalItems: any[] = []
  typeTransaction: any[] = []
  activites: any[] = []
  typeLocalItemsList: ListReference[] = []
  typeTransactionLocative: ListReference[] = []
  typeTransactionVenale: ListReference[] = []
  surfaceM2: any = 0
  surfacePonderee: any = 0
  optionList: any = {
    fiabilite: [],
    typePonderation: [],
    natureCapital: [],
    impotFoncier: [],
    motifDeplafonnement: []
  }

  fileList: any = []
  localLabel: string = ''
  transactionLabel: string = ''
  valeur: Number = 0
  loaded: boolean = false
  updater: string = ''
  isUploading: boolean = false

  created () {
    if (!authService.userValue || !authService.userValue.id) {
      this.$message({
        type: 'error',
        message: 'Utilisateur invalide',
        offset: 65
      })
      return false
    } else {
      userService
        .getUserById(authService.userValue.id.toString())
        .then(() => {
          this.initRef()
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: 'Invalid User',
            offset: 65
          })
          return false
        })
    }
  }

  get accessable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.REFERENCE_CREATE)
  }

  get editable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.REFERENCE_UPDATE)
  }

  initRef () {
    (this.$refs.generateReferenceForm as any).clearValidate()
    if (this.accessable) {
      if (!this.$route.params.id && localStorage.getItem('duplicatedReference')) {
        const reference = JSON.parse(localStorage.getItem('duplicatedReference') || '')
        reference.ficheId = null
        reference.fileIds = null
        reference.fileList = null
        reference.id = null
        referenceService.initRef(reference)
        referenceService.initReferenceAddresse(reference)
        this.getListReferences()
        this.setLabels(reference)
        localStorage.removeItem('duplicatedReference')
        this.$message({
          message: 'La duplication a réussi, sauvegardé pour confirmer',
          type: 'success',
          offset: 65
        })
        return
      }
      referenceFileService.initFiles()
      this.getListReferences()
      if (this.$route.params.id) {
        if (this.editable) {
          const refId = this.$route.params.id
          listReferenceService.getRefById(refId)
            .then((res: any) => {
              referenceService.initRef(res)
              referenceService.initReferenceAddresse(res)
              this.setLabels(res)
              referenceFileService.getAllReferenceFiles(refId)
              this.updaterInfo()
              if (res.adresse) {
                document.title = res.adresse
              }
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: 'Référence introuvable ou déjà supprimée.',
                offset: 65
              })
              this.$router.replace('/')
            })
        } else {
          this.$message({
            type: 'error',
            message: "Vous n'avez pas le droit de modifier une référence.",
            offset: 65
          })
          this.$router.go(-1)
        }
      } else {
        this.fileList = []
        const newRef = {
          confidentialite: false,
          dateDeffetBailEnd: '',
          dateDeffetBailStart: '',
          ficheInformations: new Date(),
          tablePonderation: [
            {
              niveau: '',
              zone: '',
              surfaceUtile: 0,
              ponderation: 0,
              surfacePonderee: 0
            }
          ],
          fileIds: [],
          fileList: []
        }
        referenceService.initReferenceAddresse(newRef)
        referenceService.initRef(newRef)
      }
    } else {
      this.$message({
        type: 'error',
        message: "Vous n'avez pas le droit de créer une référence.",
        offset: 65
      })
      this.$router.go(-1)
    }
  }

  getListReferences () {
    listReferenceService.getListReference().then(res => {
      const typeLocalItemsList = res.filter((item: any) => item.type === 1)
      this.typeLocalItemsList = typeLocalItemsList
      this.typeLocalItems = generateTypeLocalGroup(typeLocalItemsList)
      const activites = res.filter((item: any) => item.type === 3)
      this.activites = generateActivitiesGroup(activites)
      this.optionList.natureCapital = res.filter((item: any) => item.type === 5)
      this.typeTransactionLocative = res.filter((item: any) => item.type === 4)
      this.optionList.motifDeplafonnement = res.filter((item: any) => item.type === 6)
      this.typeTransactionVenale = res.filter((item: any) => item.type === 7)
      this.optionList.typePonderation = res.filter((item: any) => item.type === 8)
      this.optionList.fiabilite = res.filter((item: any) => item.type === 9)
      this.optionList.impotFoncier = res.filter((item: any) => item.type === 10)
      this.typeValeur = res.filter((item: ListReference) => item.type === 11)
      this.typeTransaction = [
        {
          label: 'VALEUR LOCATIVE',
          options: this.typeTransactionLocative
        },
        {
          label: 'VALEUR VENALE',
          options: this.typeTransactionVenale
        }
      ]
      if (!this.$route.params.id) {
        this.loaded = true
      }
    })
  }

  checkDisplayCondition (title: string) {
    const localLabel = this.getLocalLabel(this.form.typeLocal)
    return (
      baseBlockItems[localLabel] && !baseBlockItems[localLabel].includes(title)
    )
  }

  getLocalLabel (typeLocal: Number) {
    let label = ''
    const target: any = this.typeLocalItemsList.filter((item: any) => item.num === typeLocal)
    target.length > 0 ? (label = target[0].label) : (label = '')

    return label
  }

  getTransactionLabel (typeTransaction: Number) {
    let label = ''
    const target: any = [...this.typeTransactionLocative, ...this.typeTransactionVenale].filter((item: any) => item.num === typeTransaction)
    target.length > 0 ? (label = target[0].label) : (label = '')

    return label
  }

  saveFileIds (savedFileId: Number) {
    this.form.fileIds.push(savedFileId)
  }

  isFloat = isFloat

  roundNumber = roundNumber

  convertTimeStampToDateAndTime = convertTimeStampToDateAndTime

  onUpdatedComponent (value: any) {
    this.form = { ...this.form, ...value }
  }

  updatedTypeLocal (value: Number) {
    this.localLabel = this.getLocalLabel(value)
  }

  updatedTypeTransaction (value: Number) {
    this.transactionLabel = this.getTransactionLabel(value)
  }

  updatedTypeValeur (value: Number) {
    this.valeur = value
  }

  handleSelectLocation ({ lat, lon }: any): void {
    this.referenceAddress$.lat = lat
    this.referenceAddress$.lon = lon
  }

  deleteRef () {
    if (!this.$route.params.id) {
      return
    }
    this.$confirm('êtes-vous sûr de vouloir supprimer cette fiche ?', '', {
      confirmButtonText: 'Confirmation',
      cancelButtonText: 'Annuler',
      type: 'warning'
    }).then(() => {
      this.updater = ''
      listReferenceService.delete(this.form.id).then(() => {
        this.$message({
          message: 'La référence a été supprimée avec succès.',
          type: 'success',
          offset: 65
        })
        this.$router.replace('/add-ref/')
      }).catch(() => {
        this.$message({
          message: 'Erreur! Veuillez réessayer plus tard.',
          type: 'error',
          offset: 65
        })
      })
    })
  }

  onSubmit () {
    if (this.isUploading) {
      return
    }
    this.generateReferenceForm.validate((valid: any) => {
      const userName = this.user$.prenom + ' ' + this.user$.nom
      const userId = this.user$.id
      if (this.$route.params.id) {
        this.form.id = this.$route.params.id
        this.form.updaterId = userId
        this.form.updaterName = userName
      } else {
        this.form.creatorId = userId
        this.form.creatorName = userName
      }
      if (valid) {
        let message = ''
        if (this.$route.params.id) {
          message = 'La référence a été modifiée avec succès.'
        } else {
          message = 'La référence a été enregistrée avec succès.'
        }
        listReferenceService.saveReference(this.form, this.referenceAddress$)
          .then((res: any) => {
            this.$message({
              message,
              type: 'success',
              offset: 65
            })
            if (this.$route.params.id) {
              referenceService.initRef(res)
              referenceService.initReferenceAddresse(res)
              this.updaterInfo()
            }
            this.$router.replace(`/edit-ref/${res.id}`)
          })
          .catch(() => {
            this.$message({
              message: 'Erreur! Veuillez réessayer plus tard.',
              type: 'error',
              offset: 65
            })
          })
      }
      return valid
    })
  }

  duplicateRef () {
    if (!this.$route.params.id) {
      return
    }
    referenceService.duplicateReference().then(() => {
      window.open(this.$router.resolve({ path: '/add-ref' }).href, '_blank')
    })
  }

  setLabels (res: any) {
    this.form = res
    this.form.fileIds = []
    this.localLabel = this.getLocalLabel(res.typeLocal)
    this.transactionLabel = this.getTransactionLabel(res.typeTransaction)
    this.valeur = res.typeValeur
    if (res.referencesFile) {
      res.referencesFile.forEach((referenceFile: any) => {
        if (referenceFile.type === 'Description') {
          const url = referenceFile.url
          const name = referenceFile.name
          const id = referenceFile.id
          const file = { name, url, id }
          this.fileList.push(file)
        }
      })
    }
    this.loaded = true
  }

  updaterInfo () {
    if (!this.form || this.form.updaterName == null || this.form.updatedAt == null) {
      this.updater = ''
    } else {
      this.updater = 'Modification le ' + localeDateStr(this.form.updatedAt) + ' par ' + this.form.updaterName
    }
  }

  uploadStatus (res: boolean) {
    this.isUploading = res
  }

  isUserAllowedToDeleteReference () {
    const user = JSON.parse(JSON.stringify(authService.userValue))
    return userService.hasRight(user, USER_RIGHTS.DELETE_REFERENCE)
  }
}
