<template>
  <main-wrap :show-user-profil="false">
    <div class="search-container">
      <div
        class="map-container"
        :class="{ activeSearch: !showResult }">
        <map-search
          ref="mapSearch"
          :filtered-items="filteredGlobalItems"
          :global-item-search="globalItemSearch"
          :is-collapse="showResult"
          :show-result="showResult"
          @search="searchGlobalItems"
          @showAdvanced="showAdvancedAndOpenResult()"
          @miniature="openDetail"
          @detail="openFolderDetails"
          @showConflicts="handleOpenConflictModal"
          @findInList="handleFindInList" />
      </div>
      <div
        class="list-container"
        :class="{ hide: showResult }">
        <span
          class="close-block"
          :class="{ active: !showResult }"
          @click="showResults()">
          <i class="rb-arrow-down" />
        </span>
        <div class="result-container d-flex flex-column">
          <div class="filter-container">
            <h3>Recherche</h3>
            <filter-form
              :is-advanced="isAdvanced"
              @showAdvanced="showAdvanced()"
              @search="searchGlobalItems" />
            <div v-if="isAdvanced">
              <advanced-filter
                @hideAdvanced="hideAdvanced()"
                @search="searchGlobalItems" />
            </div>
          </div>
          <template v-if="globalItemsTotal > 0">
            <div class="result-top">
              <h2>
                Résultats
                <span>{{ globalItemsTotal }}
                  {{ globalItemsTotal > 1 ? "résultats" : "résultat" }}</span>
              </h2>
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center justify-content-start">
                  <div
                    v-if="!currentHistory$.id"
                    class="
                    d-flex
                    align-items-center
                    justify-content-start
                    mr-3
                    pointer
                  "
                    @click="openHistoryModal(false)">
                    <img
                      class="mr-2"
                      :src="require('../assets/img/pipe_line/save.svg')">
                    Sauvegarder la recherche
                  </div>
                  <div
                    v-if="currentHistory$.id"
                    class="
                    d-flex
                    align-items-center
                    justify-content-start
                    mr-3
                    pointer
                  "
                    @click="openHistoryModal(true)">
                    <img
                      class="mr-2"
                      :src="require('../assets/img/pipe_line/save.svg')">
                    Nouvelle sauvegarde
                  </div>
                  <div
                    v-if="currentHistory$.id"
                    class="
                    d-flex
                    align-items-center
                    justify-content-start
                    mr-3
                    pointer
                  "
                    @click="openHistoryModal(false)">
                    <img
                      class="mr-2"
                      :src="require('../assets/img/pipe_line/save.svg')">
                    Mettre à jour la sauvegarde
                  </div>
                  <div
                    class="
                    d-flex
                    align-items-center
                    justify-content-start
                    mr-3
                    pointer
                  "
                    @click="showSaveSearch()">
                    <img
                      class="mr-2"
                      :src="require('../assets/img/pipe_line/eye.svg')">
                    Mes recherches
                  </div>
                  <el-button
                    class="btn-download"
                    :disabled="getIsExporting()"
                    :loading="getIsExporting()"
                    @click="exportGlobalSearchItems()">
                    <img
                      :src="require('../assets/img/pipe_line/file_download.svg')">
                  </el-button>
                </div>
                <div class="d-flex align-items-center justify-content-start ml-3">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Trier par date"
                    placement="left">
                    <el-button
                      v-if="sort === 'desc'"
                      @click="sortAsc">
                      <img
                        :src="require('../assets/img/pipe_line/recent_black.svg')">
                    </el-button>
                    <el-button
                      v-else
                      class="black"
                      @click="sortDesc">
                      <img :src="require('../assets/img/pipe_line/recent.svg')">
                    </el-button>
                  </el-tooltip>
                  <div
                    class="
                    d-flex
                    align-items-center
                    justify-content-start
                    ml-3
                    view
                    pl-3
                  ">
                    <el-button
                      :class="{ active: isViewlist }"
                      @click="viewList()">
                      <i class="rb-format_list_bulleted" />
                    </el-button>
                    <el-button
                      :class="{ active: !isViewlist }"
                      @click="viewGrid()">
                      <i class="rb-grid_view" />
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="onglet-container">
                <ul class="d-flex align-items-center justify-content-start flex-wrap">
                  <li
                    :class="{ active: activeId === 'all' }"
                    @click="selectMember('all')">
                    Tout voir<span>{{ globalItems.length }}</span>
                  </li>
                  <template v-for="(menu, index) in menuList">
                    <li
                      v-if="menu.number > 0"
                      :key="index"
                      :class="[{ active: menu.id === activeId }, menu.class]"
                      @click="selectMember(menu.id)">
                      {{ menu.title }}
                      <span>{{ menu.number }}</span>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
            <div class="result-list flex-grow-1">
              <div
                v-if="!isViewlist"
                id="result-grid"
                class="h-100">
                <perfect-scrollbar>
                  <result-grid
                    ref="resultgrid"
                    :results="filteredGlobalItems"
                    :is-detail="isDetail"
                    :is-result-assoc="isResultAssoc"
                    @miniature="openDetail"
                    @detail="openFolderDetails"
                    @result="openResultAssoc"
                    @changeView="changeView"
                    @togglePin="changePinStatus"
                    @showDocument="showDocumentModal" />
                </perfect-scrollbar>
              </div>
              <div
                v-else
                id="result-list"
                class="h-100">
                <perfect-scrollbar>
                  <result-list
                    ref="resultlist"
                    :current-tab="activeId"
                    :results="filteredGlobalItems"
                    :is-detail="isDetail"
                    :is-result-assoc="isResultAssoc" />
                </perfect-scrollbar>
              </div>
              <div class="scroll-result">
                <div class="check-result">
                  <el-checkbox v-model="pinnedOnly">
                    Voir les résultats épinglés seulement
                    <span class="nb">({{ countPinnedResults }})</span>
                  </el-checkbox>
                </div>
                <span
                  v-if="isHide"
                  class="text">scroll</span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="emptyResult">
              <img
                class="icon"
                :src="require('../assets/img/groupe8951.svg')">
            </div>
          </template>
        </div>
        <div v-if="isSaveSearch">
          <saved-search @closeModal="hideSaveSearch" />
        </div>
        <transition name="slide-fade">
          <div
            v-if="isDetail && resultsDetails"
            class="detail-container">
            <div class="detail-head">
              <h3>Détails</h3>
              <span
                class="close-detail"
                @click="isDetail = false">
                <i class="rb-close" />
              </span>
            </div>
            <perfect-scrollbar>
              <folder-result-details
                v-if="resultsDetails.folder"
                :result="resultsDetails" />
              <contact-result-details
                v-if="resultsDetails.contact"
                :result="resultsDetails" />
              <ged-result-details
                v-if="resultsDetails.ged"
                :result="resultsDetails" />
              <ref-result-details
                v-if="resultsDetails.reference"
                :result="resultsDetails" />
            </perfect-scrollbar>

            <div class="detail-footer">
              <div class="d-flex align-items-center justify-content-center detail-footer-top">
                <div class="btn-container">
                  <el-button
                    v-if="resultsDetails.files"
                    @click="downloadResultFiles(resultsDetails)">
                    <i class="rb-file_download" />
                  </el-button>
                  <el-button @click="deleteResultFolder(resultsDetails)">
                    <i class="rb-poubelle" />
                  </el-button>
                </div>
                <div
                  v-if="resultsDetails.folder && resultsDetails.reportExperts"
                  class="rating-container">
                  <div class="rating-content">
                    <el-tooltip
                      v-for="(expert, index) in resultsDetails.reportExperts"
                      :key="index"
                      class="item"
                      effect="dark"
                      :content="expert && expert.user ? expert.user.nom +' '+ expert.user.prenom : ''"
                      placement="right">
                      <div class="rating">
                        <i
                          v-if="expert && expert.user && expert.user.email === resultsDetails.createdBy"
                          class="rb-noun-crown" />
                        <span v-if="expert && expert.user">
                          <img
                            :src="getAvatarUrl(expert)"
                            @error="onLoadImageError">
                        </span>
                      </div>
                    </el-tooltip>
                    <div class="rating">
                      <span>
                        {{ resultsDetails.reportExperts.length }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="align-items-center d-flex justify-content-center">
                <el-button
                  class="btn-detail-foot"
                  @click="openFolderDetails(resultsDetails)">
                  <i class="rb-open-new-alt" />
                  En savoir plus
                </el-button>
              </div>
            </div>
          </div>
        </transition>
        <transition name="slide-fade">
          <div
            v-if="isResultAssoc"
            class="detail-container ged-color">
            <div class="detail-head">
              <h3>Résultats associés</h3>
              <span
                class="close-detail"
                @click="isResultAssoc = false">
                <i class="rb-close" />
              </span>
            </div>
            <div class="detail-body detail-assoc">
              <perfect-scrollbar>
                <div class="list-result-assicie contact-color">
                  <result-associed :results="resultAssociedData" />
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </transition>
      </div>
      <saving-search
        ref="historyModal"
        :is-new-save="isNewSavedHistory" />
      <download-search ref="downloadmodal" />
      <conflit-search ref="conflitmodal" />
    </div>
    <el-dialog
      class="ged-document-dialog"
      :center="true"
      :title="gedName"
      :visible.sync="isDocumentOpen"
      width="80%"
      top="3vh">
      <div class="document-dialog-container document-container-frame">
        <EmbedFile />
      </div>
    </el-dialog>
    <template
      v-if="allowImportFormGed"
      #after>
      <FormGED />
    </template>
  </main-wrap>
</template>
<script>
import SavedSearch from '@/components/modal/SavedSearch'
import SavingSearch from '@/components/modal/SavingSearch'
import AdvancedFilter from '@/components/search/AdvancedFilter'
import DownloadSearch from '@/components/modal/DownloadSearch'
import ResultGrid from '@/components/search/result/ResultGrid'
import ResultList from '@/components/search/result/ResultList'
import ResultAssocied from '@/components/search/result/ResultAssocied'
import { quoteService } from '@/services/quote.service'
import { settledFolderService } from '@/services/settledFolder.service'
import { legalFolderService } from '@/services/legalFolder.service'
import { contactService } from '@/services/contact.service'
import { gedService } from '@/services/ged.service'
import { listReferenceService } from '@/services/listReference.service'
import FilterForm from '@/components/form/FilterForm'
import MapSearch from '@/components/search/MapSearch'
import {
  searchGlobalItemsService,
  GlobalItemSearch
} from '@/services/searchGlobalItems.service'
import FolderResultDetails from '@/components/search/result/resultDetails/FolderResultDetails'
import ContactResultDetails from '@/components/search/result/resultDetails/ContactResultDetails'
import GedResultDetails from '@/components/search/result/resultDetails/GedResultDetails'
import RefResultDetails from '@/components/search/result/resultDetails/RefResultDetails'
import { baseUrl } from '@/services/api.client'
import { historyService } from '@/services/history.service'
import { tap } from 'rxjs/operators'
import FormGED from '@/components/ged/FormGED.vue'
import EmbedFile from '@/components/ged/EmbedFile'
import moment from 'moment'
import ConflitSearch from '@/components/modal/ConflitSearch'
import DefaultAvatar from '@/assets/img/default-avatar.png'

export default {
  name: 'Home',
  components: {
    ConflitSearch,
    MapSearch,
    FilterForm,
    ResultAssocied,
    DownloadSearch,
    ResultList,
    ResultGrid,
    SavingSearch,
    AdvancedFilter,
    SavedSearch,
    FolderResultDetails,
    ContactResultDetails,
    GedResultDetails,
    RefResultDetails,
    FormGED,
    EmbedFile
  },
  props: {
    isMySearch: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  subscriptions () {
    return {
      activeGedIndex$: gedService.onChangeActiveGedIndex,
      geds$: gedService.onChangeGeds,
      loading$: searchGlobalItemsService.onLoading,
      globalItems$: searchGlobalItemsService.onChangeGlobalItems.pipe(tap((res) => {
        if (res) {
          res.forEach(item => {
            item.isPin = false
          })
          this.globalItems = res.sort(
            (current, next) =>
              Number(new Date(next.sortDate)) - Number(new Date(current.sortDate))
          )
        }
      })),
      globalItemsTotal$: searchGlobalItemsService.onChangeGlobalItemsTotal.pipe(tap((res) => {
        if (res) {
          this.globalItemsTotal = res
        } else {
          this.globalItemsTotal = 0
        }
      })),
      currentHistory$: historyService.onChangeCurrentHistory.pipe(tap((res) => {
        if (res.result) {
          this.globalItems = res.result.result.sort(
            (current, next) =>
              Number(new Date(next.sortDate)) - Number(new Date(current.sortDate))
          )
          this.globalItemsTotal = res.result.total
          this.globalItemSearch = res.criteria
        }
      })),
      globalItemSearch$: searchGlobalItemsService.onChangeGlobalItemsFilters.pipe(tap((res) => {
        if (res) {
          this.globalItemSearch = res
          this.isAdvanced = res.isAdvanced
        }
      }))
    }
  },
  data () {
    return {
      gedName: '',
      isDocumentOpen: false,
      isViewlist: false,
      isActive: false,
      isSaveSearch: false,
      showResult: false,
      isAdvanced: false,
      isDetail: false,
      isResultAssoc: false,
      resultAssociedData: [],
      isHide: false,
      isNewSavedHistory: false,
      activeId: 'all',
      history: [],
      search: '',
      pinnedOnly: false,
      globalItems: [],
      globalItemSearch: [],
      sort: 'desc',
      resultsDetails: null,
      globalItemsTotal: 0,
      allowImportFormGed: false,
      isExporting: false
    }
  },
  computed: {
    menuList () {
      const menus = [
        { id: 'contact', title: 'Contact', number: 0, class: 'contact-color' },
        { id: 'folder', title: 'Dossier', number: 0, class: 'dossier-color' },
        { id: 'quote', title: 'Devis', number: 0, class: 'devis-color' },
        { id: 'ged', title: 'GED', number: 0, class: 'ged-color' },
        { id: 'reference', title: 'Réf.', number: 0, class: 'ref-color' },
        { id: 'fodegi', title: 'FODEGI', number: 0, class: 'fodegi-color' }
      ]
      return menus.map((item) => {
        if (item.id === 'folder') {
          return {
            ...item,
            number: this.globalItems.filter(
              (globalItem) => globalItem.objectType === 'legal-folder' || globalItem.objectType === 'settled-folder'
            ).length
          }
        }
        return {
          ...item,
          number: this.globalItems.filter(
            (globalItem) => globalItem.objectType === item.id
          ).length
        }
      })
    },
    filteredGlobalItems () {
      let list
      if (this.activeId === 'all') {
        list = this.globalItems
      }
      if (this.activeId === 'folder') {
        list = this.globalItems.filter(
          (globalItem) => globalItem.objectType === 'legal-folder' || globalItem.objectType === 'settled-folder'
        )
      }
      if (this.activeId === 'contact') {
        list = this.globalItems.filter(
          (globalItem) => globalItem.objectType === 'contact'
        )
      }
      if (this.activeId === 'reference') {
        list = this.globalItems.filter(
          (globalItem) => globalItem.objectType === 'reference'
        )
      }
      if (this.activeId === 'quote') {
        list = this.globalItems.filter(
          (globalItem) => globalItem.objectType === 'quote'
        )
      }
      if (this.activeId === 'ged') {
        list = this.globalItems.filter(
          (globalItem) => globalItem.objectType === 'ged'
        )
      }
      if (this.activeId === 'fodegi') {
        list = this.globalItems.filter(
          (globalItem) => globalItem.objectType === 'fodegi'
        )
      }
      if (this.pinnedOnly === true) {
        list = this.globalItems.filter(
          (globalItem) => globalItem.isPin === true
        )
      }
      return list.sort((a, b) => {
        if (a.isPin && !b.isPin) { return -1 }
        if (!a.isPin && b.isPin) { return 1 }
        return 0
      })
    },
    countPinnedResults () {
      if (this.globalItems) {
        return this.globalItems.filter((res) => res.isPin).length
      }
      return 0
    }
  },
  created () {
    listReferenceService.getListReference()
    this.clickoutsideAdvancedFilter()
    gedService.getAllGeds()
  },
  mounted () {
    this.isSaveSearch = this.isMySearch
  },
  methods: {
    getAvatarUrl (expert) {
      if (expert.user && expert.user.avatarFile) {
        return expert.user.avatarFile.url
      } else {
        return DefaultAvatar
      }
    },
    onLoadImageError (event) {
      event.target.src = DefaultAvatar
      event.onerror = null
    },
    selectMember (id) {
      this.activeId = id
    },
    activeSearch () {
      this.isActive = true
    },
    hideSearch () {
      this.isActive = false
    },
    /**
     * @param {HTMLElement} element
     */
    beforeEnter (element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = '0px'
        }
        element.style.display = null
      })
    },
    /**
     * @param {HTMLElement} element
     */
    enter (element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = `${element.scrollHeight}px`
        })
      })
    },
    /**
     * @param {HTMLElement} element
     */
    afterEnter (element) {
      element.style.height = null
    },
    /**
     * @param {HTMLElement} element
     */
    beforeLeave (element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = `${element.offsetHeight}px`
        }
      })
    },
    /**
     * @param {HTMLElement} element
     */
    leave (element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = '0px'
        })
      })
    },
    /**
     * @param {HTMLElement} element
     */
    afterLeave (element) {
      element.style.height = null
    },
    showResults () {
      this.showResult = !this.showResult
    },
    showScroll () {
      this.isHide = true
    },
    hideScroll () {
      this.isHide = false
    },
    openHistoryModal (isNewSave = false) {
      this.isNewSavedHistory = isNewSave
      this.$refs.historyModal.open()
    },
    openDownloadModal () {
      this.$refs.downloadmodal.open()
    },
    async exportGlobalSearchItems () {
      this.isExporting = true
      this.globalItemSearch.limit = this.globalItemsTotal
      const searchGlobalItemSearch = new GlobalItemSearch(this.globalItemSearch)
      searchGlobalItemSearch.offset = 0
      await searchGlobalItemsService.exportGlobalItems(searchGlobalItemSearch).then((res) => {
        if (res.data) {
          const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          const today = moment(new Date()).format('DD-MM-YYYY')
          const fileName = 'export-global-search-' + today + '.xlsx'
          link.href = downloadUrl
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
        }
        this.isExporting = false
      })
    },
    showAdvanced () {
      this.isAdvanced = !this.isAdvanced
      this.globalItemSearch$.isAdvanced = this.isAdvanced
    },
    hideAdvanced () {
      this.isAdvanced = false
      this.globalItemSearch$.isAdvanced = false
    },
    showSaveSearch () {
      this.isSaveSearch = !this.isSaveSearch
    },
    hideSaveSearch (id) {
      this.isSaveSearch = false
      if (id !== null) {
        historyService.getHistoryById(id)
      }
    },
    viewGrid () {
      this.isViewlist = false
    },
    viewList () {
      this.isViewlist = true
    },
    sortDesc () {
      this.globalItems = this.globalItems.sort(
        (current, next) =>
          Number(new Date(next.sortDate)) - Number(new Date(current.sortDate))
      )
      this.sort = 'desc'
    },
    sortAsc () {
      this.globalItems = this.globalItems.sort(
        (current, next) =>
          Number(new Date(current.sortDate)) - Number(new Date(next.sortDate))
      )
      this.sort = 'asc'
    },
    openDetail (data) {
      this.showResult = false
      if (data.result.objectType === 'settled-folder') {
        settledFolderService.getFolderById(data.result.folder.id).then(res => {
          data.result.reportExperts = []
          if (res.folder.files.length > 0) {
            data.result.files = res.folder.files
          }
          if (res.folder.report && res.folder.report.experts.length > 0) {
            data.result.reportExperts = res.folder.report.experts
          }
          if (res.folder.createdBy) {
            data.result.reportExperts.push({ user: res.folder.createdBy })
          }
          this.isDetail = data.detail
          this.resultsDetails = data.result
        })
      } else if (data.result.objectType === 'legal-folder') {
        legalFolderService.getFolderById(data.result.folder.id).then(res => {
          data.result.reportExperts = []
          if (res.folder.files.length > 0) {
            data.result.files = res.folder.files
          }
          if (res.folder.report && res.folder.report.experts.length > 0) {
            data.result.reportExperts = res.folder.report.experts
          }
          this.isDetail = data.detail
          this.resultsDetails = data.result
        }
        )
      } else if (data.result.objectType === 'quote') {
        quoteService.getQuoteById(data.result.folder.id).then(res => {
          data.result.reportExperts = []
          if (res.quote.files.length > 0) {
            data.result.files = res.quote.files
          }
          if (res.quote.createdBy) {
            data.result.reportExperts.push({ user: res.quote.createdBy })
          }
          this.isDetail = data.detail
          this.resultsDetails = data.result
        }
        )
      } else {
        this.isDetail = data.detail
        this.resultsDetails = data.result
      }
    },
    openResultAssoc (result) {
      this.isResultAssoc = result.showAssoc
      this.resultAssociedData = result.resultsAssocies
    },
    clickoutsideAdvancedFilter () {
      window.addEventListener('mousedown', (event) => {
        if (!event.target.closest('.filter-advanced,.filter')) {
          this.isActive = false
        }
      })
    },
    async searchGlobalItems (globalItemSearch) {
      this.isDetail = false
      this.isResultAssoc = false
      await searchGlobalItemsService.search(globalItemSearch).then(() => {
        this.sortDesc()
      })
    },
    changePinStatus (result) {
      const res = this.globalItems.find((res) => {
        return res.objectType === result.type &&
            (
              (res.objectType === 'ged' && res.ged.id === result.id) ||
              (res.objectType === 'reference' && res.reference.id === result.id) ||
              (res.objectType === 'contact' && res.contact.id === result.id) ||
              ((res.objectType === 'quote' || res.objectType === 'settled-folder' || res.objectType === 'legal-folder') && res.folder.id === result.id) ||
              (res.objectType === 'fodegi' && res.fodegi.id === result.id)
            )
      })
      this.$set(res, 'isPin', !res.isPin)
    },
    openFolderDetails (result) {
      if (result.objectType === 'quote') {
        window.open(this.$router.resolve({ path: '/devis/' + result.folder.id }).href, '_blank')
      } else if (result.objectType === 'settled-folder') {
        window.open(this.$router.resolve({ path: '/dossier-amiable/' + result.folder.id }).href, '_blank')
      } else if (result.objectType === 'legal-folder') {
        window.open(this.$router.resolve({ path: '/dossier-judiciaire/' + result.folder.id }).href, '_blank')
      } else if (result.objectType === 'ged') {
        gedService.getGedById(result.ged.id)
        this.allowImportFormGed = true
        gedService.showFormModal(true)
      } else if (result.objectType === 'reference') {
        window.open(this.$router.resolve({ path: '/edit-ref/' + result.reference.id }).href, '_blank')
      } else if (result.objectType === 'contact') {
        window.open(this.$router.resolve({ path: '/contact/' + result.contact.id }).href, '_blank')
      }
    },
    downloadResultFiles (result) {
      let folderType = null

      if (result.objectType === 'legal-folder') {
        folderType = 'legal'
      } else if (result.objectType === 'settled-folder') {
        folderType = 'settled'
      } else {
        folderType = 'quote'
      }

      const bills = result.files.filter(file => file.category && file.category.id === 3)
      if (bills.length > 0) {
        window.open(baseUrl + 'file/downloads/' + folderType + '/' + result.folder.id + '?type=Bill')
      }

      const missions = result.files.filter(file => file.category && file.category.id === 2)
      if (missions.length > 0) {
        window.open(baseUrl + 'file/downloads/' + folderType + '/' + result.folder.id + '?type=LM')
      }

      if (folderType !== 'quote') {
        const reports = result.files.filter(file => file.category && file.category.id === 4)
        if (reports.length > 0) {
          window.open(baseUrl + 'file/downloads/' + folderType + '/' + result.folder.id + '?type=' + (folderType === 'legal' ? 'LegalFolderReport' : 'SettledFolderReport'))
        }
      }
    },
    handleOpenConflictModal (data) {
      this.$refs.conflitmodal.open(data.conflicts, data.name, data.type, data.id)
    },
    handleFindInList (id) {
      if (this.isViewlist) {
        this.$refs.resultlist.findInList(id)
      } else {
        this.$refs.resultgrid.findInList(id)
      }
    },
    deleteResultFolder (result) {
      this.$confirm('Voulez-vous supprimer définitivement cet élément ?', '', {
        confirmButtonText: 'Confirmation',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(() => {
        switch (result.objectType) {
          case 'reference':
            listReferenceService.delete(result.reference.id).then(() => {
              this.globalItems = this.globalItems.filter(item => item.reference.id !== result.reference.id)
              this.isDetail = false
              this.$message({
                message: 'Référence supprimée',
                type: 'success',
                offset: 65
              })
            }, () => {
              this.$message({
                message: 'Erreur lors de la suppression de la référence',
                type: 'error',
                offset: 65
              })
            })
            break
          case 'ged':
            gedService.deleteGedById(result.ged.id).then(() => {
              this.globalItems = this.globalItems.filter(item => item.ged.id !== result.ged.id)
              this.isDetail = false
              this.$message({
                message: 'document/note supprimée',
                type: 'success',
                offset: 65
              })
            }, () => {
              this.$message({
                message: 'Erreur lors de la suppression du document/note',
                type: 'error',
                offset: 65
              })
            })
            break
          case 'contact':
            contactService.deleteContact(result.contact.id).then(() => {
              this.globalItems = this.globalItems.filter(item => item.contact.id !== result.contact.id)
              this.isDetail = false
              this.$message({
                message: 'Contact supprimée',
                type: 'success',
                offset: 65
              })
            }, () => {
              this.$message({
                message: 'Erreur lors de la suppression du contact',
                type: 'error',
                offset: 65
              })
            })
            break
          case 'legal-folder':
            legalFolderService.deleteLegalFolder(result.folder.id).then(() => {
              this.globalItems = this.globalItems.filter(item => item.folder.id !== result.folder.id)
              this.isDetail = false
              this.$message({
                message: 'Dossier supprimé',
                type: 'success',
                offset: 65
              })
            }, () => {
              this.$message({
                message: 'Erreur lors de suppression du dossier',
                type: 'error',
                offset: 65
              })
            })
            break
          case 'settled-folder':
            settledFolderService.deleteSettledFolder(result.folder.id).then(() => {
              this.globalItems = this.globalItems.filter(item => item.folder.id !== result.folder.id)
              this.isDetail = false
              this.$message({
                message: 'Dossier supprimé',
                type: 'success',
                offset: 65
              })
            }, () => {
              this.$message({
                message: 'Erreur lors de suppression du dossier',
                type: 'error',
                offset: 65
              })
            })
            break
          case 'quote':
            quoteService.deleteQuoteById(result.folder.id).then(() => {
              this.globalItems = this.globalItems.filter(item => item.folder.id !== result.folder.id)
              this.isDetail = false
              this.$message({
                message: 'Devis supprimé',
                type: 'success',
                offset: 65
              })
            }, () => {
              this.$message({
                message: 'Erreur lors de suppression du dossier',
                type: 'error',
                offset: 65
              })
            })
            break
        }
      }).catch(() => {
      })
    },
    async changeView (item) {
      await this.$refs.mapSearch.mapPosition(item.address, 0.0015)
      await this.$refs.mapSearch.openPopup(item.id)
    },
    showAdvancedAndOpenResult () {
      this.showResults()
      this.showAdvanced()
    },
    showDocumentModal (ged) {
      this.gedName = ged.name.toUpperCase()
      const indexOfGed = this.geds$.findIndex((item) => item.id === ged.id)
      gedService.activeGedIndex(indexOfGed)
      this.isDocumentOpen = !this.showDocument
    },
    getIsExporting () {
      return this.isExporting
    }
  }
}
</script>

<style>
.enter-active,
.leave-active {
  overflow: hidden;
  transition: height 0.5s linear;
}
.search-black {
  cursor: pointer;
}
.search-black {
  cursor: pointer;
}
.result-list .ps {
  height: calc(100vh - 311px);
  max-height: calc(100vh - 311px);
}
.dialog-content {
  height: 400px;
}

</style>
