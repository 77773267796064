<template>
  <el-dialog
    title=""
    :visible.sync="isModalVisible"
    :modal-append-to-body="true"
    append-to-body
    width="40%"
    class="modal-rb modal-rb-notitle">
    <div class="modal-rb-body">
      <perfect-scrollbar>
        <el-table
          :data="tableData"
          style="width: 100%"
          highlight-current-row
          class="conflictContainer"
          @current-change="handleCurrentChange">
          <el-table-column
            prop="nom"
            label="Nom"
            sortable />
          <el-table-column
            prop="adress"
            label="Adresse"
            sortable />
          <el-table-column
            prop="category"
            label="Type"
            sortable />
        </el-table>
      </perfect-scrollbar>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data () {
    return {
      isModalVisible: false,
      tableData: []
    }
  },
  methods: {
    open (items) {
      this.tableData = items
      this.isModalVisible = true
    },
    close () {
      this.isModalVisible = false
    },
    handleCurrentChange (val) {
      if (val) {
        this.isModalVisible = false
        this.$emit('show-miniature', { detail: val.id })
      }
    }
  }
}
</script>
