
import { Component, Prop, Vue } from 'vue-property-decorator'
import QuoteFilesRequested from './QuoteFilesRequested.vue'
import { quoteService, Quote } from '@/services/quote.service'
import { userService, User } from '@/services/user.service'
import { FRAIS_OPTIONS, DELIVER_DATE_OPTIONS } from '@/services/constants.service'
import { convertFrNumberEuro } from '@/utils/functions'
import FormattedFloat from '../utils/FormattedFloat.vue'
import CustomToggleDouble from '@/components/form/ToggleDouble.vue'
    // The @Component decorator indicates the class is a Vue component
    @Component({
      subscriptions (this: any) {
        return {
          quote$: quoteService.onChangeQuote,
          user$: userService.onChange
        }
      },
      components: {
        QuoteFilesRequested,
        FormattedFloat,
        CustomToggleDouble
      },
      methods: {
        convertFrNumberEuro
      }
    })

export default class QuoteFees extends Vue {
      @Prop({ default: false }) updateable: boolean
      filteredFraisOptions = FRAIS_OPTIONS
      filteredDateOptions = DELIVER_DATE_OPTIONS
      quote$: Quote = new Quote()
      user$: User = new User()
      currencyTTC: number = 1.2

      get sumFeeWithoutOption () {
        let value = 0
        if (this.quote$) {
          this.quote$.fees.forEach((item: any) => {
            value += parseFloat(item.withoutOption)
          })
        }
        return value
      }

      get sumFeeWithOption () {
        let value = 0
        if (this.quote$) {
          this.quote$.fees.forEach((item: any) => {
            value += parseFloat(item.withOption)
          })
        }
        return value
      }

      get totalSumFees () {
        return this.sumFeeWithoutOption + this.sumFeeWithOption
      }

      getSumFee (fee: any): number {
        return parseFloat(fee.withoutOption) + parseFloat(fee.withOption)
      }

      checkOption () {
        quoteService.updateQuote()
      }

      changeAdvanced (): void {
        this.quote$.advancedHT = (this.quote$.advancedHT || 0)
        this.quote$.advancedTTC = (this.quote$.advancedHT * this.currencyTTC)
        quoteService.updateQuote(false, true)
      }

      get remiseValue (): number {
        this.quote$.remiseValue = Math.round(this.quote$.remiseValue || 0)
        return this.quote$.remiseValue
      }

      changeRemisePercent (): void {
        quoteService.updateQuote()
      }

      changeRemiseValue (value: number): void {
        this.quote$.remiseValue = value || 0
        quoteService.updateQuote(true)
      }

      calculPercent (): void {
        if (!this.quote$.totalHT || !this.quote$.remiseValue) {
          return
        }
        let percent = this.quote$.remiseValue / this.quote$.totalHT
        percent = Math.round(percent * 10000) / 100
        this.quote$.remisePercent = percent
        this.changeRemiseValue(this.quote$.remiseValue)
        this.changeRemisePercent()
      }

      filterFraisOptions (query: string) {
        this.filteredFraisOptions = quoteService.filterOptions(query, 1, FRAIS_OPTIONS)
      }

      filterDateOptions (query: string) {
        this.filteredDateOptions = quoteService.filterOptions(query, 3, DELIVER_DATE_OPTIONS)
      }

      handleFocus (key: string): void {
        (this.quote$ as any)[key] = null
      }
}
