<template>
  <div class="auth-wrap d-flex align-items-center pb-5 justify-content-center text-dark">
    <div class="w-100 mb-5 pb-5">
      <el-row class="px-3 d-flex w-100 justify-content-center mb-2">
        <img
          src="@/assets/AVISIO-01.png"
          class="logo"
          alt="avisio-logo">
      </el-row>
      <el-row class="px-3 d-flex w-100 justify-content-center">
        <el-col :md="8">
          <el-alert
            v-if="hasError"
            class="mb-3"
            title="Login / Mot de passe incorrect"
            type="error"
            show-icon
            :closable="false" />
          <el-form
            ref="loginForm"
            :model="credentials"
            label-width=""
            label-position="left"
            :rules="formRules">
            <el-form-item
              label=""
              prop="login"
              class="mb-6">
              <el-input
                v-model="credentials.login"
                class="block"
                type="email"
                autocomplete="on"
                placeholder="Email"
                @keyup.enter.native="submitForm('loginForm')" />
            </el-form-item>

            <el-form-item
              label=""
              prop="password"
              class="mb-6">
              <el-input
                v-model="credentials.password"
                class="block"
                type="password"
                placeholder="Mot de passe"
                autocomplete="on"
                @keyup.enter.native="submitForm('loginForm')" />
            </el-form-item>
            <div class="my-4 text-center">
              <router-link
                class="d-block font-small"
                to="/forgot-password">
                Mot de passe oublié ?
              </router-link>
            </div>

            <div class="d-flex justify-content-end justify-content-center">
              <el-button
                :loading="loading"
                type="primary"
                class="el-button status-btn el-button--default is-round"
                @click="submitForm('loginForm')">
                Connexion
              </el-button>
            </div>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { authService } from '@/services/auth.service'

export default {
  name: 'Connexion',
  subscriptions () {
    return {
      user: authService.onChange,
      loading: authService.onLoading,
      twoFactor: authService.onTwoFactor
    }
  },
  data () {
    return {
      credentials: {
        login: '',
        password: ''
      },
      formRules: {
        login: [
          {
            required: true,
            message: 'L’identifiant doit être renseigné',
            trigger: 'blur'
          },
          {
            type: 'email',
            message: 'Veuillez entrer une adresse email valide',
            trigger: ['blur']
          }
        ],
        password: [
          {
            required: true,
            message: 'Le mot de passe doit être renseigné',
            trigger: 'blur'
          }
        ]
      },
      hasError: false
    }
  },
  watch: {
    $route () {
      this.redirect()
    }
  },
  created () {
    this.redirect()
  },
  methods: {
    submitForm (formName) {
      this.hasError = false
      this.$refs[formName].validate(valid => {
        if (valid) {
          const payload = this.credentials
          authService
            .login(payload.login, payload.password)
            .then(() => {
              this.redirect()
            })
            .catch(() => {
              this.loading = false
              this.hasError = true
            })
        }
        return valid
      })
    },
    redirect () {
      const redirect = this.$route.query.redirect
      if (this.twoFactor) {
        this.loading = false
        this.$router.push({ path: '/connexion/2fa' })
      } else {
        if (this.user) {
          if (typeof redirect !== 'undefined') {
            this.$router.push({ path: redirect })
          } else {
            this.$router.push({ path: '/global-search' })
          }
        }
      }
    }
  }
}
</script>
<style>
.logo {
  height: 100px;
}
</style>
