import Vue from 'vue'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/fr'
import App from './App.vue'
import router from './router'
import VueRx from 'vue-rx'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import VueTheMask from 'vue-the-mask'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import './components'
import './theme/index.scss'
import './utils/filters'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import VueGtag from 'vue-gtag'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

Vue.use(VueRx)
Vue.use(ElementUI, { locale })
Vue.use(CKEditor)
Vue.use(VueTheMask)
Vue.use(PerfectScrollbar)
Vue.use(VueVirtualScroller)

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: {
    id: 'G-HWME5VT4KG',
    appName: 'Avisio',
    pageTrackerScreenviewEnabled: true,
    pageTrackerTemplate (to: any) {
      return {
        page_title: to.page_title,
        page_path: to.path
      }
    }
  }
}, router)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
