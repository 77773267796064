
import { Component, Vue } from 'vue-property-decorator'

@Component({
  props: {
    label: String,
    value: String,
    unit: String
  }
})
export default class PreviewDetail extends Vue {

}
