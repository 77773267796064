<template>
  <draggable
    ref="drag"
    v-model="files"
    v-bind="$attrs"
    @update="onUpdate"
    @add="onAdd"
    @remove="onRemove"
    @start="onDragStart"
    @end="onDragEnd">
    <transition-group
      type="transition"
      :name="'flip-list'">
      <template v-for="file in files">
        <slot
          name="item"
          :item="file"
          :selected="selected[file.id]"
          :select="toggleSelect"
          :drag="dragItem" />
      </template>
    </transition-group>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      selected: {},
      isDragging: false,
      lastSelectedId: null
    }
  },
  computed: {
    files: {
      get () {
        return this.value
      },
      set (files) {
        this.$emit('input', files)
      }
    }
  },
  methods: {
    dragItem ({ id }) {
      this.$set(this.selected, id, true)
    },
    onDragStart (e) {
      this.isDragging = true
      e.item._selected_items = this.files.filter((f) => this.selected[f.id])
    },
    onDragEnd () {
      this.isDragging = false
      this.selected = {}
      this.$emit('change-status', false)
    },
    onUpdate (e) {
      if (e.item._selected_items.length) {
        const ids = e.item._selected_items.map(({ id }) => id)
        const files = this.files.filter((f) => !ids.includes(f.id))
        files.splice(e.newIndex, 0, ...e.item._selected_items)
        this.files = files
      }
    },
    onRemove (e) {
      if (e.item._selected_items.length) {
        const ids = e.item._selected_items.map(({ id }) => id)
        this.files = this.files.filter((f) => !ids.includes(f.id))
      }
    },
    onAdd (e) {
      if (e.item._selected_items.length) {
        this.files.splice(e.newIndex, 1, ...e.item._selected_items)
      }
    },
    toggleSelect ({ id }) {
      if (event.shiftKey && this.lastSelectedId) {
        if (this.findShiftOrder(this.lastSelectedId, id) === 1) {
          this.selectShiftedElt(this.lastSelectedId, id, id)
        } else {
          this.selectShiftedElt(id, this.lastSelectedId, id)
          this.$set(this.selected, id, !this.selected[id])
        }
      } else {
        this.$set(this.selected, id, !this.selected[id])
      }

      this.lastSelectedId = id
      /* CHANGE SelectAll checkbox value */
      /* if all elements are true, checkbox have to be true and if element aren't all true, checkbox have to be false */
      if (Object.keys(this.selected).length === Object.keys(this.value).length) { // Check if this.selected as set all items
        const content = []
        const selected = []

        this.value.forEach((item) => {
          content.push(item.id)
        })
        Object.keys(this.selected).forEach((key) => {
          selected.push(parseInt(key))
        })
        const checker = (arr, target) => target.every(v => arr.includes(v))
        if (checker(content, selected)) { // check if id of files are the same as selected key
          const validation = []
          this.selected.forEach((item) => {
            validation.push(item)
          })
          const checkerTrue = arr => arr.every(v => v === true)
          if (checkerTrue(validation)) { // check if all files are selected (selected elements all true)
            this.$emit('change-status', true)
            return
          }
        }
      }
      this.$emit('change-status', false)
    },
    toggleSelectAll (status) {
      this.value.forEach((item) => {
        this.$set(this.selected, item.id, status)
      })
    },
    /* Find which id is first in list */
    findShiftOrder (firstId, secondId) {
      let checker = 0
      this.value.every((item) => {
        if (item.id === firstId && checker === 0) {
          checker = 1
          return false
        }
        if (item.id === secondId && checker === 0) {
          checker = 2
          return false
        }
        return true
      })
      return checker
    },
    /* Select all element between firstId and secondId */
    selectShiftedElt (firstId, secondId, id) {
      let startingElt = false
      this.value.forEach((item) => {
        if (startingElt === true) {
          this.$set(this.selected, item.id, !this.selected[id])
        }
        if (item.id === firstId) {
          startingElt = true
        }
        if (item.id === secondId) {
          startingElt = false
        }
      })
    }
  }
}
</script>
