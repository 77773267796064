
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormattedFloat from '../../utils/FormattedFloat.vue'
import FormattedNumber from '../../utils/FormattedNumber.vue'
import { baseBlockItems } from '../blockItems'
import { surfaceCapaciteItemList } from '../itemList'
import { floatRule } from '../formRules'
import { referenceService } from '@/services/listReference.service'
import { tap } from 'rxjs/operators'

@Component({
  subscriptions (this: any) {
    return {
      value: referenceService.onChangeReference.pipe(tap(() => {
        this.surfaceCapaciteItemList = [...this.surfaceCapaciteItemList]
      })),
      isMigratedRef: referenceService.onChangeIsMigratedRef
    }
  },
  components: {
    FormattedFloat,
    FormattedNumber
  }
})
export default class SurfaceCapacite extends Vue {
  @Prop({ type: Object })
    optionList: any

  @Prop({ type: String })
    localLabel!: any

  isMigratedRef = false
  value: any = {
    surfacePonderee: 0
  }

  floatRule = floatRule
  surfaceCapaciteItemList = surfaceCapaciteItemList

  checkDisplayCondition (title: string) {
    if (this.isMigratedRef) {
      return true
    }
    const localLabel = this.localLabel
    return (
      baseBlockItems[localLabel] && !baseBlockItems[localLabel].includes(title)
    )
  }

  updateValues (item: any) {
    referenceService.updateRef(this.value, item.value)
  }

  changeSelect (item: any) {
    if (this.value[item.value] === 0) {
      this.value[item.value] = ''
    }
  }
}
