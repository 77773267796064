
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { tap } from 'rxjs/operators'
import { fileService, AppFile, CategoryTree } from '@/services/file.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import { quoteService } from '@/services/quote.service'
import { baseUrl } from '@/services/api.client'
import FormCategory from './FormCategory.vue'
import Draggable from '@/components/utils/Draggable.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import FilePreviewer from '@/components/utils/FilePreviewer.vue'

@Component({
  name: 'FileCategory',
  subscriptions (this: any) {
    return {
      user$: userService.onChange,
      files$: fileService.onChange.pipe(
        tap((res: AppFile[]) => {
          const files: AppFile[] = fileService.getFilesByCategory(this.type, this.parentId)
          this.categories.forEach(({ type, id }: CategoryTree) => {
            const subFiles: AppFile[] = fileService.getFilesByCategory(type, id)
            files.push(...subFiles)
          })
          this.downloadable = files.filter((item: AppFile) => !item.disabled).length > 0

          return res
        })
      ),
      loadingQuote$: quoteService.onLoading
    }
  },
  components: {
    FormCategory,
    Draggable,
    ClipLoader,
    FilePreviewer
  }
})
export default class FileCategory extends Vue {
  @Prop({}) title: string
  @Prop({}) type: string
  @Prop({ default: 0 }) folderId: number
  @Prop({}) folderType: string
  @Prop({ default: false }) isMain: boolean
  @Prop({}) categories: CategoryTree[]
  @Prop({}) parentId: number
  @Prop({ default: true }) updatable: boolean
  @Prop({ default: false }) disabled: boolean

  user$: User = new User()
  files$: AppFile[] = []
  hidden: boolean = true
  showPreviewerDialog: boolean = false
  loadingQuote$: boolean = false
  selectedFile: any = null
  baseUrl: string | undefined = baseUrl
  localFiles: AppFile[] = []
  downloadable: boolean = false
  checked: boolean = false
  status: boolean = false
  countFiles: number = 0

  dragOptions: any = {
    animation: 0,
    group: 'description',
    ghostClass: 'ghost',
    disabled: false
  }

  isDragging: boolean = false
  delayedDragging: boolean = false
  oldFileOrders: any[] = []

  get files (): AppFile[] {
    return this.getFiles(this.type, this.parentId)
  }

  get updateable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.FILE_SECTION_UPDATE)
  }

  get changeable (): boolean {
    this.dragOptions.disabled = !this.updateable
    return this.updateable && !this.disabled
  }

  @Watch('files')
  onFilesChanged (val: any[]) {
    if (val) {
      this.localFiles = val
    }
  }

  @Watch('localFiles')
  onLocalFileChanged (val: any[], oldVal: any[]) {
    if (val === this.files || oldVal === this.files) return
    const newOrders: any[] = []
    this.oldFileOrders = this.files.map((item) => item.order)
    val.forEach((item, index) => {
      newOrders.push({ id: item.id, order: this.oldFileOrders[index], type: this.type, categoryId: this.parentId })
    })
    fileService.changeOrder(newOrders, this.type)
  }

  @Watch('isDragging')
  onIsDraggingChanged (val: boolean) {
    if (val) {
      this.delayedDragging = true
      return
    }
    this.$nextTick(() => {
      this.delayedDragging = false
    })
  }

  /*
    triggered when drag is start
  */
  onDragStart (): void {
    this.isDragging = true
  }

  /*
    triggered when darg is end
  */
  onDragEnd (): void {
    this.isDragging = false
  }

  /*
    triggered when draggable element is moved
  */
  onMove (event: any): boolean {
    const relatedElement = event.relatedContext.element
    return (!relatedElement || !relatedElement.fixed)
  }

  downloadFile (id: number): void {
    window.open(this.baseUrl + 'file/download/' + id)
  }

  deleteFile (id: number): void {
    this.$confirm('êtes-vous sûr de vouloir supprimer cette pièce jointe?', '', {
      confirmButtonText: 'Confirmation',
      cancelButtonText: 'Annuler',
      type: 'warning'
    }).then(() => {
      fileService.deleteFile(id, this.type).then(() => {
        const index = this.localFiles.findIndex((item: AppFile) => item.id === id)
        this.localFiles.splice(index, 1)
      })
    })
  }

  previewFile (file: any): void {
    this.selectedFile = file
    this.showPreviewerDialog = true
  }

  downloadAll (): void {
    window.open(this.baseUrl + 'file/downloads/' + this.folderType + '/' + this.folderId + '?type=' + this.type)
  }

  getFiles (type: string, categoryId: number): AppFile[] {
    const files = this.files$.filter(
      (file: AppFile) =>
        file.category && file.category.name === type && file.category.id === categoryId
    )

    this.countFiles = 0

    this.files$.forEach((file: AppFile) => {
      if (!file.category) {
        return
      }

      let category = file.category

      while (category) {
        if (category.name === type && category.id === categoryId) {
          this.countFiles++
          return
        }

        category = category.parent ?? null
      }
    })

    return files
  }

  onAddSubCategory (): void {
    fileService.initCategory();
    (this.$refs.formCategory as any).open()
  }

  onEditCategory (): void {
    fileService.getCategoryById(this.parentId);
    (this.$refs.formCategory as any).open()
  }

  onDeleteCategory (): void {
    this.$confirm('êtes-vous sûr de vouloir supprimer cette sous-catégorie ? Attention toutes les pièces jointes présentes dans cette sous-catégorie vont être supprimés', '', {
      confirmButtonText: 'Confirmation',
      cancelButtonText: 'Annuler',
      type: 'warning'
    }).then(() => {
      fileService.deleteCategoryById(this.parentId)
    })
  }

  createCategory (data: any): void {
    const category = Object.assign({}, {
      ...data,
      user: this.user$.id,
      parent: this.parentId
    })
    fileService.saveCategory(category).then(() => {
      this.$message({
        message: 'La sous-catégorie a été crée',
        type: 'success',
        offset: 65
      });
      (this.$refs.formCategory as any).close()
    })
  }

  handleHide (): void {
    this.hidden = !this.hidden
    this.$emit('hide', this.hidden)
  }

  changeChecked (): void {
    this.checked = !this.checked;
    (this.$refs.draggable as any).toggleSelectAll(this.checked)
  }

  changeStatus (bool: boolean): void {
    this.status = bool
    this.checked = this.status
  }
}
