<template>
  <div class="custom-form searchbar d-flex">
    <img
      src="@/assets/img/search-ged.svg"
      alt>
    <el-input
      class="form-control"
      :placeholder="placeholder"
      type="text"
      :value="val"
      @input="handleInput"
      @change="handleChange"
      @clear="handleChange"
      @key.enter.prevent="handleChange" />
  </div>
</template>
<script>
export default {
  name: 'SearchBar',
  props: {
    value: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      val: this.value
    }
  },
  watch: {
    value (value) {
      this.val = value
    }
  },
  methods: {
    handleInput (val) {
      this.val = val
      this.$emit('input', val)
    },
    handleChange (val) {
      this.$emit('change', val)
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-input ::v-deep input{
    border: none;
    border-radius: 0;
    height: 32px;
  }
</style>
