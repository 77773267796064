
import { Component, Vue } from 'vue-property-decorator'
import { tap } from 'rxjs/operators'
import { DossierFilter, dossierService } from '@/services/dossier.service'
import { PageParams } from '@/models/ged.model'
import { LEGAL_FOLDER_STATUES, SETTED_FOLDER_STATUES } from '@/services/constants.service'

@Component({
  name: 'DossierTable',
  subscriptions (this: any) {
    return {
      filter$: dossierService.onChangeFilter,
      dossiers$: dossierService.onChangeDossiers,
      activeDossierIndex$: dossierService.onChangeActiveDossierIndex,
      totalDossiers$: dossierService.onChangeTotalDossiers,
      pageParams$: dossierService.onChangePageParams,
      loading$: dossierService.onLoading,
      dossier$: dossierService.onChangeDossier.pipe(
        tap((res: any) => {
          if (this.$refs.dossierTable && res) {
            this.$refs.dossierTable.setCurrentRow(res)
          }
        })
      )
    }
  },
  components: {}
})

export default class DossierTable extends Vue {
  filter$ = new DossierFilter()
  dossiers$: any[] = []
  totalDossiers$: number = 0
  pageParams$: PageParams
  loading$: PageParams
  isFixesIndex: boolean = false
  selectedIndex: any = null
  activeDossier: any = {}
  statusesLegalFolder: any = {
    0: 'folder',
    1: 'group',
    2: 'checklist',
    4: 'report',
    5: 'document',
    6: 'mail',
    8: 'report',
    9: 'document',
    10: 'balance',
    11: 'euro',
    12: 'checked',
    13: 'checked'
  }

  statusesSettledFolder: any = {
    0: 'folder',
    1: 'group',
    2: 'checklist',
    4: 'report',
    5: 'document',
    6: 'mail',
    7: 'calculator',
    8: 'euro',
    9: 'checked',
    10: 'checked'
  }

  statusesQuote: any = {
    0: 'folder',
    1: 'group',
    2: 'checklist',
    3: 'report',
    4: 'document',
    5: 'mail',
    6: 'report',
    7: 'document',
    8: 'checked',
    9: 'checked',
    10: 'checked'
  }

  statusByColor (row: any) {
    if (row.type === 'settled-folder') {
      return row.status < (SETTED_FOLDER_STATUES[9].value) ? 'text-in-progress' : 'text-close'
    }
    if (row.type === 'legal-folder') {
      return row.status < (LEGAL_FOLDER_STATUES[12].value) ? 'text-in-progress' : 'text-close'
    }
    return row.status < 8 ? 'text-in-progress' : 'text-close'
  }

  handleSortChange (prop: string) {
    this.filter$.sortOrder = prop === this.filter$.sortProp && this.filter$.sortOrder === 'ascending' ? 'descending' : 'ascending'
    this.filter$.sortProp = prop

    dossierService.searchDossiers()
  }

  clickOnRow (dossier: any) {
    if (dossier.type === 'quote') {
      this.$router.push({ path: '/devis/' + dossier.id })
    } else if (dossier.type === 'settled-folder') {
      this.$router.push({ path: '/dossier-amiable/' + dossier.id })
    } else if (dossier.type === 'legal-folder') {
      this.$router.push({ path: '/dossier-judiciaire/' + dossier.id })
    }
  }

  editRow (dossier: any) {
    if (dossier.type === 'quote') {
      window.open(this.$router.resolve({ path: '/devis/' + dossier.id }).href, '_blank')
    } else if (dossier.type === 'settled-folder') {
      window.open(this.$router.resolve({ path: '/dossier-amiable/' + dossier.id }).href, '_blank')
    } else if (dossier.type === 'legal-folder') {
      window.open(this.$router.resolve({ path: '/dossier-judiciaire/' + dossier.id }).href, '_blank')
    }
  }

  onChangeCurrent (currentPage: number) {
    this.pageParams$.currentPage = currentPage
    dossierService.searchDossiers()
  }

  onChangeSizePage (sizePage: number) {
    localStorage.setItem('pageSize', `${sizePage}`)
    this.pageParams$.pageSize = sizePage
    dossierService.searchDossiers()
  }

  preview (dossier: any, index = null) {
    if (this.selectedIndex === index) {
      this.isFixesIndex = !this.isFixesIndex
      this.selectedIndex = null
      dossierService.activeDossierIndex(null)
    } else {
      this.isFixesIndex = true
      this.selectedIndex = index
      dossierService.activeDossierIndex(index)
      dossierService.setDossier(dossier)
    }
  }

  changeIndex (dossier: any, index = null) {
    if (!this.isFixesIndex) {
      this.activeDossier = dossier
      dossierService.setDossier(this.activeDossier)
      dossierService.activeDossierIndex(index)
    }
  }

  getaddress (row: any) {
    if (row.addresses && row.addresses.length > 0) {
      let message = ''
      for (let i = 0; i < row.addresses.length; i++) {
        if (row.addresses[i].street !== '' || row.addresses[i].city !== '') {
          message = (message === '' ? message : (message + ', ')) + `${row.addresses[i].buildingNumber} ${row.addresses[i].street}, ${row.addresses[i].zip} ${row.addresses[i].city.toUpperCase()}`
        }
      }
      return message
    }
    return row.city
  }

  getMandatorClient (row: any) {
    if (row.type === 'settled-folder' && row.quote.contacts && row.quote.contacts.length > 0) {
      let message = ''
      for (let i = 0; i < row.quote.contacts.length; i++) {
        if (!row.quote.contacts[i].mandator) {
          continue
        }
        if (row.quote.contacts[i].contact) {
          message = (message === '' ? message : (message + ', ')) + row.quote.contacts[i].contact.nom + (row.quote.contacts[i].contact.prenom ? ` ${row.quote.contacts[i].contact.prenom}` : '')
        }
      }
      return message
    }

    if ((row.type === 'quote' || row.type === 'legal-folder') && row.contacts && row.contacts.length > 0) {
      let message = ''
      for (let i = 0; i < row.contacts.length; i++) {
        if (!row.contacts[i].mandator) {
          continue
        }
        if (row.contacts[i].contact) {
          message = (message === '' ? message : (message + ', ')) + row.contacts[i].contact.nom + (row.contacts[i].contact.prenom ? ` ${row.contacts[i].contact.prenom}` : '')
        }
      }
      return message
    }
    return ''
  }
}
