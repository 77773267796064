
import { Component, Prop, Vue } from 'vue-property-decorator'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import Font from '@ckeditor/ckeditor5-font/src/font'
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline'

    @Component({})
export default class RichEditor extends Vue {
        @Prop({ default: '' }) value: string
        @Prop({ default: false }) disabled: boolean

        editor: any = ClassicEditor
        editorConfig: any = {
          plugins: [
            EssentialsPlugin,
            BoldPlugin,
            ItalicPlugin,
            LinkPlugin,
            ParagraphPlugin,
            Font,
            UnderlinePlugin
          ],

          toolbar: {
            items: [
              'bold',
              'italic',
              'underline',
              'link',
              'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor',
              'undo',
              'redo'
            ]
          }
        }

        handleInput (val: string): void {
          this.$emit('input', val)
        }

        handleBlur (eventInfo: any, data: any): void {
          this.$emit('blur', eventInfo, data)
        }

        handleFocus (eventInfo: any, data: any): void {
          this.$emit('focus', eventInfo, data)
        }
}
