
import { Component, Vue } from 'vue-property-decorator'
import { dashboardService } from '../../services/dashboard.service'
import { listReferenceService, ListReference } from '../../services/listReference.service'
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions () {
    return {
      references$: dashboardService.onChangeReferences,
      listReferences$: listReferenceService.onChangeListReferences
    }
  },
  methods: {}
})

export default class DashboardReferences extends Vue {
  listReferences$: ListReference[] = []

  created () {
    listReferenceService.getListReference()
    dashboardService.getLastReferences()
  }

  getLocalTypeLabel (value: number) {
    const find = this.listReferences$.find((item: any) => item.type === 1 && item.num === value)
    if (find) {
      return find.label
    }
    return ''
  }

  getTransactionTypeLabel (value: number) {
    const find = this.listReferences$.find((item: any) => (item.type === 4 || item.type === 7) && item.num === value)
    if (find) {
      return find.label
    }
    return ''
  }

  clickOnRow () {
    this.$router.push({
      name: 'Ged'
    })
  }
}
