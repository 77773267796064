<template>
  <div
    class="custom-dropdown"
    :class="{'active':active}"
    @mouseout="active=false">
    <a
      :style="content.title.label === 'Administration' ? 'pointer-events: none' : ''"
      :href="content.title.path"
      class="dd-button"
      @mouseenter="active=true">
      <i
        class="fa"
        :class="`fa-${content.title.icon}`" />
      <span>{{ content.title.label }}</span>
    </a>
    <ul
      v-if="content.list && content.list.length"
      class="dd-menu">
      <li
        v-for="(item, index) in content.list"
        :key="index"
        class="dd-menu-item">
        <a
          v-if="item.path && hasPermission(item.label) && item.refreshUrl"
          :href="item.path"
          class="dd-menu-item-link">
          <i
            class="fa"
            :class="`fa-${item.icon}`" />
          <span>{{ item.label }}</span>
        </a>
        <a
          v-else-if="item.label === 'Import Bodacc'"
          class="dd-menu-item-link"
          @click="handleImportBodacc">
          <i
            class="fa"
            :class="`fa-${item.icon}`" />
          <span>{{ item.label }}</span>
        </a>
        <a
          v-else-if="item.label === 'Importer des références'"
          class="dd-menu-item-link"
          @click="handleImportRef">
          <i
            class="fa"
            :class="`fa-${item.icon}`" />
          <span>{{ item.label }}</span>
        </a>
        <router-link
          v-else-if="item.path && hasPermission(item.label)"
          :to="item.path"
          class="dd-menu-item-link">
          <i
            class="fa"
            :class="`fa-${item.icon}`" />
          <span>{{ item.label }}</span>
        </router-link>
        <span
          v-else
          class="dd-menu-item-link static-link">
          <i
            class="fa"
            :class="`fa-${item.icon}`" />
          <span>{{ item.label }}</span>
        </span>
      </li>
      <slot />
    </ul>
    <import-bodacc ref="importBodacc" />
    <import-ref ref="importRef" />
  </div>
</template>
<script>
import { authService } from '../../services/auth.service'
import { userService, USER_RIGHTS } from '../../services/user.service'
import ImportRef from '../../views/ImportRef'
import ImportBodacc from '../../views/ImportBodacc'

export default {
  name: 'DropdownMenu',
  components: {
    ImportRef,
    ImportBodacc
  },
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {
        return {
          title: {
            required: true,
            type: Object

            //    { label: '', icon:''}
          },
          list: {
            type: Array

            // [ { path: '', label:'', icon:'' } ]
          }
        }
      }
    }
  },
  data () {
    return {
      active: false
    }
  },
  methods: {
    sprite (svg) {
      return require(`@/assets/dd-menu-sprite.svg#${svg}`)
      //   returm
    },
    hasPermission (label) {
      const user = authService.userValue
      switch (label) {
        case 'Contacts':
          return userService.hasRight(user, USER_RIGHTS.CLIENT_RIGHTS)
        case 'Utilisateurs':
          return userService.hasRight(user, USER_RIGHTS.USER_RIGHTS)
        case 'Roles':
          return userService.hasRight(user, USER_RIGHTS.ROLE_RIGHTS)
        case 'Nouveau devis':
          return userService.hasRight(user, USER_RIGHTS.FOLDER_UPDATE)
        case 'Mes devis':
          return userService.hasRight(user, USER_RIGHTS.FOLDER_UPDATE)
        case 'Rôles':
          return userService.hasRight(user, USER_RIGHTS.ROLE_RIGHTS)
      }
      return true
    },
    handleImportBodacc () {
      this.$refs.importBodacc.open()
    },
    handleImportRef () {
      this.$refs.importRef.open()
    }
  }
}
</script>
