
import { Component, Vue } from 'vue-property-decorator'
import {
  listReferenceService
} from '@/services/listReference.service'
import { baseUrl } from '@/services/api.client'
import {
  filterGroupList,
  generateTypeLocalGroup
} from '@/utils/functions'
import { requireRule } from '@/components/addReference/formRules'
import {
  generalInfoItemList,
  surfaceCapaciteItemList,
  loyersPrixItemList,
  performanceItemList,
  informationsBailItemList,
  jugementItemList,
  informationsVenteItemList
} from '@/components/addReference/itemList'
import {
  baseBlockItems,
  locativeBlockItems,
  baseBlockNouvelleLocation,
  valeurBlockItems
} from '@/components/addReference/blockItems'

@Component({})
export default class ImportRef extends Vue {
  loading: boolean = false
  visible: boolean = false
  importedSuccess: boolean = false
  importedRefIds: number[] = []
  requireRule = requireRule
  baseUrl: string | undefined = baseUrl
  typeLocalItemsList: any[] = []
  typeTransactionLocative: any[] = []
  typeTransactionVenale: any[] = []
  typeLocalItems: any[] = []
  typeTransaction: any[] = []
  filteredTypeLocalItems: any[] = []
  filteredTypeTransaction: any[] = []
  typeValeur: number = 0
  form: any = {
    typeLocal: null,
    typeTransaction: null
  }

  get disabled (): boolean {
    return !(this.form.typeLocal && this.form.typeTransaction)
  }

  get params (): any {
    return Object.assign({}, {
      ...this.form,
      typeValeur: this.typeValeur
    })
  }

  open (): void {
    this.loading = false
    this.visible = true
    this.getListReferences()
    this.resetForm()
  }

  close (): void {
    this.loading = false
    this.visible = false
  }

  handleClose (done: any): void {
    this.loading = false
    if (this.importedSuccess) {
      this.$confirm("Êtes-vous sûr de vouloir fermer la fenêtre d'import ? Si vous venez d'effectuer un import sans le valider celui-ci sera annulé.")
        .then(() => {
          this.form = {
            typeLocal: null,
            typeTransaction: null
          }
          listReferenceService.cancelImports({ referenceIds: this.importedRefIds })
          this.close()
          done()
        })
        .catch(() => {})
    } else {
      done()
    }
  }

  handleRemoveFile () {
    return new Promise((resolve, reject) => {
      this.$confirm("Êtes-vous sûr de vouloir retirer ce fichier d'import ? Toutes les références importées avec ce fichier seront supprimées.")
        .then(() => {
          this.loading = true
          listReferenceService.cancelImports({ referenceIds: this.importedRefIds }).then(() => {
            this.importedSuccess = false
            this.importedRefIds = []
            this.loading = false
            resolve('success')
          })
        })
        .catch(() => {
          this.loading = false
          reject(new Error())
        })
    })
  }

  resetForm (): void {
    this.loading = false
    this.importedSuccess = false
    this.importedRefIds = []
    this.$nextTick(() => {
      (this.$refs.tempForm as any).resetFields()
    })
  }

  getListReferences () {
    listReferenceService.getListReference().then(res => {
      this.typeLocalItemsList = res.filter((item: any) => item.type === 1)
      this.typeTransactionLocative = res.filter((item: any) => item.type === 4)
      this.typeTransactionVenale = res.filter((item: any) => item.type === 7)

      this.typeLocalItems = generateTypeLocalGroup(this.typeLocalItemsList)
      this.typeTransaction = [
        {
          label: 'VALEUR LOCATIVE',
          options: this.typeTransactionLocative
        },
        {
          label: 'VALEUR VENALE',
          options: this.typeTransactionVenale
        }
      ]
    })
  }

  getTempColumns (): string[] {
    const localLabel = this.getLocalLabel(this.form.typeLocal)
    const transactionLabel = this.getTransactionLabel(this.form.typeTransaction)
    const columns: string[] = [
      'Type de local',
      'Type de transaction',
      'Numéro',
      'Rue',
      'Code postal',
      'Pays',
      'Ville',
      'Region',
      'Quartier'
    ]
    const generalColumns: string[] = generalInfoItemList.filter((item: any) =>
      baseBlockItems[localLabel] && !baseBlockItems[localLabel].includes(item.label)
    ).map((item: any) => item.label)
    const surfaceColumns: string[] = surfaceCapaciteItemList.filter((item: any) =>
      baseBlockItems[localLabel] && !baseBlockItems[localLabel].includes(item.label)
    ).map((item: any) => item.label)
    const loyersPrixColumns: string[] = loyersPrixItemList.filter((item: any) => {
      let result = false
      let showSpecific = true
      let checkTransaction = true
      switch (item.condition) {
        case 'base':
          result = baseBlockItems[localLabel] && !baseBlockItems[localLabel].includes(item.label)
          break
        case 'locative':
          showSpecific = baseBlockNouvelleLocation.includes(item.label) && this.form.typeTransaction === 1
          result = this.typeValeur === 1 && locativeBlockItems[localLabel] && !locativeBlockItems[localLabel].includes(item.label) && showSpecific
          break
        case 'valeur':
          checkTransaction = item.transaction ? transactionLabel === item.transaction : true
          if (item.label !== 'Prix € HD/ chambre' && item.label !== 'Prix € HD / lit' && item.label !== 'Prix €HD / fauteuil' && item.label !== 'Prix € HD/ Place') {
            showSpecific = true
          } else {
            transactionLabel === 'Droit au bail' ? showSpecific = false : showSpecific = true
          }
          result = this.typeValeur === 2 && showSpecific && checkTransaction && valeurBlockItems[localLabel] && !valeurBlockItems[localLabel].includes(item.label)
          break
        default:
          break
      }
      return result
    }).map((item: any) => item.label)
    const performanceColumns: string[] = performanceItemList.filter((item: any) => {
      let result = false
      switch (item.condition) {
        case 'base':
          result = baseBlockItems[localLabel] && !baseBlockItems[localLabel].includes(item.label)
          break
        case 'locative':
          result = this.typeValeur === 1 && locativeBlockItems[localLabel] && !locativeBlockItems[localLabel].includes(item.label)
          break
        default:
          break
      }
      return result
    }).map((item: any) => item.label)
    const informationsBailColumns: string[] = []
    const informationsBailFields: any[] = [{ label: 'Date d\'effet du bail' }, ...informationsBailItemList].filter((item: any) => {
      if (item.label === 'Destination') {
        return true
      }
      return (
        this.typeValeur === 1 && locativeBlockItems[localLabel] && !locativeBlockItems[localLabel].includes(item.label)
      )
    })
    informationsBailFields.forEach((item: any) => {
      if (item.label === 'Date d\'effet du bail') {
        informationsBailColumns.push('Date d\'effet du bail début', 'Date d\'effet du bail fin')
      } else {
        informationsBailColumns.push(item.label)
      }
    })
    const jugementColumns: string[] = jugementItemList.filter((item: any) => {
      return (
        this.typeValeur === 1 &&
          (this.form.typeTransaction === 3 || this.form.typeTransaction === 4) &&
          locativeBlockItems[localLabel] &&
          !locativeBlockItems[localLabel].includes(item.label)
      )
    }).map((item: any) => item.label)
    const informationsVenteColumns: string[] = []
    const informationsVenteFields: any[] = [{ label: 'Date de vente' }, ...informationsVenteItemList].filter((item: any) => {
      return (
        this.typeValeur === 2 && valeurBlockItems[localLabel] && !valeurBlockItems[localLabel].includes(item.label)
      )
    })
    informationsVenteFields.forEach((item: any) => {
      if (item.label === 'Date de vente') {
        informationsBailColumns.push('Date de vente début', 'Date de vente fin')
      } else {
        informationsBailColumns.push(item.label)
      }
    })

    return [
      ...columns,
      ...generalColumns,
      ...surfaceColumns,
      ...loyersPrixColumns,
      ...performanceColumns,
      ...informationsBailColumns,
      ...jugementColumns,
      ...informationsVenteColumns,
      'Observations importantes',
      'Observations générales'
    ]
  }

  getLocalLabel (typeLocal: number): string {
    let label: string = ''
    const target: any = this.typeLocalItemsList.find(
      (item: any) => item.num === typeLocal
    )
    target ? (label = target.label) : (label = '')

    return label
  }

  getTransactionLabel (typeTransaction: number): string {
    let label = ''
    const target: any = [...this.typeTransactionLocative, ...this.typeTransactionVenale].find((item: any) => item.num === typeTransaction)
    target ? (label = target.label) : (label = '')

    return label
  }

  filterOptions (query: string, type: string) {
    if (type === 'typeLocal') {
      this.filteredTypeLocalItems = filterGroupList(query, this.typeLocalItems)
    } else if (type === 'typeTransaction') {
      this.filteredTypeTransaction = filterGroupList(query, this.typeTransaction)
    }
  }

  handleFocusList (type: string) {
    if (type === 'typeLocal') {
      this.filteredTypeLocalItems = JSON.parse(JSON.stringify(this.typeLocalItems))
    } else if (type === 'typeTransaction') {
      this.filteredTypeTransaction = JSON.parse(JSON.stringify(this.typeTransaction))
    }
  }

  changeTypeTransaction (typeTransaction: number) {
    if (typeTransaction <= 4) {
      this.typeValeur = 1
    } else {
      this.typeValeur = 2
    }
  }

  validate (): Promise<boolean> {
    return new Promise((resolve: any) => {
      (this.$refs.tempForm as any).validate((valid: boolean) => {
        resolve(valid)
      })
    })
  }

  async generateTemp (): Promise<void> {
    const isValid: Boolean = await this.validate()
    if (isValid) {
      const columns: string[] = this.getTempColumns()
      listReferenceService.generateReferenceTemplate({
        columns
      }).then((res: any) => {
        if (res.data) {
          this.downloadFile(res.data, 'reference-template.xlsx')
        }
      })
    }
  }

  handleImportSuccess (response: any[]): void {
    // this.loading = false;
    this.importedRefIds = response.filter((item: any) => item.success).map((item: any) => item.id)
    this.$message({
      message: 'Analyse du fichier finie. Un fichier de rapport à été généré.' +
          ' Validez ou annulez cet import.',
      type: 'success',
      offset: 65
    })
    listReferenceService.generateValidateFile({ references: response }).then((res: any) => {
      if (res.data) {
        this.downloadFile(res.data, 'reference-import-statut.xlsx')
        this.importedSuccess = true
      }
    })
  }

  handleImportFailed (): void {
    this.$message({
      message: 'Erreur de lecture de fichier',
      type: 'error',
      offset: 65
    })
    this.loading = false;
    (this.$refs.upload as any).clearFiles()
  }

  validateImport (): void {
    this.loading = true
    listReferenceService.validateImports({ referenceIds: this.importedRefIds }).then(() => this.close())
  }

  cancelImport (): void {
    this.loading = true
    listReferenceService.cancelImports({ referenceIds: this.importedRefIds }).then(() => this.close())
  }

  downloadFile (data: any, fileName: string): void {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  handleFileUpload (): void {
    this.loading = !this.loading
  }
}
