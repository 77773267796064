<template>
  <div
    class="result d-flex align-items-center justify-content-start"
    :class="[{ active: (result.id === activeId) }, 'contact-color']"
    @click="handleClickOnItem(result)">
    <figure>
      <i class="rb-person_black" />
    </figure>
    <div class="description">
      <div class="desc-top d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-between align-items-center tag-container">
          <el-tag v-if="result.contact.title">
            {{ result.contact.title }}
          </el-tag>
          <el-tag v-if="result.contact.contactType">
            {{ result.contact.contactType }}
          </el-tag>
          <el-tag v-else>
            Personne Physique
          </el-tag>
        </div>
        <div
          class="desc-top-right d-flex justify-content-between align-items-end"
          :class="{ active: result.isPin }">
          <el-tooltip
            class="item"
            effect="dark"
            content="Epingler"
            placement="right">
            <el-button
              :class="{ active: result.isPin }"
              @click="pinResult(result)">
              <i
                v-if="result.isPin"
                class="rb-pin" />
              <i
                v-else
                class="rb-push_pin" />
            </el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Miniature"
            placement="right">
            <el-button
              :class="{ active: iseye }"
              @click="showMiniature">
              <i
                v-if="iseye"
                class="rb-visibility1" />
              <i
                v-else
                class="rb-visibility" />
            </el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Résultats associés"
            placement="right">
            <el-button
              @click="showAssociateResult">
              <i
                v-if="result.isassoc"
                class="rb-resultats-associe" />
              <i
                v-else
                class="rb-resultats-associe-alt" />
            </el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Ouvrir"
            placement="right">
            <el-button @click="handleClick">
              <i class="rb-open-new-alt" />
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <div>
        <h5 class="text-uppercase">
          {{ result.contact.title }} {{ result.contact.lastname }} {{ result.contact.firstname }}
        </h5>
      </div>
      <div
        v-if="result.contact.adresses && result.contact.adresses[0]"
        class="desc-detail d-flex justify-content-start align-items-center">
        <div class="d-flex justify-content-between align-items-start mr-3">
          <i class="rb-marqueur mr-2" />
          {{ result.contact.adresses[0].label | capitalizeFirstLetter }}
        </div>
        <div
          v-if="result.contact.email"
          class="d-flex justify-content-between align-items-start mr-3">
          <i class="rb-alternate_email_black mr-2" />
          {{ result.contact.email }}
        </div>
        <div
          v-if="result.contact.phoneNumber"
          class="d-flex justify-content-between align-items-start">
          <i class="rb-contact-filled mr-2" />
          {{ result.contact.phoneNumber }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { searchGlobalItemsService } from '@/services/searchGlobalItems.service'
import { tap } from 'rxjs/operators'

export default {
  props: {
    result: {
      type: Object,
      required: true
    },
    isDetail: {
      type: Boolean,
      require: false
    },
    isResultAssoc: {
      type: Boolean,
      require: false
    }
  },
  subscriptions () {
    return {
      loading$: searchGlobalItemsService.onLoading,
      activeId$: searchGlobalItemsService.onChangeActiveId.pipe(tap((res) => {
        if (res) {
          this.activeId = res
        }
      }))
    }
  },
  emits: ['pin'],
  data () {
    return {
      activeId: null,
      iseye: false
    }
  },
  methods: {
    handleClickOnItem (item) {
      this.$emit('click')
      this.isDetail && this.showMiniature()
      this.isResultAssoc && this.showAssociateResult()
      searchGlobalItemsService.changeActiveId(item.id)
    },
    handleClick () {
      this.$emit('detail', this.result)
    },
    pinResult (result) {
      this.$emit('pin', result)
    },
    showMiniature () {
      this.iseye = !this.iseye
      this.$emit('miniature', this.result)
    },
    showAssociateResult () {
      this.$emit('result', this.result)
    }
  }
}
</script>
