<template>
  <div class="h-100">
    <conflit-search ref="conflitmodal" />
    <div class="result-grid-view h-100">
      <RecycleScroller
        v-slot="{ item, index }"
        ref="virtualscroller"
        class="h-100"
        :items="resultsWithId"
        :item-size="125"
        :grid-items="1"
        :item-secondary-size="0"
        key-field="id"
        :emit-update="true"
        @update="setLoading()">
        <div style="height: 125px;">
          <ged-result-item
            v-if="item.objectType === 'ged'"
            :result="item"
            :is-detail="isDetail"
            :is-result-assoc="isResultAssoc"
            @miniature="showMiniature"
            @detail="handleShowDocument"
            @result="getResultsAssocies"
            @pin="pinResult"
            @click="focusMap(item)" />
          <ref-result-item
            v-else-if="item.objectType === 'reference'"
            :result="item"
            :is-detail="isDetail"
            :is-result-assoc="isResultAssoc"
            @miniature="showMiniature"
            @detail="handleClick"
            @result="getResultsAssocies"
            @pin="pinResult"
            @click="focusMap(item)" />
          <contact-result-item
            v-else-if="item.objectType === 'contact'"
            :result="item"
            :is-detail="isDetail"
            :is-result-assoc="isResultAssoc"
            @miniature="showMiniature"
            @detail="handleClick"
            @result="getResultsAssocies"
            @pin="pinResult"
            @click="focusMap(item)" />
          <folder-result-item
            v-else-if="item.objectType === 'quote' || item.objectType === 'settled-folder' || item.objectType === 'legal-folder'"
            :index="index"
            :result="item"
            :is-detail="isDetail"
            :is-result-assoc="isResultAssoc"
            @miniature="showMiniature"
            @detail="handleClick"
            @result="getResultsAssocies"
            @pin="pinResult"
            @showConflicts="openConflitModal"
            @click="focusMap(item)" />
          <fodegi-result-item
            v-if="item.objectType === 'fodegi'"
            :key="`fodegi-${index}`"
            :result="item"
            @pin="pinResult" />
        </div>
      </RecycleScroller>
    </div>
  </div>
</template>
<script>
import ConflitSearch from '@/components/modal/ConflitSearch.vue'
import RefResultItem from '@/components/search/result/RefResultItem.vue'
import ContactResultItem from '@/components/search/result/ContactResultItem.vue'
import GedResultItem from '@/components/search/result/GedResultItem.vue'
import FolderResultItem from '@/components/search/result/FolderResultItem.vue'
import FodegiResultItem from '@/components/search/result/FodegiResultItem'
import { globalSearchService } from '@/services/globalSearch.service'
import { searchGlobalItemsService } from '@/services/searchGlobalItems.service'

export default {
  components: { ConflitSearch, RefResultItem, ContactResultItem, GedResultItem, FolderResultItem, FodegiResultItem },
  props: {
    results: {
      type: Array,
      required: true
    },
    isDetail: {
      type: Boolean,
      required: false
    },
    isResultAssoc: {
      type: Boolean,
      required: false
    }
  },
  subscriptions () {
    return {
      loading$: searchGlobalItemsService.onLoading,
      activeId: searchGlobalItemsService.onChangeActiveId
    }
  },
  emits: ['detail', 'result', 'miniature', 'togglePin', 'showDocument'],
  computed: {
    resultsWithId () {
      return this.results.map(r => ({
        id: this.getId(r),
        ...r
      })
      )
    }

  },
  methods: {
    openConflitModal (data) {
      this.$refs.conflitmodal.open(data.conflicts, data.name, data.type, data.id)
    },
    pinResult (result) {
      if (result.objectType === 'contact') {
        this.$emit('togglePin', { type: result.objectType, id: result.contact.id, isPin: result.isPin })
      } else if (result.objectType === 'ged') {
        this.$emit('togglePin', { type: result.objectType, id: result.ged.id, isPin: result.isPin })
      } else if (result.objectType === 'reference') {
        this.$emit('togglePin', { type: result.objectType, id: result.reference.id, isPin: result.isPin })
      } else if (result.objectType === 'quote' || result.objectType === 'settled-folder' || result.objectType === 'legal-folder') {
        this.$emit('togglePin', { type: result.objectType, id: result.folder.id, isPin: result.isPin })
      } else if (result.objectType === 'fodegi') {
        this.$emit('togglePin', { type: result.objectType, id: result.fodegi.id, isPin: result.isPin })
      }
    },
    handleClick (result) {
      this.$emit('detail', result)
    },
    handleResult (showAssoc, resultsAssocies) {
      this.$emit('result', { showAssoc, resultsAssocies })
    },
    async focusMap (result) {
      let address = ''
      if (result.objectType === 'contact' && result.contact.adresses && result.contact.adresses[0]) {
        searchGlobalItemsService.changeActiveId('contact-' + result.contact.id)
        address = result.contact.adresses[0].buildingnumber + ' ' + result.contact.adresses[0].street + ' ' + result.contact.adresses[0].zipcode + ' ' + result.contact.adresses[0].city
      } else if (result.objectType === 'reference' && result.reference.adresse) {
        searchGlobalItemsService.changeActiveId('reference-' + result.reference.id)
        address = result.reference.adresse
      } else if ((result.objectType === 'quote' || result.objectType === 'settled-folder' || result.objectType === 'legal-folder') && result.folder.adresses && result.folder.adresses[0]) {
        searchGlobalItemsService.changeActiveId('folder-' + result.folder.id)
        address = result.folder.adresses[0].buildingnumber + ' ' + result.folder.adresses[0].street + ' ' + result.folder.adresses[0].zipcode + ' ' + result.folder.adresses[0].city
      } else if (result.objectType === 'ged' && result.ged && result.ged.adresses && result.ged.adresses.adresse) {
        searchGlobalItemsService.changeActiveId('ged-' + result.ged.id)
        address = result.ged.adresses.adresse
      }
      if (address.trim() !== '') {
        this.$emit('changeView', { address, id: result.id })
      }

      /* let detail = true;
      this.$emit("miniature", {detail, result}); */
    },
    getResultsAssocies (result) {
      switch (result.objectType) {
        case 'contact':
          globalSearchService.getAssociatedResultsContact(result.contact.id).then(res => {
            this.handleResult(true, res)
          })
          break
        case 'ged':
          globalSearchService.getAssociatedResultsGed(result.ged.adresses.label).then(res => {
            this.handleResult(true, res)
          })
          break
        case 'reference':
          globalSearchService.getAssociatedResultsReference(result.reference.adresse).then(res => {
            this.handleResult(true, res)
          })
          break
        default:
          if (result.folder.adresses.length > 0) {
            globalSearchService.getAssociatedResultsFolder(result.folder.adresses[0].label).then(res => {
              this.handleResult(true, res)
            })
          }
          break
      }
    },
    getId (result) {
      if (result.objectType === 'contact') {
        return 'contact-' + result.contact.id
      } else if (result.objectType === 'reference') {
        return 'reference-' + result.reference.id
      } else if (result.objectType === 'quote' || result.objectType === 'settled-folder' || result.objectType === 'legal-folder') {
        return result.objectType + '-' + result.folder.id
      } else if (result.objectType === 'ged' && result.ged.adresses && result.ged) {
        return 'ged-' + result.ged.id
      } else if (result.objectType === 'fodegi') {
        return 'fodegi-' + result.fodegi.id
      }
      return ''
    },
    showMiniature (result) {
      const detail = true
      this.$emit('miniature', { detail, result })
    },
    findInList (id) {
      const i = this.resultsWithId.findIndex((element) => element.id === id)
      if (i >= 0) {
        this.$refs.virtualscroller.scrollToItem(i)
        searchGlobalItemsService.changeActiveId(id)
      }
    },
    handleShowDocument (ged) {
      this.$emit('showDocument', ged)
    },
    setLoading () {
      searchGlobalItemsService.setIsLoading(false)
    }
  }
}
</script>

<style>
.vue-recycle-scroller__item-view {
  width: 100% !important;
}
</style>
