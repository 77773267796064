
import { Component, Vue } from 'vue-property-decorator'

@Component({
  props: {
    title: String,
    isFirst: Boolean
  }
})
export default class ThItem extends Vue {

}
