
import { Component, Vue } from 'vue-property-decorator'
import { quoteService, Quote } from '@/services/quote.service'
import { QUOTE_STATUES } from '@/services/constants.service'
import QuoteStatusValidModal from './QuoteStatusValidModal.vue'
import { tap } from 'rxjs/operators'
    // The @Component decorator indicates the class is a Vue component
    @Component({
      subscriptions (this:any) {
        return {
          quote$: quoteService.onChangeQuote.pipe(
            tap((res: any) => {
              if (res.id) {
                const statusHistory: any[] = res.statusHistory.filter((element: any) => element.status <= res.status)
                for (const element of statusHistory.sort((a, b) => a.date - b.date)) {
                  this.activeStatusDate.splice(element.status, 1, new Date(element.changeAt).toLocaleDateString(undefined) + ' ' + new Date(element.changeAt).toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' }))
                }
              }
            }))
        }
      },
      components: {
        QuoteStatusValidModal
      }
    })

export default class QuotePipeLine extends Vue {
  QUOTE_STATUES = QUOTE_STATUES
  quote$: Quote = new Quote()
  activeStatusDate : any[] = []

  created () {
    this.activeStatusDate = Array(this.QUOTE_STATUES.length).fill(null)
  }

  get step (): number {
    if (this.quote$.status >= 1 && this.quote$.status <= 3) {
      return 1
    } else if (this.quote$.status === 4) {
      return 2
    } else if (this.quote$.status === 5) {
      return 3
    } else if (this.quote$.status >= 7) {
      return 5
    } else if (this.quote$.status === 6) {
      return 4
    }

    return 0
  }

  changeStep (step: number) {
    if (this.step !== step) {
      switch (step) {
        case 1:
          this.$emit('change', 1)
          break
        case 2:
          this.$emit('change', 4)
          break
        case 3:
          this.$emit('change', 5)
          break
        case 4:
          this.$emit('change', 6)
          break
        case 5:
          (this.$refs.statusValid as any).open()
          break
        default:

          break
      }
    }
  }

  handleValidStatus ({ status, reason }: any) {
    if (status === 7) {
      if (!this.quote$.acceptDate) {
        this.$message({
          message: "La date d'acceptation est vide.",
          type: 'error',
          offset: 65
        })
        this.$emit('changeActiveNames', 'general')
      } else { this.$emit('change', status) }
    } else {
      this.quote$.oldStatus = this.quote$.status
      this.quote$.status = status
      this.quote$.conflictReason = reason
      quoteService.saveQuote()
    }
  }
}
