<template>
  <div
    style="height: 40px"
    :class="[{ active: (result.id === activeId) }, 'result-list-td-container-div']"
    @click="handleClickOnItem(result)">
    <div
      style="height: 40px;"
      class="w-100 d-flex align-items-center justify-content-start">
      <div class="t-w-40 result-list-td">
        <el-tooltip
          class="item"
          effect="dark"
          content="Epingler"
          placement="right">
          <el-button
            :class="{ active: result.isPin }"
            @click="doublePinResult(result)">
            <i
              v-if="result.isPin"
              class="rb-pin" />
            <i
              v-else
              class="rb-push_pin" />
          </el-button>
        </el-tooltip>
      </div>
      <div
        v-if="result.objectType === 'reference' || result.objectType === 'fodegi'"
        class="result-list-td">
        <el-tooltip
          key="t17"
          :content="result.id.toString()">
          <span>{{ result.id }}</span>
        </el-tooltip>
      </div>
      <div
        v-else
        class="result-list-td">
        <el-tooltip
          key="t1"
          :content="result.name">
          <span class="name-table ellipsis-text">{{ result.name }}</span>
        </el-tooltip>
      </div>
      <div class="result-list-td">
        <el-tooltip
          key="t23"
          :content="result.adresse">
          <span class="ellipsis-text">{{ result.adresse }}</span>
        </el-tooltip>
      </div>
      <div
        v-if="result.objectType !== 'fodegi'"
        class="t-w-40 result-list-td">
        <el-dropdown
          :key="result.id"
          trigger="click"
          style="z-index:999;">
          <span class="el-dropdown-link">
            <i class="el-icon-more el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="rb-visibility1">
              <el-button
                type="text"
                @click="doubleShowMiniature(result)">
                Miniature
              </el-button>
            </el-dropdown-item>
            <el-dropdown-item icon="rb-resultats-associe">
              <el-button
                type="text"
                @click="doubleGetResultsAssocies(result)">
                Résultats associés
              </el-button>
            </el-dropdown-item>
            <el-dropdown-item icon="rb-open-new-alt">
              <el-button
                type="text"
                @click="doubleHandleOpenDetail(result)">
                Ouvrir
              </el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { searchGlobalItemsService } from '@/services/searchGlobalItems.service'
import { tap } from 'rxjs/operators'
import { getTransactionTypeLabel } from '@/utils/functions'
import { LOCAL_TYPE } from '@/services/constants.service'

export default {
  props: {
    result: {
      type: Object,
      required: true
    },
    isDetail: {
      type: Boolean,
      require: false
    },
    isResultAssoc: {
      type: Boolean,
      require: false
    }
  },
  emits: ['pin', 'showConflicts', 'click', 'result', 'detail', 'miniature'],
  subscriptions () {
    return {
      loading$: searchGlobalItemsService.onLoading,
      activeId$: searchGlobalItemsService.onChangeActiveId.pipe(tap((res) => {
        if (res) {
          this.activeId = res
        }
      }))
    }
  },
  data () {
    return {
      activeId: null
    }
  },
  methods: {
    doubleShowMiniature (result) {
      this.$emit('doubleShowMiniature', result)
    },
    doubleGetResultsAssocies (result) {
      this.$emit('doubleGetResultsAssocies', result)
    },
    doubleHandleOpenDetail (result) {
      this.$emit('doubleHandleOpenDetail', result)
    },
    handleClickOnItem () {
      this.$emit('click')
      this.isDetail && this.doubleShowMiniature(this.result)
      this.isResultAssoc && this.doubleGetResultsAssocies(this.result)
    },
    doublePinResult (result) {
      this.$emit('click')
      this.$emit('doublePin', result)
    },
    getTransactionTypeLabel (value) {
      return getTransactionTypeLabel(value, this.listReferences$)
    },
    localType (value) {
      if (!value) {
        return ''
      }
      return LOCAL_TYPE.filter((item) => item.value === value)[0]
        .label
    }
  }
}
</script>
