<template>
  <el-form class="el-form--custom d-flex">
    <perfect-scrollbar class="">
      <div class="filter-advanced--filters">
        <el-row>
          <el-col :span="6">
            <el-select
              v-model="filters.folder_type"
              placeholder="Type de dossier"
              popper-class="selectSearch">
              <el-option
                v-for="folder in foldersTypes"
                :key="folder.value"
                :label="folder.label"
                :value="folder.value" />
            </el-select>
          </el-col>
        </el-row>
        <el-collapse v-model="activeNames">
          <el-collapse-item
            title="Utilisateur"
            name="1">
            <div class="mt-4">
              <el-row :gutter="20">
                <el-col :span="10">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Indisponible pour une recherche devis ou dans la catégorie"
                    :disabled="filters.folder_type !== 'quote'"
                    placement="top-end">
                    <el-select
                      v-model="filters.usersIds"
                      placeholder="Nom"
                      popper-class="selectSearch"
                      :disabled="filters.folder_type === 'quote'"
                      multiple
                      filterable
                      clearable>
                      <el-option
                        v-for="(item, index) in users$"
                        :key="`${item.id}-${index}`"
                        :label="formatUserName(item.nom, item.prenom)"
                        :value="item.id" />
                    </el-select>
                  </el-tooltip>
                </el-col>
                <el-col
                  v-if="isUser()"
                  :span="3"
                  class="text-center justify-content-center">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Indisponible pour une recherche devis"
                    :disabled="filters.folder_type !== 'quote'"
                    placement="top-end">
                    <el-checkbox
                      v-model="filters.creator"
                      :disabled="filters.folder_type === 'quote'">
                      Créateur
                    </el-checkbox>
                  </el-tooltip>
                </el-col>
                <el-col
                  v-if="isUser()"
                  :span="3"
                  class="text-center justify-content-center">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Indisponible pour une recherche devis"
                    :disabled="filters.folder_type !== 'quote'"
                    placement="top-end">
                    <el-checkbox
                      v-model="filters.writer"
                      :disabled="filters.folder_type === 'quote'">
                      Rédacteur
                    </el-checkbox>
                  </el-tooltip>
                </el-col>
                <el-col
                  v-if="isUser()"
                  :span="3"
                  class="text-center justify-content-center">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Indisponible pour une recherche devis"
                    :disabled="filters.folder_type !== 'quote'"
                    placement="top-end">
                    <el-checkbox
                      v-model="filters.reviewer"
                      :disabled="filters.folder_type === 'quote'">
                      Relecteur
                    </el-checkbox>
                  </el-tooltip>
                </el-col>
                <el-col
                  v-if="isUser()"
                  :span="3"
                  class="text-center justify-content-center">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Indisponible pour une recherche devis"
                    :disabled="filters.folder_type !== 'quote'"
                    placement="top-end">
                    <el-checkbox
                      v-model="filters.signer"
                      :disabled="filters.folder_type === 'quote'">
                      Signataire
                    </el-checkbox>
                  </el-tooltip>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>
          <el-collapse-item
            title="Contact"
            name="2">
            <div class="mt-4">
              <el-row :gutter="20">
                <el-col :span="10">
                  <el-select
                    v-model="filters.contacts"
                    placeholder="Nom"
                    popper-class="selectSearch"
                    multiple
                    filterable
                    clearable
                    :filter-method="handleFilterContacts">
                    <el-option
                      v-for="(item, index) in contacts$"
                      :key="`${item.id}-${index}`"
                      :label="formatUserName(item.nom, item.prenom)"
                      :value="item.id" />
                  </el-select>
                </el-col>
                <el-col
                  v-if="isContact()"
                  :span="3">
                  <el-checkbox v-model="filters.mandator">
                    Mandant
                  </el-checkbox>
                </el-col>
                <el-col
                  v-if="isContact()"
                  :span="3">
                  <el-checkbox v-model="filters.interlocutor">
                    Interlocuteur
                  </el-checkbox>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>
          <el-collapse-item
            title="Adresse"
            name="3">
            <div class="mt-4">
              <el-row :gutter="20">
                <el-col :span="24">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-autocomplete
                        v-model="filters.address.buildingnumber"
                        clearable
                        class="d-block"
                        placeholder="N°"
                        :fetch-suggestions="
                          (query, cb) => querySearch(query, 'housenumber', cb)
                        "
                        value-key="label"
                        @select="handleSelectNumber" />
                    </el-col>
                    <el-col :span="8">
                      <el-autocomplete
                        v-model="filters.address.street"
                        clearable
                        class="d-block"
                        placeholder="Rue, allée, …"
                        :fetch-suggestions="
                          (query, cb) => querySearch(query, 'street', cb)
                        "
                        value-key="label" />
                    </el-col>
                    <el-col :span="4">
                      <el-autocomplete
                        v-model="filters.address.zipcode"
                        clearable
                        class="d-block"
                        :fetch-suggestions="
                          (query, cb) => querySearch(query, 'municipality', cb)
                        "
                        placeholder="Code Postal"
                        value-key="label" />
                    </el-col>
                    <el-col :span="6">
                      <el-autocomplete
                        v-model="filters.address.city"
                        clearable
                        class="d-block"
                        placeholder="Ville"
                        :fetch-suggestions="
                          (query, cb) => querySearch(query, 'municipality', cb)
                        "
                        value-key="label" />
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row
                :gutter="20"
                class="mt-4">
                <el-col :span="6">
                  <el-select
                    v-model="filters.typeMission"
                    placeholder="Choisir un type de mission"
                    popper-class="selectSearch"
                    multiple
                    filterable
                    clearable>
                    <el-option
                      v-for="item in MISSION_TYPES"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value" />
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <el-select
                    v-model="filters.typeExpert"
                    popper-class="selectSearch"
                    multiple
                    filterable
                    placeholder="Choisir un type d’expertise"
                    style="width: 100%"
                    clearable>
                    <el-option
                      v-for="item in MISSION_EXPERT_OPTIONS"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value" />
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <el-select
                    v-model="filters.typeLocal"
                    popper-class="selectSearch"
                    multiple
                    filterable
                    placeholder="Choisir un type d’actif"
                    style="width: 100%"
                    clearable>
                    <el-option
                      v-for="item in MISSION_LOCAL_OPTIONS"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value" />
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Taper un numéro de section"
                    :disabled="filters.folder_type !== 'legalFolder'"
                    placement="top-end">
                    <el-input
                      v-model="filters.sectionNumber"
                      v-mask="'##/#####'"
                      placeholder="Taper un numéro de section"
                      :disabled="filters.folder_type !== 'legalFolder'" />
                  </el-tooltip>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>
          <el-collapse-item
            title="Statut"
            name="4">
            <div class="mt-4">
              <el-row
                :gutter="20"
                class="mt-4 align-items-stretch">
                <el-col :span="12">
                  <div
                    class="tag-container"
                    :class="{disabled: filters.folder_type === 'folder' || !filters.folder_type}">
                    <label>Statut</label>
                    <perfect-scrollbar>
                      <div
                        class="
                        d-flex
                        flex-wrap
                        align-items-center
                        justify-content-start
                      ">
                        <el-checkbox-group v-model="filters.status">
                          <template v-if="filters.folder_type === 'quote'">
                            <el-checkbox-button
                              v-for="tag in QUOTE_STATUES"
                              :key="tag.value"
                              :label="tag.value"
                              :class="tag.class">
                              {{ tag.label }}
                            </el-checkbox-button>
                          </template>
                          <template v-if="filters.folder_type === 'legalFolder'">
                            <el-checkbox-button
                              v-for="tag in LEGAL_FOLDER_STATUES"
                              :key="tag.value"
                              :label="tag.value"
                              :class="tag.class">
                              {{ tag.label }}
                            </el-checkbox-button>
                          </template>
                          <template v-if="filters.folder_type === 'settledFolder'">
                            <el-checkbox-button
                              v-for="tag in SETTED_FOLDER_STATUES"
                              :key="tag.value"
                              :label="tag.value"
                              :class="tag.class">
                              {{ tag.label }}
                            </el-checkbox-button>
                          </template>
                        </el-checkbox-group>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="d-flex align-items-center justify-content-start status">
                    <label class="mr-3 label-status">Ouvert entre</label>
                    <el-date-picker
                      v-model="filters.open.start"
                      type="date"
                      placeholder="" />
                    <label class="ml-3 mr-3">au</label>
                    <el-date-picker
                      v-model="filters.open.end"
                      type="date"
                      placeholder="" />
                  </div>
                  <div
                    class="
                    d-flex
                    align-items-center
                    justify-content-start
                    status
                    mt-4
                  ">
                    <label class="mr-3 label-status">Clôturé entre</label>
                    <el-date-picker
                      v-model="filters.close.start"
                      type="date"
                      placeholder="" />
                    <label class="ml-3 mr-3">au</label>
                    <el-date-picker
                      v-model="filters.close.end"
                      type="date"
                      placeholder="" />
                  </div>
                  <div
                    class="
                    d-flex
                    align-items-center
                    justify-content-start
                    status
                    mt-4
                  ">
                    <el-checkbox
                      v-model="filters.hasPendingAmount"
                      label="1">
                      Reste à facturer
                    </el-checkbox>
                    <el-checkbox
                      v-model="filters.hasBalanceAmount"
                      label="2">
                      Reste à percevoir
                    </el-checkbox>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>
          <el-collapse-item
            title="Facturation"
            name="5">
            <div class="mt-4">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-select
                    v-model="filters.typeInvoice"
                    placeholder="Type de facture"
                    popper-class="selectSearch">
                    <el-option
                      v-for="facture in TYPE_INVOICES"
                      :key="facture.value"
                      :label="facture.label"
                      :value="facture.value" />
                  </el-select>
                </el-col>
                <el-col :span="12">
                  <div class="d-flex align-items-center justify-content-start status">
                    <label class="mr-3 label-status">En date du</label>
                    <el-date-picker
                      v-model="filters.invoice.start"
                      type="date"
                      placeholder="" />
                    <label class="ml-3 mr-3">au</label>
                    <el-date-picker
                      v-model="filters.invoice.end"
                      type="date"
                      placeholder="" />
                  </div>
                </el-col>
              </el-row>
              <el-row
                :gutter="20"
                class="mt-4">
                <el-col :span="12">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Veuillez sélectionner au moins un type de facture concerné"
                    :disabled="hasTypeInvoice"
                    placement="top-end">
                    <div class="tag-wrapper">
                      <el-input
                        ref="saveTagInput"
                        v-model="filters.number"
                        class="input-new-tag"
                        size="mini"
                        :disabled="!hasTypeInvoice"
                        placeholder="Numéro de facture" />
                    </div>
                  </el-tooltip>
                </el-col>
                <el-col :span="12">
                  <div class="d-flex align-items-center justify-content-start status">
                    <label class="mr-3 label-status">Plage de N°</label>
                    <el-input
                      v-model="filters.billNumber.start"
                      placeholder="" />
                    <label class="ml-3 mr-3">au</label>
                    <el-input
                      v-model="filters.billNumber.end"
                      placeholder="" />
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>
          <el-collapse-item
            title="Débours et autre"
            name="6">
            <div class="mt-4">
              <el-row
                :gutter="20"
                class="mt-4">
                <el-col :span="12">
                  <el-select
                    v-model="filters.typeDebours"
                    placeholder="Choisir un débour"
                    popper-class="selectSearch">
                    <el-option
                      v-for="option in TYPES_DEBOURS"
                      :key="option.value"
                      :label="option.label"
                      :value="option.value" />
                  </el-select>
                </el-col>
                <el-col :span="12">
                  <div class="d-flex align-items-center justify-content-start status">
                    <label class="mr-3 label-status">En date du</label>
                    <el-date-picker
                      v-model="filters.debours.start"
                      type="date"
                      placeholder="" />
                    <label class="ml-3 mr-3">au</label>
                    <el-date-picker
                      v-model="filters.debours.end"
                      type="date"
                      placeholder="" />
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>
          <el-collapse-item
            title="CA"
            name="7">
            <div class="mt-4">
              <el-row
                :gutter="20"
                class="mt-4 align-items-center d-flex status">
                <el-col :span="4">
                  <el-date-picker
                    v-model="filters.caYear"
                    type="year"
                    placeholder="Année" />
                </el-col>
                <el-col
                  :span="14"
                  class="d-flex align-items-center justify-content-center">
                  <el-radio
                    v-model="filters.caType"
                    label=""
                    class="mr-3"
                    value=""
                    @change="caLists">
                    Aucun
                  </el-radio>
                  <el-radio
                    v-model="filters.caType"
                    label="month"
                    class="mr-3"
                    value="month"
                    @change="caLists">
                    Mois
                  </el-radio>
                  <el-radio
                    v-model="filters.caType"
                    label="trimester"
                    class="mr-3"
                    value="trimester"
                    @change="caLists">
                    Trimestre
                  </el-radio>
                  <el-radio
                    v-model="filters.caType"
                    label="semester"
                    class="mr-3"
                    value="semester"
                    @change="caLists">
                    Semestre
                  </el-radio>
                </el-col>
                <el-col :span="6">
                  <el-select
                    v-model="filters.caDate"
                    popper-class="selectSearch"
                    filterable
                    :placeholder="
                      filters.caType === 'month'
                        ? 'Choisir un mois'
                        : filters.caType === 'trimester'
                          ? 'Choisir un trimestre'
                          : filters.caType === 'semester'
                            ? 'Choisir un semestre'
                            : ''
                    "
                    style="width: 100%"
                    clearable
                    :disabled="!filters.caType">
                    <el-option
                      v-for="item in caList"
                      :key="item.label"
                      :label="item.label"
                      :value="item.value" />
                  </el-select>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </perfect-scrollbar>
    <div class="btn-container">
      <div class="d-flex align-items-center justify-content-center">
        <el-button
          class="btn-black"
          @click="searchGlobalItems">
          Rechercher ({{ globalItemsTotal$ }})
        </el-button>
        <el-button @click="() => $emit('reset-filters')">
          Réinitialiser
        </el-button>
      </div>
    </div>
  </el-form>
</template>

<script>
import { userService } from '@/services/user.service'
import { dossierService } from '@/services/dossier.service'
import { listReferenceService } from '@/services/listReference.service'
import { semesterList, trimesterList, monthList } from '@/utils/constant'
import {
  MISSION_TYPES,
  MISSION_EXPERT_OPTIONS,
  MISSION_LOCAL_OPTIONS,
  QUOTE_STATUES,
  SETTED_FOLDER_STATUES,
  LEGAL_FOLDER_STATUES,
  TYPE_INVOICES,
  TYPES_DEBOURS
} from '@/services/constants.service'
import { searchGlobalItemsService } from '@/services/searchGlobalItems.service'
import { formatUserName } from '@/utils/functions'
export default {
  subscriptions () {
    return {
      users$: userService.onChangeUsers,
      user$: userService.onChange,
      contacts$: dossierService.onChangeContacts,
      globalItemsTotal$: searchGlobalItemsService.onChangeGlobalItemsTotal,
      filtersCount$: searchGlobalItemsService.onChangeFiltersCount,
      filters: searchGlobalItemsService.onChangeFolderFilters
    }
  },

  data () {
    return {
      MISSION_TYPES,
      MISSION_EXPERT_OPTIONS,
      MISSION_LOCAL_OPTIONS,
      QUOTE_STATUES,
      SETTED_FOLDER_STATUES,
      LEGAL_FOLDER_STATUES,
      TYPE_INVOICES,
      TYPES_DEBOURS,
      foldersTypes: [
        {
          value: 'folder',
          label: 'Amiable/Judiciaire'
        },
        {
          value: 'legalFolder',
          label: 'Judiciaire'
        },
        {
          value: 'settledFolder',
          label: 'Amiable'
        },
        {
          value: 'quote',
          label: 'Devis'
        }
      ],
      inputNumber: '',
      caList: [],
      activeNames: ['1']
    }
  },

  computed: {
    hasTypeInvoice () {
      return false
    }

  },

  created () {
    userService.getUserFilter()
    dossierService.getContactsByType(this.filters.folder_type)
  },

  methods: {
    formatUserName (nom, prenom) {
      return formatUserName(nom, prenom)
    },
    querySearch (queryString, layer, cb) {
      if (!queryString) return
      const { buildingnumber, street, zipcode, city } = this.filters.address
      const query = `${buildingnumber || ''}${street ? ' ' + street : ''}${
        city ? ', ' + city : ''
      }${zipcode ? ' ' + zipcode : ''}&type=${layer}`
      listReferenceService.getReferenceAddresse(query).then((res) => {
        cb(res)
      })
    },
    searchGlobalItems () {
      searchGlobalItemsService.setIsLoading(true)
      this.handleInitUserAttribute()
      this.$emit('search', this.filters)
    },
    caLists () {
      if (this.filters.caType === 'month') {
        this.caList = monthList
      } else if (this.filters.caType === 'trimester') {
        this.caList = trimesterList
      } else if (this.filters.caType === 'semester') {
        this.caList = semesterList
      } else {
        this.caList = []
      }
    },
    handleSelectNumber (item) {
      this.filters.address = {
        buildingnumber: item.housenumber,
        street: item.street,
        zipcode: item.postalcode,
        city: item.locality
      }
    },
    handleSelectStreet (item) {
      this.filter$.address = {
        street: item.street,
        zipcode: item.postalcode,
        city: item.locality
      }
    },
    handleSelectZip (item) {
      this.filters.address = {
        zipcode: item.postalcode,
        city: item.locality,
        layer: item.layer
      }
    },
    handleSelectCity (item) {
      this.filters.address = {
        zipcode: item.postalcode.slice(0, 2),
        city: item.locality,
        layer: item.layer
      }
    },
    isUser () {
      return this.filters.usersIds.length > 0
    },
    isContact () {
      return this.filters.contacts.length > 0
    },
    handleInitUserAttribute () {
      if (this.isUser()) {
        if (this.filters.creator === undefined) this.filters.creator = false
        if (this.filters.reviewer === undefined) this.filters.reviewer = false
        if (this.filters.signer === undefined) this.filters.signer = false
        if (this.filters.writer === undefined) this.filters.writer = false
      }
    },
    handleInitContactAttribute () {
      if (this.isContact()) {
        if (this.filters.mandator === undefined) this.filters.mandator = false
        if (this.filters.interlocutor === undefined) this.filters.interlocutor = false
      }
    },
    handleFilterContacts (filterValue) {
      if (filterValue != null && filterValue.length > 0) {
        dossierService.getContactsByKeyAndType(filterValue, this.filters.folder_type)
      } else {
        dossierService.getContactsByType(this.filters.folder_type)
      }
    }
  }
}
</script>

<style>
.el-form--custom {
  height: calc(100vh - 200px);
}
.el-form--custom > .ps {
  height: 100% !important;
  max-height: 100% !important;
}
.filter-advanced--filters {
  padding-bottom: 150px;
}
</style>
