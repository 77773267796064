
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import _ from 'lodash'
import L from 'leaflet'
import 'leaflet.markercluster'
import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'
import 'leaflet-defaulticon-compatibility'
import {
  ListReference,
  listReferenceService,
  ReferenceAddress
} from '@/services/listReference.service'
import {
  saveSearchService
} from '@/services/saveSearch.service'

import SearchedTable from './SearchedTable.vue'
import PreviewSection from './PreviewSection.vue'
import SearchSection from './SearchSection.vue'
import { MAP_STATE_2, MAP_STATE_3 } from '@/constants'
import {
  getIsochroneScales,
  generateTypeLocalGroup,
  generateActivitiesGroup
} from '@/utils/functions'
declare let require: any
const tgm = require('@targomo/leaflet/targomo-leaflet-full.umd.js')

@Component({
  subscriptions (this: any) {
    return {
      loading: listReferenceService.onLoading,
      saveSearch: saveSearchService.onChange
    }
  },
  components: {
    SearchedTable,
    PreviewSection,
    SearchSection
  }
})
export default class MapView extends Vue {
  @Prop({ type: Boolean })
    isInitialMap: boolean

  @Prop({ type: Array })
    newResult: any

  @Prop({ type: Boolean })
    updateAction: boolean

  loading: boolean = false
  saveSearch: any = null

  toggleState: string = MAP_STATE_2
  showMap: boolean = true
  radius = ''
  searchRadius = 0
  viewOption = 'classique'
  viewOptionsGroupe = ['rayon', 'isochrone']
  travelModesGroupe = ['walk', 'bike', 'car', 'transit']
  typeModesGroupe = ['time', 'distance']
  travelMode = 'walk'
  displayType = 'distance'
  isochroneScales: string[] = []
  isochroneScaleColors = ['#006837', '#F7931E', '#C1272D']
  map: any = null
  tileLayer: any = null
  markers: any = []
  markersLayer: any = null
  centreMarkLayer: any = null
  circlesLayer: any = null
  polygonOverlayLayer: any = null
  mapboxUrl = 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png'
  mapboxAttribution = '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'
  cordinates = {
    latitude: 48.86,
    longitude: 2.34
  }

  isNotExactAddress: boolean = true
  searchTableResult: any[] = []
  initialResult: any[] = []
  searchCondition: any = {
    typeValeur: 1,
    typeLocal: null,
    natureCapital: []
  }

  typeValeur: ListReference[] = []
  typeLocalItems: any[] = []
  typeLocalItemsList: any[] = []
  activites: any[] = []
  activitesList: ListReference[] = []
  typePonderation: ListReference[] = []
  typeTransactionLocative: ListReference[] = []
  natureCapital: ListReference[] = []
  motifDeplafonnement: ListReference[] = []
  typeTransactionVenale: ListReference[] = []
  fiabilite: ListReference[] = []
  impotFoncier: ListReference[] = []
  typeTransaction: ListReference[] = []
  typeTransactionOptions: any[] = []
  selectedRef: any = null
  selectedTableColor: string = '#ffffff'
  selectedTableKey: number = 0
  goToDivId: number = 0
  prevSelectedRefKey: number = 0
  optionList: any = {
    typeValeur: [],
    activites: [],
    typePonderation: [],
    motifDeplafonnement: [],
    natureCapital: []
  }

  zoom: number = 13
  searchMarker: any = null

  handleToggleView (val: string) {
    this.toggleState = val
  }

  changeScreenshotModel (val: boolean) {
    this.$emit('handleChangeScreenshotModel', val)
  }

  download (isNumberFormat: boolean): void {
    this.$emit('handleDownload', isNumberFormat)
    this.getFullSearchResult(this.searchTableResult)
  }

  @Watch('updateAction', { immediate: true, deep: true })
  onUpdateNewResultAction (val: string) {
    if (!val || !this.$route.params.id) {
      return
    }
    this.searchTableResult = JSON.parse(JSON.stringify(this.newResult))
  }

  @Watch('toggleState', { immediate: true, deep: true })
  onChangeToggleState (val: string) {
    if (val === MAP_STATE_3) {
      this.showMap = false
    } else {
      this.showMap = true
    }
    if (this.map) {
      setTimeout(() => {
        this.map.invalidateSize()
      }, 500) // call after end css animation
    }
  }

  created () {
    listReferenceService.getListReference().then(res => {
      const typeLocalItemsList = res.filter((item: ListReference) => item.type === 1)
      this.typeLocalItemsList = typeLocalItemsList
      this.typeLocalItems = generateTypeLocalGroup(typeLocalItemsList)
      const activites = res.filter((item: ListReference) => item.type === 3)
      this.activitesList = activites
      this.activites = generateActivitiesGroup(activites)
      this.typeTransactionLocative = res.filter((item: ListReference) => item.type === 4)
      this.natureCapital = res.filter((item: ListReference) => item.type === 5)
      this.motifDeplafonnement = res.filter((item: ListReference) => item.type === 6)
      this.typeTransactionVenale = res.filter((item: ListReference) => item.type === 7)
      this.typePonderation = res.filter((item: ListReference) => item.type === 8)
      this.fiabilite = res.filter((item: ListReference) => item.type === 9)
      this.impotFoncier = res.filter((item: ListReference) => item.type === 10)
      this.typeValeur = res.filter((item: ListReference) => item.type === 11)
      this.typeTransaction = res.filter(
        (item: ListReference) => item.type === 4 || item.type === 7
      )
      this.typeTransactionOptions = [
        {
          label: 'VALEUR LOCATIVE',
          options: this.typeTransactionLocative
        },
        {
          label: 'VALEUR VENALE',
          options: this.typeTransactionVenale
        }
      ]
      this.optionList.typeValeur = this.typeValeur
      this.optionList.typePonderation = this.typePonderation
      this.optionList.motifDeplafonnement = this.motifDeplafonnement
      this.optionList.natureCapital = this.natureCapital

      if (localStorage.getItem('duplicatedSearch')) {
        const search = JSON.parse(localStorage.getItem('duplicatedSearch') || '')
        const searchTableResult = search.result
        this.getFullSearchResult(searchTableResult)
        this.searchCondition = search.criteria
        saveSearchService.initDuplicateSearch(search)
        if (this.searchCondition.referenceAddress) {
          this.updateSearchAddress(this.searchCondition.referenceAddress)
          if (this.searchCondition.referenceAddress.lat != null && this.searchCondition.referenceAddress.lon != null) {
            this.map.setView([this.searchCondition.referenceAddress.lat, this.searchCondition.referenceAddress.lon], this.zoom)
          }
        }
        localStorage.removeItem('duplicatedSearch')
      } else {
        if (this.$route.params.id) {
          const searchId = this.$route.params.id
          saveSearchService
            .getSearchById(searchId)
            .then((res: any) => {
              const searchTableResult = res.result
              this.getFullSearchResult(searchTableResult)
              this.searchCondition = res.criteria
              if (this.searchCondition.referenceAddress) {
                this.updateSearchAddress(this.searchCondition.referenceAddress)
                if (this.searchCondition.referenceAddress.lat != null && this.searchCondition.referenceAddress.lon != null) {
                  this.map.setView([this.searchCondition.referenceAddress.lat, this.searchCondition.referenceAddress.lon], this.zoom)
                }
              }
            })
            .catch(() => {
              this.$router.replace('/search-ref')
            })
        }
      }
    })
  }

  mounted () {
    this.initMap()
  }

  get isHalf (): boolean {
    return this.toggleState === MAP_STATE_2
  }

  get showTable (): boolean {
    return this.toggleState === MAP_STATE_2 || this.toggleState === MAP_STATE_3
  }

  initMap () {
    const mapContainer: any = L.DomUtil.get('map')
    if (mapContainer != null) {
      mapContainer._leaflet_id = null
    }
    this.map = new (L.map as any)('map', {
      zoomControl: true,
      dragging: true,
      renderer: L.canvas()
    }).setView([this.cordinates.latitude, this.cordinates.longitude], this.zoom)
    this.tileLayer = L.tileLayer(this.mapboxUrl, {
      maxZoom: 18,
      attribution: this.mapboxAttribution
    })
    this.tileLayer.addTo(this.map)

    // define the polygon overlay
    this.polygonOverlayLayer = new tgm.TgmLeafletPolygonOverlay({
      strokeWidth: 20
    })
  }

  handleViewOption (option: string) {
    if (!this.isInitialMap && !this.isNotExactAddress) {
      this.viewOption = option
    }
  }

  handleTravelMode (mode: string) {
    if (this.travelMode === mode) {
      this.travelMode = ''
      this.removeIsochrne()
      return
    }
    this.travelMode = mode
    this.addIsochrone()
  }

  handleTypeMode (mode: string) {
    if (this.displayType === mode) {
      this.displayType = ''
      this.removeIsochrne()
      return
    }
    this.travelMode = 'walk'
    this.displayType = mode
    this.addIsochrone()
  }

  handleChangeViewOption (option: string) {
    if (this.viewOption === option) {
      this.viewOption = 'classique'
    } else {
      this.viewOption = option
    }
    if (this.viewOption === 'isochrone') {
      this.addIsochrone()
    } else {
      this.removeIsochrne()
    }
    if (this.viewOption !== 'rayon') {
      this.removeCircles()
    }
  }

  drawMarkers () {
    const markers: any = []
    const self = this
    this.markers.forEach((marker: any) => {
      if (marker.show && marker.latitude && marker.longitude) {
        markers.push(
          L.marker([marker.latitude, marker.longitude], {
            icon: L.divIcon({
              className: 'custom-div-icon',
              html: `<div class='marker-pin' style="opacity: ${
                marker.cloudy ? 0.3 : 1
              }; display: ${
                marker.show ? 'flex' : 'none'
              };">
                      <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32">
                        <g fill="none" fill-rule="evenodd">
                          <path fill="${
                            marker.iconColor
                          }" d="M15.427 29.435c-8.039-11.52-9.53-12.701-9.53-16.935C5.896 6.701 10.651 2 16.517 2c5.867 0 10.623 4.701 10.623 10.5 0 4.234-1.492 5.416-9.53 16.935a1.337 1.337 0 0 1-2.184 0z"/>
                        </g>
                      </svg>
                      <p style="font-size: ${
                        parseInt(marker.referenceKey) > 100 ? '9px' : '12px'
                      }; margin: ${
                parseInt(marker.referenceKey) > 100 ? '8px auto' : '5px auto'
              };">${marker.referenceKey}</p>
                    </div>`,
              iconSize: [30, 42],
              iconAnchor: [15, 42]
            })
          }).on('click', () => {
            self.handleMarkerClick(marker)
          })
        )
      }
    })
    this.markersLayer = L.layerGroup(markers)
    this.markersLayer.addTo(this.map)
  }

  removeMarkers () {
    if (this.markersLayer !== null) {
      this.markersLayer.clearLayers()
    }
  }

  @Emit('changeSearchResult')
  handleMarkerClick (marker: any) {
    const selectedTableKey = marker.tableKey
    const referenceKey = marker.referenceId
    this.goToDivId = referenceKey
    this.selectedRef = _.first(
      this.searchTableResult[selectedTableKey].data.filter(
        (reference: any) => reference.id === referenceKey
      )
    )
    this.searchTableResult.forEach((table: any, tableKey: number) => {
      if (this.prevSelectedRefKey === referenceKey) {
        table.headerHide = false
      } else {
        table.headerHide = tableKey !== selectedTableKey
      }
      for (let index = 0; index < table.data.length; index++) {
        if (this.prevSelectedRefKey === referenceKey) {
          if (table.data[index].id !== referenceKey) {
            table.data[index].cloudy = !table.data[index].cloudy
          } else {
            table.data[index].cloudy = false
            table.data[index].hide = false
          }
        } else {
          if (table.data[index].id !== referenceKey) {
            table.data[index].cloudy = true
          } else {
            table.data[index].cloudy = false
            table.data[index].hide = false
          }
        }
      }
    })
    this.prevSelectedRefKey = referenceKey
    this.updateMapPosition()
    return this.searchTableResult
  }

  updateMapPosition () {
    this.initMapMarkers()
    this.map.setView(
      [this.selectedRef.lan, this.selectedRef.lng]
    )
  }

  @Emit('changeSearchResult')
  handleSelectRef (selectedTableKey: number, referenceKey: number) {
    this.selectedRef = _.first(
      this.searchTableResult[selectedTableKey].data.filter(
        (reference: any) => reference.id === referenceKey
      )
    )
    this.selectedTableColor = this.searchTableResult[selectedTableKey].color
    this.searchTableResult.forEach((table: any, tableKey: number) => {
      if (this.prevSelectedRefKey === referenceKey) {
        table.headerHide = false
      } else {
        table.headerHide = tableKey !== selectedTableKey
      }
      for (let index = 0; index < table.data.length; index++) {
        if (this.prevSelectedRefKey === referenceKey) {
          if (table.data[index].id !== referenceKey) {
            table.data[index].cloudy = !table.data[index].cloudy
          } else {
            table.data[index].cloudy = false
            table.data[index].hide = false
          }
        } else {
          if (table.data[index].id !== referenceKey) {
            table.data[index].cloudy = true
          } else {
            table.data[index].cloudy = false
            table.data[index].hide = false
          }
        }
      }
    })
    this.searchTableResult.forEach((table: any) => {
      let globalIndex = 1
      table.data.forEach((reference: any) => {
        reference.tableId = !reference.hide ? globalIndex++ : '-'
        //! table.fold && !reference.hide ? globalIndex++ : "-";
      })
    })
    this.initialResult = JSON.parse(JSON.stringify(this.searchTableResult))
    this.prevSelectedRefKey = referenceKey
    this.updateMapPosition()
    return this.searchTableResult
  }

  // Draw radius for markers in the map
  addRadius () {
    let radius = 0
    this.radius !== '' ? (radius = parseFloat(this.radius)) : (radius = 0)
    this.drawCircles(radius)
  }

  drawCircles (radius: number) {
    this.removeCircles()
    const circles: any = []
    circles.push(
      (L.marker as any)([this.cordinates.latitude, this.cordinates.longitude], {
        icon: L.divIcon({
          className: 'custom-div-icon',
          html: `<div class='marker-pin'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <path fill="#3D60D6" fill-rule="evenodd" d="M14.92 29.435C6.976 17.915 5.5 16.734 5.5 12.5 5.5 6.701 10.201 2 16 2s10.5 4.701 10.5 10.5c0 4.234-1.475 5.416-9.42 16.935a1.313 1.313 0 0 1-2.16 0zM16 16.875a4.375 4.375 0 1 0 0-8.75 4.375 4.375 0 0 0 0 8.75z"/>
                  </svg>
                </div>`,
          iconSize: [30, 42],
          iconAnchor: [15, 42]
        })
      })
    )
    if (radius !== 0) {
      circles.push(
        (L.circle as any)(
          [this.cordinates.latitude, this.cordinates.longitude],
          {
            fillOpacity: 0,
            radius
          }
        )
      )
    }
    this.circlesLayer = L.layerGroup(circles)
    this.circlesLayer.addTo(this.map)
    this.map.panTo(
      new L.LatLng(this.cordinates.latitude, this.cordinates.longitude)
    )
  }

  removeCircles () {
    if (this.circlesLayer !== null) {
      this.circlesLayer.clearLayers()
    }
  }

  // Draw Isochrone for markers in the map
  async addIsochrone () {
    this.polygonOverlayLayer.addTo(this.map)
    const client = new tgm.TargomoClient(
      'westcentraleurope',
      'OYRNCG8HKY0C7BGT5QQE223725479'
    )
    // Coordinates to center the map
    const center = [this.cordinates.latitude, this.cordinates.longitude]
    // polygons time rings
    let travelTimes: any
    let travelDistance: any
    if (this.displayType === 'time') {
      switch (this.travelMode) {
        case 'walk':
          travelTimes = {
            300: this.isochroneScaleColors[0],
            600: this.isochroneScaleColors[1],
            900: this.isochroneScaleColors[2]
          }
          break
        case 'bike':
          travelTimes = {
            600: this.isochroneScaleColors[0],
            900: this.isochroneScaleColors[1],
            1200: this.isochroneScaleColors[2]
          }
          break
        case 'car':
          travelTimes = {
            600: this.isochroneScaleColors[0],
            1200: this.isochroneScaleColors[1],
            1800: this.isochroneScaleColors[2]
          }
          break
        case 'transit':
          travelTimes = {
            600: this.isochroneScaleColors[0],
            1200: this.isochroneScaleColors[1],
            1800: this.isochroneScaleColors[2]
          }
          break

        default:
          break
      }
    } else {
      switch (this.travelMode) {
        case 'walk':
          travelDistance = {
            300: this.isochroneScaleColors[0],
            500: this.isochroneScaleColors[1],
            1000: this.isochroneScaleColors[2]
          }
          break
        case 'bike':
          travelDistance = {
            3000: this.isochroneScaleColors[0],
            5000: this.isochroneScaleColors[1],
            10000: this.isochroneScaleColors[2]
          }
          break
        case 'car':
          travelDistance = {
            10000: this.isochroneScaleColors[0],
            20000: this.isochroneScaleColors[1],
            30000: this.isochroneScaleColors[2]
          }
          break
          /* case "transit":
          travelDistance = [10000, 20000, 30000];
          break; */

        default:
          break
      }
    }

    // define the starting point
    const sources = [{ id: 0, lat: center[0], lng: center[1] }]

    // Add markers for the sources on the map.
    const centerMarker: Array<any> = []
    sources.forEach(source => {
      centerMarker.push(
        L.marker([source.lat, source.lng], {
          icon: L.divIcon({
            className: 'custom-div-icon',
            html: `<div class='marker-pin'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                          <path fill="#3D60D6" fill-rule="evenodd" d="M14.92 29.435C6.976 17.915 5.5 16.734 5.5 12.5 5.5 6.701 10.201 2 16 2s10.5 4.701 10.5 10.5c0 4.234-1.475 5.416-9.42 16.935a1.313 1.313 0 0 1-2.16 0zM16 16.875a4.375 4.375 0 1 0 0-8.75 4.375 4.375 0 0 0 0 8.75z"/>
                        </svg>
                      </div>`,
            iconSize: [30, 42],
            iconAnchor: [15, 42]
          })
        })
      )
    })
    this.centreMarkLayer = L.layerGroup(centerMarker)
    this.centreMarkLayer.addTo(this.map)

    const mode = this.travelMode
    const displayType = this.displayType

    const options = {
      travelType: mode,
      maxEdgeWeight: 40000,
      travelEdgeWeights:
          displayType === 'time'
            ? Object.keys(travelTimes)
            : Object.keys(travelDistance),
      transitMaxTransfers: mode === 'transit' ? 5 : null,
      edgeWeight: displayType,
      serializer: 'json'
    }

    // get the polygons
    const polygons = await client.polygons.fetch(sources, options)
    // add polygons to overlay
    this.polygonOverlayLayer.setData(polygons)
    this.polygonOverlayLayer.setColors(displayType === 'time' ? travelTimes : travelDistance)
    // calculate bounding box for polygons
    const bounds = polygons.getMaxBounds()
    // zoom to the polygon bounds
    this.map.fitBounds(
      new (L.latLngBounds as any)(bounds.northEast, bounds.southWest)
    )

    this.isochroneScales = getIsochroneScales(
      this.travelMode,
      this.displayType,
      travelTimes,
      travelDistance
    )
  }

  removeIsochrne () {
    if (this.polygonOverlayLayer !== null) {
      this.polygonOverlayLayer.removeFrom(this.map)
    }
    if (this.centreMarkLayer !== null) {
      this.centreMarkLayer.removeFrom(this.map)
    }
  }

  @Emit('changeSearchCriteria')
  handleSearch (search: any) {
    Object.keys(search).forEach(
      key => (search[key] === null || search[key] === '' || (Array.isArray(search[key]) && search[key].length === 0)) && delete search[key]
    )
    this.handleSearchCriteria(search)
    return search
  }

  @Emit('changeSearchResult')
  async handleSearchCriteria (search: any) {
    const searchCondition = search
    this.searchCondition = searchCondition
    let searchedReferences: any = []
    await listReferenceService
      .searchReferences(searchCondition)
      .then((res: any) => {
        searchedReferences = res
      })
    if (this.$route.params.id) {
      const savedResults = this.saveSearch.result
      savedResults.forEach((category: any) => {
        category.data.forEach((item: any) => {
          const relatedRef = searchedReferences.find((ref: any) => ref.id === item.id)
          if (relatedRef) {
            relatedRef.rating = item.rating
          }
        })
      })
    }
    searchedReferences = _.orderBy(searchedReferences, ['dateDeffetBail'], ['asc'])
    searchedReferences = _.map(searchedReferences, function (element: any, index: number) {
      return _.extend({}, element, { hide: false }, { tableId: index }, { cloudy: false })
    })
    let conditionTypeValeur = searchCondition.typeValeur
    if (searchedReferences.length === 1 && searchCondition.ficheId) {
      conditionTypeValeur = searchedReferences[0].typeValeur
    }
    if (conditionTypeValeur === 1) {
      this.searchTableResult = [
        {
          fold: false,
          typeTransaction: conditionTypeValeur * 4 - 3,
          color: '#810D00',
          headerHide: false,
          data: searchedReferences.filter(
            (item: any) =>
              item.typeTransaction === conditionTypeValeur * 4 - 3
          )
        },
        {
          fold: false,
          typeTransaction: conditionTypeValeur * 4 - 2,
          color: '#778F1E',
          headerHide: false,
          data: searchedReferences.filter(
            (item: any) =>
              item.typeTransaction === conditionTypeValeur * 4 - 2
          )
        },
        {
          fold: false,
          typeTransaction: conditionTypeValeur * 4 - 1,
          color: '#1C3283',
          headerHide: false,
          data: searchedReferences.filter(
            (item: any) =>
              item.typeTransaction === conditionTypeValeur * 4 - 1
          )
        },
        {
          fold: false,
          typeTransaction: conditionTypeValeur * 4,
          color: '#7F7F7F',
          headerHide: false,
          data: searchedReferences.filter(
            (item: any) => item.typeTransaction === conditionTypeValeur * 4
          )
        },
        {
          fold: false,
          typeTransaction: null,
          color: '#000000',
          headerHide: false,
          data: searchedReferences.filter(
            (item: any) => item.typeTransaction === null
          )
        }
      ]
    } else {
      this.searchTableResult = [
        {
          fold: false,
          typeTransaction: conditionTypeValeur * 4 - 3,
          color: '#810D00',
          headerHide: false,
          data: searchedReferences.filter(
            (item: any) =>
              item.typeTransaction === conditionTypeValeur * 4 - 3 && item.prixDi !== item.prixHd
          )
        },
        {
          fold: false,
          typeTransaction: conditionTypeValeur * 4 - 2,
          color: '#778F1E',
          headerHide: false,
          data: searchedReferences.filter(
            (item: any) =>
              item.typeTransaction === conditionTypeValeur * 4 - 2 && item.prixDi !== item.prixHd
          )
        },
        {
          fold: false,
          typeTransaction: conditionTypeValeur * 4 - 1,
          color: '#1C3283',
          headerHide: false,
          data: searchedReferences.filter(
            (item: any) =>
              item.typeTransaction === conditionTypeValeur * 4 - 1 && item.prixDi !== item.prixHd
          )
        },
        {
          fold: false,
          typeTransaction: conditionTypeValeur * 4,
          color: '#7F7F7F',
          headerHide: false,
          data: searchedReferences.filter(
            (item: any) => item.typeTransaction === conditionTypeValeur * 4 && item.prixDi !== item.prixHd
          )
        },
        {
          fold: false,
          typeTransaction: null,
          color: '#000000',
          headerHide: false,
          data: searchedReferences.filter(
            (item: any) => item.typeTransaction === null || item.prixDi === item.prixHd
          )
        }
      ]
    }
    this.searchTableResult.forEach((table: any) => {
      let globalIndex = 1
      table.data.forEach((reference: any) => {
        reference.tableId = globalIndex++
      })
    })
    this.initialResult = JSON.parse(JSON.stringify(this.searchTableResult))
    searchCondition.radius && searchCondition.radius !== ''
      ? (this.searchRadius = parseFloat(searchCondition.radius))
      : (this.searchRadius = 0)
    await this.initMapMarkers()
    return this.searchTableResult
  }

  @Emit('changeMapStatus')
  async initMapMarkers () {
    this.removeMarkers()
    const self = this
    this.markers = []
    this.searchTableResult.forEach(async (table: any, tableKey: any) => {
      table.data.forEach(async (reference: any) => {
        let iconColor = ''
        const show: boolean = table.fold ? false : !(reference.hide)
        switch (tableKey) {
          case 0:
            iconColor = '#810D00'
            break
          case 1:
            iconColor = '#778F1E'
            break
          case 2:
            iconColor = '#1C3283'
            break
          case 3:
            iconColor = '#7F7F7F'
            break
          case 4:
            iconColor = '#000000'
            break

          default:
            break
        }
        let cordinates = {}
        const lat = reference.lan
        const lon = reference.lng
        cordinates = {
          show,
          cloudy: reference.cloudy,
          iconColor,
          tableKey,
          referenceKey: reference.tableId,
          referenceId: reference.id,
          latitude: lat,
          longitude: lon
        }
        self.markers.push(cordinates)
      })
    })
    this.isInitialMap = false
    this.drawMarkers()
    return this.isInitialMap
  }

  updateSearchAddress (item: ReferenceAddress) {
    this.isNotExactAddress = true
    if (
      item.housenumber !== '' &&
      item.street !== '' &&
      item.locality !== ''
    ) {
      this.cordinates.latitude = item.lat
      this.cordinates.longitude = item.lon
      this.isNotExactAddress = false
    } else {
      delete this.searchCondition.radius
    }
  }

  handleSelectViewAddress (item: ReferenceAddress) {
    if (this.searchMarker) {
      this.searchMarker.remove()
    }
    this.cordinates.latitude = item.lat
    this.cordinates.longitude = item.lon
    let zoom = 7
    switch (item.layer) {
      case 'macroregion':
      case 'region':
      case 'macrocounty':
        zoom = 11
        break
      case 'locality':
      case 'localadmin':
        zoom = 12
        break
      case 'borough':
      case 'neighbourhoods':
      case 'coarse':
      case 'postalcode':
        zoom = 15
        break
      case 'venue':
      case 'address':
      case 'street':
        zoom = 19
        break
      default:
        break
    }
    this.map.setView(
      [this.cordinates.latitude, this.cordinates.longitude],
      zoom
    )
    this.searchMarker = L.marker([this.cordinates.latitude, this.cordinates.longitude], {
      icon: L.divIcon({
        className: 'custom-div-icon',
        html: `<div class='marker-pin' style="opacity: 1">
                                <?xml version="1.0" encoding="utf-8"?>
                                <!-- Generator: Adobe Illustrator 24.1.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 512 512" xml:space="preserve" height="35">
                                    <style type="text/css">
                                        .searchMap{fill:#409eff;}
                                    </style>
                                    <g>
                                        <g>
                                          <path class="searchMap" d="M256,0c-70.6,0-128,57.4-128,128c0,67,51.7,122,117.3,127.5v245.9c0,5.9,4.8,10.7,10.7,10.7
                                            s10.7-4.8,10.7-10.7V255.5C332.3,250,384,195,384,128C384,57.4,326.6,0,256,0z M256,64c-35.3,0-64,28.7-64,64
                                            c0,5.9-4.8,10.7-10.7,10.7s-10.7-4.8-10.7-10.7c0-47.1,38.3-85.3,85.3-85.3c5.9,0,10.7,4.8,10.7,10.7C266.7,59.2,261.9,64,256,64z
                                            "/>
                                        </g>
                                    </g>
                                </svg>
                            </div>`,
        iconSize: [30, 42],
        iconAnchor: [15, 42]
      })
    })
    this.searchMarker.addTo(this.map)
    this.updateSearchAddress(item)
  }

  @Emit('changeSearchResult')
  updatedSearchResult (updatedResult: any) {
    this.searchTableResult = updatedResult
    this.searchTableResult.forEach((table: any) => {
      let globalIndex = 1
      table.data.forEach((reference: any) => {
        reference.tableId = !reference.hide ? globalIndex++ : '-'
      })
    })
    this.initMapMarkers()
    return this.searchTableResult
  }

  getTransactionLabel (typeTransaction: Number) {
    let label = ''
    const target: any = this.typeTransaction.filter(
      (item: any) => item.num === typeTransaction
    )
    target.length > 0 ? (label = target[0].label) : (label = '')

    return label
  }

  async getFullSearchResult (searchResult: any) {
    const refIdList: any = []
    searchResult.forEach((table: any) => {
      table.data.forEach((data: any) => {
        refIdList.push(data.id)
      })
    })
    await listReferenceService
      .getSavedReferences(refIdList)
      .then((res: any) => {
        this.loading = true
        searchResult.forEach((table: any) => {
          table.data.forEach((data: any, index: number) => {
            table.data[index] = _.merge(
              data,
              _.first(
                res.filter((element: any) => {
                  element.rating = data.rating || null
                  return element.id === data.id
                })
              )
            )
          })
        })
        // this.searchTableResult = searchResult;
        this.searchTableResult = JSON.parse(JSON.stringify(searchResult))
        this.initialResult = JSON.parse(JSON.stringify(searchResult))
        this.loading = false
      })
    this.initMapMarkers()
  }

  handleRefreshSearchCondition () {
    this.searchCondition = {
      typeLocal: null,
      typeValeur: 1,
      natureCapital: []
    }
  }

  changeRatingSavedSearch (referenceId: Number, rating: Number, key: any) {
    this.initialResult[key].data.find((ref: any) => ref.id === referenceId).rating = rating
    this.$emit('changeRatingSavedSearch', referenceId, rating, key)
  }
}
