
import ContactFields from './ContactFields.vue'
import { Component, Vue } from 'vue-property-decorator'
import { quoteService } from '@/services/quote.service'

@Component({
  subscriptions () {
    return {
      showContactModal$: quoteService.onChangeShowContactModal
    }
  },
  components: {
    ContactFields
  }
})
export default class ContactDialog extends Vue {
  openContactDialog (): void {
    quoteService.showContactModal(true)
  }

  closeContactDialog (): void {
    quoteService.showContactModal(false)
  }

  submitForm () {
    (this.$refs.contactFields as any).submitForm()
  }
}
