
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { quoteService, QuoteAddress } from '@/services/quote.service'
import { listReferenceService, ReferenceAddress } from '@/services/listReference.service'
    // The @Component decorator indicates the class is a Vue component
    @Component({

    })

export default class QuoteAddressComponent extends Vue {
        @Prop({ default: false }) updateable: boolean
        @Prop({ default: 0 }) index: number
        @Prop({ default: new QuoteAddress() }) model: QuoteAddress
        address: string = ''

        @Watch('model')
        onModelChanged (val: any) {
          if (val) {
            this.getAddress()
          }
        }

        mounted () {
          this.getAddress()
        }

        getAddress () {
          let label = ''
          if (this.model.buildingNumber) {
            label += this.model.buildingNumber + ' '
          }
          if (this.model.street) {
            label += this.model.street + ', '
          }
          if (this.model.city) {
            label += this.model.city
          }
          this.address = label
        }

        deleteAddress (index: number): void {
          quoteService.deleteAddress(index)
        }

        updateAddress (): void {
          quoteService.updateAddress()
        }

        querySearch (queryString: string, cb: Function) {
          if (!queryString) return
          listReferenceService.getReferenceAddresse(queryString).then(res => {
            cb(res)
          })
        }

        handleSelect (item: ReferenceAddress) {
          this.model.buildingNumber = item.housenumber
          this.model.street = item.street
          this.model.zip = item.postalcode
          this.model.city = item.locality
          this.model.lat = item.lat.toString()
          this.model.lon = item.lon.toString()
          this.updateAddress()
        }

        isValidAddress (item: ReferenceAddress): boolean {
          if (item.housenumber && item.street && item.locality && item.lat && item.lon) {
            return true
          }
          return false
        }

        previewAddress (): void {
          this.$emit('preview')
        }
}
