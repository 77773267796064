
import { Component, Vue } from 'vue-property-decorator'

@Component({
  subscriptions (this: Vue) {
    return {}
  }
})
export default class DossierDialogs extends Vue {
  showingSelectType: boolean = false

  callback: any = {}

  showSelectTypeDialog (callback: any) {
    this.showingSelectType = true
    this.callback = callback
  }

  selectType (type: string) {
    this.showingSelectType = false
    this.callback(type)
  }
}
