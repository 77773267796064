
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormattedFloat from '../../utils/FormattedFloat.vue'
import FormattedNumber from '../../utils/FormattedNumber.vue'
import { baseBlockItems, locativeBlockItems } from '../blockItems'
import { performanceItemList } from '../itemList'
import { floatRule } from '../formRules'
import { referenceService } from '@/services/listReference.service'
import { tap } from 'rxjs/operators'

@Component({
  subscriptions (this: any) {
    return {
      value: referenceService.onChangeReference.pipe(tap(() => {
        this.performanceItemList = [...this.performanceItemList]
      })),
      isMigratedRef: referenceService.onChangeIsMigratedRef
    }
  },
  components: {
    FormattedFloat,
    FormattedNumber
  }
})
export default class Performaces extends Vue {
  @Prop({ type: String })
    localLabel!: any

  @Prop({ type: Number })
    typeValeur!: any

  isMigratedRef = false
  value: any = {
    periodeCa: 0
  }

  floatRule = floatRule
  performanceItemList = performanceItemList

  checkDisplayCondition (title: string, condition: string) {
    if (this.isMigratedRef) {
      return true
    }
    const localLabel = this.localLabel
    let result = false
    switch (condition) {
      case 'base':
        result = baseBlockItems[localLabel] && !baseBlockItems[localLabel].includes(title)
        break
      case 'locative':
        result = this.typeValeur === 1 && locativeBlockItems[localLabel] && !locativeBlockItems[localLabel].includes(title)
        break
      default:
        break
    }
    return result
  }

  updateValues (item: any) {
    referenceService.updateRef(this.value, item.value)
  }
}
