
export default {
  data () {
    return {
      formInline: {
        input: ''
      },
      options: [{
        value: 'Adresse',
        label: 'Adresse'
      }, {
        value: 'Ref',
        label: 'Réf.'
      }, {
        value: 'Document',
        label: 'Document'
      }, {
        value: 'Devis',
        label: 'Devis'
      }, {
        value: 'Contact',
        label: 'Contact'
      }],
      value: '',
      checked: '',
      input: ''
    }
  }
}
