
import { Component, Prop, Vue } from 'vue-property-decorator'
import { quoteService, Quote, QuoteMission } from '@/services/quote.service'
import { userService, User } from '@/services/user.service'
import { MISSION_EXPERT_OPTIONS, MISSION_LOCAL_OPTIONS } from '@/services/constants.service'
import FormattedFloat from '../utils/FormattedFloat.vue'
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions () {
    return {
      quote$: quoteService.onChangeQuote,
      user$: userService.onChange
    }
  },
  components: {
    FormattedFloat
  }
})

export default class QuoteMissionComponent extends Vue {
  @Prop({ default: false }) updateable: boolean
  @Prop({ default: 0 }) index: number
  @Prop({ default: new QuoteMission() }) model: QuoteMission
  @Prop({ default: false }) isLegalFolder: boolean

  expertOptions: any[] = []
  localOptions: any[] = []
  filteredExpertOptions: any[] = []
  filteredLocalOptions: any[] = []
  quote$: Quote = new Quote()
  user$: User = new User()

  created () {
    this.expertOptions = JSON.parse(JSON.stringify(MISSION_EXPERT_OPTIONS))
    this.localOptions = JSON.parse(JSON.stringify(MISSION_LOCAL_OPTIONS))
    this.filteredExpertOptions = this.expertOptions
    this.filteredLocalOptions = this.localOptions
  }

  filterExpertOptions (query: string) {
    this.filteredExpertOptions = quoteService.filterOptions(query, 19, this.expertOptions)
  }

  filterLocalOptions (query: string) {
    this.filteredLocalOptions = quoteService.filterOptions(query, 31, this.localOptions)
  }

  deleteMission (index: number): void {
    quoteService.deleteMission(index)
  }

  addMission (): void {
    quoteService.addMission(this.quote$.missions.length - 1)
  }

  changeExpertType (): void {
    quoteService.updateMission()
  }

  changeLocalType (): void {
    quoteService.updateMission()
  }

  changeAmount (): void {
    quoteService.updateMission()
  }

  changeOption (): void {
    quoteService.updateMission()
  }
}
