<template>
  <el-form class="el-form--custom d-flex">
    <perfect-scrollbar>
      <div class="filterContent">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Adresse">
              <el-autocomplete
                v-model="filters.address"
                placeholder="Adresse"
                style="width: 100%"
                :clearable="true"
                :fetch-suggestions="querySearch"
                value-key="label"
                :trigger-on-focus="false"
                @select="handleSelectAddress"
                @clear="clearPelias" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Enseigne Locataire">
              <el-input
                v-model="filters.enseigneLocataire"
                name
                placeholder="Enseigne Locataire"
                :clearable="true" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Type de valeur">
              <el-select
                v-model="filters.typeValeur"
                placeholder="Type de valeur"
                popper-class="selectSearch"
                :clearable="true"
                filterable
                value-key="num">
                <el-option
                  v-for="(item, index) in typeValeurLists"
                  :key="index"
                  :label="item.label"
                  :value="item.num" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Type de local">
              <el-select
                v-model="filters.typeLocal"
                placeholder="Type de local"
                popper-class="selectSearch"
                filterable
                :filter-method="query => filterOptions(query, 'typeLocal')"
                value-key="num"
                :clearable="true"
                @focus="handleFocusList('typeLocal')">
                <el-option-group
                  v-for="group in filteredTypeLocalItems"
                  :key="group.label"
                  :label="group.label">
                  <el-option
                    v-for="item in group.options"
                    :key="item.num"
                    :label="item.label"
                    :value="item.num" />
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Type de transaction">
              <el-select
                v-model="filters.typeTransaction"
                placeholder="Type de transaction"
                popper-class="selectSearch"
                filterable
                :filter-method="query => filterOptions(query, 'typeTransaction')"
                value-key="num"
                :clearable="true"
                @focus="handleFocusList(true, 'typeTransaction')">
                <el-option-group
                  v-for="group in typeTransactionListsGroup"
                  :key="group.label"
                  :label="group.label">
                  <el-option
                    v-for="item in group.options"
                    :key="item.num"
                    :label="item.label"
                    :value="item.num" />
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item>
              <label for="">Activité</label>
              <el-select
                v-model="filters.activites"
                placeholder="Activité"
                popper-class="selectSearch"
                :clearable="true"
                filterable
                :filter-method="query => filterOptions(query, 'activities')"
                value-key="num"
                multiple
                @focus="handleFocusList('activities')">
                <el-option-group
                  v-for="group in filteredActivites"
                  :key="group.label"
                  :label="group.label">
                  <el-option
                    v-for="item in group.options"
                    :key="item.num"
                    :label="item.label"
                    :value="item.num" />
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <label class="">{{ datePickerLabel }}</label>
              <div class="d-flex align-items-center justify-content-start status">
                <el-date-picker
                  v-model="filters.dateMini"
                  type="date"
                  placeholder="Début" />
                <label class="ml-3 mr-3">au</label>
                <el-date-picker
                  v-model="filters.dateMaxi"
                  type="date"
                  placeholder="Fin" />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </perfect-scrollbar>
    <div class="btn-container">
      <div class="d-flex align-items-center justify-content-center">
        <el-button
          class="btn-black"
          @click="searchGlobalItems">
          Rechercher ({{ globalItemsTotal$ }})
        </el-button>
        <el-button @click="() => $emit('reset-filters')">
          Réinitialiser
        </el-button>
      </div>
    </div>
  </el-form>
</template>

<script>
import { searchGlobalItemsService } from '@/services/searchGlobalItems.service'
import { listReferenceService } from '@/services/listReference.service'
import { userService } from '@/services/user.service'
import { PageParams } from '@/models/ged.model'
import { authService } from '@/services/auth.service'
import {
  filterGroupList,
  generateTypeLocalGroup,
  generateActivitiesGroup,
  generateTypeTransactionGroup
} from '@/utils/functions'
export default {
  subscriptions () {
    return {
      globalItemsTotal$: searchGlobalItemsService.onChangeGlobalItemsTotal,
      filtersCount$: searchGlobalItemsService.onChangeFiltersCount,
      filters: searchGlobalItemsService.onChangeReferenceFilters,
      users$: userService.onChangeUsers,
      authUser$: authService.onChange
    }
  },
  data () {
    return {
      rules: {},
      typeLocalLists: [],
      typeLocalListsGroup: [],
      filteredTypeLocalItems: [],
      filteredActivites: [],
      filteredTypeTransaction: [],
      typeValeurLists: [],
      activitesLists: [],
      activitesListsGroup: [],
      pageParams$: PageParams,
      authUser$: {},
      userName: '',
      users$: [],
      typeTransactionLists: [],
      typeTransactionListsGroup: []
    }
  },
  computed: {
    datePickerLabel () {
      if (this.filters.typeValeur === 2) {
        return 'Date de vente'
      }
      return 'Date d’effet du bail début'
    }

  },
  created () {
    // this.filters.creatorId = this.authUser$.id;
    // this.userName = this.authUser$.firstname + ' ' + this.authUser$.lastname;
    listReferenceService.getListReference().then(res => {
      this.typeLocalLists = res.filter((item) => item.type === 1)
      this.typeLocalListsGroup = generateTypeLocalGroup(this.typeLocalLists)
      this.activitesLists = res.filter((item) => item.type === 3)
      this.activitesListsGroup = generateActivitiesGroup(this.activitesLists)
      this.typeValeurLists = res.filter((item) => item.type === 11)
      this.typeTransactionLists = res.filter((item) => item.type === 7 || item.type === 4)
      this.typeTransactionListsGroup = generateTypeTransactionGroup(this.typeTransactionLists)
    })
  },
  methods: {
    handleTypeLocalItems () {
      this.filteredTypeLocalItems = JSON.parse(JSON.stringify(this.typeLocalListsGroup))
    },
    handleActivites () {
      this.filteredActivites = JSON.parse(JSON.stringify(this.activitesListsGroup))
    },
    handleTypeTransaction () {
      this.filteredTypeTransaction = JSON.parse(JSON.stringify(this.typeTransactionListsGroup))
    },
    searchGlobalItems () {
      searchGlobalItemsService.setIsLoading(true)
      this.$emit('search', this.filters)
    },

    querySearch (queryString, cb) {
      if (!queryString) return
      listReferenceService.getReferenceAddresse(queryString).then(res => {
        cb(res)
      })
    },
    handleSelectAddress (peliasAddress) {
      this.filters.peliasAddress = peliasAddress
    },
    clearPelias () {
      delete this.filters.peliasAddress
    },
    filterUsers (query) {
      if (query != null && query.length > 0) {
        userService.getUsersByKey(query)
      } else {
        userService.getUsers()
      }
      // this.userName = query;
      // this.filters.creatorId = null;
    },
    changeUser (val) {
      const user = this.users$.find((user) => user.id === val)
      if (user) {
        this.userName = user.prenom + ' ' + user.nom
        this.filters.creatorId = user && user.id ? user.id : null
      }
    },
    clearUserCriteria () {
      this.userName = ''
      delete this.filters.creatorId
    },
    filterOptions (query, type) {
      if (type === 'typeLocal') {
        this.filteredTypeLocalItems = filterGroupList(query, this.typeLocalListsGroup)
      } else if (type === 'activities') {
        this.filteredActivites = filterGroupList(query, this.activitesListsGroup)
      } else if (type === 'typeTransaction') {
        this.filteredTypeTransaction = filterGroupList(query, this.typeTransactionListsGroup)
      }
    },
    handleFocusList (type) {
      if (type === 'typeLocal') {
        this.filteredTypeLocalItems = JSON.parse(JSON.stringify(this.typeLocalListsGroup))
      } else if (type === 'activities') {
        this.filteredActivites = JSON.parse(JSON.stringify(this.activitesListsGroup))
      } else if (type === 'typeTransaction') {
        this.filteredTypeTransaction = JSON.parse(JSON.stringify(this.typeTransactionListsGroup))
      }
    }

  }
}
</script>

<style>
  </style>
