<template>
  <div class="auth-wrap d-flex align-items-center pb-5 justify-content-center text-dark">
    <div class="w-100 mb-5 pb-5">
      <el-row class="px-3 d-flex w-100 justify-content-center">
        <el-col :md="9">
          <el-alert
            class="mb-3"
            :title="warning"
            type="warning alert"
            show-icon
            :closable="false" />
          <el-form
            ref="resetPasswordForm"
            :model="formData"
            label-width="240px"
            label-position="left"
            :rules="formRules">
            <el-form-item
              label="mot de passe"
              prop="password"
              class="mb-4 pb-2">
              <el-input
                v-model="formData.password"
                class="block"
                type="password"
                autocomplete="off" />
            </el-form-item>
            <el-form-item
              label="confirmation du mot de passe"
              prop="confirmPassword"
              class="mb-4 pb-2">
              <el-input
                v-model="formData.confirmPassword"
                class="block"
                type="password"
                autocomplete="off" />
            </el-form-item>
            <div class="d-flex justify-content-around mt-5">
              <el-button
                :loading="loading"
                type="primary"
                class="el-button status-btn el-button--default is-round"
                @click="submitForm('resetPasswordForm')">
                Réinitialiser mon mot de passe
              </el-button>
            </div>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { authService } from '@/services/auth.service'
import { userService } from '@/services/user.service'

export default {
  name: 'ResetPassword',
  subscriptions () {
    return {
      user: authService.onChange
    }
  },
  data () {
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Le mot de passe doit être renseigné'))
      } else if (value.length < 8) {
        callback(new Error('Le mot de passe doit contenir au moins 8 caractères'))
      } else if (!this.hasUpperCase(value)) {
        callback(new Error('Le mot de passe doit contenir au moins une majuscule'))
      } else {
        this.$refs.resetPasswordForm.validateField('confirmPassword')
        callback()
      }
    }
    const validateConfirmPassword = (rule, value, callback) => {
      if (value !== this.formData.password) {
        callback(new Error('Les mots de passe entrés ne sont pas identiques'))
      } else {
        callback()
      }
    }

    return {
      loading: false,
      warning: 'Veuillez entrer un nouveau mot de passe',
      formData: {
        password: '',
        conformPassword: ''
      },
      formRules: {
        password: [
          { validator: validatePassword, trigger: 'blur' }
        ],
        confirmPassword: [
          { validator: validateConfirmPassword, trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    if (this.user) {
      this.$router.push({ path: '/' })
    }
    if (this.$route.params.token) {
      userService.validateResetPasswordToken(this.$route.params.token).then(
        () => {
          // don't need any action
        },
        () => {
          this.$router.replace('/connexion')
        }
      )
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true
          userService.changePassword(this.$route.params.token, this.formData.password).then(
            () => {
              this.loading = false
              this.$message({
                message: 'Mot de passe réinitialisé',
                type: 'success',
                offset: 65
              })
              this.$router.replace('/connexion')
            },
            () => {
              this.loading = false
              this.$message({
                message: 'La sauvegarde a échoué!, Veuillez réessayer plus tard.',
                type: 'error',
                offset: 65
              })
            }
          )
        }
      })
    },
    hasUpperCase (str) {
      return (/[A-Z]/.test(str))
    }
  }
}
</script>
