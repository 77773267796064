
import { Component, Vue } from 'vue-property-decorator'
import { authService } from '@/services/auth.service'
import { User, USER_RIGHTS, userService } from '@/services/user.service'
import { Role, roleService } from '@/services/role.service'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

@Component({
  subscriptions (this: Vue) {
    return {
      users: userService.onChangeUsers,
      activeUserIndex$: userService.onChangeActiveUserIndex,
      loading: userService.onLoading,
      roles: roleService.onChange
    }
  },
  components: {
    ClipLoader
  }
})
export default class Roles extends Vue {
  users: User[] = []
  roles: Role[] = []
  loading: boolean = true
  isFixesIndex: boolean = false
  form = {
    sizePage: 30,
    currentPage: 1,
    nom: '',
    prenom: '',
    email: '',
    roles: [],
    sortProp: 'nom',
    sortOrder: 'ascending'
  }

  created () {
    const user = authService.userValue
    if (!userService.hasRight(user, USER_RIGHTS.USER_RIGHTS)) {
      this.$router.replace('/')
    } else {
      userService.getUsers()
      roleService.getRoles()
    }
  }

  tableData () {
    return this.users
  }

  getRoles (user: any) {
    if (user) {
      const roles = user.roles.map((roleId: number) => {
        const role = this.roles.find(role => role.id === roleId)
        return role && role.nom
      })
      return roles.join(', ')
    }

    return ''
  }

  navigateToNewUserForm () {
    this.$router.push('/user')
  }

  navigateToUpdateUserForm (user: any) {
    this.$router.push({ name: 'user', params: { id: user.id } })
  }

  filterResults () {
    userService.getUsers(
      this.form.nom,
      this.form.prenom,
      this.form.email,
      this.form.roles
    )
  }

  resetFilters () {
    this.form = {
      sizePage: 30,
      currentPage: 1,
      nom: '',
      prenom: '',
      email: '',
      roles: [],
      sortProp: 'nom',
      sortOrder: 'ascending'
    }
    this.filterResults()
  }

  handleCurrentChange ({ id }: any) {
    this.$router.push({
      name: 'user',
      params: { id }
    })
  }

  handleSortChange (prop: string) {
    this.form.sortProp = prop
    this.form.sortOrder = this.form.sortOrder === 'ascending' ? 'descending' : 'ascending'
    this.onSubmitControl()
  }

  changeIndex (index = null) {
    if (!this.isFixesIndex) {
      userService.activeUserIndex(index)
    }
  }

  onSubmitControl () {
    userService.getUsersByCriteria(this.form)
    userService.onChangeUsers.subscribe((item) => {
      this.users = item
    })
  }

  updateRow (id: any) {
    window.open(this.$router.resolve({ path: '/user/' + id }).href, '_blank')
  }
}
