import { apiClient } from '@/services/api.client'
import { BehaviorSubject, Observable } from 'rxjs'
import { router } from '@/router'
import { User } from './user.service'
import { settledFolderService } from './settledFolder.service'
import { legalFolderService } from './legalFolder.service'
import { quoteService } from './quote.service'

export class AppFile {
  id: number
  name: string
  created_on: string
  bucket_name: string
  type: string
  order: number
  selected: boolean
  disabled: boolean
  category: Category

  constructor (data?: any) {
    this.id = (data && data.id) || null
    this.created_on = (data && data.created_on) || ''
    this.bucket_name = (data && data.bucket_name) || ''
    this.type = (data && data.type) || ''
    this.order = (data && data.order) || 0
    this.selected = (data && data.selected) || false
    this.disabled = (data && data.disabled) || false
    this.category = (data && data.category) || null
  }
}

export class Category {
  id: number
  name: string
  parent: Category
  user: User
  createdAt: string
  updatedAt: string

  constructor (data?: any) {
    this.id = (data && data.id) || null
    this.name = (data && data.name) || null
    this.parent = (data && data.parent) || null
    this.user = (data && data.user) || null
    this.createdAt = (data && data.createdAt) || ''
    this.updatedAt = (data && data.updatedAt) || ''
  }
}

export class CategoryTree {
  id: number
  type: string
  subCategories: CategoryTree[]

  constructor (data?: any) {
    this.id = (data && data.id) || null
    this.type = (data && data.type) || null
    this.subCategories = (data && data.subCategories) || []
  }
}

class FileService {
  private readonly _files: BehaviorSubject<AppFile[]> = new BehaviorSubject<AppFile[]>([])
  private readonly _category: BehaviorSubject<Category> = new BehaviorSubject<Category>(new Category())
  private readonly _categoryTree: BehaviorSubject<CategoryTree[]> = new BehaviorSubject<CategoryTree[]>([])
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public onChange: Observable<AppFile[]> = this._files.asObservable()
  public onChangeCategory: Observable<Category> = this._category.asObservable()
  public onChangeTree: Observable<CategoryTree[]> = this._categoryTree.asObservable()
  public onChangeLoading: Observable<boolean> = this._loading.asObservable()

  get files (): AppFile[] {
    return this._files.getValue()
  }

  get categoryTree (): CategoryTree[] {
    return this._categoryTree.getValue()
  }

  public initFiles (files: AppFile[]): void {
    this._files.next(files)
  }

  public initCategory (): void {
    this._category.next(new Category())
  }

  public initCategoryTree (categoryTree: CategoryTree[]): void {
    this._categoryTree.next(categoryTree)
  }

  public async getPreviewFile (url: string): Promise<any> {
    return await apiClient.get(url).then(res => {
      return res
    }).catch((err) => {
      console.error(err)
      throw err
    })
  }

  public async uploadFile (file: File, type: string, categoryId: number): Promise<any> {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('type', type)
    formData.append('category-id', categoryId.toString())
    if (router.currentRoute.name === 'Quote') {
      formData.append('folder-type', 'quote')
    } else if (router.currentRoute.name === 'settled-folder') {
      formData.append('folder-type', 'settled-folder')
    } else if (router.currentRoute.name === 'legal-folder') {
      formData.append('folder-type', 'legal-folder')
    }
    formData.append('folder-id', router.currentRoute.params.id)
    await apiClient.post('file/upload', formData).then(res => {
      const files: AppFile[] = this.files
      files.push(res.data)
      this._files.next(files)
    })
  }

  public async saveConflictFile (file: File, type: string, id: string): Promise<any> {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('type', 'Random')
    formData.append('category-id', '6')
    if (type === 'quote') {
      formData.append('folder-type', 'quote')
    } else if (type === 'settledFolder') {
      formData.append('folder-type', 'settled-folder')
    } else if (type === 'legalFolder') {
      formData.append('folder-type', 'legal-folder')
    }
    formData.append('folder-id', id)
    return await apiClient.post('file/upload', formData)
  }

  public async deleteFile (id: number, type: string): Promise<void> {
    await apiClient.delete('file/' + id.toString())
  }

  public async changeOrder (newOrders: any[], type: string): Promise<void> {
    this._loading.next(true)
    await apiClient.post('file/order', {
      newOrders
    }).then(({ data }) => {
      const files: AppFile[] = this.files
      data.forEach((item: AppFile) => {
        const index: number = files.findIndex((file: AppFile) => file.id === item.id)
        if (index > -1) files[index] = item
      })
      this._files.next(files)
      this._loading.next(false)
    })
  }

  public changeCategoryTree (newTree: CategoryTree[]): void {
    const folderId: number = parseInt(router.currentRoute.params.id)
    if (router.currentRoute.name === 'Quote') {
      quoteService.saveCategoryTree(folderId, newTree).catch((err) => {
        throw err
      })
    } else if (router.currentRoute.name === 'settled-folder') {
      settledFolderService.saveCategoryTree(folderId, newTree).catch((err) => {
        throw err
      })
    } else if (router.currentRoute.name === 'legal-folder') {
      legalFolderService.saveCategoryTree(folderId, newTree).catch((err) => {
        throw err
      })
    }
  }

  public async saveCategory ({ id, name, user, parent }: any): Promise<void> {
    await apiClient.post('file-category', { id, name, user, parent }).then(res => {
      if (res.data) {
        const newCategory: Category = res.data
        if (!id) {
          const newTree: CategoryTree[] = this.categoryTree.map((item: CategoryTree) => this.addSubCategory(item, newCategory))
          this.changeCategoryTree(newTree)
        } else {
          const newTree: CategoryTree[] = this.editSubCategory(this.categoryTree, newCategory)
          this.changeCategoryTree(newTree)
        }
      }
    })
  }

  public async getCategoryById (categoryId: number): Promise<Category> {
    return await apiClient.get('file-category/' + categoryId.toString()).then(res => {
      const category: Category = new Category(res.data)
      this._category.next(category)
      return category
    })
  }

  public async deleteCategoryById (categoryId: number): Promise<void> {
    await apiClient.delete('file-category/' + categoryId.toString()).then(res => {
      if (res.data) {
        const newTree: CategoryTree[] = this.deleteSubCategory(this.categoryTree, categoryId)
        this.changeCategoryTree(newTree)
      }
    })
  }

  public addSubCategory (categoryTree: CategoryTree, newCategory: Category): CategoryTree {
    if (categoryTree.id === newCategory.parent.id) {
      categoryTree.subCategories.push({
        id: newCategory.id,
        type: newCategory.name,
        subCategories: []
      })
    } else {
      categoryTree.subCategories = categoryTree.subCategories.map((item: CategoryTree) => this.addSubCategory(item, newCategory))
    }

    return categoryTree
  }

  public editSubCategory (categories: CategoryTree[], newCategory: Category): CategoryTree[] {
    const index: number = categories.findIndex((item: CategoryTree) => item.id === newCategory.id)
    if (index < 0) {
      categories.map((item: CategoryTree) => {
        item.subCategories = this.editSubCategory(item.subCategories, newCategory)
        return item.subCategories
      })
    } else {
      categories[index].type = newCategory.name
    }

    return categories
  }

  public deleteSubCategory (categories: CategoryTree[], id: number): CategoryTree[] {
    const index: number = categories.findIndex((item: CategoryTree) => item.id === id)
    if (index < 0) {
      categories.map((item: CategoryTree) => {
        item.subCategories = this.deleteSubCategory(item.subCategories, id)
        return item.subCategories
      })
    } else {
      categories.splice(index, 1)
    }

    return categories
  }

  public getFilesByCategory (type: string, categoryId: number): AppFile[] {
    return this.files.filter(
      (file: AppFile) =>
        file.category && file.category.name === type && file.category.id === categoryId
    )
  }
}

export const fileService = new FileService()
