
import { Component, Prop, Vue } from 'vue-property-decorator'
import Judicial from './partial/Judicial.vue'
import QuoteContacts from '@/components/quote/QuoteContacts.vue'
import QuoteAddresses from '@/components/quote/QuoteAddresses.vue'
import QuoteMissions from '@/components/quote/QuoteMissions.vue'
import QuoteFilesRequested from '@/components/quote/QuoteFilesRequested.vue'
    @Component({
      subscriptions () {
        return {
        }
      },
      components: {
        Judicial,
        QuoteContacts,
        QuoteAddresses,
        QuoteMissions,
        QuoteFilesRequested
      }
    })

export default class BusinessManagement extends Vue {
        @Prop({ default: false }) updateable: boolean
        @Prop({ default: false }) opened: boolean

        validate (): Promise<boolean> {
          return (this.$refs.quoteContacts as any).validate()
        }
}
