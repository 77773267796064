
import { searchGlobalItemsService } from '@/services/searchGlobalItems.service'

export default {
  subscriptions () {
    return {
      loading$: searchGlobalItemsService.onLoading,
      activeId: searchGlobalItemsService.onChangeActiveId
    }
  },
  props: {
    result: {
      type: Object,
      required: true
    }
  },
  emits: ['pin', 'click'],
  methods: {
    handleClickOnItem (item: any) {
      (this as any).$emit('click')
      searchGlobalItemsService.changeActiveId(item.id)
    },
    pinResult (result: any) {
      (this as any).$emit('pin', result)
    }
  }
}
