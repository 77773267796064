
import Moment from 'moment'
import { Component, Vue } from 'vue-property-decorator'
import { dashboardService, SummaryData } from '../../services/dashboard.service'
import 'chart.js'
import 'chartjs-plugin-labels'
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions () {
    return {
      summaryData$: dashboardService.onChangeSummaryData
    }
  },
  methods: {}
})

export default class DashboardChartCa extends Vue {
  summaryData$: SummaryData
  chartData: any = {}
  chartOptions: any = {}
  chartInfos: any[] = [
    {
      label: Moment().locale('fr').format('MMMM'),
      backgroundColor: '#a5a4a5',
      month: Moment().locale('fr').format('M')
    }, {
      label: Moment().locale('fr').subtract(1, 'month').format('MMMM'),
      backgroundColor: '#de823c',
      month: Moment().locale('fr').subtract(1, 'month').format('M')
    },
    {
      label: Moment().locale('fr').subtract(2, 'month').format('MMMM'),
      backgroundColor: '#5071c0',
      month: Moment().locale('fr').subtract(2, 'month').format('M')
    }
  ]

  dateNom = Moment().locale('fr')

  mounted () {
    this.chartOptions = {
      responsive: true,
      legend: {
        display: false
      },
      animation: {
        animateScale: true,
        animateRotate: true
      },
      plugins: {
        labels: {
          // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
          render: 'value',
          fontSize: 12,
          fontStyle: 'bold',
          fontColor: '#000',
          fontFamily: '"Lucida Console", Monaco, monospace'
        }
      },
      tooltips: { enabled: false },
      hover: { mode: null },
      scales: {
        yAxes: [{
          ticks: {
            callback (value: number) {
              const options = [
                { max: 10, val: 1000000000, sym: 'B' },
                { max: 7, val: 1000000, sym: 'M' },
                { max: 4, val: 1000, sym: 'K' }
              ]
              const len = Math.abs(value).toString().length
              const option = options.find(opt => opt.max < len)
              if (option) {
                return (value / option.val).toFixed(0).concat(option.sym)
              }
              return value
            }
          }
        }]
      }
    }

    /* dashboardService.getSummaryData().then(() => {
                this.getChartData();
            }); */
  }

  getChartData () {
    this.chartData = {
      datasets: [{
        data: Object.values(this.summaryData$.CAByMonth),
        backgroundColor: this.chartInfos.map((item: any) => item.backgroundColor)
      }],
      labels: this.chartInfos.map((item: any) => item.label)
    }
  }

  formatNumber (number: number): string {
    return new Intl.NumberFormat('fr-FR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number)
  }
}
