<template>
  <main class="wrapper main-wrapper">
    <sidebar />
    <div
      v-if="showUserProfil"
      class="userProfile" />
    <div>
      <div class="main-content">
        <slot name="title" />
        <slot />
      </div>
    </div>
    <slot name="after" />
    <DashboardNote />
  </main>
</template>
<script>
import DashboardNote from '@/components/dashboard/DashboardNote'
import Sidebar from '../layouts/Sidebar'
export default {
  name: 'MainWrap',
  components: { Sidebar, DashboardNote },
  props: {
    showUserProfil: {
      type: Boolean,
      default: true
    }
  },
  created () {
    window.addEventListener('blur', () => {
      document.activeElement.blur()
    })
  }
}
</script>

<style>
.main-wrapper {
  padding-left: 60px;
}
</style>
