
import { Component, Vue } from 'vue-property-decorator'
import { Ged } from '@/models/ged.model'
import { gedService } from '@/services/ged.service'
import { baseUrl } from '@/services/api.client'
    @Component({
      subscriptions () {
        return {
          geds$: gedService.onChangeGeds,
          activeGedIndex$: gedService.onChangeActiveGedIndex
        }
      },
      components: {
      }
    })

export default class EmbedFile extends Vue {
  baseUrl: string | undefined = baseUrl
  geds$: Ged[]
  activeGedIndex$: number|null

  fileAccepted = ['pdf', 'html', 'png', 'jpeg', 'gif', 'svg', 'tif', 'bmp', 'mp4', 'mov', 'ogg', 'qt']

  created () {
  }

  get isDocumentCard (): boolean {
    return this.activeGedIndex$ !== null && this.geds$[this.activeGedIndex$] && this.geds$[this.activeGedIndex$].type === 'document'
  }

  get isNoteCard (): boolean {
    return this.activeGedIndex$ !== null && this.geds$[this.activeGedIndex$] && this.geds$[this.activeGedIndex$].type === 'note'
  }

  get champsUpload () {
    if (!this.activeGedIndex$) {
      return null
    }
    return this.geds$[this.activeGedIndex$].champsUpload
  }

  get fileUrl (): string {
    if (this.activeGedIndex$ !== null && this.geds$[this.activeGedIndex$] && this.geds$[this.activeGedIndex$].type === 'document' && this.geds$[this.activeGedIndex$].champsUpload) {
      return `${baseUrl}ged-view-file/${this.geds$[this.activeGedIndex$].champsUpload.fileViewPath}/${this.geds$[this.activeGedIndex$].champsUpload.originName}`
    } else {
      return ''
    }
  }

  get TypeFile () : string | null {
    if (this.activeGedIndex$ !== null && this.geds$[this.activeGedIndex$] && this.geds$[this.activeGedIndex$].type === 'document' && this.geds$[this.activeGedIndex$].champsUpload) {
      const champsUpload = this.geds$[this.activeGedIndex$].champsUpload
      if (!champsUpload || !champsUpload.fileViewPath) {
        return null
      }
      const tadExt = champsUpload.fileViewPath.split('.')
      const ext = tadExt[tadExt.length - 1]
      if (ext === 'pdf') {
        return 'pdf'
      } else {
        return `${ext}`.toLocaleLowerCase()
      }
    } else {
      return null
    }
  }

  get note (): string {
    if (this.activeGedIndex$ !== null && this.geds$[this.activeGedIndex$].type === 'note') {
      return this.geds$[this.activeGedIndex$].note
    } else {
      return ''
    }
  }

  getHeight (): number {
    return screen.height * 3 / 4
  }
}
