import { apiClient } from '@/services/api.client'
import { BehaviorSubject, Observable } from 'rxjs'
import { PageParams } from '@/models/ged.model'

export class Contact {
  id: string
  email1: string
  email2: string
  nom: string
  prenom: string
  siret: string
  telephone1: string
  telephone2: string
  active1: string
  active2: string
  juridical: boolean
  title: string
  brand: string
  contactType: boolean
  commentaire: string
  constructor (data?: any) {
    this.id = (data && data.id) || null
    this.email1 = (data && data.email1) || ''
    this.email2 = (data && data.email2) || ''
    this.nom = (data && data.nom) || ''
    this.prenom = (data && data.prenom) || ''
    this.siret = (data && data.siret) || ''
    this.telephone1 = (data && data.telephone1) || ''
    this.telephone2 = (data && data.telephone2) || ''
    this.active1 = (data && data.active1) || ''
    this.active2 = (data && data.active2) || ''
    this.juridical = (data && data.juridical) || false
    this.title = (data && data.title) || ''
    this.brand = (data && data.brand) || ''
    this.contactType = (data && data.contactType) || false
    this.commentaire = (data && data.commentaire) || ''
  }
}
// this class is responsible for managing the contacts.
class ContactService {
  private readonly _contact: BehaviorSubject<Contact | any> = new BehaviorSubject<Contact | any>({})
  private readonly _contacts: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>([])
  private readonly _totalContacts: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  private readonly _activeContactIndex: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null)
  private readonly _pageParams: BehaviorSubject<PageParams> = new BehaviorSubject<PageParams>(new PageParams())

  public onChangeContact: Observable<Contact | any> = this._contact.asObservable() // subscribe to contact change
  public onChange: Observable<Contact[]> = this._contacts.asObservable() // subscribe to contacts change
  public onChangeTotalContacts: Observable<Contact | any> = this._totalContacts.asObservable() // subscribe to contact change
  public onLoading: Observable<boolean> = this._loading.asObservable() // subscribe to loading status change
  public onChangeActiveContactIndex: Observable<number | null> = this._activeContactIndex.asObservable()
  public onChangePageParams: Observable<PageParams> = this._pageParams.asObservable()

  private readonly cancelToken: (() => void) | null = null

  get contact (): Contact | any {
    return this._contact.getValue()
  }

  get contacts (): Contact[] {
    return this._contacts.getValue()
  }

  get totalContacts (): number {
    return this._totalContacts.getValue()
  }

  get pageParams (): PageParams {
    return this._pageParams.getValue()
  }

  public async getContacts (filterForm: any): Promise<void> {
    this._loading.next(true)
    let personMoral = null
    const active1 = filterForm.active1
    const active2 = filterForm.active2
    const title = filterForm.value_title
    const juridical = filterForm.value_juridique
    const prenom = filterForm.prenom
    const nom = filterForm.nom
    const brand = filterForm.brand
    const siret = filterForm.siret
    const createdAt = filterForm.createdAt
    const sizePage = filterForm.sizePage
    const currentPage = filterForm.currentPage
    const sortProp = filterForm.sortProp
    const sortOrder = filterForm.sortOrder
    if (filterForm.contact_type === 'Tout') {
      personMoral = null
    } else if (filterForm.contact_type === 'P.Morale') {
      personMoral = true
    } else {
      personMoral = false
    }
    await apiClient.get('contacts', {
      params: { personMoral, active1, active2, title, juridical, prenom, nom, brand, siret, createdAt, sizePage, currentPage, sortProp, sortOrder }
    }).then(res => {
      this._loading.next(false)
      if (!res.data) {
        this._contacts.next([])
      }
      const contacts: Contact[] = []
      res.data.data.forEach((contact: any) => {
        contacts.push(new Contact(contact))
      })
      this._contacts.next(contacts)
      this._totalContacts.next(res.data.total)
    }).catch((err) => {
      console.error(err)
      this._loading.next(false)
      throw err
    })
  }

  public async getContactsByKey (
    searchKey: string,
    sizePage: number = 30,
    currentPage: number = 1,
    sortProp: string | null = null,
    sortOrder: string | null = null
  ): Promise<void> {
    this._loading.next(true)
    let additionalQuery: string = ''
    if (sortProp && sortProp !== '' && sortOrder && sortOrder !== '') {
      additionalQuery += `&sortProp=${sortProp}&sortOrder=${sortOrder}`
    }
    await apiClient.get('contacts-filter/' + searchKey + `?sizePage=${sizePage}&currentPage=${currentPage}${additionalQuery}`).then(res => {
      this._loading.next(false)
      if (res.data) {
        const contacts: Contact[] = []
        res.data.data.forEach((contact: any) => {
          contacts.push(new Contact(contact))
        })
        this._contacts.next(contacts)
        this._totalContacts.next(res.data.total)
      }
    }).catch((err) => {
      console.error(err)
      this._loading.next(false)
      throw err
    })
  }

  public async getContactNames (): Promise<void> {
    this._loading.next(true)
    await apiClient.get('contact-names').then(res => {
      if (res.data) {
        const contacts: Contact[] = []
        res.data.forEach((contact: any) => {
          contacts.push(new Contact(contact))
        })
        this._contacts.next(contacts)
        this._loading.next(false)
      }
    }).catch((err) => {
      console.error(err)
      this._loading.next(false)
      throw err
    })
  }

  public async getContactsById (contactsId: []): Promise<Contact[]> {
    this._loading.next(true)
    return await apiClient.get('contacts-by-id', {
      params: { contactsId }
    }).then(res => {
      this._loading.next(false)
      if (!res.data) {
        return []
      }
      const contacts: Contact[] = []
      res.data.forEach((contact: any) => {
        contacts.push(new Contact(contact))
      })
      return contacts
    }).catch((err) => {
      console.error(err)
      this._loading.next(false)
      throw err
    })
  }

  public async getContactById (contactId: string): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('contacts/' + contactId).then(res => {
      this._contact.next(new Contact(res.data))
      if (this.contacts.length > 0) {
        const index = this.contacts.findIndex((item) => item.id === contactId)
        if (index > -1) {
          this.contacts[index] = res.data
        }
        this._contacts.next(this.contacts)
      }
      this._loading.next(false)
      return res.data
    }).catch((err) => {
      console.error(err)
      this._loading.next(false)
      throw err
    })
  }

  public async deleteContact (contactId: string): Promise<void> {
    this._loading.next(true)
    await apiClient.delete('contact/' + contactId).then(res => {
      this._contact.next(new Contact(res.data))
      this._loading.next(false)
    }).catch((err) => {
      console.error(err)
      this._loading.next(false)
      throw err
    })
  }

  public async saveContact (id: any, nom: string, email1: string, email2: string, prenom: string,
    siret: string, telephone1: string, telephone2: string, active1: string, active2: string, juridical: boolean, title: string,
    brand: string, contactType: boolean, commentaire: string): Promise<any> {
    return await apiClient.post('contact', {
      id,
      nom,
      email1,
      email2,
      prenom,
      siret,
      telephone1,
      telephone2,
      active1,
      active2,
      juridical,
      title,
      brand,
      contactType,
      commentaire
    }).then(res => {
      this._contact.next(new Contact(res.data))
      return res.data
    }).catch((err) => {
      console.error(err)
      throw err
    })
  }

  public setContact (id: number): Contact | undefined {
    const item = this.contacts.find((item) => Number(item.id) === id)
    if (item != null) {
      this._contact.next(item)
    }
    return item
  }

  public updateContact (val: any): void {
    this._contact.next(val)
  }

  public async intQuoteContact (id: number): Promise<void> {
    await apiClient.get('contacts/' + id.toString()).then(res => {
      this._contact.next(new Contact(res.data))
    })
  }

  public initContact (): void {
    this._contact.next(new Contact())
  }

  public async getContactNamesByKey (
    searchKey: string,
    sizePage: number = 15
  ): Promise<void> {
    this._loading.next(true)
    await apiClient.get('contacts-filter/' + searchKey + `?sizePage=${sizePage}`).then(res => {
      this._loading.next(false)
      if (res.data) {
        const contacts: Contact[] = []
        res.data.data.forEach((contact: any) => {
          contacts.push(new Contact(contact))
        })
        this._contacts.next(contacts)
        this._totalContacts.next(res.data.total)
      }
    }).catch((err) => {
      console.error(err)
      this._loading.next(false)
      throw err
    })
  }

  public activeContactIndex (index: number | null): void {
    this._activeContactIndex.next(index)
  }
}

export const contactService = new ContactService()
