import { apiClient } from '@/services/api.client'
import { BehaviorSubject, Observable } from 'rxjs'
import { User } from './user.service'

export class Recipient {
  nom: string
  prenom: string
  email: string
  title: string
  contactType: boolean
  constructor (recipient: any = {}) {
    this.nom = recipient.nom || ''
    this.prenom = recipient.prenom || ''
    this.email = recipient.email || ''
    this.title = recipient.title || ''
    this.contactType = recipient.contactType || ''
  }
}
export class Email {
  recipients: Recipient[]
  sender: User | null
  subject: string
  attaches: string[]
  template: EmailTemplate | null
  content: string
  copyTo: Recipient[]
  cci: Recipient[]
  constructor (email: any = {}) {
    this.recipients = email.recipients || []
    this.sender = email.sender || null
    this.subject = email.subject || ''
    this.attaches = email.attaches || []
    this.template = email.template || new EmailTemplate()
    this.content = email.content || ''
    this.copyTo = email.copyTo || []
    this.cci = email.cci || []
  }
}

export class EmailTemplate {
  id?: number
  title: string
  subject: string
  content: string
  constructor (emailTemplate: any = {}) {
    this.id = emailTemplate.id || null
    this.title = emailTemplate.title || ''
    this.subject = emailTemplate.subject || ''
    this.content = emailTemplate.content || ''
  }
}

class EmailService {
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  private readonly _emailTemplates: BehaviorSubject<EmailTemplate[]> = new BehaviorSubject<EmailTemplate[]>([])
  public onLoading: Observable<boolean> = this._loading.asObservable()
  public onChangeEmailTemplates: Observable<EmailTemplate[]> = this._emailTemplates.asObservable()

  public async getEmailTemplates (): Promise<void> {
    this._loading.next(true)
    await apiClient.get('email-templates').then(res => {
      if (res.data) {
        const emailTemplates: EmailTemplate[] = []
        res.data.forEach((emailTemplate: any) => {
          emailTemplates.push(new EmailTemplate(emailTemplate))
        })
        this._emailTemplates.next(emailTemplates)
        this._loading.next(false)
      }
    })
  }

  public async sendEmail (email: Email): Promise<void> {
    this._loading.next(true)
    return await apiClient.post('emails', email).then((res: any) => {
      this._loading.next(false)
      return res
    }).catch((err: any) => {
      this._loading.next(false)
      return err
    })
  }
}

export const emailService = new EmailService()
