
import { Component, Prop, Vue } from 'vue-property-decorator'
import { fileService, CategoryTree } from '@/services/file.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import FileCategory from './FileCategory.vue'

@Component({
  subscriptions (this: any) {
    return {
      user$: userService.onChange,
      categoryTree$: fileService.onChangeTree
    }
  },
  components: {
    FileCategory
  }
})
export default class FileMainCategory extends Vue {
  @Prop({}) title: string
  @Prop({}) type: string
  @Prop({ default: 0 }) folderId: number
  @Prop({ default: 0 }) categoryId: number
  @Prop({ default: false }) isMain: boolean
  @Prop({}) folderType: string
  @Prop({ default: false }) disabled: boolean

  user$: User = new User()
  categoryTree$: CategoryTree[] = []
  selectedType: string = this.getType()
  hidden: boolean = false

  getType (): string {
    return this.type
  }

  get changeable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.FILE_SECTION_UPDATE)
  }

  getCategories (type: string): CategoryTree[] {
    const category = this.categoryTree$.find((item: CategoryTree) => item.type === type)
    return (category && category.subCategories) || []
  }
}
