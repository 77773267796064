<template>
  <div class="block-container">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Block',
  components: {
  },
  props: {
    header: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
    .block-container{
        background: white;
        padding: 10px;
        margin: 15px;
        border-radius: 5px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
    }
</style>
