
import { Component, Prop, Vue } from 'vue-property-decorator'
import { baseUrl } from '@/services/api.client'
import { Report, reportService } from '@/services/report.service'
import { SettledFolder } from '@/services/settledFolder.service'
import { Quote, quoteService } from '@/services/quote.service'
import { legalFolderService } from '@/services/legalFolder.service'
import { User, USER_RIGHTS, userService } from '@/services/user.service'
import { LegalFolder } from '@/models/legalFolder.model'
import FormattedFloat from '../../utils/FormattedFloat.vue'
import { tap } from 'rxjs/operators'
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions (this: any) {
    return {
      report$: reportService.onChangeReport.pipe(
        tap((res: any) => {
          if (res.file) {
            this.fileList = [
              {
                name: res.file.name,
                url: res.file.url
              }
            ]
          } else {
            this.fileList = []
          }
        })
      ),
      folder$:
          this.$route.name === 'legal-folder'
            ? legalFolderService.onChangeLegalFolder
            : quoteService.onChangeQuote.pipe(),
      user$: userService.onChange
    }
  },
  components: {
    FormattedFloat
  }
})
export default class InformationGenerals extends Vue {
  @Prop({ default: false }) disabled: boolean

  folder$: Quote | LegalFolder | SettledFolder
  report$: Report = new Report()
  user$: User = new User()
  fileList: any[] = []

  get isQuote (): boolean {
    return this.$route.name === 'Quote'
  }

  get isLegalFolder (): boolean {
    return this.$route.name === 'legal-folder'
  }

  get uploadUrl (): string {
    return String(baseUrl) + String(this.$route.name) + '/report/upload-image'
  }

  get readable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.LM_SECTION_READ)
  }

  get uploadable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.RAPPORT_SECTION_UPDATE) && !this.isQuote && (this.isLegalFolder ? !!(this.$route.params.id) : true) && !this.disabled
  }

  get folderUpdateable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.FOLDER_UPDATE)
  }

  get quoteUpdateable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.QUOTE_UPDATE)
  }

  get fieldUpdateable (): boolean {
    return (this.isQuote ? this.quoteUpdateable : (this.folderUpdateable && !this.folderClosed)) && !this.disabled
  }

  get folderClosed (): boolean {
    const closedStatus: number = this.$route.name === 'settled-folder' ? 9 : 12
    return !this.isQuote && this.folder$.status === closedStatus
  }

  handleRemove () {
    if (this.report$.fileId) {
      // apiClient.delete('file/' + this.report$.fileId);
      this.report$.fileId = null
    }
  }

  handleSuccess (response: any) {
    if (response && response.id) {
      this.report$.fileId = response.id
    }
  }
}
