import { render, staticRenderFns } from "./DossierFilterSection.vue?vue&type=template&id=3c60cb08&scoped=true&"
import script from "./DossierFilterSection.vue?vue&type=script&lang=ts&"
export * from "./DossierFilterSection.vue?vue&type=script&lang=ts&"
import style0 from "./DossierFilterSection.vue?vue&type=style&index=0&id=3c60cb08&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c60cb08",
  null
  
)

export default component.exports