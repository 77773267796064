
import { Component, Prop, Vue } from 'vue-property-decorator'
import { valeurBlockItems } from '../blockItems'
import { informationsVenteItemList } from '../itemList'
import { floatRule } from '../formRules'
import { referenceService } from '@/services/listReference.service'
import { tap } from 'rxjs/operators'
import { shortcutsDatePicker } from '@/utils/constant'

@Component({
  subscriptions (this: any) {
    return {
      value: referenceService.onChangeReference.pipe(tap((res: any) => {
        if (res) {
          if (!res.hasExactDateVente && res.dateVenteStart && res.dateVenteEnd) {
            this.dateVente = [res.dateVenteStart, res.dateVenteEnd]
          } else if (res.hasExactDateVente && res.dateVenteStart) {
            this.exactDateVente = res.dateVenteStart
          }
        }
      })),
      isMigratedRef: referenceService.onChangeIsMigratedRef
    }
  }
})
export default class InformationsVente extends Vue {
  // @Prop({ type: Object })
  value: any
  @Prop({ type: String })
    localLabel!: any

  @Prop({ type: Number })
    typeValeur!: any

  isExactDate = true
  isMigratedRef = false
  rangePickerOptions: any = {
    shortcuts: shortcutsDatePicker
  }

  exactDateVente: string = ''
  dateVente: any = []
  floatRule = floatRule
  informationsVenteItemList = informationsVenteItemList

  created () {
    if (this.value) {
      if (this.value.hasExactDateVente) {
        this.isExactDate = this.value.hasExactDateVente
      } else {
        this.value.hasExactDateVente = this.isExactDate
      }
      if (!this.isExactDate && this.value.dateVenteStart && this.value.dateVenteEnd) {
        this.dateVente = [this.value.dateVenteStart, this.value.dateVenteEnd]
      } else if (this.value.dateVenteStart && this.isExactDate) {
        this.exactDateVente = this.value.dateVenteStart
      }
    }
  }

  handleDateVente (val: any) {
    if (val && val.length > 1) {
      this.value.dateVenteStart = val[0]
      this.value.dateVenteEnd = val[1]
    } else {
      this.value.dateVenteStart = ''
      this.value.dateVenteEnd = ''
    }
    this.dateVente = [this.value.dateVenteStart, this.value.dateVenteEnd]
  }

  handleExactDateVente (val: string) {
    this.value.dateVenteStart = val
    this.value.dateVenteEnd = ''
    this.exactDateVente = val
  }

  toggleExactDate (val: boolean) {
    if (val) {
      this.exactDateVente = this.value.dateVenteStart
      this.value.dateVenteEnd = ''
      this.value.hasExactDateVente = true
    } else {
      this.value.hasExactDateVente = false
    }
  }

  checkDisplayCondition (title: string) {
    if (this.isMigratedRef) {
      return true
    }
    const localLabel = this.localLabel
    return (
      this.typeValeur === 2 && valeurBlockItems[localLabel] && !valeurBlockItems[localLabel].includes(title)
    )
  }
}
