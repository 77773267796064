
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  ReferenceAddress, referenceService
} from '@/services/listReference.service'
import FormattedFloat from '../../utils/FormattedFloat.vue'
import FormattedNumber from '../../utils/FormattedNumber.vue'
import ReferenceAddressComponent from './ReferenceAddress.vue'
import { baseBlockItems } from '../blockItems'
import { generalInfoItemList } from '../itemList'
import {
  requireRule,
  floatRule
} from '../formRules'
import {
  filterOptionsWithoutCaseSensitive,
  filterGroupList
} from '@/utils/functions'

@Component({
  subscriptions (this: any) {
    return {
      value: referenceService.onChangeReference,
      referenceAddress: referenceService.onChangeReferenceAdressse,
      isMigratedRef: referenceService.onChangeIsMigratedRef
    }
  },
  components: {
    FormattedFloat,
    FormattedNumber,
    ReferenceAddressComponent
  }
})
export default class GeneralInfo extends Vue {
  @Prop({ type: Array })
    typeLocalItems: any[]

  @Prop({ type: Array })
    typeLocalItemsList: any[]

  @Prop({ type: Array })
    typeTransaction: any[]

  @Prop({ type: Object })
    optionList: any

  @Prop({ type: Array })
    activites: any

  isMigratedRef = false
  value: any
  requireRule = requireRule
  floatRule = floatRule
  datePickerOptions: any = {}
  referenceAddress: ReferenceAddress
  generalInfoItemList = generalInfoItemList
  filteredOptionList: any = []
  filteredTypeLocalItems: any = []
  filteredTypeTransaction: any = []
  filteredActivites: any = []

  @Watch('optionList', { immediate: true, deep: true })
  handleOptionList () {
    this.filteredOptionList = JSON.parse(JSON.stringify(this.optionList))
  }

  @Watch('typeLocalItems', { immediate: true, deep: true })
  handleTypeLocalItems () {
    this.filteredTypeLocalItems = JSON.parse(JSON.stringify(this.typeLocalItems))
  }

  @Watch('typeTransaction', { immediate: true, deep: true })
  handleTypeTransaction () {
    this.filteredTypeTransaction = JSON.parse(JSON.stringify(this.typeTransaction))
  }

  @Watch('activites', { immediate: true, deep: true })
  handleActivites () {
    this.filteredActivites = JSON.parse(JSON.stringify(this.activites))
  }

  changeTypeLocal (typeLocal: number) {
    if (!this.isMigratedRef) {
      this.resetRef()
    }
    this.$emit('updatedTypeLocal', typeLocal)
  }

  changeTypeTransaction (typeTransaction: number) {
    if (typeTransaction <= 4) {
      this.value.typeValeur = 1
    } else {
      this.value.typeValeur = 2
    }
    if (!this.isMigratedRef) {
      this.resetRef()
    }
    this.$emit('updatedTypeTransaction', typeTransaction)
    this.$emit('updatedTypeValeur', this.value.typeValeur)
  }

  resetRef () {
    referenceService.initRef({
      typeValeur: this.value.typeValeur,
      typeLocal: this.value.typeLocal,
      typeTransaction: this.value.typeTransaction,
      adresse: this.value.adresse,
      source: this.value.source,
      dateSource: this.value.dateSource,
      fiabilite: this.value.fiabilite,
      confidentialite: this.value.confidentialite,
      enseigneLocataire: this.value.enseigneLocataire,
      activites: this.value.activites,
      fileIds: this.value.fileIds
    })
  }

  checkDisplayCondition (title: string) {
    if (this.isMigratedRef) {
      return true
    }
    const localLabel = this.getLocalLabel(this.value.typeLocal)
    return (
      baseBlockItems[localLabel] && !baseBlockItems[localLabel].includes(title)
    )
  }

  getLocalLabel (typeLocal: number) {
    let label = ''
    const target: any = this.typeLocalItemsList.filter(
      (item: any) => item.num === typeLocal
    )
    target.length > 0 ? (label = target[0].label) : (label = '')

    return label
  }

  changeSelect (item: any) {
    if (this.value[item.value] === 0) {
      this.value[item.value] = ''
    }
  }

  filterOptions (query: string, isGroup: boolean, type: string) {
    if (isGroup) {
      if (type === 'typeLocal') {
        this.filteredTypeLocalItems = filterGroupList(query, this.typeLocalItems)
      } else if (type === 'typeTransaction') {
        this.filteredTypeTransaction = filterGroupList(query, this.typeTransaction)
      } else if (type === 'activities') {
        this.filteredActivites = filterGroupList(query, this.activites)
      }
    } else {
      this.filteredOptionList[type] = filterOptionsWithoutCaseSensitive(query, 'label', this.optionList[type])
    }
  }

  handleFocusList (isGroup: boolean, type: string) {
    if (isGroup) {
      if (type === 'typeLocal') {
        this.filteredTypeLocalItems = JSON.parse(JSON.stringify(this.typeLocalItems))
      } else if (type === 'typeTransaction') {
        this.filteredTypeTransaction = JSON.parse(JSON.stringify(this.typeTransaction))
      } else if (type === 'activities') {
        this.filteredActivites = JSON.parse(JSON.stringify(this.activites))
      }
    } else {
      this.filteredOptionList[type] = JSON.parse(JSON.stringify(this.optionList[type]))
    }
  }
}
