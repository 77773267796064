
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { dossierService, DossierFilter } from '@/services/dossier.service'
import { quoteService } from '@/services/quote.service'
import { listReferenceService, ReferenceAddress } from '@/services/listReference.service'
import { User, userService, USER_RIGHTS } from '@/services/user.service'
import { authService } from '@/services/auth.service'
import {
  MISSION_TYPES,
  MISSION_EXPERT_OPTIONS,
  MISSION_LOCAL_OPTIONS,
  QUOTE_STATUES,
  SETTED_FOLDER_STATUES,
  LEGAL_FOLDER_STATUES,
  ROLE_TYPE_OPTIONS,
  TYPE_INVOICES,
  TYPES_DEBOURS
} from '@/services/constants.service'
import { formatUserName } from '@/utils/functions'
import {
  semesterList,
  trimesterList,
  monthList
} from '@/utils/constant'
import DossierFilterSection from './DossierFilterSection.vue'
import moment from 'moment'

@Component({
  subscriptions () {
    return {
      filter$: dossierService.onChangeFilter,
      users$: userService.onChangeUsers,
      // clients$: dossierService.onChangeClients,
      contacts$: dossierService.onChangeContacts,
      writers$: dossierService.onChangeWriters,
      reviewers$: dossierService.onChangeReviewers
    }
  },
  components: {
    DossierFilterSection
  }
})

export default class DossierFilterComponent extends Vue {
  @Prop({ default: false }) active: boolean
  MISSION_TYPES = MISSION_TYPES
  MISSION_EXPERT_OPTIONS = MISSION_EXPERT_OPTIONS
  MISSION_LOCAL_OPTIONS = MISSION_LOCAL_OPTIONS
  QUOTE_STATUES = QUOTE_STATUES
  SETTED_FOLDER_STATUES = SETTED_FOLDER_STATUES
  LEGAL_FOLDER_STATUES = LEGAL_FOLDER_STATUES
  TYPE_INVOICES = TYPE_INVOICES
  TYPES_DEBOURS = TYPES_DEBOURS
  filter$ = new DossierFilter()
  localOptions: any[] = []
  filteredLocalOptions: any[] = []
  roleTypeOptions: any[] = []
  rules: any = {}
  contacts$: any
  users$: any
  contactFilterSelected: any = null
  userFilterSelected: any = null
  inputNumber: string = ''
  caYear: number | null = null
  caType: string = ''
  caDate: string[] = []
  hide: boolean = true
  formatUserName: Function = formatUserName
  activeFilters: any = {
    utilisateur: '',
    contact: '',
    mission: '',
    expertise: '',
    actif: '',
    section: '',
    statut: '',
    facturation: '',
    debours: ''
  }

  created () {
    userService.getUserFilter()

    dossierService.getFilterDatas()
    // this.localOptions = JSON.parse(JSON.stringify(MISSION_LOCAL_OPTIONS));
    // this.filteredLocalOptions = this.localOptions;
    this.roleTypeOptions = JSON.parse(JSON.stringify(ROLE_TYPE_OPTIONS))
    this.roleTypeOptions.pop()
    this.updateActiveFilterStatut()
    this.QUOTE_STATUES = this.isAdmin ? QUOTE_STATUES : QUOTE_STATUES.filter(status => status.label !== 'Ouvert')
    this.SETTED_FOLDER_STATUES = this.isAdmin ? SETTED_FOLDER_STATUES : SETTED_FOLDER_STATUES.filter(status => status.label !== 'Ouvert')
    this.LEGAL_FOLDER_STATUES = this.isAdmin ? LEGAL_FOLDER_STATUES : LEGAL_FOLDER_STATUES.filter(status => status.label !== 'Ouvert')
  }

  @Watch('caYear')
  onCaYearChange () {
    this.changeCaDate(this.caDate, true)
  }

  @Watch('caType')
  onCaTypeChange () {
    this.caDate = []
    this.filter$.caDate = []
    this.changeCaDate(this.caDate)
  }

  /* get canSeeFolderList(): boolean {
      return userService.hasRight(this.user$, USER_RIGHTS.CAN_SEE_FOLDER_LISTS);
  } */

  get isAdmin (): boolean {
    const user = JSON.parse(JSON.stringify(authService.userValue))
    return userService.hasRight(user, USER_RIGHTS.ADM)
  }

  get caLists (): any[] {
    if (this.caType === 'month') {
      return monthList
    } else if (this.caType === 'trimester') {
      return trimesterList
    } else if (this.caType === 'semester') {
      return semesterList
    }
    return []
  }

  get hasTypeInvoice (): boolean {
    const hasTypeInvoice: boolean = this.filter$.typeInvoice.length > 0
    if (!hasTypeInvoice) {
      this.filter$.avecNumero = false
      this.filter$.invoice = {
        start: '',
        end: ''
      }
      this.filter$.numbers = []
      this.filter$.billNumber = {
        start: null,
        end: null
      }
    }
    return hasTypeInvoice
  }

  get hasTypeDebours (): boolean {
    const hasTypeDebours: boolean = this.filter$.typeDebours.length > 0
    if (!hasTypeDebours) {
      this.filter$.debours = {
        start: '',
        end: ''
      }
    }
    return hasTypeDebours
  }

  get hasSelectedUser (): boolean {
    const hasSelectedUser: boolean = this.filter$.expert.length > 0
    if (!hasSelectedUser) {
      this.filter$.creator = false
      this.filter$.writer = false
      this.filter$.reviewer = false
      this.filter$.signer = false
    }
    return hasSelectedUser
  }

  get hasSelectedContact (): boolean {
    const hasSelectedContact: boolean = this.filter$.client.length > 0
    if (!hasSelectedContact) {
      this.filter$.mandator = false
      this.filter$.interlocutor = false
    }
    return hasSelectedContact
  }

  updateActiveFilterUser (): void {
    this.activeFilters.utilisateur = 'Utilisateur(s) choisi(s) : '
    if (this.filter$.expert.length > 0) {
      this.filter$.expert.forEach((userId) => {
        const user = this.users$.find((user: any) => user.id === userId)
        this.activeFilters.utilisateur += user.prenom + ' ' + user.nom + ' | '
      })
    } else {
      this.activeFilters.utilisateur += 'Aucun '
    }
    this.activeFilters.utilisateur += ' Rôle(s) choisi(s) : '
    if (this.filter$.creator) {
      this.activeFilters.utilisateur += ' Créateur | '
    }
    if (this.filter$.writer) {
      this.activeFilters.utilisateur += ' Rédacteur | '
    }
    if (this.filter$.reviewer) {
      this.activeFilters.utilisateur += ' Relecteur | '
    }
    if (this.filter$.signer) {
      this.activeFilters.utilisateur += ' Signataire'
    }
  }

  updateActiveFilterContact (): void {
    this.activeFilters.contact = 'Contact(s) choisi(s) : '
    if (this.filter$.client.length > 0) {
      this.filter$.client.forEach((contactId) => {
        const contact = this.contacts$.find((client: any) => client.id === contactId)
        this.activeFilters.contact += contact.prenom + ' ' + contact.nom + ' | '
      })
    } else {
      this.activeFilters.contact += 'Aucun '
    }
    this.activeFilters.contact += ' Rôle(s) choisi(s) : '
    if (this.filter$.mandator) {
      this.activeFilters.contact += ' Mandant | '
    }
    if (this.filter$.interlocutor) {
      this.activeFilters.contact += ' Interlocuteur'
    }
    if (!this.filter$.mandator && !this.filter$.interlocutor) {
      this.activeFilters.contact += 'Aucun'
    }
  }

  updateActiveFilterStatut (): void {
    this.activeFilters.statut = 'Statut(s) choisi(s) : '
    if (this.filter$.status.length > 0) {
      let statusList: any[] = []
      if (this.filter$.type === 'settled-folder') {
        statusList = SETTED_FOLDER_STATUES
      } else if (this.filter$.type === 'quote') {
        statusList = QUOTE_STATUES
      } else if (this.filter$.type === 'legal-folder') {
        statusList = LEGAL_FOLDER_STATUES
      }
      if (statusList.length > 0) {
        this.filter$.status.forEach((statusId) => {
          const status = statusList.find((status: any) => status.value === statusId)
          this.activeFilters.statut += status.label + ' | '
        })
      }
    } else if (!Array.isArray(this.filter$.status) && (this.filter$.status === 9 || this.filter$.status === 12)) {
      this.activeFilters.statut += ' Clôturé '
    } else {
      this.activeFilters.statut += 'Aucun | '
    }
    if (this.filter$.hasPendingAmount) {
      this.activeFilters.statut += ' Avec un montant restant à facturer différent de 0 |'
    }
    if (this.filter$.hasBalanceAmount) {
      this.activeFilters.statut += ' Avec un montant restant à percevoir différent de 0'
    }
  }

  updateActiveFilterFacturation (): void {
    this.activeFilters.facturation = 'Type(s) de facture choisi(s) : '
    if (this.filter$.typeInvoice.length > 0) {
      this.filter$.typeInvoice.forEach((typeInvoiceId) => {
        const typeInvoice = TYPE_INVOICES.find((typeInvoice: any) => typeInvoice.value === typeInvoiceId)
        this.activeFilters.facturation += typeInvoice != null ? typeInvoice.label + ' | ' : ''
      })
    } else {
      this.activeFilters.facturation += 'Aucun | '
    }
    if (this.filter$.invoice.start) {
      this.activeFilters.facturation += ' Date de début : ' + moment(this.filter$.invoice.start).format('DD/MM/YYYY') + ' | '
    }
    if (this.filter$.invoice.end) {
      this.activeFilters.facturation += ' Date de fin : ' + moment(this.filter$.invoice.end).format('DD/MM/YYYY') + ' | '
    }
    if (this.filter$.avecNumero) {
      this.activeFilters.facturation += ' Uniquement avec numéro ' + ' | '
    }
    if (this.filter$.numbers.length > 0) {
      this.activeFilters.facturation += ' Avec le(s) numéro(s) de facture suivant(s) : '
      this.filter$.numbers.forEach((number) => {
        this.activeFilters.facturation += number + ' | '
      })
    }
    if (this.filter$.billNumber.start || this.filter$.billNumber.end) {
      this.activeFilters.facturation += ' Avec le(s) numéro(s) de facture entre : '
      this.activeFilters.facturation += this.filter$.billNumber.start != null ? this.filter$.billNumber.start : ''
      this.activeFilters.facturation += this.filter$.billNumber.end != null ? ' et ' + this.filter$.billNumber.end : ''
    }
  }

  updateActiveFilterDebours (): void {
    this.activeFilters.debours = 'Type(s) de facture choisi(s) : '
    if (this.filter$.typeDebours.length > 0) {
      this.filter$.typeDebours.forEach((typeDeboursId) => {
        const typeDebours = TYPES_DEBOURS.find((typeDebours: any) => typeDebours.value === typeDeboursId)
        this.activeFilters.debours += typeDebours != null ? typeDebours.label + ' | ' : ''
      })
    } else {
      this.activeFilters.debours += 'Aucun | '
    }
    if (this.filter$.debours.start) {
      this.activeFilters.debours += ' Date de début : ' + moment(this.filter$.debours.start).format('DD/MM/YYYY') + ' | '
    }
    if (this.filter$.debours.end) {
      this.activeFilters.debours += ' Date de fin : ' + moment(this.filter$.debours.end).format('DD/MM/YYYY')
    }
  }

  filterLocalOptions (query: string) {
    this.filteredLocalOptions = quoteService.filterOptions(query, 31, this.localOptions)
  }

  clearFilter () {
    this.caYear = null
    this.caType = ''
    this.caDate = []
    this.$emit('clearFilter')
    this.updateActiveFilterStatut()
  }

  submitFilter () {
    dossierService.resetCurrentPage()
    dossierService.searchDossiers('filters')
  }

  filterUser (filterValue: any) {
    if (filterValue != null && filterValue.length > 0) {
      userService.getUsersByKey(filterValue, true)
    } else {
      userService.getUserFilter()
    }
  }

  filterClient (filterValue: any) {
    if (filterValue != null && filterValue.length > 0) {
      dossierService.getContactsByKey(filterValue)
    } else {
      dossierService.getContacts()
    }
  }

  changeContact (val: any) {
    const contact = this.contacts$.find((contact: any) => contact.id === val)
    if (contact) {
      this.filter$.client = contact.id
      this.contactFilterSelected = contact
    } else {
      this.filter$.client = []
      this.contactFilterSelected = null
    }
  }

  changeUser (val: any) {
    const user = this.users$.find((user: User) => user.id === val)
    if (user) {
      this.filter$.expert = [user.id]
      this.userFilterSelected = user
    } else {
      this.filter$.expert = []
      this.userFilterSelected = null
    }
  }

  handleTagConfirm () {
    const inputNumber = this.inputNumber
    if (inputNumber && this.filter$.numbers.indexOf(inputNumber) < 0) {
      this.filter$.numbers.push(inputNumber)
      this.updateActiveFilterFacturation()
    }
    this.inputNumber = ''
  }

  handleTagClose (tag: string) {
    this.filter$.numbers.splice(this.filter$.numbers.indexOf(tag), 1)
    this.updateActiveFilterFacturation()
  }

  changeCaDate (val: string[], isYearChanged : boolean = false) {
    if (isYearChanged && this.caYear !== null && this.filter$.caDate.length > 0) { // si l'année est changée, les dates déjà dans le filtre change d'année
      this.filter$.caDate.forEach((caDate : any) => { caDate.start.setFullYear(this.caYear); caDate.end.setFullYear(this.caYear) })
    }
    if (!isYearChanged) { // ce n'est pas la date qui a été changée
      if (val.length > 0) {
        if (this.caType === 'month') {
          this.filter$.caDate.push({
            start: new Date(`${this.caYear}-${JSON.parse(val[val.length - 1]).start}`),
            end: new Date(`${this.caYear}-${JSON.parse(val[val.length - 1]).end}`)
          })
        } else {
          const { start, end }: any = JSON.parse(val.toString())
          this.filter$.caDate = [{
            start: new Date(`${this.caYear}-${start}`),
            end: new Date(`${this.caYear}-${end}`)
          }]
        }
      } else {
        this.filter$.caDate = []
      }
      if (this.caYear && !this.caType) {
        this.filter$.caDate = [{
          start: new Date(`${this.caYear}-01-01`),
          end: new Date(`${this.caYear}-12-31`)
        }]
      }
    }
  }

  querySearch (queryString: string, layer: string, cb: Function) {
    if (!queryString) return
    const { number, street, zip, city } = this.filter$.address
    const query: string = `${number || ''}${street ? (' ' + street) : ''}${city ? (', ' + city) : ''}${zip ? (' ' + zip) : ''}&type=${layer}`
    listReferenceService.getReferenceAddresse(query).then((res) => {
      cb(res)
    })
  }

  handleSelectNumber (item: ReferenceAddress): void {
    this.filter$.address = {
      number: item.housenumber,
      street: item.street,
      zip: item.postalcode,
      city: item.locality
    }
  }

  handleSelectStreet (item: ReferenceAddress): void {
    this.filter$.address = {
      street: item.street,
      zip: item.postalcode,
      city: item.locality
    }
  }

  handleSelectZip (item: ReferenceAddress): void {
    this.filter$.address = {
      zip: item.postalcode,
      city: item.locality,
      layer: item.layer
    }
  }

  handleSelectCity (item: ReferenceAddress): void {
    this.filter$.address = {
      zip: item.postalcode.slice(0, 2),
      city: item.locality,
      layer: item.layer
    }
  }
}
