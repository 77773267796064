

import { getLocalTypeLabel, getTransactionTypeLabel, getActiviteLabel } from '@/utils/functions'
import { listReferenceService } from '@/services/listReference.service'
import { referenceFileService } from '@/services/referenceFile.service'

export default {
  subscriptions () {
    return {
      listReferences$: listReferenceService.onChangeListReferences
    }
  },
  props: {
    result: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      previewUrl: null
    }
  },
  created () {
    referenceFileService.getImageUrlByRefId((this as any).result.reference.id).then((res) => {
      if (res.data.url) {
        (this as any).previewUrl = res.data.url
      }
    })
  },
  beforeUpdate () {
    (this as any).getPreviewUrl()
  },
  methods: {
    getLocalTypeLabel (value: number): any {
      return getLocalTypeLabel(value, (this as any).listReferences$)
    },
    getTransactionTypeLabel (value: number): any {
      return getTransactionTypeLabel(value, (this as any).listReferences$)
    },
    getActiviteLabel (value: number): any {
      return getActiviteLabel(value, (this as any).listReferences$)
    },
    getPreviewUrl () {
      referenceFileService.getImageUrlByRefId((this as any).result.reference.id).then((res) => {
        (this as any).previewUrl = res.data.url
      })
    }
  }
}
