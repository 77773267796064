
import { Component, Vue } from 'vue-property-decorator'
import { saveSearchService, SearchRef } from '@/services/saveSearch.service'
import { ListReference, listReferenceService } from '@/services/listReference.service'
import { PageParams } from '@/models/ged.model'
import { localeDateStr } from '@/utils/functions'
import { authService, User } from '@/services/auth.service'
    @Component({
      subscriptions () {
        return {
          pageParams$: saveSearchService.onChangePageParams,
          searches$: saveSearchService.onChangeSearches,
          totalSearches$: saveSearchService.onChangeTotalSearches,
          authUser$: authService.onChange
        }
      },
      components: {
      }
    })

export default class SearchTable extends Vue {
  localeDateStr: Function = localeDateStr
  searches$: SearchRef[]
  pageParams$: PageParams
  totalSearches$: number = 0
  rowActive: boolean = false
  typeLocalLists: ListReference[] = []
  typeValeurLists: ListReference[] = []
  activitesLists: ListReference[] = []
  authUser$: User

  get folderName () {
    return function (search: SearchRef) {
      if (search.legalFolder) {
        return search.legalFolder.name
      } else if (search.settledFolder) {
        return search.settledFolder.name
      } else if (search.folder) {
        return search.folder.name
      } else {
        return ''
      }
    }
  }

  get typeLocalName () {
    const self = this
    return function (search: SearchRef) {
      if (search.typeLocal) {
        const typeLocal = self.typeLocalLists.find((item: ListReference) => item.num === search.typeLocal)
        return typeLocal ? typeLocal.label : ''
      } else {
        return ''
      }
    }
  }

  get typeValeurName () {
    const self = this
    return function (search: SearchRef) {
      if (search.typeValeur) {
        const typeValeur = self.typeValeurLists.find((item: ListReference) => item.num === search.typeValeur)
        return typeValeur ? typeValeur.label : ''
      } else {
        return ''
      }
    }
  }

  get activitesName () {
    const self = this
    return function (search: SearchRef) {
      if (search.activites) {
        const activitesLabel = search.activites.map((id: number) => {
          const activites = self.activitesLists.find((item: ListReference) => item.num === id)
          return activites ? activites.label : ''
        })
        return activitesLabel.join(', ')
      } else {
        return ''
      }
    }
  }

  get resultCount () {
    return function (result: any) {
      let total = 0
      result.forEach((item: any) => {
        if (item.data) {
          total += item.data.length
        }
      })
      return total
    }
  }

  getSearches () {
    saveSearchService.getSearches()
  }

  getSearchesUserSearches () {
    if (this.authUser$ && this.authUser$.id) {
      saveSearchService.getUserSearches(this.authUser$.id)
    }
  }

  created () {
    this.getSearchesUserSearches()
    listReferenceService.getListReference().then(res => {
      this.typeLocalLists = res.filter((item: ListReference) => item.type === 1)
      this.activitesLists = res.filter((item: ListReference) => item.type === 3)
      this.typeValeurLists = res.filter((item: ListReference) => item.type === 11)
    })
  }

  handleCurrentChange () {
    this.rowActive = true
  }

  handleSortChange (event: any) {
    this.pageParams$.sortField = event.prop
    this.pageParams$.sortAsc = event.order
    this.getSearches()
  }

  onChangeCurrent (currentPage: number) {
    this.pageParams$.currentPage = currentPage
    this.getSearches()
  }

  onChangeSizePage (pageSize: number) {
    localStorage.setItem('pageSize', `${pageSize}`)
    this.pageParams$.pageSize = pageSize
    this.getSearches()
  }
}
