export const generalInfoItemList: any = [
  {
    label: 'Source',
    typeElement: 'input',
    value: 'source',
    checkFloat: false,
    optionList: ''
  },
  {
    label: 'Date de la source',
    typeElement: 'date',
    value: 'dateSource',
    checkFloat: false,
    optionList: ''
  },
  {
    label: 'Fiabilité',
    typeElement: 'select',
    value: 'fiabilite',
    checkFloat: false,
    optionList: 'fiabilite'
  },
  {
    label: 'Confidentielle',
    typeElement: 'checkbox',
    value: 'confidentialite',
    checkFloat: false,
    optionList: ''
  },
  {
    label: 'Enseigne / locataire',
    typeElement: 'input',
    value: 'enseigneLocataire',
    checkFloat: false,
    optionList: ''
  },
  {
    label: 'Activité',
    typeElement: 'selectGroup',
    value: 'activites',
    checkFloat: false,
    optionList: 'activites'
  },
  {
    label: 'Classement',
    typeElement: 'input',
    value: 'classement',
    checkFloat: true,
    optionList: '',
    isFloat: false
  }
]

export const surfaceCapaciteItemList: any = [
  {
    label: 'Surface m2',
    typeElement: 'input',
    value: 'surfaceM2',
    checkFloat: true,
    optionList: '',
    isFloat: true
  },
  {
    label: 'Surface pondérée',
    typeElement: 'input',
    value: 'surfacePonderee',
    checkFloat: true,
    optionList: '',
    isFloat: true
  },
  {
    label: 'Type de pondération',
    typeElement: 'select',
    value: 'typePonderation',
    checkFloat: false,
    optionList: 'typePonderation'
  },
  {
    label: 'm2 SDV',
    typeElement: 'input',
    value: 'm2Sdv',
    checkFloat: true,
    optionList: '',
    isFloat: true
  },
  {
    label: 'Nombre de places',
    typeElement: 'input',
    value: 'nombrePlace',
    checkFloat: true,
    optionList: '',
    isFloat: false
  },
  {
    label: 'Nombre de lots',
    typeElement: 'input',
    value: 'nombreLots',
    checkFloat: true,
    optionList: '',
    isFloat: false
  },
  {
    label: 'Nombre de chambres',
    typeElement: 'input',
    value: 'nombreChambres',
    checkFloat: true,
    optionList: '',
    isFloat: false
  },
  {
    label: 'Nombre de lits',
    typeElement: 'input',
    value: 'nombreLits',
    checkFloat: true,
    optionList: '',
    isFloat: false
  },
  {
    label: 'Nombre de fauteuils',
    typeElement: 'input',
    value: 'nombreFauteuil',
    checkFloat: true,
    optionList: '',
    isFloat: false
  }
]

export const loyersPrixItemList: any = [
  {
    label: 'Loyer annuel €',
    typeElement: 'input',
    value: 'loyerAnnuel',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: 'Loyer €/m2',
    typeElement: 'input',
    value: 'loyerM2',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: 'Loyer €/m2 P',
    typeElement: 'input',
    value: 'loyerP',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: 'Capital €',
    typeElement: 'input',
    value: 'capitalPrice',
    checkFloat: true,
    optionList: '',
    condition: 'locative',
    isFloat: true
  },
  {
    label: 'Nature du capital',
    typeElement: 'select',
    value: 'natureCapital',
    checkFloat: false,
    optionList: 'natureCapital',
    condition: 'locative'
  },
  {
    label: 'Coeff. d\'amortissement',
    typeElement: 'input',
    value: 'coeffDamortissement',
    checkFloat: true,
    optionList: '',
    condition: 'locative',
    isFloat: true
  },
  {
    label: 'Loyer décapitalisé €/m2 P',
    typeElement: 'input',
    value: 'loyerDecapitalise',
    checkFloat: true,
    optionList: '',
    condition: 'locative',
    isFloat: true
  },
  {
    label: 'Loyer/place',
    typeElement: 'input',
    value: 'loyerPlace',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: 'Loyer/Chambre',
    typeElement: 'input',
    value: 'loyerChambre',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: 'Loyer/Lit',
    typeElement: 'input',
    value: 'loyerLit',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: 'Loyer/fauteuil',
    typeElement: 'input',
    value: 'loyerFauteuil',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: 'Prix € HD',
    typeElement: 'input',
    value: 'prixHd',
    checkFloat: true,
    optionList: '',
    condition: 'valeur',
    isFloat: true
  },
  {
    label: 'Prix € DI',
    typeElement: 'input',
    value: 'prixDi',
    checkFloat: true,
    optionList: '',
    condition: 'valeur',
    isFloat: true
  },
  {
    label: 'Droits %',
    typeElement: 'input',
    value: 'droitsPercent',
    checkFloat: true,
    optionList: '',
    condition: 'valeur',
    isFloat: true
  },
  {
    label: 'Prix €/ m2 HD',
    typeElement: 'input',
    value: 'prixM2',
    checkFloat: true,
    optionList: '',
    condition: 'valeur',
    isFloat: true
  },
  {
    label: 'Prix € HD/ chambre',
    typeElement: 'input',
    value: 'prixChambre',
    checkFloat: true,
    optionList: '',
    condition: 'valeur',
    isFloat: true
  },
  {
    label: 'Prix € HD / lit',
    typeElement: 'input',
    value: 'prixLit',
    checkFloat: true,
    optionList: '',
    condition: 'valeur',
    isFloat: true
  },
  {
    label: 'Prix €HD / fauteuil',
    typeElement: 'input',
    value: 'prixFauteuil',
    checkFloat: true,
    optionList: '',
    condition: 'valeur',
    isFloat: true
  },
  {
    label: 'Prix € HD/ Place',
    typeElement: 'input',
    value: 'prixPlace',
    checkFloat: true,
    optionList: '',
    condition: 'valeur',
    isFloat: true
  },
  {
    label: 'Taux de rendement %',
    typeElement: 'input',
    value: 'tauxRendement',
    checkFloat: true,
    optionList: '',
    condition: 'valeur',
    transaction: 'Murs',
    isFloat: true
  },
  {
    label: 'Taux de capitalisation',
    typeElement: 'input',
    value: 'tauxCapitalisation',
    checkFloat: true,
    optionList: '',
    condition: 'valeur',
    transaction: 'Murs',
    isFloat: true
  },
  {
    label: '% Prix € HD / CA',
    typeElement: 'input',
    value: 'prixHdPerCa',
    checkFloat: true,
    optionList: '',
    condition: 'valeur',
    transaction: 'Fonds',
    isFloat: true
  },
  {
    label: 'Multiple EBE',
    typeElement: 'input',
    value: 'multipleEbe',
    checkFloat: true,
    optionList: '',
    condition: 'valeur',
    transaction: 'Fonds',
    isFloat: true
  },
  {
    label: 'Ventilation Murs/Fonds',
    typeElement: 'input',
    value: 'ventilationMursFonds',
    checkFloat: false,
    optionList: '',
    condition: 'valeur',
    transaction: 'Murs et fonds',
    isFloat: true
  },
  {
    label: 'Multiple de loyer',
    typeElement: 'input',
    value: 'multipleLoyer',
    checkFloat: true,
    optionList: '',
    condition: 'valeur',
    transaction: 'Droit au bail',
    isFloat: true
  }
]

export const performanceItemList: any = [
  {
    label: 'CA €',
    typeElement: 'input',
    value: 'mountCa',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: 'Période CA',
    typeElement: 'input',
    value: 'periodeCa',
    checkFloat: false,
    optionList: '',
    condition: 'base'
  },
  {
    label: '% Loyer / CA',
    typeElement: 'input',
    value: 'loyerPerCa',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: 'EBE',
    typeElement: 'input',
    value: 'ebe',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: 'EBITDAR',
    typeElement: 'input',
    value: 'ebitdar',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: 'CA par lit',
    typeElement: 'input',
    value: 'caParLit',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: 'CA par chambre',
    typeElement: 'input',
    value: 'caParChambre',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: '% EBE / CA',
    typeElement: 'input',
    value: 'ebePerCA',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: '% EBITDAR / CA',
    typeElement: 'input',
    value: 'ebitdarPerCA',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: 'CA/place',
    typeElement: 'input',
    value: 'caPerPlace',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: 'CA €/ m2 SDV',
    typeElement: 'input',
    value: 'caSdv',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: 'CA/ fauteuil',
    typeElement: 'input',
    value: 'caFauteuil',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: 'Taux d\'occupation %',
    typeElement: 'input',
    value: 'tauxOccupation',
    checkFloat: true,
    optionList: '',
    condition: 'base',
    isFloat: true
  },
  {
    label: 'Remise à la clientèle %',
    typeElement: 'input',
    value: 'remiseClientele',
    checkFloat: true,
    optionList: '',
    condition: 'locative',
    isFloat: true
  },
  {
    label: '% loyer/recette',
    typeElement: 'input',
    value: 'loyerRecette',
    checkFloat: true,
    optionList: '',
    condition: 'locative',
    isFloat: true
  },
  {
    label: 'PMC €',
    typeElement: 'input',
    value: 'pmcPrice',
    checkFloat: true,
    optionList: '',
    condition: 'locative',
    isFloat: true
  },
  {
    label: 'Revpar',
    typeElement: 'input',
    value: 'revpar',
    checkFloat: true,
    optionList: '',
    condition: 'locative',
    isFloat: true
  },
  {
    label: '% nuité OTA',
    typeElement: 'input',
    value: 'nuiteOta',
    checkFloat: true,
    optionList: '',
    condition: 'locative',
    isFloat: true
  },
  {
    label: '% Loyer / EBE',
    typeElement: 'input',
    value: 'loyerPerEbe',
    checkFloat: true,
    optionList: '',
    condition: 'locative',
    isFloat: true
  },
  {
    label: '% Loyer / EBITDAR',
    typeElement: 'input',
    value: 'loyerPerEbitdar',
    checkFloat: true,
    optionList: '',
    condition: 'locative',
    isFloat: true
  },
  {
    label: 'Abattements L311-1 Code du Tourisme',
    typeElement: 'input',
    value: 'abattementsTourisme',
    checkFloat: true,
    optionList: '',
    condition: 'locative',
    isFloat: true
  }
]

export const informationsBailItemList: any = [
  {
    label: 'Destination',
    typeElement: 'input',
    value: 'destination',
    checkFloat: false,
    optionList: ''
  },
  {
    label: 'Impot foncier',
    typeElement: 'select',
    value: 'impotFoncier',
    checkFloat: false,
    optionList: 'impotFoncier'
  },
  {
    label: 'Assurance immeuble',
    typeElement: 'select',
    value: 'assuranceImmeuble',
    checkFloat: false,
    optionList: 'impotFoncier'
  },
  {
    label: 'Honoraire de gestion technique',
    typeElement: 'select',
    value: 'honoraireGestionTechnique',
    checkFloat: false,
    optionList: 'impotFoncier'
  },
  {
    label: 'Mise en conformité',
    typeElement: 'select',
    value: 'miseConfomite',
    checkFloat: false,
    optionList: 'impotFoncier'
  }
]

export const jugementItemList: any = [
  {
    label: 'Tribunal',
    typeElement: 'input',
    value: 'tribunal',
    checkFloat: false,
    optionList: ''
  },
  {
    label: 'Date de la décision',
    typeElement: 'date',
    value: 'dateDecision',
    checkFloat: false,
    optionList: ''
  },
  {
    label: 'Abattements / Majorations',
    typeElement: 'textarea',
    value: 'abattementsMajorations',
    checkFloat: false,
    optionList: ''
  },
  {
    label: 'Motif de déplafonnement',
    typeElement: 'select',
    value: 'motifDeplafonnement',
    checkFloat: false,
    optionList: 'motifDeplafonnement'
  },
  {
    label: 'Détail FLC',
    typeElement: 'input',
    value: 'detailFlc',
    checkFloat: false,
    optionList: ''
  }
]

export const informationsVenteItemList: any = [
  {
    label: 'Acquéreur',
    prop: 'acquereur',
    typeElement: 'input',
    value: 'acquereur',
    checkFloat: false,
    optionList: ''
  },
  {
    label: 'RCS Acquéreur',
    prop: 'acquereurRcs',
    typeElement: 'input',
    value: 'acquereurRcs',
    checkFloat: false,
    optionList: ''
  },
  {
    label: 'Vendeur',
    prop: 'vendeur',
    typeElement: 'input',
    value: 'vendeur',
    checkFloat: false,
    optionList: ''
  },
  {
    label: 'RCS Vendeur',
    prop: 'vendeurRcs',
    typeElement: 'input',
    value: 'vendeurRcs',
    checkFloat: false,
    optionList: ''
  }
]

export const tableBaseItems: any = [
  {
    label: 'Activité',
    value: 'activites'
  },
  {
    label: 'Classement',
    value: 'classement'
  },
  {
    label: 'Nombre de chambres',
    value: 'nombreChambres'
  },
  {
    label: 'Loyer/Chambre',
    value: 'loyerChambre'
  },
  {
    label: 'Nombre de lits',
    value: 'nombreLits'
  },
  {
    label: 'Loyer/Lit',
    value: 'loyerLit'
  },
  {
    label: 'Nombre de fauteuils',
    value: 'nombreFauteuil'
  },
  {
    label: 'Loyer/fauteuil',
    value: 'loyerFauteuil'
  }
]

export const tableLocativeItems: any = [
  {
    label: 'Enseigne / locataire',
    value: 'enseigneLocataire',
    transaction: 'any',
    shortLabel: 'Enseigne',
    type: 'string'
  },
  {
    label: 'Surface pondérée',
    value: 'surfacePonderee',
    transaction: 'any',
    type: 'string'
  },
  {
    label: 'Loyer €/m2 P',
    value: 'loyerP',
    transaction: 'any',
    type: 'string'
  },
  {
    label: 'Coeff. d\' amortissement',
    value: 'coeffDamortissement',
    transaction: 'Nouvelle location',
    shortLabel: 'Coeff. Amort.',
    type: 'string'
  },
  {
    label: 'Loyer décapitalisé €/m2 P',
    value: 'loyerDecapitalise',
    transaction: 'Nouvelle location',
    shortLabel: 'Loyer décap. €/m² P',
    type: 'string'
  },
  {
    label: 'Date de la décision',
    value: 'dateDecision',
    transaction: 'Renouvellement judiciaire',
    type: 'date'
  },
  {
    label: 'Date de la décision',
    value: 'dateDecision',
    transaction: 'Révision judiciaire',
    type: 'date'
  }
]

export const tableValeurItems: any = [
  {
    label: 'Enseigne / locataire',
    value: 'enseigneLocataire',
    transaction: 'any',
    shortLabel: 'Enseigne',
    type: 'string'
  },
  {
    label: 'Surface U',
    value: 'surfaceM2',
    transaction: 'any'
  },
  {
    label: 'Montant HD',
    value: 'prixHd',
    transaction: 'any'
  },
  {
    label: 'Prix/m²U',
    value: 'prixM2',
    transaction: 'any'
  },
  {
    label: 'Loyer annuel HT-HC',
    value: 'loyerAnnuel',
    transaction: 'any'
  },
  {
    label: 'Taux de rendement %',
    value: 'tauxRendement',
    transaction: 'Murs',
    shortLabel: 'Taux rendt.'
  }
]

export const criteriaBaseItemList: any = [
  {
    label: 'Date d\'effet du bail',
    typeElement: 'daterange',
    value: 'dateEffet',
    checkFloat: false,
    optionList: '',
    valeur: 'Valeur locative',
    transaction: 'any'
  },
  {
    label: 'Date de vente',
    typeElement: 'daterange',
    value: 'dateVente',
    checkFloat: false,
    optionList: '',
    valeur: 'Valeur vénale',
    transaction: 'any'
  },
  {
    label: 'Type de pondération',
    prop: '',
    typeElement: 'select',
    value: 'typePonderation',
    checkFloat: false,
    optionList: 'typePonderation',
    valeur: 'any',
    transaction: 'any'
  },
  {
    label: 'Surface pondérée',
    prop: '',
    typeElement: 'rangeInput',
    minValue: 'minSurfacePonderee',
    maxValue: 'maxSurfacePonderee',
    checkFloat: true,
    optionList: '',
    valeur: 'any',
    transaction: 'any'
  },
  {
    label: 'Surface m2',
    prop: '',
    typeElement: 'rangeInput',
    minValue: 'minSurfaceM2',
    maxValue: 'maxSurfaceM2',
    checkFloat: true,
    optionList: '',
    valeur: 'any',
    transaction: 'any'
  },
  {
    label: 'Activité',
    prop: '',
    typeElement: 'selectGroup',
    value: 'activites',
    checkFloat: false,
    optionList: 'activites',
    valeur: 'any',
    transaction: 'any'
  },
  {
    label: 'Capital €',
    prop: '',
    typeElement: 'rangeInput',
    minValue: 'minCapitalPrice',
    maxValue: 'maxCapitalPrice',
    checkFloat: true,
    optionList: '',
    valeur: 'Valeur locative',
    transaction: 'Nouvelle location'
  },
  {
    label: 'Motif de déplafonnement',
    prop: '',
    typeElement: 'select',
    value: 'motifDeplafonnement',
    checkFloat: true,
    optionList: 'motifDeplafonnement',
    valeur: 'Valeur locative',
    transaction: 'Renouvellement judiciaire'
  },
  {
    label: 'n° de fiche (ID)',
    prop: '',
    typeElement: 'input',
    value: 'ficheId',
    checkFloat: true,
    optionList: '',
    valeur: 'any',
    transaction: 'any'
  },
  {
    label: 'Observations générales',
    prop: '',
    typeElement: 'input',
    value: 'observationGenerals',
    checkFloat: false,
    optionList: '',
    valeur: 'any',
    transaction: 'any'
  },
  {
    label: 'Observations importantes',
    prop: '',
    typeElement: 'input',
    value: 'observationImportantes',
    checkFloat: false,
    optionList: '',
    valeur: 'any',
    transaction: 'any'
  },
  {
    label: 'Enseigne / locataire',
    prop: '',
    typeElement: 'input',
    value: 'enseigneLocataire',
    checkFloat: false,
    optionList: '',
    valeur: 'any',
    transaction: 'any'
  },
  {
    label: 'Classement',
    prop: '',
    typeElement: 'input',
    value: 'classement',
    checkFloat: true,
    optionList: '',
    valeur: 'any',
    transaction: 'any'
  },
  {
    label: 'Nombre de chambres',
    prop: '',
    typeElement: 'rangeInput',
    minValue: 'minNbreChambres',
    maxValue: 'maxNbreChambres',
    checkFloat: true,
    optionList: '',
    valeur: 'any',
    transaction: 'any'
  },
  {
    label: 'Nombre de lits',
    prop: '',
    typeElement: 'input',
    value: 'nombreLits',
    checkFloat: true,
    optionList: '',
    valeur: 'any',
    transaction: 'any'
  },
  {
    label: 'Nombre de fauteuils',
    prop: '',
    typeElement: 'input',
    value: 'nombreFauteuils',
    checkFloat: true,
    optionList: '',
    valeur: 'any',
    transaction: 'any'
  },
  {
    label: 'Nature du capital',
    prop: '',
    typeElement: 'checkbox-group',
    value: 'natureCapital',
    checkFloat: false,
    optionList: 'natureCapital',
    valeur: 'Valeur locative',
    transaction: 'any'
  },
  {
    label: 'Destination',
    prop: '',
    typeElement: 'input',
    value: 'destination',
    checkFloat: false,
    optionList: '',
    valeur: 'any',
    transaction: 'any'
  },
  {
    label: 'Date de la décision',
    prop: '',
    typeElement: 'date',
    value: 'dateDecision',
    checkFloat: false,
    optionList: '',
    valeur: 'Valeur locative',
    transaction: 'Renouvellement judiciaire'
  },
  {
    label: 'Acquéreur',
    prop: '',
    typeElement: 'input',
    value: 'acquereur',
    checkFloat: false,
    optionList: '',
    valeur: 'Valeur vénale',
    transaction: 'any'
  },
  {
    label: 'Vendeur',
    prop: '',
    typeElement: 'input',
    value: 'vendeur',
    checkFloat: false,
    optionList: '',
    valeur: 'Valeur vénale',
    transaction: 'any'
  }
]

export const previewItemList: any = [
  {
    label: 'Type de local',
    value: 'typeLocal',
    valeur: 'any',
    transaction: 'any',
    dataType: 'string'
  },
  {
    label: 'Surface m2',
    value: 'surfaceM2',
    valeur: 'any',
    transaction: 'any',
    dataType: 'string',
    unit: 'm2'
  },
  {
    label: 'Enseigne / locataire',
    value: 'enseigneLocataire',
    valeur: 'any',
    transaction: 'any',
    dataType: 'string'
  },
  {
    label: 'Activité',
    value: 'activites',
    valeur: 'any',
    transaction: 'any',
    dataType: 'string'
  },
  {
    label: 'Loyer annuel €',
    value: 'loyerAnnuel',
    valeur: 'any',
    transaction: 'any',
    dataType: 'string',
    unit: '€'
  },
  {
    label: 'Nombre de places',
    value: 'nombrePlaces',
    valeur: 'any',
    transaction: 'any',
    dataType: 'string'
  },
  {
    label: 'Loyer/place',
    value: 'loyerPerPlace',
    valeur: 'any',
    transaction: 'any',
    dataType: 'string'
  },
  {
    label: 'Nombre de lots',
    value: 'nombreLots',
    valeur: 'any',
    transaction: 'any',
    dataType: 'string'
  },
  {
    label: 'Surface pondérée',
    value: 'surfacePonderee',
    valeur: 'any',
    transaction: 'any',
    dataType: 'string',
    unit: 'm2P'
  },
  {
    label: 'Type de pondération',
    value: 'typePonderation',
    valeur: 'any',
    transaction: 'any',
    dataType: 'string'
  },
  {
    label: 'Loyer €/m2 P',
    value: 'loyerP',
    valeur: 'any',
    transaction: 'any',
    dataType: 'string',
    unit: '€/m2 P'
  },
  {
    label: 'Classement',
    value: 'classement',
    valeur: 'any',
    transaction: 'any',
    dataType: 'string'
  },
  {
    label: 'Nombre de chambres',
    value: 'nombreChambres',
    valeur: 'any',
    transaction: 'any',
    dataType: 'string'
  },
  {
    label: 'Loyer/Chambre',
    value: 'loyerChambre',
    valeur: 'any',
    transaction: 'any',
    dataType: 'string',
    unit: '€'
  },
  {
    label: 'Nombre de lits',
    value: 'nombreLits',
    valeur: 'any',
    transaction: 'any',
    dataType: 'string'
  },
  {
    label: 'Loyer/Lit',
    value: 'loyerLit',
    valeur: 'any',
    transaction: 'any',
    dataType: 'string'
  },
  {
    label: 'Nombre de fauteuils',
    value: 'nombreFauteuils',
    valeur: 'any',
    transaction: 'any',
    dataType: 'string'
  },
  {
    label: 'Loyer/fauteuil',
    value: 'loyerFauteuil',
    valeur: 'any',
    transaction: 'any',
    dataType: 'string'
  },
  {
    label: 'Date d\'effet du bail',
    value: 'dateDeffetBailStart',
    valeur: 'Valeur locative',
    transaction: 'any',
    dataType: 'date'
  },
  {
    label: 'Capital €',
    value: 'capitalPrice',
    valeur: 'Valeur locative',
    transaction: 'Nouvelle location',
    dataType: 'string',
    unit: '€'
  },
  {
    label: 'Coeff. d\'amortissement',
    value: 'coeffDamortissement',
    valeur: 'Valeur locative',
    transaction: 'Nouvelle location',
    dataType: 'string'
  },
  {
    label: 'Loyer décapitalisé €/m2 P',
    value: 'loyerDecapitalise',
    valeur: 'Valeur locative',
    transaction: 'Nouvelle location',
    dataType: 'string',
    unit: '€/m2 P'
  },
  {
    label: 'Date de la décision',
    value: 'dateDecision',
    valeur: 'Valeur locative',
    transaction: 'Renouvellement judiciaire',
    dataType: 'date'
  },
  {
    label: 'Date de la décision',
    value: 'dateDecision',
    valeur: 'Valeur locative',
    transaction: 'Révision judiciaire',
    dataType: 'date'
  },
  {
    label: 'Prix €/ m2 HD',
    value: 'prixM2',
    valeur: 'Valeur vénale',
    transaction: 'any',
    dataType: 'string',
    unit: '€/ m2 HD'
  },
  {
    label: 'Prix  € HD',
    value: 'prixHd',
    valeur: 'Valeur vénale',
    transaction: 'any',
    dataType: 'string',
    unit: '€ HD'
  },
  {
    label: 'Date de vente',
    value: 'dateVenteStart',
    valeur: 'Valeur vénale',
    transaction: 'any',
    dataType: 'date'
  },
  {
    label: 'Prix € HD/ chambre',
    value: 'prixChambre',
    valeur: 'Valeur vénale',
    transaction: 'any',
    dataType: 'string',
    unit: '€'
  },
  {
    label: 'Prix € HD / lit',
    value: 'prixLit',
    valeur: 'Valeur vénale',
    transaction: 'any',
    dataType: 'string',
    unit: '€'
  },
  {
    label: 'Prix €HD / fauteuil',
    value: 'prixFauteuil',
    valeur: 'Valeur vénale',
    transaction: 'any',
    dataType: 'string',
    unit: '€'
  },
  {
    label: 'Prix € HD/ Place',
    value: 'prixPlace',
    valeur: 'Valeur vénale',
    transaction: 'any',
    dataType: 'string',
    unit: '€'
  },
  {
    label: 'Taux de rendement %',
    value: 'tauxRendement',
    valeur: 'Valeur vénale',
    transaction: 'Murs',
    dataType: 'string',
    unit: '%'
  },
  {
    label: '% Prix € HD / CA',
    value: 'prixHdPerCa',
    valeur: 'Valeur vénale',
    transaction: 'Fonds',
    dataType: 'string',
    unit: '€'
  },
  {
    label: 'Multiple EBE',
    value: 'multipleEbe',
    valeur: 'Valeur vénale',
    transaction: 'Fonds',
    dataType: 'string'
  },
  {
    label: 'Ventilation Murs/Fonds',
    value: 'ventilationMursFonds',
    valeur: 'Valeur vénale',
    transaction: 'Murs et fonds',
    dataType: 'string'
  },
  {
    label: 'Multiple de loyer',
    value: 'multipleLoyer',
    valeur: 'Valeur vénale',
    transaction: 'Droit au bail',
    dataType: 'string'
  }
]
