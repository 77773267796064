<template>
  <el-dialog
    title="Recherche sans titre"
    :visible.sync="isModalVisible"
    width="30%"
    class="modal-rb">
    <el-form
      :inline="true"
      class="modal-form"
      @submit.native.prevent="saveHistory">
      <el-input
        v-model="title"
        placeholder="Nom de la recherche" />
      <div class="modal-rb-footer d-flex align-items-center justify-content-center">
        <el-button
          class="btn-rb ml-2 mr-2"
          @click="saveHistory">
          Enregistrer
        </el-button>
        <el-button
          class="btn-rb btn-red ml-2 mr-2"
          @click="isModalVisible = !isModalVisible">
          Annuler
        </el-button>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
import { historyService } from '@/services/history.service'
import { searchGlobalItemsService } from '@/services/searchGlobalItems.service'
import { tap } from 'rxjs/operators'

export default {
  subscriptions () {
    return {
      loading$: searchGlobalItemsService.onLoading,
      globalItems$: searchGlobalItemsService.onChangeGlobalItems.pipe(tap((res) => {
        if (res) {
          res.forEach(item => {
            item.isPin = false
          })
          this.globalItems = res
        }
      })),
      globalItemsTotal$: searchGlobalItemsService.onChangeGlobalItemsTotal.pipe(tap((res) => {
        if (res) {
          this.globalItemsTotal = res
        }
      })),
      globalItemSearch$: searchGlobalItemsService.onChangeGlobalItemsFilters.pipe(tap((res) => {
        if (res) {
          this.globalItemSearch = res
        }
      })),
      currentHistory$: historyService.onChangeCurrentHistory.pipe(tap((res) => {
        if (res.title && !this.isNewSave) {
          this.title = res.title
        }
        if (res.id) {
          this.id = res.id
        }
        if (res.result) {
          this.globalItems = res.result.result
          this.globalItemsTotal = res.result.total
          this.globalItemSearch = res.criteria
        }
      }))
    }
  },
  props: {
    isNewSave: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      isModalVisible: false,
      title: '',
      id: null,
      globalItems: null,
      globalItemsTotal: 0,
      globalItemSearch: null
    }
  },
  methods: {
    open () {
      this.isModalVisible = true
    },
    close () {
      this.isModalVisible = false
    },
    saveHistory () {
      if (this.id === null || this.isNewSave === true) {
        historyService.saveHistory(this.title, this.globalItemSearch, this.globalItems, this.globalItemsTotal).then(
          () => {
            this.isModalVisible = false
          }
        )
      } else {
        historyService.updateHistory(this.id, this.title, this.globalItemSearch, this.globalItems, this.globalItemsTotal).then(
          () => {
            this.isModalVisible = false
          }
        )
      }
    }
  }
}
</script>
