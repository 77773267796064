import { apiClient } from '@/services/api.client'
import { BehaviorSubject, Observable } from 'rxjs'

export class ContactLink {
  id: string
  contactId: string
  linkContactId: string
  linkType: string
  name: string
  comment: string
  constructor (contactLink: any) {
    this.id = contactLink.id
    this.contactId = contactLink.contactId
    this.linkContactId = contactLink.linkContactId
    this.linkType = contactLink.linkType
    this.name = contactLink.name
    this.comment = contactLink.comment
  }
}

class ContactLinkService {
  private readonly _contactLink: BehaviorSubject<ContactLink | any> = new BehaviorSubject<ContactLink | any>({})
  private readonly _contactLinks: BehaviorSubject<ContactLink[]> = new BehaviorSubject<ContactLink[]>([])
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  public onChangeContactLink: Observable<ContactLink | any> = this._contactLink.asObservable()
  public onChange: Observable<ContactLink[]> = this._contactLinks.asObservable()
  public onLoading: Observable<boolean> = this._loading.asObservable()

  get contactLinks (): ContactLink[] {
    return this._contactLinks.getValue()
  }

  public async getContactLinks (contactId: string): Promise<any> {
    this._loading.next(true)

    return await apiClient.get('contact-links/' + contactId).then(res => {
      if (res.data) {
        const contactLinks: ContactLink[] = []
        const contactLink: ContactLink = {
          id: '',
          contactId: '',
          linkContactId: '',
          linkType: '',
          name: '',
          comment: ''
        }
        res.data.forEach((item: any) => {
          if (item.contact.deletedAt == null) {
            contactLink.id = item.id
            contactLink.contactId = item.contact.id
            contactLink.linkContactId = item.linkContact.id
            contactLink.linkType = item.linkType
            contactLink.name = item.name
            contactLink.comment = item.comment
            contactLinks.push(new ContactLink(contactLink))
          }
        })
        this._contactLinks.next(contactLinks)
        this._loading.next(false)
        return res.data
      }
    })
  }

  public async saveContactLink (id: any, contactId: string, linkContactId: string, linkType: string, name: string, comment: string): Promise<void> {
    this._loading.next(true)
    const contactLink: ContactLink = {
      id: '',
      contactId: '',
      linkContactId: '',
      linkType: '',
      name: '',
      comment: ''
    }
    await apiClient.post('contact-link', { id, contactId, linkContactId, linkType, name, comment }).then(res => {
      contactLink.id = res.data.id
      contactLink.contactId = res.data.contact.id
      contactLink.linkContactId = res.data.linkContact.id
      contactLink.linkType = res.data.linkType
      contactLink.name = res.data.name
      contactLink.comment = res.data.comment
      this._contactLink.next(new ContactLink(contactLink))
      this._loading.next(false)
    })
  }
}

export const contactLinkService = new ContactLinkService()
