
import { Component, Prop, Vue } from 'vue-property-decorator'
import { locativeBlockItems } from '../blockItems'
import { jugementItemList } from '../itemList'
import { floatRule } from '../formRules'
import { referenceService } from '@/services/listReference.service'

@Component({
  subscriptions (this: any) {
    return {
      value: referenceService.onChangeReference,
      isMigratedRef: referenceService.onChangeIsMigratedRef
    }
  }
})
export default class Jugement extends Vue {
  @Prop({ type: Object })
    optionList: any

  // @Prop({ type: Object })
  value: any
  @Prop({ type: String })
    localLabel!: any

  @Prop({ type: Number })
    typeValeur!: any

  isMigratedRef = false
  floatRule = floatRule
  datePickerOptions: any = {}
  jugementItemList = jugementItemList

  checkDisplayCondition (title: string) {
    if (this.isMigratedRef) {
      return true
    }
    const localLabel = this.localLabel
    return (
      this.typeValeur === 1 &&
      locativeBlockItems[localLabel] &&
      !locativeBlockItems[localLabel].includes(title)
    )
  }

  changeSelect (item: any) {
    if (this.value[item.value] === 0) {
      this.value[item.value] = null
    }
  }
}
