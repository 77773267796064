import { apiClient } from '@/services/api.client'
import { BehaviorSubject, Observable } from 'rxjs'
import { Ged } from '@/models/ged.model'
import { Contact } from './contact.service'
import { ReferenceAddress } from '@/services/listReference.service'

class GeoDistance {
  distance: number
  lat: number
  lon: number
  constructor (data: any = {}) {
    this.distance = data.distance || 0
    this.lat = data.lat || 0
    this.lon = data.lon || 0
  }
}

export class GlobalItemSearch {
  fields: string
  queryType: string
  queryString: string
  isAdvanced: boolean
  objectType: string
  advancedSearchContent: unknown
  limit: number
  offset: number
  location: GeoDistance
  filtersCount: number
  constructor (data: any = {}) {
    this.fields = data.fields || SEARCH_TYPE.ADDRESS
    this.queryType = data.queryType || 'contain'
    this.queryString = data.queryString
    this.limit = data.limit || 10000
    this.offset = data.offset || 0
    this.location = data.location || new GeoDistance({})
    this.isAdvanced = data.isAdvanced || false
    this.advancedSearchContent = data.advancedSearchContent || {}
    this.objectType = data.objectType || 'folder'
    this.filtersCount = data.filtersCount || 0
  }
}

export const enum SEARCH_TYPE {
  ADDRESS = 'ged.adresses.adresse_inner,folder.adresses.label_inner,contact.adresses.label_inner,reference.adresse_inner,fodegi.adresse_inner',
  CONTACT_NAME = 'contact.fullname_inner,folder.contacts.contactName_inner',
  QUOTE_FOLDER_NAME = 'folder.name_inner',
  GED_NAME = 'ged.name_inner',
  PHONE_NUMBER = 'folder.contacts.phoneNumber_inner,contact.phoneNumber_inner,contact.phoneNumber,folder.contacts.phoneNumber',
  GED_TAG = 'ged.tags_inner',
}

class GlobalItem {
  id: number
  objectType: string
  ged: Ged
  contact: Contact
  isPin: boolean
  iseye: boolean
  isassoc: boolean

  constructor (data: any = {}) {
    this.id = data.id
    this.objectType = data.type
    this.ged = data.ged
    this.contact = data.contact
    this.isPin = data.isPin
    this.iseye = data.iseye
    this.isassoc = data.isassoc
  }
}

class FolderFilter {
  folder_type: string
  usersIds: number[] = []
  creator: boolean
  writer: boolean
  reviewer: boolean
  signer: boolean
  contacts: string[] = []
  mandator: boolean
  interlocutor: boolean
  address: unknown
  typeExpert: string
  typeLocal: string
  sectionNumber: number
  status: number[] = []
  open: unknown
  close: unknown
  hasPendingAmount: number
  hasBalanceAmount: number
  invoiceWithNumber: boolean
  typeInvoice: string
  invoice: unknown
  number: string
  billNumber: unknown
  typeDebours: string
  debours: unknown
  caYear: string
  caType: string
  caDate: string
  constructor (data: any = {}) {
    this.folder_type = data.folder_type
    this.usersIds = data.usersIds || []
    this.creator = data.creator
    this.writer = data.writer
    this.reviewer = data.reviewer
    this.signer = data.signer
    this.contacts = data.contacts || []
    this.mandator = data.mandator
    this.interlocutor = data.interlocutor
    this.address = data.address || {}
    this.typeExpert = data.typeExpert
    this.typeLocal = data.typeLocal
    this.sectionNumber = data.sectionNumber
    this.status = data.status || []
    this.open = data.open || {}
    this.close = data.close || {}
    this.hasPendingAmount = data.hasPendingAmount
    this.hasBalanceAmount = data.hasBalanceAmount
    this.invoiceWithNumber = data.invoiceWithNumber
    this.typeInvoice = data.typeInvoice
    this.invoice = data.invoice || {}
    this.number = data.number
    this.billNumber = data.billNumber || {}
    this.typeDebours = data.typeDebours
    this.debours = data.debours || {}
    this.caYear = data.caYear
    this.caType = data.caType
    this.caDate = data.caDate
  }
}

class GedFilter {
  name: string
  source: string
  address: string
  theme: string
  content: string
  dateMaxi: string
  dateMini: string
  constructor (data: any = {}) {
    this.name = data.name
    this.source = data.source
    this.address = data.address
    this.theme = data.theme
    this.content = data.content
    this.dateMaxi = data.dateMaxi
    this.dateMini = data.dateMini
  }
}

class ContactFilter {
  type: string
  title: string
  juridique: boolean
  firstname: string
  lastname: string
  brand: string
  siret: string
  activite: string
  constructor (data: any = {}) {
    this.type = data.type
    this.title = data.title
    this.juridique = data.juridique
    this.firstname = data.firstname
    this.lastname = data.lastname
    this.brand = data.brand
    this.siret = data.siret
    this.activite = data.activite
  }
}

class ReferenceFilter {
  title: string
  folderName: string
  peliasAddress: ReferenceAddress
  creatorId: number | null
  dateMini: string
  dateMaxi: string
  typeValeur: number
  typeLocal: number
  activites: number
  typeTransaction: number
  enseigneLocataire: string
  constructor (data: any = {}) {
    this.title = data.title
    this.folderName = data.folderName
    if (data.peliasAddress) {
      this.peliasAddress = new ReferenceAddress(data.peliasAddress)
    }
    this.creatorId = data.creatorId
    this.dateMini = data.dateMini
    this.dateMaxi = data.dateMaxi
    this.typeValeur = data.typeValeur
    this.typeLocal = data.typeLocal
    this.activites = data.activites
    this.typeTransaction = data.typeTransaction
    this.enseigneLocataire = data.enseigneLocataire
  }
}

class SearchGlobalItemsService {
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  private readonly _activeId: BehaviorSubject<string> = new BehaviorSubject<string>('')
  private readonly _globalItems: BehaviorSubject<GlobalItem[]> = new BehaviorSubject<GlobalItem[]>([])
  private readonly _globalItemsTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  private readonly _filtersCount: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  private readonly _gedFilters: BehaviorSubject<GedFilter> = new BehaviorSubject<GedFilter>(new GedFilter())
  private readonly _globalItemsFilters: BehaviorSubject<GlobalItemSearch> = new BehaviorSubject<GlobalItemSearch>(new GlobalItemSearch())
  private readonly _contactFilters: BehaviorSubject<ContactFilter> = new BehaviorSubject<ContactFilter>(new ContactFilter())
  private readonly _folderFilters: BehaviorSubject<FolderFilter> = new BehaviorSubject<FolderFilter>(new FolderFilter())
  private readonly _referenceFilters: BehaviorSubject<ReferenceFilter> = new BehaviorSubject<ReferenceFilter>(new ReferenceFilter())
  private readonly addressMap = new Map()
  private readonly _isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  public onLoading: Observable<boolean> = this._loading.asObservable()
  public onChangeActiveId: Observable<string> = this._activeId.asObservable()
  public onChangeGlobalItems: Observable<GlobalItem[]> = this._globalItems.asObservable()
  public onChangeGlobalItemsTotal: Observable<number> = this._globalItemsTotal.asObservable()
  public onChangeFiltersCount: Observable<number> = this._filtersCount.asObservable()
  public onChangeGedFilters: Observable<unknown> = this._gedFilters.asObservable()
  public onChangeReferenceFilters: Observable<unknown> = this._referenceFilters.asObservable()
  public onChangeGlobalItemsFilters: Observable<GlobalItemSearch> = this._globalItemsFilters.asObservable()
  public onChangeContactFilters: Observable<ContactFilter> = this._contactFilters.asObservable()
  public onChangeFolderFilters: Observable<FolderFilter> = this._folderFilters.asObservable()
  public onChangeLoading: Observable<boolean> = this._isLoading.asObservable()

  public async search (globalItemSearch: GlobalItemSearch, loadMore = false, prevGlobalItems = []): Promise<any> {
    this._loading.next(true)
    try {
      if (globalItemSearch.fields === SEARCH_TYPE.ADDRESS) {
        const { status, data } = await this.findAddressPosition(globalItemSearch.queryString)
        if (status === 200 && globalItemSearch.location.distance > 0) {
          globalItemSearch.location.lat = data.lat
          globalItemSearch.location.lon = data.lng
        }
      }
    } catch (error) {
      globalItemSearch.location = new GeoDistance({})
    }
    globalItemSearch.filtersCount = this._filtersCount.value
    this._globalItemsFilters.next(globalItemSearch)
    await apiClient.post('global-search-items', globalItemSearch).then(res => {
      if (res.data) {
        if (loadMore && prevGlobalItems) {
          this._globalItems.next([...prevGlobalItems, ...res.data.items])
        } else {
          this._globalItems.next(res.data.items)
          this._globalItemsTotal.next(res.data.total)
          if (res.data.total <= 0) this._isLoading.next(false)
        }
      }
      this._loading.next(false)
    }).catch(() => {
      this._loading.next(false)
    })
  }

  public setFiltersCount (number: number): void {
    this._filtersCount.next(number)
  }

  public resetFilters (): void {
    this._gedFilters.next(new GedFilter({}))
    this._folderFilters.next(new FolderFilter({}))
    this._contactFilters.next(new ContactFilter({}))
    this._referenceFilters.next(new ReferenceFilter({}))
    this.setFiltersCount(0)
  }

  public async exportGlobalItems (globalItemSearch: GlobalItemSearch): Promise<any> {
    let data = null
    this._loading.next(true)
    await apiClient.post('global-search-items', globalItemSearch).then(res => {
      if (res.data) {
        data = res.data.items
      }
      this._loading.next(false)
    }).catch(() => {
      this._loading.next(false)
    })
    return await apiClient.post('export-global-search', { search: data }, {
      responseType: 'blob'
    }).then((res: any) => {
      this._loading.next(false)
      return res
    })
  }

  public async findAddressPosition (address: string): Promise<any> {
    const pos = this.addressMap.get(address)
    if (pos !== undefined) {
      return pos
    }
    if (!address) {
      return
    }
    this._loading.next(true)
    const res = await apiClient.get('global-search-find-position', { params: { address } })
    this._loading.next(false)
    this.addressMap.set(address, res)
    return res
  }

  public changeActiveId (id: string): void {
    this._activeId.next(id)
  }

  public setIsLoading (value: boolean): void {
    this._isLoading.next(value)
  }
}

export const searchGlobalItemsService = new SearchGlobalItemsService()
