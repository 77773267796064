
import { Component, Vue } from 'vue-property-decorator'
import { referenceService } from '@/services/listReference.service'

@Component({
  subscriptions (this: any) {
    return {
      value: referenceService.onChangeReference
    }
  }
})
export default class ObservationImportantes extends Vue {
  value!: any
}
