
import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import { Contact, contactService } from '@/services/contact.service'
import { quoteRouteName, settledFolderRouteName } from '@/utils/constant'
import {
  ContactLink,
  contactLinkService
} from '@/services/contactLink.service'
import {
  ContactAddress,
  contactAddressService
} from '@/services/contactAddress.service'
import { Form } from 'element-ui/types'
import { listReferenceService, ReferenceAddress } from '@/services/listReference.service'
import { quoteService } from '@/services/quote.service'

@Component({
  name: 'ContactFields',
  subscriptions (this: Vue) {
    return {
      contactLink: contactLinkService.onChangeContactLink,
      contactLinks: contactLinkService.onChange,
      contacts: contactService.onChange,
      contactAddress: contactAddressService.onChangeContactAddress,
      contactAddresses: contactAddressService.onChange
    }
  },
  watch: {
    $route () {
      (this as any).$router.go()
    }
  },
  computed: {
    quoteRouteName () {
      return quoteRouteName
    }
  }
})
export default class ContactFields extends Vue {
  @Prop({ default: false }) isContactDialog: boolean
  @Ref() readonly contactForm!: Form
  contacts: Contact[]
  contact: Contact = new Contact()
  contactLink: ContactLink = {
    id: '',
    contactId: '',
    linkContactId: '',
    linkType: '',
    name: '',
    comment: ''
  }

  contactAddress: ContactAddress = new ContactAddress({})
  contactLinks: ContactLink[] = []
  contactAddresses: ContactAddress[] = []
  options_title = [
    /* {
      value: "Indivision",
      label: "Indivision"
    },
    {
      value: "Consorts",
      label: "Consorts"
    }, */
    {
      value: 'Maître',
      label: 'Maître'
    },
    {
      value: 'Monsieur',
      label: 'Monsieur'
    },
    {
      value: 'Madame',
      label: 'Madame'
    }
  ]

  link_name_options: any[] = []
  link_type_options = [
    {
      value: 'Employeur',
      label: 'Employeur'
    },
    {
      value: 'Employé',
      label: 'Employé'
    },
    {
      value: 'Capitalistique',
      label: 'Capitalistique'
    },
    {
      value: 'Conseil',
      label: 'Conseil'
    },
    {
      value: 'Gestionnaire',
      label: 'Gestionnaire'
    },
    {
      value: 'Avocat',
      label: 'Avocat'
    },
    {
      value: 'Personnel',
      label: 'Personnel'
    },
    {
      value: 'Autres',
      label: 'Autres'
    }
  ]

  address_type_options = [
    {
      value: 'Bis',
      label: 'Bis'
    },
    {
      value: 'Ter',
      label: 'Ter'
    },
    {
      value: 'Quater',
      label: 'Quater'
    },
    {
      value: 'Quinter',
      label: 'Quinter'
    }
  ]

  rules = {
    nom: [
      { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' }
    ],
    title: [
      { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' }
    ],
    telephone1: [
      { min: 8, message: 'Veuillez entrer un numéro de téléphone valide', trigger: 'blur' }
    ],
    telephone2: [
      { min: 8, message: 'Veuillez entrer un numéro de téléphone valide', trigger: 'blur' }
    ],
    email1: [
      {
        type: 'email',
        message: 'Veuillez entrer une adresse email valide',
        trigger: ['blur']
      }
    ],
    email2: [
      {
        type: 'email',
        message: 'Veuillez entrer une adresse email valide',
        trigger: ['blur']
      }
    ]
  }

  rules1 = {
    siret: [
      { required: false, message: 'Ce champ est obligatoire', trigger: 'blur' }
    ]
  }

  rules2 = {
    prenom: [
      { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' }
    ]
  }

  mounted () {
    this.init()
  };

  getLinkContact () {
    contactService.getContactNames()
    contactService.onChange.subscribe((item: Contact[]) => {
      this.link_name_options = []
      item.forEach(itemOne => {
        if (itemOne.id !== this.contact.id) {
          this.link_name_options.push({
            value: itemOne.nom,
            label: `${itemOne.nom} ${itemOne.prenom ? itemOne.prenom : ''}`,
            linkContactId: itemOne.id
          })
        }
      })
    })
  }

  querySearch (queryString: string, cb: Function) {
    if (!queryString) return
    listReferenceService.getReferenceAddresse(queryString).then(res => {
      cb(res)
    })
  }

  handleSelect (item: ReferenceAddress, index: number) {
    if (this.isValidAddress(item)) {
      this.contactAddresses[index].buildingNumber = item.housenumber ? item.housenumber : ''
      this.contactAddresses[index].street = item.street ? item.street : ''
      this.contactAddresses[index].city = item.locality ? item.locality : ''
      this.contactAddresses[index].zipCode = item.postalcode ? item.postalcode : ''
    }
  }

  isValidAddress (item: ReferenceAddress): boolean {
    if (item.locality && item.lat && item.lon) {
      return true
    }
    return false
  }

  get_link_name_options () {
    return this.link_name_options.filter(
      item => !this.contactLinks.some(element => element.name === item.label)
    )
  }

  get_link_type_options (queryString: any) {
    if (queryString) {
      this.link_type_options[7].value = queryString
      this.link_type_options[7].label = queryString
      return this.link_type_options
    } else {
      return this.link_type_options
    }
  }

  filterMethod (query: any) {
    this.get_link_type_options(query)
  }

  init () {
    if (this.$route.params.id !== '0' && this.$route.name !== quoteRouteName && this.$route.name !== settledFolderRouteName) {
      contactService.getContactById(this.$route.params.id).then((res: any) => {
        this.contact.id = res.id
        this.contact.nom = res.nom
        this.contact.prenom = res.prenom
        this.contact.siret = res.siret
        this.contact.telephone1 = res.telephone1
        this.contact.telephone2 = res.telephone2
        this.contact.active1 = res.active1
        this.contact.active2 = res.active2
        this.contact.juridical = res.juridical
        this.contact.title = res.title
        this.contact.brand = res.brand
        this.contact.email1 = res.email1
        this.contact.email2 = res.email2
        this.contact.contactType = res.contactType
        this.contact.commentaire = res.commentaire
        if (res.nom && ((res.contactType === false && res.prenom) || res.contactType === true)) {
          document.title = res.contactType === true ? res.nom : res.prenom + ' ' + res.nom
        }
        this.getLinkContact()
      })
      contactLinkService.getContactLinks(this.$route.params.id)
      contactAddressService.getContactAddresses(this.$route.params.id)
    } else {
      this.contact = {
        id: '',
        nom: '',
        prenom: '',
        siret: '',
        telephone1: '',
        telephone2: '',
        active1: '',
        active2: '',
        juridical: false,
        title: '',
        brand: '',
        email1: '',
        email2: '',
        contactType: false,
        commentaire: ''
      }
      this.contactLinks = []
      this.getLinkContact()
      contactAddressService.initContactAddress()
    }
  }

  setValue (val: any) {
    if (val === true) {
      this.contact.prenom = ''
      this.contact.active2 = ''
      this.contact.title = ''
      this.contact.juridical = true
    } else {
      this.contact.active1 = ''
      this.contact.siret = ''
      this.contact.juridical = false
    }
  }

  addAddressRow () {
    this.contactAddresses.push(
      new ContactAddress({
        id: '',
        contactId: '',
        street: '',
        type: '',
        city: '',
        zipCode: '',
        is_billing: false
      })
    )
  }

  submitForm () {
    this.contactForm.validate((valid: any) => {
      if (!valid) {
        return
      }
      contactService
        .saveContact(
          this.contact.id,
          this.contact.nom.toUpperCase(),
          this.contact.email1,
          this.contact.email2,
          this.contact.prenom,
          this.contact.siret,
          this.contact.telephone1,
          this.contact.telephone2,
          this.contact.active1,
          this.contact.active2,
          this.contact.juridical,
          this.contact.title,
          this.contact.brand,
          this.contact.contactType,
          this.contact.commentaire
        )
        .then((res: any) => {
          this.contact = res
          if (this.contact.id) {
            const promise: Promise<any>[] = []
            this.contactLinks.forEach((item: ContactLink) => {
              promise.push(contactLinkService
                .saveContactLink(
                  item.id,
                  this.contact.id,
                  item.linkContactId,
                  item.linkType,
                  item.name,
                  item.comment
                )
                .then(() => {
                  contactLinkService.onChangeContactLink.subscribe(
                    (item: any) => {
                      this.contactLink = item
                    }
                  )
                })
                .catch((err) => {
                  this.$message({
                    message: 'Une erreur est survenue lors de la sauvegarde du lien.',
                    type: 'error',
                    offset: 65
                  })
                  throw err
                })
              )
            })
            this.contactAddresses.forEach((item: ContactAddress) => {
              promise.push(contactAddressService
                .saveContactAddress(
                  item.id,
                  this.contact.id,
                  item.type,
                  item.buildingNumber,
                  item.street,
                  item.city,
                  item.zipCode,
                  item.isBilling
                )
                .then(() => {
                  contactAddressService.onChangeContactAddress.subscribe(
                    (item: any) => {
                      this.contactAddress = item
                    }
                  )
                }).catch((err) => {
                  this.$message({
                    message: 'Une erreur est survenue lors de la sauvegarde du lien.',
                    type: 'error',
                    offset: 65
                  })
                  throw err
                })
              )
            })
            Promise.all(promise).then(() => {
              this.$message({
                message: 'le contact a été sauvegardé avec succès.',
                type: 'success',
                offset: 65
              })
              if (this.$route.name === quoteRouteName) {
                quoteService.showContactModal(false)
                this.init()
              } else {
                this.backContactList()
              }
            })
          } else {
            this.$message({
              showClose: true,
              message: "Veuillez d'abord ajouter un contact.",
              offset: 65
            })
          }
        })
      return valid
    })
  }

  deleteContactLink (index: number) {
    this.contactLinks.splice(index, 1)
  }

  addContactLink () {
    this.contactLinks.push(
      new ContactLink({
        id: '',
        contactId: '',
        linkContactId: '',
        linkType: '',
        name: '',
        comment: ''
      })
    )
  }

  deleteContactAddress (contactId: any, addressSetId: any, index: any) {
    if (addressSetId !== '') {
      contactAddressService.deleteAddress(addressSetId).then(() => {
        contactAddressService.onChangeContactAddress.subscribe((item: any) => {
          this.contactAddress = item
          this.contactAddresses.splice(index, 1)
        })
      })
    } else {
      this.contactAddresses.splice(index, 1)
    }
  }

  deleteContact (contactId: any) {
    if (contactId !== '') {
      this.$confirm('Confirmez-vous la suppression du contact ?', 'Attention', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          contactService.deleteContact(contactId).then(() => {
            contactService.onChangeContact.subscribe((item: any) => {
              this.contact = item
            })
          })
          this.$message({
            type: 'success',
            message: 'Le contact a été supprimé avec succès',
            offset: 65
          })
          this.backContactList()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled',
            offset: 65
          })
        })
    } else {
      this.$message({
        showClose: true,
        message: "Veuillez d'abord ajouter un contact.",
        offset: 65
      })
    }
  }

  backContactList () {
    this.$router.push({
      name: 'Client'
    })
  }

  filterContacts (filterValue: any) {
    if (filterValue != null && filterValue.length > 0) {
      contactService.getContactNamesByKey(filterValue)
    } else {
      contactService.getContactNames()
    }
  }

  changeContactLink (val: any, index: any) {
    const contactLink = this.contacts.find((contact: Contact) => contact.id === val)
    if (contactLink) {
      this.contactLinks[index].name = contactLink.prenom != null ? contactLink.nom + ' ' + contactLink.prenom : contactLink.prenom
      this.contactLinks[index].linkContactId = contactLink.id
    }
  }

  changeContactLinkType (val: any, index: any) {
    const linktype = this.link_type_options.find((linkType: any) => linkType.value === val)
    if (linktype) {
      this.contactLinks[index].linkType = linktype.value
    }
  }

  changeContactLinkComment (val: any, index: any) {
    this.contactLinks[index].comment = val
  }
}
