
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import L from 'leaflet'
import {
  ListReference,
  referenceService
} from '@/services/listReference.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import { referenceFileService } from '@/services/referenceFile.service'
import { convertTimeStampToDateAndTime } from '@/utils/functions'
import PreviewDetail from './PreviewDetail.vue'
import { previewItemList } from '../../addReference/itemList'
import {
  baseBlockItems,
  locativeBlockItems,
  valeurBlockItems
} from '../../addReference/blockItems'

@Component({
  subscriptions (this: Vue) {
    return {
      user$: userService.onChange
    }
  },
  components: {
    PreviewDetail
  }
})
export default class PreviewSection extends Vue {
  @Prop({ type: Object })
    selectedRef: any

  @Prop({ type: Array })
    typePonderation: ListReference[]

  @Prop({ type: Array })
    typeTransaction: ListReference[]

  @Prop({ type: Array })
    typeValeur: ListReference[]

  @Prop({ type: Array })
    typeLocalItemsList: ListReference[]

  @Prop({ type: Array })
    activites: ListReference[]

  @Prop({ type: Number })
    selectedTableKey: number

  @Prop({ type: Object })
    searchCondition: any

  @Prop({ type: String })
    selectedTableColor: string

  user$: User = new User()
  displayPreviewLocation: boolean = false
  iconList = ['actes', 'plans', 'surfaces', 'rapport', 'autres', 'message']
  refID = ''
  iconType: any = []
  previewImageUrl: string = ''

  convertTimeStampToDateAndTime = convertTimeStampToDateAndTime
  previewItemList = previewItemList

  @Watch('selectedRef', { immediate: true, deep: true })
  handleClickMarker () {
    this.previewImageUrl = ''
    if (this.selectedRef) {
      this.displayPreviewLocation = true
      this.refID = this.selectedRef.ficheId
      this.iconType[this.selectedRef.id] = []
      if (this.selectedRef.observationImportantes) {
        this.iconType[this.selectedRef.id].push('message')
      }
      if (this.selectedRef.referencesFile) {
        this.selectedRef.referencesFile.forEach((referenceFile: any) => {
          if (referenceFile.type === 'Description') {
            referenceFileService.getImageUrl(referenceFile.id).then((res: any) => {
              this.previewImageUrl = res.data
            })
          }
          this.iconType[this.selectedRef.id].push(referenceFile.type.toLowerCase())
        })
      }
    }
  }

  mounted () {
    L.DomEvent.disableClickPropagation(
      this.$refs.previewSection as HTMLDivElement
    )
    L.DomEvent.disableScrollPropagation(
      this.$refs.previewSection as HTMLDivElement
    )
  }

  get refeditable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.REFERENCE_UPDATE)
  }

  handlePreviewSwitch () {
    this.displayPreviewLocation = !this.displayPreviewLocation
  }

  getFormattedValue (key: string, value: any) {
    return referenceService.getFormattedValue(key, value)
  }

  checkDisplayCondition (valeur: string, transaction: string, title: string) {
    if (this.selectedRef.typeTransaction === null || this.selectedRef.typeLocal === null) {
      return true
    }
    const localLabel = this.getLocalLabel(this.selectedRef.typeLocal)
    const checkValue: boolean =
      valeur === 'any'
        ? true
        : this.valueCondition(this.selectedRef.typeValeur, valeur)
    const checkTransaction: boolean =
      transaction === 'any'
        ? true
        : this.transactionCondition(
          this.selectedRef.typeTransaction,
          transaction
        )
    return (
      this.selectedRef.typeValeur &&
      this.selectedRef.typeTransaction &&
      checkValue &&
      checkTransaction &&
      baseBlockItems[localLabel] &&
      !baseBlockItems[localLabel].includes(title) &&
      locativeBlockItems[localLabel] &&
      !locativeBlockItems[localLabel].includes(title) &&
      valeurBlockItems[localLabel] &&
      !valeurBlockItems[localLabel].includes(title)
    )
  }

  transactionCondition (value: number, condition: string): boolean {
    return this.getTransactionLabel(value) === condition
  }

  valueCondition (value: number, condition: string): boolean {
    return this.getValueLabel(value) === condition
  }

  getTransactionLabel (typeTransaction: Number) {
    let label = ''
    const target: any = this.typeTransaction.filter(
      (item: any) => item.num === typeTransaction
    )
    target.length > 0 ? (label = target[0].label) : (label = '')

    return label
  }

  getValueLabel (typeValeur: Number) {
    let label = ''
    const target: any = this.typeValeur.filter(
      (item: any) => item.num === typeValeur
    )
    target.length > 0 ? (label = target[0].label) : (label = '')

    return label
  }

  getLocalLabel (typeLocal: Number) {
    let label = ''
    const target: any = this.typeLocalItemsList.filter(
      (item: any) => item.num === typeLocal
    )
    target.length > 0 ? (label = target[0].label) : (label = '')

    return label
  }

  getTypePonderation (typePonderation: Number) {
    let label = ''
    const target: any = this.typePonderation.filter(
      (item: any) => item.num === typePonderation
    )
    target.length > 0 ? (label = target[0].label) : (label = '')

    return label
  }

  getActivity (activites: Number) {
    let label = ''
    const target: any = this.activites.filter(
      (item: any) => item.num === activites
    )
    target.length > 0 ? (label = target[0].label) : (label = '')

    return label
  }

  editRef (refId: Number) {
    if (!refId) {
      return
    }
    window.open(this.$router.resolve({ path: '/edit-ref/' + refId }).href, '_blank')
  }
}
