<template>
  <div
    class="custom-form file-upload"
    :class="{'has-file':hasFile}">
    <label>{{ label }}</label>
    <div class="form-group">
      <input
        :id="`file-upload-${label}`"
        type="file"
        class="form-control"
        placeholder>
      <label
        class="file-upload--label"
        :for="`file-upload-${label}`">
        <span>{{ caption }}</span>
        <button class="btn close-btn">
          <img
            src="@/assets/img/ic-clear.svg"
            alt>
        </button>
      </label>
    </div>
    <div class="file-upload--preview" />
  </div>
</template>
<script>
export default {
  name: 'CustomFileUpload',
  props: {
    value: {
      type: File,
      default: function () {
        return null
      }
    },
    label: {
      type: String,
      default: function () {
        return ''
      }
    }
  },
  computed: {
    hasFile () {
      return true
    },
    caption () {
      return 'fichier.pdf'
    }
  }
}
</script>
