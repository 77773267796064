
import { fileService } from '@/services/file.service'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { baseUrl } from '@/services/api.client'
import EmbedFile from '@/components/ged/EmbedFile.vue'
    // The @Component decorator indicates the class is a Vue component
    @Component({
      components: { EmbedFile }
    })

export default class FilePreviewer extends Vue {
        @Prop({ default: null }) file: any | null
        baseUrl: string | undefined = baseUrl
        fileAccepted = ['pdf', 'html', 'png', 'jpeg', 'jpg', 'gif', 'svg', 'tif', 'bmp', 'mp4', 'mov', 'ogg', 'qt']
        fileConverted = ['txt', 'rtf', 'fodt', 'doc', 'docx', 'odt', 'xls', 'xlsx', 'ods', 'ppt', 'pptx', 'odp']
        notFound = false

        created () {
          const url = `${baseUrl}file/preview/${this.file.id}`
          if (!url) {
            return
          }
          fileService.getPreviewFile(url).then((res) => {
            if (res.status !== 200) {
              this.notFound = true
              return null
            }
            return res
          }).catch((err) => {
            this.notFound = true
            throw err
          })
        }

        get fileUrl (): string | null {
          if (this.file && this.file.id) {
            return `${baseUrl}file/preview/${this.file.id}`
          } else {
            return null
          }
        }

        get fileType (): string | null {
          if (this.file && this.file.name) {
            const tadExt = this.file.name.split('.')
            const ext = tadExt[tadExt.length - 1].toLocaleLowerCase()
            if (this.fileConverted.includes(ext)) {
              return 'pdf'
            }
            return ext
          } else {
            return null
          }
        }
}
