<template>
  <div
    class="result d-flex align-items-center justify-content-start"
    :class="[{ active: (result.id === activeId) }, { 'dossier-color': (result.objectType === 'settled-folder') }, { 'dossier-jur-color': (result.objectType === 'legal-folder') }, { 'devis-color': (result.objectType !== 'legal-folder' && result.objectType !== 'settled-folder') }]"
    @click="handleClickOnItem(result)">
    <figure>
      <i class="rb-dossier-alt" />
    </figure>
    <div class="description">
      <div class="desc-top d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-between align-items-center tag-container">
          <el-tag
            v-if="result.folder.type"
            class="mr-2">
            {{ getFolderTypeLabel(result.folder.type) }}
          </el-tag>
          <el-tag
            v-if="result.folder.missionType"
            class="mr-2">
            {{ result.folder.missionType[0].label }}
          </el-tag>
          <el-tag
            v-if="result.folder.expertiseType"
            class="mr-2">
            {{ getExpertiseTypeLabels(result.folder.expertiseType) }}
          </el-tag>
          <el-tag v-if="result.folder.status">
            {{ statusLabel(result) }}
          </el-tag>
          <el-button
            v-if="result.folder.conflits"
            @click="openConflitModal(index, result.folder.conflits, result.folder.name, result.objectType, result.folder.id)">
            <i class="rb-warning_red" />
          </el-button>
        </div>
        <div
          class="desc-top-right d-flex justify-content-between align-items-end"
          :class="{ active: result.isPin }">
          <el-tooltip
            class="item"
            effect="dark"
            content="Epingler"
            placement="right">
            <el-button
              :class="{ active: result.isPin }"
              @click="pinResult(result)">
              <i
                v-if="result.isPin"
                class="rb-pin" />
              <i
                v-else
                class="rb-push_pin" />
            </el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Miniature"
            placement="right">
            <el-button
              :class="{ active: iseye }"
              @click="showMiniature">
              <i
                v-if="iseye"
                class="rb-visibility1" />
              <i
                v-else
                class="rb-visibility" />
            </el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Résultats associés"
            placement="right">
            <el-button
              @click="showAssociateResult">
              <i
                v-if="result.isassoc"
                class="rb-resultats-associe" />
              <i
                v-else
                class="rb-resultats-associe-alt" />
            </el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Ouvrir"
            placement="right">
            <el-button
              @click="handleClick">
              <i class="rb-open-new-alt" />
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <div>
        <h5 class="text-uppercase">
          {{ result.folder.name }}
        </h5>
      </div>
      <div class="desc-detail d-flex justify-content-start align-items-center">
        <div
          v-if="result.folder.contacts"
          class="d-flex justify-content-between align-items-start mr-3">
          <i class="rb-marqueur mr-2" />
          {{ result.folder.adresses[0].label | capitalizeFirstLetter }}
        </div>
        <div
          v-if="result.folder.closeAt"
          class="d-flex justify-content-between align-items-start mr-3">
          <i class="rb-calendrier mr-2" />
          {{ fromFrToEnDate(result.folder.closeAt) }}
        </div>
        <div
          v-else
          class="d-flex justify-content-between align-items-start mr-3">
          <i class="rb-calendrier mr-2" />
          {{ fromFrToEnDate(result.folder.createdAt) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getExpertiseTypeLabels, fromFrToEnDate } from '@/utils/functions'
import { searchGlobalItemsService } from '@/services/searchGlobalItems.service'
import { LEGAL_FOLDER_STATUES, QUOTE_STATUES, SETTED_FOLDER_STATUES } from '@/services/constants.service'
import { tap } from 'rxjs/operators'

export default {
  props: {
    result: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    isDetail: {
      type: Boolean,
      require: false
    },
    isResultAssoc: {
      type: Boolean,
      require: false
    }
  },
  emits: ['pin', 'showConflicts', 'click', 'result', 'detail', 'miniature'],
  subscriptions () {
    return {
      loading$: searchGlobalItemsService.onLoading,
      activeId$: searchGlobalItemsService.onChangeActiveId.pipe(tap((res) => {
        if (res) {
          this.activeId = res
        }
      }))
    }
  },
  data () {
    return {
      activeId: null,
      iseye: false
    }
  },
  methods: {
    handleClickOnItem (item) {
      this.$emit('click')
      this.isDetail && this.showMiniature()
      this.isResultAssoc && this.showAssociateResult()
      searchGlobalItemsService.changeActiveId(item.id)
    },
    statusLabel (result) {
      let folderStatus = null
      if (result.objectType === 'quote') {
        folderStatus = QUOTE_STATUES.find(status => status.value === result.folder.status)
      } else if (result.objectType === 'settled-folder') {
        folderStatus = SETTED_FOLDER_STATUES.find(status => status.value === result.folder.status)
      } else if (result.objectType === 'legal-folder') {
        folderStatus = LEGAL_FOLDER_STATUES.find(status => status.value === result.folder.status)
      }
      return folderStatus ? folderStatus.label : ''
    },
    openConflitModal (index, conflicts, name, type, id) {
      this.$emit('showConflicts', { index, conflicts, name, type, id })
    },
    handleClick () {
      this.$emit('detail', this.result)
    },
    handleResult () {
      this.$emit('result', true)
    },
    pinResult (result) {
      this.$emit('pin', result)
    },
    showMiniature () {
      this.iseye = !this.iseye
      this.$emit('miniature', this.result)
    },
    getFolderTypeLabel (type) {
      return type === 'settled-folder' ? 'Dossier amiable' : (type === 'legal-folder' ? 'Dossier judicaire' : 'Devis')
    },
    getExpertiseTypeLabels () {
      return getExpertiseTypeLabels(this.result.folder.expertiseType)
    },
    showAssociateResult () {
      this.$emit('result', this.result)
    },
    fromFrToEnDate (date) {
      return fromFrToEnDate(date)
    }
  }
}
</script>
