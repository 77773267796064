
import { Component, Vue } from 'vue-property-decorator'
import SearchBar from '@/components/ged/SearchBar.vue'
import CriteriaGED from '@/components/ged/CriteriaGED.vue'
import TableGED from '@/components/ged/TableGED.vue'
import FormGED from '@/components/ged/FormGED.vue'
import MapGED from '@/components/ged/MapGED.vue'
import EmbedFile from '@/components/ged/EmbedFile.vue'
import { Ged, GedCriteria, PageParams } from '@/models/ged.model'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import { authService } from '@/services/auth.service'
import { gedService } from '@/services/ged.service'
    @Component({
      subscriptions () {
        return {
          me$: authService.onChange,
          loading$: gedService.onLoading,
          gedCriteria$: gedService.onChangeGedCriteria,
          totalGed$: gedService.onChangeTotalGed,
          pageParams$: gedService.onChangePageParams
        }
      },
      components: {
        SearchBar,
        CriteriaGED,
        TableGED,
        FormGED,
        MapGED,
        EmbedFile
      }
    })

export default class GEDView extends Vue {
  me$: User
  mode: string = 'document'
  proSearch: string = ''
  showDocument: boolean = false
  loading$ : boolean = false

  inputValue: string = ''
  gedCriteria$: GedCriteria
  pageParams$: PageParams
  totalGed$: number = 0

  created () {
  }

  clearFilter () {
    this.proSearch = ''
    this.pageParams$.currentPage = 1
    gedService.updateGedCriteria(new GedCriteria())
    gedService.searchGed()
  }

  newGed () {
    const me = new User(authService.userValue)
    const ged = new Ged({ user: me })
    gedService.showFormModal(true)
    gedService.updateGedForm(ged)
  }

  handleClickMode (mode: string) {
    this.updateMode(mode)
  }

  updateMode (mode: string): void {
    if (mode === 'map') {
      this.mode = this.mode === 'document' ? 'map' : 'document'
    } else {
      this.mode = mode
      // gedService.activeGedIndex(null);
    }
  }

  selectDocument (): void {
    this.showDocument = true
  }

  handleProSearch () {
    if (!userService.hasRight(this.me$, USER_RIGHTS.DOCUMENT_LECTURE)) {
      this.$message({
        message: "Vous n'avez pas le droit nécessaire pour effectuer une recherche",
        type: 'error',
        offset: 65
      })
      return false
    }
    // permit to initialise current page at 1 when user try to apply a filter on the list
    gedService.updatePageParams({ currentPage: 1 })
    if (this.proSearch) {
      gedService.proSearchGed(this.proSearch)
    } else {
      gedService.searchGed()
    }
  }

  handleTagClose (tag: string) {
    this.gedCriteria$.tags.splice(this.gedCriteria$.tags.indexOf(tag), 1)
    return gedService.searchGed()
  }

  handleTagConfirm () {
    const inputValue = this.inputValue
    if (!inputValue || inputValue === '') {
      return
    }
    if (inputValue && this.gedCriteria$.tags.indexOf(inputValue) < 0) {
      this.gedCriteria$.tags.push(inputValue)
    }
    this.inputValue = ''
    return gedService.searchGed()
  }
}
