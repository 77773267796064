
import { localeDateStr } from '@/utils/functions'

export default {
  props: {
    result: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatDate (date: any) {
      return localeDateStr(date)
    }
  }
}
