
import { LEGAL_FOLDER_STATUES, QUOTE_STATUES, SETTED_FOLDER_STATUES } from '@/services/constants.service'
import FilePreviewer from '@/components/utils/FilePreviewer.vue'
import { getExpertiseTypeLabels } from '@/utils/functions'

export default {
  components: {
    FilePreviewer
  },
  props: {
    result: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      accordeon1: false,
      accordeon2: false,
      accordeon3: false,
      selectedFile: null,
      showPreviewerDialog: false
    }
  },
  computed: {
    getFactures (): any {
      return (this as any).result.files.filter((file: any) => file.category && file.category.id === 3)
    },
    getMissions (): any {
      return (this as any).result.files.filter((file: any) => file.category && file.category.id === 2)
    },
    getReports (): any {
      return (this as any).result.files.filter((file: any) => file.category && file.category.id === 4)
    }
  },
  methods: {
    statusLabel (result: any): any {
      let folderStatus = null
      if (result.objectType === 'quote') {
        folderStatus = QUOTE_STATUES.find(status => status.value === result.folder.status)
      } else if (result.objectType === 'legal-folder') {
        folderStatus = LEGAL_FOLDER_STATUES.find(status => status.value === result.folder.status)
      } else {
        folderStatus = SETTED_FOLDER_STATUES.find(status => status.value === result.folder.status)
      }
      return folderStatus ? folderStatus.label : ''
    },
    statusColor (type: any) {
      switch (type) {
        case 'quote':
          return 'danger'
        case 'legal-folder':
          return 'success'
        default:
          return 'ffec33'
      }
    },
    getFolderSignator (contacts: any) {
      const signator = contacts.find((contact: any) => contact.contactType === 'applicant')
      return signator.contactName
    },
    getFolderAddress (addresses: any) {
      const address = addresses[0]
      return address.buildingnumber + ' ' + address.street + ' ' + address.city
    },
    getFolderTypeName (type: any) {
      switch (type) {
        case 'legal-folder':
          return 'Judiciaire'
        case 'settled-folder':
          return 'Amiable'
        default:
          return 'Devis'
      }
    },
    capitalizeFirstLetter (string: string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    previewFile (file: any): void {
      (this as any).selectedFile = file;
      (this as any).showPreviewerDialog = true
    },
    getExpertiseTypeLabels () {
      return getExpertiseTypeLabels((this as any).result.folder.localType)
    }
  }
}
