import { apiClient, CancelToken } from '@/services/api.client'
import { Contact } from '@/services/contact.service'
import { User } from '@/services/user.service'
import { authService } from '@/services/auth.service'
import { QUOTE_STATUES, SETTED_FOLDER_STATUES, LEGAL_FOLDER_STATUES } from '@/services/constants.service'
import { BehaviorSubject, Observable } from 'rxjs'
import { PageParams } from '@/models/ged.model'
import { Quote } from '@/services/quote.service'

export class DossierFilter {
  mode: string
  pageType: string
  query: string
  type: any
  client: string[]
  address: any
  ref: string
  typeMission: string[]
  typeLocal: string[]
  typeExpert: string[]
  status: string[]
  statusSettledFolder: string
  statusLegalFolder: string
  open: any
  close: any
  files: any
  sectionNumber: string
  mandator: boolean
  interlocutor: boolean
  expert: string[]
  creator: boolean
  writer: boolean
  reviewer: boolean
  signer: boolean
  roles: string[]
  applicantRoles: string[]
  respondentRoles: string[]
  sortProp: string
  sortOrder: string
  createdBy: string
  typeInvoice: string[]
  invoice: any
  avecNumero: boolean
  numbers: string[]
  billNumber: any
  typeDebours: string[]
  debours: any
  caDate: any[]
  hasPendingAmount: boolean
  hasBalanceAmount: boolean
  quote: Quote

  constructor (data?: any) {
    this.mode = (data && data.mode) || 'query'
    this.pageType = (data && data.pageType) || ''
    this.query = (data && data.query) || ''
    this.type = (data && data.type) || 'quote'
    this.client = (data && data.client) || []
    this.address = (data && data.address) || {
      number: '',
      street: '',
      zip: '',
      city: '',
      layer: ''
    }
    this.ref = (data && data.ref) || ''
    this.typeMission = (data && data.typeMission) || []
    this.typeLocal = (data && data.typeLocal) || []
    this.typeExpert = (data && data.typeExpert) || []
    this.status = (data && data.status) || []
    this.statusSettledFolder = (data && data.statusSettledFolder) || ''
    this.statusLegalFolder = (data && data.statusLegalFolder) || ''
    this.open = (data && data.open) || {
      start: '',
      end: ''
    }
    this.close = (data && data.close) || {
      start: '',
      end: ''
    }
    this.sectionNumber = (data && data.sectionNumber) || ''
    this.mandator = (data && data.mandator) || false
    this.interlocutor = (data && data.interlocutor) || false
    this.expert = (data && data.expert) || []
    this.creator = (data && data.creator) || false
    this.writer = (data && data.writer) || false
    this.signer = (data && data.signer) || false
    this.reviewer = (data && data.reviewer) || false
    this.roles = (data && data.roles) || []
    this.applicantRoles = (data && data.applicantRoles) || []
    this.respondentRoles = (data && data.respondentRoles) || []
    this.sortProp = (data && data.sortProp) || 'number_dossier'
    this.sortOrder = (data && data.sortOrder) || 'descending'
    this.createdBy = (data && data.createdBy) || ''
    this.typeInvoice = (data && data.typeInvoice) || []
    this.invoice = (data && data.invoice) || {
      start: '',
      end: ''
    }
    this.avecNumero = (data && data.avecNumero) || false
    this.numbers = (data && data.numbers) || []
    this.billNumber = (data && data.billNumber) || {
      start: null,
      end: null
    }
    this.typeDebours = (data && data.typeDebours) || []
    this.debours = (data && data.debours) || {
      start: '',
      end: ''
    }
    this.caDate = (data && data.caDate) || []
    this.hasPendingAmount = (data && data.hasPendingAmount) || false
    this.hasBalanceAmount = (data && data.hasBalanceAmount) || false
    this.files = data && data.files
    this.quote = (data && data.quote) || null
  }
}

class DossierService {
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  private readonly _filter: BehaviorSubject<DossierFilter> = new BehaviorSubject<DossierFilter | any>(null)
  private readonly _dossier: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  private readonly _dossiers: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  private readonly _contacts: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>([])
  private readonly _clients: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>([])
  private readonly _writers: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([])
  private readonly _reviewers: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([])
  private readonly _totalDossiers: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  private readonly _pageParams: BehaviorSubject<PageParams> = new BehaviorSubject<PageParams>(new PageParams())
  private readonly _activeDossierIndex: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null)

  public onLoading: Observable<boolean> = this._loading.asObservable()
  public onChangeFilter: Observable<DossierFilter> = this._filter.asObservable()
  public onChangeDossier: Observable<any> = this._dossier.asObservable()
  public onChangeDossiers: Observable<any[]> = this._dossiers.asObservable()
  public onChangeContacts: Observable<Contact[]> = this._contacts.asObservable()
  public onChangeWriters: Observable<User[]> = this._writers.asObservable()
  public onChangeReviewers: Observable<User[]> = this._reviewers.asObservable()
  public onChangeTotalDossiers: Observable<number> = this._totalDossiers.asObservable()
  public onChangePageParams: Observable<PageParams> = this._pageParams.asObservable()
  public onChangeActiveDossierIndex: Observable<number | null> = this._activeDossierIndex.asObservable()

  private cancelToken: (() => void) | null = null

  get filter (): DossierFilter {
    return this._filter.getValue()
  }

  get dossier (): any {
    return this._dossier.getValue()
  }

  get dossiers (): any[] {
    return this._dossiers.getValue()
  }

  get totalDossiers (): number {
    return this._totalDossiers.getValue()
  }

  get pageParams (): PageParams {
    return this._pageParams.getValue()
  }

  initFilter (data?: any): void {
    if (data && data.pageType && data.pageType === 'author') {
      const authUser = authService.userValue
      if (authUser != null) {
        data.expert = [authUser.id]
        data.writer = true
        data.signer = true
        data.reviewer = true
        data.creator = true
      }
    }
    if (data.type === 'quote') {
      data.hasBalanceAmount = false
      data.hasPendingAmount = false
    }
    if (data.type !== 'legal-folder') {
      data.sectionNumber = null
    }
    const filter = new DossierFilter(data)
    this._filter.next(filter)
  }

  clearFilter (): void {
    const filter = new DossierFilter({
      mode: this.filter.mode,
      pageType: this.filter.pageType,
      query: this.filter.query,
      type: this.filter.type,
      expert: this.filter.pageType === 'author' ? this.filter.expert : '',
      writer: this.filter.pageType === 'author',
      signer: this.filter.pageType === 'author',
      reviewer: this.filter.pageType === 'author'
    })
    this._filter.next(filter)
    this._pageParams.next(new PageParams())
  }

  setDossier (val: any): void {
    this._dossier.next(val)
  }

  getFilterDatas (): void {
    // this.getClients();
    this.getContacts().catch((err) => {
      throw err
    })
    // this.getWriters();
    // this.getReviewers();
  }

  async getClients (): Promise<any> {
    return await apiClient.get('dossiers/clients?type=' + String(this.filter.type)).then((res: any) => {
      this._clients.next(res.data)
      return res.data
    })
  }

  async getContactsByKey (searchKey: string): Promise<void> {
    this._loading.next(true)
    await apiClient.get('dossiers/contacts/name/' + String(this.filter.type) + '/' + searchKey).then(res => {
      this._loading.next(false)
      if (res.data) {
        this._contacts.next(res.data)
      }
    })
  }

  async getContactsByKeyAndType (searchKey: string, type: string): Promise<void> {
    this._loading.next(true)
    await apiClient.get('dossiers/contacts/name/' + type + '/' + searchKey).then(res => {
      this._loading.next(false)
      if (res.data) {
        this._contacts.next(res.data)
      }
    })
  }

  async getContacts (): Promise<any> {
    return await apiClient.get('dossiers/contacts?type=' + String(this.filter.type)).then((res: any) => {
      this._contacts.next(res.data)
      return res.data
    })
  }

  async getContactsByType (type: string): Promise<any> {
    return await apiClient.get('dossiers/contacts?type=' + type).then((res: any) => {
      this._contacts.next(res.data)
      return res.data
    })
  }

  async getWriters (): Promise<any> {
    return await apiClient.get('dossiers/writers?type=' + String(this.filter.type)).then((res: any) => {
      this._writers.next(res.data)
      return res.data
    })
  }

  async getReviewers (): Promise<any> {
    return await apiClient.get('dossiers/reviewers?type=' + String(this.filter.type)).then((res: any) => {
      this._reviewers.next(res.data)
      return res.data
    })
  }

  async searchDossiers (mode?: string): Promise<any> {
    if (mode) {
      this.filter.mode = mode
      this._filter.next(this.filter)
    }

    if (this.filter.mode === 'query') {
      return await this.searchDossiersByQuery()
    } else {
      return await this.searchDossiersByFilters()
    }
  }

  preFilter (data?: any): DossierFilter {
    if (data && data.pageType && data.pageType === 'author') {
      const authUser = authService.userValue
      if (authUser != null) {
        data.expert = [authUser.id]
        data.writer = true
        data.signer = true
        data.reviewer = true
        data.creator = true
      }
    }
    if (data.type === 'quote') {
      data.hasBalanceAmount = false
      data.hasPendingAmount = false
    }
    if (data.type !== 'legal-folder') {
      data.sectionNumber = null
    }
    return new DossierFilter(data)
  }

  async getDossiers (filter?: any, currentPage?: number, pageSize?: number): Promise<{ data: any[], total: any }> {
    if (!filter && (this.cancelToken != null)) {
      this.cancelToken()
    }
    const self = this
    const filters = filter
    this._loading.next(true)
    return await apiClient.get('dossiers/search/filters', {
      params: {
        filters,
        currentPage,
        pageSize

      },
      cancelToken: new CancelToken(function executor (cancelRequest: () => void) {
        // An executor function receives a cancel function as a parameter
        self.cancelToken = cancelRequest
      })
    }).then((res: any) => {
      const data = this.adjustDossiers(res.data.data)
      this._loading.next(false)
      this.cancelToken = null
      return { data, total: res.data.total }
    })
  }

  async searchDossiersByQuery (): Promise<any> {
    if (this.cancelToken != null) {
      this.cancelToken()
    }
    const self = this
    this._loading.next(true)
    return await apiClient.get('dossiers/search/query', {
      params: {
        filters: this.filter,
        currentPage: this.pageParams.currentPage,
        pageSize: this.pageParams.pageSize
      },
      cancelToken: new CancelToken(function executor (cancelRequest: () => void) {
        // An executor function receives a cancel function as a parameter
        self.cancelToken = cancelRequest
      })
    }).then((res: any) => {
      const data = this.adjustDossiers(res.data.data)
      this._dossiers.next(data)
      this._loading.next(false)
      this._totalDossiers.next(res.data.total)
      this.cancelToken = null
      return res.data
    })
  }

  async searchDossiersByFilters (): Promise<any> {
    if (this.cancelToken != null) {
      this.cancelToken()
    }
    const self = this
    this._loading.next(true)
    this.filter.applicantRoles = []
    this.filter.respondentRoles = []
    this.filter.roles.forEach((item: string) => {
      if (item.includes('applicant-')) {
        this.filter.applicantRoles.push((item.replace('applicant-', '')).toLowerCase())
      } else if (item.includes('respondent-')) {
        this.filter.respondentRoles.push((item.replace('respondent-', '')).toLowerCase())
      } else {
        this.filter.applicantRoles.push(item.toLowerCase())
        this.filter.respondentRoles.push(item.toLowerCase())
      }
    })
    return await apiClient.get('dossiers/search/filters', {
      params: {
        filters: this.filter,
        currentPage: this.pageParams.currentPage,
        pageSize: this.pageParams.pageSize

      },
      cancelToken: new CancelToken(function executor (cancelRequest: () => void) {
        // An executor function receives a cancel function as a parameter
        self.cancelToken = cancelRequest
      })
    }).then((res: any) => {
      const data = this.adjustDossiers(res.data.data)
      this._dossiers.next(data)
      this._loading.next(false)
      this._totalDossiers.next(res.data.total)
      this.cancelToken = null
      return res.data
    })
  }

  async exportDossiersList (): Promise<any> {
    this._loading.next(true)
    this.filter.applicantRoles = []
    this.filter.respondentRoles = []
    this.filter.roles.forEach((item: string) => {
      if (item.includes('applicant-')) {
        this.filter.applicantRoles.push((item.replace('applicant-', '')).toLowerCase())
      } else if (item.includes('respondent-')) {
        this.filter.respondentRoles.push((item.replace('respondent-', '')).toLowerCase())
      } else {
        this.filter.applicantRoles.push(item.toLowerCase())
        this.filter.respondentRoles.push(item.toLowerCase())
      }
    })
    return await apiClient.get('dossiers/export-list', {
      params: {
        filters: this.filter
      },
      responseType: 'blob'
    }).then((res: any) => {
      this._loading.next(false)
      return res
    })
  }

  adjustDossiers (data: any[]): any[] {
    const filtered = data
    filtered.forEach((item: any) => {
      item.client = ''
      item.city = ''
      item.createdDateLabel = ''
      item.typeMission = ''
      item.typeLocal = ''
      item.statusLabel = ''
      item.writer = ''
      item.reviewer = ''
      item.lat = null
      item.lon = null
      item.creator = ''
      let mandatorClient = null
      if (item.type === 'quote' || item.type === 'legal-folder') {
        mandatorClient = item.contacts.find((contact: any) => contact.mandator)
        if (mandatorClient && mandatorClient.contact) {
          item.client = (mandatorClient.contact.prenom ? String(mandatorClient.contact.prenom) : '') + (mandatorClient.contact.nom ? (' ' + String(mandatorClient.contact.nom)) : '')
        }
        if (item.addresses.length > 0) {
          item.city = item.addresses[0].city
          item.lat = item.addresses[0].lat
          item.lon = item.addresses[0].lon
        }
        if (item.missions.length > 0) {
          item.typeLocal = item.missions[0].localType !== '' ? item.missions[0].localType : '-'
        } else {
          item.typeLocal = '-'
        }
        if (item.type === 'quote') {
          const status = QUOTE_STATUES[item.status]
          item.statusLabel = status ? status.label : item.status
          item.typeMission = item.expertiseMission.typeMission !== '' ? item.expertiseMission.typeMission : '-'
          item.expertType = item.missions[0] && item.missions[0].expertType !== '' ? item.missions[0].expertType : '-'
        } else {
          item.expertType = item.missions[0] && item.missions[0].expertType !== '' ? item.missions[0].expertType : '-'
          item.statusLabel = LEGAL_FOLDER_STATUES[item.status].label
        }
      } else if (item.type === 'settled-folder') {
        mandatorClient = item.quote.contacts.find((contact: any) => contact.mandator)
        if (mandatorClient && mandatorClient.contact) {
          item.client = (mandatorClient.contact.prenom ? String(mandatorClient.contact.prenom) : '') + (mandatorClient.contact.nom ? (' ' + String(mandatorClient.contact.nom)) : '')
        }
        if (item.quote.addresses.length > 0) {
          item.addresses = item.quote.addresses
          item.city = item.quote.addresses[0].city
          item.lat = item.quote.addresses[0].lat
          item.lon = item.quote.addresses[0].lon
        }
        if (item.quote.missions.length > 0) {
          item.typeLocal = item.quote.missions[0].localType
          item.expertType = item.quote.missions[0] && item.quote.missions[0].expertType !== '' ? item.quote.missions[0].expertType : '-'
        }
        item.typeMission = item.quote.expertiseMission.typeMission
        item.statusLabel = SETTED_FOLDER_STATUES[item.status].label
      }

      if (item.type !== 'quote') {
        if (item.report && item.report.experts) {
          const writer = item.report.experts.find((expert: any) => expert.writer)
          const reviewer = item.report.experts.find((expert: any) => expert.reviewer)
          if (writer) {
            item.writer = String(writer.user.prenom) + ' ' + String(writer.user.nom)
          }
          if (reviewer) {
            item.reviewer = String(reviewer.user.prenom) + ' ' + String(reviewer.user.nom)
          }
        }
      }

      const createdDate = new Date(item.createdAt)
      item.createdDateLabel = createdDate.toLocaleDateString('fr-FR')

      if (item.createdBy) {
        item.creator = String(item.createdBy.nom) + ' ' + String(item.createdBy.prenom)
      }
    })
    return filtered
  }

  resetCurrentPage (): void {
    this.pageParams.currentPage = 1
    this._pageParams.next(this.pageParams)
  }

  public activeDossierIndex (index: number | null): void {
    this._activeDossierIndex.next(index)
  }

  public async getStatusHistoryByDateRange (folderId: number, type: string, dateRange: string = ''): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('status-history/' + type + '/' + String(folderId) + (dateRange ? `?dateRange=${dateRange}` : '')).then((res: any) => {
      this._loading.next(false)
      return res.data
    }).catch((err: any) => {
      this._loading.next(false)
      return err
    })
  }
}

export const dossierService = new DossierService()
