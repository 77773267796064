
import { Component, Vue } from 'vue-property-decorator'
import { dossierService, DossierFilter } from '@/services/dossier.service'
import { baseUrl } from '@/services/api.client'
import FilePreviewer from '@/components/utils/FilePreviewer.vue'
import { LEGAL_FOLDER_STATUES, QUOTE_STATUES, SETTED_FOLDER_STATUES } from '@/services/constants.service'

@Component({
  subscriptions (this: any) {
    return {
      dossier$: dossierService.onChangeDossier
    }
  },
  components: {
    FilePreviewer
  }
})

export default class DossierDetail extends Vue {
  SETTLED_FOLDER_STATUES = SETTED_FOLDER_STATUES
  LEGAL_FOLDER_STATUES = LEGAL_FOLDER_STATUES
  QUOTE_STATUES = QUOTE_STATUES
  toggleHistory: boolean = false
  showPreviewerDialog: boolean = false
  selectedFile: any = null
  baseUrl: string | undefined = baseUrl
  dossier$: DossierFilter
  radio1 : string = ''
  statusList: any[] = []

  changeStringToVariable (str: string): any {
    // eslint-disable-next-line
    return eval?.(`"use strict";(${str})`)
  }

  toggleClass () {
    this.toggleHistory = !this.toggleHistory
  }

  get legalFolderReportFile () {
    if (!this.dossier$) {
      return []
    }
    const dataQuote = this.dossier$.quote
      ? this.dossier$.quote.files.reduce((tab: any, c: any) => {
        if (c.type === 'LegalFolderReport') {
          tab.push(c)
        }
        return tab
      }, [])
      : []
    const files = this.dossier$.files.reduce((tab: any, c: any) => {
      if (c.type === 'LegalFolderReport') {
        tab.push(c)
      }
      return tab
    }, []).concat(dataQuote)
    files.sort(function (a: any, b: any) {
      if (a.order < b.order) {
        return -1
      } else {
        return 1
      }
    })
    return files.slice(0, 3)
  }

  get settledFolderReportFile () {
    if (!this.dossier$) {
      return []
    }
    const dataQuote = this.dossier$.quote
      ? this.dossier$.quote.files.reduce((tab: any, c: any) => {
        if (c.type === 'SettledFolderReport') {
          tab.push(c)
        }
        return tab
      }, [])
      : []
    const files = this.dossier$.files.reduce((tab: any, c: any) => {
      if (c.type === 'SettledFolderReport') {
        tab.push(c)
      }
      return tab
    }, []).concat(dataQuote)
    files.sort(function (a: any, b: any) {
      if (a.order < b.order) {
        return -1
      } else {
        return 1
      }
    })
    return files.slice(0, 3)
  }

  get joinedFile () {
    if (!this.dossier$) {
      return []
    }
    const dataQuote = this.dossier$.quote
      ? this.dossier$.quote.files.reduce((tab: any, c: any) => {
        if (c.type === 'Joined') {
          tab.push(c)
        }
        return tab
      }, [])
      : []
    const files = this.dossier$.files.reduce((tab: any, c: any) => {
      if (c.type === 'Joined') {
        tab.push(c)
      }
      return tab
    }, []).concat(dataQuote)
    files.sort(function (a: any, b: any) {
      if (a.order < b.order) {
        return -1
      } else {
        return 1
      }
    })
    return files.slice(0, 3)
  }

  get LMFile () {
    if (!this.dossier$) {
      return []
    }
    const dataQuote = this.dossier$.quote
      ? this.dossier$.quote.files.reduce((tab: any, c: any) => {
        if (c.type === 'LM') {
          tab.push(c)
        }
        return tab
      }, [])
      : []
    const files = this.dossier$.files.reduce((tab: any, c: any) => {
      if (c.type === 'LM') {
        tab.push(c)
      }
      return tab
    }, []).concat(dataQuote)
    files.sort(function (a: any, b: any) {
      if (a.order < b.order) {
        return -1
      } else {
        return 1
      }
    })
    return files.slice(0, 3)
  }

  get billFile () {
    if (!this.dossier$) {
      return []
    }
    const dataQuote = this.dossier$.quote
      ? this.dossier$.quote.files.reduce((tab: any, c: any) => {
        if (c.type === 'Bill') {
          tab.push(c)
        }
        return tab
      }, [])
      : []
    const files = this.dossier$.files.reduce((tab: any, c: any) => {
      if (c.type === 'Bill') {
        tab.push(c)
      }
      return tab
    }, []).concat(dataQuote)
    files.sort(function (a: any, b: any) {
      if (a.order < b.order) {
        return -1
      } else {
        return 1
      }
    })
    return files.slice(0, 3)
  }

  get randomFile () {
    if (!this.dossier$) {
      return []
    }
    const dataQuote = this.dossier$.quote
      ? this.dossier$.quote.files.reduce((tab: any, c: any) => {
        if (c.type === 'Random') {
          tab.push(c)
        }
        return tab
      }, [])
      : []
    const files = this.dossier$.files.reduce((tab: any, c: any) => {
      if (c.type === 'Random') {
        tab.push(c)
      }
      return tab
    }, []).concat(dataQuote)
    files.sort(function (a: any, b: any) {
      if (a.order < b.order) {
        return -1
      } else {
        return 1
      }
    })
    return files.slice(0, 3)
  }

  downloadFile (id: number): void {
    window.open(this.baseUrl + 'file/download/' + id)
  }

  previewFile (file: any): void {
    this.selectedFile = file
    this.showPreviewerDialog = true
  }

  goPage (dossier: any) {
    if (dossier.type === 'quote') {
      window.open(this.$router.resolve({ path: '/devis/' + dossier.id }).href, '_blank')
    } else if (dossier.type === 'settled-folder') {
      window.open(this.$router.resolve({ path: '/dossier-amiable/' + dossier.id }).href, '_blank')
    } else if (dossier.type === 'legal-folder') {
      window.open(this.$router.resolve({ path: '/dossier-judiciaire/' + dossier.id }).href, '_blank')
    }
  }

  updateStatusByDate () {
    const dossier : any = this.dossier$
    dossierService.getStatusHistoryByDateRange(dossier.id, this.dossier$.type, this.radio1).then((res: any) => {
      this.statusList = res.statusHistory
    })
  }
}
