export class Service {
  public filterOptions (query: string, index: number, options: any[]): any[] {
    if (query && query !== '') {
      options[index].value = query
      options[index].label = query
    } else {
      options[index].value = 'Autre (ou saisir directement un texte libre)'
      options[index].label = 'Autre (ou saisir directement un texte libre)'
    }
    return options.filter((item: any) => {
      return item.label.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0 ||
                item.value.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0
    })
  }
}
