import axios from 'axios'
import { router } from '@/router'
import { authService } from './auth.service'
import { ACTIVE_LOG_ROCKET, VUE_APP_API_URL, VUE_APP_FRONT_URL } from '@/constants'

export const baseUrl = VUE_APP_API_URL
export const frontUrl = VUE_APP_FRONT_URL
export const activeLogRocket = ACTIVE_LOG_ROCKET
  ? process.env.ACTIVE_LOG_ROCKET === 'true' || Boolean(process.env.ACTIVE_LOG_ROCKET)
  : process.env.NODE_ENV === 'development'

// the base axios client used to communicate with the api
export const apiClient = axios.create({
  baseURL: baseUrl, // todo: properly inject the api base url
  withCredentials: true,
  maxRedirects: 0 // desactivate redirect symfony redirect on logout
})

export const CancelToken = axios.CancelToken

apiClient.interceptors.response.use(function (response) {
  return response
}, async function (error) {
  if (error.response && (error.response.status === 401 || error.response.status === 403)) {
    if (
      router.currentRoute.name !== 'connexion' &&
            router.currentRoute.name !== 'ForgotPassword' &&
            router.currentRoute.name !== 'ResetPassword' &&
            router.currentRoute.name !== '2fa'
    ) {
      authService.logout().then(() => {
        router.replace('/connexion').catch((err) => {
          throw err
        })
      }, () => {
        router.replace('/connexion').catch((err) => {
          throw err
        })
      })
    }
  }
  return await Promise.reject(error)
})
