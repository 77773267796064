import axios from 'axios'
import { apiClient } from '@/services/api.client'
import { BehaviorSubject, Observable } from 'rxjs'
import { quoteService } from './quote.service'
import { authService } from './auth.service'
import { billingService } from './billing.service'
import { fileService, AppFile, CategoryTree } from './file.service'
import { reportService, Report } from './report.service'
import { FolderInterface } from '@/models/FolderInterface'
import { VUE_APP_RAPPORT_API_URL } from '@/constants'
import { removeSpecialCharacters } from '@/utils/functions'
import _ from 'lodash'

export class SettledFolder implements FolderInterface {
  id: string
  type: string
  quoteId: string
  quote: any
  name: string
  prefixName: string
  suffixName: string
  status: number
  report: Report
  userId: number
  files: AppFile[]
  submittedFile: AppFile
  searches: any
  conflit: any[]
  folderId: number
  categories: CategoryTree[]
  conflictReason: string | null
  createdAt: Date
  statusHistory: any[]
  constructor (data?: any) {
    this.id = (data && data.id) || null
    this.quoteId = (data && data.quoteId) || null
    this.quote = (data && data.quote) || null
    this.name = (data && data.name) || ''
    this.prefixName = ''
    this.suffixName = ''
    this.folderId = (data && data.folderId) || null
    if (data && data.name) {
      const nameArray: string[] = data.name.split('-')
      if (nameArray.length === 3) {
        this.prefixName = nameArray[0] + '-' + nameArray[1] + '-'
        this.suffixName = nameArray[2]
      } else {
        this.prefixName = data.name
      }
    }
    this.status = (data && data.status) || 0
    this.report = (data && data.report) || {}
    this.userId = (data && data.userId) || null
    this.files = (data && data.files) || []
    this.submittedFile = (data && data.submittedFile) || null
    this.searches = (data && data.searches) || null
    this.conflit = (data && data.conflit) || null
    this.categories = (data && data.categories) || []
    this.conflictReason = (data && data.conflictReason) || null
    this.createdAt = (data && data.createdAt) || null
    this.statusHistory = (data && data.statusHistory) || null
  }
}

class SettledFolderService {
  private readonly _folder: BehaviorSubject<SettledFolder | any> = new BehaviorSubject<SettledFolder | any>({})
  private readonly _folders: BehaviorSubject<SettledFolder[]> = new BehaviorSubject<SettledFolder[]>([])
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  public onChangeFolder: Observable<SettledFolder> = this._folder.asObservable()
  public onChangeFolders: Observable<SettledFolder[]> = this._folders.asObservable()
  public onLoading: Observable<boolean> = this._loading.asObservable()

  get folder (): SettledFolder | any {
    return this._folder.getValue()
  }

  get folders (): SettledFolder[] {
    return this._folders.getValue()
  }

  public initFolder (data?: any): void {
    if (data) {
      const fetchedFolder = new SettledFolder(data)
      this._folder.next(fetchedFolder)
      if (data.quote) {
        quoteService.initQuote(data.quote)
      } else {
        quoteService.initQuote()
      }
      if (data.report) {
        reportService.initReport(data.report)
      } else {
        reportService.initReport()
      }
      if (data.files) {
        this.initFiles(data.files)
      }
      if (data.categories) {
        fileService.initCategoryTree(data.categories)
      }
    } else {
      const folder = new SettledFolder()
      this._folder.next(folder)
      quoteService.initQuote()
      reportService.initReport()
    }
  }

  public initFiles (files: any[]): void {
    if (this.folder.submittedFile) {
      const findFile = files.find((file: any) => file.id === this.folder.submittedFile.id)
      if (findFile) {
        findFile.selected = true
      }
    }
    if (this.folder.quote && this.folder.quote.files) {
      files = files.concat(this.folder.quote.files)
    }
    files.sort((a: any, b: any) => a.order - b.order)
    fileService.initFiles(files)
  }

  public async getFolders (offset: number = 0, limit: number = 10): Promise<void> {
    this._loading.next(true)
    await apiClient.get('settled-folders', { params: { limit, offset } }).then(res => {
      if (res.data) {
        this._folders.next(res.data)
      }
      this._loading.next(false)
    })
  }

  public async searchFolders (query: any, offset: number = 0, limit: number = 10): Promise<void> {
    this._loading.next(true)
    const params = {
      limit,
      offset
    }
    _.merge(params, query)

    await apiClient.get('settled-folders', { params }).then(res => {
      if (res.data) {
        this._folders.next(res.data)
      }
      this._loading.next(false)
    }).catch((err) => {
      console.error(err)
      throw err
    })
  }

  public async getFolderById (id: string): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('settled-folder/' + id).then((res: any) => {
      res.data.folder.conflit = res.data.conflit
      this.initFolder(res.data.folder)
      this._loading.next(false)
      return res.data
    })
  }

  public async deleteFolder (): Promise<any> {
    this._loading.next(true)
    return await apiClient.delete('settled-folder/' + String(this.folder.id)).then((res: any) => {
      this.initFolder()
      this._loading.next(false)
      return 'success'
    })
  }

  public updateFolder (): void {
    this._folder.next(this.folder)
  }

  public async getStatusHistory (): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('settled-folder/' + String(this.folder.id) + '/statusHistory').then((res: any) => {
      this.folder.statusHistory = res.data.statusHistory
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async attributeFolder (userId: number): Promise<any> {
    this._loading.next(true)
    return await apiClient.post('settled-folder/attribute', {
      folderId: this.folder.id,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async visitFolder (): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('settled-folder/visit', {
      folderId: this.folder.id,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async openFolder (): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('settled-folder/open', {
      folderId: this.folder.id,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async submitReport (fileId: string): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('settled-folder/submit', {
      folderId: this.folder.id,
      fileId,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status
      if (res.data.files) {
        this.folder.submittedFile = res.data.files.find((file: any) => file.id === fileId)
        this.initFiles(res.data.files)
      }
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async cancelSubmit (): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('settled-folder/submit/cancel', {
      folderId: this.folder.id,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status
      if (res.data.files) {
        this.initFiles(res.data.files)
      }
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async acceptValidation (): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('settled-folder/validation/accept', {
      folderId: this.folder.id,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status
      if (res.data.files) {
        this.initFiles(res.data.files)
      }
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async validateReport (comment: string): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('settled-folder/validate', {
      folderId: this.folder.id,
      comment,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status
      if (res.data.files) {
        this.initFiles(res.data.files)
      }
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async refuseValidation (comment: string): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('settled-folder/validation/refuse', {
      folderId: this.folder.id,
      comment,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status
      if (res.data.files) {
        this.initFiles(res.data.files)
      }
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async emailSent (): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('settled-folder/email', {
      folderId: this.folder.id,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async invoiceSent (): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('settled-folder/invoice', {
      folderId: this.folder.id,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async paymentSent (): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('settled-folder/payment', {
      folderId: this.folder.id,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async closeFolder (userId: number): Promise<any> {
    this._loading.next(true)
    return await apiClient.post('settled-folder/close', {
      folderId: this.folder.id,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async saveFolder (): Promise<any> {
    this._loading.next(true)
    const quote = quoteService.quote
    if ((authService.userValue != null) && authService.userValue.id) {
      quote.userId = authService.userValue.id
      this.folder.userId = authService.userValue.id
    }
    quote.billing = billingService.billing
    this.folder.report = reportService.report
    return await apiClient.post('settled-folder', {
      folder: this.folder,
      quote
    }).then((res: any) => {
      res.data.folder.conflit = res.data.conflit
      this.initFolder(res.data.folder)
      this._loading.next(false)
      return res.data
    })
  }

  public async generateDocument (): Promise<any> {
    this._loading.next(true)
    const client = this.folder.quote.contacts.find((contact: any) => contact.mandator === true && contact.type === 'applicant')
    let clientName = ''
    if (client && client.contact) {
      clientName = client.contact.prenom != null ? removeSpecialCharacters(client.contact.nom) + '_' + removeSpecialCharacters(client.contact.prenom) : removeSpecialCharacters(client.contact.nom)
    }
    return await axios.get(VUE_APP_RAPPORT_API_URL + 'settled-folder/' + String(this.folder.id) + '/report/download', {
      responseType: 'blob'
    }).then((res: any) => {
      this._loading.next(false)
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', String(this.folder.name) + '_' + String(this.folder.quote.addresses[0].buildingNumber) + '_' + removeSpecialCharacters(this.folder.quote.addresses[0].street) + '_' +
                String(this.folder.quote.addresses[0].zip) + '_' + removeSpecialCharacters(this.folder.quote.addresses[0].city) + '_' + String(clientName) + '.pptx') // any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
      return 'success'
    })
  }

  public async saveCategoryTree (folderId: number, newTree: CategoryTree[]): Promise<void> {
    await apiClient.post('settled-folder/category-tree', { folderId, newTree }).then(({ data }) => {
      if (data.categories) {
        fileService.initCategoryTree(data.categories)
        this.initFiles(data.files)
      }
    })
  }

  public async getFolderConflicts (folderId: number): Promise<void> {
    this._loading.next(true)
    return await apiClient.get('settled-folder/conflit/' + folderId.toString()).then((res: any) => {
      this._loading.next(false)
      return res.data
    })
  }

  public async deleteSettledFolder (folderId: number): Promise<any> {
    this._loading.next(true)
    await apiClient.delete('settled-folder/' + folderId.toString()).then((res: any) => {
      this._loading.next(false)
    }).catch((err) => {
      console.error(err)
      this._loading.next(false)
      throw err
    })
  }
}

export const settledFolderService = new SettledFolderService()
