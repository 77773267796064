
import { Component, Vue } from 'vue-property-decorator'
import { ListTheme, ListContent, GedCriteria } from '@/models/ged.model'
import { listReferenceService, ReferenceAddress } from '@/services/listReference.service'
import { gedService } from '@/services/ged.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import { authService } from '@/services/auth.service'

@Component({
  subscriptions () {
    return {
      gedCriteria$: gedService.onChangeGedCriteria,
      me$: authService.onChange
    }
  },
  components: {}
})

export default class CriteriaGED extends Vue {
  me$: User
  gedCriteria$: GedCriteria
  rules: any = {}
  listThemes: ListTheme[] = []
  listContents: ListContent[] = []

  created () {
    gedService.getListThemes().then((res: ListTheme[]) => {
      this.listThemes = res
    })
    gedService.getListContents().then((res: ListContent[]) => {
      this.listContents = res
    })
  }

  querySearch (queryString: string, cb: Function) {
    if (!queryString) return
    listReferenceService.getReferenceAddresse(queryString).then(res => {
      cb(res)
    })
  }

  handleSelectAddress (peliasAddress: ReferenceAddress) {
    this.gedCriteria$.peliasAddress = peliasAddress
  }

  clearPelias () {
    this.gedCriteria$.peliasAddress = new ReferenceAddress()
  }

  handleSearch () {
    if (!userService.hasRight(this.me$, USER_RIGHTS.DOCUMENT_LECTURE)) {
      this.$message({
        message: 'You have no right to search',
        type: 'error',
        offset: 65
      })
      return false
    }
    // permit to initialise current page at 1 when user try to apply a filter on the list
    gedService.updatePageParams({ currentPage: 1 })
    gedService.searchGed()
  }

  handleCancel () {
    this.$emit('clearFilter')
  }

  handleChangeDateMaxi (date: Date) {
    if (date) {
      date.setHours(23)
      date.setMinutes(59)
      date.setSeconds(59)
      this.gedCriteria$.dateMaxi = date
    }
  }
}
