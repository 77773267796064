
import { Component, Vue } from 'vue-property-decorator'
import { dashboardService } from '../../services/dashboard.service'
import { periodString } from '@/utils/functions'
import { Ged } from '@/models/ged.model' // eslint-disable-line @typescript-eslint/no-unused-vars
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions () {
    return {
      documents$: dashboardService.onChangeDocuments
    }
  },
  methods: {
    periodString
  }
})

export default class DashboardDocuments extends Vue {
  created () {
    dashboardService.getLastDocuments()
  }

  clickOnRow () {
    this.$router.push({
      name: 'Ged'
    })
  }
}
