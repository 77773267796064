<template>
  <div class="custom-form toggler toggler--double">
    <div
      v-for="(item, index) in list"
      :key="index"
      class="option-toggle"
      :class="{'option-toggle--right':index === list.length - 1,'active':item.value === val }">
      <input
        :id="`toggler-${item.label}`"
        v-model="val"
        type="radio"
        :value="item.value">
      <label
        :for="`toggler-${item.label}`"
        style="text-transform: capitalize;">{{ item.label }}</label>
    </div>

    <span
      class="option-toggler"
      :class="{'option--one':val===list[0].value,'option--two':val===list[1].value}"
      @click="toggle" />
  </div>
</template>
<script>
export default {
  name: 'CustomToggle',
  props: {
    value: {
      type: String,
      default: function () {
        return ''
      }
    },
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      val: this.value
    }
  },
  computed: {
    list () {
      const l = []
      this.options.forEach(el => {
        l.push({
          value: el,
          label: el
        })
      })

      l.splice(2, 10000)

      return l
    }
  },
  watch: {
    val (v) {
      if (this.disabled) {
        return
      }
      this.$emit('input', v)
    }
  },
  updated () {
    this.val = this.value
  },
  methods: {
    toggle () {
      if (this.disabled) {
        return
      }
      this.val = (this.val === this.options[0]) ? this.options[1] : this.options[0]
    }
  }
}
</script>
