import { render, staticRenderFns } from "./QuoteContacts.vue?vue&type=template&id=2772d684&scoped=true&"
import script from "./QuoteContacts.vue?vue&type=script&lang=ts&"
export * from "./QuoteContacts.vue?vue&type=script&lang=ts&"
import style0 from "./QuoteContacts.vue?vue&type=style&index=0&id=2772d684&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2772d684",
  null
  
)

export default component.exports