
import { fileService } from '@/services/file.service'
import html2canvas from 'html2canvas'
import b64toBlob from 'b64-to-blob'

export default {
  data () {
    return {
      isModalVisible: false,
      tableData: [],
      name: null,
      isSaving: false,
      type: null,
      id: null
    }
  },
  methods: {
    open (conflicts: any, name: any, type: string, id: number) {
      (this as any).tableData = conflicts;
      (this as any).name = name;
      (this as any).isModalVisible = true;
      (this as any).type = type;
      (this as any).id = id
    },
    close (): any {
      (this as any).isModalVisible = false
    },
    handleCurrentChange (val: any) {
      (this as any).currentRow = val
    },
    currentDateTime () {
      const date = new Date()
      return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + ' à ' + date.getHours() + 'H' + date.getMinutes()
    },
    // Capture a screenshot of .conflitContainer div
    saveConflits () {
      (this as any).isSaving = true
      const captureContainer = document.querySelector('.conflictContainer') as HTMLElement
      const self: any = this

      html2canvas(captureContainer, {
        backgroundColor: '#ffffff',
        allowTaint: false,
        useCORS: true,
        scrollX: 0,
        scrollY: -window.scrollY
      }).then(function (canvas) {
        const imgData = canvas.toDataURL('image/png')
        const contentType = imgData.split(',')[0]
        const b64Data = imgData.split(',')[1]
        const blob = b64toBlob(b64Data, contentType)

        const current = new Date()
        const date = current.getDate() + '-' + (current.getMonth() + 1) + '-' + current.getFullYear()
        const filename = 'conflits-d-interet-' + self.name + '-' + date + '.png'
        const imgFile = new File([blob], filename)
        fileService.saveConflictFile(imgFile, self.type, self.id).then(() => {
          self.$message({
            type: 'success',
            message: 'Sauvegarde réussie'
          })
          self.$emit('showConflits', false)
        }).catch(() => {
          self.$message({
            message: 'Sauvegarde échouée',
            type: 'error',
            offset: 65
          })
        }).finally(() => {
          self.close()
          self.isSaving = false
        })
      })
    }
  }
}
