
import { Component, Vue } from 'vue-property-decorator'
import { billingService } from '@/services/billing.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import { convertFrNumberEuro } from '@/utils/functions'
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions () {
    return {
      billing$: billingService.onChangeBilling,
      user$: userService.onChange
    }
  },
  methods: {
    convertFrNumberEuro
  }
})

export default class BillingState extends Vue {
  user$: User = new User()

  get readable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.BILLING_SECTION_READ)
  }
}
