import { apiClient } from './api.client'
import { BehaviorSubject, Observable } from 'rxjs'
import { quoteService, QuoteMission } from './quote.service'
import { authService } from './auth.service'
import { contactService, Contact } from './contact.service'
import { legalFolderService } from './legalFolder.service'
import { COURTHOUSE_BILLING } from '@/services/constants.service'
import { Service } from '@/services/Service'
import { settledFolderService } from '@/services/settledFolder.service'
import { DEFAULT_LEGAL_FOLDER_NAME } from '@/models/legalFolder.model'
import moment from 'moment'

export class ConsignationPayment {
  id: string
  date: string
  contactId: string
  amount: number
  contactName: string
  courthouseId: string

  constructor (data?: any) {
    this.id = (data && data.id) || null
    this.date = (data && data.date) || null
    this.contactName = (data && data.contactName) || ''
    this.amount = (data && data.amount) || 0
    this.courthouseId = (data && data.courthouseId) || null
    if (this.courthouseId != null) {
      this.contactId = 'billing_courthouse_select'
    } else {
      this.contactId = (data && data.contactId) || ''
    }
  }
}

export class Payment {
  id: string
  reference: string
  date: string
  contactId: string
  amount: number | null
  courthouseId: string

  constructor (data?: any) {
    this.id = (data && data.id) || null
    this.reference = (data && data.reference) || ''
    this.date = (data && data.date) ? data.date : null
    this.amount = (data && data.amount) || 0
    this.courthouseId = (data && data.courthouseId) || null
    if (this.courthouseId != null) {
      this.contactId = 'billing_courthouse_select'
    } else {
      this.contactId = (data && data.contactId) || ''
    }
  }
}

export class Debour extends Payment {
}

export class Remise extends Payment {
  percent: number

  constructor (data?: any) {
    super(data)
    this.percent = (data && data.percent) || 0
  }
}

export class Complement extends Payment {
}

export class ReceivedPayment extends Payment {
  comment: string

  constructor (data?: any) {
    super(data)
    this.comment = (data && data.comment) || ''
  }
}

export class Invoice extends Payment {
  number: string
  contactAddressId: string
  dueDate: string
  tvaClient: string
  referenceClient: string

  constructor (data?: any) {
    super(data)
    this.number = (data && data.number) || ''
    this.contactAddressId = (data && data.contactAddressId) || null
    this.dueDate = (data && data.dueDate) || moment().format('YYYY-MM-DD')
    this.tvaClient = (data && data.tvaClient) || ''
    this.referenceClient = (data && data.referenceClient) || ''
  }
}

export class DepositInvoice extends Invoice {
  percent: number
  monthCa: number
  anneeCa: number

  constructor (data?: any) {
    super(data)
    this.percent = (data && data.percent) || 0
    this.monthCa = (data && data.monthCa) || null
    this.anneeCa = (data && data.anneeCa) || null
  }
}

export class Avoir extends Invoice {
}

export class Retrocession extends Payment {
}

export class Billing {
  id: string
  mode: string
  missions: QuoteMission[]
  debours: Debour[]
  remises: Remise[]
  complements: Complement[]
  receivedPayments: ReceivedPayment[]
  depositInvoices: DepositInvoice[]
  invoices: Invoice[]
  avoirs: Avoir[]
  ownedAmount: number
  invoiceAmount: number
  pendingAmount: number
  paidAmount: number
  balanceAmount: number
  availableContacts: Contact[]
  consignationAmount: number | null
  consignationDate: string
  consignationPayments: ConsignationPayment[]
  retrocession: Retrocession[]

  constructor (data?: any) {
    this.id = (data && data.id) || null
    this.mode = (data && data.mode) || 'quote'
    this.missions = (data && data.missions) || []
    this.debours = (data && data.debours) || []
    this.remises = (data && data.remises) || []
    this.complements = (data && data.complements) || []
    this.receivedPayments = (data && data.receivedPayments) || []
    this.depositInvoices = (data && data.depositInvoices) || []
    this.invoices = (data && data.invoices) || []
    this.avoirs = (data && data.avoirs) || []
    this.ownedAmount = (data && data.ownedAmount) || 0
    this.invoiceAmount = (data && data.invoiceAmount) || 0
    this.pendingAmount = (data && data.pendingAmount) || 0
    this.paidAmount = (data && data.paidAmount) || 0
    this.balanceAmount = (data && data.balanceAmount) || 0
    this.availableContacts = (data && data.availableContacts) || []
    this.consignationAmount = (data && data.consignationAmount) || 0
    this.consignationDate = (data && data.consignationDate) || moment().format('YYYY-MM-DD')
    this.consignationPayments = (data && data.consignationPayments) || []
    this.retrocession = (data && data.retrocession) || []
  }
}

class BillingService extends Service {
  private readonly _billing: BehaviorSubject<Billing | any> = new BehaviorSubject<Billing | any>({})
  private readonly _billings: BehaviorSubject<Billing[]> = new BehaviorSubject<Billing[]>([])
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  public onChangeBilling: Observable<Billing | any> = this._billing.asObservable()
  public onChangeBillings: Observable<Billing[]> = this._billings.asObservable()
  public onLoading: Observable<boolean> = this._loading.asObservable()

  get billing (): Billing | any {
    return this._billing.getValue()
  }

  public initBilling (data?: any): void {
    // check billing contacts
    const availableContacts: any = {}
    const quote = quoteService.quote
    quote.contacts.forEach((item: any) => {
      if (item.contact && item.contact.id) {
        availableContacts[item.contact.id] = item.contact
      }
    })
    if (data) {
      if (data.retrocession && data.retrocession.length > 0) {
        data.retrocession.forEach((retrocession: any) => {
          retrocession.date = retrocession.date ? retrocession.date : moment().format('YYYY-MM-DD')
          retrocession.amount = (retrocession.amount || 0)
          retrocession.courthouse = retrocession.courthouse ? retrocession.courthouse.id : null
          if (retrocession.courthouse != null) {
            retrocession.contactId = COURTHOUSE_BILLING
          } else {
            retrocession.contactId = retrocession.contact ? retrocession.contact.id : null
          }
        })
      } else {
        data.retrocession = [new Retrocession()]
      }

      if (data.consignationPayments && data.consignationPayments.length > 0) {
        data.consignationPayments.forEach((payment: any) => {
          payment.amount = (payment.amount || 0)
          payment.contactName = payment.contact ? String(payment.contact.nom) + (payment.contact.prenom ? ' ' + String(payment.contact.prenom) : '') : ''
          payment.courthouse = payment.courthouse ? payment.courthouse.id : null
          if (payment.courthouse != null) {
            payment.contactId = COURTHOUSE_BILLING
          } else {
            payment.contactId = payment.contact ? payment.contact.id : null
          }
        })
      } else {
        data.consignationPayments = [new ConsignationPayment()]
      }

      /** if (data.missions && data.missions.length > 0) {
                data.missions.forEach((mission: any, index: any) => {
                    if (!mission.option) {
                        data.missions.splice(index, 1)
                    } else {
                        mission.amount = (mission.amount || 0);
                    }
                });
            } else {
                data.missions = [];
            }**/

      if (data.debours && data.debours.length > 0) {
        data.debours.forEach((debour: any) => {
          debour.date = debour.date ? debour.date : null
          debour.amount = (debour.amount || 0)
          debour.contactId = debour.contact ? debour.contact.id : null
        })
      } else {
        data.debours = [new Debour()]
      }

      if (data.remises && data.remises.length > 0) {
        data.remises.forEach((remise: any) => {
          remise.date = remise.date ? remise.date : null
          remise.amount = (remise.amount || 0)
          remise.contactId = remise.contact ? remise.contact.id : null
        })
      } else {
        data.remises = [new Remise()]
      }

      if (data.complements && data.complements.length > 0) {
        data.complements.forEach((complement: any) => {
          complement.date = complement.date ? complement.date : null
          complement.amount = (complement.amount || 0)
          complement.contactId = complement.contact ? complement.contact.id : null
        })
      } else {
        data.complements = [new Complement()]
      }

      if (data.receivedPayments && data.receivedPayments.length > 0) {
        data.receivedPayments.forEach((receivedPayment: any) => {
          receivedPayment.date = receivedPayment.date ? receivedPayment.date : null
          receivedPayment.amount = (receivedPayment.amount || 0)
          receivedPayment.courthouse = receivedPayment.courthouse ? receivedPayment.courthouse.id : null
          receivedPayment.comment = receivedPayment.comment ? receivedPayment.comment : null
          if (receivedPayment.courthouse != null) {
            receivedPayment.contactId = COURTHOUSE_BILLING
          } else {
            receivedPayment.contactId = receivedPayment.contact ? receivedPayment.contact.id : null
          }
        })
      } else {
        data.receivedPayments = [new ReceivedPayment()]
      }

      if (data.depositInvoices && data.depositInvoices.length > 0) {
        data.depositInvoices.forEach((depositInvoice: any) => {
          depositInvoice.amount = (depositInvoice.amount || 0)
          depositInvoice.date = depositInvoice.date ? depositInvoice.date : null
          depositInvoice.dueDate = depositInvoice.number ? depositInvoice.dueDate : depositInvoice.date
          depositInvoice.courthouse = depositInvoice.courthouse ? depositInvoice.courthouse.id : null
          if (depositInvoice.courthouse != null) {
            depositInvoice.contactId = COURTHOUSE_BILLING
            depositInvoice.contactAddressId = COURTHOUSE_BILLING
          } else {
            depositInvoice.contactId = depositInvoice.contact ? depositInvoice.contact.id : null
            depositInvoice.contactAddressId = depositInvoice.contactAddress ? depositInvoice.contactAddress.id : null
          }
        })
      } else {
        data.depositInvoices = [new DepositInvoice()]
      }

      if (data.invoices && data.invoices.length > 0) {
        data.invoices.forEach((invoice: any) => {
          invoice.date = invoice.date ? invoice.date : null
          invoice.dueDate = invoice.number ? invoice.dueDate : invoice.date
          invoice.amount = (invoice.amount || 0)
          invoice.courthouse = invoice.courthouse ? invoice.courthouse.id : null
          if (invoice.courthouse != null) {
            invoice.contactId = COURTHOUSE_BILLING
            invoice.contactAddressId = COURTHOUSE_BILLING
          } else {
            invoice.contactId = invoice.contact ? invoice.contact.id : null
            invoice.contactAddressId = invoice.contactAddress ? invoice.contactAddress.id : null
          }
        })
      } else {
        data.invoices = [new Invoice()]
      }

      if (data.avoirs && data.avoirs.length > 0) {
        data.avoirs.forEach((avoir: any) => {
          avoir.date = avoir.date ? avoir.date : null
          avoir.dueDate = avoir.number ? avoir.dueDate : avoir.date
          avoir.amount = (avoir.amount || 0)
          avoir.courthouse = avoir.courthouse ? avoir.courthouse.id : null
          if (avoir.courthouse != null) {
            avoir.contactId = COURTHOUSE_BILLING
            avoir.contactAddressId = COURTHOUSE_BILLING
          } else {
            avoir.contactId = avoir.contact ? avoir.contact.id : null
            avoir.contactAddressId = avoir.contactAddress ? avoir.contactAddress.id : null
          }
        })
      } else {
        data.avoirs = [new Avoir()]
      }

      data.availableContacts = Object.values(availableContacts)
      const fetchedBilling = new Billing(data)
      this._billing.next(fetchedBilling)
    } else {
      const billing = new Billing({
        consignationPayments: [new ConsignationPayment()],
        missions: [],
        debours: [new Debour()],
        remises: [new Remise()],
        complements: [new Complement()],
        receivedPayments: [new ReceivedPayment()],
        depositInvoices: [new DepositInvoice()],
        invoices: [new Invoice()],
        avoirs: [new Avoir()],
        availableContacts: Object.values(availableContacts),
        retrocession: [new Retrocession()]
      })
      this._billing.next(billing)
    }
  }

  public async updateAvailableContacts (): Promise<void> {
    const quote = quoteService.quote
    const contactsId = quote.contacts.map((contact: any) => contact.contactId)
    const contacts = await contactService.getContactsById(contactsId)

    this.billing.availableContacts = contacts.filter((contact: any) => {
      const find = quote.contacts.find((item: any) => item.contactId === contact.id)
      if (find) {
        return true
      }
      return false
    })
    // check billing contacts
    const checkingArray = ['debours', 'remises', 'complements', 'receivedPayments', 'depositInvoices', 'invoices', 'avoirs']
    checkingArray.forEach((checkingItem: string) => {
      this.billing[checkingItem].forEach((item: any) => {
        if (item.contactId) {
          const find = this.billing.availableContacts.find((contact: any) => contact.id === item.contactId)
          if (!find) {
            item.contactId = null
            if (item.contactAddressId) {
              item.contactAddressId = null
            }
          }
        }
      })
    })
    this.updateBilling()
  }

  public addConsignationPayment (index: number): void {
    const newConsignationPayment = new ConsignationPayment()
    this.billing.consignationPayments.splice(index + 1, 0, newConsignationPayment)
    this._billing.next(this.billing)
  }

  public deleteConsignationPayment (index: number): void {
    if (this.billing.consignationPayments.length > 1) {
      this.billing.consignationPayments.splice(index, 1)
    } else {
      this.billing.consignationPayments.splice(0, 1, new ConsignationPayment())
    }
    this.updateConsignationPayment()
  }

  public updateConsignationPayment (): void {
    this.updateBilling()
  }

  public addMission (index: number): void {
    const quote = quoteService.quote
    let maxNumber: number = quote.missions.reduce((a: any, b: any): number => {
      const bId = b.number ? parseInt(b.number.replace('E', '')) : 0
      return (a > bId) ? a : bId
    }, 0)
    maxNumber = this.billing.missions.reduce((a: any, b: any): number => {
      const bId = b.number ? parseInt(b.number.replace('E', '')) : 0
      return (a > bId) ? a : bId
    }, maxNumber)
    const newQuoteMission = new QuoteMission({ number: 'E' + (maxNumber + 1).toString(), option: true })
    this.billing.missions.splice(index + 1, 0, newQuoteMission)
    this._billing.next(this.billing)
  }

  public initMissionBilling (): void {
    const newQuoteMission = new QuoteMission({ number: 'E2', option: true })
    this.billing.missions.push(newQuoteMission)
    this._billing.next(this.billing)
  }

  public deleteMission (index: number): void {
    if (this.billing.missions.length > 1) {
      this.adjustBillingMissionsNumber(this.billing.missions[index].number)
      this.billing.missions.splice(index, 1)
    } else {
      this.billing.missions.splice(index, 1)
      this.addMission(this.billing.missions.length - 1)
    }
  }

  public adjustBillingMissionsNumber (number: string): void {
    const index = parseInt(number.replace('E', ''))
    this.billing.missions.forEach((billingMission: any) => {
      const idNumber = parseInt(billingMission.number.replace('E', ''))
      if (idNumber != null && index <= idNumber) {
        billingMission.number = 'E' + (idNumber - 1).toString()
      }
    })
    this.updateMission()
  }

  public updateMission (): void {
    this.updateBilling()
  }

  public addDebour (index: number): void {
    const newDebour = new Debour()
    this.billing.debours.splice(index + 1, 0, newDebour)
    this._billing.next(this.billing)
  }

  public deleteDebour (index: number): void {
    if (this.billing.debours.length > 1) {
      this.billing.debours.splice(index, 1)
    } else {
      this.billing.debours.splice(0, 1, new Debour())
    }
    this.updateDebour()
  }

  public updateDebour (): void {
    this.updateBilling()
  }

  public addRemise (index: number): void {
    const newRemise = new Remise()
    this.billing.remises.splice(index + 1, 0, newRemise)
    this._billing.next(this.billing)
  }

  public deleteRemise (index: number): void {
    if (this.billing.remises.length > 1) {
      this.billing.remises.splice(index, 1)
    } else {
      this.billing.remises.splice(0, 1, new Remise())
    }
    this.updateRemise()
  }

  public updateRemise (): void {
    this.updateBilling()
  }

  public addComplement (index: number): void {
    const newComplement = new Complement()
    this.billing.complements.splice(index + 1, 0, newComplement)
    this._billing.next(this.billing)
  }

  public deleteComplement (index: number): void {
    if (this.billing.complements.length > 1) {
      this.billing.complements.splice(index, 1)
    } else {
      this.billing.complements.splice(0, 1, new Complement())
    }
    this.updateComplement()
  }

  public updateComplement (): void {
    this.updateBilling()
  }

  public addReceivedPayment (index: number): void {
    const newReceivedPayment = new ReceivedPayment()
    this.billing.receivedPayments.splice(index + 1, 0, newReceivedPayment)
    this._billing.next(this.billing)
  }

  public deleteReceivedPayment (index: number): void {
    if (this.billing.receivedPayments.length > 1) {
      this.billing.receivedPayments.splice(index, 1)
    } else {
      this.billing.receivedPayments.splice(0, 1, new ReceivedPayment())
    }
    this.updateReceivedPayment()
  }

  public updateReceivedPayment (): void {
    this.updateBilling()
  }

  public addDepositInvoice (index: number): void {
    const newDepositInvoice = new DepositInvoice()
    this.billing.depositInvoices.splice(index + 1, 0, newDepositInvoice)
    this._billing.next(this.billing)
  }

  public deleteDepositInvoice (index: number): void {
    if (this.billing.depositInvoices[index].id) {
      this.deleteBillingRow('deposit', this.billing.depositInvoices[index].id).catch((err) => {
        throw err
      })
      this.billing.depositInvoices[index].id = null
    }
    if (this.billing.depositInvoices.length > 1) {
      this.billing.depositInvoices.splice(index, 1)
    } else {
      this.billing.depositInvoices.splice(0, 1, new DepositInvoice())
      this.billing.depositInvoices[0].number = ''
    }
    this.updateDepositInvoice()
  }

  public updateDepositInvoice (): void {
    this.updateBilling()
  }

  public addInvoice (index: number): void {
    const newInvoice = new Invoice()
    this.billing.invoices.splice(index + 1, 0, newInvoice)
    this._billing.next(this.billing)
  }

  public deleteInvoice (index: number): void {
    if (this.billing.invoices[index].id) {
      this.deleteBillingRow('invoice', this.billing.invoices[index].id).catch((err) => {
        throw err
      })
      this.billing.invoices[index].id = null
    }
    if (this.billing.invoices.length > 1) {
      this.billing.invoices.splice(index, 1)
    } else {
      this.billing.invoices.splice(0, 1, new Invoice())
      this.billing.invoices[0].number = ''
    }
    this.updateInvoice()
  }

  public updateInvoice (): void {
    this.updateBilling()
  }

  public addAvoir (index: number): void {
    const newAvoir = new Avoir()
    this.billing.avoirs.splice(index + 1, 0, newAvoir)
    this._billing.next(this.billing)
  }

  public deleteAvoir (index: number): void {
    if (this.billing.avoirs[index].id) {
      this.deleteBillingRow('avoir', this.billing.avoirs[index].id).catch((err) => {
        throw err
      })
      this.billing.avoirs[index].id = null
    }
    if (this.billing.avoirs.length > 1) {
      this.billing.avoirs.splice(index, 1)
    } else {
      this.billing.avoirs.splice(0, 1, new Avoir())
      this.billing.avoirs[0].number = ''
    }
    this.updateAvoir()
  }

  public updateAvoir (): void {
    this.updateBilling()
  }

  public addRetrocession (index: number): void {
    const newRetrocession = new Retrocession()
    this.billing.retrocession.splice(index + 1, 0, newRetrocession)
    this.updateRetrocession()
  }

  public deleteRetrocession (index: number): void {
    if (this.billing.retrocession.length > 1) {
      this.billing.retrocession.splice(index, 1)
    } else {
      this.billing.retrocession.splice(0, 1, new Retrocession())
    }
    this.updateRetrocession()
  }

  public updateRetrocession (): void {
    this.updateBilling()
  }

  public updateBilling (mode?: string): void {
    if (mode) {
      this.billing.mode = mode
    }
    this.calculateBilling()
    this._billing.next(this.billing)
  }

  public calculateBilling (): void {
    const quote = quoteService.quote
    const legalFolder = legalFolderService.folder

    const sumBillingActiveMission = this.sumOfArrayValue(this.billing.missions.filter((item: any) => {
      // check if mission exist and if exist check option to never calculate twice same mission
      const mission = quote.missions.find((c: QuoteMission) => c.number === item.number)
      return (mission && mission.option === true && item.option) || (!mission && item.option)
    }), 'amount')
    const sumBillingRemise = this.sumOfArrayValue(this.billing.remises, 'amount') // Amount of all "remise"
    const sumBillingDebour = this.sumOfArrayValue(this.billing.debours, 'amount') // Amount of all "débours"
    const sumBillingComplement = this.sumOfArrayValue(this.billing.complements, 'amount') // Amount of all "complément"
    const sumBillingReceivedPayment = this.sumOfArrayValue(this.billing.receivedPayments, 'amount') // Amount of all "Paiements reçu"
    const sumBillingDepositInvoice = this.sumOfArrayValue(this.billing.depositInvoices, 'amount') // Amount of all "Factures d'acompte"
    const sumBillingInvoice = this.sumOfArrayValue(this.billing.invoices, 'amount') // Amount of all "Factures"
    const sumBillingAvoir = this.sumOfArrayValue(this.billing.avoirs, 'amount') // Amount of all "avoirs"

    if (this.billing.mode !== 'legal-folder') {
      // calculate percent on remises
      this.billing.remises.forEach((item: any) => {
        const total = parseFloat(quote.totalHT) /* + sumQuoteActiveMission */ + sumBillingActiveMission + sumBillingDebour + sumBillingComplement
        if (total > 0) {
          item.percent = ((parseFloat(item.amount || '0') * 100) / total).toFixed(1)
        } else {
          item.percent = 0
        }
      })
      // calcuate percent on deposit invoices
      this.billing.depositInvoices.forEach((item: any) => {
        const total = parseFloat(quote.totalHT) /* + sumQuoteActiveMission */ + sumBillingActiveMission - sumBillingRemise + sumBillingDebour + sumBillingComplement
        if (total > 0) {
          item.percent = ((parseFloat(item.amount || '0') * 100) / total).toFixed(1)
        } else {
          item.percent = 0
        }
      })
      this.billing.ownedAmount = parseFloat(quote.totalHT) /* + sumQuoteActiveMission */ + sumBillingActiveMission + sumBillingComplement - sumBillingRemise + sumBillingDebour
      this.billing.invoiceAmount = sumBillingDepositInvoice + sumBillingInvoice + sumBillingAvoir
      this.billing.pendingAmount = this.billing.ownedAmount - this.billing.invoiceAmount
      this.billing.paidAmount = (sumBillingReceivedPayment / 1.2 || 0)
      this.billing.balanceAmount = this.billing.invoiceAmount - this.billing.paidAmount
    } else {
      this.billing.ownedAmount = legalFolder.modification.getTotalPrice() + legalFolder.costs.getTotalPrice()
      // calculate percent on deposit invoices
      this.billing.depositInvoices.forEach((item: any) => {
        const total = this.billing.ownedAmount
        if (total > 0) {
          item.percent = ((parseFloat(item.amount || '0') * 100) / total).toFixed(1)
        } else {
          item.percent = 0
        }
      })
      this.billing.invoiceAmount = sumBillingDepositInvoice + sumBillingInvoice + sumBillingAvoir
      this.billing.pendingAmount = this.billing.ownedAmount - this.billing.invoiceAmount
      this.billing.paidAmount = sumBillingReceivedPayment / 1.2 || 0
      this.billing.balanceAmount = this.billing.invoiceAmount - this.billing.paidAmount
    }
  }

  public sumOfArrayValue (array: any[], key: string): number {
    return array.reduce((a: any, b: any): number => Number(a) + (parseFloat(b[key] || '0')), 0)
  }

  public generateBillNumber (array: any[], prefix?: string): string {
    const maxId: number = array.reduce((a: any, b: any): number => {
      const bId = b.number ? parseInt(b.number.substr(-3)) : 0
      return (a > bId) ? a : bId
    }, 0)
    const formattedId = ('00' + (maxId + 1).toString()).slice(-3)
    const today = new Date()
    const year = '' + today.getFullYear().toString()
    const month = '0' + (today.getMonth() + 1).toString()

    return String(prefix) + '-' + year.slice(-2) + month.slice(-2) + formattedId
  }

  public async generateBillingDocument (type: string, rowIndex: number, fileName?: string): Promise<any> {
    this._loading.next(true)
    const quote = quoteService.quote
    const settleFolder = settledFolderService.folder
    const folder = legalFolderService.folder
    if ((authService.userValue != null) && authService.userValue.id) {
      quote.userId = authService.userValue.id
    }
    let name: string = quote.name
    if (folder.name !== DEFAULT_LEGAL_FOLDER_NAME) {
      name = folder.name
    }
    if (settleFolder && settleFolder.name) {
      quote.settledFolderName = settleFolder.name
      name = quote.settledFolderName
    }
    if (fileName === null) {
      let typeName = ''
      switch (type) {
        case 'deposit':
          typeName = 'Demande Acompte ' + name
          break
        case 'invoice':
          typeName = 'Facture ' + name
          break
        case 'avoir':
          typeName = 'Facture Avoir ' + name
          break
      }
      const today = new Date()
      const year = '' + today.getFullYear().toString()
      const month = '0' + (today.getMonth() + 1).toString()
      const date = '0' + today.getDate().toString()
      fileName = typeName + ' ' + date.slice(-2) + month.slice(-2) + year
    } else {
      fileName = String(fileName) + ' - ' + name
    }
    quote.billing = this.billing

    return await apiClient.post('billing/download', {
      mode: this.billing.mode,
      type,
      quote,
      folder,
      rowIndex
    }, {
      responseType: 'blob'
    }).then((res: any) => {
      this._loading.next(false)
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', String(fileName) + '.docx') // any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
      return 'success'
    })
  }

  public async saveBillingRow (type: string, row: any): Promise<any> {
    this._loading.next(true)
    const billingId = this.billing.id
    return await apiClient.post('billing/row', {
      type,
      billingId,
      row
    }).then((res: any) => {
      this._loading.next(false)
      return res.data
    })
  }

  public async deleteBillingRow (type: string, rowId: number): Promise<any> {
    this._loading.next(true)
    return await apiClient.delete('billing/row/' + type + '/' + rowId.toString()).then((res: any) => {
      this._loading.next(false)
      return res.data
    })
  }
}

export const billingService = new BillingService()
