
import { Component, Prop, Vue } from 'vue-property-decorator'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import { ListReference, listReferenceService } from '@/services/listReference.service'
import { localeDateStr } from '@/utils/functions'
    // The @Component decorator indicates the class is a Vue component
    @Component({
      subscriptions () {
        return {
          user$: userService.onChange
        }
      }
    })

export default class FolderSearches extends Vue {
        @Prop({ type: Array })
          searches: any

        user$: User = new User()
        typeLocalLists: ListReference[] = []
        typeValeurLists: ListReference[] = []
        activitesLists: ListReference[] = []
        localeDateStr: Function = localeDateStr

        methods: {
        }

        created () {
          listReferenceService.getListReference().then(res => {
            this.typeLocalLists = res.filter((item: ListReference) => item.type === 1)
            this.activitesLists = res.filter((item: ListReference) => item.type === 3)
            this.typeValeurLists = res.filter((item: ListReference) => item.type === 11)
          })
        }

        typeLocalName (typeLocalId: number): string {
          const self = this
          if (typeLocalId) {
            const typeLocal = self.typeLocalLists.find((item: ListReference) => item.num === typeLocalId)
            return typeLocal ? typeLocal.label : ''
          } else {
            return ''
          }
        }

        typeValeurName (typeValeurId: number): string {
          const self = this
          if (typeValeurId) {
            const typeValeur = self.typeValeurLists.find((item: ListReference) => item.num === typeValeurId)
            return typeValeur ? typeValeur.label : ''
          } else {
            return ''
          }
        }

        activitesName (activites: number[]) : string {
          const self = this
          if (activites) {
            const activitesLabel = activites.map((id: number) => {
              const activites = self.activitesLists.find((item: ListReference) => item.num === id)
              return activites ? activites.label : ''
            })
            return activitesLabel.join(', ')
          } else {
            return ''
          }
        }

        resultCount (result: any[]) {
          let total = 0
          result.forEach((item: any) => {
            if (item.data) {
              total += item.data.length
            }
          })
          return total
        }

        get readable (): boolean {
          return userService.hasRight(this.user$, USER_RIGHTS.SEARCH_READ)
        }
}
