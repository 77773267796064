
import { Component, Vue } from 'vue-property-decorator'
import {
  listReferenceService,
  ReferenceAddress,
  referenceService
} from '@/services/listReference.service'
import { requireRule } from '../formRules'

@Component({
  subscriptions (this: any) {
    return {
      value: referenceService.onChangeReference,
      referenceAddress: referenceService.onChangeReferenceAdressse
    }
  }
})
export default class ReferenceAddressComponent extends Vue {
  value: any
  referenceAddress: ReferenceAddress
  requireRule = requireRule

  querySearch (queryString: string, cb: Function) {
    if (!queryString) return
    listReferenceService.getReferenceAddresse(queryString).then((res) => {
      cb(res)
    })
  }

  additionalQuerySearch (queryString: string, layer: string, cb: Function) {
    if (!queryString) return
    const { locality, street, housenumber, postalcode } = this.referenceAddress
    referenceService.updateReferenceAdresse(this.referenceAddress)
    const query: string = `${housenumber || ' '}${street ? (' ' + street) : ''}${postalcode ? (', ' + postalcode) : ''}${locality ? (', ' + locality) : ''}&type=${layer}`
    listReferenceService.getReferenceAddresse(query).then((res) => {
      cb(res)
    })
  }

  handleSelect (item: ReferenceAddress): void {
    this.referenceAddress = item
    referenceService.updateReferenceAdresse(item)
  }

  handleSelectStreet (item: ReferenceAddress): void {
    const newAddress: ReferenceAddress = Object.assign({}, {
      ...this.referenceAddress,
      street: item.street,
      borough: item.borough,
      locality: item.locality,
      macroregion: item.macroregion,
      postalcode: item.postalcode,
      country: item.country,
      layer: item.layer,
      lat: item.lat,
      lon: item.lon,
      label: item.label
    })
    this.referenceAddress = newAddress
    referenceService.updateReferenceAdresse(newAddress)
  }

  handleBlur () {
    referenceService.updateReferenceAdresse(this.referenceAddress)
  }

  handleSelectNumber (item: ReferenceAddress): void {
    const newAddress: ReferenceAddress = Object.assign({}, {
      ...this.referenceAddress,
      housenumber: item.housenumber,
      street: item.street,
      borough: item.borough,
      locality: item.locality,
      macroregion: item.macroregion,
      postalcode: item.postalcode,
      country: item.country,
      layer: item.layer,
      lat: item.lat || this.referenceAddress.lat,
      lon: item.lon || this.referenceAddress.lon,
      label: item.label
    })
    this.referenceAddress = newAddress
    referenceService.updateReferenceAdresse(newAddress)
  }

  handleSelectCity (item: ReferenceAddress): void {
    const newAddress: ReferenceAddress = Object.assign({}, {
      ...this.referenceAddress,
      locality: item.locality,
      macroregion: item.macroregion,
      postalcode: item.postalcode,
      country: item.country,
      layer: item.layer,
      lat: item.lat,
      lon: item.lon,
      label: item.label
    })
    this.referenceAddress = newAddress
    referenceService.updateReferenceAdresse(newAddress)
  }

  onChangeField (data: ReferenceAddress): void {
    const newAddress: ReferenceAddress = Object.assign({}, {
      ...this.referenceAddress,
      ...data
    })
    this.referenceAddress = newAddress
    referenceService.updateReferenceAdresse(newAddress)
  }

  clearReferenceAddress () {
    referenceService.reset(this.value)
    this.referenceAddress = new ReferenceAddress()
  }

  clearSelection (item: any): void {
    switch (item) {
      case 'locality' :
        this.referenceAddress.locality = ''
        break
      case 'postalCode' :
        this.referenceAddress.postalcode = ''
        break
      case 'street' :
        this.referenceAddress.street = ''
        break
      case 'subHousenumber' :
        this.referenceAddress.subHousenumber = ''
        break
      case 'housenumber' :
        this.referenceAddress.housenumber = ''
        break
    }
    referenceService.initReferenceAddresse(this.referenceAddress)
  }
}
