

export default {
  props: {
    result: {
      type: Object,
      required: true
    }
  },
  methods: {
    getContactAddress (addresses: any) {
      const address = addresses[0]
      return address.buildingnumber + ' ' + address.street + ' ' + address.city
    }
  }
}
