import { apiClient } from '@/services/api.client'
import { BehaviorSubject, Observable } from 'rxjs'
import { authService } from './auth.service'
import { AxiosResponse } from 'axios'

export const enum USER_RIGHTS {
  FOLDER_READ = 1,
  FOLDER_UPDATE = 2,
  FOLDER_ASSIGNATION = 4,
  LM_SECTION_READ = 5,
  LM_SECTION_UPDATE = 6,
  FILE_SECTION_READ = 7,
  FILE_SECTION_UPDATE = 8,
  MAIL_SECTION_READ = 9,
  MAIL_SECTION_UPDATE = 10,
  RAPPORT_SECTION_READ = 11,
  RAPPORT_SECTION_UPDATE = 12,
  LEGAL_FOLDER_READ = 13,
  LEGAL_FOLDER_UPDATE = 14,
  BILLING_SECTION_READ = 15,
  BILLING_SECTION_UPDATE = 16,
  UPDATE_STATUS_READ = 17,
  UPDATE_STATUS_UPDATE = 18,
  CLOSE_FOLDER_READ = 19,
  CLOSE_FOLDER_UPDATE = 20,
  QUOTE_UPDATE = 24,
  REFERENCE_CREATE = 25,
  REFERENCE_UPDATE = 26,
  EXTRACTION_50_DAY = 27,
  EXTRACTION_150_DAY = 28,
  EXTRACTION_NL_DAY = 29,
  SEARCH_READ = 30,
  SEARCH_SAVE = 31,
  DOCUMENT_LECTURE = 32,
  DOCUMENT_MODIFICATION = 33,
  DOWNLOAD_LEVEL_1 = 36,
  DOWNLOAD_LEVEL_2 = 37,
  DOWNLOAD_LEVEL_3 = 38,
  ADM = 45,
  VDD = 46,
  ROLE_RIGHTS = 43,
  USER_RIGHTS = 42,
  CLIENT_RIGHTS = 44,
  EXPORT_FOLDER_LIST = 47,

  DELETE_DOCUMENT = 49,
  DELETE_CONTACT = 50,
  DELETE_USER = 51,
  DELETE_REFERENCE = 52,

  DASHBOARD_GEN = 53,
  DASHBOARD_ADMIN = 54,
  DASHBOARD_DOCUMENTALISTE = 55,
  DASHBOARD_PERSONAL_STATS = 56
}

export const enum USER_ROLES {
  ADMINISTRATOR = 1,
  DOCUMENTALIST,
  DOCUMENTALIST_CONFIRMED,
  BACK_OFFICE,
  BACK_OFFICE_CONFIRMED,
  EXPERT,
  EXPERT_CONFIRMED
};

export class User {
  id?: number
  avatarId: number | null
  titre: string | null
  nom: string
  prenom: string
  email: string
  password: string
  activer: boolean
  totpEnabled: boolean
  roles: any[]
  rights: any[]
  position: string
  avatarFile: any | null

  constructor (data: any = {}) {
    this.id = data.id || null
    this.titre = data.titre || null
    this.avatarId = data.avatarFile ? data.avatarFile.id : null
    this.avatarFile = data.avatarFile || null
    this.nom = data.nom || ''
    this.prenom = data.prenom || ''
    this.email = data.email || ''
    this.password = data.password || ''
    this.activer = data.activer || false
    this.totpEnabled = data.totpEnabled || false
    this.roles = data.roles ? data.roles.map((role: any) => role.id) : []
    this.rights = data.rights || []
    this.position = data.position || ''
  }
}

export class Note {
  id: number
  userId: number
  content: string
  constructor (data: any = {}) {
    this.id = data.id || null
    this.userId = data.userId || null
    this.content = data.content || ''
  }
}
// this class is responsible for managing the logged user.
class UserService {
  private readonly _user: BehaviorSubject<User | any> = new BehaviorSubject<User | any>({})
  private readonly _users: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([])
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  private readonly _activeUserIndex: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null)
  private readonly _totp_enabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private readonly _totp_backup_codes: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])
  public onChange: Observable<User> = this._user.asObservable() // subscribe to user change
  public onChangeUsers: Observable<User[]> = this._users.asObservable() // subscribe to user list change
  public onLoading: Observable<boolean> = this._loading.asObservable() // subscribe to loading status change
  public onChangeActiveUserIndex: Observable<number | null> = this._activeUserIndex.asObservable()
  public onChangeTotpEnabled: Observable<boolean> = this._totp_enabled.asObservable()
  public onChangeTotpBackupCodes: Observable<string[]> = this._totp_backup_codes.asObservable()

  get user (): User | any {
    return this._user.getValue()
  }

  public async getUsers (nom: string = '', prenom: string = '', email: string = '', roles: number[] = []): Promise<void> {
    this._loading.next(true)
    await apiClient.get('users', { params: { nom, prenom, email, roles: JSON.stringify(roles) } }).then(res => {
      if (res.data) {
        const users: User[] = []
        res.data.forEach((user: any) => {
          users.push(new User(user))
        })
        this._users.next(users)
        this._loading.next(false)
      }
    })
  }

  /* Find Users with filters and SizePage CurrentPage SortProp SortOrder criteria */
  public async getUsersByCriteria (filterContact: any): Promise<void> {
    this._loading.next(true)
    const nom = filterContact.nom
    const prenom = filterContact.prenom
    const email = filterContact.email
    const roles = filterContact.roles
    const sizePage = filterContact.sizePage
    const currentPage = filterContact.currentPage
    const sortProp = filterContact.sortProp
    const sortOrder = filterContact.sortOrder
    await apiClient.get('users-criteria', { params: { nom, prenom, email, roles, sizePage, currentPage, sortProp, sortOrder } }).then(res => {
      if (res.data) {
        const users: User[] = []
        res.data.data.forEach((user: any) => {
          users.push(new User(user))
        })
        this._users.next(users)
        this._loading.next(false)
      }
    })
  }

  public async getUserById (userId: string): Promise<void> {
    return await apiClient.get('user/' + userId).then(res => {
      this._user.next(new User(res.data))
      return res.data
    })
  }

  public initUser (): void {
    this._user.next(new User())
  }

  public async toggle2fa (userId: number): Promise<void> {
    await apiClient.post('user/2fa/toggle', { userId }).then(res => {
      this._totp_enabled.next(res.data.enabled)

      authService.refreshUser().catch((err) => {
        throw err
      })
    }).catch((err) => {
      throw err
    })
  }

  public async getBackupCodes (): Promise<void> {
    await apiClient.get('user/2fa/backup-codes').then(res => {
      this._totp_backup_codes.next(res.data)
    }).catch((err) => {
      throw err
    })
  }

  public async saveUser (user: User): Promise<void> {
    await apiClient.post('user', {
      id: user.id,
      titre: user.titre,
      nom: user.nom.toUpperCase(),
      prenom: user.prenom,
      email: user.email,
      activer: user.activer,
      password: user.password,
      roles: user.roles,
      avatarId: user.avatarId
    }).then(res => {
      if (authService.userValue != null) {
        if (user.id === authService.userValue.id) {
          authService.refreshUser().catch((err) => {
            throw err
          })
        }
      }
      this._user.next(new User(res.data))
    }).catch((err) => {
      throw err
    })
  }

  public async deleteUser (id: any): Promise<void> {
    await apiClient.delete('user/' + String(id)).then(res => {
      // this._user.next(res.data);
    })
  }

  public async getUserRights (id: any): Promise<any> {
    return await apiClient.get('user/rights/' + String(id))
  }

  public hasRight (user: any, right: number): boolean {
    const rights = user.rights
    if (rights) {
      if (rights.find((item: any) => item.id === right)) {
        return true
      }
    }
    return false
  }

  public hasRole (user: any, role: number): boolean {
    const roles = user.roles
    if (roles) {
      if (roles.find((item: any) => item === role)) {
        return true
      }
    }
    return false
  }

  public async sendResetPasswordLink (email: string): Promise<void> {
    await apiClient.post('password/link', {
      email
    })
  }

  public async validateResetPasswordToken (token: string): Promise<void> {
    await apiClient.get('password/validate/' + token)
  }

  public async changePassword (token: string, password: string): Promise<AxiosResponse<any>> {
    return await apiClient.post('password/change', {
      token,
      password
    })
  }

  public async createNote (note: any): Promise<AxiosResponse<any>> {
    return await apiClient.post('user/note', note)
  }

  public async updateNote (note: any): Promise<AxiosResponse<any>> {
    return await apiClient.put('user/note/' + String(note.id), note)
  }

  public async deleteNote (noteId: number): Promise<AxiosResponse<any>> {
    return await apiClient.delete('user/note/' + noteId.toString())
  }

  public async getUsersByKey (searchKey: string, deletedUsers: boolean = false): Promise<void> {
    this._loading.next(true)
    await apiClient.get('users/' + searchKey + (deletedUsers ? '?delete=true' : '')).then(res => {
      if (res.data) {
        const users: User[] = []
        res.data.forEach((user: any) => {
          users.push(new User(user))
        })
        users.sort((a: User, b: User) => (a.nom > b.nom) ? 1 : -1)
        this._users.next(users)
        this._loading.next(false)
      }
    })
  }

  public async getUserFilter (): Promise<void> {
    this._loading.next(true)
    await apiClient.get('user/filter').then(res => {
      if (res.data) {
        const users: User[] = []
        res.data.forEach((user: any) => {
          users.push(new User(user))
        })
        users.sort((a: User, b: User) => (a.nom > b.nom) ? 1 : -1)
        this._users.next(users)
        this._loading.next(false)
      }
    })
  }

  public activeUserIndex (index: number | null): void {
    this._activeUserIndex.next(index)
  }

  public isAdministrator (): boolean {
    const userRoles = (authService.userValue != null) ? authService.userValue.roles : null

    if (userRoles != null) {
      if (userRoles.find((role: any) => role.id === USER_ROLES.ADMINISTRATOR)) {
        return true
      }
    }

    return false
  }
}

export const userService = new UserService()
