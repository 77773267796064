
import { isNil } from 'lodash'
import { baseUrl } from '@/services/api.client'
import { Component, Vue, Ref } from 'vue-property-decorator'
import { authService } from '@/services/auth.service'
import { User, USER_RIGHTS, userService } from '@/services/user.service'
import { Role, roleService } from '@/services/role.service'
import { Form } from 'element-ui/types'
import { tap } from 'rxjs/operators'

@Component({
  subscriptions (this: any) {
    return {
      user$: userService.onChange.pipe(
        tap((res: any) => {
          if (res && res.nom && res.prenom) {
            document.title = res.prenom + ' ' + res.nom
          }
          if (res.avatarFile) {
            this.fileList = [{
              name: res.avatarFile.name,
              url: res.avatarFile.url
            }]
          } else {
            this.fileList = []
          }
        })
      ),
      roles: roleService.onChange,
      authUser$: authService.onChange,
      totpEnabled: userService.onChangeTotpEnabled,
      totpBackupCodes: userService.onChangeTotpBackupCodes
    }
  }
})
export default class UserView extends Vue {
  @Ref() readonly userForm!: Form
  baseUrl: string | undefined = baseUrl
  roles: Role[] = []
  fileList: any[] = []
  user$: User = new User()
  authUser$: User = new User()
  displayTotpQrCode: boolean = false
  totpEnabled: boolean = false
  totpBackupCodes: string[] = []

  rules: any = {
    nom: [
      { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' }
    ],
    prenom: [
      { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' }
    ],
    email: [
      { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
      {
        type: 'email',
        message: 'Veuillez entrer une adresse email valide',
        trigger: ['blur']
      }
    ],
    roles: [
      { required: true, message: 'Au moins un rôle doit être sélectionné', trigger: 'blur' }
    ],
    password: [
      {
        pattern: /^(?=.*[A-Z])/,
        message: 'Le mot de passe doit contenir au moins une majuscule',
        trigger: 'blur'
      },
      {
        pattern: /^(?=.*[a-z])/,
        message: 'Le mot de passe doit contenir au moins une minuscule',
        trigger: 'blur'
      },
      {
        pattern: /^(?=.{8,})/,
        message: 'Le mot de passe doit contenir au moins 8 caractères',
        trigger: 'blur'
      }
    ]
  }

  created () {
    // let user = authService.userValue;
    /* if (!userService.hasRight(user, USER_RIGHTS.USER_RIGHTS)) {
        this.$router.replace("/");
    } else { */
    const userId = isNil(this.$route.params.id) ? null : this.$route.params.id

    roleService.getRoles()
    if (userId !== null) {
      userService.getUserById(userId).then(() => {
        if (this.user$.id === this.authUser$.id && this.user$.totpEnabled) {
          this.displayTotpQrCode = true
          userService.getBackupCodes()
        }
      })
    } else {
      userService.initUser()
    }
    // }
  }

  get isCreation () {
    return isNil(this.$route.params.id)
  }

  get roleEditable (): boolean {
    return userService.hasRight(this.authUser$, USER_RIGHTS.USER_RIGHTS)
  }

  handleRemove () {
    if (this.user$.avatarId) {
      this.user$.avatarId = null
    }
  }

  handleSuccess (response: any) {
    if (response && response.id) {
      this.user$.avatarId = response.id
    }
  }

  submitForm () {
    this.userForm.validate((valid: any) => {
      if (valid) {
        userService.saveUser(this.user$).then(() => {
          this.$message({
            message: "Sauvegarde de l'utilisateur réussi",
            type: 'success',
            offset: 65
          })
          this.$router.push({ name: 'users' })
        }).catch(() => {
          this.$message({
            message: "Erreur de sauvegarde de l'utilisateur",
            type: 'error',
            offset: 65
          })
        })
      }
      return valid
    })
  }

  toggle2fa () {
    userService.toggle2fa(Number(this.user$.id)).then(() => {
      this.displayTotpQrCode = this.totpEnabled && this.user$.id === this.authUser$.id

      if (this.displayTotpQrCode) {
        userService.getBackupCodes()
      }
    })
  }

  isUserAllowedToDeleteAnotherUser () {
    const user = JSON.parse(JSON.stringify(authService.userValue))
    return userService.hasRight(user, USER_RIGHTS.DELETE_USER)
  }

  deleteUser () {
    userService.deleteUser(this.user$.id).then(() => {
      this.$router.push({ name: 'users' })
    })
  }

  goBack () {
    this.$router.push({ name: 'users' })
  }
}
