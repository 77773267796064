import { Courrier, CourrierContact } from '@/models/legalFolder.model'
import Docxtemplater from 'docxtemplater'
import { saveAs } from 'file-saver'
import PizZip from 'pizzip'
import { apiClient } from '@/services/api.client'
import { getPriceString } from '@/utils/functions'

import { DocumentData } from '@/utils/courrierDocUtils'

export class HonorairesAPI {
  public renderData: any
  public documentData: DocumentData
  public constructor (courrierContacts: CourrierContact[]) {
    this.documentData = new DocumentData(new Courrier(), courrierContacts)
    this.renderData = {
      courthouseNom: this.documentData.courthouseNom,
      sectionNumber: this.documentData.legalFolder$.sectionNumber,
      miNumber: this.documentData.legalFolder$.miNumber,
      applicantContactsWithMandator: this.documentData.applicantContactsWithMandator,
      respondentContactsWithMandator: this.documentData.respondentContactsWithMandator,
      dateOfDecision: this.documentData.dateOfDecision,
      dossierName: this.documentData.legalFolder$.name,
      dateOfFinalReportDeposit: this.documentData.dateOfFinalReportDeposit,
      displacementTemp: this.documentData.sumData('displacement'),
      placeTemp: this.documentData.sumData('place'),
      dossierTemp: this.documentData.sumData('dossier'),
      researchTemp: this.documentData.sumData('research'),
      cabinetTemp: this.documentData.sumData('cabinet'),
      redactionTemp: this.documentData.sumData('redaction'),
      responseTemp: this.documentData.sumData('response'),
      displacementPrice: getPriceString(this.documentData.priceData('displacement')),
      placePrice: getPriceString(this.documentData.priceData('place')),
      dossierPrice: getPriceString(this.documentData.priceData('dossier')),
      researchPrice: getPriceString(this.documentData.priceData('research')),
      cabinetPrice: getPriceString(this.documentData.priceData('cabinet')),
      redactionPrice: getPriceString(this.documentData.priceData('redaction')),
      responsePrice: getPriceString(this.documentData.priceData('response')),
      displacementTotal: getPriceString(this.documentData.modificationCharge('displacement')),
      placeTotal: getPriceString(this.documentData.modificationCharge('place')),
      dossierTotal: getPriceString(this.documentData.modificationCharge('dossier')),
      researchTotal: getPriceString(this.documentData.modificationCharge('research')),
      cabinetTotal: getPriceString(this.documentData.modificationCharge('cabinet')),
      redactionTotal: getPriceString(this.documentData.modificationCharge('redaction')),
      responseTotal: getPriceString(this.documentData.modificationCharge('response')),
      modificationTotalCharge: getPriceString(this.documentData.legalFolder$.modification.getTotalPrice()),
      displacementAmount: this.documentData.fraisAmountData('displacement'),
      secretariatAmount: this.documentData.fraisAmountData('secretariat'),
      correspondanceAmount: this.documentData.fraisAmountData('correspondance') || 0,
      reprographieAmount: this.documentData.fraisAmountData('reprographie') || 0,
      telephoneAmount: this.documentData.fraisAmountData('telephone') || 0,
      remunerationAmount: this.documentData.fraisAmountData('remuneration') || 0,
      accommodationAmount: this.documentData.fraisAmountData('accommodation') || 0,
      otherAmount: this.documentData.fraisAmountData('other') || 0,
      displacementFraisPrice: getPriceString(this.documentData.fraisPriceData('displacement')),
      secretariatFraisPrice: getPriceString(this.documentData.fraisPriceData('secretariat')),
      correspondanceFraisPrice: getPriceString(this.documentData.fraisPriceData('correspondance')),
      reprographieFraisPrice: getPriceString(this.documentData.fraisPriceData('reprographie')),
      telephoneFraisPrice: getPriceString(this.documentData.fraisPriceData('telephone')),
      remunerationFraisPrice: getPriceString(this.documentData.fraisPriceData('remuneration')),
      accommodationFraisPrice: getPriceString(this.documentData.fraisPriceData('accommodation')),
      otherFraisPrice: getPriceString(this.documentData.fraisPriceData('other')),
      displacementFraisTotal: getPriceString(this.documentData.fraisCharge('displacement')),
      secretariatFraisTotal: getPriceString(this.documentData.fraisCharge('secretariat')),
      correspondanceFraisTotal: getPriceString(this.documentData.fraisCharge('correspondance')),
      reprographieFraisTotal: getPriceString(this.documentData.fraisCharge('reprographie')),
      telephoneFraisTotal: getPriceString(this.documentData.fraisCharge('telephone')),
      remunerationFraisTotal: getPriceString(this.documentData.fraisCharge('remuneration')),
      accommodationFraisTotal: getPriceString(this.documentData.fraisCharge('accommodation')),
      otherFraisTotal: getPriceString(this.documentData.fraisCharge('other')),
      fraisTotalCharge: getPriceString(this.documentData.legalFolder$.costs.getTotalPrice()),
      honorairesTotal: getPriceString(this.documentData.legalFolder$.modification.getTotalPrice() + this.documentData.legalFolder$.costs.getTotalPrice()),
      honorairesTax: getPriceString((this.documentData.legalFolder$.modification.getTotalPrice() + this.documentData.legalFolder$.costs.getTotalPrice()) * 0.2),
      honorairesGeneral: getPriceString((this.documentData.legalFolder$.modification.getTotalPrice() + this.documentData.legalFolder$.costs.getTotalPrice()) * 1.2),
      chamber: this.documentData.legalFolder$.chamber,
      courthouseAddress: this.documentData.courthouseAddress,
      consignationPayments: this.documentData.consignationPayments
    }
  }

  public generate (): void {
    apiClient.get('file/template/honoraires', { responseType: 'arraybuffer' }).then(content => {
      const zip = new PizZip(content.data)
      const doc = new Docxtemplater().loadZip(zip).setOptions({
        delimiters: {
          start: '{{',
          end: '}}'
        },
        nullGetter (part: any, scopeManager: any) {
          if (!part.module) {
            return `MISSING(${String(part.value)})`
          }
          if (part.module === 'rawxml') {
            return ''
          }
          return ''
        },
        parser: function (tag: any) {
          return {
            get (scope: any, context: any) {
              if (tag === '$index') {
                const indexes = context.scopePathItem
                return indexes[indexes.length - 1]
              }
              if (tag === '$isLast') {
                const totalLength = context.scopePathLength[context.scopePathLength.length - 1]
                const index = context.scopePathItem[context.scopePathItem.length - 1]
                return index === totalLength - 1
              }
              if (tag === '$isFirst') {
                const index = context.scopePathItem[context.scopePathItem.length - 1]
                return index === 0
              }
              return scope[tag]
            }
          }
        }
      })
      doc.setData(this.renderData)
      doc.render()
      const out = doc.getZip().generate({
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      })
      saveAs(out, `honoraires-${new Date().toLocaleDateString('fr-fr')}.docx`)
    }).catch((err) => {
      throw err
    })
  }
}
