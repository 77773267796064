
import { Component, Vue } from 'vue-property-decorator'
import { authService } from '@/services/auth.service'
import { Role, roleService } from '@/services/role.service'
import { Right, rightService } from '@/services/right.service'
import { User, USER_RIGHTS, userService } from '@/services/user.service'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

@Component({
  subscriptions (this: Vue) {
    return {
      roles: roleService.onChange,
      rights: rightService.onChange,
      loading: roleService.onLoading,
      user$: userService.onChange,
      activeRoleIndex$: roleService.onChangeActiveRoleIndex
    }
  },
  components: {
    ClipLoader
  }
})
export default class Roles extends Vue {
  roles: Role[] = []
  rights: Right[] = []
  loading: boolean = true
  user$: User = new User()
  form = {
    nom: '',
    description: '',
    selectedRights: [],
    sortProp: 'nom',
    sortOrder: 'ASC'
  }

  isFixesIndex: boolean = false
  activeRoleIndex$: number = 0

  created () {
    const user = authService.userValue
    if (!userService.hasRight(user, USER_RIGHTS.ROLE_RIGHTS)) {
      this.$router.replace('/')
    } else {
      roleService.getRoles()
      rightService.getRights()
    }
  }

  tableData () {
    return this.roles
  }

  navigateToNewRoleForm () {
    this.$router.push('/role')
  }

  navigateToUpdateRoleForm (id: any) {
    window.open(this.$router.resolve({ path: '/role/' + id }).href, '_blank')
  }

  filterResults () {
    roleService.getRoles(
      this.form.nom,
      this.form.description,
      this.form.selectedRights,
      this.form.sortProp,
      this.form.sortOrder
    )
  }

  resetFilters () {
    this.form = {
      nom: '',
      description: '',
      selectedRights: [],
      sortOrder: 'ASC',
      sortProp: 'nom'
    }
    this.filterResults()
  }

  handleSortChange (prop: string) {
    this.form.sortProp = prop
    this.form.sortOrder = this.form.sortOrder === 'ASC' ? 'DESC' : 'ASC'
    this.filterResults()
  }

  changeIndex (index = null) {
    if (!this.isFixesIndex) {
      roleService.activeRoleIndex(index)
    }
  }
}
