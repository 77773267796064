
import { Component, Prop, Vue } from 'vue-property-decorator'
import { billingService, Billing } from '@/services/billing.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import FormattedFloat from '../utils/FormattedFloat.vue'
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions () {
    return {
      billing$: billingService.onChangeBilling,
      user$: userService.onChange
    }
  },
  components: {
    FormattedFloat
  }
})

export default class BillingRemises extends Vue {
  @Prop({ default: false }) disabled: boolean

  billing$: Billing = new Billing()
  user$: User = new User()

  get updateable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.BILLING_SECTION_UPDATE) && !this.disabled
  }

  selectContact (contactId: string, item: any, itemIndex: number): void {
    item.contactId = contactId
    this.billing$.remises.splice(itemIndex, 1, item)
  }

  deleteRemise (index: number): void {
    billingService.deleteRemise(index)
  }

  addRemise (index: number): void {
    billingService.addRemise(index)
  }

  changeAmount (): void {
    billingService.updateRemise()
  }
}
