
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// The @Component decorator indicates the class is a Vue component
@Component({})

export default class FormattedNumber extends Vue {
    @Prop({ default: null }) value: number | null
    @Prop({ default: '' }) placeholder: string
    @Prop({ default: false }) disabled: boolean
    @Prop({ default: false }) readonly: boolean
    @Prop({ default: false }) prefix: boolean
    @Prop({ default: false }) suffix: boolean
    formattedValue: string = ''

    @Watch('value')
    onValueChanged (val: any) {
      const value = this.formatNumber((val))
      this.formattedValue = value !== 'NaN' && value != null ? value : ''
    }

    created () {
      if (this.value != null) {
        const value = this.formatNumber(Math.floor(this.value))
        this.formattedValue = value !== 'NaN' && value != null ? value : ''
      }
    }

    handleFocus (): void {
      if (!this.readonly && !this.disabled) {
        this.formattedValue = ''
      }
    }

    handleBlur (): void {
      if (!this.readonly && !this.disabled && this.formattedValue === '' && this.value != null) {
        const value = this.formatNumber(Math.floor(this.value))
        this.formattedValue = value !== 'NaN' && value != null ? value : ''
      }
    }

    changeValue (): void {
      const changed = this.reverseFormatNumber(this.formattedValue, 'fr')
      if (changed != null) {
        const value = this.formatNumber(changed)
        if (value != null) {
          this.$emit('input', (Math.floor(changed)))
          this.formattedValue = value
        }
      }
    }

    formatNumber (number: number): string | null {
      if (number != null) {
        return new Intl.NumberFormat('fr-FR', {
          style: 'decimal',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }).format(number)
      } else {
        return null
      }
    }

    reverseFormatNumber (val: string, locale: string): number | null {
      const group: string = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '')
      const decimal: string = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '')
      const reversedVal: string = val.replace(new RegExp('\\' + group, 'g'), '')
      const reversedNumber = parseFloat(reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.'))
      return Number.isNaN(reversedNumber) ? null : reversedNumber
    }

    clearInput () {
      this.$emit('input', null)
    }
}
