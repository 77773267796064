<template>
  <div class="auth-wrap d-flex align-items-center pb-5 justify-content-center text-dark">
    <div class="w-100 mb-5 pb-5">
      <el-row class="px-3 d-flex w-100 justify-content-center mb-2">
        <img
          src="@/assets/AVISIO-01.png"
          class="logo"
          alt="avisio-logo">
      </el-row>
      <el-row class="px-3 d-flex w-100 justify-content-center">
        <el-col :md="8">
          <el-alert
            v-if="hasError"
            class="mb-3"
            title="Code incorrect"
            type="error"
            show-icon
            :closable="false" />
          <el-form
            ref="twoFactorForm"
            :model="credentials"
            label-width=""
            label-position="left"
            :rules="formRules">
            <el-form-item
              label=""
              prop="authCode"
              class="mb-6">
              <el-input
                v-model="credentials.authCode"
                class="block"
                type="text"
                autocomplete="off"
                placeholder="Code"
                @keydown.enter.native.prevent="submitForm('twoFactorForm')" />
            </el-form-item>

            <div class="d-flex justify-content-end justify-content-center">
              <el-button
                :loading="loading"
                type="primary"
                class="el-button status-btn el-button--default is-round"
                @click="submitForm('twoFactorForm')">
                Connexion
              </el-button>
            </div>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { authService } from '@/services/auth.service'

export default {
  name: 'TwoFactor',
  subscriptions () {
    return {
      user: authService.onChange,
      loading: authService.onLoading,
      twoFactor: authService.onTwoFactor
    }
  },
  data () {
    return {
      credentials: {
        authCode: ''
      },
      formRules: {
        authCode: [
          {
            required: true,
            message: 'Le code doit être renseigné',
            trigger: 'blur'
          }
        ]
      },
      hasError: false
    }
  },
  watch: {
    $route () {
      this.redirect()
    }
  },
  created () {
    this.redirect()
  },
  methods: {
    submitForm (formName) {
      this.hasError = false
      this.$refs[formName].validate(valid => {
        if (valid) {
          const payload = this.credentials
          authService
            .two_factor(payload.authCode)
            .then(() => {
              this.redirect()
            })
            .catch(() => {
              this.loading = false
              this.hasError = true
            })
        }
        return valid
      })
    },
    redirect () {
      const redirect = this.$route.query.redirect
      if (this.user) {
        if (typeof redirect !== 'undefined') {
          this.$router.push({ path: redirect })
        } else {
          this.$router.push({ path: '/global-search' })
        }
      }
    }
  }
}
</script>
<style>
.logo {
  height: 100px;
}
</style>
