import { apiClient } from '@/services/api.client'
import { BehaviorSubject, Observable } from 'rxjs'
import { Ged, ListTheme, ListContent, GedCriteria, PageParams } from '@/models/ged.model'

class GedService {
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private readonly _showFormModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private readonly _activeGedIndex: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null)
  private readonly _totalGed: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  private readonly _pageParams: BehaviorSubject<PageParams> = new BehaviorSubject<PageParams>(new PageParams())
  private readonly _geds: BehaviorSubject<Ged[]> = new BehaviorSubject<Ged[]>([])
  private readonly _ged: BehaviorSubject<Ged> = new BehaviorSubject<Ged>(new Ged())
  private readonly _gedCriteria: BehaviorSubject<GedCriteria> = new BehaviorSubject<GedCriteria>(new GedCriteria())
  public onLoading: Observable<boolean> = this._loading.asObservable()
  public onChangeShowFormModal: Observable<boolean> = this._showFormModal.asObservable()
  public onChangeActiveGedIndex: Observable<number | null> = this._activeGedIndex.asObservable()
  public onChangeTotalGed: Observable<number> = this._totalGed.asObservable()
  public onChangePageParams: Observable<PageParams> = this._pageParams.asObservable()
  public onChangeGeds: Observable<Ged[]> = this._geds.asObservable()
  public onChangeGed: Observable<Ged> = this._ged.asObservable()
  public onChangeGedCriteria: Observable<GedCriteria> = this._gedCriteria.asObservable()

  get ged (): Ged {
    return this._ged.getValue()
  }

  get gedCriteria (): GedCriteria {
    return this._gedCriteria.getValue()
  }

  get pageParams (): PageParams {
    return this._pageParams.getValue()
  }

  public updatePageParams (params: any): void {
    const oldParams = this._pageParams.getValue()
    const newParams = { ...oldParams, ...params }
    this._pageParams.next(newParams)
  }

  public updateGedCriteria (gedCriteria: GedCriteria): void {
    this._gedCriteria.next(gedCriteria)
  }

  public updateGedForm (ged: Ged): void {
    this._ged.next(ged)
  }

  public showFormModal (show: boolean): void {
    this._showFormModal.next(show)
  }

  public activeGedIndex (index: number | null): void {
    this._activeGedIndex.next(index)
  }

  public async getAllGeds (): Promise<void> {
    this._loading.next(true)
    await apiClient.get('geds/all').then(res => {
      this._loading.next(false)
      const geds: Ged[] = []
      res.data.forEach((ged: any) => {
        geds.push(new Ged(ged))
      })
      this._geds.next(geds)
    }).catch(() => {
      this._loading.next(false)
    })
  }

  public async getGedById (id: number): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('ged/' + id.toString()).then(res => {
      this._loading.next(false)
      const ged: Ged = new Ged(res.data)
      this._ged.next(ged)
      return ged
    }).catch(() => {
      this._loading.next(false)
    })
  }

  public async getListThemes (): Promise<ListTheme[]> {
    this._loading.next(true)
    return await apiClient.get('list-theme').then(res => {
      this._loading.next(false)
      return res.data
    }).catch(() => {
      this._loading.next(false)
    })
  }

  public async getListContents (): Promise<ListContent[]> {
    this._loading.next(true)
    return await apiClient.get('list-content').then(res => {
      this._loading.next(false)
      return res.data
    }).catch(() => {
      this._loading.next(false)
    })
  }

  public async saveGed (): Promise<void> {
    this._loading.next(true)
    await apiClient.post('ged', { ...this.ged }).then(res => {
      this._loading.next(false)
      const geds = this._geds.getValue()
      const existIndex = geds.findIndex((ged: any) => ged.id === res.data.id)
      if (existIndex > -1) {
        geds.splice(existIndex, 1, res.data)
      } else {
        geds.push(res.data)
      }
      this._geds.next(geds)
    }).catch((err) => {
      this._loading.next(false)
      throw err
    })
  }

  public async deleteGed (): Promise<void> {
    this._loading.next(true)
    await apiClient.delete('ged/' + this.ged.id.toString()).then(res => {
      this._loading.next(false)
      const geds = this._geds.getValue()
      const gedDeletedIndex = geds.findIndex((ged) => ged.id === this.ged.id)
      if (gedDeletedIndex !== -1) {
        geds.splice(gedDeletedIndex, 1)
      }
      this._geds.next(geds)
    }).catch(() => {
      this._loading.next(false)
    })
  }

  public async getGeds (): Promise<void> {
    this._loading.next(true)
    await apiClient.get('geds', { params: { pageParams: this.pageParams, criteria: this.gedCriteria } }).then(res => {
      this._loading.next(false)
      const geds: Ged[] = []
      res.data.items.forEach((ged: any) => {
        geds.push(new Ged(ged))
      })
      this._totalGed.next(res.data.total)
      this._geds.next(geds)
    }).catch((err) => {
      this._loading.next(false)
      throw err
    })
  }

  public async searchGed (): Promise<Ged[]> {
    this._loading.next(true)
    return await apiClient.get('ged/search', { params: { pageParams: this.pageParams, criteria: this.gedCriteria } }).then(res => {
      this._loading.next(false)
      const geds: Ged[] = []
      res.data.items.forEach((ged: any) => {
        geds.push(new Ged(ged))
      })
      this._totalGed.next(res.data.total)
      this._geds.next(geds)
      return geds
    }).catch((err) => {
      this._loading.next(false)
      throw err
    })
  }

  public async proSearchGed (proSearch: string): Promise<void> {
    this._loading.next(true)
    await apiClient.get('ged/pro/search', { params: { pageParams: this.pageParams, proQuery: proSearch } }).then(res => {
      const geds: Ged[] = []
      res.data.items.forEach((ged: any) => {
        geds.push(new Ged(ged))
      })
      this._geds.next(geds)
      this._totalGed.next(res.data.total)
      this._loading.next(false)
    }).catch(() => {
      this._loading.next(false)
    })
  }

  public async checkDownload (): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('check-download').then(res => {
      this._loading.next(false)
      return res.data
    }).catch((err) => {
      this._loading.next(false)
      throw err
    })
  }

  public async deleteGedById (gedId: number): Promise<void> {
    this._loading.next(true)
    await apiClient.delete('ged/' + gedId.toString()).then(res => {
      this._loading.next(false)
    }).catch(() => {
      this._loading.next(false)
    })
  }
}

export const gedService = new GedService()
