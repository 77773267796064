
import { Component, Vue } from 'vue-property-decorator'
import { tap } from 'rxjs/operators'
import L from 'leaflet'
import 'leaflet.markercluster'
import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'
import 'leaflet-defaulticon-compatibility'
import { listReferenceService, ReferenceAddress } from '@/services/listReference.service'
import { dossierService } from '@/services/dossier.service'
import { MarkerIcon } from '@/components/ged/MapGED.vue'
import Dossier from '@/components/dossier/Dossier.vue'
declare let require: any
const tgm = require('@targomo/leaflet/targomo-leaflet-full.umd.js')

@Component({
  name: 'DossierMap',
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  subscriptions (this: any) {
    return {
      dossier$: dossierService.onChangeDossier.pipe(
        tap((res: any) => {
          if (this.mapLoaded && res) {
            this.activeMarker(this.dossiers$.findIndex((c: Dossier) => c === res))

            if (this.focusMap) {
              return
            }

            if (res.lat && res.lon) {
              this.map.setView([res.lat, res.lon])
            }
          }
        })
      ),
      dossiers$: dossierService.onChangeDossiers.pipe(
        tap((res: any) => {
          const markers: any[] = []
          res.forEach((dossier: any, index: number) => {
            if (dossier.lat && dossier.lon) {
              markers.push({
                show: true,
                iconColor: '#810D00',
                referenceIndex: index,
                dossierId: dossier.id,
                latitude: dossier.lat,
                longitude: dossier.lon,
                createdDateLabel: dossier.createdDateLabel
              })
            }
          })
          this.markers = markers
          if (this.mapLoaded) {
            this.removeMarkers()
            this.drawMarkers()
          }
        })
      )
    }
  },
  components: {}
})
export default class DossierMap extends Vue {
  dossiers$: any[] = []
  map: any = null
  mapLoaded: boolean = false
  tileLayer: any = null
  markers: any = []
  selectedMarker: any = null
  markersLayer: any = null
  circlesLayer: any = null
  polygonOverlayLayer: any = null
  mapboxUrl = 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png'
  mapboxAttribution = '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'
  cordinates = {
    latitude: 48.86,
    longitude: 2.34
  }

  viewAddress: string = ''
  focusMap: boolean
  searchMarker: any = null

  mounted () {
    this.initMap()
    L.DomEvent.disableClickPropagation(
      this.$refs.searchBar as HTMLDivElement
    )
  }

  initMap () {
    const mapContainer: any = L.DomUtil.get('map')
    if (mapContainer != null) {
      mapContainer._leaflet_id = null
    }
    this.map = new (L.map as any)('map', {
      zoomControl: true,
      dragging: true,
      renderer: L.canvas()
    }).on('load', () => {
      this.mapLoaded = true
      setTimeout(() => {
        this.drawMarkers()
      }, 500)
    }).setView([this.cordinates.latitude, this.cordinates.longitude], 13)
    this.tileLayer = L.tileLayer(this.mapboxUrl, {
      maxZoom: 18,
      attribution: this.mapboxAttribution
    })
    this.tileLayer.addTo(this.map)
    // define the polygon overlay
    this.polygonOverlayLayer = new tgm.TgmLeafletPolygonOverlay({ strokeWidth: 20 })
  }

  drawMarkers () {
    const markers: any = []
    const previousLat: Array<any> = []
    this.markers.forEach((marker: any, index: number) => {
      if (!marker.show) {
        return
      }

      if (!marker.latitude && !marker.longitude) {
        return
      }

      marker.latitude = parseFloat(marker.latitude)
      marker.longitude = parseFloat(marker.longitude)

      let latitude: number = marker.latitude
      let longitude: number = marker.longitude

      let markerColorLeft: string = '#810D00'
      let markerColorRight: string = '#910300'
      // Changing marker color depending on folder created date
      if (marker.createdDateLabel) {
        // changing createdDateLabel format (DD/MM/YYYY) to a JS Date Format
        const dateParts = marker.createdDateLabel.split('/')
        // month is 0-based, that's why we need dataParts[1] - 1
        const formatedDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])

        // Create 2 date limit to change marker color by time
        const dateLimit1 = new Date()
        const dateLimit2 = new Date()

        // 2 Years past now
        dateLimit1.setDate(dateLimit1.getDate() - 730)
        // 5 Years past now
        dateLimit2.setDate(dateLimit2.getDate() - 1825)

        if (formatedDate > dateLimit1) {
          markerColorLeft = '#9FE79D'
          markerColorRight = '#A6EAA5'
        } else if (formatedDate < dateLimit1 && formatedDate > dateLimit2) {
          markerColorLeft = '#F2BC71'
          markerColorRight = '#EEB971'
        } else {
          markerColorLeft = '#f56c6c'
          markerColorRight = '#f06969'
        }
      }
      // permit ot check if one marker is in same place
      let countMarker: number = 0
      previousLat.forEach((currentMarker: any) => {
        countMarker += (currentMarker.latitude === marker.latitude && currentMarker.longitude === marker.longitude) ? 1 : 0
      })
      if (countMarker > 0) {
        const delta = (countMarker / 10000)
        latitude += delta
        longitude += delta
      }

      // check if document have address marker
      if (!latitude || !longitude || (latitude === 0 && longitude === 0)) {
        return
      }

      const MarkerIcon: MarkerIcon = L.marker(
        [latitude, longitude],
        {
          icon: L.divIcon({
            className: 'custom-div-icon',
            html: `<div id="marker${index}" class='marker-pin'>
                                        <?xml version="1.0" encoding="utf-8"?>
                                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 512 512" xml:space="preserve" height="32">
                                            <style type="text/css">
                                            .st0{fill:` + markerColorLeft + `;}
                                            .st1{fill:#FFFFFF;}
                                            .st2{fill:` + markerColorRight + `;}
                                            </style>
                                            <path class="st0" d="M256,0C156.7,0,76,80.7,76,180c0,33.6,9.3,66.3,27,94.5l140.8,230.4c2.4,3.9,6,6.3,10.2,6.9
                                            c5.7,0.9,12-1.5,15.3-7.2l141.2-232.5c16.8-27.6,25.5-59.7,25.5-92.1C436,80.7,355.3,0,256,0z M256,270c-50.4,0-90-40.8-90-90
                                            c0-49.5,40.5-90,90-90s90,40.5,90,90C346,228.9,307,270,256,270z"/>
                                            <circle class="st1" cx="256.5" cy="180" r="90.5"/>
                                            <path class="st2" d="M256,0v90c49.5,0,90,40.5,90,90c0,48.9-39,90-90,90v242c5.1,0.1,10.4-2.3,13.3-7.4l141.2-232.5
                                            c16.8-27.6,25.5-59.7,25.5-92.1C436,80.7,355.3,0,256,0z"/>
                                        </svg>
                                    </div>`,
            iconSize: [30, 42],
            iconAnchor: [15, 42]
          })
        }
      ).on('mouseover', () => {
        this.handleMarkerClick(MarkerIcon)
      }).on('click', () => {
        this.openFolder(MarkerIcon)
      })
      MarkerIcon.id = index
      MarkerIcon.latitude = latitude
      MarkerIcon.longitude = longitude
      MarkerIcon.dossierId = marker.dossierId
      markers.push(MarkerIcon)
      previousLat.push({ latitude: marker.latitude, longitude: marker.longitude })
    })
    this.markersLayer = L.layerGroup(markers)
    this.markersLayer.addTo(this.map)
  }

  removeMarkers () {
    if (this.markersLayer !== null) {
      this.markersLayer.clearLayers()
    }
  }

  querySearch (queryString: string, cb: Function) {
    if (!queryString) return
    listReferenceService.getReferenceAddresse(queryString).then(res => {
      cb(res)
    })
  }

  handleMarkerClick (marker: any) {
    this.selectedMarker = marker
    if (this.searchMarker) {
      this.searchMarker.remove()
    }
    const dossier = this.dossiers$.find((item: any) => item.id === marker.dossierId)
    dossierService.setDossier(dossier)
    return dossier
  }

  openFolder (marker: any) {
    const dossier = this.handleMarkerClick(marker)
    if (dossier !== undefined) {
      if (dossier.type === 'quote') {
        window.open(this.$router.resolve({ path: '/devis/' + dossier.id }).href, '_blank')
      } else if (dossier.type === 'settled-folder') {
        window.open(this.$router.resolve({ path: '/dossier-amiable/' + dossier.id }).href, '_blank')
      } else if (dossier.type === 'legal-folder') {
        window.open(this.$router.resolve({ path: '/dossier-judiciaire/' + dossier.id }).href, '_blank')
      }
    }
  }

  handleSelectViewAddress (item: ReferenceAddress) {
    if (this.searchMarker) {
      this.searchMarker.remove()
    }
    this.cordinates.latitude = item.lat
    this.cordinates.longitude = item.lon
    let zoom = 10
    switch (item.layer) {
      case 'macroregion':
      case 'region':
      case 'macrocounty':
        zoom = 11
        break
      case 'locality':
      case 'localadmin':
        zoom = 12
        break
      case 'borough':
      case 'neighbourhoods':
      case 'coarse':
      case 'postalcode':
        zoom = 15
        break
      case 'venue':
      case 'address':
      case 'street':
        zoom = 19
        break
      default:
        break
    }
    this.map.setView([this.cordinates.latitude, this.cordinates.longitude], zoom)
    this.searchMarker = L.marker([this.cordinates.latitude, this.cordinates.longitude], {
      icon: L.divIcon({
        className: 'custom-div-icon',
        html: `<div class='marker-pin' style="opacity: 1">
                                <?xml version="1.0" encoding="utf-8"?>
                                <!-- Generator: Adobe Illustrator 24.1.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 512 512" xml:space="preserve" height="20">
                                    <style type="text/css">
                                        .searchMap{fill:#1C3283;}
                                    </style>
                                    <g>
                                        <g>
                                          <path class="searchMap" d="M256,0c-70.6,0-128,57.4-128,128c0,67,51.7,122,117.3,127.5v245.9c0,5.9,4.8,10.7,10.7,10.7
                                            s10.7-4.8,10.7-10.7V255.5C332.3,250,384,195,384,128C384,57.4,326.6,0,256,0z M256,64c-35.3,0-64,28.7-64,64
                                            c0,5.9-4.8,10.7-10.7,10.7s-10.7-4.8-10.7-10.7c0-47.1,38.3-85.3,85.3-85.3c5.9,0,10.7,4.8,10.7,10.7C266.7,59.2,261.9,64,256,64z
                                            "/>
                                        </g>
                                    </g>
                                </svg>
                            </div>`,
        iconSize: [30, 42],
        iconAnchor: [15, 42]
      })
    })
    this.searchMarker.addTo(this.map)
    this.map.setView([this.cordinates.latitude, this.cordinates.longitude], zoom)
  }

  mouseOver () {
    this.focusMap = true
  }

  mouseLeave () {
    this.focusMap = false
  }

  activeMarker (index: number) {
    const elements = document.getElementsByClassName('marker-pin')
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i]
      if (!element) {
        continue
      }
      element.classList.remove('active')
      if (!element.parentElement) {
        continue
      }
      element.parentElement.style.zIndex = `-${(i + 1) * 70}`
    }
    const element = document.getElementById(`marker${index}`)
    if (!element) {
      return
    }
    element.classList.add('active')
    if (!element.parentElement) {
      return
    }
    element.parentElement.style.zIndex = '1000'
  }
}
