interface Option<T> {
  value: T
  label: string
}

interface QuoteStatus {
  value: number
  label: string
  action: string
  class?: string
}

export const QUOTE_STATUES: QuoteStatus[] = [
  {
    value: 0,
    label: 'En création',
    action: 'Soumettre la LM'
  },
  {
    value: 1,
    label: 'Soumettre la LM',
    action: 'Soumettre la LM'
  },
  {
    value: 2,
    label: 'En attente d\'acceptation de validation',
    action: 'En attente d\'acceptation de validation'
  },
  {
    value: 3,
    label: 'En attente de validation',
    action: 'Valider la LM'
  },
  {
    value: 4,
    label: 'LM validée',
    action: 'Email envoyé',
    class: 'statut-email-envoyer'
  },
  {
    value: 5,
    label: 'Envoyé',
    action: 'Ouvrir',
    class: 'statut-envoyer'
  },
  {
    value: 6,
    label: 'Signé',
    action: 'Signé',
    class: 'statut-signer'
  },
  {
    value: 7,
    label: 'Ouvert',
    action: 'Ouvert',
    class: 'statut-ouvert'
  },
  {
    value: 8,
    label: 'Devis refusé',
    action: 'Devis refusé'
  },
  {
    value: 9,
    label: 'Devis conflicté',
    action: 'Devis conflicté'
  },
  {
    value: 10,
    label: 'Devis Annulé',
    action: 'Devis Annulé'
  }
]

export const SETTED_FOLDER_STATUES: QuoteStatus[] = [
  {
    value: 0,
    label: 'Ouvert',
    action: 'Attribué',
    class: 'statut-ouvert'
  },
  {
    value: 1,
    label: 'Attribué',
    action: 'Visité'
  },
  {
    value: 2,
    label: 'Visité',
    action: 'Soumettre le rapport'
  },
  {
    value: 3,
    label: 'En attente d\'acceptation de validation',
    action: 'En attente d\'acceptation de validation'
  },
  {
    value: 4,
    label: 'Rédigé',
    action: 'Valider le rapport'
  },
  {
    value: 5,
    label: 'Validé',
    action: 'Email envoyé'
  },
  {
    value: 6,
    label: 'Envoyé',
    action: 'Facturé',
    class: 'statut-email-envoyer'
  },
  {
    value: 7,
    label: 'Facturé',
    action: 'Payé'
  },
  {
    value: 8,
    label: 'Payé',
    action: 'Cloturé'
  },
  {
    value: 9,
    label: 'Clôturé',
    action: 'Cloturé'
  },
  {
    value: 10,
    label: 'Annulé',
    action: 'Annulé le dossier'
  }
]

export const LEGAL_FOLDER_STATUES: QuoteStatus[] = [
  {
    value: 0,
    label: 'Ouvert',
    action: 'Attribué',
    class: 'statut-ouvert'
  },
  {
    value: 1,
    label: 'Attribué',
    action: 'Visité'
  },
  {
    value: 2,
    label: 'Visité',
    action: 'Soumettre le prérapport'
  },
  {
    value: 3,
    label: 'En attente d\'acceptation de validation prérapport',
    action: 'En attente d\'acceptation de validation prérapport'
  },
  {
    value: 4,
    label: 'Pré-rapport rédigé',
    action: 'Valider le prérapport'
  },
  {
    value: 5,
    label: 'Pré-rapport validé',
    action: 'Prérapport envoyé'
  },
  {
    value: 6,
    label: 'Pré-rapport envoyé',
    action: 'Soumettre le rapport final'
  },
  {
    value: 7,
    label: 'En attente d\'acceptation de validation rapport final',
    action: 'En attente d\'acceptation de validation rapport final'
  },
  {
    value: 8,
    label: 'Rapport final rédigé',
    action: 'Valider le rapport final'
  },
  {
    value: 9,
    label: 'Rapport final validé',
    action: 'Rapport Déposé'
  },
  {
    value: 10,
    label: 'Rapport final déposé',
    action: 'Payé'
  },
  {
    value: 11,
    label: 'Payé',
    action: 'Cloturé'
  },
  {
    value: 12,
    label: 'Clôturé',
    action: 'Cloturé'
  },
  {
    value: 13,
    label: 'Annulé',
    action: 'Annulé le dossier'
  }
]

export const ROLE_TYPE_OPTIONS: Array<Option<string>> = [
  {
    value: 'Conseil',
    label: 'Conseil'
  },
  {
    value: 'Avocat',
    label: 'Avocat'
  },
  {
    value: 'Notaire',
    label: 'Notaire'
  },
  {
    value: 'Gestionnaire',
    label: 'Gestionnaire'
  },
  {
    value: 'Administrateur',
    label: 'Administrateur'
  },
  {
    value: 'Enseigne',
    label: 'Enseigne'
  },
  {
    value: 'Asset',
    label: 'Asset'
  },
  {
    value: 'Propriétaire',
    label: 'Propriétaire'
  },
  {
    value: 'Locataire',
    label: 'Locataire'
  },
  {
    value: 'Autre (ou saisir directement un texte libre)',
    label: 'Autre (ou saisir directement un texte libre)'
  }
]

export const INDRES: Array<Option<string>> = [
  {
    value: 'BIS',
    label: 'BIS'
  },
  {
    value: 'TER',
    label: 'TER'
  },
  {
    value: 'QUATER',
    label: 'QUATER'
  },
  {
    value: 'QUIRQUIER',
    label: 'QUIRQUIER'
  }
]

export const MISSION_EXPERT_OPTIONS: Array<Option<string>> = [
  {
    value: 'Valeur locative de renouvellement',
    label: 'Valeur locative de renouvellement'
  },
  {
    value: 'Valeur locative de marché',
    label: 'Valeur locative de marché'
  },
  {
    value: 'Valeur locative (marché + renouvellement)',
    label: 'Valeur locative (marché + renouvellement)'
  },
  {
    value: 'Valeur locative',
    label: 'Valeur locative'
  },
  {
    value: 'Analyse des motifs de déplafonnement',
    label: 'Analyse des motifs de déplafonnement'
  },
  {
    value: 'Valeur vénale',
    label: 'Valeur vénale'
  },
  {
    value: 'Valeur vénale des murs',
    label: 'Valeur vénale des murs'
  },
  {
    value: 'Valeur vénale des parts',
    label: 'Valeur vénale des parts'
  },
  {
    value: 'Valeur du fonds de commerce',
    label: 'Valeur du fonds de commerce'
  },
  {
    value: 'Valeur du droit au bail',
    label: 'Valeur du droit au bail'
  },
  {
    value: "Ind. d'éviction",
    label: 'Indemnité d’éviction'
  },
  {
    value: "Ind. d'éviction + Ind. d'occupation",
    label: "Indemnité d’éviction + Indemnité d'occupation"
  },
  {
    value: "Ind. d'expropriation",
    label: "Indemnité d'expropriation"
  },
  {
    value: 'Ind. de résiliation anticipée',
    label: 'Indemnité de résiliation anticipée'
  },
  {
    value: 'Estimation préjudice',
    label: 'Estimation préjudice'
  },
  {
    value: 'Recherche références',
    label: 'Recherche références'
  },
  {
    value: 'Bilan Promoteur',
    label: 'Bilan promoteur'
  },
  {
    value: 'Ventilation Terrain/Construction',
    label: 'Ventilation terrain/construction'
  },
  {
    value: 'Droits résiduels',
    label: 'Droits résiduels'
  },
  {
    value: 'Autre (ou saisir directement un texte libre)',
    label: 'Autre (ou saisir directement un texte libre)'
  }
]
export const MISSION_LOCAL_OPTIONS: Array<Option<string>> = [
  {
    value: "Local commercial - Pied d'immeuble",
    label: "Local commercial - Pied d'immeuble"
  },
  {
    value: 'Hôtel de tourisme',
    label: 'Hôtel de tourisme'
  },
  {
    value: 'Local commercial - Centre commercial',
    label: 'Local commercial - Centre commercial'
  },
  {
    value: 'Grande surface',
    label: 'Grande surface'
  },
  {
    value: 'Appartement',
    label: 'Appartement'
  },
  {
    value: 'Appartement commercial / Atelier',
    label: 'Appartement commercial / Atelier'
  },
  {
    value: 'Immeuble entier',
    label: 'Immeuble entier'
  },
  {
    value: 'Propriété / Demeure / Château',
    label: 'Propriété / Demeure / Château'
  },
  {
    value: "Local d'activité / Stockage",
    label: "Local d'activité / Stockage"
  },
  {
    value: 'Ecole',
    label: 'Ecole'
  },
  {
    value: 'Salle de sport',
    label: 'Salle de sport'
  },
  {
    value: 'Showroom / Salle de réception',
    label: 'Showroom / Salle de réception'
  },
  {
    value: 'Bureau',
    label: 'Bureau'
  },
  {
    value: 'Hôtel de préfecture',
    label: 'Hôtel de préfecture'
  },
  {
    value: 'Résidence de tourisme',
    label: 'Résidence de tourisme'
  },
  {
    value: 'Auberge de jeunesse',
    label: 'Auberge de jeunesse'
  },
  {
    value: 'Résidence etudiante',
    label: 'Résidence étudiante'
  },
  {
    value: 'EHPAD',
    label: 'EHPAD'
  },
  {
    value: 'Clinique MCO',
    label: 'Clinique MCO'
  },
  {
    value: 'Clinique SSR',
    label: 'Clinique SSR'
  },
  {
    value: 'Clinique (MCO et SSR)',
    label: 'Clinique (MCO et SSR)'
  },
  {
    value: 'Cinéma',
    label: 'Cinéma'
  },
  {
    value: 'Théâtre',
    label: 'Théâtre'
  },
  {
    value: 'Garage / Parking',
    label: 'Garage / Parking'
  },
  {
    value: 'Garage avec atelier',
    label: 'Garage avec atelier'
  },
  {
    value: 'Garage hôtel',
    label: 'Garage hôtel'
  },
  {
    value: 'Loge / Chambre de service',
    label: 'Loge / Chambre de service'
  },
  {
    value: 'Cave / Cour',
    label: 'Cave / Cour'
  },
  {
    value: 'Terrain nu',
    label: 'Terrain nu'
  },
  {
    value: 'Terrain avec constructions',
    label: 'Terrain avec constructions'
  },
  {
    value: 'Résidence hôtelière',
    label: 'Résidence hôtelière'
  },
  {
    value: 'Autre (ou saisir directement un texte libre)',
    label: 'Autre (ou saisir directement un texte libre)'
  }
]
export const MISSION_TYPES: Array<Option<string>> = [
  {
    value: 'Expertise',
    label: 'Expertise'
  },
  {
    value: 'Formation',
    label: 'Formation'
  },
  {
    value: 'Arbitrage',
    label: 'Arbitrage'
  },
  {
    value: 'Conseil',
    label: 'Conseil'
  }
]
export const MISSION_DATE_VALUES: Array<Option<string>> = [
  {
    value: "à la date de réalisation de l'étude (par défaut)",
    label: "A la date de réalisation de l'étude (par défaut)"
  },
  {
    value: 'Date de rendu de la mission',
    label: 'Date de rendu de la mission'
  },
  {
    value: 'Autre (ou saisir directement un texte libre)',
    label: 'Autre (ou saisir directement un texte libre)'
  }
]
export const VISIT_OPTIONS: Array<Option<string>> = [
  {
    value: 'Une visite sera à programmer avec l’expert en charge du dossier. Préciser les coordonnées du contact.',
    label: 'Une visite sera à programmer avec l’expert en charge du dossier. Préciser les coordonnées du contact.'
  },
  {
    value: "Aucune visite ne sera réalisée, il s'agit d'une mission sur dossier",
    label: "Aucune visite ne sera réalisée, il s'agit d'une mission sur dossier"
  },
  {
    value: 'La visite a déjà été effectuée',
    label: 'La visite a déjà été effectuée'
  },
  {
    value: 'Autre (ou saisir directement un texte libre)',
    label: 'Autre (ou saisir directement un texte libre)'
  }
]
export const DELIVER_OPTIONS: Array<Option<string>> = [
  {
    value: 'Rapport complet',
    label: 'Rapport complet'
  },
  {
    value: 'Avis de valeur',
    label: 'Avis de valeur'
  },
  {
    value: 'Lettre Avis',
    label: 'Lettre avis'
  },
  {
    value: 'Note',
    label: 'Note'
  },
  {
    value: 'Tableau de synthèse',
    label: 'Tableau de synthèse'
  },
  {
    value: 'Journée de formation',
    label: 'Journée de formation'
  },
  {
    value: 'Assistance',
    label: 'Assistance'
  },
  {
    value: 'Conseil',
    label: 'Conseil'
  },
  {
    value: 'Autre (ou saisir directement un texte libre)',
    label: 'Autre (ou saisir directement un texte libre)'
  }
]
export const FRAIS_OPTIONS: Array<Option<string>> = [
  {
    value: 'Voir §7 des conditions générales',
    label: 'Voir §7 des conditions générales'
  },
  {
    value: 'Autre (ou saisir directement un texte libre)',
    label: 'Autre (ou saisir directement un texte libre)'
  },
  {
    value: '',
    label: ''
  }
]
export const DELIVER_DATE_OPTIONS: Array<Option<string>> = [
  {
    value: '{30 jours ouvrés}, après acceptation de la mission et réception des pièces demandées.',
    label: '{30 jours ouvrés}, après acceptation de la mission et réception des pièces demandées.'
  },
  {
    value: '{30 jours ouvrés} après la visite.',
    label: '{30 jours ouvrés} après la visite.'
  },
  {
    value: "Au plus tard le {aujourd'hui + délai jours ouvrés}.",
    label: "Au plus tard le {aujourd'hui + délai jours ouvrés}."
  },
  {
    value: 'Autre (ou saisir directement un texte libre)',
    label: 'Autre (ou saisir directement un texte libre)'
  }
]
export const REQUESTED_FILES: any[] = [
  {
    title: 'Valeur locative',
    content: [
      'Bail en cours (et avenants éventuels)',
      "Résiliation des baux, dénonciations reçues ou prévues, s'il y a",
      'Quittances de loyers (les 4 dernières)',
      "Etat des charges locatives de l'immeuble récupérables et non récupérables par le bailleur (Assurances immeuble, Frais de gestion… )",
      'Etat des taxes (Taxe foncière…)',
      'Convention de sous-location'
    ],
    type: 'all',
    valid: [
      'Valeur locative de renouvellement',
      'Valeur locative de marché',
      'Valeur locative (marché + renouvellement)',
      'Valeur locative'
    ]
  },
  {
    title: 'Valeur vénale',
    content: [
      'Acte (ou Attestation) de propriété avec désignation complète et servitudes privées existantes',
      "Règlement de copropriété, s'il y a",
      'Immeuble en bloc : état locatif, à jour (sous forme de tableau excel)'
    ],
    type: 'expert',
    valid: [
      'Valeur vénale',
      'Valeur vénale des murs',
      'Valeur vénale des parts',
      'Bilan Promoteur',
      'Ventilation Terrain/Construction',
      'Droits résiduels',
      'Estimation préjudice'
    ]
  },
  {
    title: 'Droit au bail / Fonds de commerces',
    content: [
      "Chiffres d'affaires HT détaillé, pour les 3 derniers exercices",
      'DSN annuelle adressée à la fin de l’exercice comptable (ex. DADS 1), si personnel'
    ],
    type: 'expert',
    valid: [
      'Valeur du droit au bail',
      'Valeur du fonds de commerce'
      /* "Ind. d'éviction",
            "Ind. d'éviction + Ind. d'occupation" */
    ]
  },
  {
    title: "Indemnite d'éviction",
    content: [
      'Extrait K-bis (à jour)',
      "Chiffre d'affaires HT détaillé, pour les 3 derniers exercices",
      'Comptes de résultats des 3 derniers exercices avec S.I.G. et ventilation du C.A.',
      'DSN annuelle adressée à la fin de l’exercice comptable (ex. DADS 1), si personnel',
      "Descriptifs de l'activité (dont capacité standard de production et de stockage) et du marché",
      'Liste des concurrents directs potentiels',
      'Descriptif, liste des installations générales et techniques',
      'Budget estimatif du déménagement (devis, …)',
      'Budget estimatif de ré-installation (devis, …)'
    ],
    type: 'expert',
    valid: [
      "Ind. d'éviction",
      'Ind. de résiliation anticipée',
      "Ind. d'éviction + Ind. d'occupation",
      "Ind. d'expropriation"
    ]
  },
  {
    title: "Indemnite d'expropriation",
    content: [
      'Acte (ou Attestation) de propriété avec désignation complète et servitudes privées existantes',
      "Chiffre d'affaires HT détaillé, pour les 3 derniers exercices",
      "Ordonnance d'expropriation, s'il y a",
      "Echanges intervenus avec l'autorité expropriante, s'il y a"
    ],
    type: 'expert',
    valid: [
      "Ind. d'expropriation"
    ]
  },
  {
    title: 'Activité spécifique - Hôtel',
    content: [
      'Tarifs affichés, à la date de référence',
      'Récépissé de déclaration (ex carton jaune)',
      'Comptes d’exploitation (des 3 dernières années)',
      'P.M.C. (Prix Moyen par chambre), et par type de chambres',
      "T.O. (Taux d'occupation)",
      'RevPAR (Revenu par chambre disponible)',
      'PV de la Commission de sécurité de la préfecture de police de Paris',
      'PV de la Commission communale de sécurité'
    ],
    type: 'local',
    valid: [
      'Hôtel de tourisme',
      'Hôtel de préfecture',
      'Résidence hôtelière',
      'Auberge de jeunesse',
      'Résidence de tourisme'
    ]
  },
  {
    title: 'Activité spécifique - EHPAD',
    content: [
      'Comptes d’exploitation de l’établissement, pour les 3 dernières années (avec C.A. détaillé)',
      'Historique du taux d’occupation / du prix moyen à la chambre / Du GIR et GMP, sur les 3 dernières années',
      'Tarifs affichés à la date du jour',
      'Age moyen des résidents',
      'Nombre de lit, par catégorie',
      "Règlement de copropriété, s'il y a",
      'PV de la commission de sécurité de la préfecture de police de Paris',
      'PV de la commission communale de sécurité'
    ],
    type: 'local',
    valid: ['EHPAD']
  },
  {
    title: 'Activité spécifique - Clinique',
    content: [
      "Descriptif de l'activité",
      "Barème/Tarification des soins en 2018 + hébergement, s'il y a",
      "Tarif des hébergements affichés en 2018, s'il y a",
      "Historique du taux d'occupation, du prix moyen à la chambre",
      'Comptes d’exploitation de l’établissement, pour les 3 dernières années (Avec C.A. détaillé)',
      "Règlement de copropriété, s'il y a",
      'PV de la commission de sécurité de la préfecture de police de Paris',
      'PV de la commission communale de sécurité'
    ],
    type: 'local',
    valid: [
      'Clinique MCO',
      'Clinique SSR',
      'Clinique (MCO et SSR)'
    ]
  },
  {
    title: 'Activité spécifique - Théâtre',
    content: [
      'Tarifs affichés, à ce jour',
      'Ventilation du nombre de places, par catégorie, par salle',
      'Comptes annuels détaillés, sur les 3 derniers exercices',
      'C.A.HT détaillé (billeterie, autres recettes), pour les 3 derniers exercices'
    ],
    type: 'local',
    valid: [
      'Cinéma',
      'Théâtre'
    ]
  },
  {
    title: 'Plans / Surfaces',
    content: [
      "Plan de masse de l'immeuble, à échelle",
      'Plan(s) détaillé(s) des locaux, à échelle',
      'Tableau récapitulatif des surfaces (par bâtiment, par niveau et par usage)',
      'Diagnostics obligatoires'
    ],
    type: 'all',
    valid: []
  },
  {
    title: 'Travaux',
    content: [
      'Liste des travaux dernièrement réalisés (budget détaillé : gros Travaux / entretien courant / montants)',
      'Liste des travaux prévisionnels (budget détaillé : gros travaux / entretien courant / montants)',
      'Budget des travaux réglementaires à effectuer (incendie, P.M.R.…)',
      'Travaux réalisés au cours du bail expiré et notifiés conformément aux dispositions de l’article L.311-2 du code du tourisme',
      'Budget prévisionnel des gros travaux et des mises aux normes (PEM détaillé)',
      'Liste des investissements à réaliser, sur un horizon de 10 ans'
    ],
    type: 'all',
    valid: []
  }
]

export const PAYMENT_REFERENCES: Array<Option<string>> = [
  {
    value: 'Chèque',
    label: 'Chèque'
  },
  {
    value: 'Virement',
    label: 'Virement'
  },
  {
    value: 'Espèce',
    label: 'Espèce'
  },
  {
    value: 'Carte',
    label: 'Carte'
  }
]

export const TYPE_INVOICES: Array<Option<string>> = [
  {
    value: 'billing_avoirs',
    label: 'Avoirs'
  },
  {
    value: 'billing_deposit_invoices',
    label: 'Factures d’acompte'
  },
  {
    value: 'billing_invoices',
    label: 'Factures'
  }
]

export const TYPES_DEBOURS: Array<Option<string>> = [
  {
    value: 'billing_debours',
    label: 'Débours'
  },
  {
    value: 'billing_complements',
    label: 'Compléments'
  },
  {
    value: 'billing_consignation_payments',
    label: 'Consignation'
  },
  {
    value: 'billing_received_payments',
    label: 'Paiements'
  },
  {
    value: 'billing_remises',
    label: 'Remises'
  },
  {
    value: 'billing_retrocession',
    label: 'Rétrocessions'
  }
]

export const LOCAL_TYPE = [
  {
    value: 1,
    label: 'Boutique en pied d\'immeuble'
  },
  {
    value: 2,
    label: 'Boutique sur cour'
  },
  {
    value: 3,
    label: 'Boutique sur domaine public'
  },
  {
    value: 4,
    label: 'Boutique en gare'
  },
  {
    value: 5,
    label: 'Boutique en aéroport'
  },
  {
    value: 6,
    label: 'Boutique en retail park'
  },
  {
    value: 7,
    label: 'Boutique en centre commercial'
  },
  {
    value: 8,
    label: 'Boutique  en galerie marchande'
  },
  {
    value: 9,
    label: 'Hôtel de tourisme'
  },
  {
    value: 10,
    label: 'Hôtel de Préfecture'
  },
  {
    value: 11,
    label: 'Résidence hôtelière'
  },
  {
    value: 12,
    label: 'Auberge de Jeunesse'
  },
  {
    value: 13,
    label: 'Résidence étudiante'
  },
  {
    value: 14,
    label: 'Résidence senior'
  },
  {
    value: 15,
    label: 'Immeuble en bloc'
  },
  {
    value: 16,
    label: 'Propriété'
  },
  {
    value: 17,
    label: 'Loge ou Chambre de Service'
  },
  {
    value: 18,
    label: 'Hôtel particulier'
  },
  {
    value: 19,
    label: 'Appartement'
  },
  {
    value: 20,
    label: 'Bureaux'
  },
  {
    value: 21,
    label: 'Local d\'activité et/ou de stockage'
  },
  {
    value: 22,
    label: 'EHPAD'
  },
  {
    value: 23,
    label: 'Clinique MCO'
  },
  {
    value: 24,
    label: 'Clinique SSR'
  },
  {
    value: 25,
    label: 'Clinique (MCO et SSR)'
  },
  {
    value: 26,
    label: 'Salle de réception'
  },
  {
    value: 27,
    label: 'Showroom'
  },
  {
    value: 28,
    label: 'Cinéma'
  },
  {
    value: 29,
    label: 'Théâtre'
  },
  {
    value: 30,
    label: 'Établissement d\'enseignement'
  },
  {
    value: 31,
    label: 'Salle de sport'
  },
  {
    value: 32,
    label: 'Appartement commercial'
  },
  {
    value: 33,
    label: 'Parking/ garage hôtel'
  },
  {
    value: 34,
    label: 'Garage avec atelier'
  },
  {
    value: 35,
    label: 'Terrain nu'
  },
  {
    value: 36,
    label: 'Terrain avec constructions'
  },
  {
    value: 37,
    label: 'Portefeuille'
  },
  {
    value: 38,
    label: 'Autre (texte libre)'
  }
]

export const COURTHOUSE_BILLING: string = 'billing_courthouse_select'
