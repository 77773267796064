
import { Component, Prop, Vue } from 'vue-property-decorator'
import { reportService, Report } from '@/services/report.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import { settledFolderService } from '@/services/settledFolder.service'
import ReportExperts from '../report/ReportExperts.vue'
import ReportAnalysis from '../report/ReportAnalysis.vue'
import QuoteFilesRequested from '@/components/quote/QuoteFilesRequested.vue'
    // The @Component decorator indicates the class is a Vue component
    @Component({
      subscriptions (this: any) {
        return {
          folder$: settledFolderService.onChangeFolder,
          report$: reportService.onChangeReport,
          user$: userService.onChange
        }
      },
      components: {
        ReportExperts,
        ReportAnalysis,
        QuoteFilesRequested
      }
    })

export default class SettledFolderRepport extends Vue {
        @Prop({ default: false }) disabled: boolean

        report$: Report = new Report()
        user$: User = new User()
        downloading: boolean = false

        get readable (): boolean {
          return userService.hasRight(this.user$, USER_RIGHTS.RAPPORT_SECTION_READ)
        }

        get updateable (): boolean {
          return userService.hasRight(this.user$, USER_RIGHTS.RAPPORT_SECTION_UPDATE) && !this.disabled
        }

        generateDocument () {
          if (!this.downloading) {
            this.validate().then(
              (res: boolean) => {
                if (res) {
                  this.downloading = true
                  settledFolderService.saveFolder().then(() => {
                    return settledFolderService.generateDocument().then((res) => {
                      this.downloading = false
                      return res
                    }).catch(error => {
                      this.$message({
                        message: "Erreur lors de la génération du rapport. Veuillez contacter le service d'administration.",
                        type: 'error',
                        offset: 65
                      })
                      this.downloading = false
                      throw error
                    })
                  })
                } else {
                  this.$message({
                    message: 'Veuillez sélectionner un rédacteur et un relecteur',
                    type: 'error',
                    offset: 65
                  })
                }
              }
            )
          }
        }

        validate (): Promise<boolean> {
          return (this.$refs.reportExperts as any).validate()
        }

        submitReport () {
          this.$emit('submit')
        }

        validateReport () {
          this.$emit('validate')
        }

        refuseReport () {
          this.$emit('refuse')
        }
}
