
import _ from 'lodash'
import { Component, Vue } from 'vue-property-decorator'
import { tap } from 'rxjs/operators'
import { dashboardService, SummaryData } from '@/services/dashboard.service'
import 'chart.js'
import 'chartjs-plugin-labels'
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions (this: any) {
    return {
      summaryData$: dashboardService.onChangeSummaryData,
      listReferences$: dashboardService.onChangeListReferences.pipe(tap((res: any) => {
        if (res.length > 0) {
          this.getDataByLocalTypeMainCategories(res)
          this.getChartData()
        }
      }))
    }
  },
  methods: {}
})
export default class DashboardRefAndGEDBarChart extends Vue {
  summaryData$: SummaryData
  chartOptions: any = {}
  resultsByLocalTypeMainCategoriesCYear : any = {}
  resultsByLocalTypeMainCategoriesPYear : any = {}
  listReferences$: any[] = []
  chartInfos: any[] = []
  chartData: any = {}

  mounted () {
    this.chartOptions = {
      responsive: true,
      legend: {
        display: true
      },
      animation: {
        animateScale: true,
        animateRotate: true
      },
      plugins: {
        labels: false
      },
      layout: {
        padding: {
          top: 25
        }
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (context: any) {
            let label = ''
            if (context.value !== null) {
              label += context.value
            }
            return label
          }
        }
      },
      hover: { mode: null },
      scales: {
        yAxes: [{
          id: 'leftY',
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            callback (value: number) {
              const options = [
                { max: 10, val: 1000000000, sym: 'B' },
                { max: 7, val: 1000000, sym: 'M' },
                { max: 4, val: 1000, sym: 'K' }
              ]
              const len = Math.abs(value).toString().length
              const option = options.find(opt => opt.max <= len)
              if (option) {
                return (value / option.val).toFixed(0).concat(option.sym)
              }
              return value
            }
          }
        }]
      }
    }
  }

  getChartData (): any {
    if (this.resultsByLocalTypeMainCategoriesCYear.length === 0) return {}
    this.chartData = {
      datasets: [
        {
          label: 'Ref et GED créés année N',
          data: this.chartInfos.map((item: string) => _.round(this.resultsByLocalTypeMainCategoriesCYear[item], 2)),
          fill: false,
          backgroundColor: 'maroon',
          yAxisID: 'leftY',
          borderWidth: 1
        },
        {
          label: 'Ref et GED créés année N-1',
          data: this.chartInfos.map((item: string) => _.round(this.resultsByLocalTypeMainCategoriesPYear[item], 2)),
          fill: false,
          backgroundColor: 'lightgrey',
          yAxisID: 'leftY',
          borderWidth: 1
        }
      ],
      labels: this.chartInfos
    }
  }

  getDataByLocalTypeMainCategories (listReferences : any[]) {
    if (listReferences.length > 0) {
      this.chartInfos = listReferences.map((element: any) => element.label)
      this.chartInfos.push('GED')
      // prepare our results object : all main categories set to 0
      for (const elem of listReferences) {
        this.resultsByLocalTypeMainCategoriesCYear[elem.label] = 0
        this.resultsByLocalTypeMainCategoriesPYear[elem.label] = 0
      }

      // for each references grouped by local type created this current year we verify in which main category it belongs and add the associated number to the counter
      this.getNumberOfRefAndGedCreatedByMainCategories(this.summaryData$.createdRefAndGedPreviousYear, this.resultsByLocalTypeMainCategoriesPYear, listReferences)
      // same thing but for the previous year
      this.getNumberOfRefAndGedCreatedByMainCategories(this.summaryData$.createdRefAndGedCurrentYear, this.resultsByLocalTypeMainCategoriesCYear, listReferences)
    }
  }

  getNumberOfRefAndGedCreatedByMainCategories (dataOfcreatedRefAndGedByYear: any, resultObjectByYear: any, listReferences : any[]): void {
    dataOfcreatedRefAndGedByYear.data.references.forEach((element: any) => {
      for (const ref of listReferences) {
        if (ref.options.map((option: any) => option.id).includes(parseInt(element.type_local))) {
          resultObjectByYear[ref.label] += parseInt(element.nb_references_created)
          break
        }
        resultObjectByYear.GED = parseInt(dataOfcreatedRefAndGedByYear.data.geds[0].nb_created_geds)
      }
    })
  }
}
