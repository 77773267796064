
import Step1 from '@/components/modal/stepDownload/Step1.vue'
import Step2 from '@/components/modal/stepDownload/Step2.vue'
import Step3 from '@/components/modal/stepDownload/Step3.vue'
import Step4 from '@/components/modal/stepDownload/Step4.vue'
import Step5 from '@/components/modal/stepDownload/Step5.vue'
import Step6 from '@/components/modal/stepDownload/Step6.vue'
export default {
  components: { Step2, Step1, Step3, Step4, Step5, Step6 },
  data () {
    return {
      isModalVisible: false,
      currentStep: null,
      stepsList: ['Step1', 'Step2', 'Step3', 'Step4', 'Step5', 'Step6'],
      formInline: {
        input: ''
      },
      options: [{
        value: 'Adresse',
        label: 'Adresse'
      }, {
        value: 'Ref',
        label: 'Réf.'
      }, {
        value: 'Document',
        label: 'Document'
      }, {
        value: 'Devis',
        label: 'Devis'
      }, {
        value: 'Contact',
        label: 'Contact'
      }],
      value: '',
      checked: '',
      input: ''
    }
  },
  computed: {
    isLastStep (): any {
      return (this as any).currentStep === (this as any).stepsList.length - 1
    }
  },
  created () {
    (this as any).currentStep = 0
  },
  methods: {
    open () {
      (this as any).isModalVisible = true
    },
    toNext () {
      if ((this as any).isLastStep) return;
      (this as any).currentStep++
    },
    toRestart (): any {
      (this as any).currentStep = 0;
      (this as any).isModalVisible = false
    }
  }
}
