import { Contact } from '@/services/contact.service'
import { ContactAddress } from '@/services/contactAddress.service'
import { Report } from '@/services/report.service'
import { AppFile, CategoryTree } from '@/services/file.service'
import { FolderInterface } from '@/models/FolderInterface'

export const MODIFICATION_KEYS: any[] = [
  {
    key: 'displacement',
    title: 'Temps de déplacement'
  }, {
    key: 'place',
    title: 'Visite des lieux'
  }, {
    key: 'dossier',
    title: 'Étude du dossier et des pièces'
  }, {
    key: 'research',
    title: 'Recherches'
  }, {
    key: 'cabinet',
    title: 'Réunion de cabinet'
  }, {
    key: 'redaction',
    title: 'Rédaction'
  }, {
    key: 'response',
    title: 'Dires et réponses aux dires'
  }
]

export const DEFAULT_LEGAL_FOLDER_NAME = 'Dossier judiciaire'

export const COST_KEYS: any[] = [
  {
    key: 'displacement',
    title: 'Frais de déplacement (forfait)'
  }, {
    key: 'secretariat',
    title: 'Frais de secrétariat'
  }, {
    key: 'correspondance',
    title: 'Frais de dactylographie et de correspondance (nombre de pages du rapport + nombre de courriers)'
  }, {
    key: 'reprographie',
    title: 'Frais de reprographie (nombre d’exemplaires X nombre de pages)'
  }, {
    key: 'telephone',
    title: 'Frais d\'expédition et de téléphone'
  }, {
    key: 'remuneration',
    title: 'Rémunération du sapiteur'
  }, {
    key: 'accommodation',
    title: 'Frais de séjour et d\'hébergement'
  }, {
    key: 'other',
    title: 'Autres frais (à préciser)'
  }
]

export class CourrierType {
  id: number
  type: string
  isCourthouse: boolean
  constructor (data: any = {}) {
    this.id = data.id || null
    this.type = data.type || ''
    this.isCourthouse = data.isCourthouse || false
  }
}

export class Courthouse {
  id: number
  name: string
  address: string
  constructor (courthouse: any = {}) {
    this.id = courthouse.id || null
    this.name = courthouse.name || ''
    this.address = courthouse.address || ''
  }
}

export class MotifDossier {
  id: number
  title: string
  constructor (motifDossier: any = {}) {
    this.id = motifDossier.id || null
    this.title = motifDossier.title || ''
  }
}

export class NatureDecision {
  id: number
  title: string
  constructor (natureDecision: any = {}) {
    this.id = natureDecision.id || null
    this.title = natureDecision.title || ''
  }
}

export class CourrierContact {
  id: number
  contact: Contact
  name: string
  contactAddress: ContactAddress | null
  email: string
  interlocuteur: boolean
  mandator: boolean
  type: string
  constructor (data: any = {}) {
    this.id = data.id || null
    this.contact = new Contact(data.contact)
    this.name = data.contact ? `${String(data.contact.nom)} ${String(data.contact.prenom)}` : ''
    this.contactAddress = data.contactAddress ? new ContactAddress(data.contactAddress) : null
    this.email = data.email || ''
    this.interlocuteur = data.interlocuteur || false
    this.mandator = data.mandator || false
    this.type = data.type || ''
  }
}

export class Courrier {
  id: number
  courrierType: CourrierType | null
  date: Date
  contact1: CourrierContact | null
  contact1Address: ContactAddress | null
  contact2: CourrierContact | null
  contactTypeId: string | null
  courrierTypeId: string | null
  contact1Id: string | null
  contact1AddressId: string | null
  contact2Id: string | null
  courthouse: boolean
  constructor (data: any = {}) {
    this.id = data.id || null
    this.courrierType = data.courrierType ? data.courrierType : null
    this.date = data.date || new Date()
    this.contact1 = data.contact1 ? data.contact1 : null
    this.contact1Address = data.contact1Address ? data.contact1Address : null
    this.contact2 = data.contact2 ? data.contact2 : null
    this.courthouse = data.courthouse || false
    this.courrierTypeId = data.courrierType ? data.courrierType.id : null
    this.contact1Id = data.contact1 ? data.contact1.id : null
    this.contact1AddressId = data.contact1Address ? data.contact1Address.id : null
    this.contact2Id = data.contact2 ? data.contact2.id : null
  }
}
export class LegalFolder implements FolderInterface {
  id: number
  name: string
  type: string
  status: number
  userId: number
  folderId: number
  courthouse: Courthouse | null
  chamber: string
  natureOfDecision: number
  dateOfDecision: Date
  sectionNumber: string
  portablisNumber: string
  miNumber: string
  dateOfFinalDeposit: Date
  endFolderDate: number
  endDate: Date
  receiptionDate: Date
  sendingDate: Date
  meetingDateSite: Date
  meetingHour: string
  dateOfPotentialEnd: string
  realDateOfDeposit: Date
  limitDateOfReception: Date
  dateOfSimulation: Date
  meetingDateOffice: string
  meetingHourOffice: string
  dateOfFinalReportDeposit: Date
  dateOfTaxReception: string
  dateOfFinalDepositForUsers: Date
  paymentDate: Date
  dateOfPaymentForUser: Date
  dateOfUserWarning: Date
  freeNote: string
  modification: Modification
  costs: Costs
  courriers: Courrier[]
  report: Report
  files: AppFile[]
  submittedFile: AppFile
  filesRequested: any
  contacts: any[]
  addresses: any[]
  addressComment: string
  missions: any[]
  expertiseMission: any
  billing: any
  searches: any
  conflit: any[]
  createdAt: Date
  categories: CategoryTree[]
  estimateDays: number | null
  generalComment: string | null
  conflictReason: string | null
  acceptDate: string | null
  statusHistory: any[]
  constructor (legalFolder: any = {}) {
    this.id = legalFolder.id || null
    this.folderId = legalFolder.folderId || null
    this.name = (legalFolder && legalFolder.name) || DEFAULT_LEGAL_FOLDER_NAME
    this.status = (legalFolder && legalFolder.status) || 0
    this.userId = legalFolder.userId || null
    this.courthouse = legalFolder.courthouse ? new Courthouse(legalFolder.courthouse) : null
    this.chamber = legalFolder.chamber || ''
    this.natureOfDecision = legalFolder.natureOfDecision || null
    this.dateOfDecision = legalFolder.dateOfDecision || null
    this.sectionNumber = legalFolder.sectionNumber || ''
    this.portablisNumber = legalFolder.portablisNumber || ''
    this.miNumber = legalFolder.miNumber || ''
    this.dateOfFinalDeposit = legalFolder.dateOfFinalDeposit || null
    this.endFolderDate = legalFolder.endFolderDate || null
    this.endDate = legalFolder.endDate || null
    this.receiptionDate = legalFolder.receiptionDate || null
    this.sendingDate = legalFolder.sendingDate || null
    this.meetingDateSite = legalFolder.meetingDateSite || null
    this.meetingHour = legalFolder.meetingHour || ''
    this.meetingHourOffice = legalFolder.meetingHourOffice || ''
    this.dateOfPotentialEnd = legalFolder.dateOfPotentialEnd || null
    this.realDateOfDeposit = legalFolder.realDateOfDeposit || null
    this.limitDateOfReception = legalFolder.limitDateOfReception || null
    this.dateOfSimulation = legalFolder.dateOfSimulation || null
    this.meetingDateOffice = legalFolder.meetingDateOffice || ''
    this.meetingHourOffice = legalFolder.meetingHourOffice || ''
    this.dateOfFinalReportDeposit = legalFolder.dateOfFinalReportDeposit || null
    this.dateOfTaxReception = legalFolder.dateOfTaxReception || ''
    this.dateOfFinalDepositForUsers = legalFolder.dateOfFinalDepositForUsers || null
    this.paymentDate = legalFolder.paymentDate || null
    this.dateOfPaymentForUser = legalFolder.dateOfPaymentForUser || null
    this.dateOfUserWarning = legalFolder.dateOfUserWarning || null
    this.freeNote = legalFolder.freeNote || ''
    this.modification = legalFolder.modification
      ? new Modification({
        datas: legalFolder.modification.datas,
        price: legalFolder.modification.price
      })
      : new Modification()
    this.costs = legalFolder.costs
      ? new Costs({
        amount: legalFolder.costs.amount,
        price: legalFolder.costs.price
      }, legalFolder.status)
      : new Costs({}, legalFolder.status)
    this.courriers = legalFolder.courriers && legalFolder.courriers.length > 0
      ? legalFolder.courriers.map((courrier: any) => {
        return new Courrier(courrier)
      })
      : [new Courrier()]
    this.report = legalFolder.report || null
    this.files = legalFolder.files || []
    this.submittedFile = legalFolder.submittedFile || null
    this.filesRequested = legalFolder.filesRequested || null
    this.contacts = legalFolder.contacts || null
    this.addresses = legalFolder.addresses || null
    this.addressComment = legalFolder.addressComment || null
    this.missions = legalFolder.missions || null
    this.expertiseMission = legalFolder.expertiseMission || null
    this.billing = legalFolder.billing || null
    this.searches = legalFolder.searches || null
    this.conflit = (legalFolder && legalFolder.conflit) || null
    this.createdAt = legalFolder.createdAt
    this.categories = (legalFolder && legalFolder.categories) || []
    this.estimateDays = (legalFolder && legalFolder.estimateDays) || null
    this.generalComment = (legalFolder && legalFolder.generalComment) || null
    this.conflictReason = (legalFolder && legalFolder.conflictReason) || null
    this.acceptDate = (legalFolder && legalFolder.acceptDate) || null
    this.statusHistory = (legalFolder && legalFolder.statusHistory) || null
  }
}

export class ModificationData {
  date: Date
  updatedAt: Date
  displacement: number
  place: number
  dossier: number
  research: number
  cabinet: number
  redaction: number
  response: number
  constructor (modificationData: any = {}) {
    this.date = modificationData.date || null
    this.updatedAt = modificationData.updatedAt || null
    this.displacement = modificationData.displacement || null
    this.place = modificationData.place || null
    this.dossier = modificationData.dossier || null
    this.research = modificationData.research || null
    this.cabinet = modificationData.cabinet || null
    this.redaction = modificationData.redaction || null
    this.response = modificationData.response || null
  }
}

export class ModificationPrice {
  displacement: number
  place: number
  dossier: number
  research: number
  cabinet: number
  redaction: number
  response: number
  constructor (modificationPrice: any = {}) {
    this.displacement = (modificationPrice.displacement || 65)
    this.place = (modificationPrice.place || 130)
    this.dossier = (modificationPrice.dossier || 130)
    this.research = (modificationPrice.research || 130)
    this.cabinet = (modificationPrice.cabinet || 130)
    this.redaction = (modificationPrice.redaction || 130)
    this.response = (modificationPrice.response || 130)
  }
}

export class Modification {
  datas: ModificationData[]
  price: ModificationPrice
  constructor (modification: any = {}) {
    this.datas = modification.datas || [new ModificationData(), new ModificationData()]
    this.price = modification.price || new ModificationPrice()
  }

  getSum (key: string): number {
    let sum: number = 0
    this.datas.forEach((data: any) => {
      sum += parseFloat(data[key] || 0)
    })
    return sum
  }

  getPrice (key: string): number {
    const sum = this.getSum(key)
    const price: any = this.price
    return sum * (price[key] || 0)
  }

  getTotalSum (): number {
    let totalSum: number = 0
    MODIFICATION_KEYS.forEach((item: any) => {
      totalSum += this.getSum(item.key)
    })
    return totalSum
  }

  getTotalPrice (): number {
    let totalPrice = 0
    MODIFICATION_KEYS.forEach((item: any) => {
      totalPrice += this.getPrice(item.key)
    })
    return totalPrice
  }
}

export class CostsAmount {
  displacement: number
  secretariat: number
  correspondance: number
  reprographie: number
  telephone: number
  remuneration: number
  accommodation: number
  other: number
  constructor (costsAmount: any = {}, status: number = 0) {
    this.displacement = costsAmount.displacement || (status < 11 ? 1 : null)
    this.secretariat = costsAmount.secretariat || (status < 11 ? 3 : null)
    this.correspondance = costsAmount.correspondance || null
    this.reprographie = costsAmount.reprographie || null
    this.telephone = costsAmount.telephone || null
    this.remuneration = costsAmount.remuneration || null
    this.accommodation = costsAmount.accommodation || null
    this.other = costsAmount.other || null
  }
}

export class CostsPrice {
  displacement: number
  secretariat: number
  correspondance: number
  reprographie: number
  telephone: number
  remuneration: number
  accommodation: number
  other: number
  constructor (costsPrice: any = {}) {
    this.displacement = (costsPrice.displacement || 15)
    this.secretariat = (costsPrice.secretariat || 30)
    this.correspondance = (costsPrice.correspondance || 6.5)
    this.reprographie = (costsPrice.reprographie || 0.15)
    this.telephone = (costsPrice.telephone || null)
    this.remuneration = (costsPrice.remuneration || null)
    this.accommodation = (costsPrice.accommodation || null)
    this.other = (costsPrice.other || null)
  }
}

export class Costs {
  amount: CostsAmount
  price: CostsPrice
  constructor (costs: any = {}, status: number = 0) {
    this.amount = new CostsAmount(costs.amount, status)
    this.price = new CostsPrice(costs.price)
  }

  getPrice (key: string): number {
    const amount: any = this.amount
    const price: any = this.price
    return parseFloat(amount[key] || 0) * (price[key] || 0)
  }

  getTotalPrice (): number {
    let totalPrice = 0
    COST_KEYS.forEach((item: any) => {
      totalPrice += this.getPrice(item.key)
    })
    return totalPrice
  }
}
