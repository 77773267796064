
import { Component, Vue } from 'vue-property-decorator'
import QuoteManagement from '../components/quote/QuoteManagement.vue'
import InformationGenerals from '../components/quote/general/InformationGenerals.vue'
import QuoteBillingManagement from '../components/quote/QuoteBillingManagement.vue'
import SettledFolderDialogs from '../components/settledFolder/SettledFolderDialogs.vue'
import SettledFolderReport from '../components/settledFolder/SettledFolderReport.vue'
import SettledFolderPipeLine from '../components/settledFolder/SettledFolderPipeLine.vue'
import FileManagementList from '../components/quote/file/FileManagementList.vue'
import EmailManagement from '../components/quote/email/EmailManagement.vue'
import BillingState from '../components/billing/BillingState.vue'
import { quoteService, Quote } from '@/services/quote.service'
import { contactService } from '@/services/contact.service'
import { userService, User, USER_RIGHTS } from '@/services/user.service'
import { SETTED_FOLDER_STATUES } from '@/services/constants.service'
import { authService } from '@/services/auth.service'
import { settledFolderService, SettledFolder } from '@/services/settledFolder.service'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import FolderSearches from '@/components/search/FolderSearches.vue'
import ConflitDialog from '@/components/conflit/ConflitDialog.vue'
import { localeDateStr } from '@/utils/functions'
import QuoteDialogs from '../components/quote/QuoteDialogs.vue'
import QuoteHistory from '@/components/quote/QuoteHistory.vue'

@Component({
  subscriptions (this: Vue) {
    return {
      folder$: settledFolderService.onChangeFolder,
      quote$: quoteService.onChangeQuote,
      user$: userService.onChange
    }
  },
  data: () => ({
    activeNames: 'general'
  }),
  components: {
    QuoteHistory,
    ConflitDialog,
    FolderSearches,
    QuoteManagement,
    FileManagementList,
    EmailManagement,
    SettledFolderDialogs,
    QuoteBillingManagement,
    BillingState,
    ClipLoader,
    SettledFolderReport,
    SettledFolderPipeLine,
    InformationGenerals,
    QuoteDialogs
  }
})
export default class QuoteView extends Vue {
  SETTED_FOLDER_STATUES = SETTED_FOLDER_STATUES
  loading: boolean = false
  collapseNumber: number = 1
  quote$: Quote = new Quote()
  user$: User = new User()
  folder$: SettledFolder = new SettledFolder()
  editing: boolean = false
  activeNames: any = null
  showConflict: boolean = false

  localeDateStr: Function = localeDateStr

  created () {
    contactService.getContactNames()
    userService.getUsers()
    settledFolderService.initFolder()
    if (!localStorage.getItem('duplicatedFolder')) {
      if (authService.userValue && authService.userValue.id) {
        this.loading = true
        userService
          .getUserById(authService.userValue.id.toString())
          .then(() => {
            if (this.$route.params.id) {
              settledFolderService
                .getFolderById(this.$route.params.id)
                .then((res: any) => {
                  if (res.folder && res.folder.name) {
                    document.title = res.folder.name
                  }
                  this.loading = false
                  if (
                    res.folder.status === 3 &&
                                        userService.hasRight(this.user$, USER_RIGHTS.VDD)
                  ) {
                    (this.$refs.settledFolderDialogs as any).showAcceptValidationDialog(
                      (result: boolean) => {
                        if (result) {
                          this.changeStatus(4)
                        } else {
                          this.$router.replace('/liste-dossiers')
                        }
                      }
                    )
                  }
                })
                .catch(() => {
                  this.$router.replace('/liste-dossiers')
                  this.loading = false
                })
            } else {
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
          })
      }
    }
  }

  get changeable (): boolean {
    if (this.$route.params.id) {
      return userService.hasRight(this.user$, USER_RIGHTS.UPDATE_STATUS_UPDATE)
    }
    return false
  }

  get updateable (): boolean {
    return (
      userService.hasRight(this.user$, USER_RIGHTS.LM_SECTION_UPDATE) ||
            userService.hasRight(this.user$, USER_RIGHTS.FILE_SECTION_UPDATE) ||
            userService.hasRight(this.user$, USER_RIGHTS.MAIL_SECTION_UPDATE) ||
            userService.hasRight(this.user$, USER_RIGHTS.BILLING_SECTION_UPDATE) ||
            userService.hasRight(this.user$, USER_RIGHTS.RAPPORT_SECTION_UPDATE)
    )
  }

  get folderStatusAction (): string {
    switch (this.folder$.status) {
      case 3:
        return 'Annuler la soumission'
      default:
        return SETTED_FOLDER_STATUES[this.folder$.status].action
    }
  }

  get deletable (): boolean {
    return userService.hasRight(this.user$, USER_RIGHTS.ADM)
  }

  get editableStatus (): boolean {
    return this.folder$.status <= 9
  }

  refuseFolder (): void {
    (this.$refs.settledFolderDialogs as any).showCommentDialog((comment: string) => {
      if (comment) {
        settledFolderService.refuseValidation(comment)
      }
    })
  }

  cancelSubmit (): void {
    settledFolderService.cancelSubmit()
  }

  changeStatus (status: number): void {
    if (this.changeable) {
      if (this.folder$.status === 9 || status === 9) {
        if (!userService.hasRight(this.user$, USER_RIGHTS.CLOSE_FOLDER_UPDATE) || !this.user$.id) {
          this.$message({
            message: "Vous n'avez pas le droit d'effectuer cette action.",
            type: 'error',
            offset: 65
          })
          return
        }
      }
      switch (status) {
        case 0:
          settledFolderService.openFolder().then(() => { settledFolderService.getStatusHistory() })
          break
        case 1: // En attente de visite
          if (!userService.hasRight(this.user$, USER_RIGHTS.FOLDER_ASSIGNATION) || !this.user$.id) {
            this.$message({
              message: "Vous n'avez pas le droit de d'attribuer un dossier.",
              type: 'error',
              offset: 65
            })
          } else {
            settledFolderService.attributeFolder(this.user$.id).then(() => { settledFolderService.getStatusHistory() })
          }
          break
        case 2: // En cours de rédactio
          settledFolderService.visitFolder().then(() => { settledFolderService.getStatusHistory() })
          break
        case 3: // En attente d'acceptation de validation
          (this.$refs.settledFolderDialogs as any).showSelectFileDialog(
            (fileId: string) => {
              if (fileId) {
                this.loading = true
                settledFolderService.submitReport(fileId).then(() => {
                  this.loading = false
                  settledFolderService.getStatusHistory()
                }, () => {
                  this.loading = false
                })
              }
            }
          )
          break
        case 4: // En attente validation rapport
          settledFolderService.acceptValidation().then(() => { settledFolderService.getStatusHistory() })
          break
        case 5: // Repports validés
          (this.$refs.settledFolderDialogs as any).showCommentDialog(
            (comment: string) => {
              if (comment) {
                this.loading = true
                settledFolderService.validateReport(comment).then(() => {
                  this.loading = false
                  settledFolderService.getStatusHistory()
                }, () => {
                  this.loading = false
                })
              }
            }
          )
          break
        case 6: // Email envoyé
          settledFolderService.emailSent().then(() => { settledFolderService.getStatusHistory() })
          break
        case 7: // Facturé
          settledFolderService.invoiceSent().then(() => { settledFolderService.getStatusHistory() })
          break
        case 8: // Payé
          settledFolderService.paymentSent().then(() => { settledFolderService.getStatusHistory() })
          break
        case 9: // Cloturé
          if (this.user$.id) {
            settledFolderService.closeFolder(this.user$.id).then(() => { settledFolderService.getStatusHistory() })
          }
          break
        default:
          // TODO
          break
      }
    }
  }

  saveFolder (): void {
    if (!this.loading) {
      this.loading = true
      settledFolderService.saveFolder().then(
        () => {
          this.loading = false
          this.$message({
            message: 'Le dossier a été sauvegardé avec succès.',
            type: 'success',
            offset: 65
          })
        },
        () => {
          this.loading = false
          this.$message({
            message: 'La sauvegarde a échoué!, Veuillez réessayer plus tard.',
            type: 'error',
            offset: 65
          })
        }
      )
    }
  }

  changeShowConflict (show: boolean) {
    this.showConflict = show
  }

  handleChange (val: number) {
    if (val) {
      this.collapseNumber = val
    }
  }

  duplicateFolder () {
    // not allowed
    if (!this.$route.params.id) {
      return
    }
    quoteService.duplicateQuote().then(() => {
      window.open(this.$router.resolve({ name: 'Quote' }).href, '_blank')
    })
  }

  deleteFolder () {
    if (!this.$route.params.id) {
      return
    }
    (this.$refs.settledFolderDialogs as any).showConfirmDeleteDialog(
      (result: boolean) => {
        if (result) {
          settledFolderService.deleteFolder().then(() => {
            this.$router.replace('/liste-dossiers')
          })
        }
      }
    )
  }

  stopEditing (): void {
    this.editing = false
    if (this.folder$.suffixName) {
      this.folder$.name = this.folder$.prefixName + this.folder$.suffixName.toUpperCase()
    }
  }

  handleAccordion () {
    this.activeNames = ''
  }

  showConflicts () {
    this.showConflict = true
  }

  transformToPdf () {
    (this.$refs.quoteDialogs as any).updateShowSelectFileToTransform(
      (fileId: string) => {
        if (fileId) {
          this.loading = true
          quoteService.generatePdf(fileId).then(() => {
            this.loading = false
            this.$message({
              message: 'PDF généré et ajouté aux pièces jointes avec succès.',
              type: 'success',
              offset: 65
            })
          }, () => {
            this.loading = false
          })
        }
      }
    )
  }
}
