
import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import { tap } from 'rxjs/operators'
import _ from 'lodash'
import { Form } from 'element-ui/types'
import { legalFolderService } from '@/services/legalFolder.service'
import { LegalFolder } from '@/models/legalFolder.model'
import {
  SettledFolder,
  settledFolderService
} from '@/services/settledFolder.service'
import {
  SearchRef,
  saveSearchService,
  FolderListItem
} from '@/services/saveSearch.service'
import { authService } from '@/services/auth.service'
import { User } from '@/services/user.service'

@Component({
  subscriptions (this: Vue) {
    return {
      loading: saveSearchService.onLoading,
      legalFolders: legalFolderService.onChange.pipe(
        tap((res: LegalFolder[]) => {
          _.map(res, function (element: any, index: number) {
            element.folderId = index + 1
            return element.folderId
          })
          saveSearchService.setFolders()
        })
      ),
      settledFolders: settledFolderService.onChangeFolders.pipe(
        tap((res: SettledFolder[]) => {
          _.map(res, function (element: any, index: number) {
            element.folderId = index + 11
            return element.folderId
          })
          saveSearchService.setFolders()
        })
      ),
      folderList: saveSearchService.onChangeFolders,
      saveSearch: saveSearchService.onChange
    }
  },
  props: {
    show: Boolean
  }
})
export default class SaveSearch extends Vue {
  @Ref() readonly saveSearchFrom!: Form
  @Prop({ type: Array })
    searchResult: any

  @Prop({ type: Object })
    searchCriteria: any

  @Prop({ type: Function })
    close: any

  saveSearch: any = {}

  rules: any = {
    title: [
      {
        required: true,
        message: 'Merci de remplir les champs obligatoires',
        trigger: 'blur'
      }
    ],
    folderId: [
      {
        required: false
      }
    ]
  }

  loading: boolean = false
  legalFolders: LegalFolder[] = []
  settledFolders: SettledFolder[] = []
  folderList: FolderListItem[] = []

  created () {
    this.getFolderList()
  }

  async getFolderList () {
    await settledFolderService.getFolders()
    await legalFolderService.getFolders()
  }

  selectFolder (folder: any) {
    let type = 1
    if (folder.folderId > 10) {
      type = 2
    }
    saveSearchService.setSaveForm(type, folder)
  }

  handleAnnuler () {
    this.close()
  }

  handleValider () {
    let user: User
    if (authService.userValue && authService.userValue.id) {
      user = new User(authService.userValue)
    } else {
      return
    }
    this.saveSearchFrom.validate((valid: any) => {
      if (valid) {
        const type = this.saveSearch.selectedFolder ? this.saveSearch.selectedFolder.type : null
        const folder = this.saveSearch.selectedFolder ? this.saveSearch.selectedFolder.folder : null
        const modelData = {
          id: null,
          hide: null,
          tableId: null,
          rating: 0
        }
        this.searchResult.forEach((searchTable: any) => {
          searchTable.data.forEach((data: any, index: number) => {
            searchTable.data[index] = _.pick(data, _.keys(modelData))
          })
        })
        const searchRef = new SearchRef({
          id: this.$route.params.id ? this.$route.params.id : null,
          legalFolder: (type && type === 1) ? folder : null,
          settledFolder: (type && type === 2) ? folder : null,
          title: this.saveSearch.title,
          criteria: this.searchCriteria,
          result: this.searchResult,
          user,
          typeValeur: this.searchCriteria.typeValeur,
          typeLocal: this.searchCriteria.typeLocal,
          activites: this.searchCriteria.activites
        })
        saveSearchService.saveSearch(searchRef).then(
          () => {
            this.$message({
              message: 'La recherche a été enregistrée avec succès.',
              type: 'success',
              offset: 65
            })
            if (!this.$route.params.id) {
              this.$router.replace('/search-ref/' + this.saveSearch.id)
            }
            this.close()
          },
          () => {
            this.$message({
              message: 'Erreur de sauvegarde!, Veuillez réessayer plus tard.',
              type: 'error',
              offset: 65
            })
          }
        )
      }
      return valid
    })
  }

  filterFolders (query: any) {
    if (query != null && query.length > 0) {
      this.saveSearch.folderName = query
      settledFolderService.searchFolders({ name: query }, 0, 10)
      legalFolderService.searchFolders({ name: query }, 0, 10)
    } else {
      this.saveSearch.folderName = ''
      settledFolderService.getFolders()
      legalFolderService.getFolders()
    }
    this.saveSearch.folderId = ''
    this.saveSearch.selectedFolder = {}
  }
}
