
import { Component, Prop, Vue } from 'vue-property-decorator'
import { dossierService, DossierFilter } from '@/services/dossier.service'
import { LEGAL_FOLDER_STATUES, QUOTE_STATUES, SETTED_FOLDER_STATUES } from '@/services/constants.service'
import { FolderInterface } from '@/models/FolderInterface'
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions () {
    return {}
  },
  methods: {}
})

export default class DashboardFolders extends Vue {
  dossiers: any[] = []
  totalDossiers: number = 0
  currentPage: number = 1
  pageSize: number = 30
  serializedFilter: DossierFilter

  @Prop({ default: {} }) filters: any
  @Prop({ default: '' }) title: string

  async created () {
    this.serializedFilter = dossierService.preFilter(this.filters)
    this.serializedFilter.sortProp = 'number_dossier'
    this.serializedFilter.sortOrder = 'descending'
    const dossiers = await dossierService.getDossiers(this.serializedFilter, this.currentPage, this.pageSize)
    this.dossiers = dossiers.data
    this.totalDossiers = dossiers.total
  }

  statusByColor (row: any) {
    if (row.type === 'settled-folder') {
      return row.status < (SETTED_FOLDER_STATUES.length - 1) ? 'text-in-progress' : 'text-close'
    }
    if (row.type === 'legal-folder') {
      return row.status < (LEGAL_FOLDER_STATUES.length - 1) ? 'text-in-progress' : 'text-close'
    }
    return row.status < (QUOTE_STATUES.length - 1) ? 'text-in-progress' : 'text-close'
  }

  async handleSortChange (event: any) {
    switch (event.prop) {
      case 'createdDateLabel':
        this.serializedFilter.sortProp = 'created_at'
        break
      case 'statusLabel':
        this.serializedFilter.sortProp = 'status'
        break
      default:
        this.serializedFilter.sortProp = event.prop
        break
    }
    this.serializedFilter.sortOrder = event.order
    const dossiers = await dossierService.getDossiers(this.serializedFilter, this.currentPage, this.pageSize)
    this.dossiers = dossiers.data
    this.totalDossiers = dossiers.total
  }

  async onChangeCurrent (currentPage: number) {
    this.currentPage = currentPage
    const dossiers = await dossierService.getDossiers(this.serializedFilter, currentPage, this.pageSize)
    this.dossiers = dossiers.data
    this.totalDossiers = dossiers.total
  }

  async onChangeSizePage (pageSize: number) {
    localStorage.setItem('pageSize', `${pageSize}`)
    this.pageSize = pageSize
    const dossiers = await dossierService.getDossiers(this.serializedFilter, this.currentPage, pageSize)
    this.dossiers = dossiers.data
    this.totalDossiers = dossiers.total
  }

  clickOnRow (row: any, column: any, name: string, event: Event) {
    if (column.property === 'name') {
      return this.gotToFolder(row, column, name, event)
    }
    window.open(this.$router.resolve({ path: '/liste-dossiers?type=progress' }).href, '_blank')
  }

  gotToFolder (folder: FolderInterface, column: string, name: string, event: Event) {
    event.preventDefault()
    if (!folder) {
      return
    }
    if (folder.type === 'settled-folder') {
      return this.$router.push({
        name: 'settled-folder',
        params: {
          id: folder.id
        }
      })
    }

    if (folder.type === 'quote') {
      return this.$router.push({
        name: 'Quote',
        params: {
          id: folder.id
        }
      })
    }

    if (folder.type === 'legal-folder') {
      return this.$router.push({
        name: 'legal-folder',
        params: {
          id: folder.id
        }
      })
    }
  }
}
