import { apiClient } from '@/services/api.client'
import { BehaviorSubject, Observable } from 'rxjs'
import { User } from '@/services/auth.service'
import { GlobalItemSearch } from '@/services/searchGlobalItems.service'

export class searchItem {
  id: number
  name: string
  createdAt: string
  createdBy: User
  deleted: number
}

// Simple toolbar 3 last history
class GlobalSearchService {
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  private readonly _globalSearches: BehaviorSubject<searchItem[]> = new BehaviorSubject<searchItem[]>([])
  private previousQueryString: string | null = null

  public onLoading: Observable<boolean> = this._loading.asObservable()
  public onChangeGlobalSearches: Observable<any> = this._globalSearches.asObservable()

  async getGlobalSearches (): Promise<void> {
    await apiClient.get('global-search-query').then((res: any) => {
      this._globalSearches.next(res.data.global_search)
    }).catch((err) => {
      throw err
    })
  }

  public async saveGlobalSearch (name: string): Promise<any> {
    if (this.previousQueryString === null || name !== this.previousQueryString) {
      this._loading.next(true)
      this.previousQueryString = name
      return await apiClient.post('global-search-query', {
        name
      }).then((res: any) => {
        if (res.status === 200) {
          this._loading.next(false)
          globalSearchService.getGlobalSearches().catch((err) => {
            throw err
          })
        }
        return res.data
      })
    }
    return null
  }

  public async deleteSearch (id: any): Promise<any> {
    this._loading.next(true)
    return await apiClient.delete('global-search/' + String(id)).then((res: any) => {
      this._loading.next(false)
      return res
    })
  }

  public async deleteAllSearches (): Promise<any> {
    this._loading.next(true)
    return await apiClient.delete('global-search-all').then((res: any) => {
      this._loading.next(false)
      return res
    })
  }

  public async getAssociatedResultsGed (adresse: string): Promise<any> {
    this._loading.next(true)
    const query = new GlobalItemSearch({
      fields: 'ged.adresses.label,folder.adresses.label,contact.adresses.label,reference.adresses.label',
      bool: 'match_phrase',
      queryString: adresse,
      isAdvanced: false,
      advancedSearchContent: null,
      objectType: null
    })
    return await apiClient.get('ged/associated-results', { params: query }).then((res: any) => {
      this._loading.next(false)
      return res.data
    }).catch(() => {
      this._loading.next(false)
    })
  }

  public async getAssociatedResultsContact (contactId: string): Promise<any> {
    this._loading.next(true)
    const query = new GlobalItemSearch({
      fields: 'contact.id,folder.contacts.id',
      bool: 'match_phrase',
      queryString: contactId.toString(),
      isAdvanced: false,
      advancedSearchContent: null,
      objectType: null
    })
    return await apiClient.get('contact/associated-results', { params: query }).then((res: any) => {
      this._loading.next(false)
      return res.data
    }).catch(() => {
      this._loading.next(false)
    })
  }

  public async getAssociatedResultsReference (adresse: string): Promise<any> {
    this._loading.next(true)
    const query = new GlobalItemSearch({
      fields: 'ged.adresses.label,folder.adresses.label,contact.adresses.label,reference.adresse',
      bool: 'match_phrase',
      queryString: adresse,
      isAdvanced: false,
      advancedSearchContent: null,
      objectType: null
    })
    return await apiClient.get('reference/associated-results', { params: query }).then((res: any) => {
      this._loading.next(false)
      return res.data
    }).catch(() => {
      this._loading.next(false)
    })
  }

  public async getAssociatedResultsFolder (adresse: string): Promise<any> {
    this._loading.next(true)
    const query = new GlobalItemSearch({
      fields: 'ged.adresses.label,folder.adresses.label,contact.adresses.label,reference.adresse',
      bool: 'match_phrase',
      queryString: adresse,
      isAdvanced: false,
      advancedSearchContent: null,
      objectType: null
    })
    return await apiClient.get('dossier/associated-results', { params: query }).then((res: any) => {
      this._loading.next(false)
      return res.data
    }).catch(() => {
      this._loading.next(false)
    })
  }
}

export const globalSearchService = new GlobalSearchService()
