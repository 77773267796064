
import { User } from '@/services/user.service'
import { ReferenceAddress } from '@/services/listReference.service'
import { FolderInterface } from '@/models/FolderInterface'

export class PageParams {
  pageSize: number
  currentPage: number
  sortField: string
  sortAsc: boolean
  constructor (data: any = {}) {
    const pageSizeLocalStorage = localStorage.getItem('pageSize')
    this.pageSize = data.pageSize ? data.pageSize : (pageSizeLocalStorage ? parseInt(pageSizeLocalStorage) : 30)
    this.currentPage = data.currentPage || 1
    this.sortField = data.sortField || ''
    this.sortAsc = data.sortAsc || true
  }
}
export class ListTheme {
  id: number
  name: string
  constructor (data: any = {}) {
    this.id = data.id
    this.name = data.name
  }
}
export class ListContent {
  id: number
  name: string
  constructor (data: any = {}) {
    this.id = data.id
    this.name = data.name
  }
}

export class GedCriteria {
  type: string
  nom: string
  source: string
  peliasAddress: ReferenceAddress
  listThemeName: string
  listContentName: string
  dateMini: string | Date
  dateMaxi: string | Date
  tags: string[]
  constructor (data: any = {}) {
    this.type = data.type || ''
    this.nom = data.nom || ''
    this.source = data.source || ''
    this.peliasAddress = new ReferenceAddress(data.peliasAddress)
    this.listThemeName = data.listThemeName || ''
    this.listContentName = data.listContentName || ''
    this.dateMini = data.dateMini || ''
    this.dateMaxi = data.dateMaxi || ''
    this.tags = data.tags || []
  }
}

export class Ged {
  id: number
  user: User
  type: string
  nom: string
  dateStart: string
  dateEnd: string
  source: string
  listTheme: ListTheme
  listContent: ListContent
  champsUpload: any
  note: string
  tags: string[]
  address: string
  lat: number
  lng: number
  folders: FolderInterface[]
  confidentiality: boolean
  layer: string
  housenumber: string
  street: string
  postalcode: string
  country: string
  macroregion: string
  locality: string
  borough: string
  label: string
  constructor (data: any = {}) {
    this.id = data.id || null
    this.user = data.user
    this.type = data.type || 'document'
    this.nom = data.nom || ''
    this.dateStart = data.dateStart || new Date()
    this.dateEnd = data.dateEnd || new Date()
    this.source = data.source || ''
    this.listTheme = data.listTheme
    this.listContent = data.listContent
    this.champsUpload = data.champsUpload || null
    this.note = data.note || ''
    this.tags = data.tags || []
    this.address = data.address || ''
    this.lat = data.lat || 0
    this.lng = data.lng || 0
    this.folders = data.folders || []
    this.confidentiality = data.confidentiality || false
    this.layer = data.layer || ''
    this.housenumber = data.housenumber || ''
    this.street = data.street || ''
    this.postalcode = data.postalcode || ''
    this.country = data.country || ''
    this.macroregion = data.macroregion || ''
    this.locality = data.locality || ''
    this.borough = data.borough || ''
    this.label = data.label || ''
  }
}
