
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormattedFloat from '../../utils/FormattedFloat.vue'
import FormattedNumber from '../../utils/FormattedNumber.vue'
import { baseBlockItems, locativeBlockItems, valeurBlockItems, baseBlockNouvelleLocation } from '../blockItems'
import { loyersPrixItemList } from '../itemList'
import { floatRule } from '../formRules'
import { referenceService } from '@/services/listReference.service'
import { tap } from 'rxjs/operators'

@Component({
  subscriptions (this: any) {
    return {
      value: referenceService.onChangeReference.pipe(tap(() => {
        this.loyersPrixItemList = [...this.loyersPrixItemList]
      })),
      isMigratedRef: referenceService.onChangeIsMigratedRef
    }
  },
  components: {
    FormattedFloat,
    FormattedNumber
  }
})
export default class LoyersPrix extends Vue {
  @Prop({ type: Object })
    optionList: any

  @Prop({ type: String })
    localLabel!: any

  @Prop({ type: String })
    transactionLabel!: any

  @Prop({ type: Number })
    typeValeur!: any

  isMigratedRef = false
  value: any = {}
  floatRule = floatRule
  loyersPrixItemList = loyersPrixItemList
  baseBlockNouvelleLocation = baseBlockNouvelleLocation

  updated () {
    /* if (
        this.value.prixHd &&
        this.value.droitsPercent &&
        isFloat(this.value.prixHd) &&
        isFloat(this.value.droitsPercent)
    ) {
        this.value.prixDi = roundNumber(+this.value.prixHd * (1 + +this.value.droitsPercent / 100 ));
    }
    if (
        this.value.prixDi &&
        this.value.droitsPercent &&
        isFloat(this.value.prixDi) &&
        isFloat(this.value.droitsPercent)
    ) {
        this.value.prixHd = roundNumber(+this.value.prixDi / (1 + +this.value.droitsPercent / 100));
    } */
    // this.form.prixHd = roundNumber(this.prixHd);
    // this.form.prixDi = roundNumber(this.prixDi);
  }

  checkDisplayCondition (title: string, condition: string, transaction: string) {
    if (this.isMigratedRef) {
      return true
    }
    const localLabel = this.localLabel
    let result = false
    let showSpecific = true
    let checkTransaction = false
    switch (condition) {
      case 'base':
        result = baseBlockItems[localLabel] && !baseBlockItems[localLabel].includes(title)
        break
      case 'locative':
        if (this.baseBlockNouvelleLocation.includes(title) && this.value.typeTransaction === 1) {
          showSpecific = true
        } else {
          showSpecific = false
        }
        result = this.typeValeur === 1 && locativeBlockItems[localLabel] && !locativeBlockItems[localLabel].includes(title) && showSpecific
        break
      case 'valeur':
        checkTransaction = transaction ? this.transactionLabel === transaction : true
        if (title !== 'Prix € HD/ chambre' && title !== 'Prix € HD / lit' && title !== 'Prix €HD / fauteuil' && title !== 'Prix € HD/ Place') {
          showSpecific = true
        } else {
          this.transactionLabel === 'Droit au bail' ? showSpecific = false : showSpecific = true
        }
        result = this.typeValeur === 2 && showSpecific && checkTransaction && valeurBlockItems[localLabel] && !valeurBlockItems[localLabel].includes(title)
        break
      default:
        break
    }
    return result
  }

  updateValues (item: any) {
    if (item.value === 'prixDi' || item.value === 'prixHd' || item.value === 'loyerAnnuel') {
      referenceService.clearValues(item.value)
    }
    referenceService.updateRef(this.value, item.value)
  }

  changeSelect (item: any) {
    if (this.value[item.value] === 0) {
      this.value[item.value] = ''
    }
  }
}
