
import { Component, Prop, Vue } from 'vue-property-decorator'
import { LegalFolder, Courthouse } from '@/models/legalFolder.model'
import { legalFolderService } from '@/services/legalFolder.service'
import { POTENTIAL_END_DELAY } from '@/constants'
import moment from 'moment'

@Component({
  subscriptions () {
    return {
      courthouses$: legalFolderService.onChangeCourthouses,
      motifDossiers$: legalFolderService.onChangeMotifDossiers,
      natureDecisions$: legalFolderService.onChangeNatureDecisions,
      legalFolder$: legalFolderService.onChangeLegalFolder
    }
  }
})

export default class JudicialManagement extends Vue {
  @Prop({ default: false }) updateable: boolean
  legalFolder$: LegalFolder
  courthouses$: Courthouse[]

  rules: any = {}

  created () {
    legalFolderService.getCourthouses()
    legalFolderService.getMotifDossiers()
    legalFolderService.getNatureDecisions()
  }

  handleChangeMeetingDateSite (meetingDateSite: string): void {
    const timeStamp = moment(meetingDateSite).add(POTENTIAL_END_DELAY, 'days')
    this.legalFolder$.dateOfPotentialEnd = timeStamp.format('YYYY-MM-DD')
  }

  validate (): Promise<boolean> {
    return new Promise((resolve: any) => {
      (this.$refs.legalFolderForm as any).validate((valid: boolean) => {
        resolve(valid)
      })
    })
  }
}
