
import { Component, Prop, Vue } from 'vue-property-decorator'
import { LegalFolder, ModificationData, MODIFICATION_KEYS } from '@/models/legalFolder.model'
import { legalFolderService } from '@/services/legalFolder.service'
import { convertFrNumberEuro } from '@/utils/functions'
import FormattedFloat from '../../../utils/FormattedFloat.vue'

@Component({
  subscriptions () {
    return {
      legalFolder$: legalFolderService.onChangeLegalFolder
    }
  },
  components: {
    FormattedFloat
  },
  methods: {
    convertFrNumberEuro
  }
})

export default class ModificationManagement extends Vue {
  @Prop({ default: false }) updateable: boolean
  legalFolder$: LegalFolder
  MODIFICATION_KEYS = MODIFICATION_KEYS

  handleUpdatedAt (index: number): void {
    this.legalFolder$.modification.datas[index].updatedAt = new Date()
    legalFolderService.updateFolder()
  }

  handlePriceChange (): void {
    legalFolderService.updateFolder()
  }

  addDate (): void {
    this.legalFolder$.modification.datas.push(new ModificationData())
  }
}
