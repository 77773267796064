
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
    // The @Component decorator indicates the class is a Vue component
    @Component({

    })

export default class FormattedFloat extends Vue {
      @Prop({ default: null }) value: number | null
      @Prop({ default: '' }) placeholder: string
      @Prop({ default: '' }) size: string
      @Prop({ default: false }) disabled: boolean
      @Prop({ default: false }) readonly: boolean
      @Prop({ default: false }) prefix: boolean
      @Prop({ default: false }) suffix: boolean
      @Prop({ default: 2 }) decimal: number
      formattedValue: string = ''
      activeInput: boolean = false

      @Watch('value')
      onValueChanged (val: any) {
        const value = this.formatNumber(val, this.activeInput ? 0 : 2)
        this.formattedValue = value !== 'NaN' && value != null ? value : ''
      }

      created () {
        if (this.value != null) {
          const value = this.formatNumber(this.value, 2)
          this.formattedValue = value !== 'NaN' && value != null ? value : ''
        }
      }

      handleFocus (): void {
        this.activeInput = true
        /* if (!this.readonly && !this.disabled) {
          this.formattedValue = '';
        } */
        this.$emit('focus', this.formattedValue)
      }

      handleBlur (): void {
        this.activeInput = false
        if (!this.readonly && !this.disabled && this.value != null) {
          const value = this.formatNumber(this.value, 2)
          this.formattedValue = value !== 'NaN' && value != null ? value : ''
        }
        this.$emit('blur', this.formattedValue)
        /* if (this.formattedValue  !== '') {
          let changed = this.reverseFormatNumber(this.formattedValue, 'fr');
          if (changed != null) {
            this.$emit('input', changed);
          }
        } */
      }

      changeValue (event: any): void {
        if (event.key !== ',' && this.formattedValue !== '0,0') {
          const changed = this.reverseFormatNumber(this.formattedValue, 'fr')
          if (changed != null) {
            const value = this.formatNumber(changed, this.activeInput ? 0 : 2)
            this.formattedValue = value != null ? value : ''
            this.$emit('input', changed)
            /* if (event.key === 'Enter') {
              this.$emit('input', changed);
            } */
          }
        }
      }

      formatNumber (number: number, minimumFractionDigits: number = 0) {
        if (number != null) {
          return new Intl.NumberFormat('fr-FR', {
            style: 'decimal',
            minimumFractionDigits,
            maximumFractionDigits: this.decimal
          }).format(number)
        } else {
          return null
        }
      }

      reverseFormatNumber (val: string, locale: string) : number | null {
        const group: string = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '')
        const decimal: string = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '')
        const reversedVal: string = val.replace(new RegExp('\\' + group, 'g'), '')
        const reversedNumber = parseFloat(reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.'))
        return Number.isNaN(reversedNumber) ? null : reversedNumber
      }

      clearInput () {
        this.$emit('input', null)
      }
}
