
import { Component, Prop, Vue } from 'vue-property-decorator'
import { apiClient, baseUrl } from '@/services/api.client'
import { referenceService } from '@/services/listReference.service'

@Component({
  subscriptions (this: any) {
    return {
      value: referenceService.onChangeReference
    }
  }
})
export default class EmplacementImage extends Vue {
  /* @Prop({ type: Object })
  value!: any; */
  @Prop({ type: Array })
    fileList!: any

  baseUrl = baseUrl
  value: any

  handleSuccess (response: any, file: any, fileList: any[]) {
    if (response && fileList.length === 1) {
      if (!this.value.fileIds) {
        this.value.fileIds = []
      }
      this.value.fileIds.push(response.id)
      referenceService.updateRef(this.value, 'fileList')
    }
  }

  handleError (response: any) {
    this.$message({
      message: JSON.parse(response).message,
      type: 'error',
      offset: 65
    })
  }

  handleRemove (file: any) {
    if (file && file.id) {
      this.value.fileList = null
      this.value.fileIds = null
      apiClient.delete('reference-files/' + file.id)
      referenceService.updateRef(this.value, 'fileList')
    }
  }
}
