
import ContactFields from '../components/contact/ContactFields.vue'
import { Component, Vue } from 'vue-property-decorator'
import { userService, USER_RIGHTS } from '@/services/user.service'
import { authService } from '@/services/auth.service'
@Component({
  components: {
    ContactFields
  }
})
export default class ContactManagement extends Vue {
  submitForm () {
    (this.$refs.contactFields as any).submitForm()
  }

  deleteContact () {
    (this.$refs.contactFields as any).deleteContact((this.$refs.contactFields as any).contact.id)
  }

  isUserAllowedToDeleteContact () {
    const user = JSON.parse(JSON.stringify(authService.userValue))
    return userService.hasRight(user, USER_RIGHTS.DELETE_CONTACT)
  }
}
