
import { Component, Prop, Vue } from 'vue-property-decorator'
import QuoteMissionComponent from './QuoteMission.vue'
import { quoteService, Quote } from '@/services/quote.service'
import { userService, User } from '@/services/user.service'
import {
  MISSION_TYPES,
  MISSION_DATE_VALUES,
  VISIT_OPTIONS,
  DELIVER_OPTIONS
} from '@/services/constants.service'
// The @Component decorator indicates the class is a Vue component
@Component({
  subscriptions () {
    return {
      quote$: quoteService.onChangeQuote,
      user$: userService.onChange
    }
  },
  components: {
    QuoteMissionComponent
  }
})

export default class QuoteMissions extends Vue {
  @Prop({ default: false }) updateable: boolean
  @Prop({ default: false }) isLegalFolder: boolean
  MISSION_TYPES = MISSION_TYPES
  filteredDateValues = MISSION_DATE_VALUES
  filteredVisitOptions = VISIT_OPTIONS
  filteredDeliverOptions = DELIVER_OPTIONS
  quote$: Quote = new Quote()
  user$: User = new User()

  filterDateValues (query: string) {
    this.filteredDateValues = quoteService.filterOptions(query, 2, MISSION_DATE_VALUES)
  }

  filterVisitOptions (query: string) {
    this.filteredVisitOptions = quoteService.filterOptions(query, 3, VISIT_OPTIONS)
  }

  filterDeliverOptions (query: string) {
    this.filteredDeliverOptions = quoteService.filterOptions(query, 7, DELIVER_OPTIONS)
  }
}
