import moment from 'moment'
import { ListReference } from '@/services/listReference.service'

function isFloat (val: any): boolean {
  return val ? !(!/^\d+\.\d+$/.test(val) && !/^\d+$/.test(val)) : true
}

function roundNumber (val: number): number {
  return Math.round(+val * 10) / 10
}

function getPriceString (price: number | null): string {
  if (price === null) {
    price = 0
  }
  return price.toLocaleString('fr-fr', { style: 'currency', currency: 'EUR' })
}

function convertTimeStampToDateAndTime (timestamp: string): string {
  const day = new Date(timestamp)
  const date = ('0' + day.getDate().toString()).slice(-2) + '/' + ('0' + (day.getMonth() + 1).toString()).slice(-2).slice(-2) + '/' + day.getFullYear().toString()

  return `${date}`
}

const safeGet = (nestedObj: any, pathArr: any): any => {
  return pathArr.reduce((obj: any, key: any) =>
    (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj)
}

function localeDateStr (date: Date): string {
  return new Date(date).toLocaleDateString('fr-fr')
}

function getIsochroneScales (travelMode: string, displayType: string, travelTimes: any, travelDistance: any): string[] {
  const scales: string[] = []
  if (displayType === 'time') {
    Object.keys(travelTimes).forEach((time: string) => {
      scales.push(Math.floor(parseInt(time) / 60).toString() + ' Min')
    })
  } else {
    if (travelMode === 'walk') {
      Object.keys(travelDistance).forEach((distance: string) => {
        scales.push(parseInt(distance).toString() + ' m')
      })
    } else {
      Object.keys(travelDistance).forEach((distance: string) => {
        scales.push((parseInt(distance) / 1000).toString() + ' Km')
      })
    }
  }
  return scales
}

function periodString (startDateStr: string, endDateStr: string): string {
  if ((startDateStr === '' || startDateStr === null) && (endDateStr === '' || endDateStr === null)) {
    return ''
  }
  const startDate = new Date(startDateStr)
  const endDate = new Date(endDateStr)
  const start = {
    year: startDate.getFullYear(),
    month: startDate.getMonth() + 1,
    date: startDate.getDate()
  }
  const end = {
    year: endDate.getFullYear(),
    month: endDate.getMonth() + 1,
    date: endDate.getDate()
  }
  if (endDateStr === null || endDateStr === '') {
    return `${localeDateStr(startDate)}`
  }
  if (start.year !== end.year) {
    return `${localeDateStr(startDate)}`
  }
  if (start.month === end.month && start.year === end.year && start.date === end.date) {
    return `${localeDateStr(startDate)}`
  }
  if ((start.month === 1 && start.date === 1) && (end.month === 12 && end.date === 31)) {
    return `${start.year}`
  }
  if ((start.month === 1 && start.date === 1) && (end.month === 6 && end.date === 30)) {
    return `1S ${start.year}`
  }
  if ((start.month === 7 && start.date === 1) && (end.month === 12 && end.date === 31)) {
    return `2S ${start.year}`
  }
  if ((start.month === 1 && start.date === 1) && (end.month === 3 && end.date === 31)) {
    return `1T ${start.year}`
  }
  if ((start.month === 4 && start.date === 1) && (end.month === 6 && end.date === 30)) {
    return `2T ${start.year}`
  }
  if ((start.month === 7 && start.date === 1) && (end.month === 9 && end.date === 30)) {
    return `3T ${start.year}`
  }
  if ((start.month === 10 && start.date === 1) && (end.month === 12 && end.date === 31)) {
    return `4T ${start.year}`
  }
  if ((start.date === 1 && (end.date === 31 || end.date === 30)) || (start.month === 2 && start.date === 1 && end.date === 28)) {
    if (start.month === 1 && end.month === 1) {
      return `Janvier ${start.year}`
    }
    if (start.month === 2 && end.month === 2) {
      return `Février ${start.year}`
    }
    if (start.month === 3 && end.month === 3) {
      return `Mars ${start.year}`
    }
    if (start.month === 4 && end.month === 4) {
      return `Avril ${start.year}`
    }
    if (start.month === 5 && end.month === 5) {
      return `Mai ${start.year}`
    }
    if (start.month === 6 && end.month === 6) {
      return `Juin ${start.year}`
    }
    if (start.month === 7 && end.month === 7) {
      return `Juillet ${start.year}`
    }
    if (start.month === 8 && end.month === 8) {
      return `Août ${start.year}`
    }
    if (start.month === 9 && end.month === 9) {
      return `Septembre ${start.year}`
    }
    if (start.month === 10 && end.month === 10) {
      return `Octobre ${start.year}`
    }
    if (start.month === 11 && end.month === 11) {
      return `Novembre ${start.year}`
    }
    if (start.month === 12 && end.month === 12) {
      return `Décembre ${start.year}`
    }
  }
  if (startDateStr !== '' && endDateStr !== '') {
    return `${localeDateStr(startDate)} - ${localeDateStr(endDate)}`
  }
  return `${localeDateStr(startDate)}`
}

function validPeriodString (startDateStr: string, endDateStr: string): string | boolean {
  const startDate = new Date(startDateStr)
  const endDate = new Date(endDateStr)
  const start = {
    year: startDate.getFullYear(),
    month: startDate.getMonth() + 1,
    date: startDate.getDate()
  }
  const end = {
    year: endDate.getFullYear(),
    month: endDate.getMonth() + 1,
    date: endDate.getDate()
  }
  if (start.year !== end.year) {
    return false
  }
  if (start.month === end.month && start.year === end.year && start.date === end.date) {
    return true
  }
  if ((start.month === 1 && start.date === 1) && (end.month === 12 && end.date === 31)) {
    return `${start.year}`
  }
  if ((start.month === 1 && start.date === 1) && (end.month === 6 && end.date === 30)) {
    return `1S ${start.year}`
  }
  if ((start.month === 7 && start.date === 1) && (end.month === 12 && end.date === 31)) {
    return `2S ${start.year}`
  }
  if ((start.month === 1 && start.date === 1) && (end.month === 3 && end.date === 31)) {
    return `1T ${start.year}`
  }
  if ((start.month === 4 && start.date === 1) && (end.month === 6 && end.date === 30)) {
    return `2T ${start.year}`
  }
  if ((start.month === 7 && start.date === 1) && (end.month === 9 && end.date === 30)) {
    return `3T ${start.year}`
  }
  if ((start.month === 10 && start.date === 1) && (end.month === 12 && end.date === 31)) {
    return `4T ${start.year}`
  }
  if ((start.date === 1 && (end.date === 31 || end.date === 30)) || (start.month === 2 && start.date === 1 && end.date === 28)) {
    if (start.month === 1 && end.month === 1) {
      return `Janvier ${start.year}`
    }
    if (start.month === 2 && end.month === 2) {
      return `Février ${start.year}`
    }
    if (start.month === 3 && end.month === 3) {
      return `Mars ${start.year}`
    }
    if (start.month === 4 && end.month === 4) {
      return `Avril ${start.year}`
    }
    if (start.month === 5 && end.month === 5) {
      return `Mai ${start.year}`
    }
    if (start.month === 6 && end.month === 6) {
      return `Juin ${start.year}`
    }
    if (start.month === 7 && end.month === 7) {
      return `Juillet ${start.year}`
    }
    if (start.month === 8 && end.month === 8) {
      return `Août ${start.year}`
    }
    if (start.month === 9 && end.month === 9) {
      return `Septembre ${start.year}`
    }
    if (start.month === 10 && end.month === 10) {
      return `Octobre ${start.year}`
    }
    if (start.month === 11 && end.month === 11) {
      return `Novembre ${start.year}`
    }
    if (start.month === 12 && end.month === 12) {
      return `Décembre ${start.year}`
    }
  }
  return false
}

const convertFrNumberEuro = (number: number): string => {
  number = Math.round((number * 100)) / 100
  if (number === 0) {
    number = Math.abs(number)
  }
  return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)
}

const convertFrNumber = (number: number): string => {
  return new Intl.NumberFormat('fr-FR', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(number)
}

function formattedDate (date: any): string {
  moment.locale('fr')
  return moment(date).format('dddd DD MMMM YYYY')
}

function formattedHour (time: string): string {
  time = time.replace(':', 'H')
  return time
}

const getNow = (): any => {
  const today = new Date()
  const month = (today.getMonth() + 1) > 9 ? (today.getMonth() + 1).toString() : '0' + (today.getMonth() + 1).toString()
  const day = today.getDate() > 9 ? today.getDate().toString() : '0' + today.getDate().toString()
  const date = day + '/' + month + '/' + today.getFullYear().toString()
  const hour = today.getHours() > 9 ? today.getHours().toString() : '0' + today.getHours().toString()
  const min = today.getMinutes() > 9 ? today.getMinutes().toString() : '0' + today.getMinutes().toString()
  const time = hour + 'H' + min
  return date + ' à ' + time
}

const filterOptionsWithoutCaseSensitive = (query: string, key: string, options: any[]): any[] => {
  if (key !== '') {
    return options.filter((option: any) => {
      return option[key].normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase().indexOf(query.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase()) >= 0
    })
  } else {
    return options.filter((option: any) => {
      return option.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase().indexOf(query.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase()) >= 0
    })
  }
}

const filterGroupList = (query: string, sourceArray: any[]): any[] => {
  const filteredData: any[] = []
  sourceArray.forEach((group: any) => {
    const filteredOptions = filterOptionsWithoutCaseSensitive(query, 'label', group.options)
    if (filteredOptions.length > 0) {
      filteredData.push({
        label: group.label,
        options: filteredOptions
      })
    }
  })

  return filteredData
}

const removeSpecialCharacters = (item: string): string => {
  return item.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

const formatUserName = (contactLastName: string, contactFirstName: string): string => {
  return contactFirstName !== null && contactFirstName !== '' ? contactFirstName[0].toUpperCase() + contactFirstName.slice(1).toLowerCase() + ' ' + contactLastName.toUpperCase() : contactLastName
}

function getLocalTypeLabel (value: number, listReferences: ListReference[]): any {
  const find = listReferences.find((item: any) => item.type === 1 && item.num === value)
  if (find != null) {
    return find.label
  }
  return ''
}

function getTransactionTypeLabel (value: number, listReferences: ListReference[]): any {
  const find = listReferences.find((item: any) => (item.type === 4 || item.type === 7) && item.num === value)
  if (find != null) {
    return find.label
  }
  return ''
}

function getActiviteLabel (value: number, listReferences: ListReference[]): any {
  const find = listReferences.find((item: any) => (item.type === 3) && item.num === value)
  if (find != null) {
    return find.label
  }
  return ''
}

function generateTypeTransactionGroup (listReferences: ListReference[]): Array<{ label: string, options: ListReference[] }> {
  return [
    {
      label: 'VALEUR LOCATIVE',
      options: listReferences.filter((item) => item.type === 4)
    },
    {
      label: 'VALEUR VENALE',
      options: listReferences.filter((item) => item.type === 7)
    }
  ]
}

function generateTypeLocalGroup (listReferences: ListReference[]): Array<{ label: string, options: ListReference[] }> {
  return [
    {
      label: 'COMMERCIAL',
      options: [
        listReferences[0],
        listReferences[1],
        listReferences[2],
        listReferences[3],
        listReferences[4],
        listReferences[5],
        listReferences[6],
        listReferences[7]
      ]
    },
    {
      label: 'TOURISME',
      options: [
        listReferences[8],
        listReferences[9],
        listReferences[10],
        listReferences[11]
      ]
    },
    {
      label: 'RESIDENCES GEREES',
      options: [
        listReferences[12],
        listReferences[13]
      ]
    },
    {
      label: 'RESIDENTIEL',
      options: [
        listReferences[14],
        listReferences[16],
        listReferences[15],
        listReferences[17],
        listReferences[18]
      ]
    },
    {
      label: 'TERTIAIRE',
      options: [listReferences[19], listReferences[20]]
    },
    {
      label: 'SANTE',
      options: [
        listReferences[21],
        listReferences[22],
        listReferences[23],
        listReferences[24]
      ]
    },
    {
      label: 'EVENEMENTIEL',
      options: [
        listReferences[25]
        // listReferences[26]
      ]
    },
    {
      label: 'LOISIR',
      options: [
        listReferences[27],
        listReferences[28]
        // listReferences[37]
      ]
    },
    {
      label: 'Autres',
      options: [
        listReferences[29],
        listReferences[30],
        // listReferences[31],
        listReferences[32],
        listReferences[33],
        listReferences[34],
        listReferences[35]
        // listReferences[36],
      ]
    }
  ]
}

function generateActivitiesGroup (listReferences: ListReference[]): Array<{ label: string, options: ListReference[] }> {
  return [
    {
      label: 'ALIMENTATION',
      options: [
        listReferences[169],
        listReferences[0],
        listReferences[1],
        listReferences[2],
        listReferences[3],
        listReferences[4],
        listReferences[5],
        listReferences[6],
        listReferences[7],
        listReferences[8],
        listReferences[9],
        listReferences[10],
        listReferences[11],
        listReferences[12],
        listReferences[13],
        listReferences[14],
        listReferences[15],
        listReferences[16],
        listReferences[17],
        listReferences[18],
        listReferences[19],
        listReferences[20],
        listReferences[21]
      ]
    },
    {
      label: 'AUTRES - DIVERS',
      options: [
        listReferences[172],
        listReferences[22],
        listReferences[23],
        listReferences[24],
        listReferences[25],
        listReferences[26],
        listReferences[27]
      ]
    },
    {
      label: 'CINASPIC',
      options: [
        listReferences[28],
        listReferences[29],
        listReferences[30],
        listReferences[32]
      ]
    },
    {
      label: 'BUREAUX',
      options: [
        listReferences[31]
      ]
    },
    {
      label: 'BATIMENTS - INACTIFS',
      options: [
        listReferences[33]
      ]
    },
    {
      label: 'RÉSIDENTIEL',
      options: [
        listReferences[34],
        listReferences[35],
        listReferences[36],
        listReferences[37]
      ]
    },
    {
      label: 'TERRAIN',
      options: [
        listReferences[38],
        listReferences[39],
        listReferences[40]
      ]
    },
    {
      label: "LOCAUX D'ACTIVITES",
      options: [
        listReferences[41]
      ]
    },
    {
      label: 'CADEAUX - BIJOUX',
      options: [
        listReferences[42],
        listReferences[43],
        listReferences[44],
        listReferences[45],
        listReferences[46],
        listReferences[47]
      ]
    },
    {
      label: 'CAFE - RESTAURATION',
      options: [
        listReferences[48],
        listReferences[49],
        listReferences[50],
        listReferences[51]
      ]
    },
    {
      label: 'HEBERGEMENT',
      options: [
        listReferences[52],
        listReferences[53],
        listReferences[54],
        listReferences[55],
        listReferences[56]
      ]
    },
    {
      label: 'EQUIPEMENT DE LA PERSONNE',
      options: [
        listReferences[171],
        listReferences[57],
        listReferences[58],
        listReferences[59],
        listReferences[60],
        listReferences[61],
        listReferences[62],
        listReferences[63],
        listReferences[64],
        listReferences[65],
        listReferences[66],
        listReferences[67],
        listReferences[68],
        listReferences[69],
        listReferences[70],
        listReferences[71],
        listReferences[72],
        listReferences[73],
        listReferences[74]
      ]
    },
    {
      label: 'EQUIPEMENT DE LA MAISON',
      options: [
        listReferences[170],
        listReferences[75],
        listReferences[76],
        listReferences[77],
        listReferences[78],
        listReferences[79],
        listReferences[80],
        listReferences[81],
        listReferences[82],
        listReferences[83],
        listReferences[84],
        listReferences[85],
        listReferences[86],
        listReferences[87],
        listReferences[88],
        listReferences[89],
        listReferences[90],
        listReferences[91],
        listReferences[92],
        listReferences[93],
        listReferences[94],
        listReferences[95],
        listReferences[96],
        listReferences[97]
      ]
    },
    {
      label: 'GRANDE SURFACE',
      options: [
        listReferences[98],
        listReferences[99],
        listReferences[100],
        listReferences[101],
        listReferences[102]
      ]
    },
    {
      label: 'LOISIRS',
      options: [
        listReferences[173],
        listReferences[103],
        listReferences[104],
        listReferences[105],
        listReferences[106],
        listReferences[107],
        listReferences[108],
        listReferences[109],
        listReferences[110],
        listReferences[111],
        listReferences[112],
        listReferences[113],
        listReferences[114],
        listReferences[115],
        listReferences[116],
        listReferences[117],
        listReferences[118],
        listReferences[119],
        listReferences[120],
        listReferences[121],
        listReferences[122],
        listReferences[123],
        listReferences[124],
        listReferences[125],
        listReferences[126],
        listReferences[127],
        listReferences[128]
      ]
    },
    {
      label: 'PARFUMERIE - BEAUTE - SOINS',
      options: [
        listReferences[129],
        listReferences[130],
        listReferences[131],
        listReferences[132],
        listReferences[133],
        listReferences[134],
        listReferences[135],
        listReferences[136],
        listReferences[137]
      ]
    },
    {
      label: 'SERVICES',
      options: [
        listReferences[174],
        listReferences[138],
        listReferences[139],
        listReferences[140],
        listReferences[141],
        listReferences[142],
        listReferences[143],
        listReferences[144],
        listReferences[145],
        listReferences[146],
        listReferences[147],
        listReferences[148],
        listReferences[149],
        listReferences[150],
        listReferences[151],
        listReferences[152],
        listReferences[153],
        listReferences[154],
        listReferences[155],
        listReferences[156],
        listReferences[157],
        listReferences[158],
        listReferences[159],
        listReferences[160],
        listReferences[161],
        listReferences[162],
        listReferences[163],
        listReferences[164],
        listReferences[165],
        listReferences[166],
        listReferences[167],
        listReferences[168]
      ]
    }
  ]
}

function getExpertiseTypeLabels (expertiseTypes: []): string {
  return expertiseTypes.map((item: any) => {
    return item.label
  }).join(', ')
}

function getAbbreviation (value: string): string {
  switch (value) {
    case 'Nouvelle location':
      return 'NL'
    case 'Renouvellement amiable':
      return 'RA'
    case 'Renouvellement judiciaire':
      return 'RJ'
    case 'Révision judiciaire':
      return 'REJ'
    default:
      return value
  }
}

function getShortLabel (value: string): string {
  const splittedStr = value.split(',')
  const shortStr = splittedStr.length > 2 ? [splittedStr[0], splittedStr[1]].join(',') + '...' : splittedStr.join(',')
  return shortStr
}

function numberWithSpaces (x: number): string {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join('.')
}

function fromFrToEnDate (values: string): string {
  const splitted = values.split('-')
  splitted.reverse()

  return splitted.join('-')
}

function getDistanceBetweenTwoPoints (lat1: number, lon1: number, lat2: number, lon2: number): number {
  const x = degreesToRadians(lon2 - lon1) * Math.cos(degreesToRadians((lat1 + lat2) / 2))
  const y = degreesToRadians(lat2 - lat1)

  return Math.sqrt(x * x + y * y) * 6371
}

function degreesToRadians (degrees: number): number {
  return degrees * Math.PI / 180
}

export {
  fromFrToEnDate,
  isFloat,
  roundNumber,
  getPriceString,
  convertTimeStampToDateAndTime,
  safeGet,
  localeDateStr,
  getIsochroneScales,
  periodString,
  validPeriodString,
  convertFrNumberEuro,
  convertFrNumber,
  formattedDate,
  formattedHour,
  getNow,
  filterOptionsWithoutCaseSensitive,
  filterGroupList,
  removeSpecialCharacters,
  formatUserName,
  generateTypeLocalGroup,
  generateActivitiesGroup,
  getLocalTypeLabel,
  getTransactionTypeLabel,
  getActiviteLabel,
  getExpertiseTypeLabels,
  getAbbreviation,
  getShortLabel,
  generateTypeTransactionGroup,
  numberWithSpaces,
  getDistanceBetweenTwoPoints
}
