
import { Component, Vue } from 'vue-property-decorator'
import { authService } from '@/services/auth.service'
import { Contact, contactService } from '@/services/contact.service'
import { User, USER_RIGHTS, userService } from '@/services/user.service'
import SearchBar from '@/components/ged/SearchBar.vue'

@Component({
  subscriptions (this: Vue) {
    return {
      contacts: contactService.onChange,
      activeContactIndex$: contactService.onChangeActiveContactIndex,
      user$: userService.onChange,
      loading$: contactService.onLoading,
      totalClient$: contactService.onChangeTotalContacts
    }
  },
  components: {
    SearchBar
  }
})
export default class Client extends Vue {
  contacts: Contact[] = []
  rowActive: boolean = false
  filterActive: boolean = false
  contactType: string | null = null
  user$: User = new User()
  loading$: boolean = false
  totalClient$: number = 0
  activeContactIndex$: number = 0
  isFixesIndex: boolean = false
  selectedIndex: any = null

  form = {
    sizePage: 30,
    currentPage: 1,
    adress: {
      code_postal: '',
      ville: ''
    },
    contact_type: 'Tout',
    active1: '',
    active2: '',
    value_title: '',
    options_title: [
      {
        value: '',
        label: ''
      },
      {
        value: 'Indivision',
        label: 'Indivision'
      }, {
        value: 'Consorts',
        label: 'Consorts'
      }, {
        value: 'Maître',
        label: 'Maître'
      },
      {
        value: 'Monsieur',
        label: 'Monsieur'
      },
      {
        value: 'Madame',
        label: 'Madame'
      }
    ],
    value_juridique: false,
    prenom: '',
    nom: '',
    brand: '',
    siret: '',
    sortProp: 'updated_at',
    sortOrder: 'descending'
  }

  search: string = ''

  created () {
    const user = authService.userValue
    if (!userService.hasRight(user, USER_RIGHTS.CLIENT_RIGHTS)) {
      this.$router.replace('/')
    } else {
      this.contactType = this.form.contact_type
      contactService.getContacts(this.form)
      contactService.onChange.subscribe((item) => {
        this.contacts = item
      })
    }
  }

  handleFilter () {
    this.filterActive = !this.filterActive
  }

  addUser () {
    this.$router.push('/contact/0')
  }

  handleSortChange (prop: string) {
    this.form.sortProp = prop
    this.form.sortOrder = this.form.sortOrder === 'ascending' ? 'descending' : 'ascending'
    if (this.search && this.search !== '') {
      this.searchSeveral(this.search)
      return
    }
    this.onSubmitControl()
  }

  handleCurrentChange ({ id }: any) {
    this.$router.push({
      name: 'Contact',
      params: { id }
    })
  }

  updateRow (id: any) {
    window.open(this.$router.resolve({ path: '/contact/' + id }).href, '_blank')
  }

  onChangeCurrent (currentPage: number) {
    this.form.currentPage = currentPage
    if (this.search && this.search !== '') {
      this.searchSeveral(this.search)
      return
    }
    this.onSubmitControl()
  }

  onChangeSizePage (sizePage: number) {
    localStorage.setItem('pageSize', `${sizePage}`)
    this.form.sizePage = sizePage
    if (this.search !== '') {
      this.searchSeveral(this.search)
      return
    }
    this.onSubmitControl()
  }

  onSubmitControl () {
    contactService.getContacts(this.form)
    contactService.onChange.subscribe((item) => {
      this.contacts = item
      this.contactType = this.form.contact_type
    })
  }

  onEmpty_control () {
    this.search = ''
    this.form.siret = ''
    this.form.prenom = ''
    this.form.active1 = ''
    this.form.active2 = ''
    this.form.nom = ''
    this.form.value_title = ''
    this.form.brand = ''
    this.form.currentPage = 1
    this.form.contact_type = 'Tout'
    this.form.value_juridique = false
    this.onSubmitControl()
  }

  changeSearchKey (searchKey: string) {
    this.form.currentPage = 1
    this.searchSeveral(searchKey)
  }

  changeIndex (index = null) {
    if (!this.isFixesIndex) {
      contactService.activeContactIndex(index)
    }
  }

  searchByFilters () {
    this.form.currentPage = 1
    this.onSubmitControl()
  }

  searchSeveral (searchKey: any) {
    if (searchKey) {
      contactService.getContactsByKey(
        searchKey,
        this.form.sizePage,
        this.form.currentPage,
        this.form.sortProp,
        this.form.sortOrder
      )
      contactService.onChange.subscribe((item) => {
        this.contacts = item
      })
    } else {
      contactService.getContacts(this.form)
      contactService.onChange.subscribe((item) => {
        this.contacts = item
      })
    }
  }

  closeDetail () {
    this.rowActive = false
  }

  getEmail () {
    if (this.contactType === 'Tout' || this.contactType === 'P.Physique') {
      return 'email1'
    } else {
      return 'email2'
    }
  }

  getActivity () {
    if (this.contactType === 'P.Morale' || this.contactType === 'Tout') {
      return 'active1'
    } else {
      return 'active2'
    }
  }

  getTitle () {
    if (this.contactType === 'P.Morale') {
      return 'brand'
    } else {
      return 'title'
    }
  }
}
