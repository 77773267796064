import { apiClient } from '@/services/api.client'
import { BehaviorSubject, Observable } from 'rxjs'
import { roundNumber } from '@/utils/functions'

export class ListReference {
  id?: number
  num: number
  type: number
  label: string

  constructor (listReference: any) {
    this.id = listReference.id
    this.num = listReference.num
    this.type = listReference.type
    this.label = listReference.label
  }
}

export class ReferenceAddress {
  layer: string
  housenumber: string
  street: string
  postalcode: string
  country: string
  macroregion: string
  region: string
  locality: string
  continent: string
  borough: string
  label: string
  lat: number
  lon: number
  subHousenumber: string
  name: string
  postcode: string
  district: string
  type: string
  apiUsed: string
  public constructor (data: any = {}) {
    this.apiUsed = data.type && data.context ? 'gouv' : 'pelias'
    this.layer = data.layer || data.type || ''
    this.housenumber = data.housenumber || ''
    if (this.housenumber.includes('bis') || this.housenumber.includes('ter')) {
      const number = this.housenumber.split(' ')
      this.housenumber = number[0]
      this.subHousenumber = number[1]
    } else {
      this.subHousenumber = data.subHousenumber || ''
    }
    if (data.city === data.name) {
      this.street = data.street || ''
    } else {
      this.street = data.street || data.name || ''
    }
    this.postalcode = data.postalcode || data.postcode || ''
    if (data.context) {
      this.continent = 'Europe'
      this.country = 'France'
      const item = data.context.split(', ')
      if (item.length === 3) {
        this.region = item[1]
        this.macroregion = item[2]
      }
    } else {
      this.continent = data.continent || ''
      this.country = data.country || ''
      this.macroregion = data.macroregion || ''
      this.region = data.region || ''
    }
    this.locality = data.locality || data.city || ''
    this.borough = data.borough || data.district || ''
    this.label = data.label || ''
    this.lat = data.coordinates ? data.coordinates[1] : 0
    this.lon = data.coordinates ? data.coordinates[0] : 0
  }
}
// this class is responsible for managing the roles.
class ListReferenceService {
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  private readonly _referenceAddresses: BehaviorSubject<ReferenceAddress[]> = new BehaviorSubject<ReferenceAddress[]>([])
  private readonly _listReferences: BehaviorSubject<ListReference[]> = new BehaviorSubject<ListReference[]>([])
  public onLoading: Observable<boolean> = this._loading.asObservable()
  public onChangeReferenceAddresses: Observable<ReferenceAddress[]> = this._referenceAddresses.asObservable()
  public onChangeListReferences: Observable<ListReference[]> = this._listReferences.asObservable()

  public async getListReference (): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('list-reference').then(res => {
      if (res.data) {
        const listReferences: ListReference[] = []
        res.data.forEach((listReference: any) => {
          listReferences.push(new ListReference(listReference))
        })
        this._listReferences.next(listReferences)
        this._loading.next(false)
        return listReferences
      }
    })
  }

  public async saveReference (reference: any, referenceAddress: ReferenceAddress): Promise<void> {
    this._loading.next(true)
    return await apiClient.post('reference', { reference, referenceAddress }).then(res => {
      this._loading.next(false)
      if (res.data) {
        const reference: any = res.data
        return reference
      }
    })
  }

  public async delete (id: number): Promise<void> {
    this._loading.next(true)
    await apiClient.delete('reference/' + id.toString()).then(res => {
      this._loading.next(false)
    })
  }

  public async getReferences (): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('references').then(res => {
      if (res.data) {
        const references: any[] = []
        res.data.forEach((reference: any) => {
          references.push(reference)
        })
        this._loading.next(false)
        return references
      }
    })
  }

  public async getSavedReferences (refIdList: any): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('saved-references', { params: { refIdList: JSON.stringify(refIdList) } }).then(res => {
      if (res.data) {
        const references: any[] = []
        res.data.forEach((reference: any) => {
          references.push(reference)
        })
        this._loading.next(false)
        return references
      }
    })
  }

  public async searchReferences (searchCondition: any): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('search-references', { params: { searchCondition: JSON.stringify(searchCondition) } }).then(res => {
      if (res.data) {
        const references: any[] = []
        res.data.forEach((reference: any) => {
          references.push(reference)
        })
        this._loading.next(false)
        return references
      }
    }).catch((err) => {
      this._loading.next(false)
      return err
    })
  }

  public async getReferenceAddresse (query: string): Promise<any> {
    return await apiClient.get('address-suggest/' + query).then(res => {
      if (res.data) {
        const referenceAddresses: ReferenceAddress[] = []
        res.data.features.forEach((feature: any) => {
          const data = { ...feature.properties, ...feature.geometry }
          if (feature.properties.type) {
            data.type = feature.properties.type
          }
          referenceAddresses.push(new ReferenceAddress(data))
        })
        this._referenceAddresses.next(referenceAddresses)
        return referenceAddresses
      }
    })
  }

  public async exportSearch (userId: number, searchId: number, isNumberFormat: boolean): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('map/download-screenshots/' + userId.toString() + '/' + searchId.toString(), {
      params: {
        'number-format': isNumberFormat
      },
      responseType: 'blob'
    }).then((res: any) => {
      this._loading.next(false)
      return res
    }).catch((err) => {
      this._loading.next(false)
      return err
    })
  }

  public updateRating (referenceId: number, rating: number): any {
    return apiClient.post('update-rating', { referenceId, rating }).then(res => {
      if (res.data) {
        return res.data
      }
    })
  }

  public async getRefById (id: string): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('ref/' + id).then((res: any) => {
      this._loading.next(false)
      return res.data
    }).catch((err) => {
      this._loading.next(false)
      throw err
    })
  }

  public async generateReferenceTemplate (data: any): Promise<any> {
    this._loading.next(true)
    return await apiClient.post('reference-template', data, {
      responseType: 'blob'
    }).then((res: any) => {
      this._loading.next(false)
      return res
    }).catch((err) => {
      this._loading.next(false)
      return err
    })
  }

  public async validateImports (data: any): Promise<any> {
    return await apiClient.post('reference/validate-imports', data)
  }

  public async cancelImports (data: any): Promise<any> {
    return await apiClient.post('reference/cancel-imports', data)
  }

  public async generateValidateFile (data: any): Promise<any> {
    this._loading.next(true)
    return await apiClient.post('template-validate', data, {
      responseType: 'blob'
    }).then((res: any) => {
      this._loading.next(false)
      return res
    }).catch((err) => {
      this._loading.next(false)
      return err
    })
  }
}

class ReferenceService {
  private readonly _referenceAddress: BehaviorSubject<ReferenceAddress> = new BehaviorSubject<any>({})
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  private readonly _reference: BehaviorSubject<any> = new BehaviorSubject<any>({})
  private readonly _isMigratedRef: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public onChangeReference: Observable<any> = this._reference.asObservable()
  public onChangeReferenceAdressse: Observable<ReferenceAddress> = this._referenceAddress.asObservable()
  public onChangeIsMigratedRef: Observable<boolean> = this._isMigratedRef.asObservable()
  public onLoading: Observable<boolean> = this._loading.asObservable()

  public LOCAL_COMMERCIAL = [1, 2, 3, 4, 5, 6, 7, 8, 9, 32]
  public LOCAL_TOURISME = [9, 10, 11, 12]
  public LOCAL_RESIDENCE = [13, 14]
  public LOCAL_SANTE = [22, 23, 24, 25]
  public LOCAL_LOISIR = [28, 29]
  public LOCAL_PARKING = [33, 34]

  get reference (): any {
    return this._reference.getValue()
  }

  get isMigratedRef (): boolean {
    return this._isMigratedRef.getValue()
  }

  public clearValues (key: string): void {
    switch (key) {
      case 'prixHd':
        this.reference.prixDi = null
        this.reference.droitsPercent = null
        this.reference.tauxRendement = null
        this.reference.prixM2 = null
        this.reference.multipleEbe = null
        this.reference.prixHdPerCa = null
        this.reference.multipleLoyer = null
        this.reference.tauxCapitalisation = null
        this.reference.prixPlace = null
        this.reference.prixFauteuil = null
        this.reference.prixLit = null
        this.reference.prixChambre = null
        break
      case 'prixDi':
        this.reference.prixHd = null
        this.reference.droitsPercent = null
        this.reference.tauxRendement = null
        this.reference.tauxCapitalisation = null
        break
      case 'loyerAnnuel':
        this.reference.loyerM2 = null
        this.reference.tauxRendement = null
        this.reference.tauxCapitalisation = null
        break
      default:
        break
    }
    this._reference.next(this.reference)
  }

  public updateRef (form: any, key: string): void {
    if ((key === 'mountCa' || key === 'nombreLits') && this.reference.nombreLits && this.reference.mountCa && (this.LOCAL_SANTE.includes(this.reference.typeLocal) ||
            this.reference.typeLocal === 10 || this.reference.typeLocal === 12)) {
      const caParLit = roundNumber(this.reference.mountCa / this.reference.nombreLits)
      this.reference.caParLit = (caParLit || 0).toFixed(0)
    }
    if ((key === 'mountCa' || key === 'nombreChambres') && this.reference.nombreChambres && this.reference.mountCa &&
            (this.LOCAL_TOURISME.includes(this.reference.typeLocal) || this.LOCAL_RESIDENCE.includes(this.reference.typeLocal) || this.LOCAL_SANTE.includes(this.reference.typeLocal))) {
      const caParChambre = (this.reference.mountCa / this.reference.nombreChambres)
      this.reference.caParChambre = (caParChambre || 0).toFixed(0)
    }
    if ((key === 'mountCa' || key === 'ebe') && this.reference.ebe && this.reference.mountCa && (this.LOCAL_TOURISME.includes(this.reference.typeLocal) ||
        this.LOCAL_RESIDENCE.includes(this.reference.typeLocal) || this.LOCAL_SANTE.includes(this.reference.typeLocal) ||
            this.reference.typeLocal === 26 || this.reference.typeLocal === 33)) {
      const ebePerCA = (this.reference.ebe / this.reference.mountCa) * 100
      this.reference.ebePerCA = (ebePerCA || 0).toFixed(1)
    }
    if ((key === 'mountCa' || key === 'ebitdar') && this.reference.mountCa && this.reference.ebitdar && (this.LOCAL_TOURISME.includes(this.reference.typeLocal) ||
            this.LOCAL_RESIDENCE.includes(this.reference.typeLocal) || this.LOCAL_SANTE.includes(this.reference.typeLocal) ||
            this.reference.typeLocal === 26 || this.reference.typeLocal === 33)) {
      const ebitdarPerCA = (this.reference.ebitdar / this.reference.mountCa) * 100
      this.reference.ebitdarPerCA = (ebitdarPerCA || 0).toFixed(1)
    }
    if ((key === 'nombrePlace' || key === 'loyerAnnuel') && this.reference.nombrePlace && this.reference.loyerAnnuel && this.LOCAL_PARKING.includes(this.reference.typeLocal)) {
      const loyerPerPlace = roundNumber(this.reference.loyerAnnuel / this.reference.nombrePlace)
      this.reference.loyerPlace = (loyerPerPlace || 0).toFixed(0)
    }
    if ((key === 'mountCa' || key === 'nombrePlaces') && this.reference.nombrePlaces && this.reference.mountCa && this.LOCAL_PARKING.includes(this.reference.typeLocal)) {
      const caPerPlace = roundNumber(this.reference.mountCa / this.reference.nombrePlaces)
      this.reference.caPerPlace = (caPerPlace || 0).toFixed(0)
    }
    if ((key === 'loyerAnnuel' || key === 'nombreLits') && this.reference.nombreLits && this.reference.loyerAnnuel && (this.LOCAL_LOISIR.includes(this.reference.typeLocal) || this.LOCAL_SANTE.includes(this.reference.typeLocal) ||
            this.reference.typeLocal === 10 || this.reference.typeLocal === 12)) {
      const loyerLit = roundNumber(this.reference.loyerAnnuel / this.reference.nombreLits)
      this.reference.loyerLit = (loyerLit || 0).toFixed(0)
    }
    if ((key === 'loyerAnnuel' || key === 'nombreFauteuils') && this.reference.nombreFauteuils && this.reference.loyerAnnuel && this.LOCAL_LOISIR.includes(this.reference.typeLocal)) {
      const loyerFauteuil = roundNumber(this.reference.loyerAnnuel / this.reference.nombreFauteuils)
      this.reference.loyerFauteuil = (loyerFauteuil || 0).toFixed(0)
    }
    if ((key === 'mountCa' || key === 'nombreFauteuils') && this.reference.nombreFauteuils && this.reference.mountCa && this.LOCAL_LOISIR.includes(this.reference.typeLocal)) {
      const caFauteuil = roundNumber(this.reference.mountCa / this.reference.nombreFauteuils)
      this.reference.caFauteuil = (caFauteuil || 0).toFixed(0)
    }
    if ((key === 'nombreChambres' || key === 'prixHd' || key === 'droitsPercent' || key === 'prixDi') && this.reference.prixHd && this.reference.nombreChambres && (this.LOCAL_TOURISME.includes(this.reference.typeLocal) ||
            this.LOCAL_RESIDENCE.includes(this.reference.typeLocal) || this.LOCAL_SANTE.includes(this.reference.typeLocal))) {
      const prixChambre = roundNumber(this.reference.prixHd / this.reference.nombreChambres)
      this.reference.prixChambre = (prixChambre || 0).toFixed(0)
    }
    if ((key === 'nombreLits' || key === 'prixHd' || key === 'droitsPercent' || key === 'prixDi') && this.reference.prixHd && this.reference.nombreLits && this.LOCAL_SANTE.includes(this.reference.typeLocal)) {
      const prixLit = roundNumber(this.reference.prixHd / this.reference.nombreLits)
      this.reference.prixLit = (prixLit || 0).toFixed(0)
    }
    if ((key === 'nombreFauteuils' || key === 'prixHd' || key === 'droitsPercent' || key === 'prixDi') && this.LOCAL_LOISIR.includes(this.reference.typeLocal) && this.reference.typeValeur === 2) {
      const prixFauteuil = roundNumber(this.reference.prixHd / this.reference.nombreFauteuils)
      this.reference.prixFauteuil = (prixFauteuil || 0).toFixed(0)
    }
    if ((key === 'nombrePlaces' || key === 'prixHd' || key === 'droitsPercent' || key === 'prixDi') && this.LOCAL_PARKING.includes(this.reference.typeLocal) && this.reference.typeValeur === 2) {
      const prixPlace = roundNumber(this.reference.prixHd / this.reference.nombrePlaces)
      this.reference.prixPlace = (prixPlace || 0).toFixed(0)
    }
    if ((key === 'loyerAnnuel' || key === 'ebe') && this.reference.typeValeur === 1 &&
            (this.LOCAL_TOURISME.includes(this.reference.typeLocal) || this.LOCAL_RESIDENCE.includes(this.reference.typeLocal) || this.LOCAL_SANTE.includes(this.reference.typeLocal) ||
            this.LOCAL_LOISIR.includes(this.reference.typeLocal))) {
      const loyerPerEbe = (this.reference.loyerAnnuel / this.reference.ebe) * 100
      this.reference.loyerPerEbe = (loyerPerEbe || 0).toFixed(1)
    }
    if ((key === 'loyerAnnuel' || key === 'ebitdar') && this.reference.loyerAnnuel && this.reference.ebitdar && this.reference.typeValeur === 1 &&
            (this.LOCAL_TOURISME.includes(this.reference.typeLocal) || this.LOCAL_RESIDENCE.includes(this.reference.typeLocal) || this.LOCAL_SANTE.includes(this.reference.typeLocal) ||
            this.LOCAL_LOISIR.includes(this.reference.typeLocal))) {
      const loyerPerEbitdar = (this.reference.loyerAnnuel / this.reference.ebitdar) * 100
      this.reference.loyerPerEbitdar = (loyerPerEbitdar || 0).toFixed(1)
    }
    if (key === 'filelist') {
      this.reference.fileList = form.fileList
    }
    if ((key === 'loyerAnnuel' || key === 'mountCa') && this.reference.loyerAnnuel && this.reference.mountCa) {
      const loyerPerCa = ((this.reference.loyerAnnuel / this.reference.mountCa)) * 100
      this.reference.loyerPerCa = (loyerPerCa || 0).toFixed(2)
    }
    if ((key === 'surfaceM2' || key === 'loyerAnnuel')) {
      if (this.reference.loyerAnnuel && this.reference.surfaceM2) {
        const loyerM2 = this.reference.loyerAnnuel / this.reference.surfaceM2
        this.reference.loyerM2 = (loyerM2 || 0).toFixed(2)
      }
    }
    if (key === 'tablePonderation') {
      let surfacePonderee: number = 0
      let surfaceUtile = 0
      form.tablePonderation.forEach((item: any) => {
        item.surfacePonderee = parseFloat((item.surfaceUtile * item.ponderation).toFixed(2)) || 0
        surfacePonderee += Number(item.surfacePonderee)
        surfaceUtile += parseFloat(item.surfaceUtile)
      })
      this.reference.surfacePonderee = surfacePonderee
      this.reference.surfaceM2 = surfaceUtile
    }
    if (key === 'loyerAnnuel' || key === 'surfacePonderee' || key === 'tablePonderation') {
      if (this.reference.loyerAnnuel && this.reference.surfacePonderee && (this.LOCAL_COMMERCIAL.includes(this.reference.typeLocal) ||
            this.LOCAL_SANTE.includes(this.reference.typeLocal) || this.reference.typeLocal === 26 || this.reference.typeLocal === 30 || this.reference.typeLocal === 31)) {
        const loyerP = this.reference.loyerAnnuel / this.reference.surfacePonderee
        this.reference.loyerP = (loyerP || 0).toFixed(2)
      }
    }
    if ((key === 'loyerAnnuel' || key === 'nombreChambres') && (this.LOCAL_TOURISME.includes(this.reference.typeLocal) ||
            this.LOCAL_RESIDENCE.includes(this.reference.typeLocal) || this.LOCAL_SANTE.includes(this.reference.typeLocal))) {
      if (this.reference.loyerAnnuel && this.reference.nombreChambres) {
        const loyerChambre = this.reference.loyerAnnuel / this.reference.nombreChambres
        this.reference.loyerChambre = (loyerChambre || 0).toFixed(2)
      }
    }
    if ((key === 'mountCa' || key === 'm2Sdv') && (this.LOCAL_COMMERCIAL.includes(this.reference.typeLocal) || this.reference.typeLocal === 26)) {
      if (this.reference.mountCa && this.reference.m2Sdv) {
        const caSdv = +this.reference.mountCa / +this.reference.m2Sdv
        this.reference.caSdv = (caSdv || 0).toFixed(2)
      }
    }
    if ((key === 'loyerAnnuel' || key === 'capitalPrice' || key === 'coeffDamortissement' || key === 'surfacePonderee' || key === 'tablePonderation') && this.reference.typeTransaction === 1) {
      if (this.reference.loyerAnnuel && this.reference.capitalPrice && this.reference.coeffDamortissement && this.reference.surfacePonderee) {
        const loyerDecap = roundNumber((+this.reference.loyerAnnuel + (+this.reference.capitalPrice / +this.reference.coeffDamortissement)) / +this.reference.surfacePonderee)
        this.reference.loyerDecapitalise = (loyerDecap || 0).toFixed(0)
      }
    }
    if ((key === 'prixHd' || key === 'droitsPercent') && this.reference.typeValeur === 2) {
      if (this.reference.prixHd && this.reference.droitsPercent) {
        const prixDi = roundNumber(+this.reference.prixHd * (1 + this.reference.droitsPercent / 100))
        this.reference.prixDi = (prixDi || 0).toFixed(0)
      }
    }
    if ((key === 'prixDi' || key === 'droitsPercent') && this.reference.typeValeur === 2) {
      if (this.reference.prixDi && this.reference.droitsPercent) {
        const prixHd = roundNumber(+this.reference.prixDi / (1 + this.reference.droitsPercent / 100))
        this.reference.prixHd = (prixHd || 0).toFixed(0)
      }
    }
    if ((key === 'loyerAnnuel' || key === 'prixDi' || key === 'droitsPercent' || key === 'prixHd') && this.reference.typeTransaction === 5 && this.reference.loyerAnnuel && this.reference.prixDi) {
      const tauxRendement = (+this.reference.loyerAnnuel / +this.reference.prixDi) * 100
      this.reference.tauxRendement = (tauxRendement || 0).toFixed(2)
    }
    if ((key === 'loyerAnnuel' || key === 'prixHd' || key === 'droitsPercent' || key === 'prixDi') && this.reference.typeTransaction === 5 && this.reference.loyerAnnuel && this.reference.prixHd) {
      const tauxCapitalisation = (+this.reference.loyerAnnuel / +this.reference.prixHd) * 100
      this.reference.tauxCapitalisation = (tauxCapitalisation || 0).toFixed(2)
    }
    if ((key === 'loyerAnnuel' || key === 'prixHd' || key === 'droitsPercent' || key === 'prixDi') && this.reference.typeTransaction === 8 && this.reference.prixHd && this.reference.loyerAnnuel) {
      const multipleLoyer = (+this.reference.prixHd / +this.reference.loyerAnnuel)
      this.reference.multipleLoyer = (multipleLoyer || 0).toFixed(2)
    }
    if ((key === 'mountCa' || key === 'prixHd' || key === 'droitsPercent' || key === 'prixDi') && this.reference.typeTransaction === 6 && this.reference.prixHd && this.reference.mountCa) {
      const prixHdPerCa = (+this.reference.prixHd / +this.reference.mountCa) * 100
      this.reference.prixHdPerCa = (prixHdPerCa || 0).toFixed(1)
    }
    if ((key === 'prixHd' || key === 'ebe' || key === 'droitsPercent' || key === 'prixDi') && this.reference.typeTransaction === 6 && this.reference.prixHd && this.reference.ebe) {
      const multipleEbe = (+this.reference.prixHd / +this.reference.ebe)
      this.reference.multipleEbe = (multipleEbe || 0).toFixed(2)
    }
    if (key === 'surfaceM2' || key === 'prixHd' || key === 'droitsPercent' || key === 'prixDi') {
      if (this.reference.surfaceM2 && this.reference.prixHd) {
        const prixM2 = roundNumber(+this.reference.prixHd / +this.reference.surfaceM2)
        this.reference.prixM2 = (prixM2 || 0).toFixed(0)
      }
    }
    this._reference.next(this.reference)
  }

  public initRef (form: any): void {
    if ((form.typeLocal === null || form.typeTransaction === null) && (form.id != null || form.isMigratedRef)) {
      this._isMigratedRef.next(true)
    } else {
      this._isMigratedRef.next(false)
    }
    this._reference.next(form)
  }

  public async duplicateReference (): Promise<any> {
    return await new Promise((resolve: any, reject: any) => {
      localStorage.setItem('duplicatedReference', JSON.stringify(this.reference))
      resolve('success')
    })
  }

  public updateReferenceAdresse (form: ReferenceAddress): void {
    let label = ''
    if (form.housenumber) {
      label += form.housenumber + ' '
    }
    if (form.subHousenumber) {
      label += form.subHousenumber + ' '
    }
    if (form.street) {
      label += form.street + ', '
    }
    if (form.locality) {
      label += form.locality
    }
    this.reference.adresse = label || form.label
    form.label = label || form.label
    this._reference.next(this.reference)
    this._referenceAddress.next(form)
  }

  public reset (form: any): void {
    form.adresse = ''
    this.reference.adresse = ''
    this._reference.next(this.reference)
    this._referenceAddress.next(form)
  }

  public initReferenceAddresse (form: any): void {
    const address = {
      layer: form.layer,
      housenumber: form.housenumber,
      street: form.street,
      postalcode: form.postalcode,
      country: form.country,
      macroregion: form.macroregion,
      region: form.region,
      locality: form.locality,
      continent: form.continent,
      borough: form.borough,
      label: form.label,
      coordinates: [form.lng || form.lon, form.lan || form.lat],
      subHousenumber: form.subHousenumber,
      lat: form.lan || form.lat,
      lon: form.lng || form.lon
    }
    const referenceAddress = new ReferenceAddress(address)
    this._referenceAddress.next(referenceAddress)
  }

  public getFormattedValue (key: string, value: any): string | any {
    if (!isNaN(value)) {
      if (key === 'surfacePonderee' || key === 'loyerP' || key === 'loyerDecapitalise' || key === 'prixM2' || key === 'loyerAnnuel' || key === 'surfaceM2' || key === 'capitalPrice' || key === 'loyerChambre' || key === 'loyerPerPlace' || key === 'loyerLit' || key === 'loyerFauteuil') {
        return new Intl.NumberFormat('fr-FR', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(parseFloat(value))
      } else if (key === 'coeffDamortissement' || key === 'prixHdPerCa' || key === 'multipleEbe' || key === 'multipleLoyer') {
        return new Intl.NumberFormat('fr-FR', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 1 }).format(parseFloat(value))
      } else {
        return new Intl.NumberFormat('fr-FR', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(parseFloat(value))
      }
    }

    return value
  }
}

export const listReferenceService = new ListReferenceService()
export const referenceService = new ReferenceService()
