const itemList: any = {
  4: 'Adresse',
  5: 'Source',
  6: 'Date de la source',
  7: 'Type de local',
  8: 'Surface m2',
  9: 'Confidentielle',
  10: 'Fiabilité',
  11: 'n° de fiche (ID)',
  12: 'Observations générales',
  13: 'Observations importantes',
  14: 'Informations création/modification fiche',
  15: 'Pièces-jointes',
  16: 'Emplacement image',
  17: 'Enseigne / locataire',
  18: 'Activité',
  19: 'CA €',
  20: 'Période CA',
  21: 'Loyer €/m2',
  22: 'Loyer annuel €',
  23: '% Loyer / CA',
  25: 'EBE',
  26: 'EBITDAR',
  27: 'CA par lit',
  28: 'CA par chambre',
  29: '% EBE / CA',
  30: '% EBITDAR / CA',
  31: 'Nombre de places',
  32: 'Loyer/place',
  33: 'CA/place',
  34: 'Nombre de lots',
  36: 'Tableau de pondération',
  37: 'Surface pondérée',
  38: 'Type de pondération',
  39: 'Loyer €/m2 P',
  40: 'm2 SDV',
  41: 'CA €/ m2 SDV',
  43: 'Classement',
  44: 'Nombre de chambres',
  45: 'Loyer/Chambre',
  46: 'Nombre de lits',
  47: 'Loyer/Lit',
  48: 'Nombre de fauteuils',
  49: 'Loyer/fauteuil',
  50: 'CA/ fauteuil',
  52: 'Date d\'effet du bail',
  53: 'Destination',
  54: 'Impot foncier',
  55: 'Assurance immeuble',
  56: 'Honoraire de gestion technique',
  57: 'Mise en conformité',
  58: 'Type de transaction',
  60: 'Taux d\'occupation %',
  61: 'Remise à la clientèle %',
  62: '% loyer/recette',
  63: 'PMC €',
  64: 'Revpar',
  65: '% nuité OTA',
  66: '% Loyer / EBE',
  67: '% Loyer / EBITDAR',
  68: 'Abattements L311-1 Code du Tourisme',
  70: 'Capital €',
  71: 'Coeff. d\'amortissement',
  72: 'Loyer décapitalisé €/m2 P',
  73: 'Nature du capital',
  78: 'Tribunal',
  79: 'Date de la décision',
  80: 'Motif de déplafonnement',
  81: 'Détail FLC',
  82: 'Abattements / Majorations',
  85: 'Prix €/ m2 HD',
  86: 'Prix € HD',
  87: 'Prix € DI',
  88: 'Droits %',
  89: 'Date de vente',
  90: 'Acquéreur',
  91: 'Vendeur',
  92: 'RCS Vendeur',
  93: 'RCS Acquéreur',
  94: 'Type de Transaction',
  96: 'Prix € HD/ chambre',
  97: 'Prix € HD / lit',
  98: 'Prix €HD / fauteuil',
  99: 'Prix € HD/ Place',
  101: 'Taux de rendement %',
  102: 'Taux de capitalisation',
  104: '% Prix € HD / CA ',
  105: 'Multiple EBE',
  107: 'Ventilation Murs/Fonds',
  109: 'Multiple de loyer'
}

export const baseBlockItems: any = {
  // COMMERCIAL
  "Boutique en pied d'immeuble": [
    // itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  'Boutique sur cour': [
    // itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  'Boutique sur domaine public': [
    // itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  'Boutique en gare': [
    // itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  'Boutique en aéroport': [
    // itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  'Boutique en retail park': [
    // itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  'Boutique en centre commercial': [
    // itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  'Appartement commercial': [
    // itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  'Boutique  en galerie marchande': [
    // itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  // TOURISME
  'Hôtel de tourisme': [
    itemList[27],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[36],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50]
  ],
  'Hôtel de Préfecture': [
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[36],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[48],
    itemList[49],
    itemList[50]
  ],
  'Résidence hôtelière': [
    itemList[27],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[36],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50]
  ],
  'Auberge de Jeunesse': [
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[36],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[48],
    itemList[49],
    itemList[50]
  ],
  // RESIDENCES GEREES
  'Résidence étudiante': [
    itemList[27],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[36],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50]
  ],
  'Résidence senior': [
    itemList[27],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[36],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50]
  ],
  // RESIDENTIEL
  'Immeuble en bloc': [
    itemList[17],
    itemList[18],
    itemList[19],
    itemList[20],
    itemList[23],
    itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[36],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50]
  ],
  Propriété: [
    itemList[17],
    itemList[18],
    itemList[19],
    itemList[20],
    itemList[23],
    itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[36],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  'Loge ou Chambre de Service': [
    itemList[17],
    itemList[18],
    itemList[19],
    itemList[20],
    itemList[23],
    itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[36],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  'Hôtel particulier': [
    itemList[17],
    itemList[18],
    itemList[19],
    itemList[20],
    itemList[23],
    itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[36],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  Appartement: [
    itemList[17],
    itemList[18],
    itemList[19],
    itemList[20],
    itemList[23],
    itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[36],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  // TERTIAIRE
  Bureaux: [
    itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[36],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  "Local d'activité et/ou de stockage": [
    itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[36],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  // SANTE
  EHPAD: [
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[38],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[48],
    itemList[49],
    itemList[50]
  ],
  'Clinique MCO': [
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[38],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  'Clinique SSR': [
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[38],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[48],
    itemList[49],
    itemList[50]
  ],
  'Clinique (MCO et SSR)': [
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[38],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  // EVENEMENTIEL
  'Salle de réception': [
    itemList[27],
    itemList[28],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[38],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  // LOISIR
  Cinéma: [
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[36],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47]
  ],
  Théâtre: [
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[36],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47]
  ],
  // Else
  "Établissement d'enseignement": [
    itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[38],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  'Salle de sport': [
    itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    // itemList[39],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  'Parking/ garage hôtel': [
    itemList[27],
    itemList[28],
    itemList[34],
    itemList[36],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50]
  ],
  'Garage avec atelier': [
    itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[34],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  'Terrain nu': [
    itemList[19],
    itemList[20],
    itemList[23],
    itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[36],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ],
  'Terrain avec constructions': [
    itemList[19],
    itemList[20],
    itemList[23],
    itemList[25],
    itemList[26],
    itemList[27],
    itemList[28],
    itemList[29],
    itemList[30],
    itemList[31],
    itemList[32],
    itemList[33],
    itemList[34],
    itemList[36],
    itemList[37],
    itemList[38],
    itemList[39],
    itemList[40],
    itemList[41],
    itemList[43],
    itemList[44],
    itemList[45],
    itemList[46],
    itemList[47],
    itemList[48],
    itemList[49],
    itemList[50],
    itemList[60]
  ]
}

export const locativeBlockItems: any = {
  // COMMERCIAL
  "Boutique en pied d'immeuble": [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68]
  ],
  'Boutique sur cour': [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68]
  ],
  'Boutique sur domaine public': [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68]
  ],
  'Boutique en gare': [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68]
  ],
  'Boutique en aéroport': [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68]
  ],
  'Boutique en retail park': [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68]
  ],
  'Boutique en centre commercial': [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68]
  ],
  'Appartement commercial': [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68]
  ],
  'Boutique  en galerie marchande': [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68]
  ],
  // TOURISME
  'Hôtel de tourisme': [],
  'Hôtel de Préfecture': [],
  'Résidence hôtelière': [],
  'Auberge de Jeunesse': [],
  // RESIDENCES GEREES
  'Résidence étudiante': [
    itemList[68]
  ],
  'Résidence senior': [
    itemList[68]
  ],
  // RESIDENTIEL
  'Immeuble en bloc': [
    itemList[54],
    itemList[55],
    itemList[56],
    itemList[57],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68],
    itemList[70],
    itemList[71],
    itemList[72],
    itemList[73],
    itemList[75],
    itemList[76],
    itemList[78],
    itemList[79],
    itemList[80],
    itemList[81],
    itemList[82]
  ],
  Propriété: [
    itemList[54],
    itemList[55],
    itemList[56],
    itemList[57],
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68],
    itemList[70],
    itemList[71],
    itemList[72],
    itemList[73],
    itemList[75],
    itemList[76],
    itemList[78],
    itemList[79],
    itemList[80],
    itemList[81],
    itemList[82]
  ],
  'Loge ou Chambre de Service': [
    itemList[54],
    itemList[55],
    itemList[56],
    itemList[57],
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68],
    itemList[70],
    itemList[71],
    itemList[72],
    itemList[73],
    itemList[75],
    itemList[76],
    itemList[78],
    itemList[79],
    itemList[80],
    itemList[81],
    itemList[82]
  ],
  'Hôtel particulier': [
    itemList[54],
    itemList[55],
    itemList[56],
    itemList[57],
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68],
    itemList[70],
    itemList[71],
    itemList[72],
    itemList[73],
    itemList[75],
    itemList[76],
    itemList[78],
    itemList[79],
    itemList[80],
    itemList[81],
    itemList[82]
  ],
  Appartement: [
    itemList[54],
    itemList[55],
    itemList[56],
    itemList[57],
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68],
    itemList[70],
    itemList[71],
    itemList[72],
    itemList[73],
    itemList[75],
    itemList[76],
    itemList[78],
    itemList[79],
    itemList[80],
    itemList[81],
    itemList[82]
  ],
  // TERTIAIRE
  Bureaux: [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68]
  ],
  "Local d'activité et/ou de stockage": [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68]
  ],
  // SANTE
  EHPAD: [
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[68]
  ],
  'Clinique MCO': [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[68]
  ],
  'Clinique SSR': [
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[68]
  ],
  'Clinique (MCO et SSR)': [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[68]
  ],
  // EVENEMENTIEL
  'Salle de réception': [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68]
  ],
  // LOISIR
  Cinéma: [
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[68]
  ],
  Théâtre: [
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[68]
  ],
  // Else
  "Établissement d'enseignement": [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68]
  ],
  'Salle de sport': [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68]
  ],
  'Parking/ garage hôtel': [
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68]
  ],
  'Garage avec atelier': [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68]
  ],
  'Terrain nu': [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68]
  ],
  'Terrain avec constructions': [
    itemList[60],
    itemList[61],
    itemList[62],
    itemList[63],
    itemList[64],
    itemList[65],
    itemList[66],
    itemList[67],
    itemList[68]
  ]
}

export const valeurBlockItems: any = {
  // COMMERCIAL
  "Boutique en pied d'immeuble": [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  'Boutique sur cour': [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  'Boutique sur domaine public': [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  'Boutique en gare': [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  'Boutique en aéroport': [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  'Boutique en retail park': [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  'Boutique en centre commercial': [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  'Appartement commercial': [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  'Boutique  en galerie marchande': [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  // TOURISME
  'Hôtel de tourisme': [
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  'Hôtel de Préfecture': [
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  'Résidence hôtelière': [
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  'Auberge de Jeunesse': [
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  // RESIDENCES GEREES
  'Résidence étudiante': [
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  'Résidence senior': [
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  // RESIDENTIEL
  'Immeuble en bloc': [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99],
    itemList[104],
    itemList[105],
    itemList[107],
    itemList[109]
  ],
  Propriété: [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99],
    itemList[104],
    itemList[105],
    itemList[107],
    itemList[109]
  ],
  'Loge ou Chambre de Service': [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99],
    itemList[104],
    itemList[105],
    itemList[107],
    itemList[109]
  ],
  'Hôtel particulier': [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99],
    itemList[104],
    itemList[105],
    itemList[107],
    itemList[109]
  ],
  Appartement: [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99],
    itemList[104],
    itemList[105],
    itemList[107],
    itemList[109]
  ],
  // TERTIAIRE
  Bureaux: [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  "Local d'activité et/ou de stockage": [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  // SANTE
  EHPAD: [
    itemList[98],
    itemList[99]
  ],
  'Clinique MCO': [
    itemList[98],
    itemList[99]
  ],
  'Clinique SSR': [
    itemList[98],
    itemList[99]
  ],
  'Clinique (MCO et SSR)': [
    itemList[98],
    itemList[99]
  ],
  // EVENEMENTIEL
  'Salle de réception': [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  // LOISIR
  Cinéma: [
    itemList[96],
    itemList[97],
    itemList[99]
  ],
  Théâtre: [
    itemList[96],
    itemList[97],
    itemList[99]
  ],
  // Else
  "Établissement d'enseignement": [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  'Salle de sport': [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  'Parking/ garage hôtel': [
    itemList[96],
    itemList[97],
    itemList[98]
  ],
  'Garage avec atelier': [
    itemList[96],
    itemList[97],
    itemList[98]
  ],
  'Terrain nu': [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99]
  ],
  'Terrain avec constructions': [
    itemList[96],
    itemList[97],
    itemList[98],
    itemList[99]
  ]
}

export const baseBlockItemsSearchSection = [
  itemList[8],
  itemList[11],
  itemList[12],
  itemList[13],
  itemList[17],
  itemList[18],
  itemList[52],
  itemList[89],
  itemList[53],
  itemList[90],
  itemList[91]
]

export const baseBlockWithoutTypeLocal = [
  itemList[4],
  itemList[8],
  itemList[17],
  itemList[18]
]

export const baseBlockNouvelleLocation = [
  itemList[70],
  itemList[71],
  itemList[72],
  itemList[73]
]
