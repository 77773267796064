import axios from 'axios'
import { apiClient } from '@/services/api.client'
import { authService } from '@/services/auth.service'
import { quoteService } from '@/services/quote.service'
import { reportService } from '@/services/report.service'
import { fileService, CategoryTree } from '@/services/file.service'
import { billingService } from '@/services/billing.service'
import { BehaviorSubject, Observable } from 'rxjs'
import {
  CourrierType,
  Courthouse,
  MotifDossier,
  NatureDecision,
  Courrier,
  LegalFolder
} from '@/models/legalFolder.model'
import { VUE_APP_RAPPORT_API_URL } from '@/constants'
import { removeSpecialCharacters } from '@/utils/functions'
import _ from 'lodash'

class LegalFolderService {
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  private readonly _courrierTypes: BehaviorSubject<CourrierType[]> = new BehaviorSubject<CourrierType[]>([])
  private readonly _courthouses: BehaviorSubject<Courthouse[]> = new BehaviorSubject<Courthouse[]>([])
  private readonly _motifDossiers: BehaviorSubject<MotifDossier[]> = new BehaviorSubject<MotifDossier[]>([])
  private readonly _natureDecisions: BehaviorSubject<NatureDecision[]> = new BehaviorSubject<NatureDecision[]>([])
  private readonly _folder: BehaviorSubject<LegalFolder> = new BehaviorSubject<LegalFolder>(new LegalFolder())
  private readonly _folders: BehaviorSubject<LegalFolder[]> = new BehaviorSubject<LegalFolder[]>([])
  public onLoading: Observable<boolean> = this._loading.asObservable()
  public onChangeCourrierTypes: Observable<CourrierType[]> = this._courrierTypes.asObservable()
  public onChangeCourthouses: Observable<Courthouse[]> = this._courthouses.asObservable()
  public onChangeMotifDossiers: Observable<MotifDossier[]> = this._motifDossiers.asObservable()
  public onChangeNatureDecisions: Observable<NatureDecision[]> = this._natureDecisions.asObservable()
  public onChangeLegalFolder: Observable<LegalFolder> = this._folder.asObservable()
  public onChange: Observable<LegalFolder[]> = this._folders.asObservable()

  get folder (): LegalFolder {
    return this._folder.getValue()
  }

  get folders (): LegalFolder[] {
    return this._folders.getValue()
  }

  public initFolder (data?: any): void {
    if (data) {
      data.natureOfDecision = data.natureDecision ? data.natureDecision.id : null
      data.endFolderDate = data.motifDossier ? data.motifDossier.id : null
      const fetchedFolder = new LegalFolder(data)
      this._folder.next(fetchedFolder)
      const quote = {
        filesRequested: data.filesRequested,
        contacts: data.contacts,
        addresses: data.addresses,
        missions: data.missions,
        expertiseMission: data.expertiseMission,
        billing: data.billing
      }
      quoteService.initQuote(quote)
      if (data.report) {
        reportService.initReport(data.report)
      } else {
        reportService.initReport()
      }
      if (data.files) {
        this.initFiles(data.files)
      }
      if (data.categories) {
        fileService.initCategoryTree(data.categories)
      }
      this.updateFolder()
    } else {
      const folder = new LegalFolder()
      this._folder.next(folder)
      quoteService.initQuote()
      reportService.initReport()
    }
  }

  public initFiles (files: any[]): void {
    if (this.folder.submittedFile) {
      const findFile = files.find((file: any) => file.id === this.folder.submittedFile.id)
      if (findFile) {
        findFile.selected = true
      }
    }
    fileService.initFiles(files)
  }

  public async duplicateFolder (): Promise<any> {
    return await new Promise((resolve: any, reject: any) => {
      if ((authService.userValue != null) && authService.userValue.id) {
        this.folder.userId = authService.userValue.id
      }
      const quote = quoteService.quote
      this.folder.filesRequested = quote.filesRequested
      this.folder.contacts = quote.contacts
      this.folder.addresses = quote.addresses
      this.folder.missions = quote.missions
      this.folder.expertiseMission = quote.expertiseMission
      localStorage.setItem('duplicatedFolder', JSON.stringify(this.folder))
      resolve('success')
    })
  }

  public initDuplicateFolder (data: any): void {
    const folder = data
    folder.id = null
    folder.status = 0
    folder.name = ''
    folder.report = null
    folder.expertiseMission.id = null
    folder.missions.forEach((item: any) => {
      item.id = null
    })
    folder.contacts.forEach((item: any) => {
      item.id = null
    })
    folder.addresses.forEach((item: any) => {
      item.id = null
    })
    folder.billing = null
    folder.files = []
    this.initFolder(data)
  }

  public async getCourrierTypes (): Promise<void> {
    this._loading.next(true)
    await apiClient.get('courrier-types').then(res => {
      if (res.data) {
        const courrierTypes: CourrierType[] = []
        res.data.forEach((courrierType: any) => {
          courrierTypes.push(new CourrierType(courrierType))
        })
        this._courrierTypes.next(courrierTypes)
        this._loading.next(false)
      }
    })
  }

  public async getCourthouses (): Promise<void> {
    this._loading.next(true)
    await apiClient.get('courthouses').then(res => {
      if (res.data) {
        const courthouses: Courthouse[] = []
        res.data.forEach((courthouse: any) => {
          courthouses.push(new Courthouse(courthouse))
        })
        this._courthouses.next(courthouses)
        this._loading.next(false)
      }
    })
  }

  public async getMotifDossiers (): Promise<void> {
    this._loading.next(true)
    await apiClient.get('motif-dossiers').then(res => {
      if (res.data) {
        const motifDossiers: MotifDossier[] = []
        res.data.forEach((motifDossier: any) => {
          motifDossiers.push(new MotifDossier(motifDossier))
        })
        this._motifDossiers.next(motifDossiers)
        this._loading.next(false)
      }
    })
  }

  public async getNatureDecisions (): Promise<void> {
    this._loading.next(true)
    await apiClient.get('nature-decisions').then(res => {
      if (res.data) {
        const natureDecisions: NatureDecision[] = []
        res.data.forEach((natureDecision: any) => {
          natureDecisions.push(new NatureDecision(natureDecision))
        })
        this._natureDecisions.next(natureDecisions)
        this._loading.next(false)
      }
    })
  }

  public addCourrier (index: number): void {
    const courrier = new Courrier({})
    this.folder.courriers.splice(index + 1, 0, courrier)
    this._folder.next(this.folder)
  }

  public deleteCourrier (index: number): void {
    if (index === 0) {
      this.folder.courriers.splice(index, 1, new Courrier({}))
    } else {
      this.folder.courriers.splice(index, 1)
    }
    this._folder.next(this.folder)
  }

  public async getFolders (offset: number = 0, limit: number = 10): Promise<void> {
    this._loading.next(true)
    await apiClient.get('legal-folders', { params: { limit, offset } }).then(res => {
      if (res.data) {
        this._folders.next(res.data)
      }
      this._loading.next(false)
    })
  }

  public async searchFolders (query: any, offset: number = 0, limit: number = 10): Promise<void> {
    this._loading.next(true)
    const params = {
      limit,
      offset
    }
    _.merge(params, query)
    this._loading.next(true)
    await apiClient.get('legal-folders', { params }).then(res => {
      if (res.data) {
        this._folders.next(res.data)
      }
      this._loading.next(false)
    })
  }

  public async deleteFolder (): Promise<any> {
    this._loading.next(true)
    return await apiClient.delete('settled-folder/' + String(this.folder.id)).then((res: any) => {
      this.initFolder()
      this._loading.next(false)
      return 'success'
    })
  }

  public async getFolderById (id: string): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('legal-folder/' + id).then((res: any) => {
      res.data.folder.conflit = res.data.conflit
      this.initFolder(res.data.folder)
      this._loading.next(false)
      return res.data
    })
  }

  public updateFolder (): void {
    this._folder.next(this.folder)
    billingService.updateBilling('legal-folder')
  }

  public async getStatusHistory (): Promise<any> {
    this._loading.next(true)
    return await apiClient.get('legal-folder/' + String(this.folder.id) + '/statusHistory').then((res: any) => {
      this.folder.statusHistory = res.data.statusHistory
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async openFolder (): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('legal-folder/open', {
      folderId: this.folder.id,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status

      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async attributeFolder (userId: number): Promise<any> {
    this._loading.next(true)
    return await apiClient.post('legal-folder/attribute', {
      folderId: this.folder.id,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status

      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async visitFolder (): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('legal-folder/visit', {
      folderId: this.folder.id,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status

      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async submitReport (fileId: string, type: string): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('legal-folder/submit', {
      folderId: this.folder.id,
      fileId,
      type,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status

      if (res.data.files) {
        this.folder.submittedFile = res.data.files.find((file: any) => file.id === fileId)
        this.initFiles(res.data.files)
      }
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async cancelSubmit (type: string): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('legal-folder/submit/cancel', {
      folderId: this.folder.id,
      type,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status

      if (res.data.files) {
        this.initFiles(res.data.files)
      }
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async acceptValidation (type: string): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('legal-folder/validation/accept', {
      folderId: this.folder.id,
      type,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status

      if (res.data.files) {
        this.initFiles(res.data.files)
      }
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async validateReport (comment: string, type: string): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('legal-folder/validate', {
      folderId: this.folder.id,
      comment,
      type,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status

      if (res.data.files) {
        this.initFiles(res.data.files)
      }
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async refuseValidation (comment: string, type: string): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('legal-folder/validation/refuse', {
      folderId: this.folder.id,
      comment,
      type,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status

      if (res.data.files) {
        this.initFiles(res.data.files)
      }
      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async emailSent (): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('legal-folder/email', {
      folderId: this.folder.id,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status

      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async depositReport (): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('legal-folder/deposit', {
      folderId: this.folder.id,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status

      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async paymentSent (): Promise<any> {
    this._loading.next(true)
    const userId = authService.getUserId()
    return await apiClient.post('legal-folder/payment', {
      folderId: this.folder.id,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status

      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async closeFolder (userId: number): Promise<any> {
    this._loading.next(true)
    return await apiClient.post('legal-folder/close', {
      folderId: this.folder.id,
      userId,
      oldStatus: this.folder.status
    }).then((res: any) => {
      this.folder.status = res.data.status

      this.updateFolder()
      this._loading.next(false)
      return res.data
    })
  }

  public async saveFolder (): Promise<any> {
    this._loading.next(true)
    const quote = quoteService.quote
    if ((authService.userValue != null) && authService.userValue.id) {
      quote.userId = authService.userValue.id
      this.folder.userId = authService.userValue.id
    }
    this.folder.report = reportService.report
    this.folder.billing = billingService.billing
    return await apiClient.post('legal-folder', {
      folder: this.folder,
      quote
    }).then((res: any) => {
      res.data.folder.conflit = res.data.conflit
      this.initFolder(res.data.folder)
      this._loading.next(false)
      return res.data
    })
  }

  public async generateDocument (): Promise<any> {
    this._loading.next(true)
    const client = this.folder.contacts.find((contact: any) => contact.mandator === true && contact.type === 'applicant')
    let clientName = ''
    if (client && client.contact) {
      clientName = client.contact.prenom != null ? removeSpecialCharacters(client.contact.nom) + ' ' + removeSpecialCharacters(client.contact.prenom) : removeSpecialCharacters(client.contact.nom)
    }
    const address = this.folder.addresses ? String(this.folder.addresses[0].buildingNumber) + ' ' + removeSpecialCharacters(this.folder.addresses[0].street) + ' ' + String(this.folder.addresses[0].zip) + ' ' + removeSpecialCharacters(this.folder.addresses[0].city) : null
    return await axios.get(VUE_APP_RAPPORT_API_URL + 'legal-folder/' + String(this.folder.id) + '/report/download', {
      responseType: 'blob'
    }).then((res: any) => {
      this._loading.next(false)
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', String(this.folder.name) + ' - ' + String(address) + ' - ' + clientName + '.pptx') // any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
      return 'success'
    })
  }

  public async saveCategoryTree (folderId: number, newTree: CategoryTree[]): Promise<void> {
    await apiClient.post('legal-folder/category-tree', { folderId, newTree }).then(({ data }) => {
      if (data.categories) {
        fileService.initCategoryTree(data.categories)
        this.initFiles(data.files)
      }
    })
  }

  public async getFolderConflicts (folderId: number): Promise<void> {
    this._loading.next(true)
    return await apiClient.get('legal-folder/conflit/' + folderId.toString()).then((res: any) => {
      this._loading.next(false)
      return res.data
    })
  }

  public async deleteLegalFolder (folderId: number): Promise<any> {
    this._loading.next(true)
    await apiClient.delete('legal-folder/' + folderId.toString()).then((res: any) => {
      this._loading.next(false)
    }).catch((err) => {
      console.error(err)
      this._loading.next(false)
      throw err
    })
  }
}

export const legalFolderService = new LegalFolderService()
