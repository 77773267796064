
import { Component, Vue, Watch } from 'vue-property-decorator'
import { SearchCriteria, saveSearchService } from '@/services/saveSearch.service'
import { listReferenceService, ReferenceAddress, ListReference } from '@/services/listReference.service'
import { User, userService } from '@/services/user.service'
import { PageParams } from '@/models/ged.model'
import { authService } from '@/services/auth.service'
import {
  filterGroupList,
  generateTypeLocalGroup,
  generateActivitiesGroup
} from '@/utils/functions'

@Component({
  subscriptions () {
    return {
      pageParams$: saveSearchService.onChangePageParams,
      users$: userService.onChangeUsers,
      searchCriteria$: saveSearchService.onChangeSearchCriteria,
      authUser$: authService.onChange
    }
  },
  components: {}
})

export default class SearchCriteriaView extends Vue {
  searchCriteria$: SearchCriteria = new SearchCriteria()
  rules: any = {}
  typeLocalLists: ListReference[] = []
  typeLocalListsGroup: any = []
  filteredTypeLocalItems: any = []
  filteredActivites: any = []
  typeValeurLists: ListReference[] = []
  activitesLists: ListReference[] = []
  activitesListsGroup: any = []
  pageParams$: PageParams
  authUser$: any = {}
  userName: string = ''
  users$: User[] = []

  created () {
    this.searchCriteria$.userId = this.authUser$.id
    this.userName = this.authUser$.firstname + ' ' + this.authUser$.lastname
    userService.getUsers()
    listReferenceService.getListReference().then(res => {
      this.typeLocalLists = res.filter((item: ListReference) => item.type === 1)
      this.typeLocalListsGroup = generateTypeLocalGroup(this.typeLocalLists)
      this.activitesLists = res.filter((item: ListReference) => item.type === 3)
      this.activitesListsGroup = generateActivitiesGroup(this.activitesLists)
      this.typeValeurLists = res.filter((item: ListReference) => item.type === 11)
    })
  }

  @Watch('typeLocalListsGroup', { immediate: true, deep: true })
  handleTypeLocalItems () {
    this.filteredTypeLocalItems = JSON.parse(JSON.stringify(this.typeLocalListsGroup))
  }

  @Watch('activitesListsGroup', { immediate: true, deep: true })
  handleActivites () {
    this.filteredActivites = JSON.parse(JSON.stringify(this.activitesListsGroup))
  }

  querySearch (queryString: string, cb: Function) {
    if (!queryString) return
    listReferenceService.getReferenceAddresse(queryString).then(res => {
      cb(res)
    })
  }

  handleSelectAddress (peliasAddress: ReferenceAddress) {
    this.searchCriteria$.peliasAddress = peliasAddress
  }

  clearPelias () {
    this.searchCriteria$.peliasAddress = new ReferenceAddress()
  }

  handleSearch () {
    this.pageParams$.currentPage = 1
    saveSearchService.getSearches('filters')
  }

  handleCancel () {
    this.searchCriteria$ = new SearchCriteria()
    this.userName = ''
    this.$emit('clearFilter')
  }

  filterUsers (query: string) {
    if (query != null && query.length > 0) {
      userService.getUsersByKey(query)
    } else {
      userService.getUsers()
    }
    this.userName = query
    this.searchCriteria$.userId = null
  }

  changeUser (val: any) {
    const user = this.users$.find((user: User) => user.id === val)
    if (user) {
      this.userName = user.prenom + ' ' + user.nom
      this.searchCriteria$.userId = user && user.id ? user.id : null
    }
  }

  clearUserCriteria () {
    this.userName = ''
    this.searchCriteria$.userId = null
  }

  filterOptions (query: string, type: string) {
    if (type === 'typeLocal') {
      this.filteredTypeLocalItems = filterGroupList(query, this.typeLocalListsGroup)
    } else if (type === 'activities') {
      this.filteredActivites = filterGroupList(query, this.activitesListsGroup)
    }
  }

  handleFocusList (type: string) {
    if (type === 'typeLocal') {
      this.filteredTypeLocalItems = JSON.parse(JSON.stringify(this.typeLocalListsGroup))
    } else if (type === 'activities') {
      this.filteredActivites = JSON.parse(JSON.stringify(this.activitesListsGroup))
    }
  }
}
